import { AsyncJobDialogBase, JOB_COMPLETED_MESSAGE } from './async-job-base.dialog';
import { AsyncJobService } from '../../services/async-job.service';
import { ImportFormInstancesMetrics } from '../../models/export-import/import-form-instances-metrics.model';
import { AsyncJob } from '../../models/async-job.model';

export class MonitorExcelImportToGridHelper extends AsyncJobDialogBase {
    // Constructor.
    public constructor(private providedAsyncJobService: AsyncJobService) {
        super(providedAsyncJobService);
    }

    public get ComponentClassName(): string {
        return 'MonitorExcelImportToGridHelper';
    }

    public startMonitoringJob(asyncJobInfo: AsyncJob, jobCompletedCallback: (asyncJob: AsyncJob) => void, jobUpdatedCallback: (asyncJob: AsyncJob) => void = null, timeoutTimeInMilliseconds: number = 1000): void
    {
        super.startMonitoringJob(asyncJobInfo, jobCompletedCallback, jobUpdatedCallback, timeoutTimeInMilliseconds);
    }

    // Protected methods.
    /*
    public static parseImportFormInstancesMetrics(jobMetricsJson: string): ImportFormInstancesMetrics {
        let jobCompletedMessageJson: Object = JSON.parse(jobMetricsJson);
        let importMetrics: ImportFormInstancesMetrics = new ImportFormInstancesMetrics(jobCompletedMessageJson);

        return (importMetrics);
    }
    */
}
