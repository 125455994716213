<div class="field-label">{{FormField.displayName || FormField.name}}</div>
<div class="button-div">
    <!-- <button mat-raised-button color="primary" (click)="handleGetContentButtonClick($event)">{{ButtonName}}</button>&nbsp; -->
    <button mat-raised-button color="primary" (click)="handleOpenContentButtonClick($event)">Open in New Tab</button>

    <!--This was just to aid develpment of backend algorithm-->
    <!--&nbsp;<button mat-raised-button color="primary" (click)="handleOpenContentButtonClickForGridRow_FOR_DEV_ONLY($event)">Open GridRow Data in New Tab </button>-->

</div>
<div *ngIf="ShowLoading" class="inline-content-loading">Loading...</div>
<div *ngIf="Resource" [innerHTML]="Resource" class="inline-content-div"></div>
