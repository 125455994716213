<ng-container *ngIf="true">
    <div class="confirm-main">
        <h2 mat-dialog-title
            class="draggable-dialogue-header"
            cdkDrag
            cdkDragRootElement=".cdk-overlay-pane"
            cdkDragHandle>
            {{Title}}
        </h2>

        <div>
            <p>Use this function to validate street addresses and look up census tract values.</p>
        </div>

        <div *ngIf="ShowConfiguration" style="width: 100%;">

            <b>Geocode Grid Columns</b>
            <br />

            <div class="column-name">Address</div>
            <div class="column-found" *ngIf="GridHasColumn('Address')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('Address')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">City</div>
            <div class="column-found" *ngIf="GridHasColumn('city')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('city')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">State</div>
            <div class="column-found" *ngIf="GridHasColumn('state')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('state')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">ZIP</div>
            <div class="column-found" *ngIf="GridHasColumn('zip')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('zip')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">Longitude</div>
            <div class="column-found" *ngIf="GridHasColumn('longitude')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('longitude')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">Latitude</div>
            <div class="column-found" *ngIf="GridHasColumn('latitude')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('latitude')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">Censustract2010</div>
            <div class="column-found" *ngIf="GridHasColumn('censustract2010')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('censustract2010')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">Censustract2020</div>
            <div class="column-found" *ngIf="GridHasColumn('censustract2020')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('censustract2020')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">Validation</div>
            <div class="column-found" *ngIf="GridHasColumn('validation')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('validation')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">Matchtype</div>
            <div class="column-found" *ngIf="GridHasColumn('matchtype')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('matchtype')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">Validatedaddress</div>
            <div class="column-found" *ngIf="GridHasColumn('validatedaddress')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('validatedaddress')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">Geoid</div>
            <div class="column-found" *ngIf="GridHasColumn('geoid')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('geoid')"><mat-icon>cancel</mat-icon></div>

            <div class="column-name">Place</div>
            <div class="column-found" *ngIf="GridHasColumn('place')"><mat-icon>check</mat-icon></div>
            <div class="column-found" *ngIf="!GridHasColumn('place')"><mat-icon>cancel</mat-icon></div>
            <br />
        </div>
        <br />

        <div>
            <button type="button" mat-raised-button color="primary" class="theme-primary-btn" (click)="onStart()">Start</button>
            &nbsp;
            <button mat-button (click)="onDismiss()">Cancel</button>
        </div>
    </div>
</ng-container>
