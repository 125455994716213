export enum BrowserStorageEnums {
    GOTO = 'goto',
    PREV = 'prev',
    PREVNAVBREADCRUMBSLABEL = 'prevNavBreadcrumbsLabel',
    IDLELOGOUT = 'idleLogout',
    PINTREEOPEN = 'pinTreeOpen',
    INCLUDE_FORMS_IN_TREE = 'includeFormsInTreeForSite',
    PREVNAVBREADCRUMBSURL = 'prevNavBreadcrumbsUrl',
    SIDE_NAV_OPEN = 'sideNavOpen',
    NEW_WORKFLOW_REFRESH = '', //VNEXT-235: KLW - New enum for new workflow
    NEW_FORM_INSTANCE_REFRESH = '', //VNEXT-235: KLW - New enum for new form instance
    FORCEDLOGOUT = 'forcelogout'
}
