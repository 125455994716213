<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<div>
    <div *ngIf="this.Mode === 'design'" class="flex-row-top-right">
        <div class="fill-remaining-space">

            <!-- If there are instructions, display label first, then instructions then field -->
            <ng-container *ngIf="this.HasInstructions">
                <div class="design-mode-field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </ng-container>

            <!-- //VNEXT-561: KLW - Have required label show in design -->
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label *ngIf="this.ShowLabel && !this.HasInstructions"
                           class="design-mode-field-label">
                    {{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span>
                </mat-label>

                <input matInput type="number" placeholder="{{this.PlaceholderText}}" value="{{this.DefaultValue}}"
                       disabled />
            </mat-form-field>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>

        <div *ngIf="this.ShowFormattedValue" class="design-mode-formatted-value-div">
            <!--<span>{{this.DefaultValue | number:'1.0-0'}}</span>-->
            <span>{{this.DesignModePreviewValue | number:'1.0-0'}}</span>
        </div>
    </div>

    <div *ngIf="((this.Mode === 'preview') || (this.Mode === 'instance')) && (! this.ShowFormattedValue)"
         class="flex-row-top-right">
        <div class="fill-remaining-space">
            <ng-container *ngIf="(this.ControlType === 'Reactive Forms') && (this.FormControl !== null)">

                <!-- If there are instructions, display label first, then instructions then field -->
                <ng-container *ngIf="this.HasInstructions">
                    <div class="field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                </ng-container>

                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <!-- If there are no instructions, display label integrated into the field -->
                    <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid" class="field-label">
                        {{this.DisplayName}}
                        <span *ngIf="this.FormField.required" style="color:red">*</span>
                    </mat-label>

                    <ng-container *ngIf="! this.ReadOnly">
                        <input matInput IntegerOnly type="text" id="{{this.MatInputId}}"
                               placeholder="{{this.PlaceholderText}}" [formControl]="this.FormControl"
                               (blur)="this.handleOnBlur()" (focus)="this.handleOnFocus($event)" />
                    </ng-container>

                    <ng-container *ngIf="this.ReadOnly">
                        <input matInput readonly type="number" id="{{this.MatInputId}}"
                               placeholder="{{this.PlaceholderText}}" [formControl]="this.FormControl"
                               (blur)="this.handleOnBlur()" (focus)="this.handleOnFocus($event)" />
                    </ng-container>
                </mat-form-field>

            </ng-container>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>

    <!--class="input-with-formatted-value-div"-->
    <div *ngIf="((this.Mode === 'preview') || (this.Mode === 'instance')) && this.ShowFormattedValue"
         class="{{this.PreviewInstanceModesOutDivCssClass}}">
        <div class="show-formatted-value-mat-form-field-div">
            <ng-container *ngIf="(this.ControlType === 'Reactive Forms') && (this.FormControl !== null)">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label *ngIf="this.ShowLabel">
                        {{this.DisplayName}} <span *ngIf="this.FormField.required"
                                                   style="color:red">*</span>
                    </mat-label>
                    <input matInput IntegerOnly type="text" id="{{this.MatInputId}}"
                           placeholder="{{this.PlaceholderText}}" step="0.01" [formControl]="this.FormControl"
                           (blur)="this.handleOnBlur()" (focus)="this.handleOnFocus($event)" />

                    <mat-icon *ngIf="this.HasTooltipText" matSuffix matTooltip="{{this.TooltipText}}">info</mat-icon>

                </mat-form-field>

            </ng-container>
        </div>

        <div class="show-formatted-value-formatted-value-div">
            <ng-container *ngIf="this.FormControl !== null">
                <span>{{this.FormControl.value | number:'1.0-0'}}</span>
            </ng-container>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>
</div>
