import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { plainToClass } from 'class-transformer';

import { environment } from '../../../environments/environment';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { CollectApiServiceBase } from './collect-api-base.service';
import { AsyncJob } from '../models/async-job.model';
import { ExcelImportExportTemplate } from '../models/site-metadata/excel-import-export-template.model';

@Injectable()
export class ExcelTemplateService extends CollectApiServiceBase<ExcelImportExportTemplate> {
    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'excelTemplate', ExcelImportExportTemplate)
    }

    // Implement formatResponse()
    formatResponse(data: ExcelImportExportTemplate): ExcelImportExportTemplate {
        let obj = plainToClass(ExcelImportExportTemplate, data);
        return (obj);
    }

    // Implement service methods.
    public getGridExcelTemplateBase64Representation(templateFile: File): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/getGridExcelTemplateBase64Representation`;

        const formData: FormData = new FormData();
        formData.append('file', templateFile, templateFile.name);

        /*
        var httpHeaders: HttpHeaders = new HttpHeaders();
        const req = new HttpRequest('POST', url, formData, { 
            reportProgress: true,

            headers: httpHeaders
        });
        */

        return this.http.post<AsyncJob>(url, formData)
            .toPromise()
            .then(response => {
                let asyncJob: AsyncJob = response['value']; // Not sure why this has to be done, but perhaps it's because the server is return IActionResult.
                let result = plainToClass(AsyncJob, asyncJob);
                return result;
            })
            .catch(this.handleError);
    }

    public getGridExcelTemplateBase64RepresentationResult(asyncJob: AsyncJob): Promise<ExcelImportExportTemplate> {
        let url = `${this.url}/${this.endpoint}/getGridExcelTemplateBase64RepresentationResult/${asyncJob.jobKey}`;

        return this.http.get<ExcelImportExportTemplate>(url)
            .toPromise()
            .then(response => {
                let result = this.formatResponse(response);
                return result;
            });
    }
}
