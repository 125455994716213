
export class VirtualFolderPermissionsHierarchyRecord {
    // Properties.
    public folderId: number;
    public folderName: string;

    public workflowId: number;
    public workflowName: string;
    public workflowStateId: number;
    public workflowStateName: string;

    public workflowSortField: number;
    public levelInHierarchy: number;

    public roleId: number;
    public roleName: string;

    public isSiteLevelPermission: boolean;

    public denormalizedRecordId: number;

    public canBuildRoles: boolean;
    public canBuildForms: boolean;
    public canBuildFolders: boolean;
    public canBuildFormInstances: boolean;

    public canView: boolean;
    public canEdit: boolean;
    public canDelete: boolean;
    public canGrant: boolean;

    // Methods.
    public static buildPermissionCellNames(): string[] {
        return ['canBuildRoles', 'canBuildWorkflows', 'canBuildForms', 'canBuildFolders', 'canBuildFormInstances'];
    }

    public static performPermissionCellNames(): string[] {
        return ['canView', 'canEdit', 'canDelete', 'canGrant'];
    }

    public canBuild(permissionCellName: string): boolean {
        let canBuild: boolean = this[permissionCellName];

        return (canBuild);
    }
         
    public canPerform(permissionCellName: string): boolean {
        let canPerform: boolean = this[permissionCellName]

        return (canPerform);
    }
}
