
import { IComponentNameToMethodsMetadata, ComponentMethodMetadata, ComponentMethodsMetadata } from './component-methods-metadata.model';

export class EnumMetadata {
    public constructor(public name: string, public values: string[] = null) { }
    public prettyNames: string[] = null;
}
export interface IEnumMetadataByName {
    [enumName: string]: EnumMetadata;
}

// Define a service-like class, albeit one that is not dependency injected, for managing registered component methods and related data.
export class ComponentMethodsService {
    // Static data.
    private static mapOfComponentMethodsMetadata: IComponentNameToMethodsMetadata = {};
    private static enumMetadataByName: IEnumMetadataByName = {};

    // Static method(s).
    // Method for managing registered component methods.
    public static saveComponentMethodMetadataFor(componentClassName: string, methodMetadata: ComponentMethodMetadata): void {
        let componentMethodsMetadata: ComponentMethodsMetadata = ComponentMethodsService.mapOfComponentMethodsMetadata[componentClassName];
        if (componentMethodsMetadata == null) {
            componentMethodsMetadata = new ComponentMethodsMetadata(componentClassName);
            ComponentMethodsService.mapOfComponentMethodsMetadata[componentClassName] = componentMethodsMetadata;
        }

        if (componentMethodsMetadata.methodsMetadata.find(md => md.methodName == methodMetadata.methodName) == null)
            componentMethodsMetadata.methodsMetadata.push(methodMetadata);
    }
    public static getComponentMethodsMetadataFor(componentClassName: string): ComponentMethodsMetadata {
        let componentMethodsMetadata: ComponentMethodsMetadata = ComponentMethodsService.mapOfComponentMethodsMetadata[componentClassName];
        return componentMethodsMetadata;
    }
    /*
    public static getAllComponentMethods(): IComponentNameToMethodsMetadata {
        return this.mapOfComponentMethodsMetadata;
    }
    */

    // Methods for managing registered enumerations used for method parameters.
    public static registerOrUpdateEnum(enumName: string, enumMetadata: EnumMetadata): void {
        ComponentMethodsService.enumMetadataByName[enumName] = enumMetadata;
    }
    public static getEnumMetadata(enumName: string): EnumMetadata {
        let enumMetadata: EnumMetadata = ComponentMethodsService.enumMetadataByName[enumName];
        return enumMetadata;
    }
}
