import { Injectable } from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';

import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { UITestCenterDialog } from '../../dialogs/ui-test-center/ui-test-center.dialog';

export class ComponentTreeNode implements TreeNode<ComponentTreeNode> {
    // Properties.
    private childNodes: TreeNode<ComponentTreeNode>[] = [];

    // Constructor.
    public constructor(private component: ITestableComponent, private _parent: TreeNode<ComponentTreeNode>, private _level: number) { }

    // Child management.
    public addChild(childNode: TreeNode<ComponentTreeNode>): void {
        this.childNodes.push(childNode);
    }

    // Getters.
    public get Component(): ITestableComponent {
        return this.component;
    }

    // Methods used by <p-tree/>.
    public get label(): string {
        return this.component.tagName != null ? this.component.tagName.toLowerCase() : 'null';
    }
    public set label(value: string) {
    }
    public get name(): string {
        return this.label;
    }
    public get key(): string {
        let id: number = this.component.id;
        return id.toString();
    }
    public set key(value: string) {
    }
    public get icon(): string {
        return 'article';
    }
    public set icon(value: string) {
    }

    public get data(): ComponentTreeNode {
        return this;
    }
    public set data(value: ComponentTreeNode) {
    }

    public get level(): number {
        return this._level;
    }
    public get children(): TreeNode<ComponentTreeNode>[] {
        return this.childNodes;
    }
    public set children(value: TreeNode<ComponentTreeNode>[]) {
    }

    public get parent(): TreeNode<ComponentTreeNode> {
        return this._parent;
    }
    public set parent(value: TreeNode<ComponentTreeNode>) {
    }

    public get leaf(): boolean {
        return (this.childNodes == null) || (this.childNodes.length == 0);
    }
    public set leaf(value: boolean) {
    }
    public get expanded(): boolean {
        return true;
    }
    public set expanded(value: boolean) {
    }
    public get selectable(): boolean {
        return true;
    }
    public set selectable(value: boolean) {
    }
    public get draggable(): boolean {
        return false;
    }
    public set draggable(value: boolean) {
    }
    public get droppable(): boolean {
        return false;
    }
    public set droppable(value: boolean) {
    }

    // Following are TreeNode attributes that are not yet implemented.
    /*
    expandedIcon?: any;
    collapsedIcon?: any;
    type?: string;
    partialSelected?: boolean;
    styleClass?: string;
    */
}

export class FlatComponentTreeNode {
    // Properties.
    private childNodes: FlatComponentTreeNode[] = [];

    // Constructor.
    public constructor(private component: ITestableComponent, private _parent: FlatComponentTreeNode, private _level: number) { }

    // Child management.
    public addChild(childNode: FlatComponentTreeNode): void {
        this.childNodes.push(childNode);
    }

    // Getters.
    public get Component(): ITestableComponent {
        return this.component;
    }
    public get name(): string {
        return this.component.tagName != null ? this.component.tagName.toLowerCase() : 'null';
    }
    public get children(): FlatComponentTreeNode[] {
        return this.childNodes;
    }
    public get level(): number {
        return this._level;
    }
    public get isExpandable(): boolean {
        return (this.childNodes != null) && (this.childNodes.length > 0);
    }
}
