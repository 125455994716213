<div class="file-upload-main">
    <div class="container confirm-main drag-drop-instructions" appDragDrop (fileDropped)="onFileDropped($event)" (fileEntered)="onFileEntered($event)">
        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
        <span class="material-icons upload-icon">
            upload_file
        </span>

        <div *ngIf="!fileEntered;else file_entered">
            <h3>Drag and drop file/s here</h3>
            <h3>or</h3>
            <label for="fileDropRef">Browse</label>
        </div>

        <ng-template #file_entered>
            <h3>Drop file/s</h3>
        </ng-template>
    </div>
     
    <div class="files-list">
        <div class="single-file" *ngFor="let file of files; let i = index">
            <i class="upload-icon added-icon center-icon" [appIcon]="file" appIconItemType="{{this.FileType}}"></i>
            <div class="info">
                <h4 class="name">
                    {{ file?.name }}
                </h4>
                <p class="size">
                    {{ formatBytes(file?.size) }}
                </p>
                <mat-progress-bar *ngIf="this.Progress"
                                  mode="determinate"
                                  [value]="this.GetProgressFor(file, i)"></mat-progress-bar>
            </div>

            <div class="delete" (click)="deleteFile(i)">
                <span class="material-icons">
                    clear
                </span>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="confirm-buttons submit-cancel-buttons ">
        <button mat-raised-button color="primary" cdkFocusInitial (click)="this.uploadFilesOrCloseDialog()">Upload</button>
        <span>{{currentFileUploaded}}</span>
        <button mat-button mat-dialog-close>Cancel</button>
    </div>
</div>
