
    <!-- Display the selected node, if any. -->
    <div class="selected-node-div">
        <!-- The selected node's elements.-->
        <div class="selected-node-elements-div">
            <!-- Message if no component is selected. -->
            <p *ngIf="!this.ComponentSelected">A component's nodes display here.</p>

            <!-- List of a component's elements. -->
            <!--
            <ng-container *ngIf="this.ComponentSelected && this.ComponentHasElements">
                <mat-tab-group [selectedIndex]="0"
                               (selectedIndexChange)="this.elementTypeIndexChanged($event)"
                               class="element-types-mat-tab-group">
                    <mat-tab *ngFor="let elementType of this.ComponentElementTypes"
                             label="{{this.getElementTypeTitle(elementType)}}">
                        <mat-selection-list #selectedListElement
                                            [multiple]="false">
                            <ng-container *ngFor="let elementInfo of this.getComponentElementsOfType(elementType)">
                                <mat-list-option [value]="this.getElementTitle(elementInfo)"
                                                 matTooltip="{{this.getElementTooltip(elementInfo)}}"
                                                 [matTooltipPosition]="this.TooltipPosition"
                                                 (click)="this.onSelectedElementClicked(elementType, elementInfo)"
                                                 (dblclick)="this.onSelectedElementDoubleClicked(elementType, elementInfo)">
                                    {{this.getElementTitle(elementInfo)}}
                                </mat-list-option>
                            </ng-container>
                        </mat-selection-list>

                    </mat-tab>
                </mat-tab-group>
            </ng-container>
                -->
            <ng-container *ngIf="this.ComponentSelected && this.ComponentHasElements">
                <mat-tab-group [selectedIndex]="0"
                               (selectedIndexChange)="this.elementTypeIndexChanged($event)"
                               class="element-types-mat-tab-group">
                    <mat-tab *ngFor="let displayTabName of this.ComponentDisplayTabNames"
                             label="{{displayTabName}}">
                        <mat-selection-list #selectedListElement
                                            [multiple]="false">
                            <ng-container *ngFor="let elementInfo of this.getComponentElementsForTab(displayTabName)">
                                <mat-list-option [value]="this.getElementTitle(elementInfo)"
                                                 matTooltip="{{this.getElementTooltip(elementInfo)}}"
                                                 [matTooltipPosition]="this.TooltipPosition"
                                                 (click)="this.onElementInfoClicked(elementInfo)"
                                                 (dblclick)="this.onElementInfoDoubleClicked(elementInfo)">
                                    {{this.getElementTitle(elementInfo)}}
                                </mat-list-option>
                            </ng-container>
                        </mat-selection-list>
                    </mat-tab>
                </mat-tab-group>
            </ng-container>

            <!-- Message for a selected component with no testable elements. -->
            <p *ngIf="this.ComponentSelected && (!this.ComponentHasElements)">{{this.ComponentNoElementsMessage}}</p>
        </div>
        <!-- Selected node element, if any.-->
        <div class="selected-node-element-div">

            <p *ngIf="!this.ElementSelected">A selected element displays here.</p>
            <!--<p>Selected element goes here.</p>-->
           <app-selected-node-element #selectedNodeElementComponent
                                      [selectedComponentInfo]="this.SelectedElementInfo"></app-selected-node-element>
        </div>
    </div>

