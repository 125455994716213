import { Type } from 'class-transformer';

import { WorkflowStateCountData } from './workflow-state-count-data.model';

export class WorkflowStateResponseData {
    public workflowId: number;

    public includeFormInstances: boolean;
    public includeFolders: boolean;

    // Results.
    @Type(() => WorkflowStateCountData)
    public workflowStateCounts: WorkflowStateCountData[];
}
