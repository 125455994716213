<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<div>
    <!-- //VNEXT-645: KLW - Adding class flex-row-top-right to correct the tool tip styling -->
    <div *ngIf="this.Mode === 'design'" class="flex-row-top-right">
        <div class="fill-remaining-space">
            <!-- If there are instructions, display label first, then instructions then field -->
            <ng-container *ngIf="this.HasInstructions">
                <div class="design-mode-field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </ng-container>

            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label *ngIf="this.ShowLabel && !this.HasInstructions"
                           class="design-mode-field-label">
                    {{this.DisplayName}} <span *ngIf="this.FormField.required"
                                               style="color:red">*</span>
                </mat-label>
                <textarea matInput placeholder="{{this.PlaceholderText}}" rows="{{this.FormField.displayRows}}"
                          disabled>{{this.DefaultValue}}</textarea>
            </mat-form-field>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>
    <!-- //VNEXT-645: KLW - Adding class flex-row-top-right to correct the tool tip styling -->
    <div *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')" class="flex-row-top-right">
        <div class="fill-remaining-space">
            <ng-container *ngIf="(this.ControlType === 'Reactive Forms')">

                <ng-container *ngIf="this.FormControl !== null">
                    <!-- If there are instructions, display label first, then instructions then field -->
                    <ng-container *ngIf="this.HasInstructions">
                        <div class="field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                    </ng-container>

                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid"
                            class="design-mode-field-label">{{this.DisplayName}} <span *ngIf="this.FormField.required"
                                style="color:red">*</span></mat-label>
                        <ng-container *ngIf="! this.ReadOnly">
                            <textarea matInput placeholder="{{this.PlaceholderText}}" [formControl]="this.FormControl"
                                rows="{{this.FormField.displayRows}}" (blur)="this.handleOnBlur()"
                                (focus)="this.handleOnFocus($event)"></textarea>
                        </ng-container>

                        <ng-container *ngIf="this.ReadOnly">
                            <textarea matInput readonly placeholder="{{this.PlaceholderText}}"
                                [formControl]="this.FormControl" rows="{{this.FormField.displayRows}}"
                                (blur)="this.handleOnBlur()" (focus)="this.handleOnFocus($event)"></textarea>
                        </ng-container>
                    </mat-form-field>
                </ng-container>

            </ng-container>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>
</div>
