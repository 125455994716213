import { MatInput } from '@angular/material/input';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { ServiceClassInfo } from '../../services/service-class-catalogue.service';
import { HtmlElementTypeNames } from './html-element-type-names.model';
import { HtmlElementInfo } from './html-element-info.model';
import { ComponentMethodsMetadata, ComponentMethodMetadata } from './component-methods-metadata.model';
import { ComponentMethodsService } from './component-methods.service'
import { ElementTypeMetadata } from './element-type-metadata.model';
import { HtmlElementTypeService } from '../../services/html-element-type.service';
import { ScriptableComponentStatus } from '../../enums/component-scripting/scriptable-component-status.enum';

export class ServiceAsTestableComponent implements ITestableComponent {
    // Static data.
    private static nextServiceId: number = 1;

    // Properties.
    private _id: number;

    // Constructor.
    public constructor(public serviceInfo: ServiceClassInfo, private htmlElementTypeService: HtmlElementTypeService) {
        this._id = ServiceAsTestableComponent.nextServiceId++;
    }

    // Implement ITestableComponent methods.
    public get tagName(): string {
        let name: string = this.serviceInfo.serviceInstance.constructor.name;
        return name;
    }
    public get id(): number {
        return this._id;
    }

    public get InitializationStatus(): ScriptableComponentStatus {
        return ScriptableComponentStatus.InitializationComplete;
    }

    public get elementTypes(): string[] {
        return [HtmlElementTypeNames.componentMethods_metadataKey];
    }
    public getElementsOfType(elementType: string): HtmlElementInfo[] {
        let elements: HtmlElementInfo[] = [];

        let serviceClassName: string = this.serviceInfo.serviceInstance.constructor.name;
        let componentMethodsMetadata: ComponentMethodsMetadata = ComponentMethodsService.getComponentMethodsMetadataFor(serviceClassName);

        if ((componentMethodsMetadata != null) && (componentMethodsMetadata.methodsMetadata.length > 0)) {
            let methodsElementTypeMetadata: ElementTypeMetadata = this.htmlElementTypeService.getMetadataForElementType(HtmlElementTypeNames.componentMethods_metadataKey);

            for (let index: number = 0; index < componentMethodsMetadata.methodsMetadata.length; index++) {
                let methodMetadata: ComponentMethodMetadata = componentMethodsMetadata.methodsMetadata[index];

                let elementInfo: HtmlElementInfo = new HtmlElementInfo(methodsElementTypeMetadata, methodMetadata, HtmlElementTypeNames.componentMethods_metadataKey, methodMetadata.methodName);
                elements.push(elementInfo);
            }
        }

        return elements;
    }

    public getMatInputWithElement(element: object): MatInput {
        return null;
    }
}
