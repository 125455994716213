import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { MenuService } from '../services/menu.service';
import { Menu } from '../models/navigation/menu.model';
import { Observable } from 'rxjs';

@Injectable()
export class MenuResolver implements Resolve<Menu> {
    constructor(private menuService: MenuService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Menu> {
        var menu = route.data["menu"];

        return this.menuService.get(menu).then(response => {
            return response;
        });
    }
}
