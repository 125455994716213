
export class ExcelFolderFormDataExportRequest {
    public folderId: number;
    public selectedFormNames: string[];

    public constructor(folderId: number, selectedFormNames: string[]) {
        this.folderId = folderId;
        this.selectedFormNames = selectedFormNames;
    }
}
