import { IViewModel } from '../../interfaces/view-model.interface';
import { FormInstance } from '../site-content/form-instance.model';
import { FolderFormTemplate } from '../folder-form-template.model';
import { WorkflowTransition } from '../workflow-transition.model';
import { Workflow } from '../site-content/workflow.model';
import { IListItem } from '../../interfaces/ilist-item.interface';
import { ItemTypeEnum } from '../../enums/item-type.enum';
import { IHasIdAndNameAndWorkflowId } from '../../interfaces/has-id-and-name.interface';
import { IListItemBase } from '../ilist-item-base.model';
import { IconFormatEnum } from '../../enums/icon-format.enum';
import { Form } from '../form-builder/form.model';

export class Folder extends IListItemBase implements IViewModel, IListItem, IHasIdAndNameAndWorkflowId {
    // Primitive properties.
    public id: number = 0;
    public dataCollectionId: number; 
    public parentFolderId: number;
    public workflowId: number;
    //public name: string;
    public folderOrder: number;
    public description: string;
    public helpTextFormat: string;
    public helpText: string;

    public readonly statuses: any[];
    public readonly siteWorkflows: Workflow[];
    public readonly siteForms: Form[];
    public currentWorkflowState: string;
    public selectedWorkflowTransition: string;

    public canView: boolean; 
    public canEdit: boolean;
    public canDelete: boolean;
    public canGrant: boolean;
    public canTransition: boolean;
    public canBuildFolders: boolean;
    public canBuildFormInstances: boolean;
    public canPerformBulkWorkflowUpdate: boolean;
    public isTransparentFolder: boolean;

    //simplified permissions model
    public canCreateFolder: boolean;
    public canDeleteFolder: boolean;
    public canCreateForm: boolean;
    public canDeleteForm: boolean;
    public canGrantPermission: boolean;
    public canManageRoleMembers: boolean;

    public hideExportToExcel: boolean;
    public hideUpload: boolean;
    public hideDownload: boolean;
    public hidePrintForm: boolean;

    public isFavorite: boolean = false; 
    public favoriteTitle: string;
    public roleMembersAreInherited: boolean; // added 4/28/2021
    public roleGroupName: string;

    public magicStructureDefinitionId: number;
    public magicFolderId: number;

    // Collection properties.
    public readonly workflowStateTransitions: WorkflowTransition[];

    public readonly childFolders: Folder[] = []; // 03-04-2020 note:  this field is not treated as read only in some code. Change?
    public readonly ancestorFolders: Folder[] = [];
    public folderFormTemplate: FolderFormTemplate;

    private arrFormInstances: FormInstance[] = [];
    public get formInstances(): FormInstance[] {
        return (this.arrFormInstances);
    }
    public set formInstances(formInstancesParam: FormInstance[]) {
        this.arrFormInstances = formInstancesParam;
    }

    public childCount: number;
    public childCountTitle: string;

    // 09/08/2021 pharv - for pagination
    public readonly totalChildFolderCount: number; 
    public readonly totalFormInstanceCount: number; 

    //history
    public createdBy: string;
    public createdByUserName: string;
    public createdDate: Date;
    public modifiedBy: string;
    public modifiedByUserName: string;
    public modifiedDate: Date;

    // Constructor.
    public constructor(sourceObject: Folder = null) {
        super();

        if (sourceObject != null) {
            // Basic properties.
            if (sourceObject.id != null) {
                this.id = sourceObject.id;
            } else {
                this.id = 0;
            }
            if (sourceObject.dataCollectionId != null) {
                this.dataCollectionId = sourceObject.dataCollectionId;
            } else {
                this.dataCollectionId = 0;
            }
            if (sourceObject.parentFolderId != null) {
                this.parentFolderId = sourceObject.parentFolderId;
            } else {
                this.parentFolderId = 0;
            }

            // Permission properties.
            this.canView = sourceObject.canView;
            this.canEdit = sourceObject.canEdit;
            this.canDelete = sourceObject.canDelete;
            this.canGrant = sourceObject.canGrant;
            this.canTransition = sourceObject.canTransition;
            this.canBuildFolders = sourceObject.canBuildFolders;
            this.canBuildFormInstances = sourceObject.canBuildFormInstances;
            this.isTransparentFolder = sourceObject.isTransparentFolder;

            //simplified permissions model
            this.canCreateFolder = sourceObject.canCreateFolder;
            this.canDeleteFolder = sourceObject.canDeleteFolder;
            this.canCreateForm = sourceObject.canCreateForm;
            this.canDeleteForm = sourceObject.canDeleteForm;
            this.canGrantPermission = sourceObject.canGrantPermission;
            this.canManageRoleMembers = sourceObject.canManageRoleMembers;

            // User favorite properties.
            this.isFavorite = sourceObject.isFavorite;
            this.favoriteTitle = sourceObject.favoriteTitle;

            // Role group properties.
            this.roleMembersAreInherited = sourceObject.roleMembersAreInherited;
            this.roleGroupName = sourceObject.roleGroupName;

            // Magic structure properties.
            this.magicStructureDefinitionId = sourceObject.magicStructureDefinitionId;
            this.magicFolderId = sourceObject.magicFolderId;

            // Additional properties.
            if (sourceObject.statuses != null) {
                this.statuses = sourceObject.statuses;
            } else {
                this.statuses = null;
            }
            if (sourceObject.siteWorkflows != null) {
                this.siteWorkflows = sourceObject.siteWorkflows;
            } else {
                this.siteWorkflows = null;
            }

            if (sourceObject.workflowStateTransitions != null) {
                this.workflowStateTransitions = sourceObject.workflowStateTransitions;
            } else {
                this.workflowStateTransitions = null;
            }

            if (sourceObject.childFolders != null) {
                this.childFolders = sourceObject.childFolders;
            } else {
                this.childFolders = [];
            }
            if (sourceObject.ancestorFolders != null) {
                this.ancestorFolders = sourceObject.ancestorFolders;
            } else {
                this.ancestorFolders = [];
            }
        }

        return;
    }
    setIcon(iconParam: string): void {
        throw new Error('Method not implemented.');
    }

    // Load from de-serialized network data.
    public static loadFromDeserializedNetworkData(data: Folder): Folder {
        let folder: Folder = new Folder(data);

        folder.loadFromDeserializedNetworkData(data);

        return (folder);
    }

    private loadFromDeserializedNetworkData(data: Folder): void {
        // Copy primitive attributes.
        this.id = data.id;

        this.dataCollectionId = data.dataCollectionId;
        this.parentFolderId = data.parentFolderId;
        this.workflowId = data.workflowId;

        this.name = data.name;

        this.folderOrder = data.folderOrder;

        this.description = data.description;
        this.helpTextFormat = data.helpTextFormat;
        this.helpText = data.helpText;

        this.currentWorkflowState = data.currentWorkflowState;
        this.selectedWorkflowTransition = data.selectedWorkflowTransition;

        this.canView = data.canView;
        this.canEdit = data.canEdit;
        this.canDelete = data.canDelete;
        this.canGrant = data.canGrant;
        this.canTransition = data.canTransition;
        this.canBuildFolders = data.canBuildFolders;
        this.canBuildFormInstances = data.canBuildFormInstances;
        this.isTransparentFolder = data.isTransparentFolder;

        //simplified permissions model
        this.canCreateFolder = data.canCreateFolder;
        this.canCreateForm = data.canCreateForm;

        this.isFavorite = data.isFavorite;
        this.favoriteTitle = data.favoriteTitle;

        // Role group properties.
        this.roleMembersAreInherited = data.roleMembersAreInherited;
        this.roleGroupName = data.roleGroupName;

        // Magic structure properties.
        this.magicStructureDefinitionId = data.magicStructureDefinitionId;
        this.magicFolderId = data.magicFolderId;

        // Note:  the following fields will be set in my constructor:
        //
        //            statuses;
        //            siteWorkflows;
        //            workflowStateTransitions;
        //            childFolders;
        //            ancestorFolders.

        this.formInstances = data.formInstances;

        //history
        this.createdBy = data.createdBy;
        this.createdByUserName = data.createdByUserName;
        this.createdDate = data.createdDate;
        this.modifiedBy = data.modifiedBy;
        this.modifiedByUserName = data.modifiedByUserName;
        this.modifiedDate = data.modifiedDate;

        return;
    }

    // IListItem methods.
    public getId(): number {
        return (this.id);
    }
    public setId(idParam: number): void {
        this.id = idParam;

        return;
    }

    public getOriginalVersionId(): number {
        return -1;
    }

    public getTitle(): string {
        let title: string =
            (this.description != null ? `Click to view ${this.name} (${this.description})` : super.getTitle());

        return (title);
    }

    public getIsTransparentFolder(): boolean {
        return this.isTransparentFolder;
    }

    public isMagicStructure(): boolean {
        return this.magicStructureDefinitionId != null;
    }

    public userCanDelete(): boolean {
        return (this.canDelete);
    }

    public getStatus(): string {
        return this.currentWorkflowState;
    }

    public getExtraInfo(): string {
        return "";
    }

    public getValue(property: string): string {
        property = property.toLowerCase();
        if (property == 'status') {
            return this.getStatus();
        } else {
            return '';
        }
    }

    //TEAMS-424: KLW - set the object type
    public static readonly TypeName: string = ItemTypeEnum.FOLDER;
    public getType(): string {
        //return (this.magicStructureDefinitionId != null ? ItemTypeEnum.MAGIC_FOLDER : Folder.TypeName);
        return Folder.TypeName;
    } 

    public getPosition(): number {
        return (this.folderOrder);
    }

    public canBeDeleted(): boolean { // Note:  this will have to be fixed to use
                                     //        a value returned by the server.
        return (true);
    }

    public getIconType(): IconFormatEnum {
        // Note:  will enable the following conditional line after reviewing the new magic folder icon with others.
        //return (this.magicStructureDefinitionId != null ? IconFormatEnum.SVG_ICON : IconFormatEnum.MAT_ICON);
        return IconFormatEnum.MAT_ICON;
    }
    public getIcon(): string {
        // Note:  will enable the following conditional line after reviewing the new magic folder icon with others.
        //return (this.magicStructureDefinitionId != null ? 'magic-folder' : 'folder'); // Returns the default icon for a site/data collection.
        return 'folder';
    }

    public getUniqueId(): string { // For interface IListItem
        let uniqueId: string = `${this.id}-${this.getType()}`;

        return (uniqueId);
    }

    public getChildCount(): number {
        throw new Error('Method not implemented.');
    }

    // If modifiedByUserName is set, return that
    public getModifiedBy(): string {
        return this.modifiedByUserName != '' ? this.modifiedByUserName : this.modifiedBy;
    }
    public getModifiedDate(): Date {
        return this.modifiedDate;
    }

    public update(obj: any, type: string, icon?: string, position?: number): void {
        // 03-19-2021 note:  restoring the implementation of this code.
        this.id = obj.getId();

        if ((obj.name != null) && (obj.name != this.name)) {
            this.name = obj.name;
        }
        this.modifiedBy = obj.modifiedBy;
        this.modifiedDate = obj.modifiedDate;
        this.currentWorkflowState = obj.currentWorkflowState;
        this.description = obj.description;

        return;
    }

    public findAndUpdateFrom(items, obj: any): void {
        throw ('Folder.findAndUpdateFrom():  I am not yet implemented ... do I need to be implemented?');
    }

    // Implement interface IHasIdAndName methods.
    public getName(): string {
        return (this.name);
    }
    public setName(nameParam: string): void {
        this.name = nameParam;
    }

    public typeName(): string {
        return (Folder.TypeName);
    }

    hasDescriptionField(): boolean {
        return true;
    }
    getDescription(): string {
        return this.description;
    }
    setDescription(val: string) {
        this.description = val;
    }    

    // Define HasIdAndName interface methods that have no meaning for this class.
    public getChangeWorkflowStateDialogTitle(): string { return null; }
    public setChangeWorkflowStateDialogTitle(value: string): void { }

    public getTransitionConfirmationDialogMessage(): string { return null; }
    public setTransitionConfirmationDialogMessage(value: string): void { }

    public getTakeUserToSiteHomePageAfterTransitionApplied(): boolean { return false; }
    public setTakeUserToSiteHomePageAfterTransitionApplied(value: boolean): void { }

    public getPropertiesDrawerTitle(): string {
        return null;
    }
    // End HasIdAndName interface methods that have no meaning for this class.

    public getParentId(): number {
        return this.parentFolderId;
    }

    public getWorkflowId(): number {
        return this.workflowId;
    }

    public get HideExportToExcel(): boolean {
        return (this.hideExportToExcel || this.HideExportToExcelIsInherited);
    }

    public get HideExportToExcelIsInherited(): boolean {
        return this.ancestorFolders.filter(x => x.hideExportToExcel).length > 0;
    }

    public get HideUpload(): boolean {
        return (this.hideUpload || this.HideUploadIsInherited);
    }

    public get HideUploadIsInherited(): boolean {
        return this.ancestorFolders.filter(x => x.hideUpload).length > 0;
    }

    public get HideDownload(): boolean {
        return (this.hideDownload || this.HideDownloadIsInherited);
    }

    public get HideDownloadIsInherited(): boolean {
        return this.ancestorFolders.filter(x => x.hideDownload).length > 0;
    }

    public get HidePrintForm(): boolean {
        return (this.hidePrintForm || this.HidePrintFormIsInherited);
    }

    public get HidePrintFormIsInherited(): boolean {
        return this.ancestorFolders.filter(x => x.hidePrintForm).length > 0;
    }
}
