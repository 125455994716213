import { ScriptableBaseComponent } from '../../components/scriptable-base/scriptable-base.component';

export interface IEnumReference {
    enumName: string;

    hasValues(values: string[]): IEnumReference;
    withPrettyNames(prettyNames: string[]): IEnumReference;
}
export class EnumReference implements IEnumReference {
    public constructor(public enumName: string, private component: ScriptableBaseComponent) { }

    public hasValues(values: string[]): IEnumReference {
        this.component.enumHasValues(this.enumName, values);
        return this;
    }
    public withPrettyNames(prettyNames: string[]): IEnumReference {
        this.component.enumHasPrettyNames(this.enumName, prettyNames);
        return this;
    }

}
