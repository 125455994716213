import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { plainToClass } from "class-transformer";

import { CollectApiServiceBase } from './collect-api-base.service';
import { AsyncJob } from '../models/async-job.model';
import { ProgressIndicatorService } from './progress-indicator.service';

@Injectable()
export class AsyncJobService extends CollectApiServiceBase<AsyncJob> {
    // Properties.
    //private exportDataTimeout: number = 0;
    //private exportDataTimeout: NodeJS.Timeout = null;

    // Constructor.
    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'asyncjob', AsyncJob)
    }

    public getJob(jobKey: string): Promise<AsyncJob> {
        var url = `${this.url}/${this.endpoint}/${jobKey}`;

        return this.http.get<AsyncJob>(url)
            .toPromise()
            .then(response => {
                return this.formatResponse(response);
            })
            .catch(this.handleError);
    }

    public updateJob(asyncJob: AsyncJob): Promise<AsyncJob> {
        var url = `${this.url}/${this.endpoint}/update`;

        return this.http.put<AsyncJob>(url, asyncJob)
            .toPromise()
            .then(response => {
                return this.formatResponse(response);
                //let result = plainToClass(AsyncJob, response);
                //return result;
            });
    }

    public monitorJobUntilCompletion(asyncJob: AsyncJob, timeoutDuration: number, callbackArrowFunction: any): void {
        //let exportDataTimeout: number = 0;
        let jobCompleted: boolean = false;

        let exportDataTimeout = setInterval(() => {
            this.getJob(asyncJob.jobKey).then(updatedAsyncJob => {
                if ((!jobCompleted) && (updatedAsyncJob.status == AsyncJob.STATUS_COMPLETE)) {
                    jobCompleted = true;

                    callbackArrowFunction(asyncJob, true);
                } else if ((!jobCompleted) && (updatedAsyncJob.status == AsyncJob.STATUS_COMPLETE_ERROR)) {
                    jobCompleted = true;                    
                }

                if (jobCompleted)
                    //clearInterval(this.exportDataTimeout);
                    clearInterval(exportDataTimeout);
            });
        }, timeoutDuration);
    }
}
