<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<!--*ngIf="(this.Mode === 'design') || (this.Mode === 'preview') || (this.Mode === 'instance')"-->
<div *ngIf="this.ReadyToRender" class="design-mode-outer-div">
    <div *ngIf="this.UsesMainFieldLabel && !FieldIsInGrid" class="{{this.Mode =='design' ? 'design-mode-field-label' : 'field-label'}}">
        {{this.DisplayName}}
        <span *ngIf="this.FormField.required" style="color:red">*</span>
    </div>
    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>

    <!-- If we do not have a configuration, give the user a hint. -->
    <div *ngIf="this.DisplayConfigTip"
         class="no-config-div">
        <p class="user-tip-p">Use properties to load options from a CSV file</p>
    </div>

    <!-- If we have a configuration, display it. -->
    <div *ngIf="(!this.DisplayConfigTip) && (this.DropDownHeaders !== null)"
         [ngClass]="this.IsHorizontal ? 'flex-direction-row' : 'flex-direction-column'">
        <div *ngFor="let dropDownHeader of this.DropDownHeaders; let i=index"
             [ngClass]="this.IsHorizontalAndNotFirstSelection(i) ? 'flex-row-top-right flex-grow-one margin-left-10px' : 'flex-row-top-right flex-grow-one'">
            <div class="fill-remaining-space">
                <mat-form-field appearance="outline"
                                class="dropdown-mat-form-field"
                                style="{{this.AdditionalMatFormFieldStyles}}">
                    <mat-label *ngIf=" !FieldIsInGrid">{{dropDownHeader}}</mat-label>

                    <mat-select [disabled]="this.ReadOnly || this.HeaderSelectionDisabled(i + this.HeaderIndexOffset, dropDownHeader)"
                                [(ngModel)]="this.DropDownValues[i + this.HeaderIndexOffset]"
                                (ngModelChange)="this.dropDownValueChanged(i + this.HeaderIndexOffset)"
                                (selectionChange)="this.handleOnModelChange()">
                        <mat-option title="Select {{dropDownHeader}}" [value]="0">
                            -- Select {{dropDownHeader}}--
                        </mat-option>

                        <mat-option *ngFor="let hshOption of this.DropDownOptionsForHeader(i + this.HeaderIndexOffset)"
                                    title="{{this.getSelectedValue(hshOption.ValueText, hshOption.Index, i)}}"
                                    [value]="hshOption.Index">
                            {{this.getSelectedValue(hshOption.ValueText, hshOption.Index, i)}}
                        </mat-option>

                    </mat-select>
                </mat-form-field>
            </div>
            <ng-container *ngIf="i == 0">
                <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
            </ng-container>
        </div>
    </div>

    <!-- If we have an error, display it. -->
    <div *ngIf="this.Error !== null"
         class="error-div">
        <span>{{this.Error}}</span>
    </div>

</div>
