import { DecimalFieldDefLogic } from './decimal-field-def-logic';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../form-instance-element.model';
import { IGridRow, IValuesByFieldName } from '../../../interfaces/grid-row.interface';
import { FormField } from '../form-field.model';
import { FieldDefinitionService } from '../../../services/field-definition.service';
import { FieldDefinition } from '../../../models/form-builder/field-definition.model';
import { FormulaFormFieldComponent } from '../../../components/form-fields/formula-form-field/formula-form-field.component';
import { FieldNameHashes } from '../../../components/form-fields/formula-form-field/field-name-hashes';
import { FormulaEvaluationHelper } from '../../../components/form-fields/formula-form-field/formula-evaluation-helper';
import { FormulaEvaluator } from '../../../components/form-fields/formula-form-field/formula-evaluator';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { AppInjector } from '../../../../app.module';
import { GridRowDef } from '../../../models/grid/grid-row.model';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';

import * as math from 'mathjs';
import { TextFieldDefinitionLogicBase } from './text-logic-base';

export class UrlBuilderFieldDefLogic extends TextFieldDefinitionLogicBase {
    // Constructor.
    public constructor(private fieldDefinitionServce: FieldDefinitionService) {
        super();
    }

    public whenInGridShowAs(): string {
        return 'BUTTON';
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[]): string {
        let displayValue: string = '';

        if (gridRowColumnDefs != null) {
            let hshFieldValues: IValuesByFieldName = gridRow.getValuesByFieldName(this.fieldDefinitionServce);
            let formulaEvaluationHelper = new FormulaEvaluationHelper().init(formFieldParam, gridRow, gridRowColumnDefs);
            let url = FormulaEvaluator.evaluateUrl(formulaEvaluationHelper, formFieldParam.transientUrlBuilderUrlTemplate, hshFieldValues);
            displayValue = url;
        }

        return displayValue;
    }

    public requiresFieldValueUpdate(): boolean {
        return true;
    }

    private static displayNameToVariableName(formFieldParam: FormField): string {
        let variableName: string = formFieldParam.displayName.toLowerCase().replace(/ /g, '_');

        return (variableName);
    }

    public filterType(): string {
        return 'text';
    }

    public isFilterable(): boolean {
        return false;
    }

    // this field displays as a button in a grid cell, and we don't want clicking the button to place the grid row into edit mode
    public doNotTriggerGridRowEditFor(): boolean {
        return true;
    }
}
