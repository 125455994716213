export class HtmlElementTypeNames {
    // Constants.
    public static readonly links_metadataKey: string = 'links';
    public static readonly buttons_metadataKey: string = 'buttons';
    public static readonly buttonsWithMatIcons_metadataKey: string = 'buttonsWithMatIcons';
    public static readonly matListItems_metadataKey: string = 'matlistitems';
    public static readonly matIcons_metadataKey: string = 'maticons';
    public static readonly matSelect_metadataKey: string = 'matselect';
    public static readonly input_metadataKey: string = 'input';
    public static readonly inputs_metadataKey: string = 'inputs';
    public static readonly matCheckBox_metadataKey: string = 'matcheckbox';
    public static readonly matList_metadataKey: string = 'matlist';

    public static readonly componentMethods_metadataKey: string = 'class_method';

    private constructor() { }
}

export class HtmlMetadataTagNames {
    public static readonly elementName_dataAttribute = 'data-display-name';
}
