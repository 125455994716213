import { Type } from 'class-transformer';

import { WorkflowState } from '../workflow-state.model';
import { WorkflowTransition } from '../workflow-transition.model';
import { WorkflowPermissionBase } from '../workflow-permission-base.model';
import { IViewModel } from '../../interfaces/view-model.interface';
import { IHasIdAndName } from '../../interfaces/has-id-and-name.interface';
import { ICloneAndCopy } from '../../interfaces/clone-and-copy';
import { IListItem } from '../../interfaces/ilist-item.interface';
import { ItemTypeEnum } from '../../enums/item-type.enum';
import { IListItemBase } from '../ilist-item-base.model';
import { IconFormatEnum } from '../../enums/icon-format.enum';

// Define an internally used type.
interface IWorkflowRoles {
    [roleName: string]: string;
}

// Define class Workflow.
export class Workflow extends IListItemBase implements IViewModel, IHasIdAndName, ICloneAndCopy, IListItem {
    // Constructor.
    public constructor() {
        super();
    }

    // Key.
    public id: number = 0;
    public getId(): number {
        return (this.id);
    }

    public getOriginalVersionId(): number {
        return -1;
    }

    // Implement IHasIdAndName methods.
    public getName(): string {
        return (this.name);
    }
    public setName(nameParam: string): void {
        this.name = nameParam;
    }

    public description: string; // Added 04-16-2022.

    public static readonly TypeName: string = 'Workflow';
    public typeName(): string {
        return (Workflow.TypeName);
    }

    public hasDescriptionField(): boolean {
        return true;
    }
    public getDescription(): string {
        return this.description;
    }
    public setDescription(val: string) {
        this.description = val;
    }

    // Define HasIdAndName interface methods that have no meaning for this class.
    public getChangeWorkflowStateDialogTitle(): string { return null; }
    public setChangeWorkflowStateDialogTitle(value: string): void { }

    public getTransitionConfirmationDialogMessage(): string { return null; }
    public setTransitionConfirmationDialogMessage(value: string): void { }

    public getTakeUserToSiteHomePageAfterTransitionApplied(): boolean { return false; }
    public setTakeUserToSiteHomePageAfterTransitionApplied(value: boolean): void { }

    public getPropertiesDrawerTitle(): string {
        return null;
    }
    // End HasIdAndName interface methods that have no meaning for this class.

    // Primitives.
    //public name: string;

    public dataCollectionId: number;

    // Flags.
    public isSystemWorkflow: boolean;

    public isDefaultFormWorkflow: boolean;
    public isDefaultFolderWorkflow: boolean;
    public isFormWorkflow: boolean;
    public isFolderWorkflow: boolean;
    public currentUserCanBuildWorkflows: boolean;

    // 04-19-2021 note:  added the following property.
    public wasDeletedFromUI: boolean;

    // 09-29-2021 note: added the following three properties
    //                  related to moving objects from one
    //                  workflow state to another.
    public needToMoveObjectsToDifferentWorkflowState: boolean = false;
    public stateToMoveObjectsFromState: WorkflowState = null;
    public stateToMoveObjectsToState: WorkflowState = null;
    public NumFormInstancesMoved: number = 0;
    public NumFoldersMoved: number = 0;

    //history
    public createdBy: string;
    public createdByUserName: string;
    public createdDate: Date;
    public modifiedBy: string;
    public modifiedByUserName: string;
    public modifiedDate: Date;

    // Collections.
    @Type(() => WorkflowState)
    public states: WorkflowState[];
    @Type(() => WorkflowTransition)
    public transitions: WorkflowTransition[];

    // pjh - 2/5/2020 - quick fix to allow new workflow to be built. 
    public get CurrentUserCanBuildWorkflows(): boolean {
        return this.id == 0 || this.currentUserCanBuildWorkflows;
    }

    // Define client-only instance data.
    public hasGeneratedName: boolean = false;;

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let clone: Workflow = new Workflow();

        // Copy primitives.
        clone.id = this.id;
        clone.name = this.name;
        clone.description = this.description;
        clone.dataCollectionId = this.dataCollectionId;

        clone.modifiedBy = this.modifiedBy;
        clone.modifiedDate = this.modifiedDate;

        clone.isSystemWorkflow = this.isSystemWorkflow;
        clone.isDefaultFormWorkflow = this.isDefaultFolderWorkflow;
        clone.isDefaultFolderWorkflow = this.isDefaultFolderWorkflow;
        clone.isFormWorkflow = this.isFormWorkflow;
        clone.isFolderWorkflow = this.isFolderWorkflow;
        clone.currentUserCanBuildWorkflows = this.currentUserCanBuildWorkflows;

        // Copy states and transitions.
        clone.states = [];
        if (this.states != null) {
            for (let iState: number = 0; iState < this.states.length; iState++) {
                let state: WorkflowState = this.states[iState];
                let stateClone: WorkflowState = <WorkflowState>state.clone();

                clone.states.push(stateClone);
            }
        }

        clone.transitions = [];
        if (this.transitions != null) {           
            for (let iTransition: number = 0; iTransition < this.transitions.length; iTransition++) {
                let transition: WorkflowTransition = this.transitions[iTransition];
                let transitionClone: WorkflowTransition = <WorkflowTransition>transition.clone();

                clone.transitions.push(transitionClone);
            }
        }

        return (clone);
    }

    public copy(objectToCopyParam: ICloneAndCopy): void {
        let objectToCopy: Workflow = <Workflow>objectToCopyParam;

        // Copy primitives.
        this.id = objectToCopy.id;
        this.name = objectToCopy.name;
        this.description = objectToCopy.description;
        this.dataCollectionId = objectToCopy.dataCollectionId;

        this.modifiedBy = objectToCopy.modifiedBy;
        this.modifiedDate = objectToCopy.modifiedDate;

        this.isSystemWorkflow = objectToCopy.isSystemWorkflow;
        this.isDefaultFormWorkflow = objectToCopy.isDefaultFolderWorkflow;
        this.isDefaultFolderWorkflow = objectToCopy.isDefaultFolderWorkflow;
        this.isFormWorkflow = objectToCopy.isFormWorkflow;
        this.isFolderWorkflow = objectToCopy.isFolderWorkflow;
        this.currentUserCanBuildWorkflows = objectToCopy.currentUserCanBuildWorkflows;

        // Copy states and transitions.
        //VNEXT-233: KLW - Changed the referenced states to objectToCopy, which fixed the bug
        this.states = [];
        if (objectToCopy.states != null) {
            for (let iState: number = 0; iState < objectToCopy.states.length; iState++) {
                let state: WorkflowState = objectToCopy.states[iState];
                let stateClone: WorkflowState = <WorkflowState>state.clone();

                this.states.push(stateClone);
            }
        }

        //VNEXT-233: KLW - Changed the referenced states to objectToCopy, which fixed the bug
        this.transitions = [];
        if (objectToCopy.transitions != null) {
            for (let iTransition: number = 0; iTransition < objectToCopy.transitions.length; iTransition++) {
                let transition: WorkflowTransition = objectToCopy.transitions[iTransition];
                let transitionClone: WorkflowTransition = <WorkflowTransition>transition.clone();

                this.transitions.push(transitionClone);
            }
        }

        return;
    }

    // IListItem methods.
    public setId(idParam: number): void {
        this.id = idParam;

        return;
    }

    /*
    public getTitle(): string {
        return (this.name);
    }
    */

    public userCanDelete(): boolean {
        return (false); // Note:  delete permissions are based on the 'siteIsAdministerable'
                        //        flag in model class DataCollecdtion.
    }

    public getStatus(): string {
        return ('na');
    }

    public getExtraInfo(): string {
        return "";
    }

    public getValue(property: string): string {
        return '';
    }

    public getType(): string {
        return ItemTypeEnum.WORKFLOW; 
    }

    public getPosition(): number {
        return this.id; // id is a reasonable default attribute for ordering
    }

    public canBeDeleted(): boolean { // Note:  this will have to be fixed to use
        //        a value returned by the server.
        let canBeDeleted: boolean = ((!this.isSystemWorkflow) && (!this.isDefaultFolderWorkflow) && (!this.isDefaultFormWorkflow));
        return (canBeDeleted);
    }

    public getIconType(): IconFormatEnum {
        return IconFormatEnum.MAT_ICON;
    }
    public getIcon(): string {
        return ('timeline'); // Returns the default icon for a site/data collection.
    }

    public getUniqueId(): string { // For interface IListItem
        let uniqueId: string = `${this.id}-${this.getType()}`;

        return (uniqueId);
    }

    public getChildCount(): number {
        throw new Error('Method not implemented.');
    }

    public getModifiedBy(): string {
        return this.modifiedBy;
    }
    public getModifiedDate(): Date {
        return this.modifiedDate;
    }

    public update(obj: any, type: string, icon?: string, position?: number): void {
        // TO DO:  DISCUSS WITH PAUL.
        //
        // NOTE:  I BELIEVE THIS CAN BE A NOOP SINCE
        //        THE OBJECT WILL ALWAYS BE CONSISTENT
        //        WITH ITSELF/NO NEED TO UPDATE/SYNC.

        return;
    }

    public findAndUpdateFrom(items, obj: any): void {
        throw ('Workflow.findAndUpdateFrom():  this method is not implement.');
    }

    public getParentId(): number {
        throw new Error('Method not implemented.');
    }

    public getRoleNames(): string[] {
        let mapOfRoleNames: IWorkflowRoles = {};

        // Get state permission role names.
        if (this.states != null) {
            for (let iState: number = 0; iState < this.states.length; iState++) {
                let state: WorkflowState = this.states[iState];

                if (state.permissions != null) {
                    for (let iPermission: number = 0; iPermission < state.permissions.length; iPermission++) {
                        let permission: WorkflowPermissionBase = state.permissions[iPermission];

                        if (!mapOfRoleNames[permission.roleName])
                            mapOfRoleNames[permission.roleName] = permission.roleName;
                    }
                }
            }
        }

        // Get transition permission role names.
        if (this.transitions != null) {
            for (let iTrans: number = 0; iTrans < this.transitions.length; iTrans++) {
                let transition: WorkflowTransition = this.transitions[iTrans];

                if (transition.permissions != null) {
                    for (let iPermission: number = 0; iPermission < transition.permissions.length; iPermission++) {
                        let permission: WorkflowPermissionBase = transition.permissions[iPermission];

                        if (!mapOfRoleNames[permission.roleName])
                            mapOfRoleNames[permission.roleName] = permission.roleName;
                    }
                }
            }
        }

        // Convert the map of role names to an array.
        let roleNames: string[] = [];

        for (let roleName in mapOfRoleNames) {
            roleNames.push(roleName);
        }

        return roleNames;
    }
}
