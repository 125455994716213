<h1 mat-dialog-title
    class="draggable-dialogue-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
    Paste from Excel into Kendo Grid
</h1>
<form>
    <mat-dialog-content class="text-area">
        <mat-form-field appearance="outline" style="width: 100%;">
            <textarea matInput
                      [(ngModel)]="pasteData"
                      (ngModelChange)="this.handleNgOnChange()"
                      placeholder="Paste data from Excel"
                      name="txtPasteData"
                      rows="5"
                      class="text-area"
                      cdkFocusInitial>
            </textarea>
        </mat-form-field>
        <mat-hint>{{ this.parseInfo }}</mat-hint>
        <mat-error style="width: 100%;">{{ this.parseError }}</mat-error>
    </mat-dialog-content>

    <mat-radio-group *ngIf="griddata.gridHasData" name="replaceGridData" [(ngModel)]="this.griddata.pasteMode" aria-label="Select data destination">
        <mat-radio-button value="replace">Replace data in grid</mat-radio-button> &nbsp;&nbsp;
        <mat-radio-button value="append">Append to end of grid</mat-radio-button>
    </mat-radio-group>
    <div>&nbsp;<span *ngIf="this.showLengthWarning" style="color: red;">Warning: pasting more than ten records at a time may fail</span></div>
    <mat-dialog-actions>
        <button mat-flat-button type="submit" color="primary" class="theme-primary-btn"
                [disabled]="disableCopyButton" [mat-dialog-close]="pasteData">
            Copy into Grid
        </button>

        <button mat-flat-button type="button" class="btn-light" style="margin-left: 10px;"
                mat-dialog-close (click)="clickCancel()">
            Cancel
        </button>
    </mat-dialog-actions>
</form>
