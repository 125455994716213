import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { HttpClient } from '@angular/common/http';

import { FormFieldActionInstructions } from '../models/field-conditional-logic/field-action-instructions.model';
import { FormFieldActionNameEnum } from '../models/field-conditional-logic/enums';
import { Form } from '../models/form-builder/form.model';
import { FormFieldExpression } from '../models/field-conditional-logic/form-field-expression.model';
import { CollectApiServiceBase } from './collect-api-base.service';
import { environment } from '../../../environments/environment';
import { FormInstance } from '../models/site-content/form-instance.model';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { SourceFieldChangeConditionalLogicInstructions } from '../models/site-metadata/source-field-change-logic-instructions.model';
import { FieldConditionalLogicActionNameEnum, FieldConditionalLogicTargetFieldDefaultAttrs } from '../models/field-conditional-logic/enums';
//import { IOperandSymbolToCompareMethodName, allFieldOperators } from '../models/field-conditional-logic/conditional-logic-operators.model';
import { ConditionalLogicRuleOperator } from '../models/field-conditional-logic/rule-operator.model';
import { FormInstanceLoadedConditionalLogicInstructions } from '../models/site-metadata/form-instance-loaded-logic-instructions.model';
import { FormFieldComparisonOperatorNameEnum } from '../models/field-conditional-logic/enums';
import { SiteHomePageLoadedConditionalLogicInstructions } from '../models/site-metadata/site-home-page-loaded-logic-instructions.model';

export class FormFieldActionValue {
    public actionDisplayName: FieldConditionalLogicActionNameEnum;
    public actionValue: string; // The value that is stored in the database.

    public constructor(actionDisplayName: FieldConditionalLogicActionNameEnum, actionValue: string) {
        this.actionDisplayName = actionDisplayName;
        this.actionValue = actionValue;
    }
}

export class TargetFieldDefaultAttributeValue {
    public attributeDisplayName: FieldConditionalLogicTargetFieldDefaultAttrs;
    public attributeValue: string;

    public constructor(attributeDisplayName: FieldConditionalLogicTargetFieldDefaultAttrs, attributeValue: string) {
        this.attributeDisplayName = attributeDisplayName;
        this.attributeValue = attributeValue;
    }
}

export interface IOperandSymbolToCompareMethodName {
    [operatorSymbol: string]: string;
}

interface IActionNameToPrecedence {
    [actionName: string]: number;
}

@Injectable({
    providedIn: 'root'
})
export class FieldConditionalLogicService extends CollectApiServiceBase<FormFieldExpression> {
    // Properties.
    // Static data.
    private static formFieldActionInstructions: FormFieldActionInstructions[] =
        [
            {
                actionName: FormFieldActionNameEnum.Show,
                oppositeActionName: FormFieldActionNameEnum.Hide,

                applyActionStyles: [
                    'apply-show-action'
                ],
                applyActionStylesToRemove: [
                    'apply-hide-action'
                ],
                reverseActionStyles: [
                    'reverse-show-action'
                ],

                appliesFormFieldAttribute: false,
                formFieldAttributeName: null,
                formFieldAttributeValue: null
            },
            {
                actionName: FormFieldActionNameEnum.Hide,
                oppositeActionName: FormFieldActionNameEnum.Show,

                applyActionStyles: [
                    'apply-hide-action'
                ],
                applyActionStylesToRemove: [
                    'apply-show-action'
                ],
                reverseActionStyles: [
                    'reverse-hide-action'
                ],

                appliesFormFieldAttribute: false,
                formFieldAttributeName: null,
                formFieldAttributeValue: null
            },
            {
                actionName: FormFieldActionNameEnum.Require,
                oppositeActionName: FormFieldActionNameEnum.DoNotRequire,

                applyActionStyles: [
                    'apply-require-action'
                ],
                applyActionStylesToRemove: [
                ],
                reverseActionStyles: [
                    'reverse-require-action'
                ],

                appliesFormFieldAttribute: true,
                formFieldAttributeName: 'required',
                formFieldAttributeValue: 'true'
            },
            {
                actionName: FormFieldActionNameEnum.DoNotRequire,
                oppositeActionName: FormFieldActionNameEnum.Require,

                applyActionStyles: [
                    'apply-do-not-require-action'
                ],
                applyActionStylesToRemove: [
                ],
                reverseActionStyles: [
                    'reverse-do-not-require-action'
                ],

                appliesFormFieldAttribute: true,
                formFieldAttributeName: 'required',
                formFieldAttributeValue: 'false'
            }
            // TO DO:  ADD ENTRIES FOR EDITABLE AND POSSIBLY INSTRUCTION OPTIONS.
            // TO DO:  ADD PROPERTY FOR ORDER OF PRECEDENCE.
        ];

    private static allFieldOperators: ConditionalLogicRuleOperator[] =
        [
            {
                id: 1,
                name: 'Is greater than',
                //symbol: '>',
                symbol: FormFieldComparisonOperatorNameEnum.IsGreaterThan,
                appliesToNumericFields: true,
                appliesToTextFields: false,
                appliesToDateFields: true,
                requiresOperandValue: true
            },
            {
                id: 2,
                name: 'Is greater than or equal to',
                //symbol: '>=',
                symbol: FormFieldComparisonOperatorNameEnum.IsGreaterThanOrEqualTo,
                appliesToNumericFields: true,
                appliesToTextFields: false,
                appliesToDateFields: true,
                requiresOperandValue: true
            },
            {
                id: 3,
                name: 'Equals',
                //symbol: '=',
                symbol: FormFieldComparisonOperatorNameEnum.Equals,
                appliesToNumericFields: true,
                appliesToTextFields: true,
                appliesToDateFields: true,
                requiresOperandValue: true
            },
            {
                id: 4,
                name: 'Does not equal',
                //symbol: '<>',
                symbol: FormFieldComparisonOperatorNameEnum.DoesNotEqual,
                appliesToNumericFields: true,
                appliesToTextFields: true,
                appliesToDateFields: true,
                requiresOperandValue: true
            },
            {
                id: 5,
                name: 'Is less than',
                //symbol: '<',
                symbol: FormFieldComparisonOperatorNameEnum.IsLessThan,
                appliesToNumericFields: true,
                appliesToTextFields: false,
                appliesToDateFields: true,
                requiresOperandValue: true
            },
            {
                id: 6,
                name: 'Is less than or equal to',
                //symbol: '<=',
                symbol: FormFieldComparisonOperatorNameEnum.IsLessThanOrEqualTo,
                appliesToNumericFields: true,
                appliesToTextFields: false,
                appliesToDateFields: true,
                requiresOperandValue: true
            },
            {
                id: 7,
                name: 'Includes/IN',
                symbol: FormFieldComparisonOperatorNameEnum.InListOfValues,
                appliesToNumericFields: false,
                appliesToTextFields: true,
                appliesToDateFields: false,
                requiresOperandValue: true
            },
            {
                id: 8,
                name: 'Does not Include/NOT_IN',
                symbol: FormFieldComparisonOperatorNameEnum.NotInListOfValues,
                appliesToNumericFields: false,
                appliesToTextFields: true,
                appliesToDateFields: false,
                requiresOperandValue: true   
            },
            {
                id: 9,
                name: 'Starts With',
                symbol: FormFieldComparisonOperatorNameEnum.StartsWith,
                appliesToNumericFields: false,
                appliesToTextFields: true,
                appliesToDateFields: false,
                requiresOperandValue: true
            },
            {
                id: 10,
                name: 'Ends With',
                symbol: FormFieldComparisonOperatorNameEnum.EndsWith,
                appliesToNumericFields: false,
                appliesToTextFields: true,
                appliesToDateFields: false,
                requiresOperandValue: true
            },
            {
                id: 11,
                name: 'Contains',
                symbol: FormFieldComparisonOperatorNameEnum.Contains,
                appliesToNumericFields: false,
                appliesToTextFields: true,
                appliesToDateFields: false,
                requiresOperandValue: true
            },
            {
                id: 12,
                name: 'IsEmpty',
                symbol: FormFieldComparisonOperatorNameEnum.IsEmpty,
                appliesToNumericFields: false,
                appliesToTextFields: true,
                appliesToDateFields: true,
                requiresOperandValue: false
            },
            {
                id: 13,
                name: 'IsNotEmpty',
                symbol: FormFieldComparisonOperatorNameEnum.IsNotEmpty,
                appliesToNumericFields: false,
                appliesToTextFields: true,
                appliesToDateFields: true,
                requiresOperandValue: false
            },
            /*
            {
                id: 14,
                name: 'Range/Between',
                symbol: FormFieldComparisonOperatorNameEnum.Between,
                appliesToNumericFields: true,
                appliesToTextFields: false,
                appliesToDateFields: true,
                requiresOperandValue: true
            },
            */
        ];

    private static availableFormFieldActions: FieldConditionalLogicActionNameEnum[] =
        [
            FieldConditionalLogicActionNameEnum.NoAction,
            FieldConditionalLogicActionNameEnum.Show,
            FieldConditionalLogicActionNameEnum.CanEdit,
            FieldConditionalLogicActionNameEnum.Require,
            FieldConditionalLogicActionNameEnum.Show_CanEdit,
            FieldConditionalLogicActionNameEnum.Show_CanEdit_Require
        ]

    private static availableFormFieldActionValues: FormFieldActionValue[] =
        [
            new FormFieldActionValue(FieldConditionalLogicActionNameEnum.NoAction, FieldConditionalLogicActionNameEnum.NoAction),
            new FormFieldActionValue(FieldConditionalLogicActionNameEnum.Show, FieldConditionalLogicService.removeCommasAndSpacesFrom(FieldConditionalLogicActionNameEnum.Show)),
            new FormFieldActionValue(FieldConditionalLogicActionNameEnum.CanEdit, FieldConditionalLogicService.removeCommasAndSpacesFrom(FieldConditionalLogicActionNameEnum.CanEdit)),
            new FormFieldActionValue(FieldConditionalLogicActionNameEnum.Require, FieldConditionalLogicService.removeCommasAndSpacesFrom(FieldConditionalLogicActionNameEnum.Require)),
            new FormFieldActionValue(FieldConditionalLogicActionNameEnum.Show_CanEdit, FieldConditionalLogicService.removeCommasAndSpacesFrom(FieldConditionalLogicActionNameEnum.Show_CanEdit)),
            new FormFieldActionValue(FieldConditionalLogicActionNameEnum.Show_CanEdit_Require, FieldConditionalLogicService.removeCommasAndSpacesFrom(FieldConditionalLogicActionNameEnum.Show_CanEdit_Require)),
            new FormFieldActionValue(FieldConditionalLogicActionNameEnum.ApplyFilter, FieldConditionalLogicService.removeCommasAndSpacesFrom(FieldConditionalLogicActionNameEnum.ApplyFilter)),
        ];

    private static targetFieldActionsInOrderOfPrecedence: FieldConditionalLogicActionNameEnum[] = 
        [
            FieldConditionalLogicActionNameEnum.Show_CanEdit_Require,
            FieldConditionalLogicActionNameEnum.Show_CanEdit,

            FieldConditionalLogicActionNameEnum.Show,
            FieldConditionalLogicActionNameEnum.CanEdit,
            FieldConditionalLogicActionNameEnum.Require
        ];

    private static targetFieldDefaultAttributes: FieldConditionalLogicTargetFieldDefaultAttrs[] =
        [
            FieldConditionalLogicTargetFieldDefaultAttrs.None,

            FieldConditionalLogicTargetFieldDefaultAttrs.Show,
            FieldConditionalLogicTargetFieldDefaultAttrs.Show_CanEdit
        ];
    private static targetFieldDefaultAttributeValues: TargetFieldDefaultAttributeValue[] =
        [
            new TargetFieldDefaultAttributeValue(FieldConditionalLogicTargetFieldDefaultAttrs.None, FieldConditionalLogicTargetFieldDefaultAttrs.None),

            new TargetFieldDefaultAttributeValue(FieldConditionalLogicTargetFieldDefaultAttrs.Show, FieldConditionalLogicService.removeCommasAndSpacesFrom(FieldConditionalLogicTargetFieldDefaultAttrs.Show)),
            new TargetFieldDefaultAttributeValue(FieldConditionalLogicTargetFieldDefaultAttrs.Show_CanEdit, FieldConditionalLogicService.removeCommasAndSpacesFrom(FieldConditionalLogicTargetFieldDefaultAttrs.Show_CanEdit))
        ];

    private static mapOfTargetFieldActionPrecedences: IActionNameToPrecedence = {};

    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'fieldConditionalLogic', FormFieldExpression)
    }

    // Static methods.
    public static get AllFieldOperators(): ConditionalLogicRuleOperator[] {
        return FieldConditionalLogicService.allFieldOperators;
    }
    public static get AllTextFieldOperators(): ConditionalLogicRuleOperator[] {
        return FieldConditionalLogicService.allFieldOperators.filter(o => o.appliesToTextFields);
    }
    public static get AllNumericFieldOperators(): ConditionalLogicRuleOperator[] {
        return FieldConditionalLogicService.allFieldOperators.filter(o => o.appliesToNumericFields);
    }
    
    private static removeCommasAndSpacesFrom(actionName: string): string {
        return actionName.replace(/, /g, '|').replace(/ /g, '');
    }

    // Implement formatResponse.
    public formatResponse(data: FormFieldExpression): FormFieldExpression {
        let obj = plainToClass(FormFieldExpression, data);
        return (obj);
    }

    // Service methods.
    public getFormInstanceLoadedTargetFieldInstructions(formInstanceId: number): Promise<FormInstanceLoadedConditionalLogicInstructions> {
        let url = `${this.url}/${this.endpoint}/getFormLoadedTargetFieldInstructions/${formInstanceId}`;

        return this.http.get<FormInstanceLoadedConditionalLogicInstructions>(url)
            .toPromise()
            .then(formInstanceLoadedInstructions => {
                let instructionsResult: FormInstanceLoadedConditionalLogicInstructions = plainToClass(FormInstanceLoadedConditionalLogicInstructions, formInstanceLoadedInstructions);
                return instructionsResult;
            })
            .catch(this.handleError);
    }

    public getSiteHomePageLoadedTargetFieldInstructions(dataCollectionId: number): Promise<SiteHomePageLoadedConditionalLogicInstructions> {
        let url = `${this.url}/${this.endpoint}/getSiteHomePageLoadedTargetFieldInstructions/${dataCollectionId}`;

        return this.http.get<FormInstanceLoadedConditionalLogicInstructions>(url)
            .toPromise()
            .then(formInstanceLoadedInstructions => {
                let instructionsResult: SiteHomePageLoadedConditionalLogicInstructions = plainToClass(SiteHomePageLoadedConditionalLogicInstructions, formInstanceLoadedInstructions);
                return instructionsResult;
            })
            .catch(this.handleError);
    }

    public sourceFieldValueChanged(formInstance: FormInstance, sourceFieldId: number): Promise<SourceFieldChangeConditionalLogicInstructions> {
        let url = `${this.url}/${this.endpoint}/sourceFieldValueChanged/${sourceFieldId}`;
        return this.http.put<SourceFieldChangeConditionalLogicInstructions>(url, formInstance)
            .toPromise()
            .then(sourceFieldChangeInstructions => {
                let instructionsResult: SourceFieldChangeConditionalLogicInstructions = plainToClass(SourceFieldChangeConditionalLogicInstructions, sourceFieldChangeInstructions);
                return instructionsResult;
            })
            .catch(this.handleError);
    }

    public getFormFieldActionInstructions(): FormFieldActionInstructions[] {
        return FieldConditionalLogicService.formFieldActionInstructions;
    }

    public getAvailableTargetFieldDefaultAttributes(): FieldConditionalLogicTargetFieldDefaultAttrs[] {
        return FieldConditionalLogicService.targetFieldDefaultAttributes;
    }
    public getAvailableTargetFieldDefaultAttributeValues(): TargetFieldDefaultAttributeValue[] {
        return FieldConditionalLogicService.targetFieldDefaultAttributeValues;
    }

    public getAvailableFormFieldActions(): FieldConditionalLogicActionNameEnum[] {
        return FieldConditionalLogicService.availableFormFieldActions;
    }
    public getAvailableFormFieldActionValues(): FormFieldActionValue[] {
        return FieldConditionalLogicService.availableFormFieldActionValues;
    }

    public getAvailableFieldOperators(): ConditionalLogicRuleOperator[] {
        return FieldConditionalLogicService.allFieldOperators;
    }
    public getFieldOperatorFromSymbol(operatorSymbol: string): ConditionalLogicRuleOperator {
        let operator: ConditionalLogicRuleOperator = FieldConditionalLogicService.allFieldOperators.find(o => o.symbol == operatorSymbol);
        return operator;
    }
    public getFieldOperatorFromId(operatorId: number): ConditionalLogicRuleOperator {
        let operator: ConditionalLogicRuleOperator = FieldConditionalLogicService.allFieldOperators.find(o => o.id == operatorId);
        return operator;
    }

    public compareFieldActions(action1: string, action2: string): number {
        // If the map of actions by precedence is not yet loaded, do that now.
        if (Object.keys(FieldConditionalLogicService.mapOfTargetFieldActionPrecedences).length == 0) {
            for (let index: number = 0; index < FieldConditionalLogicService.targetFieldActionsInOrderOfPrecedence.length; index++) {
                let actionName: FieldConditionalLogicActionNameEnum = FieldConditionalLogicService.targetFieldActionsInOrderOfPrecedence[index];
                FieldConditionalLogicService.mapOfTargetFieldActionPrecedences[actionName] = (index + 1);
            }
        }

        // Compare the two actions and return the result.
        let action1Precedence: number = FieldConditionalLogicService.mapOfTargetFieldActionPrecedences[action1];
        let action2Precedence: number = FieldConditionalLogicService.mapOfTargetFieldActionPrecedences[action2];

        if (action1Precedence == null)
            throw `FieldConditionalLogicService.compareFieldActions():  cannot get a precedence for action '${action1}'.`;
        else if (action2Precedence == null)
            throw `FieldConditionalLogicService.compareFieldActions():  cannot get a precedence for action '${action2}'.`;

        let iCompare: number = 0;
        if (action1Precedence != action2Precedence)
            iCompare = action1Precedence < action2Precedence ? -1 : 1;

        return iCompare;
    }

    // Helper methods.
    public getFormConditionalLogicExpr(form: Form): FormFieldExpression[] {
        return form.conditionalLogicRules != null ? form.conditionalLogicRules : [];
    }
}
