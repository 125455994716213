// Note:  named this file a bit differently so the name would not be excessively long.

//import { FormFieldConstraintBase } from './form-field-constraint-base.model';
import { FormField } from './../form-field.model';
import { FormInstanceElement } from './../form-instance-element.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../interfaces/iform-field-constraint.interface';
import { ICloneAndCopy } from '../../../interfaces/clone-and-copy';
import { FormFieldConstraintBase } from './form-field-constraint-base.model';
//import { SiteFormFieldConstraintProperties } from './site-form-field-constraint-props.model';
import { FormFieldConstraintTypeEnum } from '../../../enums/form-field-constraint-type.enum';

export class FormFieldRegularExpressionConstraint extends FormFieldConstraintBase implements IFormFieldConstraint {
    // Properties.
    private regularExpressionConstraintValue: string;
    // pharv 11/9/2022 - renamed from regularExpressionConstraintMessage to parralel the naming in the ViewModel on the server
    private regularExpressionMessage: string;

    // Constructor.
    public constructor(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null, constraintFailureMessage: string = null) {
        super(dataCollectionId, constraintId, FormFieldConstraintTypeEnum.RegularExpression, constraintName);

        this.regularExpressionConstraintValue = constraintValue;
        this.regularExpressionMessage = constraintFailureMessage;
    }

    // Implement IFormFieldConstraint.
    // Property-related methods.
    public get type(): string {
        return FormFieldConstraintTypeEnum.RegularExpression;
    }

    public get constraintExpression(): string {
        return this.regularExpressionConstraintValue;
    }
    public set constraintExpression(value: string) {
        this.regularExpressionConstraintValue = value;
    }

    public get constraintFailureMessage(): string {
        return this.regularExpressionMessage;
    }
    public set constraintFailureMessage(value: string) {
        this.regularExpressionMessage = value;
    }

    // Logic-related methods.
    public validate(formField: FormField, formInstanceElement: FormInstanceElement): void {
        // TO DO:  IMPLEMENT THIS METHOD.
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let clone: FormFieldRegularExpressionConstraint = new FormFieldRegularExpressionConstraint(this.dataCollectionId, this.constraintId, this.constraintName, this.regularExpressionConstraintValue);

        return clone;
    }

    public copy(objectToCopyParam: ICloneAndCopy): void {
        let objectToCopy: FormFieldRegularExpressionConstraint = <FormFieldRegularExpressionConstraint>objectToCopyParam;

        this.dataCollectionId = objectToCopy.dataCollectionId;
        this.constraintId = objectToCopy.constraintId;
        this.constraintType = objectToCopy.constraintType;
        this.constraintName = objectToCopy.constraintName;
        this.regularExpressionConstraintValue = objectToCopy.regularExpressionConstraintValue;
        this.regularExpressionMessage = objectToCopy.regularExpressionMessage;
    }
}

export class FormFieldRegularExpressionConstraintWithMetrics extends FormFieldRegularExpressionConstraint implements IFormFieldConstraintWithMetrics {
    // Properties.
    public useCount: number = 0;

    // Constructor.
    public constructor(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null, constraintFailureMessage: string = null) {
        super(dataCollectionId, constraintId, constraintName, constraintValue, constraintFailureMessage);
    }
}
