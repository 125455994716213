<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<!-- DESIGN mode -->
<div class="flex-row-top-right" *ngIf="this.Mode === 'design'">
    <div class="fill-remaining-space">
        <!-- If there are instructions, display label first, then instructions then field -->
        <ng-container *ngIf="this.HasInstructions">
            <div class="design-mode-field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
            <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
        </ng-container>

        <mat-form-field appearance="outline">
            <mat-label *ngIf="this.ShowLabel && !HasInstructions" class="design-mode-field-label">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></mat-label>

            <input matInput
                   type="date"
                   placeholder="{{this.PlaceholderText}}"
                   disabled />
        </mat-form-field>
        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
</div>

<!-- DESIGN PREVIEW or INSTANCE mode -->
<div class="flex-row-top-right" *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')">
    <div class="fill-remaining-space">
        <ng-container *ngIf="this.FormGroup !== null">

            <!-- If there are instructions, display label first, then instructions then field -->
            <ng-container *ngIf="this.HasInstructions">
                <div class="field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </ng-container>

            <form [formGroup]="this.FormGroup">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <!-- If there are no instructions, display label integrated into the field -->
                    <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid"
                               class="field-label">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></mat-label>

                    <ng-container *ngIf="this.ReadOnly === false">
                        <input matInput
                               dateFormat formatString="{{this.DatePipeFormat}}"
                               #dateInput
                               [min]="this.MinDate"
                               [max]="this.MaxDate"
                               [matDatepicker]="picker"
                               formControlName="date_control"
                               class="enabled-date-input"
                               placeholder="{{this.PlaceholderText}}"
                               (blur)="this.handleOnBlur()"
                               (dateChange)="this.handleOnModelChange()" />

                        <mat-datepicker-toggle matSuffix
                                               class="datepicker"
                                               [for]="picker"
                                               id="{{this.MatInputId}}"></mat-datepicker-toggle>
                        <mat-datepicker #picker>
                            <mat-datepicker-actions>
                                <button mat-button matDatepickerCancel>Cancel</button>
                                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            </mat-datepicker-actions>
                        </mat-datepicker>
                    </ng-container>

                    <ng-container *ngIf="this.ReadOnly === true">
                        <input matInput
                               dateFormat formatString="{{this.DatePipeFormat}}"
                               #dateInput
                               [min]="this.MinDate"
                               [max]="this.MaxDate"
                               [readonly]="true"
                               formControlName="date_control"
                               class="enabled-date-input"
                               placeholder="{{this.PlaceholderText}}" />

                        <span class="formatted-date-span-no-click">{{this.FormattedDateValue}}</span>
                        <!--<mat-datepicker #na_picker></mat-datepicker>-->
                        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                    </ng-container>

                </mat-form-field>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </form>
        </ng-container>
    </div>
    <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
</div>
