import { FormInstance } from "./site-content/form-instance.model";
import { LogMessage } from "./log-message.model";

export class FormInstanceHistory {
    public versions: FormInstance[];
    public logMessages: LogMessage[];

    // pharvey - Added for VNEXT-1056
    public formInstanceName: string;
    public formInstanceVersion: string;
    public formFieldName: string;
    public value: string;
    public modifiedDate: Date;
    public modifiedBy: string

    public get Versions(): FormInstance[] {
        let versions = this.versions.map(x => Object.assign(new FormInstance(), x));
        return versions;
    }

    public get LogMessages(): LogMessage[] {
        let messages: LogMessage[] = [];
        for (let msg of this.logMessages) {
            let logMsg = Object.assign(new LogMessage(), msg);
            logMsg.modifiedBy = logMsg.createdBy;
            logMsg.modifiedDate = logMsg.createdDate
            messages.push(logMsg)
        }
        return messages;
    }
}
