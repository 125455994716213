<h2 mat-dialog-title
    class="draggable-dialogue-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
    New {{this.SiteName}} Site
</h2>

<form>
    <mat-dialog-content>
        <div class="row">
            <div class="col-4">
                <mat-card appearance="outlined">
                    <mat-card-content>
                        <mat-radio-group [(ngModel)]="options.type" name="newSiteType" aria-label="Select an option">
                            <mat-radio-button value="empty">An Empty Site</mat-radio-button>
                        </mat-radio-group>
                        <p>
                            Create a new site with no contents.
                        </p>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-4">
                <mat-card appearance="outlined">
                    <mat-card-content>
                        <mat-radio-group [(ngModel)]="options.type" name="newSiteType" aria-label="Select an option">
                            <mat-radio-button value="template">From a Template</mat-radio-button>
                        </mat-radio-group>
                        <p>
                            Create a new site based on an existing template.
                        </p>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-4">
                <mat-card appearance="outlined">
                    <mat-card-content>
                        <mat-radio-group [(ngModel)]="options.type" name="newSiteType" aria-label="Select an option">
                            <mat-radio-button value="copy">Copy an Existing Site</mat-radio-button>
                        </mat-radio-group>
                        <p>
                            Create a duplicate of an existing site.
                        </p>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="row" id="siteNameDiv">
            <mat-form-field appearance="outline" class="col-12">
                <mat-label>Site Name</mat-label>
                <input matInput [(ngModel)]="options.name" (ngModelChange)="this.handleModelChange()" placeholder="Site Name" name="siteName" data-scriptName="siteName" required />
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions id="dialogActions">
        <button mat-flat-button type="submit" color="primary" class="theme-primary-btn" [mat-dialog-close]="options">OK</button>
        <!--<button mat-flat-button type="submit" color="primary" class="theme-primary-btn" click="this.onOkClicked()">OK 2</button>-->
        <button mat-flat-button [mat-dialog-close]="null" style="margin-left: 10px;">Cancel</button>
    </mat-dialog-actions>
</form>
