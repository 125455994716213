import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CurrentUserService } from '../../../security/current-user.service';
import { EmailService } from '../../../shared/services/email.service';
export class AlertDialogModel {
    constructor(
        public title: string,
        public message: string,
        public technicalInfo: string = null,
        public correlationId: string = null,
        public hideRequestAssistance: boolean = false) { }
}

@Component({
    selector: 'app-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
    // Properties.
    private title: string;
    private message: string;
    private technicalInfo: string;
    private correlationId: string;
    private userEmail: string;
    private notifyDevs: boolean = false;
    private sendCCtoUser: boolean = false;
    private sendDisabled: boolean = false;
    private hideRequestAssistance: boolean = false;
    private isFeedback: boolean = false;
    private placeholder: string;
    private sendCaption: string;
    private emailStatus: string = '';
    private ngFormGroup: UntypedFormGroup;

    // Constructor.
    public constructor(
        public dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AlertDialogModel,
        private clipboard: Clipboard,
        public emailService: EmailService,
        private currentUserService: CurrentUserService,
        private fb: UntypedFormBuilder,
        public router: Router) {
        this.title = data.title;
        this.message = data.message;
        this.technicalInfo = data.technicalInfo;
        this.correlationId = data.correlationId;
        this.hideRequestAssistance = data.hideRequestAssistance;
        this.userEmail = '';
        this.emailStatus = '';
    }

    // Life cycle methods.
    public ngOnInit(): void {
        if (this.currentUserService) {
            this.userEmail = this.currentUserService.user.emailAddress;
        }
        else {
            console.log('this.currentUserService undefined in alert dialog');
        }
        this.placeholder = 'Please describe what you were trying to do.';
        this.sendCaption = 'Send Error Report';
        if (this.title == 'Send Feedback or Report an Issue') {
            this.isFeedback = true;
            this.hideRequestAssistance = false;
            this.notifyDevs = true;
            this.placeholder = '';
            this.sendCaption = 'Send feedback or report issue';
        }
        this.ngFormGroup = this.fb.group({
            tryingToDo: ['']
        });
    }

    // Workaround for angular component issue #13870 (panel briefly appears expanded when dialog opens)
    disableAnimation = true;
    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => this.disableAnimation = false);
    }

    // Getter methods called from my HTML.
    public get Title(): string {
        return this.title;
    }
    public get Message(): string {
        return this.message;
    }
    public get TechnicalInfo(): string {
        return this.technicalInfo;
    }
    public get NgFormGroup(): UntypedFormGroup {
        return (this.ngFormGroup);
    }
    public get CorrelationId(): string {
        return this.correlationId;
    }
    public get EmailStatus(): string {
        return this.emailStatus;
    }
    public get UserEmail(): string {
        return this.userEmail;
    }
    public get HideRequestAssistance(): boolean {
        return this.hideRequestAssistance;
    }
    public CopyToClipboard() {
        this.clipboard.copy(this.correlationId);
    }

    public toggleNotifyDevs() {
        this.notifyDevs = !this.notifyDevs;
    }
    public toggleCCtoUser() {
        this.sendCCtoUser = !this.sendCCtoUser;
    }
    public get NotifyDevs() {
        return this.notifyDevs;
    }
    public get Placeholder() {
        return this.placeholder;
    }
    public get SendCaption() {
        return this.sendCaption;
    }
    public get SendDisabled() {
        if (this.isFeedback && this.ngFormGroup.value.tryingToDo.trim() == '') return true;
        return this.sendDisabled;
    }
    public get ShowTechnicalDetails() {
        return this.correlationId || this.technicalInfo;
    }

    public SendErrorReport() {
        this.emailStatus = 'Sending email...';
        let msgBody = 'A OMB Collect user requested investigation of an error.<br><br>';
        if (this.isFeedback) {
            msgBody = 'An OMB Collect user submitted feedback or reported an issue.<br><br>';
        }
        msgBody += '<table><thead>'
        if (this.title) {
            msgBody += `<tr><td><strong>Title</strong></td><td>${this.title}</td></tr>`
        }
        if (this.message) {
            msgBody += `<tr><td><strong>Message</strong></td><td>${this.message}</tr>`
        }
        if (this.technicalInfo) {
            msgBody += `<tr><td><strong>Technical&nbsp;Info</strong> &nbsp; </td><td>${this.technicalInfo}</td></tr>`
        }
        if (this.correlationId) {
            msgBody += '<tr><td><strong>Correlation&nbsp;ID</strong></td><td>' + this.correlationId + ' &nbsp; ';
            msgBody += `<a href="https://splunk.max.gov/en-US/app/search/search?q=search%20${this.correlationId}">Splunk</a></td></tr>`;
        }
        if (this.currentUserService.user) {
            msgBody += `<tr><td><strong>User</strong></td><td>${this.currentUserService.user.displayName}</td></tr>`
            msgBody += `<tr><td><strong>Email</strong></td><td>${this.currentUserService.user.emailAddress}</td></tr>`
        }
        if (this.router) {
            msgBody += `<tr><td><strong>URL</strong></td><td>${this.router.url}</td></tr>`
        }
        msgBody += '</thead></table><br />'

        let context = this.ngFormGroup.value.tryingToDo;
        if (context) {
            msgBody += 'User provided this information:<br><br>';
            msgBody += context + '<br /><br />';
        }

        let msgSubject = 'OMB Collect error report';
        if (this.isFeedback) {
            msgSubject = 'OMB Collect feedback or issue report';
        }
        else if (this.title) {
            msgSubject += ': ' + this.title;
        }

        let msgCC = '';
        if (this.sendCCtoUser) {
            msgCC = this.currentUserService.user.emailAddress;
        }

        let msgTo = 'devs'; // send to dev team
        if (this.isFeedback) {
            msgTo = 'all'; // send to dev team and DMT Help Desk
        }
        else if (this.title == 'Unauthorized') {
            msgTo = 'dmt' // send to DMT Help Desk
        }

        let msg = {
            subject: msgSubject,
            body: msgBody,
            to: msgTo,
            cc: msgCC
        };

        this.emailService.SendErrorMail(msg).then(resp => {
            this.sendDisabled = true;
            this.emailStatus = resp.toString();
        }).catch(error => {
            this.emailStatus = "Problem sending email: " + error.message;
        });
        return;
    }

    public reloadPage() {
        //should only reload when page is broken
        //location.reload();
    }
}
