import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CurrentUserService } from '../security/current-user.service';

//import 'rxjs/add/operator/toPromise';

@Injectable()
export class CasService {
    private casUrl = '';

    constructor(private http: HttpClient, private currentUserService: CurrentUserService) {
        this.casUrl = environment.loginSiteUrl + "frameworkapi/cas/proxyticket";

        //console.log(this._user);
    }

    //public get http(): Http {
    //    return this.injector.get(Http);
    //}

    public getProxyTicket(serviceUrl: string): Promise<string> {
        var url = this.casUrl + "?serviceUrl=" + serviceUrl;
        console.log(url);
        return this.http.get(url)
            .toPromise()
            .then((response: Response) => {
                console.log("CAS ticket return: " + response.body.toString());
                return response.body.toString();
            })
            .catch((error: Response) => {
                var responseData = JSON.stringify(error)
                //this.currentUserService.logout();
                return Promise.reject(error.statusText); //??
            });
    }

    public get(targetUrl: string): Promise<any> {
        return this.http.get(targetUrl)
            .toPromise()
            .then(response => {
                return response;
            })
            .catch((error: Response) => {
                //if (error.status != 401) {
                //    return Promise.reject("Can't communicate with " + url + " (" + error.statusText) + ")";
                //}
                var url: string;
                url = targetUrl.substr(0, targetUrl.indexOf("?") == 0 ? targetUrl.length : targetUrl.indexOf("?"));
                console.log("url=" + url);
                this.getProxyTicket(url).then(ticket => {
                    console.log("ticket=" + ticket);
                    this.http.get(url + "?ticket=" + ticket)
                        .toPromise()
                        .then(response => {
                            return response;
                        })
                        .catch((error: HttpErrorResponse) => {
                            if (error.headers.keys.length == 0 && error.status == 0) {
                                return Promise.reject("Can't call " + url + ", probably because CORS is not enabled for " + this.currentUserService.getDomain(url));
                            }
                            else {
                                return Promise.reject("Can't make the call to " + this.currentUserService.getDomain(url) + " (" + error.statusText + ")");
                            }
                        });
                }).catch((error: Response) => {
                    return Promise.reject("Can't generate CAS proxy ticket." + " (" + error.statusText + ")");
                });
            });
    }

    public simpleGet(targetUrl: string, ticket: string): Promise<any> {
        var url: string = targetUrl;
        if (targetUrl.indexOf("?") == -1) {
            url += "?ticket=" + ticket;
        }
        else {
            url += "&ticket=" + ticket;
        }
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response;
            })
            .catch((error: HttpErrorResponse) => {
                var domain: string = this.currentUserService.getDomain(url);
                if (error.headers.keys.length == 0 && error.status == 0) {
                    return Promise.reject("Can't call " + url + ", probably because CORS is not enabled for " + domain);
                }
                else {
                    return Promise.reject("Can't make the call to " + domain + " (" + error.statusText + ")");
                }
            });

    }

}
