import { Type } from 'class-transformer';

// Define classes used for the site response management page.
export class SiteResponseManagementMetrics {
    public distinctRecipientCount: number;
    public distinctEditorsCount: number;
    public totalSubmissionsCount: number;
    public averageResponseTimeInHours: number;
}

export class SiteSubmissionForReview {
    public submissionId: number = -1;
    public submissionUserId: string;
    public submissionDate: string;
}

export class SiteResponseManagementData {
    id: number; // Note:  this value is not used by is required by interface IViewModel.

    @Type(() => SiteResponseManagementMetrics)
    public siteManagementMetrics: SiteResponseManagementMetrics;

    @Type(() => SiteSubmissionForReview)
    public submissionsForReview: SiteSubmissionForReview[] = [];
}
