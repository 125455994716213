import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { plainToClass } from "class-transformer";

import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { FormField } from '../models/form-builder/form-field.model';
import { ProgressIndicatorService } from './progress-indicator.service';
import { FormFieldSelectOptionsConstraint } from '../models/form-builder/field-constraints/field-select-options-constraint.model';
import { FormFieldRegularExpressionConstraint } from '../models/form-builder/field-constraints/field-regular-expression-constraint.model';
import { FormFieldCascadingDropdownConstraint } from '../models/form-builder/field-constraints/field-cascading-dropdown-constraint.model';
import { FormFieldDateRangeConstraint } from '../models/form-builder/field-constraints/field-date-range-constraint.model';
import { FormFieldNumericRangeConstraint } from '../models/form-builder/field-constraints/field-numeric-range-constraint.model';
import { FormFieldService } from './form-field.service';
import { Form } from '../models/form-builder/form.model';
import { ModelEventEnum, IModelEventHandler } from '../interfaces/imodel-event-handler.interface';
import { IFormFieldConstraint } from '../interfaces/iform-field-constraint.interface';
import { CascadingDropdownConfigRow } from '../models/site-metadata/cascading-drop-down-config-row.model';
//import { FormFieldSimpleListValuesConstraint } from '../models/form-builder/field-constraints/field-simple-list-values-constraint.model';
//import { FormFieldCascadingListValuesConstraint } from '../models/form-builder/field-constraints/field-cascading-list-value-constraint.model';
import { FormFieldListValuesConstraint } from '../models/form-builder/field-constraints/form-field-list-values-constraint.model';

// Define a helper class that flushes this service's cache when a form is saved.
export class FlushCachedServiceUponFormSave implements IModelEventHandler {
    public constructor(private cachedService: CachedFormFieldService, private form: Form) {
        form.eventHandler = this;
    }

    public eventCompleted(event: ModelEventEnum, modelObject: any): void {
        if (event == ModelEventEnum.MODEL_WILL_SAVE) {
            let form: Form = <Form>modelObject;
            this.cachedService.setUnsavedConstraintIdValuesToZero(form);
        } else if (event == ModelEventEnum.MODEL_SAVED) {
            this.cachedService.clearCache();
        }        
    }
}

// Define a class used to track cached data.
class FormFieldConstraintsCache {
    // Properties.
    public selectOptionsConstraints: FormFieldSelectOptionsConstraint[] = null;
    public regularExpressionConstraints: FormFieldRegularExpressionConstraint[] = null;
    public cascadingDropdownConstraints: FormFieldCascadingDropdownConstraint[] = null;
    public dateRangeConstraints: FormFieldDateRangeConstraint[] = null;
    public numericRangeConstraints: FormFieldNumericRangeConstraint[] = null;
    // New list value-based constraints:
    public simpleListValueConstraints: FormFieldListValuesConstraint[] = null;
    public cascadingListValueConstraints: FormFieldListValuesConstraint[] = null;  

    public numUnsavedSelectOptionsConstraints: number = 0;
    public numUnsavedRegularExpressionConstraints: number = 0;
    public numUnsavedCascadingDropdownConstraints: number = 0;
    public numUnsavedDateRangeConstraints: number = 0;
    public numUnsavedNumericRangeConstraints: number = 0;
    // New list value-based constraints:
    public numUnsavedSimpleListValueConstraints: number = 0;
    public numUnsavedCascadingListValueConstraints: number = 0;

    // Method(s).
    public setUnsavedConstraintIdValuesToZero(): void {
        FormFieldConstraintsCache.doSetUnsavedConstraintIdValuesToZero(this.selectOptionsConstraints);
        FormFieldConstraintsCache.doSetUnsavedConstraintIdValuesToZero(this.regularExpressionConstraints);
        FormFieldConstraintsCache.doSetUnsavedConstraintIdValuesToZero(this.cascadingDropdownConstraints);
        FormFieldConstraintsCache.doSetUnsavedConstraintIdValuesToZero(this.dateRangeConstraints);
        FormFieldConstraintsCache.doSetUnsavedConstraintIdValuesToZero(this.numericRangeConstraints);
        // New list value-based constraints:
        FormFieldConstraintsCache.doSetUnsavedConstraintIdValuesToZero(this.simpleListValueConstraints);
        FormFieldConstraintsCache.doSetUnsavedConstraintIdValuesToZero(this.cascadingListValueConstraints);
    }

    // Helper methods.
    private static doSetUnsavedConstraintIdValuesToZero(listOfConstraints: IFormFieldConstraint[]): void {
        if ((listOfConstraints != null) && (listOfConstraints.length > 0)) {
            for (let index: number = 0; index < listOfConstraints.length; index++) {
                let constraint: IFormFieldConstraint = listOfConstraints[index];
                if (constraint.id < 0)
                    constraint.id = 0;
            }
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class CachedFormFieldService extends CollectApiServiceBase<FormField>{
    // Properties.
    private constraintsCache: FormFieldConstraintsCache = new FormFieldConstraintsCache();
    private formFieldService: FormFieldService = null;
    private cacheEnabled: boolean = true; //false;

    public constructor(http: HttpClient,
        formFieldService: FormFieldService,
        progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'formField', FormField)

        this.formFieldService = formFieldService;
    }

    // Cache-related methods.
    // Enable/disable the cache.
    public enableCache(): void {
        this.cacheEnabled = true;
    }
    public disableCache(): void {
        this.cacheEnabled = false;
    }
    public get cacheIsEnabled(): boolean {
        return this.cacheEnabled;
    }

    // Clear the entire cache.
    public clearCache(): void {
        this.constraintsCache = new FormFieldConstraintsCache();
    }

    // Set unsaved constraint id values to zero.
    public setUnsavedConstraintIdValuesToZero(form: Form): void {
        if (this.cacheEnabled) {
            this.constraintsCache.setUnsavedConstraintIdValuesToZero();

            if ((form.formFields != null) && (form.formFields.length > 0)) {
                for (let index: number = 0; index < form.formFields.length; index++) {
                    let formField: FormField = form.formFields[index];

                    CachedFormFieldService.setNewConstraintIdValuesToZero(formField);

                    if ((formField.childFormFields != null) && (formField.childFormFields.length > 0)) {
                        for (let childIndex: number = 0; childIndex < formField.childFormFields.length; childIndex++) {
                            let childFormField: FormField = formField.childFormFields[childIndex];

                            CachedFormFieldService.setNewConstraintIdValuesToZero(childFormField);
                        }
                    }
                }
            }
        }            
    }

    // Manage the five types of form field constraints.
    // Manage select options constraints.
    public getSiteNamedSelectOptions(dataCollectionId: number): Promise<FormFieldSelectOptionsConstraint[]> {
        let result: Promise<FormFieldSelectOptionsConstraint[]> = null;

        if ((!this.cacheEnabled) || (this.constraintsCache.selectOptionsConstraints == null)) {
            result = new Promise<FormFieldSelectOptionsConstraint[]>((resolve, reject) => {
                this.formFieldService.getSiteNamedSelectOptions(dataCollectionId).then(response => {
                    this.constraintsCache.selectOptionsConstraints = response;

                    resolve(this.constraintsCache.selectOptionsConstraints);
                });
            });
        } else {
            result = new Promise<FormFieldSelectOptionsConstraint[]>((resolve, reject) => {
                resolve(this.constraintsCache.selectOptionsConstraints);
            });
        }

        return result;
    }

    public updateOrCreateSelectOptionsConstraint(updatedConstraint: FormFieldSelectOptionsConstraint): boolean {
        let existingConstraint: FormFieldSelectOptionsConstraint =
            <FormFieldSelectOptionsConstraint>CachedFormFieldService.findExistingConstraint(updatedConstraint, this.constraintsCache.selectOptionsConstraints);
        if (existingConstraint != null)
            existingConstraint.copy(updatedConstraint);
        else {
            if (this.constraintsCache.selectOptionsConstraints == null)
                this.constraintsCache.selectOptionsConstraints = [];

            let notYetSavedId: number = -(++this.constraintsCache.numUnsavedSelectOptionsConstraints);
            updatedConstraint.id = notYetSavedId;
            this.constraintsCache.selectOptionsConstraints.push(updatedConstraint);
        }

        return (existingConstraint != null ? true : false);
    }

    // Manage regular expression constraints.
    public getSiteNamedRegularExpressions(dataCollectionId: number): Promise<FormFieldRegularExpressionConstraint[]> {
        let result: Promise<FormFieldRegularExpressionConstraint[]> = null;

        if ((!this.cacheEnabled) || (this.constraintsCache.regularExpressionConstraints == null)) {
            result = new Promise<FormFieldRegularExpressionConstraint[]>((resolve, reject) => {
                this.formFieldService.getSiteNamedRegularExpressions(dataCollectionId).then(response => {
                    this.constraintsCache.regularExpressionConstraints = response;

                    resolve(this.constraintsCache.regularExpressionConstraints);
                });
            });
        } else {            
            result = new Promise<FormFieldRegularExpressionConstraint[]>((resolve, reject) => {
                resolve(this.constraintsCache.regularExpressionConstraints);
            });
        }

        return result;
    }

    public updateOrCreateRegularExpressionConstraint(updatedConstraint: FormFieldRegularExpressionConstraint): boolean {
        let existingConstraint: FormFieldRegularExpressionConstraint =
            <FormFieldRegularExpressionConstraint>CachedFormFieldService.findExistingConstraint(updatedConstraint, this.constraintsCache.regularExpressionConstraints);
        if (existingConstraint != null)
            existingConstraint.copy(updatedConstraint);
        else {
            if (this.constraintsCache.regularExpressionConstraints == null)
                this.constraintsCache.regularExpressionConstraints = [];

            let notYetSavedId: number = -(++this.constraintsCache.numUnsavedRegularExpressionConstraints);
            updatedConstraint.id = notYetSavedId;
            this.constraintsCache.regularExpressionConstraints.push(updatedConstraint);
        }

        return (existingConstraint != null ? true : false);
    }

    // Manage named cascading dropdown constraints.
    public getSiteNamedCascadingDropdowns(dataCollectionId: number): Promise<FormFieldCascadingDropdownConstraint[]> {
        let result: Promise<FormFieldCascadingDropdownConstraint[]> = null;

        if ((!this.cacheEnabled) || (this.constraintsCache.cascadingDropdownConstraints == null)) {
            result = new Promise<FormFieldCascadingDropdownConstraint[]>((resolve, reject) => {
                this.formFieldService.getSiteNamedCascadingDropdowns(dataCollectionId).then(response => {
                    this.constraintsCache.cascadingDropdownConstraints = response;

                    resolve(this.constraintsCache.cascadingDropdownConstraints);
                });
            });
        } else {
            result = new Promise<FormFieldCascadingDropdownConstraint[]>((resolve, reject) => {
                resolve(this.constraintsCache.cascadingDropdownConstraints);
            });
        }

        return result;
    }

    public updateOrCreateCascadingDropdownConstraint(updatedConstraint: FormFieldCascadingDropdownConstraint): boolean {
        let existingConstraint: FormFieldCascadingDropdownConstraint =
            <FormFieldCascadingDropdownConstraint>CachedFormFieldService.findExistingConstraint(updatedConstraint, this.constraintsCache.cascadingDropdownConstraints);
        if (existingConstraint != null)
            existingConstraint.copy(updatedConstraint);
        else {
            if (this.constraintsCache.cascadingDropdownConstraints == null)
                this.constraintsCache.cascadingDropdownConstraints = [];

            let notYetSavedId: number = -(++this.constraintsCache.numUnsavedCascadingDropdownConstraints);
            updatedConstraint.id = notYetSavedId;
            this.constraintsCache.cascadingDropdownConstraints.push(updatedConstraint);
        }

        return (existingConstraint != null ? true : false);
    }

    // Manage named date range constraints.
    public getSiteNamedDateRanges(dataCollectionId: number): Promise<FormFieldDateRangeConstraint[]> {
        let result: Promise<FormFieldDateRangeConstraint[]> = null;

        if ((!this.cacheEnabled) || (this.constraintsCache.dateRangeConstraints == null)) {
            result = new Promise<FormFieldDateRangeConstraint[]>((resolve, reject) => {
                this.formFieldService.getSiteNamedDateRanges(dataCollectionId).then(response => {
                    this.constraintsCache.dateRangeConstraints = response;

                    resolve(this.constraintsCache.dateRangeConstraints);
                });
            });
        } else {
            result = new Promise<FormFieldDateRangeConstraint[]>((resolve, reject) => {
                resolve(this.constraintsCache.dateRangeConstraints);
            });
        }

        return result;
    }

    public updateOrCreateDateRangeConstraint(updatedConstraint: FormFieldDateRangeConstraint): boolean {
        let existingConstraint: FormFieldDateRangeConstraint =
            <FormFieldDateRangeConstraint>CachedFormFieldService.findExistingConstraint(updatedConstraint, this.constraintsCache.dateRangeConstraints);
        if (existingConstraint != null)
            existingConstraint.copy(updatedConstraint);
        else {
            if (this.constraintsCache.dateRangeConstraints == null)
                this.constraintsCache.dateRangeConstraints = [];

            let notYetSavedId: number = -(++this.constraintsCache.numUnsavedDateRangeConstraints);
            updatedConstraint.id = notYetSavedId;
            this.constraintsCache.dateRangeConstraints.push(updatedConstraint);
        }

        return (existingConstraint != null ? true : false);
    }

    // Manage named numeric range constraints.
    public getSiteNamedNumericRanges(dataCollectionId: number): Promise<FormFieldNumericRangeConstraint[]> {
        let result: Promise<FormFieldNumericRangeConstraint[]> = null;

        if ((!this.cacheEnabled) || (this.constraintsCache.numericRangeConstraints == null)) {
            result = new Promise<FormFieldNumericRangeConstraint[]>((resolve, reject) => {
                this.formFieldService.getSiteNamedNumericRanges(dataCollectionId).then(response => {
                    this.constraintsCache.numericRangeConstraints = response;

                    resolve(this.constraintsCache.numericRangeConstraints);
                });
            });
        } else {
            result = new Promise<FormFieldNumericRangeConstraint[]>((resolve, reject) => {
                resolve(this.constraintsCache.numericRangeConstraints);
            });
        }

        return result;
    }

    public updateOrCreateNumericRangeConstraint(updatedConstraint: FormFieldNumericRangeConstraint): boolean {
        let existingConstraint: FormFieldNumericRangeConstraint =
            <FormFieldNumericRangeConstraint>CachedFormFieldService.findExistingConstraint(updatedConstraint, this.constraintsCache.numericRangeConstraints);
        if (existingConstraint != null)
            existingConstraint.copy(updatedConstraint);
        else {
            if (this.constraintsCache.numericRangeConstraints == null)
                this.constraintsCache.numericRangeConstraints = [];

            let notYetSavedId: number = -(++this.constraintsCache.numUnsavedNumericRangeConstraints);
            updatedConstraint.id = notYetSavedId;
            this.constraintsCache.numericRangeConstraints.push(updatedConstraint);
        }

        return (existingConstraint != null ? true : false);
    }

    // Define methods for managing the two new types of list value constraints:  simple list value constraints and cascading list value constraints.
    public getSiteNamedSimpleListValuesConstraints(dataCollectionId: number): Promise<FormFieldListValuesConstraint[]> {
        let result: Promise<FormFieldListValuesConstraint[]> = null;

        if ((!this.cacheEnabled) || (this.constraintsCache.simpleListValueConstraints == null)) {
            result = new Promise<FormFieldListValuesConstraint[]>((resolve, reject) => {
                this.formFieldService.getSiteNamedSimpleListValueConstraints(dataCollectionId).then(response => {
                    this.constraintsCache.simpleListValueConstraints = response;

                    resolve(this.constraintsCache.simpleListValueConstraints);
                });
            });
        } else {
            result = new Promise<FormFieldListValuesConstraint[]>((resolve, reject) => {
                resolve(this.constraintsCache.simpleListValueConstraints);
            });
        }

        return result;
    }

    public updateOrCreateSimpleListValuesConstraint(updatedConstraint: FormFieldListValuesConstraint): boolean {
        let existingConstraint: FormFieldListValuesConstraint =
            <FormFieldListValuesConstraint>CachedFormFieldService.findExistingConstraint(updatedConstraint, this.constraintsCache.simpleListValueConstraints);
        if (existingConstraint != null)
            existingConstraint.copy(updatedConstraint);
        else {
            if (this.constraintsCache.simpleListValueConstraints == null)
                this.constraintsCache.simpleListValueConstraints = [];

            let notYetSavedId: number = -(++this.constraintsCache.numUnsavedSimpleListValueConstraints);
            updatedConstraint.id = notYetSavedId;
            this.constraintsCache.simpleListValueConstraints.push(updatedConstraint);
        }

        return (existingConstraint != null ? true : false);
    }

    public getSiteNamedCascadingListValuesConstraints(dataCollectionId: number): Promise<FormFieldListValuesConstraint[]> {
        let result: Promise<FormFieldListValuesConstraint[]> = null;

        if ((!this.cacheEnabled) || (this.constraintsCache.cascadingListValueConstraints == null)) {
            result = new Promise<FormFieldListValuesConstraint[]>((resolve, reject) => {
                this.formFieldService.getSiteNamedCascadingListValueConstraints(dataCollectionId).then(response => {
                    this.constraintsCache.cascadingListValueConstraints = response;

                    resolve(this.constraintsCache.cascadingListValueConstraints);
                });
            });
        } else {
            result = new Promise<FormFieldListValuesConstraint[]>((resolve, reject) => {
                resolve(this.constraintsCache.cascadingListValueConstraints);
            });
        }

        return result;
    }

    public updateOrCreateCascadingListValuesConstraint(updatedConstraint: FormFieldListValuesConstraint): boolean {
        let existingConstraint: FormFieldListValuesConstraint =
            <FormFieldListValuesConstraint>CachedFormFieldService.findExistingConstraint(updatedConstraint, this.constraintsCache.cascadingListValueConstraints);
        if (existingConstraint != null)
            existingConstraint.copy(updatedConstraint);
        else {
            if (this.constraintsCache.cascadingListValueConstraints == null)
                this.constraintsCache.cascadingListValueConstraints = [];

            let notYetSavedId: number = -(++this.constraintsCache.numUnsavedCascadingListValueConstraints);
            updatedConstraint.id = notYetSavedId;
            this.constraintsCache.cascadingListValueConstraints.push(updatedConstraint);
        }

        return (existingConstraint != null ? true : false);
    }

    // A method for getting a cascading drop-down configuration.
    public getCascadingDropdownConstraintConfiguration(constraintId: number, wrapFieldDataInDoubleQuotes: boolean, fieldDelimiter: string = ','): Promise<CascadingDropdownConfigRow[]> {
        return this.formFieldService.getCascadingDropdownConstraintConfiguration(constraintId, wrapFieldDataInDoubleQuotes, fieldDelimiter);
    }

    // Helper methods.
    private static setNewConstraintIdValuesToZero(formField: FormField): void {
        if (formField.selectOptionsConstraintId < 0)
            formField.selectOptionsConstraintId = 0;
        if (formField.regularExpressionConstraintId < 0)
            formField.regularExpressionConstraintId = 0;
        if (formField.cascadingDropdownConstraintId < 0)
            formField.cascadingDropdownConstraintId = 0;
        if (formField.dateRangeConstraintId < 0)
            formField.dateRangeConstraintId = 0;
        if (formField.numericRangeConstraintId < 0)
            formField.numericRangeConstraintId = 0;
    }

    private static findExistingConstraint(updatedConstraint: IFormFieldConstraint, constraintsList: IFormFieldConstraint[]): IFormFieldConstraint {
        let existingConstraint: IFormFieldConstraint = null;

        if (constraintsList != null) {
            // Look for an existing constraint in two steps to keep things simple:
            //
            // 1. Look for a saved constraint, with an 'id' value great than zero; and
            // 2. If necessary, look for an unsaved constraint, with an 'id' value less than zero.
            existingConstraint = constraintsList.find(c => (updatedConstraint.id > 0) && (c.id == updatedConstraint.id));
            if (!existingConstraint) {
                //existingConstraint = constraintsList.find(c => (c.name == updatedConstraint.name));
                existingConstraint = constraintsList.find(c => (updatedConstraint.id < 0) && (c.id == updatedConstraint.id));
            }                
        }

        return existingConstraint;
    }

}
