import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';

import { ElementTypeMetadata } from './element-type-metadata.model';
import { HtmlPropertyValue } from './html-property-value.model';
import { ElementCountExpected } from './element-count-expected.enum';
//import { HtmlElementTypeService } from '../../services/html-element-type.service';
import { ComponentMethodMetadata } from './component-methods-metadata.model';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
import { HtmlElementTypeNames } from './html-element-type-names.model';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { EditInputParametersDialog, EditInputParametersDialogManager, ParameterFormControlInfo } from '../../dialogs/edit-input-parameters/edit-input-parameters.dialog';
//import { AdditionalElementInfo } from './additional-element-info.model';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
import { HtmlElementInfo } from './html-element-info.model';

// Note:  class methods are not HTML element types but they are scriptable,
//        so we are going to treat them like HTML element types.  We
//        should probably change the naming convention to refer to
//        scriptable element types rather than HTML element types.
export class ComponentMethodElementTypeMetadata extends ElementTypeMetadata {
    public constructor(private dialog: MatDialog) {
        super(HtmlElementTypeNames.componentMethods_metadataKey, HtmlElementTypeNames.componentMethods_metadataKey, ElementCountExpected.None);

        this.prettyElementTypeTitle = 'Actions';//' with Inputs';
    }

    public getTitle = (driver: IBrowserDriver, element: object): string => {
        let methodMetadata: ComponentMethodMetadata = <ComponentMethodMetadata>element;
        return methodMetadata.userFriendlyName != null ? methodMetadata.userFriendlyName : methodMetadata.methodName;
    }

    public hasActions = (driver: IBrowserDriver, element: object): boolean => {
        return true;
    }
    public getActions = (driver: IBrowserDriver, element: object): string[] => {
        let methodMetadata: ComponentMethodMetadata = <ComponentMethodMetadata>element;
        return methodMetadata.userFriendlyName != null ? [methodMetadata.userFriendlyName] : [methodMetadata.methodName];
    }

    public click(driver: IBrowserDriver, recorderService: ITestActionRecorderService, component: ITestableComponent, htmlElementInfo: HtmlElementInfo, element: object, getClickableElementFunction: (element: object) => object): void {
        let methodMetadata: ComponentMethodMetadata = <ComponentMethodMetadata>element;
        if ((methodMetadata.argumentTypeNames == null) || (methodMetadata.argumentTypeNames.length == 0)) {
            //recorderService.callMethod(htmlElementInfo.elementTitle, methodMetadata, null);
            //recorderService.callMethod(component, methodMetadata.methodName, []);
            recorderService.callMethod(methodMetadata.methodName, []);

            component[methodMetadata.methodName]();
        } else {
            // Open an EditInputParametersComponent dialogue.
            let dialogManager: EditInputParametersDialogManager = new EditInputParametersDialogManager(this.dialog, methodMetadata);
            const dialogRef: MatDialogRef<EditInputParametersDialog, any> = dialogManager.openDialog();
            dialogRef.afterClosed().subscribe(parameterValueInfo => {
                if (parameterValueInfo != null) {
                    let parameters: any[] = this.prepareArrayOfCallParams(parameterValueInfo, 5);

                    let paramsAsStrings: string[] = [];
                    for (let index: number = 0; index < parameters.length; index++) {
                        let paramAsString: string = parameters[index] != null ? parameters[index].toString() : null;
                        if (paramAsString != null)
                            paramsAsStrings.push(paramAsString);
                        else
                            break;
                    }
                    //recorderService.callMethod(htmlElementInfo.elementTitle, methodMetadata, paramsAsStrings);
                    //recorderService.callMethod(component, methodMetadata.methodName, paramsAsStrings);
                    recorderService.callMethod(methodMetadata.methodName, paramsAsStrings);

                    component[methodMetadata.methodName](parameters[0], parameters[1], parameters[2], parameters[3], parameters[4]);
                }
            });
        }
    }
    /*
    public recordActionClick(recorderService: ITestActionRecorderService, htmlElementInfo: HtmlElementInfo): void {
        //recorderService.elementActionClicked(htmlElementInfo.elementTitle);
        let methodMetadata: ComponentMethodMetadata = <ComponentMethodMetadata>htmlElementInfo.element;
        recorderService.callMethod(htmlElementInfo.elementTitle, methodMetadata, null);
    }
    */

    public hasMethods(driver: IBrowserDriver, element: object): boolean {
        return true;
    }
    public getMethods(driver: IBrowserDriver, element: object): ComponentMethodMetadata[] {
        // TO DO:  CODE THIS METHOD.

        return [];
    }

    public hasProperties = (driver: IBrowserDriver, element: object): boolean => {
        let methodMetadata: ComponentMethodMetadata = <ComponentMethodMetadata>element;
        let hasProperties: boolean = (methodMetadata.argumentTypeNames != null) && (methodMetadata.argumentTypeNames.length > 0);
        return hasProperties;
    }
    public getProperties = (driver: IBrowserDriver, element: object): HtmlPropertyValue[] => {
        let properties: HtmlPropertyValue[] = [];

        let methodMetadata: ComponentMethodMetadata = <ComponentMethodMetadata>element;
        for (let index: number = 0; index < methodMetadata.argumentTypeNames.length; index++) {
            let propertyValue: HtmlPropertyValue = new HtmlPropertyValue('paramType', methodMetadata.argumentTypeNames[index]);
            properties.push(propertyValue);
        }

        return properties;
    }

    private prepareArrayOfCallParams(parameterValues: ParameterFormControlInfo[], expectedArraySize: number): any[] {
        let parameters: any[] = [];

        let index: number = 0;
        for (; index < parameterValues.length; index++) {
            let value: any = parameterValues[index].enteredValue;
            parameters.push(value);
        }
        if (index < expectedArraySize) {
            for (; index < expectedArraySize; index++) {
                //parameters.push('');
                parameters.push(null);
            }                
        }

        return parameters;
    }
}
