import { Injectable, ElementRef } from '@angular/core';
import { MatInput } from '@angular/material/input';

import { IBrowserDriver } from '../interfaces/ibrowser-driver.interface';
import { ITestableComponent } from '../interfaces/itestable-component.interface';

declare let $: any; // jQuery
//require('jquery-sendkeys');

class CharacterToAsciiCode {
    private static numericBaseCode: number = 48;
    private static alphaBaseCode: number = 65;
    private static spaceCode: number = 32;

    private static numbericCharacters: string = '0123456789';
    private static alphaCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    public static charToAsciiCode(char: string): number {
        let asciiCode: number = -1;

        if (CharacterToAsciiCode.alphaCharacters.indexOf(char) >= 0) {
            asciiCode = CharacterToAsciiCode.alphaBaseCode + CharacterToAsciiCode.alphaCharacters.indexOf(char);
        } else if (CharacterToAsciiCode.numbericCharacters.indexOf(char) >= 0) {
            asciiCode = CharacterToAsciiCode.numericBaseCode + CharacterToAsciiCode.numbericCharacters.indexOf(char)
        } else if (char == ' ') {
            asciiCode = CharacterToAsciiCode.spaceCode;
        } else {
            // TO DO:  CODE THIS METHOD.
        }

        return asciiCode;
    }
}

@Injectable({
    providedIn: 'root'
})
export class JQueryBrowserDriverService implements IBrowserDriver {
    // Properties.

    // Constructor.
    public constructor() {
    }

    // Service methods.
    // Find elements.
    public findElementsIn(containingElementSelector: string, elementsToFindSelector: string): object {
        try {
            let elementsToFind: object = $(containingElementSelector).find(elementsToFindSelector);
            return elementsToFind;
        } catch (error) {
            //console.log(error);
            return null;
        }              
    }

    public findElementIn(element: object, elementToFindSelector: string): object {
        let elementsToFind: object = $(element).find(elementToFindSelector);
        let elementToFind: object = (elementsToFind['length'] != null) && (elementsToFind['length'] > 0) ? elementsToFind[0] : null;
        return elementToFind;
    }

    // Methods used for multiple types of elements.
    public getTextFor(element: object, optionalTextElementSelector: string = null): string {
        let text: string = optionalTextElementSelector != null ? $(element).find(optionalTextElementSelector).text() : $(element).text();
        return text;
    }
    public getValueFor(element: object): string {
        let value: string = $(element).val();
        return value;
    }
    public getAttributeValueFor(element: object, attributeName: string): string {
        let value: string = $(element).attr(attributeName);
        return value;
    }

    // Method(s) used for <a/> tags/links.
    public clickLink(component: ITestableComponent, element: object): void {
        $(element).get(0).click();
    }

    // Methods for button input controls.
    public getButtonTitle(element: object): string {
        let title: string = $(element).text();

        if ((title == null) || (title.trim() == '')) {
            let scriptName: string = $(element).data('scriptname');
            if (scriptName != null)
                title = scriptName;
        }

        return title;
    }
    public clickButton(component: ITestableComponent, element: object): void {
        $(element).click();
    }
    public doubleClickButton(component: ITestableComponent, element: object): void {
        $(element).dblclick();
    }

    // Method(s) for <mat-icon/> elements.
    public getMatIconTitle(element: object): string {
        let title: string = $(element).text();

        if ((title == null) || (title.trim() == '')) {
            //let scriptName: string = $(element).data('scriptname');
            let scriptName: string = $(element).data('matIconName');
            if (scriptName != null)
                title = scriptName;
        }

        return title;
    }

    // Methods used for text input controls.
    public getInputTitle(element: object): string {
        let title: string = $(element).attr('id');

        let scriptName: string = $(element).data('scriptname');
        if (scriptName != null)
            title = scriptName;
        else {
            scriptName = $(element).attr('name');
            if (scriptName != null)
                title = scriptName;
        }

        return title;
    }

    public sendKeysTo(element: object, matInput: MatInput, value: string): void {
        //$(element).sendkeys(value);

        matInput.ngControl.control.setValue(value);

        // TO DO:  TEST, RECODE THIS METHOD.
        if (value != null) {
            for (let index: number = 0; index < value.length; index++) {
                let char = value.charAt(index);
                let charCode: number = CharacterToAsciiCode.charToAsciiCode(char);
                $(element).trigger({ type: 'keypress', which: charCode, keyCode: charCode });
            }
        } else {
            let charCode: number = CharacterToAsciiCode.charToAsciiCode(' ');
            $(element).trigger({ type: 'keypress', which: charCode, keyCode: charCode });
        }
        $(element).val(value).trigger('input');
        $(element).val(value).triggerHandler('change');
        //angular.trigger
    }

    // Methods for managing data attributes.
    public setDataAttributeValue(element: object, key: string, value: string) {
        $(element).data(key, value);
    }
    public getDataAttributeValue(element: object, key: string) {
        let value: string = $(element).data(key);
        return value;
    }

    public getAncestor(element: object, numLevelsUp: number): object {
        let ancestor: object = element;

        let index: number = 0;
        for (; index < numLevelsUp; index++) {
            ancestor = $(ancestor).parent();
            if (ancestor == null)
                break;
        }

        if (index < numLevelsUp)
            ancestor = null;

        return ancestor;
    }
    public getParent(element: object): object {
        return this.getAncestor(element, 1);
    }
}
