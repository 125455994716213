import { Directive, ViewContainerRef, Input, OnInit, OnDestroy } from '@angular/core';

@Directive({
    selector: '[dynamic-component-host]',
})
export class DynamicComponentHostDirective implements OnInit, OnDestroy {
    // Define instance data.
    @Input() name: string = null;

    // Implement my constructor.
    public constructor(public viewContainerRef: ViewContainerRef) {
        return;
    }

    // Life cycle methods.
    public ngOnInit(): void {
        return;
    }

    public ngAfterContentInit(): void {
        return;
    }

    public ngOnDestroy(): void {
        return;
    }
    // End life cycle methods.

    // Define getter methods.
    public get Name(): string {
        return (this.name);
    }
    // End getter methods.
}
