import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from "class-transformer";
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { PermissionsPreview } from '../models/permissions-preview-models/permissions-preview.model';
import { PermissionsPreviewVirtualFolderHierarchyRequest } from '../models/permissions-preview-models/perPreVirtualFolderHierarchyRequest.model';
import { PermissionsPreviewAllVirtualFoldersHierarchyRequest } from '../models/permissions-preview-models/per-pre-all-virtual-folders-hierarchy-request.model';
import { ProgressIndicatorService, ProgressConfig } from './progress-indicator.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionsPreviewService extends CollectApiServiceBase<PermissionsPreview> {
    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'permissionsPreview', PermissionsPreview)
    }

    public formatResponse(data: PermissionsPreview): PermissionsPreview {
        var obj = plainToClass(PermissionsPreview, data);

        return (obj);
    }

    public generatePermissionsPreview(permissionsPreview: PermissionsPreview,
        progressConfig: ProgressConfig = ProgressConfig.default()): Promise<PermissionsPreview> {
        let msg = progressConfig.msgDuring || this.progressMsg('Generating preview');
        this.updateProgress(50, 75, msg);

        var url = `${this.url}/${this.endpoint}`;

        return this.http.post<PermissionsPreview>(url, permissionsPreview)
            .toPromise()
            .then(response => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Generated');
                this.updateProgress(100, 100, msg);

                //let result: PermissionsPreview = plainToClass(PermissionsPreview, response);
                let result = new PermissionsPreview(response);
                return result;
            }).catch(this.handleError);
    }

    public generateVirtualFolderHierarchyRecords(request: PermissionsPreviewVirtualFolderHierarchyRequest): Promise<PermissionsPreviewVirtualFolderHierarchyRequest> {
        var url = `${this.url}/${this.endpoint}/requestVirtualFolderHierarchyRecords`;

        return this.http.post<PermissionsPreviewVirtualFolderHierarchyRequest>(url, request)
            .toPromise()
            .then(response => {
                //let result = plainToClass(PermissionsPreviewVirtualFolderHierarchyRequest, response);
                let result = new PermissionsPreviewVirtualFolderHierarchyRequest(response);
                return result;
            }).catch(this.handleError);
    }

    public generateAllVirtualFoldersHierarchyRecords(request: PermissionsPreviewAllVirtualFoldersHierarchyRequest): Promise<PermissionsPreviewAllVirtualFoldersHierarchyRequest> {
        var url = `${this.url}/${this.endpoint}/requestAllVirtualFoldersHierarchyRecords`;

        return this.http.post<PermissionsPreviewAllVirtualFoldersHierarchyRequest>(url, request)
            .toPromise()
            .then(response => {
                let result = new PermissionsPreviewAllVirtualFoldersHierarchyRequest(response);
                return result;
            }).catch(this.handleError);
    }

    /*
    public generateAllVirtualFoldersHierarchyRecordsAsObservable(request: PermissionsPreviewAllVirtualFoldersHierarchyRequest): Observable<PermissionsPreviewAllVirtualFoldersHierarchyRequest> {
        var url = `${this.url}/${this.endpoint}/requestAllVirtualFoldersHierarchyRecords`;

        let result: Observable<PermissionsPreviewAllVirtualFoldersHierarchyRequest> = this.http.post<PermissionsPreviewAllVirtualFoldersHierarchyRequest>(url, request);
        result.subscribe(response => {
            let result = new PermissionsPreviewAllVirtualFoldersHierarchyRequest(response);
            return result;
        }); //.catch(this.handleError);

        return result;
    }
    */
}
