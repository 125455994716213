<mat-form-field appearance="outline"
                [floatLabel]="'always'"
                class="full-width"
                [hideRequiredMarker]="this.HideRequiredMarker"
                >

    <mat-label class="field-label">{{this.Title}} <span *ngIf="this.Required" style="color:red">*</span></mat-label>


    <div *ngIf="this.ShowChipList; else noChipList">
        <div class="chip-list-wrapper">
            <mat-chip-grid #chipList 
            [formControl]="chipControl" 
            [required]="this.Required" 
            >
                <ng-container *ngFor="let select of SelectedData">
                    <mat-chip-row (keyup.delete)="removeChip(select)"
                            [removable]="true" 
                            class="mat-elevation-z2 regular-chip">
                        {{ select.item }}
                        <mat-icon class="mat-chip-remove" (click)="removeChip(select)">cancel</mat-icon>
                    </mat-chip-row>
                </ng-container>
                <mat-chip-row class="mat-elevation-z3 remove-all-chip"
                        *ngIf="this.ShowRemoveAll" (click)="this.removeAllChips()" (keyup.enter)="this.removeAllChips()">
                    Remove All
                </mat-chip-row>

            </mat-chip-grid>
        </div>

        <div class="input-group">
            <input #trigger="matAutocompleteTrigger"
                #autocompleteInput
                (blur)="this.blurred()"
                matInput
                type="text"
                class="input-styling full-width"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [formControl]="selectControl"
                (focusout)="this.clearOptions()"
                (keydown.backspace)="this.preventBackspaceExitingFocus()">

            <mat-icon (click)="this.setTriggerStatusIconClick()"
                    class="custom-icon custom-form-field-icon">
                {{this.DropDownIcon}}
            </mat-icon>
        </div>

        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let data of filteredData | async">
                <div (click)="optionClicked($event, data)">
                    <mat-checkbox [checked]="data.selected"
                                  (change)="toggleSelection(data)"
                                  (click)="$event.stopPropagation()">
                        {{ data.item }}
                    </mat-checkbox>
                </div>
            </mat-option>
        </mat-autocomplete>
    </div> 

    <ng-template #noChipList> 
        <div class="input-group">
            <input #trigger="matAutocompleteTrigger"
                #autocompleteInput
                (blur)="this.singleBlurred()"
                matInput
                type="text"
                class="input-styling full-width"
                [matAutocomplete]="auto" 
                [required]="this.Required" 
                [formControl]="selectControl"
                (focusout)="this.clearOptions()"
                (keydown.backspace)="this.preventBackspaceExitingFocus()">

            <!-- <mat-icon (click)="this.setTriggerStatusIconClick()"
                    class="custom-icon custom-form-field-icon">
                {{this.DropDownIcon}}
            </mat-icon> -->
        </div> 

        <mat-autocomplete #auto="matAutocomplete">
            <!-- VNEXT-538: KLW - Implementing Autocomplete set the property attribute of value to show the selected option after it's been selected -->
            <mat-option *ngFor="let data of filteredData | async" [value]="data.item">
                <div (click)="optionClickedSingle($event, data)">
                    {{ data.item }}
                </div>
            </mat-option>
        </mat-autocomplete>
    </ng-template> 

    <!-- <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let data of filteredData | async">
            <div (click)="optionClicked($event, data)">
                <mat-checkbox [checked]="data.selected"
                              (change)="toggleSelection(data)"
                              (click)="$event.stopPropagation()">
                    {{ data.item }}
                </mat-checkbox>
            </div>
        </mat-option>
    </mat-autocomplete> -->

    <!--<mat-hint>{{this.TotalCount}}</mat-hint>-->
</mat-form-field>







