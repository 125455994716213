import { Observable } from "rxjs";
import { GridRowDef } from '../../../models/grid/grid-row.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { GridDataResult } from "@progress/kendo-angular-grid";
import { State } from "@progress/kendo-data-query";

// This class was created in initial integration with Kendo Grid
export class DataUsedWithKendoGrid {
    public editedRowIndex: number;
    private _selectedGridRow: GridRowDef = null;
    private elementsInInitialState: FormInstanceElement[];
    private currentDataItem: any;

    public view: Observable<GridDataResult>;
    public gridState: State = {
        sort: [],
        skip: 0,
        take: 50,
    };

    public get CurrentDataItem(): any {
        return this.currentDataItem;
    }

    public set CurrentDataItem(value: any) {
        this.currentDataItem = value;
    }

    public get selectedGridRow(): GridRowDef {
        return this._selectedGridRow;
    }

    public set selectedGridRow(value: GridRowDef) {
        for (let wrapper of value.FormInstanceElementWrappers) {
            // TOTAL HACK FOR NOW. I CANNOT FIGURE OUT WHY THIS IS NOT GETTING SET FOR FLEX FIELDS!!!!!!!!!!!!!!!!!!!!!
            if (!wrapper.formInstanceElement.valueType) {
                wrapper.formInstanceElement.valueType = 'text';
            }
        }
        this._selectedGridRow = value;
        
    }

    // Used to reset on cancelation of an edit
    public backupForCancel(): void {
        // clone the FormInstanceElements without referencing them
        this.elementsInInitialState = JSON.parse(JSON.stringify(this.selectedGridRow.FormInstanceElementWrappers.map(x => { return x.formInstanceElement; }))) as FormInstanceElement[];
    }

    public get ElementsInInitialState(): FormInstanceElement[] {
        return this.elementsInInitialState;
    };
}
