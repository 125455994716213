import { FormField } from '../../../../../models/form-builder/form-field.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../../../interfaces/iform-field-constraint.interface';
//import { FormFieldSimpleListValuesConstraint, FormFieldSimpleListValuesConstraintWithMetrics } from '../../../../../models/form-builder/field-constraints/field-simple-list-values-constraint.model';
import { FormFieldListValuesConstraint, FormFieldListValuesConstraintWithMetrics } from '../../../../../models/form-builder/field-constraints/form-field-list-values-constraint.model';
import { CachedFormFieldService } from '../../../../../services/form-field-with-cache.service';
import { IFormFieldConstraintLiaison } from './ifield-constraint-liason.interface';
import { FieldConstraintLiaisonBase } from './field-constraint-liaison-base';
import { SiteFormFieldConstraintProperties } from '../../../../../models/form-builder/field-constraints/site-form-field-constraint-props.model';
import { DropDownFieldType, RadioButtonsFieldType } from '../../../../../models/form-builder/form-field-types';
import { FormFieldConstraintTypeEnum, FormFieldConstraintTypeForUsersEnum } from '../../../../../enums/form-field-constraint-type.enum';

export class FormFieldSimpleListValueConstraintLiaison extends FieldConstraintLiaisonBase implements IFormFieldConstraintLiaison {
    // Constructor.
    public constructor() {
        super();
    }

    // Implement IFormFieldConstraintLiaison.
    // Getter/setter-like methods.
    public constraintTypeForEndUser(): string {  // A user-friendly constraint type name.
        return FormFieldConstraintTypeForUsersEnum.SimpleListValues;
    }

    public managesConstraintsOfType(): string {
        return FormFieldConstraintTypeEnum.SimpleListValues;
    }

    public getPrimaryPropertyName(): string {
        return 'selectOptions'; // Yes, I am reusing the existing 'selectOptions' FormField property.
    }

    public getConstraintId(formField: FormField): number {
        return formField.listValuesConstraintId;
    }
    public setConstraintId(formField: FormField, value: number): void {
        formField.listValuesConstraintId = value;
    }

    public getConstraintName(formField: FormField): string {
        //return formField.listValuesConstraintName;
        return formField.selectOptionsConstraintName;
    }
    public setConstraintName(formField: FormField, value: string): void {
        formField.listValuesConstraintName = value;
    }

    public getConstraintValue(formField: FormField): string {
        return formField.simpleListValuesConstraintValue;
    }
    public setConstraintValue(formField: FormField, value: string): void {
        formField.simpleListValuesConstraintValue = value;
    }

    // not relevant for this field type
    public getConstraintFailureMessage(formField: FormField): string {
        return null;
    }
    public setConstraintFailureMessage(formField: FormField, value: string): void {
        // noop
    }

    public getConstraintFormFieldPropertyValue(formField: FormField): string {
        return formField.selectOptions;
    }

    public copyConstraintValueToCorrespondFormFieldProperty(formField: FormField, value: string): void {
        formField.selectOptions = value;
    }

    // Create a new, empty constraint.
    public createNewConstraint(dataCollectionId: number): IFormFieldConstraintWithMetrics {
        //return new FormFieldListValuesConstraintWithMetrics(dataCollectionId);
        let constraint = FormFieldListValuesConstraintWithMetrics.createSimpleListValuesConstraintFrom(dataCollectionId);
        return constraint;
    }

    public createNewConstraintFrom(constraintParam: IFormFieldConstraint): IFormFieldConstraintWithMetrics {
        let constraint = <FormFieldListValuesConstraint>constraintParam;
        //let newConstraint = new FormFieldListValuesConstraintWithMetrics(constraint.dataCollectionId, constraint.id, constraint.name, constraint.constraintExpression);
        let newConstraint = FormFieldListValuesConstraintWithMetrics.createSimpleListValuesConstraintFrom(constraint.dataCollectionId, constraint.id, constraint.name, constraint.constraintExpression);
        return newConstraint;
    }

    // Package constraint values as a constraint.
    public packageConstraint(dataCollectionId: number, formField: FormField): IFormFieldConstraint {
        //let packagedConstraint = new FormFieldListValuesConstraint(dataCollectionId, formField.selectOptionsConstraintId, formField.selectOptionsConstraintName, formField.selectOptionsConstraintValue);
        let packagedConstraint = FormFieldListValuesConstraint.createSimpleListValuesConstraintFrom(dataCollectionId, formField.selectOptionsConstraintId, formField.selectOptionsConstraintName, formField.selectOptionsConstraintValue);

        return packagedConstraint;
    }
    public updateOrCreateConstraint(cachedFormFieldService: CachedFormFieldService, updatedConstraintParam: IFormFieldConstraint): void {
        let updatedConstraint: FormFieldListValuesConstraint = <FormFieldListValuesConstraint>updatedConstraintParam;
        cachedFormFieldService.updateOrCreateSimpleListValuesConstraint(updatedConstraint);
    }

    // Methods related to applying form field constraint
    // updates to a form field that uses the same constraint.
    public get createNamedConstraintMethodName(): string {
        return 'createSimpleListValuesConstraint';
    }
    public get updateNamedConstraintMethodName(): string {
        return 'updateSimpleListValuesConstraint';
    }
    public get updateOrCreateNamedConstraintMethodName(): string {
        return 'updateOrCreateSimpleListValuesConstraint';
    }
    public get siteNamedConstraintsMethodName(): string {
        return 'getSiteNamedSimpleListValueConstraints';
    }

    public get usedByFormFieldClasses(): string[] {
        return [DropDownFieldType, RadioButtonsFieldType];
    }

    public applyConstraintPropertiesToFormField(constraint: IFormFieldConstraint, formField: FormField) {
        formField.listValuesConstraintId = constraint.id;
        // PHarvey - 6/3/2024 - for VNEXT-1293 - changed formField.listValuesConstraintName to formField.selectOptionsConstraintName
        // The problem was that when editing the properties of a Simple List Values constraint, the name was empty
        // This was because listValuesConstraintName was being set here, but the HTML Template is using [(ngModel)]="field.selectOptionsConstraintName" hence this change
        formField.selectOptionsConstraintName = constraint.name;
        formField.simpleListValuesConstraintValue = constraint.constraintExpression;
        formField.selectOptions = constraint.constraintExpression;
    }

    // A method to create a constraint from the results of an all site named constraints query.
    public createFromConstraintProperties(dataCollectionId: number, constraintProperties: SiteFormFieldConstraintProperties): IFormFieldConstraintWithMetrics {
        //let constraint: FormFieldListValuesConstraintWithMetrics = new FormFieldListValuesConstraintWithMetrics(dataCollectionId, constraintProperties.constraintId, constraintProperties.constraintName, constraintProperties.selectOptionsValue);
        let constraint: FormFieldListValuesConstraintWithMetrics = FormFieldListValuesConstraintWithMetrics.createSimpleListValuesConstraintFrom(dataCollectionId, constraintProperties.constraintId, constraintProperties.constraintName, constraintProperties.selectOptionsValue);
        constraint.useCount = constraintProperties.useCount;
        return constraint;
    }
}
