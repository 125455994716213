import { Type } from 'class-transformer'; 
import { ItemTypeEnum } from '../../enums/item-type.enum';
import { IListItem } from '../../interfaces/ilist-item.interface';
import { DataCollection } from '../site-content/data-collection.model';
import { IListItemBase } from '../ilist-item-base.model';
import { IconFormatEnum } from '../../enums/icon-format.enum';

export class AttachmentCheckoutInfo {
    public email: string;
    public fullName: string;
    public mf_userid: string;
}

export class AttachmentStatus {
    public canCheckOut: boolean;
    public canEdit: boolean;

    public checkOutType: number;
    public checkedOutBy: AttachmentCheckoutInfo;
    public checkedOutDate: string;

    public lockExpires: string;
    public lockType: number;
    public lockedBy: AttachmentCheckoutInfo;
    public lockedDate: string;
}

//TEAMS-424: KLW - Create a new attachment class to handle the api call to get attachments
// TODO this inherits from DataCollection but should inherit from IListItemBase...
export class AttachmentInfoWithUrls extends IListItemBase implements IListItem {
    public id: number;
    public currentStatus: string;

    public comment: string;

    public createdByEmail: string;
    public createdByMf_UserId: string;
    public createdByName: string;
    public createdDate: string;

    public downloadUrl: string;

    public editInAppUrl: string;
    public editInBrowserUrl: string;

    public fileName: string;

    // pharvey - added 2/9/2022
    public displayOrder: number;
    public description: string;

    public isCurrent: boolean;

    public miniViewUrl: string;
    public serverRelativeUrl: string;

    public size: number;

    public uniqueId: string;
    public url: string;

    public versionLabel: string;
    public viewInBrowserUrl: string;

    public status: AttachmentStatus;


    // Implement IListItem
    public getType(): string {
        return ItemTypeEnum.ATTACH_W_URLS;
    }

    public getExtraInfo(): string {
        return this.description;
    }

    public getValue(property: string): string {
        return "";
    }

    public getPosition(): number {
        return this.displayOrder;
    }


    setId(idParam: number): void {
        this.id = idParam;
    }
    userCanDelete(): boolean {
        throw new Error('Method not implemented.');
    }
    modifiedDate: Date;
    modifiedBy: string;
    getStatus(): string {
        return (this.currentStatus);
    }
    public getIconType(): IconFormatEnum {
        return IconFormatEnum.MAT_ICON;
    }
    getIcon(): string {
        throw new Error('Method not implemented.');
    }
    setIcon(iconParam: string): void {
        throw new Error('Method not implemented.');
    }
    canBeDeleted(): boolean {
        return true;
    }
    update(obj: any, type: string, icon?: string, position?: number): void {
        throw new Error('Method not implemented.');
    }
    getUniqueId(): string {
        throw new Error('Method not implemented.');
    }
    public getChildCount(): number {
        throw new Error('Method not implemented.');
    }
    public getModifiedBy(): string {
        return this.modifiedBy;
    }
    public getModifiedDate(): Date {
        return this.modifiedDate;
    }
    findAndUpdateFrom(items: any, obj: any): void {
        throw new Error('Method not implemented.');
    }
    getId(): number {
        return this.id;
    }

    public getOriginalVersionId(): number {
        return -1;
    }

    getParentId(): number {
        throw new Error('Method not implemented.');
    }
}
