import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    constructor(private domSanitizer: DomSanitizer, private http: HttpClient) { }

    composeInvEmail(email: string, contactName: string, formInstanceName: string, formInstanceLink: string): SafeUrl {

        //Do NOT use the backtick interpolation to create this string, it will cause issues
        var mailtoProp = "mailto:" + email + "?";
        var name = contactName;

        if (contactName.indexOf("(") > 0)
            name = contactName.substring(0, contactName.indexOf("("));

        var subjectProp = "subject=";
        var bodyProp = "body=";
        var amp = "&amp;";
        var newLine = "%0D%0A";
        var footer = newLine + newLine + "This message was automatically generated by vNext"
        var subject = formInstanceName + " link from vNext";

        var body = "Greetings " + name + "," + newLine + newLine +
            "Please click the following link to access the form instance " + formInstanceName + ":" + newLine + newLine +
            formInstanceLink;

        const url = `${mailtoProp}${subjectProp}${subject}&${bodyProp}${body}${footer}`;
        return this.domSanitizer.bypassSecurityTrustUrl(url)
    }

    //VNEXT-951: KLW - Changes to email a contact list
    composeAllEmail(emails: string[], formInstanceName: string, formInstanceLink: string): SafeUrl {
        var emailsString: string = "";

        emails.forEach(email => {
            //Do NOT use the backtick interpolation to create this string, it will cause issues
            emailsString += email + ";";
        });

        var mailtoProp = "mailto:" + emailsString + "?";

        var subjectProp = "subject=";
        var bodyProp = "body=";
        var newLine = "%0D%0A";
        var footer = newLine + newLine + "This message was automatically generated by vNext"
        var subject = formInstanceName + " link from vNext";

        var body = "Greetings All," + newLine + newLine +
            "Please click the following link to access the form instance " + formInstanceName + ":" + newLine + newLine +
            formInstanceLink;

        const url = `${mailtoProp}${subjectProp}${subject}&${bodyProp}${body}${footer}`;
        return this.domSanitizer.bypassSecurityTrustUrl(url)
    }

    public SendErrorMail(msg: any) {
        let url = environment.apiUrl + "/api/Error/SendErrorMail";
        let message = { subject: msg.subject, body: msg.body, to: msg.to, CC: msg.cc };
        return this.http.post(url, message).toPromise();
    }
}
