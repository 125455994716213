import { FormField } from '../../../../../models/form-builder/form-field.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../../../interfaces/iform-field-constraint.interface';
import { FormFieldCascadingDropdownConstraint, FormFieldCascadingDropdownConstraintWithMetrics } from '../../../../../models/form-builder/field-constraints/field-cascading-dropdown-constraint.model';
import { CachedFormFieldService, FlushCachedServiceUponFormSave } from '../../../../../services/form-field-with-cache.service';
import { IFormFieldConstraintLiaison } from './ifield-constraint-liason.interface';
//import { ISiteFormFieldConstraints } from './isite-form-field-constraints.interface';
import { FieldConstraintLiaisonBase } from './field-constraint-liaison-base';
import { SiteFormFieldConstraintProperties } from '../../../../../models/form-builder/field-constraints/site-form-field-constraint-props.model';
import { CascadingDropDownFieldType } from '../../../../../models/form-builder/form-field-types';
import { FormFieldConstraintTypeEnum, FormFieldConstraintTypeForUsersEnum } from '../../../../../enums/form-field-constraint-type.enum';

export class FormFieldCascadingDropdownConstraintLiaison extends FieldConstraintLiaisonBase implements IFormFieldConstraintLiaison {
    // Constructor.
    public constructor() {
        super();
    }

    // Implement IFormFieldConstraintLiaison.
    // Getter/setter-like methods.
    public constraintTypeForEndUser(): string {  // A user-friendly constraint type name.
        return FormFieldConstraintTypeForUsersEnum.CascadingDropdown;
    }

    public managesConstraintsOfType(): string {
        return FormFieldConstraintTypeEnum.CascadingDropdown;
    }

    public getPrimaryPropertyName(): string {
        return 'selectOptionsFile'; // This is the name of the property for loading a file that contains a cascading-dropdown field's configuration.
    }

    public getConstraintId(formField: FormField): number {
        return formField.cascadingDropdownConstraintId;
    }
    public setConstraintId(formField: FormField, value: number): void {
        formField.cascadingDropdownConstraintId = value;
    }

    public getConstraintName(formField: FormField): string {
        return formField.cascadingDropdownConstraintName;
    }
    public setConstraintName(formField: FormField, value: string): void {
        formField.cascadingDropdownConstraintName = value;
    }

    public getConstraintValue(formField: FormField): string {
        return formField.cascadingDropdownConstraintValue;
    }
    public setConstraintValue(formField: FormField, value: string): void {
        formField.cascadingDropdownConstraintValue = value;
    }

    // not relevant for this field type
    public getConstraintFailureMessage(formField: FormField): string {
        return null;
    }
    public setConstraintFailureMessage(formField: FormField, value:string): void {
        // noop
    }

    public getConstraintFormFieldPropertyValue(formField: FormField): string {
        return formField.jsonConfig;
    }

    public copyConstraintValueToCorrespondFormFieldProperty(formField: FormField, value: string): void {
        formField.jsonConfig = value;
    }

    // Create a new, empty constraint.
    public createNewConstraint(dataCollectionId: number): IFormFieldConstraintWithMetrics {
        return new FormFieldCascadingDropdownConstraintWithMetrics(dataCollectionId);
    }

    public createNewConstraintFrom(constraintParam: IFormFieldConstraint): IFormFieldConstraintWithMetrics {
        let constraint = <FormFieldCascadingDropdownConstraint>constraintParam;
        let newConstraint = new FormFieldCascadingDropdownConstraintWithMetrics(constraint.dataCollectionId, constraint.id, constraint.name, constraint.constraintExpression);
        return newConstraint;
    }

    // Package constraint values as a constraint.
    public packageConstraint(dataCollectionId: number, formField: FormField): IFormFieldConstraint {
        let packagedConstraint = new FormFieldCascadingDropdownConstraint(dataCollectionId, formField.cascadingDropdownConstraintId, formField.cascadingDropdownConstraintName, formField.cascadingDropdownConstraintValue);

        return packagedConstraint;
    }
    public updateOrCreateConstraint(cachedFormFieldService: CachedFormFieldService, updatedConstraintParam: IFormFieldConstraint): void {
        let updatedConstraint: FormFieldCascadingDropdownConstraint = <FormFieldCascadingDropdownConstraint>updatedConstraintParam;
        cachedFormFieldService.updateOrCreateCascadingDropdownConstraint(updatedConstraint);
    }

    // Methods related to applying form field constraint
    // updates to a form field that uses the same constraint.
    public get createNamedConstraintMethodName(): string {
        return 'createCascadingDropdownConstraint';
    }
    public get updateNamedConstraintMethodName(): string {
        return 'updateCascadingDropdownConstraint';
    }
    public get updateOrCreateNamedConstraintMethodName(): string {
        return 'updateOrCreateCascadingDropdownConstraint';
    }
    public get siteNamedConstraintsMethodName(): string {
        return 'getSiteNamedCascadingDropdowns';
    }

    // Get the field definition class name(s) associated with this type of constraint.
    public get usedByFormFieldClasses(): string[] {
        return [CascadingDropDownFieldType];
    }

    public applyConstraintPropertiesToFormField(constraint: IFormFieldConstraint, formField: FormField) {
        formField.cascadingDropdownConstraintId = constraint.id;
        formField.cascadingDropdownConstraintName = constraint.name;
        formField.constraintJson = constraint.constraintExpression;
        formField.jsonConfig = constraint.constraintExpression;
    }

    // A method to create a constraint from the results of an all site named constraints query.
    public createFromConstraintProperties(dataCollectionId: number, constraintProperties: SiteFormFieldConstraintProperties): IFormFieldConstraintWithMetrics {
        let constraint: FormFieldCascadingDropdownConstraintWithMetrics = new FormFieldCascadingDropdownConstraintWithMetrics(dataCollectionId, constraintProperties.constraintId, constraintProperties.constraintName, constraintProperties.jsonConfig);
        constraint.useCount = constraintProperties.useCount;
        return constraint;
    }
}
