import {
    Component, EventEmitter, Input, OnInit, Output, Renderer2, Type as AngularCoreType,
    ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { AccessibilityHelper } from '../../../accessibility.helper';
import { ConfirmationDialogComponent, ConfirmationDialogModel } from '../../../dialogs/confirmation/confirmation-dialog.component';
import { IFileUploadProgressValueHash } from '../../../dialogs/file-upload/file-upload-dialog.component';
import { ConfirmationDialogEnums } from '../../../enums/confirmation-dialog.enum';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IconFormatEnum } from '../../../enums/icon-format.enum';
import { ItemTypeEnum } from '../../../enums/item-type.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FabricFileTypeIconMapping, IFileTypeIconMap } from '../../../mappings/fabric-file-types-icon.mapping';
import { AttachmentInfoWithUrls } from '../../../models/attachment/attachment-info-with-urls.model';
import { AttachmentInfo } from '../../../models/attachment/attachment-status-and-history.model';
import { AttachmentProperties } from '../../../models/attachment/attachment.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { AttachmentService, FileUploadProgress } from '../../../services/attachment.service';
import { FileUploaderComponent } from '../../file-uploader/file-uploader.component';
import { ButtonConfig, IButtonConfig } from '../../list-view/list-view-button-config.model';
import { TrashConfig, TrashDeleteEvent } from '../../list-view/list-view-trash-config.model';
import { ListViewComponent } from '../../list-view/list-view.component';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';

@Component({
    selector: 'app-attachment-form-field',
    templateUrl: './attachment-form-field.component.html',
    styleUrls: ['./attachment-form-field.component.scss', '../form-fields.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AttachmentFormFieldComponent,
            multi: true
        }
    ]
})

export class AttachmentFormFieldComponent extends FormFieldBaseComponent implements OnInit {

    @Output() onInit = new EventEmitter();
    @Output() requestFormInstanceIds = new EventEmitter();

    @Input() selectedId: number;

    @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
    @ViewChild(ListViewComponent) attachmentListComponent: ListViewComponent;
    @ViewChild(FileUploaderComponent) fileUploaderComponent: FileUploaderComponent;


    attachmentItems: AttachmentInfoWithUrls[] = null;

    // Keep track of Id values needed for file uploads.
    private dataCollectionId: number = -1;
    private folderId: number = -1;
    private formInstanceId: number = -1;

    //TEAMS-424: KLW - Variable to determine if the upload button should be disabled
    private disableUploadButton = true;
    //TEAMS-424: KLW - Flag to determine if checkboxes should be displayed for the list views
    hasCheckboxes: boolean = true;
    hideActionButtons: boolean = true;

    buttonConfig: any;
    title = 'Attachments';

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.REQUIRED,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.PLACEHOLDER_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT
        ];

    private readonly LIBRARY_ADD: string = 'library_add';
    private readonly STANDARD: string = 'standard';
    private readonly NONE: string = 'none';
    private readonly EDITABLE: string = 'Editable';
    private readonly CHECKED_OUT: string = 'Checked Out';
    private readonly MORE_VERT: string = 'more_vert';
    private readonly INFO: string = 'info';

    private contextMenuPosition = { x: '0px', y: '0px' };

    private _fileTypeIconMap: IFileTypeIconMap[] = null;

    private selectedItemDescription: string;
    private selectedItemDisplayOrder: number;

    private get FileTypeIconMap(): IFileTypeIconMap[] {
        if (this._fileTypeIconMap == null) {
            this._fileTypeIconMap = new FabricFileTypeIconMapping().FileTypeIconMap;
        }

        return this._fileTypeIconMap;
    }

    public get DataCollectionId(): number {
        return (this.dataCollectionId);
    }

    public get FolderId(): number {
        return (this.folderId);
    }

    public get FormInstanceId(): number {
        return (this.formInstanceId);
    }

    public get FormFieldId(): number {
        return (this.FormField.id);
    }

    public get ContextMenuPositionX(): string {
        return (this.contextMenuPosition.x);
    }
    public get ContextMenuPositionY(): string {
        return (this.contextMenuPosition.y);
    }

    public get GetMiniViewerURL(): string {
        if (this._selectedAttachment.miniViewUrl)
            return this._selectedAttachment.miniViewUrl;
    }

    public get ShowEditInBrowser(): boolean {

        let retVal: boolean = true;

        if (this.contextMenu) {
            if (this.contextMenu.menuOpen) {
                retVal = this.contextMenu.menuData.item.status.canCheckOut;
            }
        }

        if (this._selectedAttachments)
            if (this._selectedAttachments.length > 1)
                retVal = false;

        return retVal;
    }

    public get OnlyShowDelete() {
        let retVal: boolean = true;

        if (this._selectedAttachments)
            if (this._selectedAttachments.length > 1)
                retVal = false;


        return retVal;
    }

    private _selectedAttachments: AttachmentInfoWithUrls[] = null;
    private _enableAttachments = true;


    private _selectedAttachment: AttachmentInfoWithUrls = null;
    public get FileExtension() {
        return this._selectedAttachment.name.split('.').pop();
    }

    private _attachmentHistory: AttachmentInfo[] = null;
    public get AttachmentInfo(): AttachmentInfo[] {
        return this._attachmentHistory;
    }

    private _editInputAppearance: boolean = false;
    public get EditInputAppearance() {
        if (this._editInputAppearance)
            return this.STANDARD;
        else
            return this.NONE;
    }

    private _editEnabled: boolean = false;
    public get EditEnabled() {
        return this._editEnabled;
    }

    public selectedItemFileName = '';
    public get EditFileName() {
        if (!this._editEnabled)
            return this.selectedItemFileName;
        else
            return this.selectedItemFileName.split('.')[0];
    }

    public get SelectedItemDescription(): string {
        return this.selectedItemDescription;
    }

    public set SelectedItemDescription(value: string) {
        this.selectedItemDescription = value;
    }

    public get ModifiedDate() {
        if (this.AttachmentInfo)
            return this.AttachmentInfo[this.AttachmentInfo.length - 1].createdDate
    }

    public get SelectedAttachment(): AttachmentInfoWithUrls {
        return (this._selectedAttachment);
    }

    public get Attachments(): AttachmentInfoWithUrls[] {
        return (this.FormInstanceElement ? this.FormInstanceElement.attachmentDetails : null);
    }

    public get AttachmentItems(): AttachmentInfoWithUrls[] {
        if (this.attachmentItems == null && this.Attachments != null) {
            this.attachmentItems = [];

            this.FormInstanceElement.attachmentDetails.forEach(x => {
                this.attachmentItems.push(this.createAttachmentInfoWithUrlsForList(x));
            });
        }

        return this.attachmentItems;
    }

    public get AttachmentCount(): number {
        if (this.AttachmentItems) {
            return this.AttachmentItems.length;
        } else {
            return 0;
        }
    }

    // Constructor.
    public constructor(private renderer: Renderer2,
        private attachmentService: AttachmentService,
        private dialog: MatDialog
    ) {
        super();

        return;
    }


    private createAttachmentInfoWithUrlsForList(item: AttachmentInfoWithUrls) {
        let toAdd = new AttachmentInfoWithUrls();

        toAdd.name = item.fileName;
        toAdd.createdByName = item.createdByName;
        toAdd.createdDate = item.createdDate;

        toAdd.setId(item.id);
        if (item.status.canCheckOut)
            toAdd.currentStatus = this.EDITABLE;
        else
            toAdd.currentStatus = this.CHECKED_OUT;

        toAdd.comment = item.comment;
        toAdd.createdByEmail = item.createdByEmail;
        toAdd.size = item.size;
        toAdd.versionLabel = item.versionLabel;

        toAdd.editInBrowserUrl = item.editInBrowserUrl;
        //VNEXT-707: KLW - Add option for Edit in App for SharePoint attachments
        toAdd.editInAppUrl = item.editInAppUrl;
        toAdd.viewInBrowserUrl = item.viewInBrowserUrl;
        toAdd.miniViewUrl = item.miniViewUrl;
        toAdd.downloadUrl = item.downloadUrl;

        toAdd.description = item.description;
        toAdd.displayOrder = item.displayOrder;

        toAdd.status = item.status;

        return toAdd;
    }

    private saveAttachmentProperties(newName: string, newDescription: string, displayOrder: number) {
        if (this._selectedAttachment.name != newName) {
            this.attachmentService.renameAttachment(this.DataCollectionId, this.FolderId, this.FormInstanceId, this.FormFieldId, this._selectedAttachment.name, newName).then(x => {
                this._selectedAttachment.name = newName;
                this.deselectAttachment();
            });
        }
        if (this._selectedAttachment.description != newDescription) {
            let properties = new AttachmentProperties(newDescription, displayOrder);
            this.attachmentService.setCustomProperties(this.DataCollectionId, this.FolderId, this.FormInstanceId, this.FormFieldId, this._selectedAttachment.name, properties).then(x => {
                this._selectedAttachment.description = newDescription;
                this.deselectAttachment();
            });
        }
    }

    private buildButtonConfig(): void {

        // Construct the button configs array.
        let itemActions: IButtonConfig[] =
            [
                new ButtonConfig('View Details', this.INFO, this.INFO),
                new ButtonConfig('Sub Menu', this.MORE_VERT, this.MORE_VERT)
            ];

        this.buttonConfig = {
            right: [
                new ButtonConfig('Add Attachment', this.LIBRARY_ADD, this.LIBRARY_ADD, IconFormatEnum.MAT_ICON, this.disableUploadButton) //TEAMS-424: KLW - Use new constructor to pass in if upload button should be disabled
            ],
            item_actions: itemActions,
            trash: new TrashConfig(true)
        };
    }


    private deleteOnConfirm(entity: string[], callback: Function) {

        let title = 'Delete Attachment';
        let desc = 'Are you sure you want to delete the attachment';

        if (entity.length > 1) {
            title += 's';
            desc += 's:\n';

            entity.forEach(x => {
                desc += x + '\n';
            });
        }
        else {
            desc += ' ' + entity[0] + '?\n';
        }

        desc += '\nThe operation cannot be undone!';

        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: new ConfirmationDialogModel(title, desc, { hideNo: false, dialogType: ConfirmationDialogEnums.WARNING }),
            panelClass: 'dialog-confirm'
        });

        dialogRef.afterClosed().subscribe(yes => {
            if (yes) callback();
        });
    }

    private getAssociatedFabricIconProperties(extension: string): IFileTypeIconMap {
        let retVal: IFileTypeIconMap = null;

        if (extension) {
            let findKey = this.FileTypeIconMap.find(x => x.value.extensions.find(y => y == extension));

            if (findKey) {
                retVal = findKey;
            }
        }

        return retVal;
    }

    private getFileExtension(fileName: string): string {
        let retVal = '';

        if (fileName)
            retVal = fileName.split('.').pop();

        return retVal;
    }


    public canPreview(fileName: string): boolean {
        let retVal: boolean = false;

        let fileType = this.getAssociatedFabricIconProperties(this.getFileExtension(fileName));

        if (fileType)
            if (fileType.value.canpreview)
                retVal = true;

        return retVal;
    }

    public EditButtonClicked() {
        this._editEnabled = !this._editEnabled;
        this._editInputAppearance = !this._editInputAppearance;
    }

    public EditFocusOut(event: any, field: string) {
        if (field == 'name') {
            this.selectedItemFileName = event.target.value + '.' + this.FileExtension;
        } else if (field == 'desc') {
            this.selectedItemDescription = event.target.value;
        }
        this.EditButtonClicked();
    }

    public deselectAttachment() {
        this._selectedAttachment = null;
        this._attachmentHistory = null;
        this._editInputAppearance = false;
        this._editEnabled = false;
        this.selectedItemFileName = '';
        this.selectedItemDescription = '';
        this.selectedItemDisplayOrder = 0;
    }

    public getSelectedId(): number {
        if (this._selectedAttachment != null)
            return this._selectedAttachment.getId();
        else
            return null;
    }

    // Handle save and cancel clicks.
    public saveClicked(): void {
        this.saveAttachmentProperties(this.selectedItemFileName, this.selectedItemDescription, this.selectedItemDisplayOrder);
    }

    public cancelClicked(): void {
        this.deselectAttachment();
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshProperties =
        {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties
        };

        return (hshProperties);
    }

    // Life cycle methods.
    public ngOnInit(): void {
        // Emit my onInit() output.
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);

        // Emit a request to get additional Id values.
        {
            let hshGetIdsInfo = {
                'formInstanceElement': this.FormInstanceElement
            };
            this.requestFormInstanceIds.emit(hshGetIdsInfo);

            // Save Id values.
            this.dataCollectionId = hshGetIdsInfo['dataCollectionId'];
            this.folderId = hshGetIdsInfo['folderId'];
            this.formInstanceId = hshGetIdsInfo['formInstanceId'];
        } // block

        return;
    }

    public doOnItemMoved(moveData: any): void {
        if (moveData.NothingMoved) {
            return null;
        }

        let movedItems = moveData.MovedItems;
        let targetItem = moveData.TargetOfMove;
        let reorderedItems = this.reorderItems(movedItems[0], targetItem);
        this.attachmentItems = reorderedItems;

        this.attachmentService.reorderAttachments(reorderedItems).then(x => { });
    }

    private reorderItems(movedItem: AttachmentInfoWithUrls, targetItem: AttachmentInfoWithUrls): AttachmentInfoWithUrls[] {
        let idOfMovedItem = movedItem.getId();
        let idOfTargetItem = targetItem.getId();

        let oldIndex = -1, newIndex = -1;
        let allItems = this.AttachmentItems;
        let otherItems = allItems.filter((item) => { return item.getId() != idOfMovedItem; });

        // find old and new index
        for (let i = 0; i < allItems.length; i++) {
            let item = allItems[i];
            let id = item.getId();
            if (id == idOfMovedItem) {
                oldIndex = i;
            } else if (id == idOfTargetItem) {
                newIndex = i;
            }
        }

        // create reordered array
        // see https://medium.com/kevin-salters-blog/reordering-a-javascript-array-based-on-a-drag-and-drop-interface-e3ca39ca25c
        let reordered = [
            ...otherItems.slice(0, newIndex),
            movedItem,
            ...otherItems.slice(newIndex)
        ];

        let results: AttachmentInfoWithUrls[] = [];
        for (let i = 0; i < reordered.length; i++) {
            let item = reordered[i];
            item.displayOrder = i + 1;
            results.push(item);
        }

        return results;
    }

    public doOnItemAction(eventData: any): void {
        let action = eventData.button.value;
        console.log('doOnItemAction');
        console.log(eventData.item);

        if (action == this.INFO) {
            this.displayAttachmentDetails(eventData.item);
            this.AttachmentHistory();
        }
        else if (action == this.MORE_VERT) {
            this.doOnItemContextMenu(eventData);
        }

        return;
    }

    public doOnItemContextMenu(event: any): void {
        // Set the context menu position.
        this.contextMenuPosition.x = event.originalEvent.clientX + 'px';
        this.contextMenuPosition.y = event.originalEvent.clientY + 'px';

        //TEAMS-424: KLW - Adding logic to pass selected items to the parent component
        if (event.selectedItems)
            if (event.selectedItems.length > 0)
                this._selectedAttachments = event.selectedItems;

        // Set menu data.
        this.contextMenu.menuData = { 'item': event.item };
        this.contextMenu.openMenu();

        return;
    }

    public displayAttachmentDetails(attachment: AttachmentInfoWithUrls) {
        this._selectedAttachment = attachment;
        this.selectedItemFileName = attachment.name.replace('%20', ' ');
        this.selectedItemDescription = attachment.description;
        this.selectedItemDisplayOrder = attachment.displayOrder;

        AccessibilityHelper.setFocus('#details-drawer-keyboard-focus-div');
    }

    public get ActivityListIcon(): string {
        return ItemTypeEnum.ATTACHMENT_ACTIVITY;
    }

    public AttachmentHistory() {

        if (this._attachmentHistory == null) {

            this.attachmentService.getAttachmentHistories(this.DataCollectionId, this.FolderId, this.FormInstanceId, this.FormFieldId).then(x => {
                try {
                    this._attachmentHistory = x.find(h => h.history.some(i => i.fileName === this.SelectedAttachment.name)).history;
                } catch (e) {
                    //log error
                }
            });
        }
    }

    public formatBytes(bytes, decimals = 0.0) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    // Implement protected methods defined in my base class.
    // Handle getting this field's form instance element.
    protected formInstanceElementReceived(): void {
        //TEAMS-424: KLW - Set if the upload button should be disabled, which is true except for a form instance of attachments.
        //Also put in a check to see if attachments should be used or not
        this.attachmentService.getCanUseAttachments().then(x => {
            this._enableAttachments = x;

            if (this.Mode === 'instance' && this._enableAttachments) {
                this.disableUploadButton = false;
            }
            else {
                this.disableUploadButton = true;
            }

            this.buildButtonConfig();
        });

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        //TEAMS-424: KLW - Set if the upload button should be disabled, which is true except for a form instance of attachments.
        //Also put in a check to see if attachments should be used or not
        this.attachmentService.getCanUseAttachments().then(x => {
            this._enableAttachments = x;

            if (this.Mode === 'instance' && this._enableAttachments) {
                this.disableUploadButton = false;
            }
            else {
                this.disableUploadButton = true;
            }

            this.buildButtonConfig();
        });

        return;
    }


    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): string {
        //if ((!formInstanceElementParam.transientValueSetFlag) ||
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        }

        // NOTE:  NEED TO REVISIT THIS.
        return (formInstanceElementParam.textValue);
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (AttachmentFormFieldComponent);
    }

    // Handle control events.
    public handleFilesUploaded(eventData: IFileUploadProgressValueHash): void {
        let iNumFiles: number = Object.keys(eventData).length;

        if (eventData && (iNumFiles > 0)) {
            if (!this.FormInstanceElement.attachmentDetails) {
                this.FormInstanceElement.attachmentDetails = [];
            }
            for (let fileName in eventData) {
                let fileUploadProgress: FileUploadProgress = eventData[fileName];

                if (fileUploadProgress.attachInfoWithUrls != null && !this.FormInstanceElement.attachmentDetails.some(attach => attach.fileName == fileUploadProgress.fileName)) {
                    this.FormInstanceElement.attachmentDetails.push(fileUploadProgress.attachInfoWithUrls);
                    this.attachmentItems.push(this.createAttachmentInfoWithUrlsForList(fileUploadProgress.attachInfoWithUrls));
                }
                this.FormInstanceElement.UserUpdatedData = true;
            }
            this.updateTableSource(this.attachmentItems);
        }

        return;
    }

    //VNEXT-707: KLW - Add option for Edit in App for SharePoint attachments
    public openEditInAppUrl(): void {
        let editInAppUrl = this.contextMenu.menuData.item.editInAppUrl;

        if (editInAppUrl)
            window.open(editInAppUrl, '_blank').focus();
    }

    public openEditInBrowserUrl(): void {
        let editInBrowserUrl = this.contextMenu.menuData.item.editInBrowserUrl;

        if (editInBrowserUrl)
            window.open(editInBrowserUrl, '_blank').focus();
    }

    public openViewInBrowserUrl(): void {
        let viewInBrowserUrl = this.contextMenu.menuData.item.viewInBrowserUrl;

        if (viewInBrowserUrl)
            window.open(viewInBrowserUrl, '_blank').focus();
    }

    public openDownloadUrl(event: any = null): void {
        let item;
        if (event) {
            item = event.item;
        } else {
            item = this.contextMenu.menuData.item;
        }
        let downloadUrl = item.downloadUrl;

        if (downloadUrl)
            window.open(downloadUrl, '_blank').focus();
    }


    public matMenuDeleteAttachment(): void {
        let items = [this.contextMenu.menuData.item.name];

        if (this._selectedAttachments)
            if (this._selectedAttachments.length > 1)
                items = this._selectedAttachments.map(x => x.name);

        this.deleteAttachment(items);
    }

    public deleteAttachment(fileName: string[]): void {
        if (fileName) {
            this.deleteOnConfirm(fileName, () => {
                fileName.forEach(file => {
                    this.attachmentService.deleteAttachment(this.DataCollectionId, this.FolderId, this.FormInstanceId, this.FormFieldId, file).then(x => {
                        this.FormInstanceElement.attachmentDetails = this.FormInstanceElement.attachmentDetails.filter(item => item.fileName !== file);
                        this.attachmentItems = this.attachmentItems.filter(item => item.name !== file);

                        this.updateTableSource(this.attachmentItems);
                    });
                }
                );
            });
        }
    }

    public doOnItemDelete(eventData: TrashDeleteEvent): void {
        //TEAMS-424: KLW - Change the delete logic to handle a selection instead of just one item
        let fileNames = [];

        if (eventData.items.length > 0) {
            fileNames = eventData.items.map(x => x.name);
        }

        this.deleteAttachment(fileNames);

        return;
    }

    public doOnButtonClick(eventData: any): void {
        if (eventData.button == this.LIBRARY_ADD) {
            this.fileUploaderComponent.openUploadDialog();
        }
    }

    public updateTableSource(source: AttachmentInfoWithUrls[]) {
        this.attachmentListComponent.updateTableSource(this.AttachmentItems);
    }
}






