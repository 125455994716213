import { ElementRef } from '@angular/core';

import { FormField } from '../../../../../models/form-builder/form-field.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../../../interfaces/iform-field-constraint.interface';
import { CachedFormFieldService } from '../../../../../services/form-field-with-cache.service';
import { ISiteFormFieldConstraints } from './isite-form-field-constraints.interface';
import { IFormFieldConstraintLiaison } from './ifield-constraint-liason.interface';
import { SiteFormFieldConstraintProperties } from '../../../../../models/form-builder/field-constraints/site-form-field-constraint-props.model';

export abstract class FieldConstraintLiaisonBase implements IFormFieldConstraintLiaison {
    // Abstract methods.
    public abstract managesConstraintsOfType(): string;
    public abstract constraintTypeForEndUser(): string; // A user-friendly constraint type name.

    public abstract getPrimaryPropertyName(): string;

    public abstract getConstraintId(formField: FormField): number;
    public abstract setConstraintId(formField: FormField, value: number): void;

    public abstract getConstraintName(formField: FormField): string;
    public abstract setConstraintName(formField: FormField, value: string): void;

    public abstract getConstraintValue(formField: FormField): string;
    public abstract setConstraintValue(formField: FormField, value: string): void;

    public abstract getConstraintFailureMessage(formField: FormField): string;
    public abstract setConstraintFailureMessage(formField: FormField, value: string): void;

    public abstract getConstraintFormFieldPropertyValue(formField: FormField): string;
    public abstract copyConstraintValueToCorrespondFormFieldProperty(formField: FormField, value: string): void;

    public abstract createNewConstraint(dataCollectionId: number): IFormFieldConstraintWithMetrics;
    public abstract createNewConstraintFrom(constraintParam: IFormFieldConstraint): IFormFieldConstraintWithMetrics;

    // Package constraint values as a constraint.
    public abstract packageConstraint(dataCollectionId: number, formField: FormField): IFormFieldConstraint;
    public abstract updateOrCreateConstraint(cachedFormFieldService: CachedFormFieldService, updatedConstraint: IFormFieldConstraint): void;

    // Methods for getting method names.
    public abstract get createNamedConstraintMethodName(): string;
    public abstract get updateNamedConstraintMethodName(): string;
    public abstract get updateOrCreateNamedConstraintMethodName(): string;
    public abstract get siteNamedConstraintsMethodName(): string;

    // Get the field definition class name(s) associated with this type of constraint.
    public abstract get usedByFormFieldClasses(): string[];

    // Methods related to applying form field constraint
    // updates to a form field that uses the same constraint.
    public abstract applyConstraintPropertiesToFormField(constraint: IFormFieldConstraint, formField: FormField);

    public abstract createFromConstraintProperties(dataCollectionId: number, constraintProperties: SiteFormFieldConstraintProperties): IFormFieldConstraintWithMetrics;

    // Non-abstract methods.
    public proposeConstraintNameIfApplicable(formField: FormField, proposedName: string, siteConstraints: ISiteFormFieldConstraints, inputField: ElementRef<HTMLInputElement>, setInputFieldFocusNow: boolean): void {
        let constraintId = this.getConstraintId(formField);
        let constraintName = this.getConstraintName(formField);

        if ((constraintId <= 0) && (!siteConstraints.ConstraintNameProposed)) {
        //if ((constraintId < 0) && (!siteConstraints.ConstraintNameProposed)) {
            if ((constraintName == null) || (constraintName.trim() == '')) {
                this.setConstraintName(formField, proposedName);
                if (setInputFieldFocusNow) {
                    setTimeout(() => {
                        inputField.nativeElement.focus();
                        inputField.nativeElement.select();
                    }, 0);
                }

                siteConstraints.enableEditing();
                siteConstraints.ConstraintNameProposed = true;
            }
        }
    }

    // Methods related to applying form field constraint
    // updates to a form field that uses the same constraint.
    public updateFormFieldWithLatestConstraintValues(siteNamedConstraints: IFormFieldConstraint[], formField: FormField): boolean {
        let updatedApplied: boolean = false;
        let constraintId: number = this.getConstraintId(formField);

        if ((constraintId != null) && (constraintId != 0)) {
            let siteConstraint: IFormFieldConstraint = siteNamedConstraints.find(c => c.id == constraintId);
            if (siteConstraint != null) {
                if (this.getConstraintName(formField) != siteConstraint.name) {
                    this.setConstraintName(formField, siteConstraint.name);
                    updatedApplied = true;
                }                    
                if (this.getConstraintValue(formField) != siteConstraint.constraintExpression) {
                    this.setConstraintValue(formField, siteConstraint.constraintExpression);
                    updatedApplied = true;
                }                    
            }
        }

        return updatedApplied;
    }

    public updateOtherFieldConstraintValues(dataCollectionId: number, formField: FormField, otherField: FormField): void {
        if (this.getConstraintId(otherField) == this.getConstraintId(formField)) {
            this.setConstraintName(otherField, this.getConstraintName(formField));
            this.setConstraintValue(otherField, this.getConstraintValue(formField));
            this.setConstraintFailureMessage(otherField, this.getConstraintFailureMessage(formField));

            // Also update the field's constraint value(s).
            this.copyConstraintValueToCorrespondFormFieldProperty(otherField, this.getConstraintValue(formField))
        }

        // Also call the other field's constraint updated method.
        let updatedConstraint = this.packageConstraint(dataCollectionId, formField);
        otherField.constraintUpdated(updatedConstraint, <IFormFieldConstraintLiaison><unknown>this);
    }

    public updateOtherFieldIfUsingSameConstraint(formFieldConstraint: IFormFieldConstraint, otherField: FormField): void {
        if (formFieldConstraint.id == this.getConstraintId(otherField)) {
            if (formFieldConstraint.name != this.getConstraintName(otherField))
                this.setConstraintName(otherField, formFieldConstraint.name);
            if (formFieldConstraint.constraintExpression != this.getConstraintValue(otherField))
                this.setConstraintValue(otherField, formFieldConstraint.constraintExpression);

            // Also update the field's constraint value(s).
            if (formFieldConstraint.constraintExpression != this.getConstraintFormFieldPropertyValue(otherField))
                this.copyConstraintValueToCorrespondFormFieldProperty(otherField, formFieldConstraint.constraintExpression);
        }
    }
}
