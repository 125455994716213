// loader.interceptors.ts
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { CurrentUserService } from '../security/current-user.service';
import { LogInOutComponent } from '../security/loginout/loginout.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent, AlertDialogModel } from './dialogs/alert/alert-dialog.component';
import { ProgressIndicatorService } from './services/progress-indicator.service';
import { environment } from '../../environments/environment';

// URLs for which the loading mask should not be displayed
// add a part of url here, and the loading mask will not display for it
const URLS_TO_SKIP: string[] = [
    'frameworkapi/maxpicker/search',
    'ForKeepAlive',
    'GetTreeWithPermissions',
    'export/folder',
    'export/forminstance',
    'getImpersonatableUsers',
    'attachment/histories'
];

@Injectable()
export class LoaderIntercepter implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(
        private loaderService: LoaderService,
        private currentUserService: CurrentUserService,
        private progressIndicatorService: ProgressIndicatorService,
        private dialog: MatDialog) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.skipThisRequest(req)) {
            return next.handle(req);
        } else {
            this.requests.push(req);
            this.loaderService.isLoading.next(true);
            return Observable.create(observer => {
                const subscription = next.handle(req)
                    .subscribe(
                        event => {
                            if (event instanceof HttpResponse) {
                                this.removeRequest(req);
                                observer.next(event);
                            }
                        },
                        err => {
                            // note: http 409 (editing conflict) is handled at a higher level,
                            // see form-instance-service.ts
                            if (err.status == 401) {
                                // PJH - 6/28/2024 - adding a comment as part of reviewing logout code for VNEXT-591
                                // I don't see the server code ever returning a 401, so not sure this will ever actually be thrown
                                // Also a 401 error code does not pertain to a session timeout. Anyway, keeping it here for now 
                                this.showErrorDialog(err, `Session Expired`, `Your session has expired and you will now be logged out. (401)`);
                                setTimeout(() => {
                                    this.currentUserService.setForcedLogout(true);
                                    this.currentUserService.setIdleLogout(false);
                                    this.currentUserService.logout(false);
                                }, 2000);
                            } else if (err.status == 403) {
                                let title = 'Unauthorized';
                                let message = `You don't have the necessary permissions to perform that operation.`;
                                if (err.error.Description.indexOf('You appear to have been granted some permissions') > -1) {
                                    title = 'Insufficient Permissions'
                                    message = err.error.Description.substring(0, err.error.Description.indexOf('Correlation ID'));
                                }
                                this.showErrorDialog(err, title, message);
                            } else if (err.status == 500) {
                                this.showErrorDialog(err, `Server Error`, `Something went wrong. If the error persists please report it to MAX Support.`);
                            } else if (err.status == 422) {
                                // pharvey - 6/14/2023 - added for VNEXT-505 but can serve to handle any detailed
                                // error message you wish to give to the user from exceptions handled and thrown
                                // in the server
                                this.showErrorDialog(err, 'Error', err.error.Message);
                            } else if (err.status == 503) {
                                // This was added for VNEXT-1116 which enables the application to be made
                                // unavailable for users who are not in the COLLECT-VNEXT-REGRESSIONTESTERS
                                // MAX group
                                document.location = "/unavailable";
                            }
                            this.removeRequest(req);
                            if (this.progressIndicatorService != null) {
                                this.progressIndicatorService.hideProgress();
                            }
                            observer.error(err);
                        },
                        () => {
                            this.removeRequest(req);
                            observer.complete();
                        });
                // remove request from queue when cancelled
                return () => {
                    this.removeRequest(req);
                    subscription.unsubscribe();
                };
            });
        }
    }

    private skipThisRequest(req: HttpRequest<any>): boolean {
        var matched = false;
        for (let url of URLS_TO_SKIP) {
            matched = req.url.match(url) != null;
            if (matched) break;
        }
        return matched;
    }

    private showErrorDialog(error, title: string, message: string): void {
        let technicalInfo: string = null;
        let correlationId: string = null;
        if ((error != null) && (error.error != null)) {
            // Do we have a correlation Id?
            if ((error.error.Description != null) && (error.error.Description.trim() != ''))
                correlationId = error.error.Description.split("Correlation ID = ")[1];
            else if (error.error.correlationId != null)
                correlationId = error.error.correlationId;

            // Do we have a more detailed title and/or message?
            if (error.error.title != null)
                title = error.error.title;
            if (error.error.detail != null)
                message = error.error.detail;
            if (error.url != null)
                technicalInfo = 'Requested ' + error.url;
        }

        this.dialog.open(AlertDialogComponent, {
            disableClose: true,
            maxWidth: "600px",
            data: new AlertDialogModel(title, message, technicalInfo, correlationId)
        });
    }
}
