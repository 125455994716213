import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { Workflow } from '../models/site-content/workflow.model';
import { FormsGridBulkWorkflowStateUpdate } from '../models/site-metadata/forms-grid-bulk-workflow-state-update.model';

import { plainToClass } from "class-transformer";
import { ProgressIndicatorService, ProgressConfig } from './progress-indicator.service';

@Injectable({
    providedIn: 'root'
})
export class WorkflowService extends CollectApiServiceBase<Workflow> { 
    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'workflow', Workflow)
    }

    formatResponse(data: Workflow): Workflow {
        var obj = plainToClass(Workflow, data);

        return (obj);
    }

    save(data: Workflow): Promise<Workflow> {
        //console.log(data.id);
        if (data.id > 0) {
            return (this.update(data));
        } else
            return (this.create(data));
    }

    public folderBulkWorkflowStateUpdate(folderId: number, selectedWorkflowStateId: number, cascade: boolean): Promise<boolean> {
        let msg = "Setting Forms' workflow state...";
        this.updateProgress(50, 75, msg);
        let url: string = `${this.url}/${this.endpoint}/folderBulkWorkflowStateUpdate/${folderId}/${selectedWorkflowStateId}?cascade=${cascade}`;
        return this.http.post(url, {})
            .toPromise()
            .then(res => {
                this.updateProgress(100, 100, "Done");
                return res;
            }).catch(this.handleError);
    }

    public formsGridBulkWorkflowStateUpdate(formGridBulkWorkflowStateUpdate: FormsGridBulkWorkflowStateUpdate): Promise<boolean> {
        let msg = "Setting Forms' workflow state...";
        this.updateProgress(50, 75, msg);
        let url: string = `${this.url}/${this.endpoint}/formsGridBulkWorkflowStateUpdate`;
        return this.http.post(url, formGridBulkWorkflowStateUpdate)
            .toPromise()
            .then(res => {
                this.updateProgress(100, 100, "Done");
                return res;
            }).catch(this.handleError);
    }

    public deleteFromUI(id: number, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<number>
    {
        let msg = progressConfig.msgDuring || this.progressMsg('Deleting');
        this.updateProgress(50, 75, msg);

        return this.http.delete(`${this.url}/${this.endpoint}/fromui/${id}`)
            .toPromise()
            .then(res => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Deleted');
                this.updateProgress(100, 100, msg);
                return id;
            }).catch(this.handleError);
    }
}
