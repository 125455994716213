// Note:  named this file a bit differently so the name would not be excessively long.
import { plainToClass } from 'class-transformer';

//import { FormFieldConstraintBase } from './form-field-constraint-base.model';
import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../interfaces/iform-field-constraint.interface';
import { ICloneAndCopy } from '../../../interfaces/clone-and-copy';
import { FormFieldConstraintBase } from './form-field-constraint-base.model';
//import { SiteFormFieldConstraintProperties } from './site-form-field-constraint-props.model';
import { FormFieldConstraintTypeEnum } from '../../../enums/form-field-constraint-type.enum';
import { DateUtil } from '../../../utility-classes/date.util';

export class DateRangeDataForJsonSerialization {
    // Properties.
    public minDate: string = null;
    public maxDate: string = null;

    // Constructor.
    public constructor(minDateValue: string, maxDateValue: string) {
        this.minDate = minDateValue;
        this.maxDate = maxDateValue;
    }

    // JSON-related methods.
    public toJson(): string {
        let json: string = JSON.stringify(this);
        return json;
    }
    public static fromJson(json: string): DateRangeDataForJsonSerialization {
        let object = ((json != null) && (json.trim() != '') ? JSON.parse(json) : null);
        let result: DateRangeDataForJsonSerialization = (object != null ? plainToClass(DateRangeDataForJsonSerialization, object) : null);
        return result;
    }
}

export class FormFieldDateRangeConstraint extends FormFieldConstraintBase implements IFormFieldConstraint, ICloneAndCopy {
    // Properties.
    public minDate: string = null;
    public maxDate: string = null;

    // Constructor.
    /*
    public constructor(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        super(dataCollectionId, constraintId, FormFieldConstraintTypeEnum.DateRange, constraintName);

        if (constraintValue != null)
            this.constraintExpression = constraintValue;
    }
    */
    public constructor() {
        super(0, 0, FormFieldConstraintTypeEnum.DateRange, null);
    }

    // Static method (will plan to do away with this after troubleshooting the problem with "plainToClass(FormFieldDateRangeConstraint,object)".
    //
    // Note:  the problem is that the above constructor will not work with plainToClass(), so the constructor needs to be replaced.
    /*
    public static fromServerObject(serverObject: any): FormFieldDateRangeConstraint {
        let constraint = new FormFieldDateRangeConstraint();// serverObject['dataCollectionId'], serverObject['constraintId'], serverObject['constraintName']);

        constraint.dataCollectionId = serverObject['dataCollectionId'];
        constraint.constraintId = serverObject['constraintId'];
        constraint.constraintType = FormFieldConstraintTypeEnum.DateRange;
        constraint.constraintName = serverObject['constraintName'];
        constraint.minDate = serverObject['minDate'];
        constraint.maxDate = serverObject['maxDate'];

        return constraint;
    }
    */
    // constructConstraint() -- a constructor-like method.
    public static constructConstraint(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        let constraint = new FormFieldDateRangeConstraint();

        constraint.dataCollectionId = dataCollectionId;
        constraint.constraintId = constraintId;
        constraint.constraintType = FormFieldConstraintTypeEnum.DateRange;
        constraint.constraintName = constraintName;
        if (constraintValue != null)
            constraint.constraintExpression = constraintValue;

        return constraint;
    }

    // Implement IFormFieldConstraint.
    // Property-related methods.
    public get type(): string {
        return FormFieldConstraintTypeEnum.DateRange;
    }

    public get constraintExpression(): string {
        let objectForSerialization = new DateRangeDataForJsonSerialization(this.minDate, this.maxDate);
        let json = objectForSerialization.toJson();
        return json;
    }
    public set constraintExpression(value: string) {
        let serializationObject: DateRangeDataForJsonSerialization = DateRangeDataForJsonSerialization.fromJson(value);
        if (serializationObject != null) {
            this.minDate = serializationObject.minDate;
            this.maxDate = serializationObject.maxDate;
        } else {
            this.minDate = null;
            this.maxDate = null;
        }
    }

    public get constraintExpressionSummary(): string {
        let minDate: Date = (this.minDate != null ? DateUtil.DateToUTCDate(this.minDate) : null);
        let maxDate: Date = (this.maxDate != null ? DateUtil.DateToUTCDate(this.maxDate) : null);

        /*
        if ((this.minDate != null) && (this.maxDate != null))
            summary = `${this.minDate} - ${this.maxDate}`;
        else if (this.minDate != null)
            summary = `> ${this.minDate}`;
        else if (this.maxDate != null)
            summary = `< ${this.maxDate}`;
        */
        /*
        let summary: string = '';

        if ((minDate != null) && (maxDate != null))
            summary = `${minDate} - ${maxDate}`;
        else if (minDate != null)
            summary = `On or after ${minDate}`;
        else if (maxDate != null)
            summary = `Before or on ${maxDate}`;
        */
        let summary: string = FormFieldDateRangeConstraint.deriveExpressionSummary(minDate, maxDate);
         
        return summary;
    }

    // Not relevant for this field type
    public get constraintFailureMessage(): string {
        return null;
    }
    public set constraintFailureMessage(value: string) {
        // noop
    }

    // Logic-related methods.
    public validate(formField: FormField, formInstanceElement: FormInstanceElement): void {
        // TO DO:  IMPLEMENT THIS METHOD.
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        //let clone: FormFieldDateRangeConstraint = new FormFieldDateRangeConstraint(this.dataCollectionId, this.constraintId, this.constraintName);
        let clone = FormFieldDateRangeConstraint.constructConstraint(this.dataCollectionId, this.constraintId, this.constraintName);

        //clone.constraintId = this.constraintId;
        //clone.constraintName = this.constraintName;
        clone.minDate = this.minDate;
        clone.maxDate = this.maxDate;

        return clone;
    }

    public copy(objectToCopyParam: ICloneAndCopy): void {
        let objectToCopy: FormFieldDateRangeConstraint = <FormFieldDateRangeConstraint>objectToCopyParam;

        this.dataCollectionId = objectToCopy.dataCollectionId;
        this.constraintId = objectToCopy.constraintId;
        this.constraintType = objectToCopy.constraintType;
        this.constraintName = objectToCopy.constraintName;
        this.minDate = objectToCopy.minDate;
        this.maxDate = objectToCopy.maxDate;
    }

    // Helper methods.
    private static deriveExpressionSummary(minDate: Date, maxDate: Date): string {
        let summary: string = '';

        if ((minDate != null) && (maxDate != null))
            summary = `${DateUtil.AsMMDDYYYY(minDate)} to ${DateUtil.AsMMDDYYYY(maxDate)}`;
        else if (minDate != null)
            summary = `On or after ${DateUtil.AsMMDDYYYY(minDate)}`;
        else if (maxDate != null)
            summary = `Before or on ${DateUtil.AsMMDDYYYY(maxDate)}`;

        return summary;
    }
}

export class FormFieldDateRangeConstraintWithMetrics extends FormFieldDateRangeConstraint implements IFormFieldConstraintWithMetrics {
    // Properties.
    public useCount: number = 0;

    // Constructor.
    public constructor(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        //super(dataCollectionId, constraintId, constraintName, constraintValue);
        super();

        this.dataCollectionId = dataCollectionId;
        this.constraintId = constraintId;
        this.constraintName = constraintName;

        if (constraintValue != null)
            this.constraintExpression = constraintValue;
    }
}
