import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, RouterState } from '@angular/router';
import { CurrentUserService } from '../security/current-user.service';

@Injectable()
export class HomePageRouteGuard implements CanActivate {


    constructor(
        private router: Router,
        private currentUserService: CurrentUserService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let siteId = parseInt(route.parent.url[1]?.path);

        return this.currentUserService.initialRouteToActiviateForUser(siteId).then(route => {
            if (route == 'summary') {
                this.router.navigate(['site', siteId, 'summary']);
            } else if (route == "home-page") {
                this.router.navigate(['site', siteId, 'home-page']);
            } else if (route == 'structure') {
                this.router.navigate(['site', siteId, 'structure']);
            }
            
            // returning false allows the manually performed navigation above to take effect, and effectively cancels the navigation initiated via the routing mechanism
            return false;
        });

    }
}
