<ng-container *ngIf="this.HasDesignConfiguration">
    <div class="kendo-grid-wrapper-design">
        <div class="footnotes-title-div field-label" *ngIf="this.FootnotesTitle">{{this.FootnotesTitle}}</div>
        <kendo-grid>
            <kendo-grid-column field="identifier" title="Identifier" editor="text"></kendo-grid-column>
            <kendo-grid-column field="text" title="Text" editor="text"></kendo-grid-column>
        </kendo-grid>
    </div>
</ng-container>

<ng-container *ngIf="false && ((this.Mode === 'preview') || (this.Mode === 'instance'))">
    <p>The footnote form field '{{this.Mode}}' mode is not yet implemented.</p>
</ng-container>

<ng-container *ngIf="this.HasPreviewInstanceConfiguration">
    <div class="kendo-grid-wrapper">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header class="footnotes-expansion-panel">
                    <mat-panel-title><div class="footnotes-title-div field-label" *ngIf="this.FootnotesTitle">{{this.FootnotesTitle}}</div></mat-panel-title>
                </mat-expansion-panel-header>

                <kendo-grid [kendoGridBinding]="this.FootnoteData"
                            [navigable]="true"
                            (cellClick)="handleEditRow($event)"
                            (save)="handleAddOrUpdate($event)"
                            (remove)="handleRemove($event)"
                            (add)="addRowToUI($event)"
                            (cancel)="handleCancel($event)"
                            (edit)="handleEditRow($event)">

                    <ng-template kendoGridToolbarTemplate>
                        <button kendoGridAddCommand style="margin-left:4px; padding:4px; height:30px;">
                            <mat-icon aria-hidden="false" aria-label="Add grid row" class="add-row-mat-icon" title="Add row">add</mat-icon>
                        </button>
                    </ng-template>

                    <kendo-grid-column field="identifier" title="Identifier" editor="text"></kendo-grid-column>
                    <kendo-grid-column field="text" title="Text" editor="text"></kendo-grid-column>

                    <kendo-grid-command-column [width]="90">
                        <ng-template kendoGridCellTemplate let-isNew="isNew">
                            <button class="command-btn" kendoGridEditCommand kendoGridFocusable><mat-icon class="row-action-icon" title="edit">edit</mat-icon></button>
                            <button class="command-btn" kendoGridRemoveCommand kendoGridFocusable><mat-icon class="row-action-icon" title="delete">delete</mat-icon></button>

                            <button class="command-btn" kendoGridSaveCommand kendoGridFocusable><mat-icon class="row-action" title="Save Row">save</mat-icon></button>
                            <button class="command-btn" kendoGridCancelCommand kendoGridFocusable><mat-icon class="row-action" title="cancel">cancel</mat-icon></button>
                        </ng-template>
                    </kendo-grid-command-column>
                </kendo-grid>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-container>
