import { Injectable } from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';

import { ITestableComponent } from '../interfaces/itestable-component.interface';
import { UITestCenterDialog } from '../dialogs/ui-test-center/ui-test-center.dialog';
import { ComponentTreeNode, FlatComponentTreeNode } from '../models/component-hierarchy/component-tree-node.model';
import { StringUtil } from '../utility-classes/string.util';
import { ScriptableComponentStatus } from '../enums/component-scripting/scriptable-component-status.enum';

// Define a class used to model the component hierarchy.
class ComponentHierarchyNode {
    public component: ITestableComponent;
    public childComponentNodes: ComponentHierarchyNode[] = [];

    public constructor(component: ITestableComponent, childComponentNodes: ComponentHierarchyNode[] = null) {

        this.component = component;
        if (childComponentNodes != null)
            this.childComponentNodes = childComponentNodes;
    }
}
 
export interface IComponentHierarchyChanged {
    componentAdded(component: ITestableComponent);
    componentRemoved(component: ITestableComponent, currentParentComponent: ITestableComponent): void;
    hierarchyChanged(): void;
    componentInitialized(component: ITestableComponent, initializationStatus: ScriptableComponentStatus): void;
}

class LogData {
    public logToConsole: boolean = false;
    public log: string[] = []; // Default log.
    public errorLog: string[] = [];
    //public testActionLog: string[] = [];

    public clearLog(): void {
        this.log = [];
    }
    public clearErrorLog(): void {
        this.errorLog = [];
    }
    public clearLogs(): void {
        this.log = [];
        this.errorLog = [];
    }
}

@Injectable({
    providedIn: 'root'
})
export class ComponentHierarchyService {
    // Properties.
    private rootComponentNode: ComponentHierarchyNode = null;
    private currentParentComponentNode: ComponentHierarchyNode = null;
    private cachedTreeNodes: TreeNode[] = null;

    private hierarchyChangedHandlers: IComponentHierarchyChanged[] = [];
    private uiTestCenterDialog: UITestCenterDialog = null; // Provided as a convenience to other classes.
    // Debug-related properties.
    private logData: LogData = new LogData();

    // Constructor.
    public constructor() {
    }

    // Getter method(s).
    public get LogToConsole(): boolean {
        return this.logData.logToConsole;
    }

    public set UITestCenterDialog(value: UITestCenterDialog) {
        this.uiTestCenterDialog = value;
    }
    public get UITestCenterDialog(): UITestCenterDialog {
        return this.uiTestCenterDialog;
    }
    public get UITestingUnderway(): boolean {
        return this.uiTestCenterDialog != null;
    }

    // Component registration.
    public registerChildComponent(component: ITestableComponent, registerAsCurrentParent: boolean = true): void {
        this.cachedTreeNodes = null; // Clear the cached nodes.

        // Create a new node.
        let componentNode: ComponentHierarchyNode = new ComponentHierarchyNode(component);

        if (this.rootComponentNode == null)
            this.rootComponentNode = componentNode;

        let tagName: string = (component.tagName != null ? component.tagName : 'null');

        let parentTagName: string = ((this.currentParentComponentNode != null) && (this.currentParentComponentNode.component.tagName != null) ? this.currentParentComponentNode.component.tagName : 'null');

        this.logComponentRegistration(tagName, parentTagName);

        // Add to the hierarchy.
        if (this.currentParentComponentNode != null)
            this.currentParentComponentNode.childComponentNodes.push(componentNode);

        // Register as the new parent component?
        if (registerAsCurrentParent) {
            this.currentParentComponentNode = componentNode;

            this.logComponentParentRegistration(tagName);
        }

        if (this.logData.logToConsole)
            this.printHierarchy();

        if (this.hierarchyChangedHandlers.length > 0) {
            for (let index: number = 0; index < this.hierarchyChangedHandlers.length; index++) {
                let handler: IComponentHierarchyChanged = this.hierarchyChangedHandlers[index];
                handler.componentAdded(component);
                handler.hierarchyChanged();
            }
        }        
    }

    public componentInitialized(component: ITestableComponent, initializationStatus: ScriptableComponentStatus): void {
        if (this.hierarchyChangedHandlers.length > 0) {
            for (let index: number = 0; index < this.hierarchyChangedHandlers.length; index++) {
                let handler: IComponentHierarchyChanged = this.hierarchyChangedHandlers[index];
                handler.componentInitialized(component, initializationStatus);
            }
        }
    }

    public removeComponent(testableComponent: ITestableComponent): boolean {
        this.cachedTreeNodes = null; // Clear the cached nodes.

        let tagName: string = (testableComponent.tagName != null ? testableComponent.tagName : 'null');
        let message: string = `registerChildComponent(): removing a component with tag '${tagName.toLowerCase()}'.`;
        this.logInfo(message);

        //let removed: boolean = this.removeComponentNodeFor(null, this.rootComponentNode, testableComponent);
        let removedNode: ComponentHierarchyNode = this.removeComponentNodeFor(null, this.rootComponentNode, testableComponent);

        if (this.logData.logToConsole)
            this.printHierarchy();

        //if ((this.hierarchyChangedHandler != null) && removed) {
        if ((this.hierarchyChangedHandlers.length > 0) && (removedNode != null)) {
            for (let index: number = 0; index < this.hierarchyChangedHandlers.length; index++) {
                let handler: IComponentHierarchyChanged = this.hierarchyChangedHandlers[index];
                ComponentHierarchyService.notifyComponentRemoved(removedNode, this.currentParentComponentNode, handler);
                handler.hierarchyChanged();
            }
        }            

        return removedNode != null;
    }

    public get HierarchyAsTreeNodes(): TreeNode[] {
        if (this.cachedTreeNodes == null) {
            this.cachedTreeNodes = [];

            if (this.rootComponentNode != null) {
                let node = this.mapHierarchyNodeToTreeNode(null, this.rootComponentNode);
                this.cachedTreeNodes.push(node);
            }            
        }

        return this.cachedTreeNodes;
    }

    public get HierarchyAsFlatTreeNodes(): FlatComponentTreeNode[] {
        let nodes: FlatComponentTreeNode[] = [];

        if (this.rootComponentNode != null) {
            let node = this.mapHiherarchyNodeToFlatTreeNode(null, this.rootComponentNode);
            nodes.push(node);
        }

        return nodes;
    }

    public get Log(): string[] {
        return this.logData.log;
    }
    public get LogText(): string {
        return StringUtil.arrayToString(this.logData.log);
    }
    public get LogIsEmpty(): boolean {
        return this.logData.log.length == 0;
    }
    public get ErrorLog(): string[] {
        return this.logData.errorLog;
    }
    public get ErrorLogText(): string {
        return StringUtil.arrayToString(this.logData.errorLog);
    }

    //public findComponentTreeNodeIn(rootTreeNode: ComponentTreeNode, component: ITestableComponent): ComponentTreeNode {
    public findComponentTreeNodeIn(rootTreeNode: ComponentTreeNode, component: ITestableComponent): ComponentTreeNode {
        let componentTreeNode: ComponentTreeNode = null;

        if (rootTreeNode.Component == component)
            componentTreeNode = rootTreeNode;
        else {
            if (rootTreeNode.children != null) {
                for (let index: number = 0; index < rootTreeNode.children.length; index++) {
                    let child: ComponentTreeNode = <ComponentTreeNode>rootTreeNode.children[index];
                    componentTreeNode = this.findComponentTreeNodeIn(child, component);
                    if (componentTreeNode != null)
                        break;
                }
            }
        }

        return componentTreeNode;
    }

    public findComponentTreeNodeByTagFromRoot(tagName: string, rootNode: ComponentHierarchyNode = this.rootComponentNode): ITestableComponent {
        let foundComponent: ITestableComponent = null;

        if (rootNode != null) {
            if (rootNode.component.tagName == tagName)
                foundComponent = rootNode.component;
            else if (rootNode.childComponentNodes != null) {
                for (let index: number = 0; index < rootNode.childComponentNodes.length; index++) {
                    let childNode: ComponentHierarchyNode = rootNode.childComponentNodes[index];
                    foundComponent = this.findComponentTreeNodeByTagFromRoot(tagName, childNode);
                    if (foundComponent != null)
                        break;
                }
            }
        }

        return foundComponent;
    }

    public clearLogs(): void {
        this.logData.clearLogs();
    }

    // Hierarchy changed handler.
    public addHierarchyChangedHandler(hierarchyChangedHandler: IComponentHierarchyChanged): void {
        this.hierarchyChangedHandlers.push(hierarchyChangedHandler);
    }
    public removeHierarchyChangedHandler(hierarchyChangedHandler: IComponentHierarchyChanged): boolean {
        let numHandlers: number = this.hierarchyChangedHandlers.length;
        this.hierarchyChangedHandlers = this.hierarchyChangedHandlers.filter(h => h != hierarchyChangedHandler);
        return numHandlers != this.hierarchyChangedHandlers.length;
    }

    // Logging.
    public logInfo(message: string): void {
        this.logData.log.push(message);

        if (this.logData.logToConsole) 
            console.log(message);            
    }
    public logError(message: string): void {
        this.logData.errorLog.push(message);

        if (this.logData.logToConsole)
            console.log(message);            
    }

    // Helper methods.
    private getTagNameFor(component: ITestableComponent): string {
        return component.tagName != null ? component.tagName.toLowerCase() : 'null';
    }

    private printHierarchy(): void {
        this.logInfo(`printHierarchy():  begin component hierarchy:`);
        if (this.rootComponentNode != null) 
            this.printHierarchyNode(this.rootComponentNode);        
        this.logInfo(`printHierarchy():  component hierarchy complete.`);
    }

    private printHierarchyNode(node: ComponentHierarchyNode, hierarchyLevel: number = 0): void {
        let message: string = '    ';
        for (let index: number = 0; index < hierarchyLevel; index++)
            message += '    ';
        message += this.getTagNameFor(node.component);
        this.logInfo(message);

        if (node.childComponentNodes != null) {
            for (let index: number = 0; index < node.childComponentNodes.length; index++)
                this.printHierarchyNode(node.childComponentNodes[index], hierarchyLevel + 1);
        }            
    }

    //private removeComponentNodeFor(parentComponentNode: ComponentHierarchyNode, currentComponentNode: ComponentHierarchyNode, component: ITestableComponent): boolean {
    private removeComponentNodeFor(parentComponentNode: ComponentHierarchyNode, currentComponentNode: ComponentHierarchyNode, component: ITestableComponent): ComponentHierarchyNode {
        //let removed: boolean = false;
        let removedComponentNode: ComponentHierarchyNode = null;

        if (currentComponentNode != null) {
            if (currentComponentNode.component == component) {
                if (parentComponentNode != null) {
                    parentComponentNode.childComponentNodes = parentComponentNode.childComponentNodes.filter(n => n.component != component);
                    this.currentParentComponentNode = parentComponentNode;
                } else {
                    this.rootComponentNode = null;
                }

                //removed = true;
                removedComponentNode = currentComponentNode;
            } else {
                for (let index: number = 0; index < currentComponentNode.childComponentNodes.length; index++) {
                    removedComponentNode = this.removeComponentNodeFor(currentComponentNode, currentComponentNode.childComponentNodes[index], component);
                    //if (this.removeComponentNodeFor(currentComponentNode, currentComponentNode.childComponentNodes[index], component)) {
                    if (removedComponentNode != null) {
                        //removed = true;
                        break;
                    }
                }
            }
                
        }

        //return removed;
        return removedComponentNode;
    }

    private static notifyComponentRemoved(removedComponentNode: ComponentHierarchyNode, currentParentComponentNode: ComponentHierarchyNode, handler: IComponentHierarchyChanged): void {
        handler.componentRemoved(removedComponentNode.component, currentParentComponentNode.component);

        // Notify of any removed children.
        if (removedComponentNode.childComponentNodes != null) {
            for (let index: number = 0; index < removedComponentNode.childComponentNodes.length; index++) {
                let childNode: ComponentHierarchyNode = removedComponentNode.childComponentNodes[index];
                ComponentHierarchyService.notifyComponentRemoved(childNode, currentParentComponentNode, handler);
            }
        }
    }

    private mapHierarchyNodeToTreeNode(parentTreeNode: TreeNode<ComponentTreeNode>, hierarchyNode: ComponentHierarchyNode, hierarchyLevel: number = 0): TreeNode {
        let treeNode = new ComponentTreeNode(hierarchyNode.component, parentTreeNode, hierarchyLevel);

        if ((hierarchyNode.childComponentNodes != null) && (hierarchyNode.childComponentNodes.length > 0)) {
            for (let index: number = 0; index < hierarchyNode.childComponentNodes.length; index++) {
                let childHierarchyNode: ComponentHierarchyNode = hierarchyNode.childComponentNodes[index];

                let childTreeNode = this.mapHierarchyNodeToTreeNode(treeNode, childHierarchyNode, hierarchyLevel + 1);
                treeNode.addChild(childTreeNode);
            }
        }

        return treeNode;
    }
    private mapHiherarchyNodeToFlatTreeNode(parentTreeNode: FlatComponentTreeNode, hierarchyNode: ComponentHierarchyNode, hierarchyLevel: number = 0): FlatComponentTreeNode {
        let treeNode = new FlatComponentTreeNode(hierarchyNode.component, parentTreeNode, hierarchyLevel);

        if ((hierarchyNode.childComponentNodes != null) && (hierarchyNode.childComponentNodes.length > 0)) {
            for (let index: number = 0; index < hierarchyNode.childComponentNodes.length; index++) {
                let childHierarchyNode: ComponentHierarchyNode = hierarchyNode.childComponentNodes[index];

                let childTreeNode: FlatComponentTreeNode = this.mapHiherarchyNodeToFlatTreeNode(treeNode, childHierarchyNode, hierarchyLevel + 1);
                treeNode.addChild(childTreeNode);
            }
        }

        return treeNode;
    }

    // Logging-related methods.
    private logComponentRegistration(tagName: string, parentTagName: string): void {
        let message: string = `registerComponent():  component '${tagName.toLowerCase()}' ` +
                              (this.currentParentComponentNode != null ? `is a child of component '${parentTagName.toLowerCase()}'.` : "is the application root element.");
        this.logInfo(message);
    }

    private logComponentParentRegistration(tagName: string): void {
        let message: string = `registerComponent():  component '${tagName.toLowerCase()}' is the current parent component.`;
        this.logInfo(message);
    }
}
