import { FormField } from '../../../../../models/form-builder/form-field.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../../../interfaces/iform-field-constraint.interface';
import { FormFieldSelectOptionsConstraint, FormFieldSelectOptionsConstraintWithMetrics } from '../../../../../models/form-builder/field-constraints/field-select-options-constraint.model';
import { CachedFormFieldService } from '../../../../../services/form-field-with-cache.service';
import { IFormFieldConstraintLiaison } from './ifield-constraint-liason.interface';
import { FieldConstraintLiaisonBase } from './field-constraint-liaison-base';
import { SiteFormFieldConstraintProperties } from '../../../../../models/form-builder/field-constraints/site-form-field-constraint-props.model';
import { DropDownFieldType, RadioButtonsFieldType } from '../../../../../models/form-builder/form-field-types';
import { FormFieldConstraintTypeEnum, FormFieldConstraintTypeForUsersEnum } from '../../../../../enums/form-field-constraint-type.enum';

export class FormFieldSelectOptionsConstraintLiaison extends FieldConstraintLiaisonBase implements IFormFieldConstraintLiaison {
    // Constructor.
    public constructor() {
        super();
    }

    // Implement IFormFieldConstraintLiaison.
    // Getter/setter-like methods.
    public constraintTypeForEndUser(): string {  // A user-friendly constraint type name.
        return FormFieldConstraintTypeForUsersEnum.SelectOptions;
    }

    public managesConstraintsOfType(): string {
        return FormFieldConstraintTypeEnum.SelectOptions;
    }

    public getPrimaryPropertyName(): string {
        return 'selectOptions';
    }

    public getConstraintId(formField: FormField): number {
        return formField.selectOptionsConstraintId;
    }
    public setConstraintId(formField: FormField, value: number): void {
        formField.selectOptionsConstraintId = value;
    }

    public getConstraintName(formField: FormField): string {
        return formField.selectOptionsConstraintName;
    }
    public setConstraintName(formField: FormField, value: string): void {
        formField.selectOptionsConstraintName = value;
    }

    public getConstraintValue(formField: FormField): string {
        return formField.selectOptionsConstraintValue;
    }
    public setConstraintValue(formField: FormField, value: string): void {
        formField.selectOptionsConstraintValue = value;
    }

    // not relevant for this field type
    public getConstraintFailureMessage(formField: FormField): string {
        return null;
    }
    public setConstraintFailureMessage(formField: FormField, value: string): void {
        // noop
    }

    public getConstraintFormFieldPropertyValue(formField: FormField): string {
        return formField.selectOptions;
    }

    public copyConstraintValueToCorrespondFormFieldProperty(formField: FormField, value: string): void {
        formField.selectOptions = value;
    }

    // Create a new, empty constraint.
    public createNewConstraint(dataCollectionId: number): IFormFieldConstraintWithMetrics {
        return new FormFieldSelectOptionsConstraintWithMetrics(dataCollectionId);
    }

    public createNewConstraintFrom(constraintParam: IFormFieldConstraint): IFormFieldConstraintWithMetrics {
        let constraint = <FormFieldSelectOptionsConstraint>constraintParam;
        let newConstraint = new FormFieldSelectOptionsConstraintWithMetrics(constraint.dataCollectionId, constraint.id, constraint.name, constraint.constraintExpression);
        return newConstraint;
    }

    // Package constraint values as a constraint.
    public packageConstraint(dataCollectionId: number, formField: FormField): IFormFieldConstraint {
        let packagedConstraint = new FormFieldSelectOptionsConstraint(dataCollectionId, formField.selectOptionsConstraintId, formField.selectOptionsConstraintName, formField.selectOptionsConstraintValue);

        return packagedConstraint;
    }
    public updateOrCreateConstraint(cachedFormFieldService: CachedFormFieldService, updatedConstraintParam: IFormFieldConstraint): void {
        let updatedConstraint: FormFieldSelectOptionsConstraint = <FormFieldSelectOptionsConstraint>updatedConstraintParam;
        cachedFormFieldService.updateOrCreateSelectOptionsConstraint(updatedConstraint);
    }

    // Methods related to applying form field constraint
    // updates to a form field that uses the same constraint.
    public get createNamedConstraintMethodName(): string {
        return 'createSelectOptionsConstraint';
    }
    public get updateNamedConstraintMethodName(): string {
        return 'updateSelectOptionsConstraint';
    }
    public get updateOrCreateNamedConstraintMethodName(): string {
        return 'updateOrCreateSelectOptionsConstraint';
    }
    public get siteNamedConstraintsMethodName(): string {
        return 'getSiteNamedSelectOptions';
    }

    public get usedByFormFieldClasses(): string[] {
        return [DropDownFieldType, RadioButtonsFieldType];
    }

    public applyConstraintPropertiesToFormField(constraint: IFormFieldConstraint, formField: FormField) {
        formField.selectOptionsConstraintId = constraint.id;
        formField.selectOptionsConstraintName = constraint.name;
        formField.selectOptionsConstraintValue = constraint.constraintExpression;
        formField.selectOptions = constraint.constraintExpression;        
    }

    // A method to create a constraint from the results of an all site named constraints query.
    public createFromConstraintProperties(dataCollectionId: number, constraintProperties: SiteFormFieldConstraintProperties): IFormFieldConstraintWithMetrics {
        let constraint: FormFieldSelectOptionsConstraintWithMetrics = new FormFieldSelectOptionsConstraintWithMetrics(dataCollectionId, constraintProperties.constraintId, constraintProperties.constraintName, constraintProperties.selectOptionsValue);
        constraint.useCount = constraintProperties.useCount;
        return constraint;
    }
}
