import { ElementRef } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

import { ClickableElementTypeMetadata } from './clickable-element-type-metadata.model';
//import { HtmlPropertyValue } from './html-property-value.model';
import { ElementCountExpected } from './element-count-expected.enum';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
import { HtmlElementTypeNames } from './html-element-type-names.model';
import { AdditionalElementInfo } from './additional-element-info.model';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { ScriptableBaseComponent } from '../../components/scriptable-base/scriptable-base.component';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
import { HtmlElementInfo } from './html-element-info.model';

declare let $: any; // jQuery

export class MatCheckBoxElementTypeMetadata extends ClickableElementTypeMetadata {
    public constructor() {
        //super('input.mat-checkbox-input', HtmlElementTypeNames.checkBox_metadataKey, ElementCountExpected.Singular);
        super('mat-checkbox', HtmlElementTypeNames.matCheckBox_metadataKey, ElementCountExpected.Singular);
    }

    // Override metadata methods.
    public getTitle = (driver: IBrowserDriver, element: object, additionalElementInfo: AdditionalElementInfo = null): string => {
        let title: string = driver.getTextFor(element);
        title = title.trim();
        return title;
    }

    //public click(driver: IBrowserDriver, component: ITestableComponent, element: object): void {
    public click(driver: IBrowserDriver, recorderService: ITestActionRecorderService, component: ITestableComponent, htmlElementInfo: HtmlElementInfo, element: object, getClickableElementFunction: (element: object) => object): void {
        //driver.clickButton(component, element);
        //$('#formPropertiesDiv').find('mat-checkbox').find('input').prop('checked',true);
        let input = $(element).find('input');
        input.prop('checked', true);
        let value = input.prop('checked');
        $(element).trigger('input');

        if (component != null) {
            let baseComponent: ScriptableBaseComponent = <ScriptableBaseComponent>component; // Need to use the interface instead!!!

            if ((baseComponent.matCheckboxes != null) && (baseComponent.matCheckboxElementRefs != null) && (baseComponent.matCheckboxes.length == baseComponent.matCheckboxElementRefs.length)) {
                let matCheckboxesArray: MatCheckbox[] = baseComponent.matCheckboxes.toArray();
                let matCheckboxElementRefsArray: ElementRef[] = baseComponent.matCheckboxElementRefs.toArray();

                for (let index: number = 0; index < matCheckboxesArray.length; index++) {
                    let matCheckbox: MatCheckbox = matCheckboxesArray[index];
                    let matInputElementRef: ElementRef = matCheckboxElementRefsArray[index];

                    if (element == matInputElementRef.nativeElement) {
                        //matInput.ngControl.control.setValue(value);
                        //driver.sendKeysTo(element, matCheckbox, value);
                        //matCheckbox.checked = !matCheckbox.checked;
                        matCheckbox.toggle();
                        matCheckbox.change.emit();

                        break;
                    }
                }
            }
        }
    }
}
