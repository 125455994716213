import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { VERSION } from "../../../environments/version";
import { AppBuildInfo} from '../models/admin/app-build-info.model';
import { BuildInfo } from '../models/admin/build-info.model';

@Injectable()
export class AdminService {

    private buildInfoUrl = "";


    constructor(private http: HttpClient) {
        this.buildInfoUrl = environment.apiUrl + "/frameworkapi/buildinfo";
    }

    public get appBuildInfo(): Promise<any> {
        return this.http.get(this.buildInfoUrl)
            .toPromise()
            .then((response: BuildInfo) => {
                let abi = new AppBuildInfo();
                abi.apiBuildInfo = response;
                abi.webBuildInfo = VERSION;
                return abi;
            })
            .catch((error: Response) => {
                return this.handleError(error); //"Can't get the API configuration at " + this.baseUrl;
            });
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
