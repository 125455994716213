import {
    NG_VALUE_ACCESSOR, UntypedFormControl
} from '@angular/forms';

export interface ICheckboxValueChanged {
    checkboxValueChanged(index: number, checkboxData: CheckboxData): void;
}

export class CheckboxData {
    // Properties.
    public id: number = 0;
    public title: string;
    public value: boolean = false;

    private index: number = -1;
    private checkboxValueChanged: ICheckboxValueChanged;
    private formControl: UntypedFormControl;

    // Constructor.
    public constructor(indexParam: number,
        checkboxValueChangedParam: ICheckboxValueChanged) {
        this.index = indexParam;
        this.checkboxValueChanged = checkboxValueChangedParam;

        return;
    }

    // Getter methods.
    public get FormControl(): UntypedFormControl {
        return (this.formControl);
    }

    // Methods.
    public setupFormControl(disabled: boolean = false): void {
        // Setup my form control.
        let hshControlProperties = {
            value: this.value,
            disabled: disabled
        }

        this.formControl = new UntypedFormControl(hshControlProperties);

        // Listen for/subscribe to value changes.
        this.formControl.valueChanges
            .subscribe(val => {
                this.value = val;

                if (this.checkboxValueChanged != null)
                    this.checkboxValueChanged.checkboxValueChanged(this.index, this);
            });

        return;
    }
}
