import { IUserOrGroupInfo } from './iuser-or-group-info.interface';
import { UserTypeEnum } from './user-type.enum';

export class CommentUserOrGroupInfo implements IUserOrGroupInfo {
    // Static data.
    private static nextClientId: number = 1;

    public clientId: number;

    //public isUser: boolean;
    public userType: UserTypeEnum;
    public userOrGroupId: string;
    public displayText: string;

    public constructor(userType: UserTypeEnum, userOrGroupId: string, displayText: string) {
        this.clientId = CommentUserOrGroupInfo.nextClientId++;

        //this.isUser = isUser;
        this.userType = userType;
        this.userOrGroupId = userOrGroupId;
        this.displayText = displayText;
    }
}
