import { ElementTypeMetadata } from './element-type-metadata.model';
import { IElementTypeMetadata } from '../../interfaces/component-scripting/ielement-type-metadata';
import { AdditionalElementInfo } from './additional-element-info.model';
import { ElementTypeInfo } from './element-type-info.model';

export class HtmlElementInfo {
    public additionalElementInfo: AdditionalElementInfo = null;
    public elementSubtype: string = null;
    public displayTabName: string = null;

    public constructor(public elementTypeMetadata: IElementTypeMetadata, public element: object, unusedElementType: string, public elementTitle: string) {
    }

    public get elementType(): string {
        return this.elementTypeMetadata.metadataKey;
    }
}
export interface IComponentElementsByElementType {
    [elementType: string]: HtmlElementInfo[];
}
export interface IDisplayTabNameToElementType {
    [displayTabName: string]: ElementTypeInfo;
}
