
import { AsyncJobDialogBase, JOB_COMPLETED_MESSAGE } from './async-job-base.dialog';
import { AsyncJobService } from '../../services/async-job.service';
import { ImportMetricsBase } from '../../models/export-import/import-metrics-base';
import { ImportFormInstancesMetrics } from '../../models/export-import/import-form-instances-metrics.model';
import { ExportFormInstancesMetrics } from '../../models/export-import/export-form-instances-metrics.model';

export abstract class ImportAsyncJobDialogBase extends AsyncJobDialogBase {
    // Constructor.
    public constructor(private providedAsyncJobService: AsyncJobService) {
        super(providedAsyncJobService);
    }

    // Methods called by HTML code.
    public get ImportStatus(): string {
        let status: string = null;

        if ((!this.jobInProgress) && (this.asyncJobInfo == null)) {
            // We haven't started a copy job.
            status = 'Not started';
        } else if (this.jobInProgress) {
            // A copy job is in progress.
            status = 'Import in progress';
        } else if (!this.jobErrorOccurred) {
            // A copy completed without error.
            status = JOB_COMPLETED_MESSAGE;//'Completed';
        } else {
            // A copy error occurred.
            status = 'An import error occurred';
        }

        return (status);
    }

    // Protected methods.
    protected static parseImportFormInstancesMetrics(jobMetricsJson: string): ImportFormInstancesMetrics {
        let jobCompletedMessageJson: Object = JSON.parse(jobMetricsJson);
        let importMetrics: ImportFormInstancesMetrics = new ImportFormInstancesMetrics(jobCompletedMessageJson);

        return (importMetrics);
    }
}
