import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { IFormPreRenderFilter } from '../../../interfaces/iform-pre-render-filter.interface';
import { FormField } from '../form-field.model';
import { ShowToHideFromCustomLogicBase } from './show-to-hide-from-logic-base';
import { MarkDataAsReadOnlyPreRenderFilter } from './mark-data-as-read-only-pre-render-filter';

export class MarkDataAsReadOnlyFieldDefinitionCustomLogic extends ShowToHideFromCustomLogicBase implements IFieldDefinitionLogic {
    public constructor() {
        super();
    };

    // Implement some IFieldDefinitionLogic methods.
    public pushFormPreRenderFilterFor(formField: FormField): IFormPreRenderFilter {
        let filter: IFormPreRenderFilter = null;
        // As a show to or hide from has a begin and an end form field, only return a filter for the begin field.
        if (formField.isConditionalBeginElement)
            filter = new MarkDataAsReadOnlyPreRenderFilter(formField);
        return filter;
    }
    public popFormPreRenderFilter(filter: IFormPreRenderFilter, formField: FormField): boolean {
        let pop: boolean = false;

        if (filter instanceof MarkDataAsReadOnlyPreRenderFilter) {
            let showToFilter = <MarkDataAsReadOnlyPreRenderFilter>filter;
            let filterFormField: FormField = showToFilter.FormField;

            pop = ShowToHideFromCustomLogicBase.popFilter(filterFormField, formField);
        }

        return pop;
    }

    public filterType(): string {
        return null;
    }
}
