import { Injectable, Type as AngularCoreType } from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';

import { AppInjector } from '../../app.module';

import { ITestableComponent } from '../interfaces/itestable-component.interface';
//import { UITestCenterDialog } from '../dialogs/ui-test-center/ui-test-center.dialog';
//import { ScriptableBaseComponent } from '../components/scriptable-base/scriptable-base.component';
import { ComponentMethodsService } from '../models/component-scripting/component-methods.service';
import { StringUtil } from '../utility-classes/string.util';
import { ElementTypeMetadata } from '../models/component-scripting/element-type-metadata.model';
import { HtmlPropertyValue } from '../models/component-scripting/html-property-value.model';
import { HtmlInputValue } from '../models/component-scripting/html-input-value.model';
import { ComponentMethodMetadata, ComponentMethodsMetadata, IComponentNameToMethodsMetadata } from '../models/component-scripting/component-methods-metadata.model';
import { AsyncJobService } from './async-job.service';
import { AttachmentService } from './attachment.service';
import { AutocompleteService } from './autocomplete.service';
import { DataCollectionService } from './data-collection.service';
import { DocumentPublishingService } from './document-publishing.service';
import { ExcelExportService } from './excel-export.service';
import { ExportDataService } from './export-data.service';
import { FolderService } from './folder.service';
import { FormBuilderService } from './form-builder.service';
import { FormInstanceService } from './form-instance.service';
// IMPORT MORE SERVICE CLASSES HERE!!!

export class ServiceClassInfo {
    public constructor(public className: string, public serviceClass: AngularCoreType<any>, public serviceInstance: Object) {
    }

    public componentMethodsMetadata: ComponentMethodsMetadata = null;
}

@Injectable({
    providedIn: 'root'
})
export class ServiceClassCatalogueService {
    // Properties.
    private serviceClasses: AngularCoreType<any>[] = [AsyncJobService, FormBuilderService, ExcelExportService];
    private cachedServiceClassInfo: ServiceClassInfo[] = null;

    // Constructor.
    public constructor() {
    }

    // Getter method(s).
    public get serviceClassCatalogue(): ServiceClassInfo[] {
        if (this.cachedServiceClassInfo == null) {
            this.cachedServiceClassInfo = [];

            for (let index: number = 0; index < this.serviceClasses.length; index++) {
                let service: Object = AppInjector.get(this.serviceClasses[index]);
                let serviceClassName: string = service.constructor.name;

                let serviceClassInfo: ServiceClassInfo = new ServiceClassInfo(serviceClassName, this.serviceClasses[index], service);
                serviceClassInfo.componentMethodsMetadata = ComponentMethodsService.getComponentMethodsMetadataFor(serviceClassName);
                //serviceClassInfo.componentMethodsMetadata = ScriptableBaseComponent.getComponentMethodsMetadata(serviceClassName);
                if (serviceClassInfo.componentMethodsMetadata == null)
                    serviceClassInfo.componentMethodsMetadata = new ComponentMethodsMetadata(serviceClassName);

                this.cachedServiceClassInfo.push(serviceClassInfo);
            }
        }

        return this.cachedServiceClassInfo;
    }

    // Methods for recording test actions.
    public selectComponent(component: ITestableComponent): void {
        // TO DO:  CODE THIS METOHD.
    }

    public clickElement(element: object, elementTypeMetadata: ElementTypeMetadata): void {
        // TO DO:  CODE THIS METOHD.
    }

    public setValue(element: object, elementTypeMetadata: ElementTypeMetadata, value: string): void {
        // TO DO:  CODE THIS METOHD.
    }
    public getValue(element: object, elementTypeMetadata: ElementTypeMetadata): string {
        let value: string = null;
        // TO DO:  CODE THIS METOHD.
        return value;
    }

    // Helper methods.
}
