import {
    Component,
    Renderer2,
    AfterViewInit
} from '@angular/core';

import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';

@Component({
    selector: 'app-input-form-field-base',
    templateUrl: './input-form-field-base.component.html',
    styleUrls: ['./input-form-field-base.component.scss']
})
export abstract class InputFormFieldBaseComponent extends FormFieldBaseComponent implements AfterViewInit {
    // Properties.
    protected matInputId: string = null;

    // Constructor.
    protected constructor(protected renderer: Renderer2) {
        super();

        return;
    }

    // Life cycle methods.
    public ngAfterViewInit(): void {
        // Tell my base class that
        // my view has initialized.
        this.viewHasInitialized();

        return;
    }

    // Implement doSetFocus().
    protected doSetFocus(): void {
        let inputSelector: string = `#${this.matInputId}`;
        let matInput: any = this.renderer.selectRootElement(inputSelector);
        matInput.focus();

        return;
    }

    // Define getter methods.
    public get MatInputId(): string {
        return (this.matInputId);
    }
}
