import {
    Directive,
    Input, 
    HostListener,
    ElementRef
} from '@angular/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { DecimalPipe } from '@angular/common';

// Define, export a directive used to format a date 'matInput' control.
//
// Note:  much of the code in the following directive was taken from the following article:
//        https://medium.com/angular-in-depth/angular-material-matinput-control-with-thousands-separation-ebcbb7b027f4.
@Directive({
    selector: '[matInputDecimalFormat]',
    providers:
        [
            { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: DecimalFormatDirective }
        ]
})
export class DecimalFormatDirective {
    // Properties.
    @Input() digitsAfterDecimal: number;
    @Input() showTrailingZeroes: boolean;

    private _value: string | null;
    private fnOnChange = (_ignored: any) => { }; // Initial, NOOP function.

    // Constructor.
    public constructor(private elementRef: ElementRef<HTMLInputElement>) {
    }
    public get value(): string | null {
        return this._value;
    }

    @Input('value')
    public set value(value: string | null) {
        this._value = value;
        this.formatValue(value);
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value) {
        this._value = value.replace(/[^-^\d.]/g, '');
        this._onChange(this._value); // here to notify Angular Validators
    }

    @HostListener('blur')
    _onBlur() {
        if (this.elementRef && this.elementRef.nativeElement && this.elementRef.nativeElement.value) {
            this.formatValue(this.elementRef.nativeElement.value);
        }
        return;
    }

    @HostListener('focus')
    onFocus() {
        this.unFormatValue(); // remove commas for editing purpose
        return;
    }

    writeValue(value: any) {
        this._value = value;
        this.formatValue(this._value);
    }

    _onChange(value: any): void {
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }

    // Private helper methods.
    private formatValue(value: string | null): void {
        if (value !== null) {
            this._value = this.applyDecimalFormatting(value);
            this.elementRef.nativeElement.value = this._value;
        } else {
            this.elementRef.nativeElement.value = '';
        }

        return;
    }

    public applyDecimalFormatting(value: string | null): string {
        if (!value) return '';
        value = value.toString().replace(/(?!^)-[^0-9.]/g, '');
        if (value == '.') value = '0';
        if (value == '-') value = '0';
        if (value.split('.').length > 2) return '';
        let decimalPipe: DecimalPipe = new DecimalPipe('en-US');
        let minDigits = 0;
        let maxDigits = 3;
        if (this.digitsAfterDecimal) maxDigits = this.digitsAfterDecimal;
        if (this.showTrailingZeroes) {
            minDigits = maxDigits;
        }
        let fmt = '1.' + minDigits + '-' + maxDigits;
        return decimalPipe.transform(value, fmt);
    }

    private unFormatValue() {
        const value = this.elementRef.nativeElement.value;
        this._value = value.replace(/[^\d.-]/g, '');
        if (value) {
            this.elementRef.nativeElement.value = this._value;
        } else {
            this.elementRef.nativeElement.value = '';
        }
    }
}
