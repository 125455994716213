import { ClickableElementTypeMetadata } from './clickable-element-type-metadata.model';
import { HtmlPropertyValue } from './html-property-value.model';
import { ElementCountExpected } from './element-count-expected.enum';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
import { HtmlElementTypeNames } from './html-element-type-names.model';
import { AdditionalElementInfo } from './additional-element-info.model';

declare let $: any; // jQuery

export class ButtonsElementTypeMetadata extends ClickableElementTypeMetadata {
    public constructor() {
        super('button', HtmlElementTypeNames.buttons_metadataKey, ElementCountExpected.Singular_or_Plural);
        this.prettyElementTypeTitle = 'Buttons';
    }

    // Override metadata methods.
    public getTitle = (driver: IBrowserDriver, element: object, additionalElementInfo: AdditionalElementInfo = null): string => {
        let title: string = driver.getButtonTitle(element);
        if ((additionalElementInfo != null) && (additionalElementInfo.nameToPrettyNameMap != null))
            title = super.mapNameWithPartialMatch(title, additionalElementInfo.nameToPrettyNameMap);
        return title;
    }
    // Note:  no properties for buttons so far ...
    public hasProperties = (driver: IBrowserDriver, element: object): boolean => {
        return true;
    }
    public getProperties = (driver: IBrowserDriver, element: object): HtmlPropertyValue[] => {
        let properties: HtmlPropertyValue[] = [];

        return properties;
    }
}

export class ButtonsWithMatIconsElementTypeMetadata extends ClickableElementTypeMetadata {
    public constructor() {
        super('button', HtmlElementTypeNames.buttonsWithMatIcons_metadataKey, ElementCountExpected.Singular_or_Plural);
        this.prettyElementTypeTitle = 'Buttons with Icons';
    }

    // Override metadata methods.
    public getTitle = (driver: IBrowserDriver, element: object, additionalElementInfo: AdditionalElementInfo = null): string => {
        let title: string = driver.getButtonTitle(element);

        if (element['firstChild'] != null) {
            let firstChild: object = element['firstChild'];
            if ((firstChild['children'] != null) && (firstChild['children'].length > 0)) {
                let child: object = firstChild['children'][0];
                if ($(child).data('matIconName') != null)
                    title = $(child).data('matIconName');
                else if (child['innerText'] != null)
                    title = child['innerText'];
            }
        }
        
        return title;
    }
    // Note:  no properties for buttons so far ...
    public hasProperties = (driver: IBrowserDriver, element: object): boolean => {
        return true;
    }
    public getProperties = (driver: IBrowserDriver, element: object): HtmlPropertyValue[] => {
        let properties: HtmlPropertyValue[] = [];

        return properties;
    }
}
