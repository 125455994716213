import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { plainToClass } from 'class-transformer';

import { environment } from '../../../environments/environment';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { CollectApiServiceBase } from './collect-api-base.service';
import { AsyncJob } from '../models/async-job.model';
import { Form } from '../models/form-builder/form.model';
import { CascadingDropdownConfigRow } from '../models/site-metadata/cascading-drop-down-config-row.model';

@Injectable()
export class ExportMetadataService extends CollectApiServiceBase<Form> {
    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'exportMetadata', Form)
    }

    // Service methods.
    public exportFormDefinition(formId: number): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/exportFormDefinition/${formId}`;

        return this.http.get<AsyncJob>(url)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    /*
    public exportCascadingDropDownConfig(formFieldId: number): Promise<CascadingDropdownConfigRow[]> {
        let url = `${this.url}/${this.endpoint}/exportCascadingDropDownConfig/${formFieldId}`;

        return this.http.get<CascadingDropdownConfigRow[]>(url)
            .toPromise()
            .then(configRows => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                //return this.formatAsyncJobResponse(res);
                return configRows;
            })
            .catch(this.handleError);
    }
    */

    // Helper methods.
    private formatAsyncJobResponse(data: AsyncJob): AsyncJob {
        let obj = plainToClass(AsyncJob, data);

        return (obj);
    }

    private packageDataFiles(dataFiles: File[]): FormData {
        const formData: FormData = new FormData();
        //for (let iFile: number = 0; iFile < formInstanceDataFiles.length; iFile++) {
        for (let iFile: number = 0; iFile < dataFiles.length; iFile++) {
            //let dataFile: File = formInstanceDataFiles[iFile];
            let dataFile: File = dataFiles[iFile];
            formData.append('file', dataFile, dataFile.name);
        }

        return (formData);
    }
}
