import { Type } from 'class-transformer';

export class HeaderValuePair {
    public Index: number; // This is a one-based index, not zero based.
    //public OneBasedIndex: number;
    public Header: string;
    public ValueText: string;

    @Type(() => HeaderValuePair)
    public ChildValuePairs: HeaderValuePair[];

    public constructor(index: number = 1, header: string = 'tbd', valueText: string = 'tbd') {
        this.Index = index;
        this.Header = header;
        this.ValueText = valueText;
    }
}
