import { Injectable } from '@angular/core';

import { IScriptableFunction } from '../interfaces/iscriptable-function';
import { SelectComponentScriptableFunction } from '../models/scriptable-functions/select-component.model';
import { CallMethodScriptableFunction } from '../models/scriptable-functions/call-method.model';
import { ClickButtonScriptableFunction } from '../models/scriptable-functions/click-button.model';
import { ClickLinkScriptableFunction } from '../models/scriptable-functions/click-link.model';
//import { InputValueScriptableFunction } from '../models/scriptable-functions/input-value.model';
import { SetInputValueScriptableFunction } from '../models/scriptable-functions/set-input-value.model';
      
@Injectable({
    providedIn: 'root'
})
export class ScriptableFunctionService {
    // Properties.
    private static scriptableFunctions: IScriptableFunction[] =
        [
            new SelectComponentScriptableFunction(),
            new ClickButtonScriptableFunction(),
            new ClickLinkScriptableFunction(),
            //new InputValueScriptableFunction(),
            new SetInputValueScriptableFunction(),
            new CallMethodScriptableFunction()
        ];

    // Constructor.
    public constructor() {
    }

    // Service methods.
    public get ScriptableFunctions(): IScriptableFunction[] {
        return ScriptableFunctionService.scriptableFunctions;
    }

    public getScriptableFunctionNamed(functionName: string): IScriptableFunction {
        let scriptableFunction: IScriptableFunction = ScriptableFunctionService.scriptableFunctions.find(f => f.callableName == functionName);
        return scriptableFunction;
    }

    // Helper methods.
}
