import { environment } from '../../environments/environment'

export class Logging {
    static log(val: any) {
        //if (environment.debug) // 03-06-2020 Note:  temporarily relaxing this condition (will re-enable).
        console.log(val);
    }

    // convenience method to log all arguments
    // Usage:
    // Logging.all("My Object", obj, `some id = ${something.id}`)
    static all(...entries: any[]) {
        entries.forEach(e => {
            console.log(e);
        });
    }
}
