<app-list-view class="publish-list-container"
               [list]="this.ListItems"
               [title]="'Document Publishing Settings'"
               [heading]="'Document Publishing'"
               [buttonConfig]="this.ButtonConfig"
               [columnsConfig]="['select', 'name', 'modifiedDate', 'modifiedBy']"
               (editClick)="doOnEdit($event)"
               (itemActionClick)="doOnItemAction($event)"
               (buttonClick)="doOnButtonClick($event)"
               (itemDeleted)="doOnItemDelete($event)"
               (itemContextMenu)="doOnItemContextMenu($event)">
</app-list-view>

<div style="visibility: hidden; position: fixed"
     [style.left]="this.ContextMenuPositionX"
     [style.top]="this.ContextMenuPositionY"
     [matMenuTriggerFor]="itemContextMenu">
</div>

<mat-menu #itemContextMenu="matMenu">
    <button mat-menu-item
            [disabled]="false"
            (click)="doOnEdit($event, 'context_menu')">
        Edit ...
    </button>
    <button mat-menu-item
            [disabled]="false"
            (click)="doOnItemDelete($event, 'context_menu')">
        Delete ...
    </button>
</mat-menu>
