import { Type } from 'class-transformer';

import { IViewModel } from '../../interfaces/view-model.interface';
import { FormField } from '../form-builder/form-field.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../form-builder/form-instance-element.model';
import { ICloneAndCopy } from '../../interfaces/clone-and-copy';
import { FormFieldAction } from './form-field-action.model';
import { FieldDefinition } from '../../models/form-builder/field-definition.model';
import { IFieldDefinitionLogic } from '../../interfaces/ifield-definition-logic.interface';
import { FieldDefinitionService } from '../../services/field-definition.service';
import { Form } from '../../models/form-builder/form.model';

// Define classes used to define static data.
class FieldDefinitionClassNameToValueTypeData {
    public fieldDefinitionClassName: string;
    public valueType: string;
    public comparisonValueType: string;
    public formInstanceElementPropertyName: string;
    public valueConversionFunction: (a) => any;

    public constructor(fieldDefinitionClassName: string, valueType: string, comparisonValueType: string, formInstanceElementPropertyName: string, valueConversionFunction: (a) => any) {
        this.fieldDefinitionClassName = fieldDefinitionClassName;
        this.valueType = valueType;
        this.comparisonValueType = comparisonValueType;
        this.formInstanceElementPropertyName = formInstanceElementPropertyName;
        this.valueConversionFunction = valueConversionFunction;
    }
}
interface FieldDefinitionClassNameToValueTypeMap {
    [fieldDefinitionClassName: string]: FieldDefinitionClassNameToValueTypeData;
}

class FieldExpressionComparator {
    public valueTypes: string[];
    public operatorSymbol: string;

    public comparator: (a, b) => boolean;

    public constructor(valueTypes: string[], operatorSymbol: string, comparator: (a, b) => boolean) {
        this.valueTypes = valueTypes;
        this.operatorSymbol = operatorSymbol;
        this.comparator = comparator;
    }
}
interface FieldExpressionComparatorMap {
    [valueType_operatorSymbol_key: string]: FieldExpressionComparator;
}

export class FormFieldExpression implements ICloneAndCopy {
    // Static data.
    private static staticDataInitialized: boolean = false;

    // Instance data.
    public id: number = 0;

    //public sourceFieldClientId: number = 0; // This can be ignored by the server.
    public sourceFieldId: number = 0; // Note:  this is a database Id or zero if no DB Id yet exists.
    public sourceFieldName: string = null; // Must be the internal form field name, not the display name.

    public sourceFieldChildFieldName: string = null; // Added 08-15-2024.

    public operatorSymbol: string; // e.g. =, >, >=, <, <=

    public operandValue: string; // e.g. 'abc'

    public expressionOrder: number = 0;

    //public actions: FormConditionalLogicAction[];
    @Type(() => FormFieldAction)
    public actions: FormFieldAction[];

    public getRuleSummaryText(form: Form, fieldDefinitionService: FieldDefinitionService): string {
        let sourceFieldName: string = this.sourceFieldName;
        let sourceField: FormField = null;
        if (form.formFields != null) {
            sourceField = form.formFields.find(ff => ff.name == this.sourceFieldName);
            if (sourceField != null)
                sourceFieldName = (sourceField.displayName != null) && (sourceField.displayName.trim() != '') ? sourceField.displayName : sourceField.name;
        }
        let ruleText: string = `[${sourceFieldName}] ${this.operatorSymbol} `;

        let strOperandValue: string = (this.operandValue ? this.operandValue.toString() : '');

        let bUseQuotationMarks: boolean = false;
        if (sourceField != null) {
            let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(sourceField.fieldDefinitionClassName);
            if ((fieldDefinition != null) && (fieldDefinition.customLogicHandler != null))
                bUseQuotationMarks = fieldDefinition.customLogicHandler.displayValueWithQuotationMarks();
        }        

        if (strOperandValue && (strOperandValue.trim() != '')) { // This test should never fail.
            // Add the operand value.
            let strTrimmedOperandValue: string = strOperandValue.trim();

            if (strTrimmedOperandValue.length <= 20) {
                ruleText += (bUseQuotationMarks ? `'${strTrimmedOperandValue}'` : strTrimmedOperandValue);
            } else {
                ruleText += (bUseQuotationMarks ? `'${strTrimmedOperandValue.substring(0, 16)}' ...` : `${strTrimmedOperandValue.substring(0, 16)} ...`);
            }

            // Add an action count.
            let iActionCount: number = this.actions != null ? this.actions.length : 0;

            if (iActionCount == 1) {
                ruleText += ' (1 action)';
            } else {
                ruleText += ` (${iActionCount} actions)`;
            }
        }

        return ruleText;
    }

    public getRuleConditionText(form: Form, fieldDefinitionService: FieldDefinitionService, addActionCount: boolean = true): string {
        //let ruleText: string = `[${sourceFieldName}] ${this.operatorSymbol} `;
        let ruleText: string = `${this.operatorSymbol} `;

        let strOperandValue: string = (this.operandValue ? this.operandValue.toString() : '');

        let sourceField: FormField = null;
        if (form.formFields != null) {
            sourceField = form.formFields.find(ff => ff.name == this.sourceFieldName);
        }

        let bUseQuotationMarks: boolean = false;
        if (sourceField != null) {
            let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(sourceField.fieldDefinitionClassName);
            if ((fieldDefinition != null) && (fieldDefinition.customLogicHandler != null))
                bUseQuotationMarks = fieldDefinition.customLogicHandler.displayValueWithQuotationMarks();
        }

        if (strOperandValue && (strOperandValue.trim() != '')) { // This test should never fail.
            // Add the operand value.
            let strTrimmedOperandValue: string = strOperandValue.trim();

            if (strTrimmedOperandValue.length <= 20) {
                ruleText += (bUseQuotationMarks ? `'${strTrimmedOperandValue}'` : strTrimmedOperandValue);
            } else {
                ruleText += (bUseQuotationMarks ? `'${strTrimmedOperandValue.substring(0, 16)}' ...` : `${strTrimmedOperandValue.substring(0, 16)} ...`);
            }

            if (addActionCount) {
                // Add an action count.
                let iActionCount: number = this.actions != null ? this.actions.length : 0;

                if (iActionCount == 1) {
                    ruleText += ' (1 action)';
                } else {
                    ruleText += ` (${iActionCount} actions)`;
                }
            }
        }

        return ruleText;
    }

    public get numberOfActions(): number {
        return this.actions != null ? this.actions.length : 0;
    }

    public applyTargetFieldDefaultAttributesToActions(targetFieldDefaultAttributes: string): void {
        if (this.actions != null) {
            for (let index: number = 0; index < this.actions.length; index++) {
                let action: FormFieldAction = this.actions[index];
                action.targetFormFieldDefaultAttributes = targetFieldDefaultAttributes;
            }
        }
    }

    // Implement ICloneAndCopy methods.
    // To do:  make methods clone() and copy() use a common, static method to perform the copy operations.
    public clone(): ICloneAndCopy {
        let clone: FormFieldExpression = new FormFieldExpression();

        FormFieldExpression.copyPropertiesFromTo(this, clone);

        return (clone);
    }

    public copy(objectToCopy: ICloneAndCopy): void {
        let exprToCopy: FormFieldExpression = <FormFieldExpression>objectToCopy;

        FormFieldExpression.copyPropertiesFromTo(exprToCopy, this);

        return;
    }

    private static copyPropertiesFromTo(fromExpr: FormFieldExpression, toExpr: FormFieldExpression): void {
        toExpr.id = fromExpr.id;

        //toExpr.sourceFieldClientId = fromExpr.sourceFieldClientId;
        toExpr.sourceFieldId = fromExpr.sourceFieldId;
        toExpr.sourceFieldName = fromExpr.sourceFieldName;

        toExpr.operatorSymbol = fromExpr.operatorSymbol;

        toExpr.operandValue = fromExpr.operandValue;

        toExpr.expressionOrder = fromExpr.expressionOrder;

        toExpr.actions = [];
        if (fromExpr.actions && (fromExpr.actions.length > 0)) {
            for (let iAction: number = 0; iAction < fromExpr.actions.length; iAction++) {
                let actionToCopy: FormFieldAction = fromExpr.actions[iAction];

                let actionClone: FormFieldAction = <FormFieldAction>actionToCopy.clone();
                toExpr.actions.push(actionClone);
            }
        }
    }

    // Constructor.
    public constructor(idParam: number = 0) {
        this.id = idParam;

        //FormFieldExpression.initComparatorDefs();

        return;
    }

    /*
    private static initComparatorDefs(): void {
        if (!FormFieldExpression.staticDataInitialized) {
            // Initialize the 'fieldDefinitionClassName' to 'valueType' map.
            FormFieldExpression.fieldDefinitionClassNamesToValueTypesMap = {};

            for (let index: number = 0; index < FormFieldExpression.fieldDefinitionClassNamesToValueTypes.length; index++) {
                let fieldDefToValueType: FieldDefinitionClassNameToValueTypeData = FormFieldExpression.fieldDefinitionClassNamesToValueTypes[index];

                //FormFieldExpression.fieldDefinitionClassNamesToValueTypesMap[fieldDefToValueType.fieldDefinitionClassName] = fieldDefToValueType.valueType;
                FormFieldExpression.fieldDefinitionClassNamesToValueTypesMap[fieldDefToValueType.fieldDefinitionClassName] = fieldDefToValueType;
            }

            // Initialize comparators.
            FormFieldExpression.mapOfFieldExpressionComparators = {};

            for (let index: number = 0; index < FormFieldExpression.fieldExpressionComparators.length; index++) {
                let comparatorDef: FieldExpressionComparator = FormFieldExpression.fieldExpressionComparators[index];

                for (let valueTypeIndex: number = 0; valueTypeIndex < comparatorDef.valueTypes.length; valueTypeIndex++) {
                    let valueType: string = comparatorDef.valueTypes[valueTypeIndex];

                    let valueType_operatorSymbol_key: string = FormFieldExpression.getValueTypeOperatorKeyString(valueType, comparatorDef.operatorSymbol); //`${valueType}_${comparatorDef.operatorSymbol}`;
                    FormFieldExpression.mapOfFieldExpressionComparators[valueType_operatorSymbol_key] = comparatorDef;
                }
            }

            FormFieldExpression.staticDataInitialized = true;
        }
    }
    */

    private static getValueTypeOperatorKeyString(valueType: string, operatorSymbol: string): string {
        return `${valueType}_${operatorSymbol}`
    }

    // Rule test method.

    // Note:  rewrite the logic in the following method to use interfaces.
    //
    //        The key to the correct interface implementation could be
    //        a form instance element's value type.
    //
    //        Also need to abstract 'this.operatorSymbol' into an
    //        expression evaluation interfadce that can be called without
    //        if-then/switch/conditional logic.
    //
    //        Question:  could we get away with one interface implementation
    //        per 'operatorSymbol' or do we need one implementation per
    //        'operatorSymbol' per 'valueType' value?  The latter?
    public valueTriggersExpression(formField: FormField, formInstanceElement: FormInstanceElement, fieldDefinitionService: FieldDefinitionService): boolean {
        let triggersExprs: boolean = false;

        let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(formField.fieldDefinitionClassName);
        let fieldLogic: IFieldDefinitionLogic = (fieldDefinition != null ? fieldDefinition.customLogicHandler : null);
        if (fieldLogic == null) {
            let errorMsg = `FormFieldExpression.valueTriggersExpression():  cannot find an instance of IFieldDefinitionLogic for field definition class '${formField.fieldDefinitionClassName}'.`;
            throw errorMsg;
        }

        /*
        let valueTypeData: FieldDefinitionClassNameToValueTypeData = FormFieldExpression.fieldDefinitionClassNamesToValueTypesMap[formField.fieldDefinitionClassName];
        if (valueTypeData == null) {
            let errorMsg = `FormFieldExpression.valueTriggersExpression():  cannot find a 'valueType' value for field definition class name '${formField.fieldDefinitionClassName}'.`;
            throw errorMsg;
        }
        */

        //let propertyName: string = valueTypeData.formInstanceElementPropertyName;
        let propertyName: string = fieldDefinition.formInstanceElementPropertyName;
        let propertyValue: any = formInstanceElement[propertyName];

        triggersExprs = fieldLogic.compare(propertyValue, this.operatorSymbol, this.operandValue);

        return triggersExprs;
    }
    /*
    public originalNotUsed_valueTriggersExpression(formField: FormField, formInstanceElement: FormInstanceElement, fieldDefinitionService: FieldDefinitionService): boolean {
        //let valueType: string = FormFieldExpression.fieldDefinitionClassNamesToValueTypesMap[formField.fieldDefinitionClassName];
        let valueTypeData: FieldDefinitionClassNameToValueTypeData = FormFieldExpression.fieldDefinitionClassNamesToValueTypesMap[formField.fieldDefinitionClassName];
        if (valueTypeData == null) {
            let errorMsg = `FormFieldExpression.valueTriggersExpression():  cannot find a 'valueType' value for field definition class name '${formField.fieldDefinitionClassName}'.`;
            throw errorMsg;
        }
        //let valueType: string = valueTypeData.valueType;
        let valueType: string = valueTypeData.comparisonValueType;
        let propertyName: string = valueTypeData.formInstanceElementPropertyName;

        let valueType_operatorSymbol_key: string = FormFieldExpression.getValueTypeOperatorKeyString(valueType, this.operatorSymbol); //`${formInstanceElement.valueType}_${this.operatorSymbol}`;
        let comparator: FieldExpressionComparator = FormFieldExpression.mapOfFieldExpressionComparators[valueType_operatorSymbol_key];
        if (comparator == null) {
            let errorMsg = `FormFieldExpression.valueTriggersExpression():  cannot find a comparator for 'valueType'/'operator' key '${valueType_operatorSymbol_key}'.`;
            throw errorMsg;
        }

        let propertyValue: any = formInstanceElement[propertyName];
        let textPropertyValue: string = (propertyValue != null ? propertyValue.toString() : '');
        let convertedPropertyValue: any = valueTypeData.valueConversionFunction(textPropertyValue);
        let convertedOperandValue: any = valueTypeData.valueConversionFunction(this.operandValue);
        let triggersExpr: boolean = comparator.comparator(convertedPropertyValue, convertedOperandValue);

        return triggersExpr;
    }
    */

    // Helper/convenience methods.
    public static addRulesForSourceFormField(sourceFormFieldName: string,
        arrExistingRules: FormFieldExpression[],
        arrNewlyDefinedRules: FormFieldExpression[]): FormFieldExpression[] {
        let arrResults: FormFieldExpression[] = null;

        // Remove any existing rules for this form field.
        if (arrExistingRules && (arrExistingRules.length > 0)) {
            arrResults = arrExistingRules.filter(r => r.sourceFieldName !== sourceFormFieldName);
        } else {
            arrResults = [];
        }

        if (arrNewlyDefinedRules && (arrNewlyDefinedRules.length > 0)) {
            for (let iNewRule: number = 0; iNewRule < arrNewlyDefinedRules.length; iNewRule++) {
                let newRule: FormFieldExpression = arrNewlyDefinedRules[iNewRule];

                arrResults.push(newRule);
            }
        }

        return (arrResults);
    }

    public static getRulesForSourceFormField(sourceFormFieldName: string,
        arrExistingRules: FormFieldExpression[]): FormFieldExpression[] {
        let arrResults: FormFieldExpression[] = null;

        // Find any existing rules for
        // the specified source form field.
        if (arrExistingRules && (arrExistingRules.length > 0)) {
            //arrResults = arrExistingRules.filter(r => r.sourceFieldClientId === sourceFormFieldClientId);
            arrResults = arrExistingRules.filter(r => r.sourceFieldName === sourceFormFieldName);
        } else {
            arrResults = [];
        }

        return (arrResults);
    }
}
