<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<div class="outer-div">
    <div *ngIf="this.Mode === 'design'" class="inner-div">
        <div class="design-mode-field-label">{{this.DisplayName}}</div>
        <ng-container *ngIf="(this.CheckboxData === undefined) || (this.CheckboxData === null) || (this.CheckboxData.length === 0)">
            <mat-checkbox disabled>No checkboxes defined</mat-checkbox>
        </ng-container>

        <ng-container *ngIf="(this.CheckboxData !== undefined) && (this.CheckboxData !== null) && (this.CheckboxData.length > 0)">
            <section>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                <ng-container *ngFor="let checkboxData of this.CheckboxData; let i=index">

                    <mat-checkbox [(ngModel)]="checkboxData.value"
                                  (ngModelChange)="this.handleCheckboxModelChange(i)"
                                  disabled
                                  class="set-left-align">{{checkboxData.title}}</mat-checkbox>

                    <ng-container *ngIf="(i < this.CheckboxValueCount - 1) && (this.FormField.displayFormat === 'Vertical')"><br /></ng-container>
                    <ng-container *ngIf="(i < this.CheckboxValueCount - 1) && (this.FormField.displayFormat !== 'Vertical')">&nbsp;&nbsp;&nbsp;</ng-container>

                </ng-container>
            </section>
        </ng-container>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>

    <div *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')">
        <div class="field-label" *ngIf="!FieldIsInGrid">{{this.DisplayName}}</div>
        <ng-container *ngIf="(this.CheckboxData === undefined) || (this.CheckboxData === null) || (this.CheckboxData.length === 0)">
            <mat-checkbox disabled>No checkboxes defined</mat-checkbox>
        </ng-container>

        <ng-container *ngIf="(this.CheckboxData !== undefined) && (this.CheckboxData !== null) && (this.CheckboxData.length > 0)">
            <section>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                <ng-container *ngFor="let checkboxData of this.CheckboxData; let i=index">
                    <mat-checkbox [formControl]="this.getCheckboxFormControl(i)"
                                  (blur)="this.handleOnBlur()"
                                  class="set-left-align"
                                  [ngClass]="this.ReadOnly ? 'pointer-events: none; opacity: 0.5; mat-checkbox-disabled: true;' : ''">
                        {{checkboxData.title}}
                    </mat-checkbox>

                    <ng-container *ngIf="(i < this.CheckboxValueCount - 1) && (this.FormField.displayFormat === 'Vertical')"><br /></ng-container>
                    <ng-container *ngIf="(i < this.CheckboxValueCount - 1) && (this.FormField.displayFormat !== 'Vertical')">&nbsp;&nbsp;&nbsp;</ng-container>

                </ng-container>
            </section>
        </ng-container>
    </div>
    <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
</div>
