import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { State } from "@progress/kendo-data-query";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { CustomDateFormatDirective } from "../directives/custom-date-format.directive";
import { CrossSiteUserFavoriteFormsResults } from '../models/site-content/cross-site-user-favorite-form.model';
import { ProgressIndicatorService } from "./progress-indicator.service";

@Injectable({
    providedIn: 'root'
})
export class CrossSiteUserFavoriteFormsService extends BehaviorSubject<CrossSiteUserFavoriteFormsResults> {
    private url = environment.apiUrl;
    private endpoint: string = 'api/formInstance';
    private data: object[] = [];
    private loading = false;
    private gridData: CrossSiteUserFavoriteFormsResults;

    public constructor(private http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(new CrossSiteUserFavoriteFormsResults());
    }


    public read(gridState: State): void {
        this.fetch(gridState, 'getCrossSiteUserFavorites')
            .pipe(
                tap((data) => {
                    this.gridData = data;
                    this.loading = false;
                })
            )
            .subscribe((data) => {
                super.next(data);
            });
    }

    private fetch(gridState: State, action: string): Observable<CrossSiteUserFavoriteFormsResults> {

        let baseUrl = this.url.endsWith('/') ? this.url : `${this.url}/`;
        let url = `${baseUrl}${this.endpoint}/${action}`;
        return <Observable<CrossSiteUserFavoriteFormsResults>><unknown>this.http.post<CrossSiteUserFavoriteFormsResults>(url, gridState)
            .pipe(
                map((dataFromServer) => {
                    dataFromServer.resultsAsPlainObjects = [];
                    for (let dc of dataFromServer.results) {
                        var plainObj = {
                            formId: dc.formId,
                            formName: dc.formName,
                            siteId: dc.siteId,
                            folderId: dc.folderId,
                            siteName: dc.siteName,
                            modifiedDate: CustomDateFormatDirective.applyDateFormattingUsing("yyyy-MM-dd HH:mm:ss", dc.modifiedDate.toString()),
                            modifiedBy: dc.modifiedBy,
                            //modifiedByUserName: dc.modifiedByUserName,
                            workflowState: dc.workflowState,
                            isFavorite: true
                        };
                        dataFromServer.resultsAsPlainObjects.push(plainObj);
                    }
                    return dataFromServer;
                })
            );
    }
}
