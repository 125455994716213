import { BrowserStorageService } from '../../services/browserstorage.service';

export enum ListViewEnum {
    SITE_LIST = 'site-list',
    STRUCTURE = 'structure',

    GRID_VIEW = 'grid',
    TILE_VIEW = 'tile',

    VIEW_SORT_STATE = 'sort-state',

    FORM_INSTANCE_HISTORY = 'form-instance-history'
}

export class ListViewHelper {
    public static readonly PAGE_SIZE: number = 50;
    public static readonly APP_WIDE_INFINITE_SCROLL_ENABLED: boolean = true;
    public static readonly DEFAULT_SORT: any = { active: 'name', direction: 'asc' };
    public static readonly FOLDER_DEFAULT_SORT: any = { active: 'position', direction: 'asc' };
    public static readonly NO_SORT = 'NONE';

    public static getInitialPageSize(): any {
        //const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        //const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        //return [width, height];
        //return height * 10;
    }

    public static getSortObjFor(listType): any {
        let view = localStorage.getItem(`${listType}_listView`);

        let sortConfig = JSON.parse(localStorage.getItem(ListViewHelper.sortKey(listType, view)));
        sortConfig ||= this.FOLDER_DEFAULT_SORT;

        if (!sortConfig["direction"]) sortConfig["direction"] = "asc";

        return sortConfig;
    }
        
    // listType is "structure" or "site-list" etc...
    // view is "grid" or "tile"
    public static sortKey(listType: string, view: string): string {
        return `${listType}_${view}_${ListViewEnum.VIEW_SORT_STATE}`;
    }
}
