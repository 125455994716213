import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { environment } from '../../../environments/environment';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { CollectApiServiceBase } from './collect-api-base.service';
import { FormInstance } from '../models/site-content/form-instance.model';
import { AsyncJob } from '../models/async-job.model';
import { XmlFileFormatEnum } from '../enums/xml-file-format.enum';
import { ExcelSiteFormDataExportRequest } from '../models/export-import/excelSiteFormDataExportRequest';
import { ExcelFolderFormDataExportRequest } from '../models/export-import/excelFolderFormDataExportRequest';

@Injectable()
export class GeocodeService {
    private serviceUrl = '';

    public constructor(private http: HttpClient) {
        this.serviceUrl = environment.apiUrl + "/api/geocode/";
    }

    public Geocode2010Address(address): Promise<string> {
        let url = this.serviceUrl + 'Geocode2010Address';
        return this.http.post(url, {}, { params: { address: address } })
            .toPromise()
            .then((response) => {
                return response;
            })
            .catch(this.handleError);
    }

    public Geocode2020Address(address): Promise<string> {
        let url = this.serviceUrl + 'Geocode2020Address';
        return this.http.post(url, {}, { params: { address: address } })
            .toPromise()
            .then((response) => {
                return response;
            })
            .catch(this.handleError);
    }

    public GeocodeGrid(formInstanceId, gridId, settings = null): Promise<string> {
        let url = this.serviceUrl + 'GeocodeGrid';

        return this.http.post(url, {}, { params: { formInstanceId: formInstanceId, gridId: gridId, options: 'vintage=Census2010_Census2020' } })
            .toPromise()
            .then((response) => {
                return response;
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
