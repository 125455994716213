import { Type } from 'class-transformer';

import { IViewModel } from '../interfaces/view-model.interface';
import { ShareMember } from './share-member.model';
import { ICloneAndCopy } from '../interfaces/clone-and-copy';

export class Share implements IViewModel, ICloneAndCopy {
    // Properties.
    public id: number;

    public name: string;
    public description: string;

    // Collection properties.
    @Type(() => ShareMember)
    public shareMembers: ShareMember[] = [];

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let clone: Share = new Share();

        clone.id = this.id;

        clone.name = this.name;
        clone.description = this.description;

        clone.shareMembers = [];
        if (this.shareMembers && (this.shareMembers.length > 0)) {
            for (let iMember: number = 0; iMember < this.shareMembers.length; iMember++) {
                let memberToCopy: ShareMember = this.shareMembers[iMember];

                let memberClone: ShareMember = <ShareMember>memberToCopy.clone();
                clone.shareMembers.push(memberClone);
            }
        }

        return (clone);
    }

    public copy(objectToCopy: ICloneAndCopy): void {
        let shareToCopy: Share = <Share>objectToCopy;

        this.id = shareToCopy.id;

        this.name = shareToCopy.name;
        this.description = shareToCopy.description;

        this.shareMembers = [];
        if (shareToCopy.shareMembers && (shareToCopy.shareMembers.length > 0)) {
            for (let iMember: number = 0; iMember < shareToCopy.shareMembers.length; iMember++) {
                let memberToCopy: ShareMember = shareToCopy.shareMembers[iMember];

                let memberClone: ShareMember = <ShareMember>memberToCopy.clone();
                this.shareMembers.push(memberClone);
            }
        }

        return;
    }
}
