import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { TextFieldDefinitionLogicBase } from './text-logic-base';
import { FormFieldBaseComponent } from '../../../components/form-fields/form-field-base/form-field-base.component';
import { RichTextFormFieldComponent } from '../../../components/form-fields/rich-text-form-field/rich-text-form-field.component';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';

export class RichTextFieldDefLogic extends TextFieldDefinitionLogicBase {
    // Constructor.
    public constructor() {
        super();
    };

    // pasteDataForInto():  only allow the pasting of values that are valid for a given form field.
    public pasteDataForInto(formField: FormField, formInstanceElement: FormInstanceElement, pasteValue: string): void {
        formInstanceElement.textValue = pasteValue;

        // TO DO:  ADD VALIDATION TO THIS METHOD.
    }

    public allowsHtmlInDisplayValue(): boolean {
        return true;
    }

    public setFieldSpecificValuesForDynamicallyCreatedField(formFieldComponent: FormFieldBaseComponent, formField: FormField, parentComponentPrefix: string): void {
        let mode: string = formFieldComponent.Mode;

        let richTextComponent: RichTextFormFieldComponent = formFieldComponent as RichTextFormFieldComponent;
        richTextComponent.showHistoryLink = false;
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        if (!formInstanceElementParam.UserUpdatedData) {
            if (formInstanceElementParam.textValue == null) formInstanceElementParam.TextValue = '';
        }

        return formInstanceElementParam.textValue;
    }
    public getPropertiesRequiredByGrid(formField: FormField): any {
        let hshOnInitProperties = {
        };
        hshOnInitProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT] = 150;
        hshOnInitProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT] = 'px';

        return (hshOnInitProperties);
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }
}
