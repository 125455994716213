 import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from "class-transformer";

import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { ProgressIndicatorService, ProgressConfig } from './progress-indicator.service';
import { VnextReleaseFeatures } from '../models/vnext-release-features.model';

@Injectable({
    providedIn: 'root'
})
export class VnextReleaseFeaturesService extends CollectApiServiceBase<VnextReleaseFeatures>{
    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'vnextReleaseFeatures', VnextReleaseFeatures)
    }

    public formatResponse(data: VnextReleaseFeatures): VnextReleaseFeatures {
        var obj = plainToClass(VnextReleaseFeatures, data);

        return obj;
    }

    // Note:  define a method to get the current release features record.
    public getCurrentReleaseFeatures(): Promise<VnextReleaseFeatures> {
        let url = `${this.url}/${this.endpoint}/getCurrentReleaseFeatures`;

        //this.updateProgress(50, 75, `Getting release features ...`);

        return this.http.get<VnextReleaseFeatures>(url)
            .toPromise()
            .then(response => {
                let result: VnextReleaseFeatures = plainToClass(VnextReleaseFeatures, response);

                //this.updateProgress(100, 100, `Completed`);

                return (result);
            })
            .catch(this.handleError);
    }

    public saveCurrentReleaseFeatures(releaseFeatures: VnextReleaseFeatures): Promise<VnextReleaseFeatures> {
        let result = super.create(releaseFeatures);
        return result;
    }
}
