// Note:  the following enum is used by form fields
//        when they emit their 'onInit' output.
export enum FormFieldOnInitPropertyEnum {
    COMPONENT = 'component',
    DISPLAY_FORMAT_VALUES = 'displayFormatValues',
    FORM_FIELD = 'formField',
    PROPERTIES = 'properties',
    PROPERTY_UPDATE_REQUIRED = 'propertyUpdateRequired',
    REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT = 'requiredPreviewInstanceModesHeight',
    REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT = 'requiredPreviewInstanceModesHeightUnit',
    ROWS_OF_TEXT_TO_DISPLAY_IN_UNSELECTED_GRID_ROW = 'rowsOfTextToDisplayInUnselectedGridRow'
}
