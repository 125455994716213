<ng-container *ngIf="false && this.ElementSelected">
    <h6>{{this.SelectedElementName}}</h6>

    <!-- Note:  disabling the rest of the display elements for the time being. -->
    <!-- Display any properties. -->
    <!--
    <ng-container *ngIf="this.CachedSelectedElementHasProperties">
        <table>
            <tr>
                <td class="property-name-header-td">Property Name</td>
                <td class="spacer-td"></td>
                <td class="property-value-header-td">Property Value</td>
            </tr>
            <ng-container *ngFor="let property of this.SelectedElementProperties">
                <tr>
                    <td>{{property.propertyName}}:</td>
                    <td class="spacer-td"></td>
                    <td>{{property.propertyValue}}</td>
                </tr>
            </ng-container>
        </table>
    </ng-container>
    -->
    <!-- Display any actions. -->
    <!--
    <ng-container *ngIf="this.CachedSelectedElementHasActions">
        <ng-container *ngFor="let action of this.SelectedElementActions">
            <div class="action-div">
                <span class="action-button-span">Action</span>:
                <button mat-raised-button
                        color="primary"
                        class="action-button"
                        (click)="this.onSelectedElementActionClicked()">
                    {{action}}
                </button>
            </div>
        </ng-container>
    </ng-container>
        -->
    <!-- Display any input fields. -->
    <!--
    <ng-container *ngIf="this.CachedSelectedElementHasInputs">
        <ng-container>
            <div class="input-div">
                <span class="input-span">Value</span>:
                <p class="selected-input-element">{{this.SelectedElementInputValue}}</p>

                <button mat-raised-button
                        color="primary"
                        class="action-button"
                        (click)="this.editSelectedInputValue()">
                    Edit Value ...
                </button>

            </div>
        </ng-container>
    </ng-container>
        -->
</ng-container>
