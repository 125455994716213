import { plainToClass } from "class-transformer";

import { FormInstanceElement } from '../form-builder/form-instance-element.model';

// Define a contacts data type.
export interface IContactInfo {
    userId: string;
    //firstName: string;
    //lastName: string;
    fullName: string;
    email: string;
    phone: string;

    editingEnabled: boolean;
}

export class ContactInfo implements IContactInfo {
    // Properties.
    public userId: string;
    public fullName: string;
    public email: string;
    public phone: string;
    public agencyShortName: string;

    public editingEnabled: boolean = false;

    // Constructor...
    public constructor(userId: string, fullName: string, email: string, phone: string, agencyShortName: string = null) {
        this.userId = userId;
        this.fullName = fullName;
        this.email = email;
        this.phone = phone;
        this.agencyShortName = agencyShortName;
    }
}

// Define a contacts data structure.
export class ContactsFormFieldData {
    // Property.
    public contacts: IContactInfo[] = [];

    // Constructor.
    public constructor() { }

    // Method(s).
    public containsUser(userMaxId: string): boolean {
        let containsUser: boolean = false;

        if (this.contacts && (this.contacts.length > 0)) {
            let contact: IContactInfo = this.contacts.find(c => c.userId == userMaxId);
            containsUser = (contact != null);
        }

        return containsUser;
    }

    // Static method.
    public static deserializeData(formInstanceElement: FormInstanceElement): ContactsFormFieldData {
        let data: ContactsFormFieldData = null;

        if (formInstanceElement.textValue && (formInstanceElement.textValue.trim() != '')) {
            let hshData: any = JSON.parse(formInstanceElement.textValue);
            data = plainToClass(ContactsFormFieldData, hshData);
        } else
            data = new ContactsFormFieldData();

        return data;
    }
}
