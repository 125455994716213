import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { plainToClass } from 'class-transformer';

import { environment } from '../../../environments/environment';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { CollectApiServiceBase } from './collect-api-base.service';
import { FormInstance } from '../models/site-content/form-instance.model';
import { AsyncJob } from '../models/async-job.model';
import { XmlFileFormatEnum } from '../enums/xml-file-format.enum';
import { GenericExportDataAsExcelSpreadsheet } from '../models/export-import/generic-export-data-as-excel-spreadsheet';

@Injectable()
export class ExportDataService extends CollectApiServiceBase<FormInstance> {
    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'exportData', FormInstance)
    }

    // Service methods.
    public exportFormInstances(formId: number, xmlFileFormat: XmlFileFormatEnum): Promise<AsyncJob> {
        let url = (xmlFileFormat == XmlFileFormatEnum.VERBOSE_FORMAT ?
            `${this.url}/${this.endpoint}/exportFormInstances/${formId}` :
            `${this.url}/${this.endpoint}/exportSimplifiedFormInstances/${formId}`);

        return this.http.get<AsyncJob>(url)
            .toPromise()
            .then(res => {
                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    public exportFormInstance(formInstanceId: number): Promise<FormInstance> {
        let url = `${this.url}/${this.endpoint}/exportFormInstance/${formInstanceId}`;

        return this.http.get<FormInstance>(url)
            .toPromise()
            .then(response => {
                return response;
            })
            .catch(this.handleError);
    }
    
    public getResultFileDownloadUrl(asyncJob: AsyncJob, expectedDownloadFileName: string): string {
        let url = `${this.url}/${this.endpoint}/JobKey/${asyncJob.jobKey}/FileDownloadName/${expectedDownloadFileName}`;

        return (url);
    }

    public exportGridDataAsExcelSpreadsheet(formInstanceElementId: number): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/ExportGridDataAsExcelSpreadsheet/${formInstanceElementId}`

        return this.http.get<AsyncJob>(url)
            .toPromise()
            .then(res => {
                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    public genericExportDataAsExcelSpreadsheet(exportInstructions: GenericExportDataAsExcelSpreadsheet): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/genericExportDataAsExcelSpreadsheet`;

        return this.http.post<AsyncJob>(url, exportInstructions)
            .toPromise()
            .then(response => {
                let asyncJob: AsyncJob = response; // Not sure why this has to be done, but perhaps it's because the server is return IActionResult.
                let result = plainToClass(AsyncJob, asyncJob);
                return result;
            })
            .catch(this.handleError);
    }

    // Helper methods.
    private formatAsyncJobResponse(data: AsyncJob): AsyncJob {
        var obj = plainToClass(AsyncJob, data);

        return (obj);
    }   
}
