import { IListItem } from '../../../shared/interfaces/ilist-item.interface';
import { IconDisplayStyle } from '../../enums/icon-display-style.enum';
import { IconFormatEnum } from '../../enums/icon-format.enum';

export interface IButtonConfigClickHandler {
    handlePreButtonClick(): void;

    // Note:  parameter 'contextItem' in the following call can be null.
    handleButtonClick(buttonConfig: IButtonConfig, eventData: any, contextItem: any): void;
}

export interface IButtonConfig {
    // Properties.
    label: string;
    value: string;

    // Methods for conditional logic.
    appliesTo(item: IListItem): boolean; // Indicate whether this button applies to the specified item.
    isDisabled(item: IListItem): boolean; // Indicate with this button should be greyed out/disabled.

    // Methods for icon, icon format.
    iconFor(item: IListItem): string; // Returns the icon name for the specified item.
    iconFormatFor(item: IListItem): string; // Returns the icon format (MatIcon vs. SVG) for the specified item.

    buttonClickHandler: IButtonConfigClickHandler;
}

export class SvgViewBox {
    public x: number;
    public y: number;
    public width: number;
    public height: number;

    public constructor(x: number, y: number, width: number, height: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }
}

export class ButtonConfig implements IButtonConfig {
    // Properties.
    public label: string;
    public value: string;
    public icon: string;
    public iconFormat: string;
    public disabled: boolean = false; //TEAMS-424: KLW - Set the default value for disabled to false, making this an optional parameter
    public iconDisplayStyle: string = null;
    //public viewBox: SvgViewBox = null;
    public inlineStyles: string = null;

    //TEAMS-653: KLW - New property to set/add a class of a ButtonConfig
    public cssClass: string = null;

    // Implement additional IButtonConfig methods.
    public appliesTo(ignoredItem: any): boolean {
        return (true);
    }

    public isDisabled(ignoredItem: any): boolean {
        return this.disabled; //TEAMS-424: KLW - Return the variable representing disabled instead of a hard coded value
    }

    public iconFor(ignoredItem: any): string {
        return (this.icon);
    }
    public iconFormatFor(ignoredItem: any): string {
        return (this.iconFormat);
    }

    public buttonClickHandler: IButtonConfigClickHandler;

    // Constructor.
    //TEAMS-424: KLW - Change constructor to have an optional value for disabled
    public constructor(label: string,
        value: string,
        icon: string = null,
        iconFormat: string = IconFormatEnum.MAT_ICON,
        disabled: boolean = false,
        iconDisplayStyle: string = IconDisplayStyle.Default,
        cssClass: string = '') { 
        this.label = label;
        this.value = value;
        this.icon = icon;
        this.iconFormat = iconFormat;
        this.disabled = disabled;
        this.iconDisplayStyle = iconDisplayStyle;
        this.cssClass = cssClass;
    }

    public setInlineStyles(inlineStyles: string): ButtonConfig {
        this.inlineStyles = inlineStyles;
        return this;
    }
    public setButtonClickHandler(buttonClickHandler: IButtonConfigClickHandler): ButtonConfig {
        this.buttonClickHandler = buttonClickHandler;
        return this;
    }
}
