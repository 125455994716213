
<mat-drawer-container class="notification-list">
    <mat-drawer-content>
        <app-list-view [list]="Notifications"
                       [buttonConfig]="ButtonConfig"
                       [columnsConfig]="['select', 'name:Message', 'createdDate:Notification Date']"
                       [defaultSortColumn]="'createdDate'"
                       [viewOverride]="'grid'"
                       [renderNameAsLink]="false"
                       (itemActionClick)="onItemAction($event)"
                       (itemDeleted)="onListItemDeleted($event)">
        </app-list-view>
    </mat-drawer-content>
    <mat-drawer [opened]="ShowDetails"
                mode="over"
                position="end">
        <mat-toolbar>
            <span>Details</span>
            <span class="info-drawer-toolbar-spacer"></span>
            <button mat-icon-button (click)="toggleDetails()" tabindex="-1"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
        <div class="details">
            <span *ngFor="let part of SelectedMessageParts">
                {{part}}
            </span>
        </div>
    </mat-drawer>
</mat-drawer-container>
