import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { BinaryComparisonTypesFieldDefinitionLogicBase } from './binary-comparison-base-logic';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { KendoGridOperatorName } from '../../../enums/kendo-grid/kendo-grid-operator-name.enum';
import { FormInstanceGridRow } from '../../../models/site-content/form-instance-grid-row.model';

export class TextFieldDefinitionLogicBase extends BinaryComparisonTypesFieldDefinitionLogicBase implements IFieldDefinitionLogic {
    // Constructor.
    public constructor() {
        super();
    }

    // pasteDataForInto():  only allow the pasting of values that are valid for a given form field.
    public pasteDataForInto(formField: FormField, formInstanceElement: FormInstanceElement, pasteValue: string): void {
        formInstanceElement.textValue = pasteValue;

        // TO DO:  ADD VALIDATION TO THIS METHOD.
    }

    // Sorting.
    public compareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        let compareResult: number = TextFieldDefinitionLogicBase.staticCompareAscendingFor(formField, value1, value2);
        return compareResult;
    }
    public static staticCompareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        let compareResult: number = 0;

        if ((value1.textValue != null) && (value2.textValue != null)) {
            if (value1.textValue == value2.textValue)
                compareResult = 0;
            else if (value1.textValue > value2.textValue)
                compareResult = 1;
            else
                compareResult = -1;
        } else if (value1.textValue != null) {
            compareResult = 1;
        } else if (value2.textValue != null) {
            compareResult = -1;
        }

        return compareResult;
    }
    public static staticCompareAscending(value1: string, value2: string): number {
        let compareResult: number = 0;

        if ((value1 != null) && (value2 != null)) {
            if (value1 == value2)
                compareResult = 0;
            else if (value1 > value2)
                compareResult = 1;
            else
                compareResult = -1;
        } else if (value1 != null) {
            compareResult = 1;
        } else if (value2 != null) {
            compareResult = -1;
        }

        return compareResult;
    }

    public compareValuesAscending(value1: any, value2: any): number {
        let compareResult: number = 0;

        if ((value1 != null) && (value2 != null)) {
            let formattedValue1: any = this.formatValueForCompare(value1);
            let formattedValue2: any = this.formatValueForCompare(value2);

            if (formattedValue1 > formattedValue2)
                compareResult = 1;
            else if (formattedValue1 < formattedValue2)
                compareResult = -1;
        } else if (value1 != null) {
            compareResult = 1;
        } else if (value2 != null) {
            compareResult = -1;
        }

        return compareResult;
    }
    public compareValuesDescending(value1: any, value2: any): number {
        let compareResult: number = - this.compareValuesAscending(value1, value2);
        return compareResult;
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        //if ((!formInstanceElementParam.transientValueSetFlag) ||
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        }

        return (formInstanceElementParam.textValue);
    }

    public filterType(): string {
        return 'text';
    }

    public isFilterable(): boolean {
        return true;
    }
    public filterOperator(): string {
        return 'contains';
    }

    // Protected methods.
    protected formatValueForCompare(value: any): any {
        return value;
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }

    public displayValueWithQuotationMarks(): boolean {
        return true;
    }

    public getDefaultKendoGridOperatorName(): KendoGridOperatorName {
        return KendoGridOperatorName.Contains;
    }

    public unpackTemplateInstancesRow(formField: FormField, colValue: FormInstanceElement, row: FormInstanceGridRow): any {
        return colValue != null ? colValue.textValue : '';
    }
}
