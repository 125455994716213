import { TextFieldDefinitionLogicBase } from './text-logic-base';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';
import { FormField } from '../form-field.model';

export class ParagraphFieldDefLogic extends TextFieldDefinitionLogicBase {
    // Constructor.
    public constructor() {
        super();
    };

    public getPropertiesRequiredByGrid(formField: FormField): any {
        let hshProperties = {
        };
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT] = 150;
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT] = 'px';

        return hshProperties;
    }

    public getDefaultMaxLengthWhenInGrid(): number {
        return 250;
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }
}
