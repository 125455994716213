import { FormField } from '../../../../../models/form-builder/form-field.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../../../interfaces/iform-field-constraint.interface';
import { FormFieldDateRangeConstraint, FormFieldDateRangeConstraintWithMetrics } from '../../../../../models/form-builder/field-constraints/field-date-range-constraint.model';
import { CachedFormFieldService } from '../../../../../services/form-field-with-cache.service';
import { IFormFieldConstraintLiaison } from './ifield-constraint-liason.interface';
import { DateRangeDataForJsonSerialization } from '../../../../../models/form-builder/field-constraints/field-date-range-constraint.model';
//import { ISiteFormFieldConstraints } from './isite-form-field-constraints.interface';
import { FieldConstraintLiaisonBase } from './field-constraint-liaison-base';
import { SiteFormFieldConstraintProperties } from '../../../../../models/form-builder/field-constraints/site-form-field-constraint-props.model';
import { DateFieldType } from '../../../../../models/form-builder/form-field-types';
import { FormFieldConstraintTypeEnum, FormFieldConstraintTypeForUsersEnum } from '../../../../../enums/form-field-constraint-type.enum';

export class FormFieldDateRangeConstraintLiaison extends FieldConstraintLiaisonBase implements IFormFieldConstraintLiaison {
    // Constructor.
    public constructor() {
        super();
    }

    // Implement IFormFieldConstraintLiaison.
    // Getter/setter-like methods.
    public constraintTypeForEndUser(): string {  // A user-friendly constraint type name.
        return FormFieldConstraintTypeForUsersEnum.DateRange;
    }

    public managesConstraintsOfType(): string {
        return FormFieldConstraintTypeEnum.DateRange;
    }

    public getPrimaryPropertyName(): string {
        return 'minDate';
    }

    public getConstraintId(formField: FormField): number {
        return formField.dateRangeConstraintId;
    }
    public setConstraintId(formField: FormField, value: number): void {
        formField.dateRangeConstraintId = value;
    }

    public getConstraintName(formField: FormField): string {
        return formField.dateRangeConstraintName;
    }
    public setConstraintName(formField: FormField, value: string): void {
        formField.dateRangeConstraintName = value;
    }

    public getConstraintValue(formField: FormField): string {
        //return formField.dateRangeConstraintValue;
        let serializationObject = new DateRangeDataForJsonSerialization(formField.dateRangeConstraintMinDate, formField.dateRangeConstraintMaxDate);
        let json: string = serializationObject.toJson();
        return json;
    }
    public setConstraintValue(formField: FormField, value: string): void {
        if ((value == null) || (value.trim() == '')) {
            formField.minDate = null;
            formField.maxDate = null;
            formField.dateRangeConstraintMinDate = null;
            formField.dateRangeConstraintMaxDate = null;
        } else {
            let serializationObject = DateRangeDataForJsonSerialization.fromJson(value);
            if (serializationObject != null) {
                formField.dateRangeConstraintMinDate = serializationObject.minDate;
                formField.dateRangeConstraintMaxDate = serializationObject.maxDate;
            }
        }
    }

    // not relevant for this field type
    public getConstraintFailureMessage(formField: FormField): string {
        return null;
    }
    public setConstraintFailureMessage(formField: FormField, value: string): void {
        // noop
    }

    public getConstraintFormFieldPropertyValue(formField: FormField): string {
        let serializationObject = new DateRangeDataForJsonSerialization(formField.minDate, formField.maxDate);
        let value = serializationObject.toJson();
        return value;
    }

    public copyConstraintValueToCorrespondFormFieldProperty(formField: FormField, value: string): void {
        let serializationObject = DateRangeDataForJsonSerialization.fromJson(value);
        if (serializationObject != null) {
            formField.minDate = serializationObject.minDate;
            formField.maxDate = serializationObject.maxDate;
        }
    }

    // Create a new, empty constraint.
    public createNewConstraint(dataCollectionId: number): IFormFieldConstraintWithMetrics {
        return new FormFieldDateRangeConstraintWithMetrics(dataCollectionId);
    }

    public createNewConstraintFrom(constraintParam: IFormFieldConstraint): IFormFieldConstraintWithMetrics {
        let constraint = <FormFieldDateRangeConstraint>constraintParam;
        let newConstraint = new FormFieldDateRangeConstraintWithMetrics(constraint.dataCollectionId, constraint.id, constraint.name, constraint.constraintExpression);
        return newConstraint;
    }

    // Package constraint values as a constraint.
    public packageConstraint(dataCollectionId: number, formField: FormField): IFormFieldConstraint {
        let serializationObject = new DateRangeDataForJsonSerialization(formField.minDate, formField.maxDate);
        let constraintExpr: string = serializationObject.toJson();
        //let packagedConstraint = new FormFieldDateRangeConstraint(dataCollectionId, formField.dateRangeConstraintId, formField.dateRangeConstraintName, constraintExpr); 
        let packagedConstraint = FormFieldDateRangeConstraint.constructConstraint(dataCollectionId, formField.dateRangeConstraintId, formField.dateRangeConstraintName, constraintExpr); 

        return packagedConstraint;
    }
    public updateOrCreateConstraint(cachedFormFieldService: CachedFormFieldService, updatedConstraintParam: IFormFieldConstraint): void {
        let updatedConstraint: FormFieldDateRangeConstraint = <FormFieldDateRangeConstraint>updatedConstraintParam;
        cachedFormFieldService.updateOrCreateDateRangeConstraint(updatedConstraint);
    }

    // Methods related to applying form field constraint
    // updates to a form field that uses the same constraint.
    public get createNamedConstraintMethodName(): string {
        return 'createDateRangeConstraint';
    }
    public get updateNamedConstraintMethodName(): string {
        return 'updateDateRangeConstraint';
    }
    public get updateOrCreateNamedConstraintMethodName(): string {
        return 'updateOrCreateDateRangeConstraint';
    }
    public get siteNamedConstraintsMethodName(): string {
        return 'getSiteNamedDateRanges';
    }

    public get usedByFormFieldClasses(): string[] {
        return [DateFieldType];
    }

    public applyConstraintPropertiesToFormField(constraint: IFormFieldConstraint, formField: FormField) {
        formField.dateRangeConstraintId = constraint.id;
        formField.dateRangeConstraintName = constraint.name;
        let dateRangeConstraint = <FormFieldDateRangeConstraint>constraint;
        formField.dateRangeConstraintMinDate = dateRangeConstraint.minDate;
        formField.dateRangeConstraintMaxDate = dateRangeConstraint.maxDate;
        formField.minDate = dateRangeConstraint.minDate;
        formField.maxDate = dateRangeConstraint.maxDate;
    }

    // A method to create a constraint from the results of an all site named constraints query.
    public createFromConstraintProperties(dataCollectionId: number, constraintProperties: SiteFormFieldConstraintProperties): IFormFieldConstraintWithMetrics {
        let constraint: FormFieldDateRangeConstraintWithMetrics = new FormFieldDateRangeConstraintWithMetrics(dataCollectionId, constraintProperties.constraintId, constraintProperties.constraintName, null);
        constraint.minDate = constraintProperties.minDate;
        constraint.maxDate = constraintProperties.maxDate;
        constraint.useCount = constraintProperties.useCount;
        return constraint;
    }
}
