import { MatInput } from '@angular/material/input';
import { ElementRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { ElementTypeMetadata } from './element-type-metadata.model';
import { HtmlPropertyValue } from './html-property-value.model';
import { HtmlInputValue } from './html-input-value.model';
import { ElementCountExpected } from './element-count-expected.enum';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { ScriptableBaseComponent } from '../../components/scriptable-base/scriptable-base.component';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
import { HtmlElementTypeNames } from './html-element-type-names.model';
import { AdditionalElementInfo } from './additional-element-info.model';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
import { HtmlElementInfo } from './html-element-info.model';

export class InputElementTypeMetadataBase extends ElementTypeMetadata {
    protected constructor(metadataKey: string, elementCountExpected: ElementCountExpected) {
        super('input:text', metadataKey, elementCountExpected);
    }

    // Override metadata methods.
    public getTitle = (driver: IBrowserDriver, element: object, additionalElementInfo: AdditionalElementInfo = null): string => {
        let title: string = driver.getInputTitle(element);

        if (additionalElementInfo != null) {
            if ((additionalElementInfo.nameToPrettyNameMap != null) && (additionalElementInfo.nameToPrettyNameMap[title] != null))
                title = additionalElementInfo.nameToPrettyNameMap[title];
        }

        return title;
    }

    public hasProperties = (driver: IBrowserDriver, element: object): boolean => {
        return true;
    }
    public getProperties = (driver: IBrowserDriver, element: object): HtmlPropertyValue[] => {
        let properties: HtmlPropertyValue[] = [];

        return properties;
    }

    public hasInputs = (driver: IBrowserDriver, element: object): boolean => {
        return true;
    }
    public getInputs = (driver: IBrowserDriver, element: object): HtmlInputValue[] => {
        let inputs: HtmlInputValue[] = [];

        let inputTitle: string = this.getTitle(driver, element);
        //let inputValue: string = $(element).val();
        let inputValue: string = driver.getValueFor(element);
        let input: HtmlInputValue = new HtmlInputValue(element, inputTitle, inputValue);
        inputs.push(input);

        return inputs;
    }
    //public setValue(driver: IBrowserDriver, component: ITestableComponent, element: object, value: string): void {
    public setValue(driver: IBrowserDriver, recorderService: ITestActionRecorderService, component: ITestableComponent, htmlElementInfo: HtmlElementInfo, value: string): void { 
        if (component != null) {
            //let baseComponent: ScriptableBaseComponent = <ScriptableBaseComponent>component; // Need to use the interface instead!!!

            /*
            if ((baseComponent.matInputs != null) && (baseComponent.matInputElementRefs != null) &&
                (baseComponent.matInputs.length == baseComponent.matInputElementRefs.length)) {
                let matInputsArray: MatInput[] = baseComponent.matInputs.toArray();
                let matInputElementRefsArray: ElementRef[] = baseComponent.matInputElementRefs.toArray();

                if ((matInputsArray != null) && (matInputsArray.length > 0)) {
                    for (let index: number = 0; index < matInputsArray.length; index++) {
                        let matInput: MatInput = matInputsArray[index];
                        let matInputElementRef: ElementRef = matInputElementRefsArray[index];

                        if (element == matInputElementRef.nativeElement) {
                            //matInput.ngControl.control.setValue(value);
                            driver.sendKeysTo(element, matInput, value);

                            break;
                        }
                    }
                }
            }
            */
            let matInput: MatInput = component.getMatInputWithElement(htmlElementInfo.element);
            if (matInput != null) {
                driver.sendKeysTo(htmlElementInfo.element, matInput, value);
                //recorderService.setInputValue(htmlElementInfo.elementTitle, htmlElementInfo.elementTypeMetadata.metadataKey, value, false);
                recorderService.setInputValue(htmlElementInfo.elementTitle, htmlElementInfo.elementTypeMetadata.metadataKey, htmlElementInfo.elementSubtype, value, false);
            }                
        }
    }
}

export class InputElementTypeMetadata extends InputElementTypeMetadataBase {
    public constructor() {
        super(HtmlElementTypeNames.input_metadataKey, ElementCountExpected.Singular);
        this.prettyElementTypeTitle = 'Input Field';
    }
}
export class InputsElementTypeMetadata extends InputElementTypeMetadataBase {
    public constructor() {
        super(HtmlElementTypeNames.inputs_metadataKey, ElementCountExpected.Singular_or_Plural);
        this.prettyElementTypeTitle = 'Input Fields';
    }
}
