import { Type } from 'class-transformer';

import { WorkflowCountData } from './workflow-count-data.model';

export class WorkflowResponseData {
    public dataCollectionId: number;

    public includeFormInstances: boolean;
    public includeFolders: boolean;

    // Response data.
    @Type(() => WorkflowCountData)
    public workflowCountData: WorkflowCountData[];
}
