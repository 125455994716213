import { IntegerFieldDefLogic } from './integer-field-def-logic';
import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { FormInstanceGridRow } from '../../site-content/form-instance-grid-row.model';
import { MetadataFieldDefinitionLogicBase } from './metadata-field-def-logic-base';

export class IdMetadataFieldDefLogic extends MetadataFieldDefinitionLogicBase {
    public constructor() {
        super('id');
    }

    public unpackTemplateInstancesRow(formField: FormField, colValue: FormInstanceElement, row: FormInstanceGridRow): any {
        let fieldValue: string = row[formField.name];
        return fieldValue;
    }
}
