<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>

<div *ngIf="(this.Mode === 'design') || (this.Mode === 'preview') || (this.Mode === 'instance')"
     class="main-content-div">
    <!-- Display my label. -->
    <!--<div class="design-mode-field-label" *ngIf="this.DisplayName != null">{{this.DisplayName}}</div>-->
    <div class="label-div">
        <mat-label class="field-label">{{this.DisplayName}}</mat-label>
    </div>
    <!-- Define a place to inject instructions, if any. -->
    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>

    <div class="{{getContactTableCssClasses(true)}}">
        <table mat-table
               [dataSource]="this.ContactsListing"
               class="{{this.getContactTableCssClasses(false)}}">
            <!-- Define columns. -->
            <ng-container matColumnDef="name"
                          [sticky]="this.columnIsSticky('name')">
                <th mat-header-cell
                    *matHeaderCellDef
                    class="contacts-header-cell"
                    style="{{this.getColumnStyle('name')}}">
                    Name
                </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="!element.editingEnabled">{{element.fullName}}</span>
                    <div *ngIf="element.editingEnabled"
                         class="edit-field-div">
                        <div class="edit-field-spacer-div"></div>
                        <input matInput
                               class="contact-field-input"
                               placeholder="Contact name"
                               [(ngModel)]="element.fullName"
                               (ngModelChange)="this.handleModelChange(element, 'fullName')" />
                        <div class="edit-field-spacer-div"></div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="email"
                          [sticky]="this.columnIsSticky('email')">
                <th mat-header-cell
                    *matHeaderCellDef
                    class="contacts-header-cell"
                    style="{{this.getColumnStyle('email')}}">
                    Email
                </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="!element.editingEnabled">{{element.email}}</span>
                    <div *ngIf="element.editingEnabled"
                         class="edit-field-div">
                        <div class="edit-field-spacer-div"></div>
                        <input matInput
                               class="contact-field-input"
                               placeholder="Contact email"
                               [(ngModel)]="element.email"
                               (ngModelChange)="this.handleModelChange(element, 'email')" />
                        <div class="edit-field-spacer-div"></div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="phone"
                          [sticky]="this.columnIsSticky('phone')">
                <th mat-header-cell
                    *matHeaderCellDef
                    class="contacts-header-cell"
                    style="{{this.getColumnStyle('phone')}}">
                    Phone
                </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="!element.editingEnabled">{{element.phone}}</span>
                    <div *ngIf="element.editingEnabled"
                         class="edit-field-div">
                        <div class="edit-field-spacer-div"></div>
                        <input matInput
                               class="contact-field-input"
                               placeholder="Contact phone"
                               [(ngModel)]="element.phone"
                               (ngModelChange)="this.handleModelChange(element, 'phone')" />
                        <div class="edit-field-spacer-div"></div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="action"
                          [sticky]="this.columnIsSticky('action')">
                <th mat-header-cell
                    *matHeaderCellDef
                    class="contacts-header-cell action-header-cell"
                    style="font-weight: 900;">
                    <!-- VNEXT-951: KLW - Changes to email a contact list-->
                    <div class="action-email-all">
                        <p class="remove-margins">Action</p>
                        <a [href]="this.emailAll()">
                            <button mat-raised-button
                                    [disabled]="this.EmailAllButtonDisabled"
                                    [ngClass]="this.EmailButtonDisabled ? 'row-action-disabled' : 'row-action'"
                                    title="{{this.EmailAllToolTip}}"
                                    matTooltip="{{this.EmailAllToolTip}}"
                                    [matTooltipPosition]="this.TooltipPosition"
                                    (click)="this.showEmailDialog()">
                                Email All
                            </button>
                        </a>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="actions-div">

                    <!-- VNEXT-811: KLW - Changes to email a contact -->
                    <a [href]="this.getEmail(element.email, element.fullName)">
                        <button *ngIf="this.EnableEmailEditing"
                                 mat-stroked-button 
                                 [disabled]="this.EmailButtonDisabled"
                                 (click)="this.showEmailDialog()"
                                  > 
                            <i [appIcon] 
                                matIconText="email" 
                                appIconItemType="{{this.ActivityListIcon}}"
                                [ngClass]="this.EmailButtonDisabled ? 'row-action-disabled' : 'row-action'"
                                title="{{this.EmailToolTip}}"
                                matTooltip="{{this.EmailToolTip}}"
                                [matTooltipPosition]="this.TooltipPosition"></i>
                        </button>
                    </a>
                
                        <button *ngIf="this.EnableContactEditing"
                                mat-stroked-button
                                [disabled]="this.EditContactButtonDisabled"
                                (click)="this.editContact(element)">
                            <mat-icon [ngClass]="this.EditContactButtonDisabled ? 'row-action-disabled' : 'row-action'"
                                      title="{{this.getEditContactTooltip(element)}}"
                                      matTooltip="{{this.getEditContactTooltip(element)}}"
                                      [matTooltipPosition]="this.TooltipPosition">create</mat-icon>
                        </button>

                        <button mat-stroked-button
                                [disabled]="this.TrashCanButtonDisabled"
                                (click)="this.deleteContact(element)">
                            <mat-icon [ngClass]="this.TrashCanButtonDisabled ? 'row-action-disabled' : 'row-action'"
                                      title="{{this.TrashCanTooltip}}"
                                      matTooltip="{{this.TrashCanTooltip}}"
                                      [matTooltipPosition]="this.TooltipPosition">delete</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <!-- Define the header row and data row template. -->
            <tr mat-header-row *matHeaderRowDef="this.DisplayedColumns; sticky: this.HeaderRowIsSticky"></tr>
            <tr mat-row *matRowDef="let row; columns: this.DisplayedColumns;"></tr>
        </table>
    </div>

    <!--VNEXT-614: KLW - Adding conditional to determine if control should be disabled-->
    <div class="mat-picker-div">
        <app-max-picker class="max-picker-component"
                        [scope]="'usersOnly'"
                        [pickerDisabled]="this.PickerInputDisabled || this.Disabled"
                        [userGroupPickerLabel]="this.AddContactLabel"
                        [maxMemberStyle]="'width: 59%;'"
                        [addButtonName]="this.AddContactButtonTitle"
                        [addButtonAdditionalStyle]="this.AddContactButtonAdditionalStyle"
                        [addButtonFlyoverText]="this.AddContactButtonFlyoverText"
                        (addButtonClicked)="this.addContactButtonClicked($event)"
                        [secondButtonName]="this.AddMeButtonTitle"
                        [secondButtonCanEnable]="this.AddMeButtonCanEnable"
                        [secondButtonIgnoreMemberInput]="true"
                        [secondButtonFlyoverText]="this.AddMeButtonFlyoverText"
                        (secondButtonClicked)="this.addMeButtonClicked($event)"></app-max-picker>

        <!--xxxclass="add-me-btn theme-primary-btn"-->
        <!--
        <button mat-flat-button matSuffix
                color="primary"
                style=""
                [disabled]="!this.AddMeButtonCanEnable"
                matTooltip="{{this.AddMeButtonFlyoverText}}"
                [matTooltipPosition]="this.TooltipPosition"
                (click)="this.addMeButtonClicked($event)">
            {{this.AddMeButtonTitle}}
        </button>
            -->
        <!--
        <button mat-flat-button matSuffix
                class="add-me-btn theme-primary-btn"
                color="primary">Add Me</button>
            -->
    </div>


</div>
