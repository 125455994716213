import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
import { FunctionExecutionStatus } from '../../interfaces/iscriptable-function';
import { IScriptableFunction } from '../../interfaces/iscriptable-function';
import { IOperationCompletedCallback, IRegisterOperationCompletedCallback } from '../../interfaces/ioperation-completed.intefaces';

export abstract class ScriptableFunctionBase implements IScriptableFunction, IOperationCompletedCallback {
    // Properties.
    protected awaitingCompletionOfOperationName: string = null;
    protected awaitedOperationCompleted: boolean = false;
    protected numCheckExecutionCallsSinceComponentInitialized: number = 0;

    // Constructor.
    protected constructor() {
    }

    // Implement IScriptableFunction methods.
    public abstract get callableName(): string;

    public get minNumParams(): number {
        return 0; // default.
    }
    public get maxNumParams(): number {
        return 0; // Default.
    }
    public validateParams(params: string[]): boolean {
        return true; // Default.
    }

    public abstract execute(testActionService: ITestActionRecorderService, params: string[]): FunctionExecutionStatus;
    public checkExecutionStatus(testActionService: ITestActionRecorderService, params: string[]): FunctionExecutionStatus {
        return FunctionExecutionStatus.Completed;
    }

    // Implement IOperationCompletedCallback.
    public operationCompleted(operationName: string): void {
        // NOTE:  THIS IMPLEMENTATION IS A NOOP.
        // NOTE:  MY DERIVED CLASS SHOULD IMPLEMENT THIS METHOD IF IT REQUIRES THE FUNCTIONALITY.
    }

    // Protected methods.
    protected registerAsOperationCompletedCallback(testActionService: ITestActionRecorderService, operationCompletedServiceName: string, operationName: string): boolean {
        let success = true;

        // Always reset the properties.
        this.awaitingCompletionOfOperationName = null;
        this.awaitedOperationCompleted = false;
        this.numCheckExecutionCallsSinceComponentInitialized = 0;

        // Conditionally register as a callback.
        if ((operationCompletedServiceName != null) && (operationName != null)) {
            let registerCallback: IRegisterOperationCompletedCallback = testActionService.getRegisterOperationCompletedCallbackFor(operationCompletedServiceName);
            if (registerCallback != null) {
                registerCallback.registerOperationCompletedCallback(this);
                this.awaitingCompletionOfOperationName = operationName;
            } else
                success = false;
        }

        return success;
    }
}
