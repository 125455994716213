// NOTE:  THE FOLLOWING VALUES SHOULD BE MAINTAINED IN
//        ALPHABETICAL ORDER TO HELP AVOID MAKING MISTAKES.
export enum FormFieldPropertyEnum {
    ALL = 'all',
    CANCEL_ALL = 'cancelEditOfAllFieldProperties',

    FORM_TEMPLATE_INSTANCE_ACTION_BUTTONS_CONFIG = 'formTemplateInstanceActionButtonsConfig',

    AUTOCOMPLETE = 'autocomplete',

    //VNEXT-519: KLW - Refinements to the Type ahead functionality
    AUTOCOMPLETE_STARTSWITH = 'autocompleteStartsWith',
    AUTOCOMPLETE_CONTAINS = 'autocompleteContains',

    BLANK_VALUE = 'blankValue',
    CASCADING_DROPDOWN_CONSTRAINT_NAME = 'cascadingDropdownConstraintName',
    SELECTED_CASCADING_DROPDOWN_CONSTRAINT = 'selectedNamedCascadingDropdownConstraint',
    CONSTRAINT = 'constraint',
    DEFAULT_VALUE = 'defaultValue',
    DISPLAY_FORMAT = 'displayFormat',
    GRID_COLUMN_WIDTH = 'gridColumnWidth',
    DISPLAY_NAME = 'displayName',
    DISPLAY_ROWS = 'displayRows',
    DISPLAY_KENDO_GRID_ROWS = 'displayKendoGridRows', //VNEXT-980: KLW - Property to set the number of Kendo grid rows to display
    EXCEL_IMPORT_EXPORT_TEMPLATE = 'excelImportExportTemplate',
    FIELD_GROUP = 'fieldGroup',
    FIXED_FIRST_COLUMN_FILE = 'fixedFirstColumnFile',
    //FIXED_ROW_HEADINGS_FILE = 'fixedRowHeadingsFile',
    FOOTER = 'footer', // For grid form fields.
    FORMULA = 'formula',
    URL_BUILDER = 'urlBuilder',
    URL_BUILDER_LABEL = 'urlBuilderLabel',
    FORM_INSTANCE_SERVICE_REQUIRED = 'formInstanceServiceRequired',
    FORM_TEMPLATE = 'formTemplate',
    FORM_TEMPLATE_PROPERTIES = 'formTemplateProperties',
    GRID_KEY_COLUMNS = 'gridKeyColumns', // Note:  please see the comment above proeprty 'gridKeyColumns' in model class FormField for information about meaning of this property.
    HAS_FOOTNOTE = 'footnote', //TEAMS-424: KLW - Added property for footnote
    HELP_TEXT = 'helpText',
    HTML_TEXT = 'htmlText',
    HIDE_GRID_FILTERING = 'hideGridFiltering',
    HIDE_GRID_BODY = 'hideFormFieldBody',
    DISABLE_GRID_GROUPING = 'disableGridGrouping',
    IMAGE_MANAGEMENT_BUTTONS = 'imageButtons',
    IFRAME_SRC = 'iframeSource',
    INSTRUCTIONS_TEXT = 'instructionsText',
    MIN_DATE = 'minDate',
    MAX_DATE = 'maxDate',
    NO_DEFAULT_PROPERTIES = 'noDefaultProperties',
    SELECTED_DATE_RANGE_CONSTRAINT = 'selectedNamedDateRangeConstraint',
    DATE_RANGE_CONSTRAINT_NAME = 'dateRangeConstraintName',
    MAX_LENGTH = 'maxLength',
    MIN_VALUE = 'minValue',
    MAX_VALUE = 'maxValue',
    NAME = 'name',
    GRID_COLUMN_NAME = 'gridColumnName',
    NUM_DIGITS_AFTER_DECIMAL_POINT = 'numDigitsAfterDecimalPoint',
    NUMBER_OF_DISPLAY_ROWS = 'numDisplayRows',
    SELECTED_NUMERIC_RANGE_CONSTRAINT = 'selectedNamedNumericRangeConstraint',
    SHOW_TRAILING_ZEROES_AFTER_DECIMAL_POINT = 'showTrailingZeroesAfterDecimalPoint',
    NUMERIC_RANGE_CONSTRAINT_NAME = 'numericRangeConstraintName',
    OPEN_FORM_INSTANCES_IN_NEW_TAB = 'openFormInstancesInNewTab',
    PLACEHOLDER_TEXT = 'placeholderText',
    READ_ONLY = 'readOnly',
    REGEX = 'regex',
    REGEX_CONSTRAINT_NAME = 'regularExpressionConstraintName',
    REGEX_CONSTRAINT_MSG = 'regularExpressionConstraintMessage',
    REQUIRED = 'required',
    ROLE_NAMES = 'roleNames',
    ROUND_TO_NUM_DIGITS_AFTER_DECIMAL_POINT = 'roundToNumDigitsAfterDecimalPoint',
    ROUND_TO_WHOLE_NUMBER = 'roundToWholeNumber',
    SELECT_OPTIONS = 'selectOptions',
    SELECT_OPTIONS_FILE = 'selectOptionsFile',
    SELECT_OPTIONS_CONSTRAINT_NAME = 'selectOptionsConstraintName',

    // 01-20-2021 note:  added the following option
    //                   for grid form field components.
    //
    //                   Might want to rethink this
    //                   option to make the form
    //                   filler request any additional
    //                   data, but that would require
    //                   an additional output for every
    //                   form field type.

    SHOW_CONTACTS_PROPERTIES = 'showContactsProperties',
    SHOW_DIGITS_WITH_COMMA_SEPARATORS = 'showDigitsWithCommaSeparators',
    SHOW_DOLLAR_SIGN_PREFIX = 'showDollarSignPrefix',
    SHOW_PREVIEW = 'showPreview', // Used to show previews of numeric values, with formatting,
    // to make it easier for someone entering data to be sure
    // that the entered values are correct.
    TOOL_TIP = 'toolTip',

    // 06-22-2022:  added the following property related only to a contacts form field.

    // 09-10-2022:  added the following property related only to a comments form field.
    SHOW_COMMENTS_PROPERTIES = 'showCommentsProperties',
    SHOW_CASCADING_DROPDOWN_INDICES_IN_PREVIEW_MODE = 'showCascadingDropdownIndicesInPreviewMode',
    //VNEXT-384: KLW - Implementing Autocomplete

    //TEAMS-561: KLW - Logic for validation
    INVALID = 'INVALID',
    VALID = 'VALID',

    DISABLED = 'DISABLED'

}
