export interface QueryBuilder {
    toQueryMap: () => Map<string, string>;
    toQueryString: () => string;
}

export class QueryOptions implements QueryBuilder {
    public parameters: Param[];

    constructor() {
    }

    public addParameter(name, value) {
        if (!this.parameters)
            this.parameters = [];

        var p = new Param();
        p.name = name;
        p.value = value;
        this.parameters.push(p);
    }

    toQueryMap() {
        const queryMap = new Map<string, string>();

        if (this.parameters) {
            this.parameters.forEach(x => {
                queryMap.set(x.name, x.value);
            });
        }

        return queryMap;
    }

    toQueryString() {
        let queryString = '';
        this.toQueryMap().forEach((value: string, key: string) => {
            queryString = queryString.concat(`${key}=${value}&`);
        });

        return queryString.substring(0, queryString.length - 1);
    }
}

export class Param {
    name: string;
    value: string;
}
