import { ICloneAndCopy } from '../../interfaces/clone-and-copy';

export class FormSettings implements ICloneAndCopy {
    // Properties.
    public hideSaveButton: boolean = false;
    public saveButtonCaption: string;
    public confirmSavePopup: boolean = false;

    // ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let settingsClone: FormSettings = new FormSettings();
        settingsClone.hideSaveButton = this.hideSaveButton;
        settingsClone.saveButtonCaption = this.saveButtonCaption;
        settingsClone.confirmSavePopup = this.confirmSavePopup;
        return (settingsClone);
    }

    public copy(objectToCopy: ICloneAndCopy): void {
        let settingsToCopy: FormSettings = <FormSettings>objectToCopy;
        this.hideSaveButton = settingsToCopy.hideSaveButton;
        this.saveButtonCaption = settingsToCopy.saveButtonCaption;
        this.confirmSavePopup = settingsToCopy.confirmSavePopup;
        return;
    }
}
