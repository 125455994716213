// Define a general purpose interface for notifying code/components of significant
// changes to a a model object, e.g.the completion of a save operation.
export enum ModelEventEnum {
    MODEL_WILL_SAVE = 'modelWillSave', // Called just prior to a save operation.
    MODEL_SAVED = 'modelSaved' // Called after a save operation completes.
};

export interface IModelEventHandler {
    eventCompleted(event: ModelEventEnum, modelObject: any): void;
}
