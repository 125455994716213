import { PermissionsPreviewVirtualFolderHierarchyRequest } from './perPreVirtualFolderHierarchyRequest.model';
import { VirtualFolderPermissionsHierarchyRecord } from './virtualFolderPerHierarchyRecord.model';

export class PermissionPreviewNode {
    // Properties.
    public id: number;

    public isFirstRoleRecordFlag: boolean;
    public isLastRoleRecordFlag: boolean;

    public roleId: number;
    public roleName: string;

    // The following two fields will probably be removed.
    //public memberType: string;
    //public member: string;

    public numGroupMembers: number;
    public numUserMembers: number;

    public folderId: number; // The 'virtual' folder Id.
    public folderName: string; // Replaces the 'workflowAndState' property.
    public workflowSortField: string;

    //public workflowAndState: string;
    public workflowId: number;
    public workflowStateId: number;

    public canBuildRoles: boolean;
    public canBuildWorkflows: boolean;
    public canBuildForms: boolean;
    public canBuildFolders: boolean;
    public canBuildFormInstances: boolean;

    public canView: boolean;
    public canEdit: boolean;
    public canDelete: boolean;
    public canGrant: boolean;

    // Define transient properties
    // only used by the client.
    //public transientClientId: number;
    public transientIsShown: boolean = false;
    //public transientVirtualFolderHierarchyResponse: PermissionsPreviewVirtualFolderHierarchyRequest;
    public transientHierarchyRecords: VirtualFolderPermissionsHierarchyRecord[] = null;
    public transientPermissionNameClicked: string = null;

    // Methods.
    public canBuild(permissionCellName: string): boolean {
        let canBuild: boolean = this[permissionCellName];

        return (canBuild);
    }

    public canPerform(permissionCellName: string): boolean {
        let canPerform: boolean = this[permissionCellName]

        return (canPerform);
    }

    public get workflowHierarchyShown(): boolean {
        return this.transientIsShown;
    }

    public get workflowHierarchyRecords(): VirtualFolderPermissionsHierarchyRecord[] {
        /*
        let hierarchyRecords: VirtualFolderPermissionsHierarchyRecord[] =
            (this.transientVirtualFolderHierarchyResponse != null ?
                this.transientVirtualFolderHierarchyResponse.hierarchyRecords : []);

        return (hierarchyRecords);
        */
        return this.transientHierarchyRecords;
    }
}
