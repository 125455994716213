<!--<h3>{{this.FieldTypeName}}</h3>-->

<div class="flex-row-top-right" *ngIf="this.Mode === 'design'">
    <div class="fill-remaining-space">

        <mat-label *ngIf="this.ShowLabel"
                   class="design-mode-field-label"
                   style="{{this.IndentationStyle}}">{{this.DescriptiveDisplayName}}</mat-label>

        <!-- Note:  only one of the following *ngIf conditions will be true. -->
        <!-- If we do not have a configuration, give the user a hint. -->
        <div *ngIf="this.ShowConfigurationHint"
             class="has-no-config-div">
            <p class="user-tip-p">{{this.ConfigurationHintText}}</p>
        </div>
        <!-- If we have selected role names, display them. -->
        <div *ngIf="!this.ShowConfigurationHint"
             class="has-config-div"
             style="{{this.IndentationStyle}}">
            <ng-container *ngIf="this.ShowConfiguredRoleNames">
                <span class="configured-roles-label">Configured Roles:</span><span class="configured-roles-span">{{this.ConfiguredRoleNames}}</span>
            </ng-container>

            <ng-container *ngIf="this.DebugMode">
                <span class="debug-nesting-level-span">Nesting Level: </span><span>{{this.ConditionalNestingLevel}}</span>
            </ng-container>
        </div>
    </div>
</div>

