<ng-template #instructions><ng-content></ng-content></ng-template>
<div *ngIf="this.Mode === 'design'">
    <mat-form-field appearance="outline" [floatLabel]="'always'">
        <mat-label>{{this.DisplayName}}</mat-label>
        <input matInput
               type="number"
               placeholder="{{this.PlaceholderText}}"
               value="{{this.PreviewModeValue}}"
               disabled />
    </mat-form-field>
    <ng-container *ngTemplateOutlet="instructions"></ng-container>
</div>

<div *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')"
     class="preview-instance-modes-div">
    <span class="preview-instance-modes-span">{{this.FormattedCalculatedValue}}</span>
    <ng-container *ngTemplateOutlet="instructions"></ng-container>
</div>
