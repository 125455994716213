import {
    Component,
    OnInit,
    Renderer2,
    Output,
    EventEmitter,
    Type as AngularCoreType
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
//import { FormFieldPropertyEnum } from '../../form-builder/properties/form-field-properties/form-field-properties.component';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';

@Component({
    selector: 'app-raw-html-form-field',
    templateUrl: './raw-html-form-field.component.html',
    styleUrls: ['./raw-html-form-field.component.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: RawHtmlFormFieldComponent,
            multi: true
        }
    ]
})
export class RawHtmlFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    // Properties.
    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();

    private readonly formFieldNames: string[] =
        [
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.PLACEHOLDER_TEXT,
            FormFieldPropertyEnum.HTML_TEXT
        ]

    private testHTML: string = "<strong>The Tortoise</strong> &amp; and the Hare.";

    // Constructor.
    constructor(private renderer: Renderer2)
    {
        super();

        return;
    }

    // Life cycle methods.
    ngOnInit(): void {
        //this.onInit.emit({ component: this, formField: this.formField, properties: this.formFieldNames });
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldNames
        };

        return (hshProperties);
    }

    // Accessor methods.
    public get HTML(): string {
        //return this.testHTML;
        return (this.FormField.htmlText != null ? this.FormField.htmlText : '*** Enter text to configure this field ***');
    }

    public get canHaveFieldConditionalLogic(): boolean {
        return false;
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement): string {
        //if ((!formInstanceElementParam.transientValueSetFlag) ||
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        }

        // NOTE:  NEED TO REVISIT THIS.
        return (formInstanceElementParam.textValue);
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (RawHtmlFormFieldComponent);
    }
}
