import { DynamicComponentHostDirective } from '../../directives/dynamic-content-host.directive';
import { FormFieldBaseComponent } from "../../components/form-fields/form-field-base/form-field-base.component";
import { IDirectNameToDynamicComponentHost, IFormFieldByTemplateName, IGridRowErrors } from './type-safe-hashes';

export class GridRuntimeData {
    public templateDirectives: DynamicComponentHostDirective[] = null;
    public hshDirectivesByName: IDirectNameToDynamicComponentHost = null;

    public dynamicallyCreatedFormFieldsByName: IFormFieldByTemplateName = {};

    public componentBeingEdited: FormFieldBaseComponent = null;

    public selectedGridRowIndex: number = -1;
    public selectedGridColumnIndex: number = 0;

    public iNumRowFormFieldsInitialized: number = 0;
    public iMaxComponentPreviewInstanceHeightRequired: number = 0; // 0 => no height required.
    public gridWidth: number;

    public invalidGridRows: IGridRowErrors = {};
}
