import { ExportSiteStepEnum } from './export-site-step.enum';
import { IJobSummaryText } from './i-job-summary-text';
import { MetricsBase } from './metrics-base.model';

export class ExportSiteMetrics extends MetricsBase {
    public numRolesFound: number;
    public numWorkflowsFound: number;
    public numFormsFound: number;
    public numFoldersFound: number;
    public numFormInstancesFound: number;

    public exportStep: ExportSiteStepEnum = ExportSiteStepEnum.ExportingRoles;

    public numRolesExported: number;
    public numWorkflowsExported: number;
    public numFormsExported: number;
    public numFoldersExported: number;
    public numFormInstancesExported: number;

    // Import summary message.
    //public importSummaryMessage: string;

    // Property names for the super/base class constructor.
    private static readonly propertyNames: string[] =
        [
            // Properties from MetricsBase.
            'progressLog',
            'warningLog',
            'errorLog',

            'jobSummaryMessage',

            'exceptionLoggedAndJobTerminated',

            // My properties.
            'numRolesFound',
            'numWorkflowsFound',
            'numFormsFound',
            'numFoldersFound',
            'numFormInstancesFound',

            'exportStep',

            'numRolesExported',
            'numWorkflowsExported',
            'numFormsExported',
            'numFoldersExported',
            'numFormInstancesExported'
        ];

    // Constructor.
    public constructor(jsonData: Object) {
        super(ExportSiteMetrics.propertyNames, jsonData);
    }

}
