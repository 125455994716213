import { FormulaEvaluationHelper } from './formula-evaluation-helper';
import { INumericValuesHashByFieldName, IValuesByFieldName } from '../../../interfaces/grid-row.interface';
import { FormField } from '../../../models/form-builder/form-field.model';
import * as math from 'mathjs';

export class FormulaEvaluator {
    // PJH - 10/15/2024 - Added for VNEXT-1419 (url builder field)
    public static evaluateUrl(formulaEvaluationHelper: FormulaEvaluationHelper, formulaParam: string, hshFieldValues: IValuesByFieldName): string {
        let formula: string = ((formulaParam != null) && (formulaParam.trim() != '') ? formulaParam.toLowerCase() : '');
        let result = FormulaEvaluator.replaceTokens(formula, hshFieldValues);
        return result;
    }

    // pharvey - 8/3/2023 - simplified it by removing first param and having all hash lookups
    // performed against the hashes in IFormulaEvaluationHelper.FieldNameHashes
    public static evaluateFormula(formulaEvaluationHelper: FormulaEvaluationHelper, formulaParam: string, hshValues: INumericValuesHashByFieldName = null): any {
        const parser: any = math.parser();

        let formula: string = ((formulaParam != null) && (formulaParam.trim() != '') ? formulaParam.toLowerCase() : '');

        // Build a scope object.
        FormulaEvaluator.buildComputationScope(formulaEvaluationHelper, parser.scope, hshValues);

        // Try to evalulate the expression.
        let result: number = 0;
        //let strError: string = null;

        try {
            result = parser.evaluate(formula);

            if ((result != null) && (!Number.isNaN(result))) {
                result = Math.round(result * formulaEvaluationHelper.RoundTo) / formulaEvaluationHelper.RoundTo;
                formulaEvaluationHelper.CalculationError = null;
            } else
                formulaEvaluationHelper.CalculationError = 'Calcuation error';
        } catch (error) {
            //strError = error.toString();
            formulaEvaluationHelper.CalculationError = error.toString();
        }

        // Construct and return a result.
        let hshResult = {
            result: result,
            error: formulaEvaluationHelper.CalculationError
        };

        return (hshResult);
    }

    // pharvey - 8/3/2023 - simplified it by removing first param and having all hash lookups
    // performed against the hashes in IFormulaEvaluationHelper.FieldNameHashes
    private static buildComputationScope(formulaEvaluationHelper: FormulaEvaluationHelper, parserScope: Map<string, number>, hshValues: any = null): void {
        let colNameToFormFieldsHash = formulaEvaluationHelper.FieldNameHashes.hshColNameToFormField;
        for (let fieldName in colNameToFormFieldsHash) {
            let formField: FormField = colNameToFormFieldsHash[fieldName];

            if (formField.gridColClientId !== formulaEvaluationHelper.FormField.gridColClientId) {
                if (hshValues && hshValues[fieldName]) {
                    parserScope.set(fieldName, hshValues[fieldName]);
                } else {
                    parserScope.set(fieldName, 0);
                } // if-else
            } // if
        } // for

        // Add scope members for display variable names.
        for (let gridVariableName in formulaEvaluationHelper.FieldNameHashes.hshDisplayVariableNameToFormField) {
            let formField: FormField = formulaEvaluationHelper.FieldNameHashes.hshDisplayVariableNameToFormField[gridVariableName];

            if (formField.gridColClientId !== formulaEvaluationHelper.FormField.gridColClientId) {
                let fieldName: string =
                    formulaEvaluationHelper.FieldNameHashes.hshFieldIdToFieldName[formField.gridColClientId];

                if (hshValues && hshValues[fieldName]) {
                    parserScope.set(gridVariableName, hshValues[fieldName]);
                } else {
                    parserScope.set(gridVariableName, 0);
                } // if-else
            } // if
        }

        //return (hshScope);
    }

    // Thank you, Google AI Overview
    private static replaceTokens(template, values): string {
        return template.replace(/\{(\w+)\}/g, (match, token) => {
            if (values.hasOwnProperty(token)) {
                let value = values[token];
                if (!value) value = 'PARAM-IS-NOT-SET';
                return value;
            } else {
                return match;
            }
        });
    }
}
