import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class WorkflowResolver implements Resolve<any> {
    constructor(private workflowService: WorkflowService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        let id = route.params['id'];

        return this.workflowService.get(id).then(response => {
            return response;
        });
    }
}
