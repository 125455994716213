import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from "class-transformer";

import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { SiteResponseManagementData } from '../models/site-response-management.model';
import { ProgressIndicatorService, ProgressConfig } from './progress-indicator.service';
import { WorkflowResponseData } from '../models/site-response-management/workflow-response-data.model';
import { WorkflowStateResponseData } from '../models/site-response-management/workflow-state-response-data.model';
import { MagicFolderResponseData } from '../models/site-response-management/magic-folder-response-data.model';
import { MagicFormInstanceResponseData } from '../models/site-response-management/magic-form-instance-response-data.model';

@Injectable({
    providedIn: 'root'
})
export class SiteResponseManagementService extends CollectApiServiceBase<SiteResponseManagementData> {
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'siteResponseManagement', SiteResponseManagementData)
    }

    /*
    public formatResponse(data: SiteResponseManagementData): SiteResponseManagementData {
        let obj = plainToClass(SiteResponseManagementData, data);

        return (obj);
    }
    */

    // Service methods.
    public getWorkflowMetrics(dataCollectionId: number, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<WorkflowResponseData> {
        let url = `${this.url}/${this.endpoint}/getWorkflowMetrics/${dataCollectionId}`;

        let msg = progressConfig.msgDuring || this.progressMsg('Querying workflow metrics');
        this.updateProgress(50, 75, msg);

        return this.http.get<WorkflowResponseData>(url)
            .toPromise()
            .then(res => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Queried');
                this.updateProgress(100, 100, msg);

                let result = plainToClass(WorkflowResponseData, res);
                return (result);
            })
            .catch(this.handleError);
    }

    public getWorkflowStateMetrics(workflowId: number, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<WorkflowStateResponseData> {
        let url = `${this.url}/${this.endpoint}/getWorkflowStateMetrics/${workflowId}`;

        let msg = progressConfig.msgDuring || this.progressMsg('Querying workflow state metrics');
        this.updateProgress(50, 75, msg);

        return this.http.get<WorkflowStateResponseData>(url)
            .toPromise()
            .then(res => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Queried');
                this.updateProgress(100, 100, msg);

                let result = plainToClass(WorkflowStateResponseData, res);
                return (result);
            })
            .catch(this.handleError);
    }

    public getMagicFolderMetrics(magicFolderId: number, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<MagicFolderResponseData> {
        let url = `${this.url}/${this.endpoint}/getMagicFolderMetrics/${magicFolderId}`;

        let msg = progressConfig.msgDuring || this.progressMsg('Querying magic folder metrics');
        this.updateProgress(50, 75, msg);

        return this.http.get<MagicFolderResponseData>(url)
            .toPromise()
            .then(res => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Queried');
                this.updateProgress(100, 100, msg);

                let result = plainToClass(MagicFolderResponseData, res);
                return (result);
            })
            .catch(this.handleError);
    }

    public getMagicFormInstanceMetrics(magicFormInstanceId: number, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<MagicFormInstanceResponseData> {
        let url = `${this.url}/${this.endpoint}/getMagicFormInstanceMetrics/${magicFormInstanceId}`;

        let msg = progressConfig.msgDuring || this.progressMsg('Querying magic form metrics');
        this.updateProgress(50, 75, msg);

        return this.http.get<MagicFormInstanceResponseData>(url)
            .toPromise()
            .then(res => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Queried');
                this.updateProgress(100, 100, msg);

                let result = plainToClass(MagicFormInstanceResponseData, res);
                return (result);
            })
            .catch(this.handleError);
    }
}
