import { Type as AngularCoreType } from '@angular/core';

export class ComponentMethodMetadata {
    // Properties.
    public userFriendlyName: string = null;
    public parameterNames: string[] = null;
    public parametersTypeInfo: string[] = null;

    // Constructor with required properties.
    public constructor(public componentClass: AngularCoreType<any>, public methodName: string, public argumentTypeNames: string[], public returnTypeName: string) {
    }
}
export class ComponentMethodsMetadata {
    public constructor(public className: string) { }

    methodsMetadata: ComponentMethodMetadata[] = [];
}
export interface IComponentNameToMethodsMetadata {
    [componentClassName: string]: ComponentMethodsMetadata;
}



