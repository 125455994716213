import { ElementCountExpected } from './element-count-expected.enum';
import { StringUtil } from '../../utility-classes/string.util';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { ComponentMethodMetadata } from './component-methods-metadata.model';
import { HtmlInputValue } from './html-input-value.model';
import { HtmlPropertyValue } from './html-property-value.model';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
import { AdditionalElementInfo, INameToPrettyNameMap } from './additional-element-info.model';
import { HtmlElementInfo } from './html-element-info.model';
import { IElementTypeMetadata } from '../../interfaces/component-scripting/ielement-type-metadata';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';

declare let $: any; // jQuery

export class ElementTypeMetadata implements IElementTypeMetadata {
    // Properties.
    public prettyElementTypeTitle: string = null;

    // Constructor.
    public constructor(public htmlElementSelector: string,
        public metadataKey: string,
        public elementCountExpected: ElementCountExpected)
    {
    }

    // Compute callback method names.
    public get elementsFlagName(): string {
        return 'has' + StringUtil.capitalize(this.metadataKey);
    }
    public get elementsPropertyName(): string {
        return this.metadataKey;
    }
    public get elementsInitializedCallback(): string {
        return this.metadataKey + 'Initialized';
    }

    // Metadata function default implementations.
    public getTitle = (driver: IBrowserDriver, element: object, additionalElementInfo: AdditionalElementInfo = null): string => {
        return "";
    }

    public hasProperties = (driver: IBrowserDriver, element: object): boolean => {
        return false;
    }
    public getProperties = (driver: IBrowserDriver, element: object): HtmlPropertyValue[] => {
        return [];
    }

    public hasActions = (driver: IBrowserDriver, element: object): boolean => {
        return false;
    }
    public getActions = (driver: IBrowserDriver, element: object): string[] => {
        return [];
    }
    //public click(driver: IBrowserDriver, component: ITestableComponent, element: object, getClickableElementFunction: (element: object) => object): void {
    public click(driver: IBrowserDriver, recorderService: ITestActionRecorderService, component: ITestableComponent, htmlElementInfo: HtmlElementInfo, element: object, getClickableElementFunction: (element: object) => object): void {
        // NOOP BY DESIGN.
    }
    //public performAction(driver: IBrowserDriver, component: ITestableComponent, element: object, getClickableElementFunction: (element: object) => object): void {
    public performAction(driver: IBrowserDriver, recorderService: ITestActionRecorderService, component: ITestableComponent, htmlElementInfo: HtmlElementInfo, element: object, getClickableElementFunction: (element: object) => object): void {
        //this.click(driver, component, element, getClickableElementFunction);
        this.click(driver, recorderService, component, htmlElementInfo, element, getClickableElementFunction);
    }
    public doubleClick(driver: IBrowserDriver, component: ITestableComponent, element: object, getClickableElementFunction: (element: object) => object): void {
        // NOOP BY DESIGN.
    }
    public recordActionClick(recorderService: ITestActionRecorderService, htmlElementInfo: HtmlElementInfo): void {
        // NOOP BY DESIGN.
    }

    public hasMethods(driver: IBrowserDriver, element: object): boolean {
        return false;
    }
    public getMethods(driver: IBrowserDriver, element: object): ComponentMethodMetadata[] {
        return [];
    }

    public hasInputs = (driver: IBrowserDriver, element: object): boolean => {
        return false;
    }
    public getInputs = (driver: IBrowserDriver, element: object): HtmlInputValue[] => {
        return [];
    }
    //public setValue(driver: IBrowserDriver, component: ITestableComponent, element: object, value: string): void {
    public setValue(driver: IBrowserDriver, recorderService: ITestActionRecorderService, component: ITestableComponent, htmlElementInfo: HtmlElementInfo, value: string): void {
        // NOOP BY DESIGN.
    }

    // Protected methods.
    protected mapNameWithExactMatch(name: string, namesMap: INameToPrettyNameMap): string {
        let result: string = namesMap[name] != null ? namesMap[name] : name;
        return result;
    }
    protected mapNameWithPartialMatch(name: string, namesMap: INameToPrettyNameMap): string {
        let result: string = name;
        for (let nameKey in namesMap) {
            if (name.includes(nameKey)) {
                let prettyName: string = namesMap[nameKey];
                result = prettyName;
                break;
            }                       
        }
        return result;
    }
}
