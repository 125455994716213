import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Logging } from './logging'
import { Router } from '@angular/router'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }

    handleError(error) {
        const router = this.injector.get(Router);

        if (error.message && error.message.includes("JWT present or has expired")) {
            Logging.log('attempting to relogin');
            router.navigate(['/login']);
        }

        // 03-23-2020 new code:  handle chunk caching errors by reloading the app URL.
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();

            return;
        }
        // End 03-23-2020 new code.

        Logging.log("Global Error Handler -- handling the following error:  " + error.message || error.toString());
        if (error.stack) {
            Logging.log("Stack trace:  " + error.stack);
        }

        // IMPORTANT: Rethrow the error otherwise it gets swallowed
        throw error;
    }

}
