import { ClickableElementTypeMetadata } from './clickable-element-type-metadata.model';
import { ElementCountExpected } from './element-count-expected.enum';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
import { HtmlElementTypeNames } from './html-element-type-names.model';
import { AdditionalElementInfo, IAdditionalElementInfo } from './additional-element-info.model';

export class MatListItemsElementTypeMetadata extends ClickableElementTypeMetadata {
    public constructor() {
        super('mat-list-item', HtmlElementTypeNames.matListItems_metadataKey, ElementCountExpected.Singular_or_Plural);
        this.prettyElementTypeTitle = 'Material List Items';
    }

    public getTitle = (driver: IBrowserDriver, element: object, additionalElementInfo: AdditionalElementInfo = null): string => {
        //let title: string = additionalElementInfo != null ? driver.getTextFor(element, additionalElementInfo.optionalInnerTextSelector) : driver.getTextFor(element);
        let title: string = additionalElementInfo != null ? driver.getTextFor(element, additionalElementInfo.innerTextSelector) : driver.getTextFor(element);
        if ((additionalElementInfo != null) && (additionalElementInfo.customGetTextForFunction != null))
            title = additionalElementInfo.customGetTextForFunction(driver, element, title, additionalElementInfo);
        //if ((title != null) && (additionalElementInfo != null) && (a))
        return title;
    }
}
