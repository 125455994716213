import { Type } from 'class-transformer';

import { IViewModel } from '../interfaces/view-model.interface';
import { WorkflowStatePermission } from './workflow-state-permission.model';
import { WorkflowPermissionBase } from './workflow-permission-base.model';
import { IHasIdAndName } from '../interfaces/has-id-and-name.interface';
import { IHasWorkflowPermissions } from '../interfaces/has-workflow-permissions.interface';
import { ICloneAndCopy } from '../interfaces/clone-and-copy';
import { Workflow } from './site-content/workflow.model';

export class WorkflowState implements IViewModel,
    IHasIdAndName, IHasWorkflowPermissions, ICloneAndCopy {
    // Key.
    public id: number = 0;
    public getId(): number {
        return (this.id);
    }

    // Client-only key (not saved in the database).
    public clientId: number = 0;

    // Primitives.
    public workflowId: number;

    public name: string;
    public description: string; // Added 04-16-2022.

    public notify: boolean;
    public showSendNotificationsButton: boolean;

    // Implement IHasIdAndName methods.
    public getName(): string {
        return (this.name);
    }
    public setName(nameParam: string): void {
        this.name = nameParam;
    }

    public static readonly TypeName: string = 'WorkflowState';
    public typeName(): string {
        return (WorkflowState.TypeName);
    }

    public hasDescriptionField(): boolean {
        return true;
    }
    public getDescription(): string {
        return this.description;
    }
    public setDescription(val: string) {
        this.description = val;
    }

    // Define HasIdAndName interface methods that have no meaning for workflow states (as this time).
    public getChangeWorkflowStateDialogTitle(): string { return null; }
    public setChangeWorkflowStateDialogTitle(value: string): void { }

    public getTransitionConfirmationDialogMessage(): string { return null; }
    public setTransitionConfirmationDialogMessage(value: string): void { }

    public getTakeUserToSiteHomePageAfterTransitionApplied(): boolean { return false; }
    public setTakeUserToSiteHomePageAfterTransitionApplied(value: boolean): void { }
    // End HasIdAndName interface methods that have no meaning for workflow states (as this time).

    public getPropertiesDrawerTitle(): string {
        return "Workflow State Properties";
    }

    public isStartState: boolean;
    public isEndState: boolean;

    public stateOrder: number;

    // Define transient/non-persisted values.
    public transientWorkflowStateTypeId: number;
    public transientWorkflowStateNotify: boolean;
    public transientWorkflowStateShowSendNotificationsButton: boolean;

    // Collections.
    @Type(() => WorkflowStatePermission)
    public permissions: WorkflowStatePermission[] = [];

    // Implement interface IHasWorkflowPermissions.
    public getPropertiesTitle(): string {
        let title: string = `Permissions:  ${this.name}`;

        return (title);
    }

    public getPermissionNames(): string[] {
        
        let names: string[] = [
            'View',
            'Edit',
            'Delete',
            'Grant',

            'BuildFolders',
            'BuildFormInstances'
        ];
        
        return (names);
    }

    public getSimplePermissionNames(): string[] {
        let names: string[] =
            [
                'Edit'
            ];

        return (names);
    }

    public getAdvancedPermissionNames(): string[] {
        let names: string[] = [
            'View',
            'Edit',
            'Grant',

            'BuildFolders',
            'BuildForms'
        ];

        return (names);
    }

    public getPermissions(): WorkflowPermissionBase[] {
        // Make sure the following workflow state permissions
        // are set:  workflowId, stateId, and stateName.
        if (this.permissions &&
            (this.permissions.length > 0) &&
            !this.permissions[0].transientParentPropertiesSet) {
            for (let iPermission: number = 0; iPermission < this.permissions.length; iPermission++) {
                let permission: WorkflowStatePermission = this.permissions[iPermission];

                permission.workflowId = this.workflowId;
                permission.stateId = this.id;
                permission.stateName = this.name;

                permission.transientParentPropertiesSet = true;
            }
        }

        // Return the permissions.
        return (this.permissions);
    }

    public get Permissions(): WorkflowPermissionBase[] {
        return (this.getPermissions());
    }

    public get PermissionCount(): number {
        let permissions: WorkflowPermissionBase[] = this.getPermissions();
        let permissionCount: number = (permissions != null ? permissions.length : 0);

        return (permissionCount);
    }

    public addPermission(permissionParam: WorkflowPermissionBase): boolean {
        let permission: WorkflowStatePermission = new WorkflowStatePermission();
        permission.workflowId = this.workflowId;
        permission.stateId = this.id;
        permission.stateName = this.name;
        permission.copyFieldsFrom(permissionParam);

        this.ensurePermissions();
        this.permissions.push(permission);

        return (true);
    }

    public updatePermission(permissionParam: WorkflowPermissionBase): boolean {
        this.ensurePermissions();

        let permissions: WorkflowStatePermission[] = this.permissions.filter(p => p.roleName == permissionParam.roleName);
        let bHavePermission: boolean = (permissions.length === 1);

        if (bHavePermission) {
            let permission: WorkflowStatePermission = permissions[0];
            permission.copyFieldsFrom(permissionParam);
        }

        return (bHavePermission);
    }

    public removePermission(permissionParam: WorkflowPermissionBase): boolean {
        this.ensurePermissions();

        let iNumPermissions = this.permissions.length;

        this.permissions = this.permissions.filter(p => p.roleName != permissionParam.roleName);

        return (this.permissions.length != iNumPermissions);
    }

    public replacePermissionsForRole(roleId: number, workflowStatePermissions: WorkflowStatePermission[]) {
        this.ensurePermissions();

        this.permissions = this.permissions.filter(p => p.roleId != roleId);
        for (let iPermission: number = 0; iPermission < workflowStatePermissions.length; iPermission++) {
            let workflowStatePermission: WorkflowStatePermission = workflowStatePermissions[iPermission];

            this.permissions.push(workflowStatePermission);
        }
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let clone: WorkflowState = new WorkflowState();

        clone.id = this.id;
        clone.clientId = this.clientId;

        clone.workflowId = this.workflowId;

        clone.name = this.name;
        clone.description = this.description;

        clone.notify = this.notify;
        clone.showSendNotificationsButton = this.showSendNotificationsButton;

        clone.isStartState = this.isStartState;
        clone.isEndState = this.isEndState;

        clone.stateOrder = this.stateOrder;

        clone.permissions = [];
        if (this.permissions && (this.permissions.length > 0)) {
            for (let iPerm: number = 0; iPerm < this.permissions.length; iPerm++) {
                let permission: WorkflowStatePermission = this.permissions[iPerm];

                clone.permissions.push(permission);
            }
        }

        return (clone);
    }

    public copy(objectToCopyParam: ICloneAndCopy): void {
        let objectToCopy: WorkflowState = <WorkflowState>objectToCopyParam;

        this.id = objectToCopy.id;
        this.clientId = objectToCopy.clientId;

        this.workflowId = objectToCopy.workflowId;

        this.name = objectToCopy.name;
        this.description = objectToCopy.description;

        this.notify = objectToCopy.notify;
        this.showSendNotificationsButton = objectToCopy.showSendNotificationsButton;

        this.isStartState = objectToCopy.isStartState;
        this.isEndState = objectToCopy.isEndState;

        this.stateOrder = objectToCopy.stateOrder;

        this.permissions = [];
        if (objectToCopy.permissions && (objectToCopy.permissions.length > 0)) {
            for (let iPerm: number = 0; iPerm < objectToCopy.permissions.length; iPerm++) {
                let permission: WorkflowStatePermission = objectToCopy.permissions[iPerm];

                this.permissions.push(permission);
            }
        }

        return;
    }

    // Implement additional public methods.
    public clientIdsEqual(secondWorkflowState: WorkflowState): boolean {
        return (this.clientId == secondWorkflowState.clientId);
    }

    // Implement private helper methods.
    private ensurePermissions(): void {
        if (!this.permissions) {
            this.permissions = [];
        }

        return;
    }
}
