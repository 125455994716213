import { FormInstanceElement } from './form-instance-element.model';
import { ICloneAndCopy } from '../../interfaces/clone-and-copy';

export class LayoutCell implements ICloneAndCopy {
    public position: number = 1;
    public formFieldId: number = 0;
    public name: string = '';
    public gridCssClass: string;
    public isSelected: boolean = false;

    public formInstanceElement: FormInstanceElement = null; // 11-26-2019 NOTE: ADDED BY STUART.

    // Transient cell value (not persisted).
    public transientColSpan: number = 1;
    public transientLayoutCellStyle: string;
    public transientCellOccupiedByFullRowWidthField: boolean = false;

    public transientIsHidden: boolean = false; // 04-11-2023 note:  used for field conditional logic.
    // End transient cell values.

    public get hasContent(): boolean {
        let bHasContent: boolean = ((this.formFieldId > 0) || ((this.name != null) && (this.name.trim() != '')));

        return (bHasContent);
    }

    public get width(): number {
        let strWidth: string = this.gridCssClass.replace('col-', '');
        let iWidth: number = parseInt(strWidth);

        return (iWidth);
    }

    public clearContent(): void {
        this.formFieldId = 0;
        this.name = null;
    }

    public getColSpan(iDefaultColWidth: number,bSaveColspan = true): number {
        let iColSpan: number = this.width / iDefaultColWidth;

        if (bSaveColspan) {
            this.transientColSpan = iColSpan;
        }

        return (iColSpan);
    }

    public get ColSpan(): number {
        return (this.transientColSpan);
    }

    public CellOccupiedByFullRowWidthField(): boolean {
        return this.transientCellOccupiedByFullRowWidthField;
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let newCell: LayoutCell = new LayoutCell();

        newCell.position = this.position;
        newCell.formFieldId = this.formFieldId;
        newCell.name = this.name;
        newCell.gridCssClass = this.gridCssClass;

        newCell.transientColSpan = this.transientColSpan;

        return newCell;
    }

    public copy(objectToCopy: ICloneAndCopy): void {
        let cellToCopy: LayoutCell = <LayoutCell>objectToCopy;

        this.position = cellToCopy.position;
        this.formFieldId = cellToCopy.formFieldId;
        this.name = cellToCopy.name;
        this.gridCssClass = cellToCopy.gridCssClass;

        this.transientColSpan = cellToCopy.transientColSpan;

        return;
    }
}
