import {
    Component,
    OnInit,
    Renderer2,
    Output,
    EventEmitter,
    Type as AngularCoreType
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms'; // Used for Reactive Forms

import { FormFieldMode } from '../form-field-mode.enum';
import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';

// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-label-form-field',
    templateUrl: './label-form-field.component.html',
    styleUrls: ['./label-form-field.component.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: LabelFormFieldComponent,
            multi: true
        }
    ]
})
export class LabelFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    // Properties.
    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();

    private readonly formFieldNames: string[] =
        ['name',
            'fieldGroup',
            'displayName',
            'helpText',
            //'placeholderText',
            'toolTip',
            'displayFormat'
        ];

    private readonly displayFormats: string[] =
        [
            'Align Left',
            'Align Center',
            'Align Right'
        ]

    // Constructor.
    public constructor(private renderer: Renderer2) {
        super();

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshEventProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldNames,
            displayFormatValues: this.displayFormats
        };

        return (hshEventProperties);
    }

    public get canHaveFieldConditionalLogic(): boolean {
        return false;
    }

    // Life cycle methods.
    ngOnInit(): void {
        /*
        let hshEventProperties = {
            component: this,
            formField: this.formField,
            properties: this.formFieldNames,
            displayFormatValues: this.displayFormats
        };
        */
        let hshEventProperties = this.getProperties();

        //this.onInit.emit({ component: this, formField: this.formField, properties: this.formFieldNames });
        this.onInit.emit(hshEventProperties);

        return;
    }

    // Override a base class method.
    public get CanBeReadOnly(): boolean {
        return (false);
    }

    // HTML accessor methods.
    get CssClass(): string {
        let strCssClass: string = 'label-div-align-left';

        switch (this.FormField.displayFormat) {
            case 'Align Center':
                strCssClass = 'label-div-align-center';
                break;

            case 'Align Right':
                strCssClass = 'label-div-align-right';
                break;

            default:
                // Note:  the default value was set in the first line of this method.
                break;
        }

        return (strCssClass);
    }

    // Implement formInstanceElementReceived(), called from my base class.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                this.setupIntegerFormControl(); // SWH:  this cannot be correct (and I wrote it).
            } // if (this.controlType === ControlType.REACTIVE_FORMS)
        } // if ((this.Mode === 'preview') || (this.Mode === 'instance'))

        return;
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement): string {
        //if ((!formInstanceElementParam.transientValueSetFlag) ||
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        }

        // NOTE:  NEED TO REVISIT THIS.
        return (formInstanceElementParam.textValue);
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (LabelFormFieldComponent);
    }
}
