import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { plainToClass } from 'class-transformer';

import { environment } from '../../../environments/environment';
//import * as parseUri from 'parse-uri';
import { DataCollection } from '../models/site-content/data-collection.model';
import { CollectApiServiceBase } from './collect-api-base.service';
import { Form } from '../models/form-builder/form.model';
import { Workflow } from '../models/site-content/workflow.model';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { Folder } from '../models/site-content/folder.model';
import { BrowserStorageService } from './browserstorage.service';
import { ListViewEnum, ListViewHelper } from '../components/list-view/list-view.helper';
import { BannerText } from '../models/banner/banner-text.model';

@Injectable()
export class DataCollectionService extends CollectApiServiceBase<DataCollection> {
    private searchCount: number = 0;

    public currentSite: Promise<DataCollection>;

    public constructor(
        http: HttpClient,
        progressIndicatorService: ProgressIndicatorService,
        private localStorageService: BrowserStorageService) {
        super(http, progressIndicatorService, environment.apiUrl, 'datacollection', DataCollection)
    }

    public toggleIsFavorite(id: number): Promise<DataCollection> {
        let url = `${this.url}/${this.endpoint}/ToggleIsFavorite/${id}`;
        return this.http.put<DataCollection>(url, {}).toPromise().then(x => {
            return x;
        });
    }

    //VNEXT-1066: KLW - Properties of the Banner
    public updateBannerText(bannerText: BannerText){ 

        let url: string = `${this.url}/${this.endpoint}/UpdateBannerText`; 

        return this.http.put<BannerText>(url, bannerText)
            .toPromise()
            .then(res => {
            })
            .catch(this.handleError);

    }

    // pharvey ~ added 9/13/2021 for vnext-104
    public getWithPagination(offset: number, maxToReturn: number, filterTerm: string = null, progressConfig: ProgressConfig = ProgressConfig.default()) {
        let msg = ((filterTerm != null) && (filterTerm.trim() != '') ? 'Searching sites ...' : 'Loading sites ...');
        this.updateProgress(25, 50, msg);

        this.searchCount += 1;

        let sortObj = ListViewHelper.getSortObjFor(ListViewEnum.SITE_LIST) || ListViewHelper.DEFAULT_SORT;
        
        let url: string = `${this.url}/${this.endpoint}/getWithPagination/${offset}/${maxToReturn}/${sortObj.active}/${sortObj.direction}`;
        if (filterTerm) {
            url = `${url}?q=${filterTerm}&c=${this.searchCount}`;
        }

        //VNEXT-221: track search count and discard stale responses
        return this.http.get(url, { observe: "response" })
            .toPromise()
            .then(res => {
                this.progressIndicatorService.updateProgress(100, 100, '');

                let data: DataCollection[] = [];
                let sc = res.body['searchcount'];
                if (!sc) sc = "1";

                if (parseInt(sc) > 1 && parseInt(sc) < this.searchCount) {
                    return;
                }

                res.body['siteVMs'].forEach(x => {
                    let obj = this.formatResponse(x);
                    data.push(obj);
                });
                return data;
            });
    }

    public getAllForAdmin() {
        let url: string = `${this.url}/${this.endpoint}/getAllForAdmin`;
        this.updateProgress(25, 50, "Getting all Sites (this could take a while)...");
        return this.http.get<DataCollection[]>(url)
            .toPromise()
            .then(res => {
                let data: DataCollection[] = [];
                res.forEach(x => {
                    let obj = this.formatResponse(x);
                    data.push(obj);
                });
                this.progressIndicatorService.updateProgress(100, 100, '');
                return data;
            })
    }

    public removeAsSiteAdmin(siteId: number): Promise<any> {
        let url = `${this.url}/${this.endpoint}/RemoveFromSiteAdmin/${siteId}`;

        return this.http.delete(url)
            .toPromise()
            .then(res => {
                if (!res) {
                    alert("Could not remove you. Perhaps you are a Site Admin via a Group?  (This means the backend returned false.)");
                }
            });
    }

    public addAsSiteAdmin(siteId: number): Promise<any> {
        let url = `${this.url}/${this.endpoint}/AddAsSiteAdmin/${siteId}`;

        return this.http.post(url, {})
            .toPromise()
            .then(res => {
                if (!res) {
                    alert("Could not add you! (This means the backend returned false.)");
                }
            });
    }

    public transitionWorkflow(rootFolder: Folder, progressConfig: ProgressConfig = ProgressConfig.default()) {
        let url: string = `${this.url}/${this.endpoint}/${rootFolder.dataCollectionId}/Transition`;

        let msg = progressConfig.msgDuring || this.progressMsg('Updating');
        this.updateProgress(50, 75, msg);
        return this.http.put<DataCollection>(url, rootFolder)
            .toPromise()
            .then(res => {
                msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                this.updateProgress(100, 100, msg);

                return this.formatResponse(res);
            })
            .catch(this.handleError);
    }

    public enableDisableBetaFeatures(dataCollectionId: number, enableBetaFeatures: boolean, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<DataCollection> {
        let url: string =
            (enableBetaFeatures ?
                `${this.url}/${this.endpoint}/${dataCollectionId}/EnableBetaFeatures` :
                `${this.url}/${this.endpoint}/${dataCollectionId}/DisableBetaFeatures`);
        let firstStatus: string = (enableBetaFeatures ? 'Enabling site beta features' : 'Disabling site beta features');
        let secondStatus: string = (enableBetaFeatures ? 'Enabled' : 'Disabled');

        let msg = progressConfig.msgDuring || firstStatus;
        this.updateProgress(50, 75, msg);
        return this.http.put<DataCollection>(url, dataCollectionId)
            .toPromise()
            .then(res => {
                msg = progressConfig.msgOnComplete || secondStatus;
                this.updateProgress(100, 100, msg);

                return this.formatResponse(res);
            })
            .catch(this.handleError);
    }

    // overriding so as to store the returned site in memmory
    public get(id: number, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<DataCollection> {
        let url = `${this.url}/${this.endpoint}/${id}`;

        let msg = 'Loading Site ...';
        this.updateProgress(25, 50, msg);

        let lStartTime: number = performance.now();

        return this.currentSite = this.http.get<DataCollection>(url)
            .toPromise()
            .then(response => {
                this.progressIndicatorService.updateProgress(100, 100, '');

                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let obj = this.formatResponse(response);

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);

                return obj;
            })
            .catch(this.handleError);
    }

    public getFormsBySite(siteId: number, format: string = null): Promise<Form[]> {
        let url = `${this.url}/${this.endpoint}/getFormsBySite/${siteId}`;
        if (format != null) url += `/${format}`;

        this.updateProgress(25, 50, 'Loading forms...');

        let lStartTime: number = performance.now();

        return this.http
            .get<Form[]>(url)
            .toPromise()
            .then(response => {

                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let data: Form[] = [];

                response.forEach(x => {
                    let obj = this.formatFormResponse(x);

                    obj.childCount = obj.formInstanceCount;
                    obj.childCountTitle = 'template is used by one form';
                    if (obj.childCount > 1) obj.childCountTitle = 'template is used by ' + obj.childCount + ' forms';

                    data.push(obj);
                });

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);

                this.hideProgressIndicator();

                return data;
            })
            .catch(this.handleError);
    }

    public getFormsForFolder(siteId: number, folderId: number, format: string = null): Promise<Form[]> {
        let url = `${this.url}/${this.endpoint}/GetFormsForFolder/${siteId}/${folderId}`;
        if (format != null) url += `/${format}`;

        this.updateProgress(25, 50, 'Loading forms...');

        let lStartTime: number = performance.now();

        return this.http
            .get<Form[]>(url)
            .toPromise()
            .then(response => {

                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let data: Form[] = [];

                response.forEach(x => {
                    let obj = this.formatFormResponse(x);
                    data.push(obj);
                });

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);

                this.hideProgressIndicator();

                return data;
            })
            .catch(this.handleError);
    }

    public getFormForFolder(formTemplateId: number, folderId: number): Promise<Form> {
        let url = `${this.url}/${this.endpoint}/GetFormForFolder/${formTemplateId}/${folderId}`;

        let lStartTime: number = performance.now();

        return this.http
            .get<Form>(url)
            .toPromise()
            .then(response => {
                let formTemplate: Form = response != null ? this.formatFormResponse(response) : null;

                return formTemplate;
            })
            .catch(this.handleError);
    }

    public getWorkflowsBySite(siteId: number): Promise<Workflow[]> {
        let url: string = `${this.url}/${this.endpoint}/getWorkflowsBySite/${siteId}`;

        let lStartTime: number = performance.now();

        this.updateProgress(25, 50, 'Loading workflows ...');

        return this.http
            .get<Workflow[]>(url)
            .toPromise()
            .then(response => {
                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let workflows: Workflow[] = [];

                response.forEach(wf => {
                    let workflow = this.formatWorkflowResponse(wf);
                    workflows.push(workflow);
                });

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);

                this.hideProgressIndicator();

                return workflows;
            })
            .catch(this.handleError);
    }

    public CountAccessibleSites(): Promise<number> {
        let url: string = `${this.url}/${this.endpoint}/count`;

        let lStartTime: number = performance.now();

        return this.http
            .get<number>(url)
            .toPromise()
            .then(response => {
                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                return response;
            })
            .catch(this.handleError);
    }

    public getFavorites(): Promise<DataCollection[]> {
        let url = `${this.url}/${this.endpoint}/getFavorites`;

        let lStartTime: number = performance.now();

        let msg = 'Loading favorite sites ...';
        this.updateProgress(25, 50, msg);

        return this.http
            .get<DataCollection[]>(url)
            .toPromise()
            .then(response => {
                this.progressIndicatorService.updateProgress(100, 100, '');

                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let data: DataCollection[] = [];

                response.forEach(dc => {
                    let obj = this.formatResponse(dc);
                    data.push(obj);
                });

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);

                return data;
            })
            .catch(this.handleError);
    }

    public getRecentlyAccessed(maxObjectsToReturn: number = 25): Promise<DataCollection[]> {
        let url = `${this.url}/${this.endpoint}/getRecentlyAccessed/${maxObjectsToReturn}`;

        let lStartTime: number = performance.now();

        let msg = "Loading sites you've recently viewed...";
        this.updateProgress(25, 50, msg);

        return this.http
            .get<DataCollection[]>(url)
            .toPromise()
            .then(response => {
                this.progressIndicatorService.updateProgress(100, 100, '');

                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let data: DataCollection[] = [];

                response.forEach(dc => {
                    let obj = this.formatResponse(dc);
                    data.push(obj);
                });

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);

                return data;
            })
            .catch(this.handleError);
    }

    public setPermissionsModelFlag(dataCollectionId: number, useSimplifiedPermissionsModel: boolean): Promise<DataCollection> {
        let url: string = `${this.url}/${this.endpoint}/${dataCollectionId}/SetPermissionsModelFlag/${useSimplifiedPermissionsModel}`;

        return this.http.put<DataCollection>(url, dataCollectionId)
            .toPromise()
            .then(res => {
                return this.formatResponse(res);
            })
            .catch(this.handleError);
    }

    public enableStructureForEndUser(dataCollectionId: number, enableStructureForEndUser: boolean, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<DataCollection> {
        let url: string = `${this.url}/${this.endpoint}/${dataCollectionId}/SetEnableStructureForEndUser/${enableStructureForEndUser}`;

        return this.http.put<DataCollection>(url, dataCollectionId)
            .toPromise()
            .then(res => {
                return this.formatResponse(res);
            })
            .catch(this.handleError);
    }

    // 04-15-2021:  will re-enable the following method after completing site copies
    //              (as an export will be the first part of a site copy).
    /*
    public exportSite(siteId: number): Promise<DataCollection> {
        let url = `${this.url}/${this.endpoint}/exportSite/${siteId}`;

        let lStartTime: number = performance.now();

        return this.http
            .get<DataCollection>(url)
            .toPromise()
            .then(exportedDataCollection => {
                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);

                return (exportedDataCollection);
            })
            .catch(this.handleError);
    }
    */

    // Protected methods.
    protected formatFormResponse(data: Form): Form {
        let obj = plainToClass(Form, data);

        return obj;
    }

    protected progressMsg(verb: string): string {
        return super.progressMsg(verb, 'site');
    }

    // Private methods.
    private formatWorkflowResponse(data: Workflow): Workflow {
        let workflow = plainToClass(Workflow, data);

        return workflow;
    }

}
