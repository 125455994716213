import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { plainToClass } from 'class-transformer';

import { environment } from '../../../environments/environment';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { CollectApiServiceBase } from './collect-api-base.service';
import { Form } from '../models/form-builder/form.model';
import { AsyncJob } from '../models/async-job.model';
import { XmlFileFormatEnum } from '../enums/xml-file-format.enum';

@Injectable()
export class ImportMetadataService extends CollectApiServiceBase<Form> {
    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'importMetadata', Form)
    }

    // Service methods.
    public importFormDefinitions(formDefinitionFiles: File[],
        targetDataCollectionId: number,
        xmlFileFormat: XmlFileFormatEnum) {
        let url: string = `${this.url}/${this.endpoint}/importFormDefinitionsInto/${targetDataCollectionId}`;

        // Package file data and create an HTTP POST request.
        const formData: FormData = this.packageDataFiles(formDefinitionFiles);

        var httpHeaders: HttpHeaders = new HttpHeaders();
        const req = new HttpRequest('POST', url, formData, {
            reportProgress: true,

            headers: httpHeaders
        });

        return this.http.post<AsyncJob>(url, formData)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    // Helper methods.
    private formatAsyncJobResponse(data: AsyncJob): AsyncJob {
        var obj = plainToClass(AsyncJob, data);

        return (obj);
    }

    private packageDataFiles(dataFiles: File[]): FormData {
        const formData: FormData = new FormData();
        for (var iFile: number = 0; iFile < dataFiles.length; iFile++) {
            let dataFile: File = dataFiles[iFile];
            formData.append('file', dataFile, dataFile.name);
        }

        return (formData);
    }
}
