import { TokenCode } from './token-code.enum';
import { IScriptableFunction } from '../../interfaces/iscriptable-function';

export class Tuple {
    // Instance data.
    //private opCode: number = -1;
    private opCode: TokenCode = null;
    private leftValue: any = null;
    private rightValue: any = null;

    // Constructor.
    //public constructor(opCodeParam: number, leftValParam: any, rightValParam: any = null) {
    public constructor(opCodeParam: TokenCode, leftValParam: any, rightValParam: any = null) {
        this.opCode = opCodeParam;
        this.leftValue = leftValParam;
        if (rightValParam != null)
            this.rightValue = rightValParam;
    }

    // Getter methods.
    //public get OpCode(): number {
    public get OpCode(): TokenCode {
        return this.opCode;
    }
    public get LeftValue(): any {
        return this.leftValue;
    }
    public get RightValue(): any {
        return this.rightValue;
    }
}

export class FunctionCallTuple extends Tuple {
    public constructor(public scriptableFunction: IScriptableFunction, public params: string[]) {
        super(TokenCode.TOKEN_FUNCTION_CALL, scriptableFunction, params);
    }
}
