//import { IInputsValue } from '../../interfaces/component-scripting/iinputs-value';
import { ScriptableFunctionBase } from './scriptable-function-base';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
import { FunctionExecutionStatus, IScriptableFunction } from '../../interfaces/iscriptable-function';

export class SetInputValueScriptableFunction extends ScriptableFunctionBase implements IScriptableFunction {
    public constructor() {
        super();
    }

    public get callableName(): string {
        return 'setInputValue';
    }

    public execute(testActionService: ITestActionRecorderService, params: string[]): FunctionExecutionStatus {
        let executionStatus: FunctionExecutionStatus = FunctionExecutionStatus.Failed;

        if ((params != null) && (params.length >= 4) && (params.length <= 5)) {
            let elementTitle: string = params[0];
            let elementMetadataKey: string = params[1];
            let elementSubtype: string = params[2];
            let inputValue: string = params[3];
            let addDateTimeSuffix = (params.length == 5) && (params[4] != null) && (params[4].toLowerCase() == 'true') ? true : false;

            testActionService.setInputValue(elementTitle, elementMetadataKey, elementSubtype, inputValue, addDateTimeSuffix);
            executionStatus = FunctionExecutionStatus.Completed;
        }

        return executionStatus;
    }
}
