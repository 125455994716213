export class DataCollectionStatus {
    public static readonly SETUP: string = 'Setup';
    public static readonly ACTIVE: string = 'Active';
    public static readonly CLOSED: string = 'Closed';

    public id: number = 0;

    public name: string;
    public createdAt: Date;
}
