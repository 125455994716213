
export class SiteFormFieldConstraintProperties {
    // General constraint properties.
    public constraintId: number;

    public constraintType: string;
    public constraintName: string;
    public constraintValue: string; // This value is constraint-type-specific.

    // 'UseCount' indicates the number of times this constraint has been used in its site.
    public useCount: number;

    // Constraint-type-specific constraint properties.
    public selectOptionsValue: string; // Used by both the original select options constraint and the new the simple list values constraint.
    //public selectOptionsConstarintValue: string; // Use by the simple list values constraint.
    public regularExpressionValue: string;
    public regularExpressionMessage: string;
    public jsonConfig: string; // Used by both the original cascading drop-down constraint and the new cascading list values constraint.
    public minValue: number;
    public maxValue: number;
    public minDate: string;
    public maxDate: string;

    public editable: boolean;
}
