import { Type, plainToClass } from 'class-transformer';

import { IViewModel } from '../../interfaces/view-model.interface';
import { FormInstanceElement } from '../form-builder/form-instance-element.model';
import { Form } from '../form-builder/form.model';
//import { FolderInstance } from './folder-instance.model'; // 03-04-2020 note:  per below, this needs to be phased out.
import { Folder } from '../site-content/folder.model';
import { WorkflowTransition } from '../workflow-transition.model';
// 02-02-2021 note:  at some point, making class Folder
//                   implement interface IListItem.
import { IListItem } from '../../interfaces/ilist-item.interface';
import { Workflow } from '../site-content/workflow.model'; //TEAMS-424: KLW - Import workflow model
import { ItemTypeEnum } from '../../enums/item-type.enum';
import { IHasIdAndNameAndWorkflowId } from '../../interfaces/has-id-and-name.interface';
import { IListItemBase } from '../ilist-item-base.model';
import { IconFormatEnum } from '../../enums/icon-format.enum';

export class FormInstance extends IListItemBase implements IViewModel, IListItem, IHasIdAndNameAndWorkflowId{
    // Constructor.
    public constructor(_id: number = 0) {
        super();

        this.id = _id;
    }

    // Define properties.
    // Key field.
    public id: number = 0;
    public explicitType: string = 'form-instance';

    // Foreign key fields.
    public dataCollectionId: number; 
    public formId: number;
    public folderId: number;

    // Primitive properties.
    // Property 'name' has been moved into base class IListItemBase.

    public description: string;

    // 02-02-2021 note:  added the following properties that were
    //                   defined in the server-side view model class,
    //                   FormInstanceVM, but not in this class.
    public ownerType: string;
    public owner: string;

    public currentWorkflowStateId: number;
    public currentWorkflowState: string;
    public showSendNotificationsButton: boolean;

    // start - new for VNEXT-1184
    public currentWorkflowStateIsStartState: boolean;
    public skipValidationOnUpdateContent: boolean;
    public hasFailedClientValidation: boolean;
    // end - new for VNEXT-1184

    //TEAMS-178: KLW - This is for the state order of the current state
    public currentWorkflowStateOrder: number;

    public formInstanceOrder: number;
    // End 02-02-2021 changes.

    // Workflow transition, if any, that the user has selected.
    public selectedWorkflowTransition: string;
    //TEAMS-178: KLW - This is needed to hold the end state order for the selected workflow transition
    public selectedWorkflowTransitionEndStateId: number;

    public createdBy: string;
    public createdByUserName: string;
    public createdDate: Date;
    public modifiedBy: string;
    public modifiedByUserName: string;
    public modifiedDate: Date;
    public contentModifiedBy: string;
    public contentModifiedByUserName: string;
    public contentModifiedDate: Date;

    public version: number;
    public originalFormInstanceId: number;

    // Permission flags.
    public canEdit: boolean;
    public canView: boolean;
    public canGrant: boolean;
    public canDelete: boolean;
    public canTransition: boolean;
    public canEditMetadata: boolean;

    public accessLostAfterTransition: boolean;

    public isFavorite: boolean = false; // Added 01-07-2021.
    public favoriteTitle: string; // Added 01-07-2021.

    public subscription: number;

    //TEAMS-424: KLW - Create collection for Workflows
    @Type(() => Workflow)
    public siteWorkflows: Workflow[];
    public workflowId: number;

    public folderName: string;
    public folderPath: string;

    public magicStructureDefinitionId: number;
    public magicFormInstanceId: number;

    //VNEXT-310: KLW - Variable to set if form action buttons are disabled during any saves
    public formButtonsDisabled: boolean = false;

    // Define transient properties not saved on the server.
    public transientFormInstanceIsSaving: boolean = false;

    // Related objects.
    @Type(() => Form)
    public form: Form;

    @Type(() => Folder)
    public folder: Folder;

    // Collection properties.
    @Type(() => FormInstanceElement)
    public formInstanceElements: FormInstanceElement[] = [];

    // Workflow transitions that the user can perform on this form instance.
    @Type(() => WorkflowTransition)
    public workflowStateTransitions: WorkflowTransition[] = [];

    public isReference: boolean;

    public isReferenced: boolean;

    public isReferencedCount: number;

    public rowVersion: string;

    @Type(() => FormInstance)
    public referencedFormInstance: FormInstance;

    public referencedFormInstanceId: number;

    @Type(() => FormInstance)
    public referencedBy: FormInstance;
    
    // Implement assignFrom.
    public assignFrom(cloneObj: any): any {
        for (let attribut in cloneObj) {
            this[attribut] = cloneObj[attribut];
        }

        return (this);
    }

    // Load from de-serialized network data.
    public static loadFromDeserializedNetworkData(data: FormInstance): FormInstance {
        let formInstance: FormInstance = new FormInstance();

        formInstance.loadFromDeserializedNetworkData(data);

        return (formInstance);
    }

    private loadFromDeserializedNetworkData(data: FormInstance): void {
        // Copy primitive attributes.
        this.id = data.id;

        this.dataCollectionId = data.dataCollectionId;
        this.formId = data.formId;
        this.folderId = data.folderId;
        this.formInstanceOrder = data.formInstanceOrder;

        this.name = data.name;

        this.canEdit = data.canEdit;
        this.canView = data.canView;
        this.canGrant = data.canGrant;
        this.canDelete = data.canDelete;
        this.canTransition = data.canTransition;
        this.canEditMetadata = data.canEditMetadata;
        this.accessLostAfterTransition = data.accessLostAfterTransition;

        //VNEXT-692
        this.isReference = data.isReference; 
        this.referencedFormInstance = data.referencedFormInstance;
        this.referencedFormInstanceId = data.referencedFormInstanceId;

        this.isFavorite = data.isFavorite; // Added 01-10-2021.
        this.favoriteTitle = data.favoriteTitle; // Added 01-10-2021.

        this.subscription = data.subscription;

        this.magicStructureDefinitionId = data.magicStructureDefinitionId;
        this.magicFormInstanceId = data.magicFormInstanceId;

        this.version = data.version;
        this.originalFormInstanceId = data.originalFormInstanceId;

        this.workflowId = data.workflowId;
        this.siteWorkflows = [];
        if ((data.siteWorkflows != null) && (data.siteWorkflows.length > 0)) {
            for (let iWorkflow: number = 0; iWorkflow < data.siteWorkflows.length; iWorkflow++) {
                let siteWorkflow: Workflow = plainToClass(Workflow, data.siteWorkflows[iWorkflow]);
                this.siteWorkflows.push(siteWorkflow);
            }
        }

        // Copy non-primitive objects.
        this.form = plainToClass(Form, data.form);     

        this.folder = plainToClass(Folder, data.folder);

        this.formInstanceElements = [];
        for (let iFIE: number = 0; iFIE < data.formInstanceElements.length; iFIE++) {
            let fieData = data.formInstanceElements[iFIE];

            //let formInstanceElement: FormInstanceElement = plainToClass(FormInstanceElement, fieData);
            let formInstanceElement: FormInstanceElement = FormInstanceElement.loadFromDeserializedNetworkData(fieData);

            this.formInstanceElements.push(formInstanceElement);
        }

        this.workflowStateTransitions = [];
        for (let iTransition: number = 0; iTransition < data.workflowStateTransitions.length; iTransition++) {
            let transitionData = data.workflowStateTransitions[iTransition];

            let transition: WorkflowTransition = plainToClass(WorkflowTransition, transitionData);

            this.workflowStateTransitions.push(transition);
        }

        this.referencedFormInstance = plainToClass(FormInstance, data.referencedFormInstance);

        this.currentWorkflowState = data.currentWorkflowState;
        this.showSendNotificationsButton = data.showSendNotificationsButton;

        //TEAMS-178: KLW - Set the current state order of the selected state
        this.currentWorkflowStateOrder = data.currentWorkflowStateOrder;

        this.currentWorkflowStateIsStartState = data.currentWorkflowStateIsStartState;

        //TEAMS-424: KLW - Set the workflows after the API call
        this.siteWorkflows = data.siteWorkflows;
        this.workflowId = data.workflowId;

        //history
        this.createdBy = data.createdBy;
        this.createdByUserName = data.createdByUserName;
        this.createdDate = data.createdDate;
        this.modifiedBy = data.modifiedBy;
        this.modifiedByUserName = data.modifiedByUserName;
        this.modifiedDate = data.modifiedDate;

        this.rowVersion = data.rowVersion;

        return;
    }

    // IListItem methods.
    public getId(): number {
        return (this.id);
    }
    public setId(idParam: number): void {
        this.id = idParam;

        return;
    }

    public getTitle(): string {
        let title: string =
            (this.description != null ? `Click to view ${this.name} (${this.description})` : super.getTitle());

        return (title);
    }

    public isMagicStructure(): boolean {
        return this.magicStructureDefinitionId != null;
    }

    public userCanDelete(): boolean {
        return (this.canDelete);
    }

    public getStatus(): string {
        return this.currentWorkflowState;
    }

    public getExtraInfo(): string {
        return '';
    }

    public getValue(property: string): string {
        property = property.toLowerCase();
        if (property == 'template') {
            return this.form.name;
        } else if (property == 'status') {
            return this.getStatus();
        }
        else {
            return '';
        }
    }
    public isOriginalVersion: boolean;

    // Always returns the original/current FormInstance's id, even if this is an older version
    public getOriginalVersionId(): number {
        return this.version == 0 ? this.id : this.originalFormInstanceId;
    }

    //TEAMS-424: KLW - set the object type
    public static readonly TypeName: string = ItemTypeEnum.FORM_INSTANCE;
    public getType(): string {
        return (FormInstance.TypeName);
    }

    public getPosition(): number {
        return (this.formInstanceOrder);
    }

    public canBeDeleted(): boolean { // Note:  this will have to be fixed to use
        //        a value returned by the server.
        return (true);
    }

    public getIconType(): IconFormatEnum {
        // Note:  will enable the following conditional line after reviewing the new magic form icon with others.
        //return (this.magicStructureDefinitionId != null ? IconFormatEnum.SVG_ICON : IconFormatEnum.MAT_ICON);
        return IconFormatEnum.MAT_ICON;
    }
    public getIcon(): string {
        // Note:  will enable the following conditional line after reviewing the new magic form icon with others.modified
        //return (this.isReference || this.referencedFormInstance ? 'exit_to_app' : 'article');
        let icon: string = 'article';
        if (false && (this.magicStructureDefinitionId != null)) // We are not yet ready to use this condition.
            icon = 'magic-form';
        else if (this.referencedFormInstance)
            icon = 'exit_to_app';
        else if (this.isReferencedCount > 9)
            icon = 'filter_9_plus'
        else if (this.isReferencedCount > 0)
            icon = 'filter_' + this.isReferencedCount
        return icon;
    }

    public getUniqueId(): string { // For interface IListItem
        let uniqueId: string = `${this.id}-${this.getType()}`;

        return (uniqueId);
    }

    public getChildCount(): number {
        throw new Error('Method not implemented.');
    }

    // If modifiedByUserName is set, return that
    public getModifiedBy(): string {
        let result = this.contentModifiedByUserName != '' ? this.contentModifiedByUserName : this.contentModifiedBy;
        return result;
    }
    public getModifiedDate(): Date {
        return this.contentModifiedDate;
    }

    public update(obj: any, type: string, icon?: string, position?: number): void {
        // 03-19-2021 note:  restoring the implementation of this code.
        this.id = obj.getId();

        if ((obj.name != null) && (obj.name != this.name)) {
            this.name = obj.name;
        }
        this.modifiedBy = obj.modifiedBy;
        this.modifiedDate = obj.modifiedDate;
        this.currentWorkflowState = obj.currentWorkflowState;
        this.description = obj.description; // Added 03-01-2022.

        return;
    }

    public findAndUpdateFrom(items, obj: any): void {
        throw ('FormInstance.findAndUpdateFrom():  I am not yet implemented ... do I need to be implemented?');
    }

    // Implement interface IHasIdAndName methods.
    public getName(): string {
        return (this.name);
    }
    public setName(nameParam: string): void {
        this.name = nameParam;
    }

    public typeName(): string {
        return (FormInstance.TypeName);
    }

    public hasDescriptionField(): boolean {
        return true;
    }
    public getDescription(): string {
        return this.description;
    }
    public setDescription(val: string) {
        this.description = val;
    }

    // Define HasIdAndName interface methods that have no meaning for this class.
    public getChangeWorkflowStateDialogTitle(): string { return null; }
    public setChangeWorkflowStateDialogTitle(value: string): void { }

    public getTransitionConfirmationDialogMessage(): string { return null; }
    public setTransitionConfirmationDialogMessage(value: string): void { }

    public getTakeUserToSiteHomePageAfterTransitionApplied(): boolean { return false; }
    public setTakeUserToSiteHomePageAfterTransitionApplied(value: boolean): void { }

    public getPropertiesDrawerTitle(): string {
        return null;
    }
    // End HasIdAndName interface methods that have no meaning for this class.

    public getParentId(): number {
        return this.folderId;
    }

    public getWorkflowId(): number {
        return this.workflowId;
    }

    public replaceFormInstanceElementValue(formFieldId: number, formInstanceElement: FormInstanceElement): void {
        if (this.formInstanceElements != null) {
            for (let index: number = 0; index < this.formInstanceElements.length; index++) {
                let searchFormInstanceElement: FormInstanceElement = this.formInstanceElements[index];

                if (searchFormInstanceElement.formFieldId == formInstanceElement.formFieldId) {
                    //searchFormInstanceElement.copyFieldValuesFrom(formInstanceElement);
                    this.formInstanceElements.splice(index, 1, formInstanceElement);
                    break;
                }
            }
        }
    }
}
