import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { DecimalFieldDefLogic } from './decimal-field-def-logic';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';

export class CurrencyFieldDefLogic extends DecimalFieldDefLogic implements IFieldDefinitionLogic { 
    // Constructor.
    public constructor() {
        super();
    };

    // Note:  the following methods, previsouly implemented here, are now implemented in my base class:
    //
    //            pasteDataForInto()
    //            compareAscendingFor()
    //            formatValueForCompare()

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        let displayValue: string = super.getDisplayValue(formFieldParam, formInstanceElementParam, gridRow, processingPhase, gridRowColumnDefs);
        displayValue = `$ ${displayValue}`;
        return displayValue;
    }

    public hasDollarSignPrefix(): boolean {
        return true;
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }

    public getNumericValueFrom(value: string): number {
        let numericValue: number = 0;

        if ((value != null) && (value.trim() != '')) {
            let valueWithoutDollarSignAndCommas: string = value.replace('$', '').replace(/,/g, '');
            numericValue = parseFloat(valueWithoutDollarSignAndCommas);
        }

        return numericValue;
    }
}
