import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { plainToClass } from 'class-transformer';

import { environment } from '../../../environments/environment';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { CollectApiServiceBase } from './collect-api-base.service';
import { DataCollection } from '../models/site-content/data-collection.model';
import { AsyncJob } from '../models/async-job.model';
import { XmlFileFormatEnum } from '../enums/xml-file-format.enum';
import { CopySiteAndTransformSettings } from '../models/copy-site-and-transform-settings.model';

@Injectable()
export class CopySiteService extends CollectApiServiceBase<DataCollection> {
    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'copySite', DataCollection)
    }

    // Service methods.
    public copySite(siteId: number, siteCopyName: string): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/copy/${siteId}/as/${siteCopyName}`;

        return this.http.get<DataCollection>(url)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatResponse(res);
            })
            .catch(this.handleError);
    }

    public copyAndTransformSite(settings: CopySiteAndTransformSettings): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/copyAndTransform`;

        return this.http.post<string>(url, settings)
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public exportAndTransformSite(settings: CopySiteAndTransformSettings): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/exportAndTransform`;

        return this.http.post<string>(url, settings)
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public exportSite(siteId: number, exportStructureOnly:boolean, componentClassName: string): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/export/${siteId}/forAppComponent/${componentClassName}?exportStructureOnly=${exportStructureOnly}`;

        return this.http.get<AsyncJob>(url)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    public getDownloadExportedSiteUrl(asyncJob: AsyncJob): string {
        let url = `${this.url}/${this.endpoint}/GetExportedSiteZipFile/${asyncJob.jobKey}`;

        return(url);
    }

    public importSite(siteDataZipFile: File, importSiteName: string, ignoreValidationErrors: boolean): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/importsiteas/${importSiteName}?ignoreValidationErrors=${ignoreValidationErrors}`;

        // Package file data and create an HTTP POST request.
        const formData: FormData = new FormData();
        formData.append('file', siteDataZipFile, siteDataZipFile.name);

        let httpHeaders: HttpHeaders = new HttpHeaders();
        const req = new HttpRequest('POST', url, formData, {
            reportProgress: true,
            headers: httpHeaders
        });

        return this.http.post<AsyncJob>(url,formData)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    // Note:  the following method is being moved into class ExportMetadataService.
    /*
    public exportFormDefinition(formId: number): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/exportFormDefinition/${formId}`;

        return this.http.get<AsyncJob>(url)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }
    */

    // Note:  the following method is being moved into class ImportMetadataService.
    /*
    public importFormDefinitions(formDefinitionFiles: File[],
        targetDataCollectionId: number,
        xmlFileFormat: XmlFileFormatEnum) {
        let url: string = `${this.url}/${this.endpoint}/importFormDefinitionsInto/${targetDataCollectionId}`;

        // Package file data and create an HTTP POST request.
        const formData: FormData = this.packageDataFiles(formDefinitionFiles);

        let httpHeaders: HttpHeaders = new HttpHeaders();
        const req = new HttpRequest('POST', url, formData, {
            reportProgress: true,

            headers: httpHeaders
        });

        return this.http.post<AsyncJob>(url, formData)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }
    */

    /*
    public exportFormInstances(formId: number, xmlFileFormat: XmlFileFormatEnum): Promise<AsyncJob> {
        let url = (xmlFileFormat == XmlFileFormatEnum.VERBOSE_FORMAT ?
            `${this.url}/${this.endpoint}/exportFormInstances/${formId}` :
            `${this.url}/${this.endpoint}/exportSimplifiedFormInstances/${formId}`);

        return this.http.get<AsyncJob>(url)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }
    */

    public getDownloadExportedFormInstanceDataUrl(asyncJob: AsyncJob): string {
        let url = `${this.url}/${this.endpoint}/GetExportedFormInstanceDataZipFile/${asyncJob.jobKey}`;

        return (url);
    }

    // Note:  the following method is being moved into class ImportDataService.
    /*
    public importFormInstances(formInstanceDataZipFile: File,
        xmlFileFormat: XmlFileFormatEnum,
        folderId: number,
        optionalFormId: number = 0): Promise<AsyncJob> {
        // Determine the target URL.
        let url: string = null;

        if (optionalFormId > 0) {
            url = (xmlFileFormat == XmlFileFormatEnum.VERBOSE_FORMAT ?
                `${this.url}/${this.endpoint}/importFormInstancesInto/${folderId}/usingForm/${optionalFormId}` :
                `${this.url}/${this.endpoint}/importSimplifiedFormInstancesInto/${folderId}/usingForm/${optionalFormId}`);
        } else {
            url = (xmlFileFormat == XmlFileFormatEnum.VERBOSE_FORMAT ?
                `${this.url}/${this.endpoint}/importFormInstancesInto/${folderId}` :
                `${this.url}/${this.endpoint}/importSimplifiedFormInstancesInto/${folderId}`);
        }

        // Package file data and create an HTTP POST request.
        const formData: FormData = new FormData();
        formData.append('file', formInstanceDataZipFile, formInstanceDataZipFile.name);

        let httpHeaders: HttpHeaders = new HttpHeaders();
        const req = new HttpRequest('POST', url, formData, {
            reportProgress: true,

            headers: httpHeaders
        });

        return this.http.post<AsyncJob>(url, formData)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }
    */

    // Note:  the following method is being moved into new service class ImportDataService.
    /*
    public importFormInstanceFiles(formInstanceDataFiles: File[], folderId: number): Promise<AsyncJob> {
        let url: string = `${this.url}/${this.endpoint}/importSimplifiedFormInstanceFilesInto/${folderId}`

        // Package file data and create an HTTP POST request.
        const formData: FormData = this.packageDataFiles(formInstanceDataFiles);

        let httpHeaders: HttpHeaders = new HttpHeaders();
        const req = new HttpRequest('POST', url, formData, {
            reportProgress: true,

            headers: httpHeaders
        });

        return this.http.post<AsyncJob>(url, formData)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }
    */

    // Helper methods.
    private formatAsyncJobResponse(data: AsyncJob): AsyncJob {
        let obj = plainToClass(AsyncJob, data);

        return (obj);
    }

    /*
    private packageDataFiles(dataFiles: File[]): FormData {
        const formData: FormData = new FormData();
        //for (let iFile: number = 0; iFile < formInstanceDataFiles.length; iFile++) {
        for (let iFile: number = 0; iFile < dataFiles.length; iFile++) {
            //let dataFile: File = formInstanceDataFiles[iFile];
            let dataFile: File = dataFiles[iFile];
            formData.append('file', dataFile, dataFile.name);
        }

        return (formData);
    }
    */
}
