import { ImportDataFromExcelDialogAction } from "../../services/excel-import.service";


export enum ImportDataServiceNames {
    ImportSiteStructureService = 'ImportSiteStructureService',
    ImportSiteFormInstancesService = 'ImportSiteFormInstancesService',
    ImportFormInstanceGridsService = 'ImportSiteGridsService',
    ImportValuesToExistingFormInstancesService = 'ImportValuesToExistingFormInstancesService'
}

export class GenericImportDataFromExcelSpreadsheet {
    // Note:  generally only one or two of the following Id properties will be set.
    public dataCollectionId: number; // A property that might or might not be set.
    public formInstanceId: number; // A property that might or might not be set.
    public formFieldId: number; // A property that might or might not be set.
    public formInstanceElementId: number; // A property that might or might not be set.

    // Note:  the 'serviceName' property must be specified.
    public serviceName: string; // The name of the service to perform much of the download.

    // The object being imported to may be a site, a form instance, etc -- return it based on the service
    public getObjectId(): number {
        let objectId: number = 0;
        if (this.serviceName == ImportDataFromExcelDialogAction.ImportSiteStructure)
            objectId = this.dataCollectionId;
        else if (this.serviceName == ImportDataFromExcelDialogAction.ImportFormInstanceGrids)
            objectId = this.dataCollectionId;
        else if (this.serviceName == ImportDataFromExcelDialogAction.ImportSiteFormInstances)
            objectId = this.dataCollectionId;
        else if (this.serviceName == ImportDataFromExcelDialogAction.ImportValuesToExistingFormInstances)
            objectId = this.dataCollectionId;

        return objectId;
    }
}
