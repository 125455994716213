export class CustomColumnProperty {
    public id: string;
    public propertyName: string;

    // colConfig is a string of the form "property(PropertyName)"
    constructor(colConfig: string) {
        let pattern = /^property\([a-zA-Z\t\s\-_]+\)$/;
        if (!pattern.test(colConfig)) {
            throw `It looks like you used a value in a ColumnsConfig @Input property to <app-list-view> with an invalid property format. Expected format: "${pattern}". Supplied value: "${colConfig}"`;
        }

        let propertyName = colConfig.split('(')[1].split(')')[0];
        this.propertyName = propertyName;
        this.id = colConfig;
    }
}
