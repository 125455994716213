import {
    Component, EventEmitter, OnInit, Output, Renderer2, Type as AngularCoreType,
    ViewChild
} from '@angular/core';
import {
    UntypedFormControl, NG_VALUE_ACCESSOR
} from '@angular/forms';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { IAutoCompleteItemData } from '../../../interfaces/iautocomplete-item-data.interface';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import {
    FormInstanceElement,
    FormInstanceElementValueTypeEnum
} from '../../../models/form-builder/form-instance-element.model';
import { AutocompleteComponent } from '../../autocomplete/autocomplete.component';
import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormModeEnum } from '../../../enums/form-mode.enum';

// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-multi-drop-down-form-field',
    templateUrl: './multi-drop-down-form-field.component.html',
    styleUrls: ['./multi-drop-down-form-field.component.scss', '../form-fields.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MultiDropDownFormFieldComponent,
            multi: true
        }
    ]
})
export class MultiDropDownFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    // Properties.
    @Output() onInit = new EventEmitter();

    @ViewChild('multidropdownauto') autoCompleteObject: AutocompleteComponent;
    @ViewChild('multiSelect') multiSelect;

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.SELECT_OPTIONS,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            FormFieldPropertyEnum.AUTOCOMPLETE, //VNEXT-384: KLW - Implementing Autocomplete             
            FormFieldPropertyEnum.AUTOCOMPLETE_STARTSWITH, //VNEXT-519: KLW - Refinements to the Type ahead functionality
            FormFieldPropertyEnum.AUTOCOMPLETE_CONTAINS, //VNEXT-519: KLW - Refinements to the Type ahead functionality
            FormFieldPropertyEnum.REQUIRED, //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
            FormFieldPropertyEnum.GRID_COLUMN_WIDTH
        ];

    private dropDownOptionsFormControl: UntypedFormControl = null;
    private derivedDropDownOptions: string[] = [];
    private _autoCompleteItemDataOptions: string[] = [];
    private selectionsOpen: boolean = false;

    private readonly placeholderSelectOption: string = 'Set up options using properties';

    // Constructor.
    public constructor(private renderer: Renderer2) {
        super();

        return;
    }

    // Lifecycle methods.
    public ngOnInit(): void {
        // Output my properties to my containing component.
        let hshProperties = this.getProperties();

        this.onInit.emit(hshProperties);

        // First setup my drop-down options.
        this.rebuildDropDownOptionsData();

        // Now setup my form control.
        //KLW - Do NOT remove or move this, this is required for the form field to show properly in the form template design
        //DO NOT MOVE OR DELETE this.setupFormControl() in ngOnInit!!!!
        this.setupFormControl();

        return;
    }

    // Implement base class abstract methods.
    public getProperties(): any {
        let hshProperties = {
            component: this,
            formField: this.formField,
            properties: this.formFieldProperties,

            propertyUpdateRequired: true
        };

        return (hshProperties);
    }

    public getFormFieldClass(): AngularCoreType<any> {
        return (MultiDropDownFormFieldComponent);
    }

    public get DropDownOptions(): string[] {
        return (this.derivedDropDownOptions);
    }

    public get DropDownOptionsFormControl(): UntypedFormControl {
        return (this.dropDownOptionsFormControl);
    }

    public get canHaveFieldConditionalLogic(): boolean {
        return false;
    }


    public get ShowRemoveAll(): boolean {

        //if (this.DropDownOptionsFormControl.value != null)
        //    return this.DropDownOptionsFormControl.value.length > 0;
        //else
        //    false;

        return false;
    }

    public get DropDownReadyToDisplay(): boolean {
        let bReady: boolean = (this.dropDownOptionsFormControl !== null);

        return (bReady);
    }


    public onRemove(option: string) {
        const options = this.dropDownOptionsFormControl.value as string[];
        this.removeFirst(options, option);
        this.dropDownOptionsFormControl.setValue(options); // To trigger change detection
    }

    public removeAllChips() {
        this.dropDownOptionsFormControl.setValue([]);
    }

    private removeFirst<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    public selectChange = (event: any) => {
        this.DropDownOptionsFormControl.setValue(event);
    };

    // Handle some form field event methods.
    public propertyUpdated(formField: FormField, propertyName: string): void {
        if ((propertyName == FormFieldPropertyEnum.SELECT_OPTIONS) ||
            (propertyName == FormFieldPropertyEnum.ALL)) {
            // Set a 'reminder' to rebuild my checkbox titles.
            //
            // Note:  do not want to do this now as it triggers
            //        an endless loop of Angular callbacks.
            setTimeout(() => {
                this.rebuildDropDownOptionsData();
            }, 0);
        }

        return;
    }

    // Handle updates made outside the control.
    public formInstanceElementUpdated(formInstanceElement: FormInstanceElement): void {
        /*
        if ((this.controlData != null) && (this.controlData.formControl != null))
            this.controlData.formControl.setValue(formInstanceElement.textValue);
        */
    }

    public formFieldUpdated(): void {
        // 03-14-2024 note:  added this method so it can be called by the field conditional
        //                   logic to indicate that a component's form field has been updated.

        // Make sure the form control's disabled state agrees with the form field's 'isReadOnly' attribute.
        this.toggleFormControlDisabledBasedOnReadOnlyAttribute()
    }

    public openSelections() {
        this.multiSelect.open();
        this.selectionsOpen = true;
    }

    public get SelectionsOpen() {
        return this.selectionsOpen;
    }

    public multiSelectBlur() {
        this.handleValidationOnBlur();
    }

    public multiSelectClosed() {
        this.selectionsOpen = false;
    }

    public multiSelectOpened() {
        this.selectionsOpen = true;
    }

    public closeSelections() {
        this.multiSelect.close();
        this.selectionsOpen = false;
    }

    // Handle getting this field's form instance element.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                // If I have no value assigned but
                // have a default value, apply it now.

                // Note:  this form field does not
                //        yet handle default values.  


                // Rebuild my select options data.
                this.rebuildDropDownOptionsData();

                // Setup my Reactive Forms data structure.
                this.setupFormControl();

                //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
                if (this.mode != FormModeEnum.DESIGN) {
                    this.controlData.formControl.markAsTouched();
                }

                //VNEXT-384: KLW - Implementing Autocomplete
                if (this.autoCompleteObject != null)
                    if (this.SelectOptions != null) {

                        var toPass: IAutoCompleteItemData[] = [];

                        this.SelectOptions.forEach(option => {

                            if (this._autoCompleteItemDataOptions.includes(option)) {
                                toPass.push({ item: option, selected: true } as IAutoCompleteItemData);
                            }
                        });

                        //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
                        this.autoCompleteObject.setIsRequired(this.FormField.required);
                        this.autoCompleteObject.setData(toPass);
                        this.autoCompleteObject.setFIE_ID(this.formField.id);
                        this.autoCompleteObject.setHasStartsWith(this.formField.autocomplete_StartsWith);
                        this.autoCompleteObject.setHasContains(this.formField.autocomplete_Contains);
                    }
            }
        }

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                // If I have no value assigned but
                // have a default value, apply it now.

                // Note:  this form field does not
                //        yet handle default values.  


                // Rebuild my select options data.
                this.rebuildDropDownOptionsData();

                // Setup my Reactive Forms data structure.
                this.setupFormControl();


                //VNEXT-384: KLW - Implementing Autocomplete
                if (this.autoCompleteObject != null)
                    if (this.SelectOptions != null) {

                        var toPass: IAutoCompleteItemData[] = [];

                        this.SelectOptions.forEach(option => {

                            if (this._autoCompleteItemDataOptions.includes(option)) {
                                toPass.push({ item: option, selected: true } as IAutoCompleteItemData);
                            }
                        });

                        //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
                        this.autoCompleteObject.setIsRequired(this.FormField.required);
                        this.autoCompleteObject.setData(toPass);
                        this.autoCompleteObject.setFIE_ID(this.formField.id);
                        this.autoCompleteObject.setHasStartsWith(this.formField.autocomplete_StartsWith);
                        this.autoCompleteObject.setHasContains(this.formField.autocomplete_Contains);
                    }
            }
        }

        return;
    }

    public pseudoStatic_getDisplayValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): string {
        let value: string = '';

        if (formInstanceElementParam.childFormInstanceElements &&
            (formInstanceElementParam.childFormInstanceElements.length > 0)) {
            let iNumValuesSelected: number = 0;

            for (let iChild: number = 0; iChild < formInstanceElementParam.childFormInstanceElements.length; iChild++) {
                let child: FormInstanceElement = formInstanceElementParam.childFormInstanceElements[iChild];

                if (!child.isDeleted) {
                    if (iNumValuesSelected > 0) {
                        value += ',';
                    }

                    value += child.textValue;
                    iNumValuesSelected++;
                }
            }
        }

        return (value);
    }

    public handleSelectionChange() {
        this.getFormInstanceElement().UserUpdatedData = true;
    }

    private formGroupName: string = 'MultiSelect_Control';

    // Implement private helper methods.
    private setupFormControl(): void {
        // Parse/package existing data, if any.
        var astrExistingValues: string[] = []; //['Apple', 'Orange'];

        if (this.FormInstanceElement) {
            if (this.FormInstanceElement.childFormInstanceElements &&
                (this.FormInstanceElement.childFormInstanceElements.length > 0)) {
                for (let iChild: number = 0; iChild < this.FormInstanceElement.childFormInstanceElements.length; iChild++) {
                    let childFormInstanceElement: FormInstanceElement =
                        this.FormInstanceElement.childFormInstanceElements[iChild];

                    astrExistingValues.push(childFormInstanceElement.textValue);
                    this._autoCompleteItemDataOptions.push(childFormInstanceElement.textValue);
                }
            }
        }

        // Setup/configure my form control.
        let hshControlProperties = {
            value: astrExistingValues,
            disabled: this.ReadOnly //false
        }

        //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
        // this.createFormGroup(this.formGroupName, hshControlProperties);
        // this.dropDownOptionsFormControl = <FormControl>this.controlData.formGroup.get(this.formGroupName);

        this.setupMultiSelectFormGroup('MultiSelect_Control', hshControlProperties);
        this.dropDownOptionsFormControl = <UntypedFormControl>this.controlData.formGroup.get('MultiSelect_Control');

        //let hey = new FormControl(hshControlProperties);

        // If we are in preview or instance mode,
        // listen for/subscribe to value changes.
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            this.dropDownOptionsFormControl.valueChanges
                .subscribe(astrValues => {
                    this.processSelectedValues(astrValues);
                }); // subscribe 
        }

        return;
    }

    //VNEXT-384: KLW - Implementing Autocomplete
    private processSelectedValues(passedValues: string[]) {
        //console.log("Need to code this block ...");
        // Rebuild my child form instance elements
        // based on the current selections.
        if (!this.FormInstanceElement.childFormInstanceElements) {
            this.FormInstanceElement.childFormInstanceElements = [];
        }

        // See if any existing child elements
        // need to be marked as deleted.
        for (let iChildElement: number = 0;
            iChildElement < this.FormInstanceElement.childFormInstanceElements.length;
            iChildElement++) {
            let childFormInstanceElement: FormInstanceElement =
                this.FormInstanceElement.childFormInstanceElements[iChildElement];

            let arrFoundValue: string[] =
                passedValues.filter(v => v == childFormInstanceElement.textValue);
            let bValueFound: boolean = (arrFoundValue && (arrFoundValue.length == 1));

            if (!bValueFound) {
                childFormInstanceElement.isDeleted = true;
            }
        }

        // For any child element marked as deleted
        // that does not have an Id, just remove it.
        this.FormInstanceElement.childFormInstanceElements =
            this.FormInstanceElement.childFormInstanceElements
                .filter(fie => (fie.id > 0) || (!fie.isDeleted));

        // Update or add each value in the control.
        if (passedValues && (passedValues.length > 0)) {
            for (let iValue: number = 0; iValue < passedValues.length; iValue++) {
                let strValue: string = passedValues[iValue];

                // See if this value already exists
                // as a child form instance element.
                let arrExistingChildFormInstanceElement: FormInstanceElement[] =
                    this.FormInstanceElement.childFormInstanceElements.filter(fie => fie.textValue == strValue);

                if (arrExistingChildFormInstanceElement && (arrExistingChildFormInstanceElement.length == 1)) {
                    let existingChildFormInstanceElement: FormInstanceElement =
                        arrExistingChildFormInstanceElement[0];

                    existingChildFormInstanceElement.isDeleted = false;
                } else {
                    let childFormInstanceElement =
                        new FormInstanceElement();
                    childFormInstanceElement.ValueType =
                        FormInstanceElementValueTypeEnum.TypeText;
                    childFormInstanceElement.TextValue = strValue;

                    this.FormInstanceElement.childFormInstanceElements.push(childFormInstanceElement);
                } // if-else
            } // for
        } // if

        // Did a user enter this value?
        if (!this.FormInstanceElement.transientInSetupFlag) {
            // A user entered this value.
            //this.FormInstanceElement.transientValueSetFlag = true;
            this.FormInstanceElement.UserUpdatedData = true;

            this.userEnteredValue();
        }

        return;
    }

    //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
    protected userEnteredValue() {
        if (this.controlData.formControl.errors) {
            if (this.FormInstanceElement.childFormInstanceElements != null) {
                if (this.FormInstanceElement.childFormInstanceElements.length > 0) {
                    this.controlData.formControl.setErrors({ 'required': false });
                }
                else {
                    this.controlData.formControl.setErrors({ 'required': true });
                }
            }
        }
    }

    private rebuildDropDownOptionsData(): void {
        this.derivedDropDownOptions = [];

        // Evaulate select options.
        let arrDropDownOptions: string[] = this.SelectOptions;

        //VNEXT-519: KLW - Refinements to the Type ahead functionality
        if (!this.formField.autocomplete) {
            if (arrDropDownOptions && arrDropDownOptions.length > 0) {
                for (let iOption: number = 0; iOption < arrDropDownOptions.length; iOption++) {
                    let dropDownOption: string = arrDropDownOptions[iOption];

                    // If the last value is
                    // a blank, do not add it.
                    let bIsLastValue: boolean = (iOption === arrDropDownOptions.length - 1);

                    if (bIsLastValue &&
                        ((!dropDownOption) || (dropDownOption.trim() === ''))) {
                        continue;
                    }

                    this.derivedDropDownOptions.push(dropDownOption);
                }
            } else {
                // Add a placeholder select option.
                this.derivedDropDownOptions.push(this.placeholderSelectOption);
            }
        }

        // If we are in preview or instance
        // mode, we have more work to do.
        /*
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            // TO DO:  CODE THIS BLOCK.
        }
        */


        return;
    }
}
