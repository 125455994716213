import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';
import { AlertDialogComponent, AlertDialogModel } from '../../shared/dialogs/alert/alert-dialog.component';
import { UITestCenterDialogManager } from '../../shared/dialogs/ui-test-center/ui-test-center.dialog';
import { ImpersonatableUser } from '../../shared/models/impersonatable-user.model';
import { QueryParamsService } from '../../shared/services/query-params.service';
import { UserImpersonationService } from '../../shared/services/user-impersonation.service';
import { CurrentUserService } from "../current-user.service";

@Component({
    selector: 'loginout',
    templateUrl: './loginout.component.html',
    styleUrls: ['./loginout.component.scss']
})
export class LogInOutComponent implements OnInit {
    public constructor(
        public currentUserService: CurrentUserService,
        public impersonationService: UserImpersonationService,
        private queryParamsService: QueryParamsService,
        private router: Router,
        public dialog: MatDialog) {
    }

    private myUrl = '';
    private myLocation: Location;
    public impersonatableUsers: ImpersonatableUser[];
    public isLoggedIn: boolean = false;

    public ngOnInit(): void {
        this.myUrl = window.location.href;
        this.myLocation = window.location;

        if (!this.currentUserService.browserOk) {
            this.currentUserService.browserCheck().then(response => { });
        }
        if (!this.currentUserService.isForcedLogout) {
            this.loginButtonHidden = true;
            this.currentUserService.login().then(response => {
                this.isLoggedIn = true;
                this.impersonationService.getImpersonatableUsers().then(x => {
                    this.impersonatableUsers = x;
                });
            }).catch(err => {
                if (!this.currentUserService.isIdleLogout) {
                    this.logIn(true);
                }
            });
        }
        else {
            // PJH - 7/1/2024 - commented out as part of work on VNEXT-591
            //this.currentUserService.setForcedLogout(false);
        }
    }

    public logIn(login: boolean = true) {
        //console.log('LoginOut.logIn() INSTANCE VERSION! UrlBeforeLogout=' + localStorage.getItem('UrlBeforeLogout'));
        this.currentUserService.setForcedLogout(false);
        let startingUrl = this.setStartingUrl();
        this.currentUserService.setIdleLogout(false);
        this.currentUserService.setForcedLogout(false);
        let url = environment.loginSiteUrl + (environment.loginSiteUrl.endsWith("/") ? "" : "/") + environment.loginPage + "?ReturnUrl=" + encodeURIComponent(startingUrl);
        window.open(url, '_self');
    }
    
    public logOut(): void {
        this.isLoggedIn = false;
        this.currentUserService.setForcedLogout(true);
        this.currentUserService.setIdleLogout(false);
        this.currentUserService.logout(false);
        var url = environment.loginSiteUrl + (environment.loginSiteUrl.endsWith("/") ? "" : "/") + environment.logoutPage + "?ReturnUrl=" + encodeURIComponent(this.myLocation.origin);

        window.open(url, '_self');
    }

    public userinfo() {
        this.router.navigate(['/admin/userinfo']);
    }

    public about() {
        this.router.navigate(['/admin/about']);
    }

    public whatsnew() {
        this.router.navigate(['/admin/newfeatures']);
    }

    public sendFeedback() {
        let technicalInfo = null;
        let correlationId = null;
        let message = 'Enter your feedback or report an issue for review by the OMB Collect development team.';
        let title = 'Send Feedback or Report an Issue'
        this.dialog.open(AlertDialogComponent, {
            disableClose: true,
            maxWidth: "600px",
            data: new AlertDialogModel(title, message, technicalInfo, correlationId)
        });
    }

    // Getter method(s).
    public get ShowAppChrome(): boolean {
        return !this.queryParamsService.HideAppChrome;
    }

    // The static variable has to be there to be set in the static method below
    // when the user logs out, and in landing-page-component.ts when the user times
    // out.  But the instance property is there so it can be used in the html.

    public static hideLoginButton: boolean = false;

    public get loginButtonHidden() {
        return LogInOutComponent.hideLoginButton;
    }

    public set loginButtonHidden(value: boolean) {
        LogInOutComponent.hideLoginButton = value;
    }

    public impersonate(userId: string) {
        if (userId == null) {
            alert(`There was a problem. UserId is {userId}`);
            return;
        }
        this.impersonationService.impersonate(userId).then(x => {
            location.reload();
        });
    }

    public stopImpersonating() {
        this.impersonationService.stopImpersonating().then(x => {
            location.reload();
        });
    }

    public doingImpersonation() {
        // Bit of a hacky implementation but ok for mow
        // Relies on the fact that during impersonation, firstName is of the 
        // format "<Full Name of Actual User> impersonating <First Name of Impersonated User>"
        return this.currentUserService.user.firstName.indexOf('impersonating') > -1;
    }

    //VNEXT-1066: KLW - Property to get site name
    public get SiteName() {
        return environment.sitename;
    }

    public get UserIsSystemAdmin(): boolean {
        return this.currentUserService.user.isSystemAdmin;
    }

    public openUITestCenter(): void {
        let manager: UITestCenterDialogManager = new UITestCenterDialogManager();

        const dialogRef = manager.openDialogUsing(this.dialog);
    }
    
    // Helpers...
    private setStartingUrl() {
        let url = new URL(this.myUrl);
        let lastUrl = localStorage.getItem('urlBeforeLogout');
        if (url.pathname != '/' || !lastUrl) {
            return this.myUrl;
        } else {
            return lastUrl;
        }
    }
}
