<div>
    <div *ngIf="this.Mode === 'design'">
        <mat-form-field appearance="outline" [floatLabel]="'always'">
            <mat-label>{{this.DisplayName}}</mat-label>
            <textarea matInput
                      placeholder="{{this.PlaceholderText}}"
                      rows="{{this.FormField.displayRows}}"
                      disabled>{{this.FormField.instructionsText}}</textarea>
        </mat-form-field>
    </div>
</div>
