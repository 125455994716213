<kendo-grid #grid="remoteBindingDirectiveRef"
            appCrossSiteUserFavoriteFormsRemoteBinding
            [height]="600"
            [pageSize]="this.PageSize"
            [skip]="this.Skip"
            [sort]="this.Sort"
            scrollable="virtual"
            [rowClass]="Helper.applyClickedRowClassToClickedRow"
            (cellClick)="Helper.setIndexOfClickedRow($event)"
            [(filter)]="Helper.Filters"
            [rowHeight]="20"
            [sortable]="SortSettings"
            [filterable]="true">

    <kendo-grid-command-column [width]="80" class="!k-text-center">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="command-column-buttons">
                <button class="button" kendoButton kendoGridFocusable (click)="openForm(dataItem)" title="Open Site">Open</button>
                <mat-icon class="command-btn" kendoButton (click)="toggleFave(dataItem)" title="Toggle Fave">{{favIcon(dataItem)}}</mat-icon>
            </div>
        </ng-template>
    </kendo-grid-command-column>

    <ng-container *ngFor="let columnDef of this.ColumnDefs; let iColIndex=index">
        <kendo-grid-column field="{{columnDef.fieldName}}"
                           title="{{columnDef.title}}"
                           [filterable]="true">
        </kendo-grid-column>
    </ng-container>

</kendo-grid>
