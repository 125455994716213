<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>

<div *ngIf="(this.Mode === 'design') || (this.Mode === 'preview') || (this.Mode === 'instance')" class="main-content-div">
    <div class="label-div">
        <mat-label class="field-label">{{this.DisplayName}}</mat-label>
    </div>
    <!-- Define a place to inject instructions, if any. -->
    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>

    <mat-toolbar class="short-toolbar noprint">
        <!--VNEXT-614: KLW - Adding conditional to determine if control should be disabled-->
        <button [disabled]="this.Disabled" mat-button (click)="this.addNewComment()">
            <mat-icon aria-hidden="false" aria-label="Add comment" class="add-row-mat-icon" title="Add Comment">add</mat-icon>
        </button>
    </mat-toolbar>

    <div class="{{getCommentTableCssClasses(true)}}">
        <table mat-table
               multiTemplateDataRows
               [dataSource]="this.CommentsListing"
               class="{{this.getCommentTableCssClasses(false)}} grid-border">
            <!-- Define columns. -->
            <!-- Comment Index -->
            <ng-container matColumnDef="commentNumber" [sticky]="this.columnIsSticky('commentNumber')">
                <th mat-header-cell *matHeaderCellDef class="header-cell column column-number">#</th>
                <td mat-cell *matCellDef="let element; let i = dataIndex" class="row-cell column column-number">
                    {{i + 1}}
                </td>
            </ng-container>

            <!-- Comment Text -->
            <ng-container matColumnDef="comment" [sticky]="this.columnIsSticky('comment')">
                <th mat-header-cell
                    *matHeaderCellDef
                    class="header-cell column column-comment">
                    Comment
                </th>
                <td mat-cell *matCellDef="let element" class="row-cell column column-comment">
                    <ng-container *ngIf="!element.editingEnabled">
                        <textarea matInput
                                  rows="{{this.TextAreaRowCount}}"
                                  cols="80"
                                  [disabled]="true"
                                  [value]="element.comment"
                                  class="comment-field-input-disabled"></textarea>
                    </ng-container>
                    <ng-container *ngIf="element.editingEnabled">
                        <textarea matInput
                                  rows="{{this.TextAreaRowCount}}"
                                  cols="80"
                                  [disabled]="false"
                                  [(ngModel)]="element.comment"
                                  class="comment-field-input-enabled"></textarea>
                    </ng-container>
                </td>
            </ng-container>

            <!-- Commenter/Date -->
            <ng-container matColumnDef="commenter" [sticky]="this.columnIsSticky('commenter')">
                <th mat-header-cell
                    *matHeaderCellDef
                    class="header-cell column column-commenter">
                    Commenter
                </th>
                <td mat-cell *matCellDef="let element" class="row-cell column column-commenter">
                    {{element.commenter}}<br />
                    {{element.commentTimestamp | date:'medium'}}
                </td>
            </ng-container>

            <!-- Viewable By -->
            <ng-container matColumnDef="viewableBy"
                          [sticky]="this.columnIsSticky('viewableBy')">
                <th mat-header-cell
                    *matHeaderCellDef
                    class="header-cell column column-viewable-by">
                    Viewable By
                </th>
                <td mat-cell *matCellDef="let element"
                    class="row-cell column column-viewable-by">
                    <span class="show-viewable-by-rows" (click)="this.showViewableByRowsFor(element)">{{element.ViewableByText}}</span>
                </td>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="action"
                          [sticky]="this.columnIsSticky('action')">
                <th mat-header-cell *matHeaderCellDef class="header-cell column column-actions">
                    Action
                </th>
                <td mat-cell *matCellDef="let element" class="row-cell column column-actions">
                    <div class="actions-div">
                        <mat-checkbox [ngClass]="element.isSelected ? 'selected-mat-checkbox' : 'unselected-mat-checkbox'"
                                      [(ngModel)]="element.isSelected"
                                      [disabled]="this.CommentSelectionDisabled">{{element.SelectedText}}</mat-checkbox>

                        <button *ngIf="this.EnableCommentEditing"
                                mat-stroked-button
                                [disabled]="this.EditCommentButtonDisabled"
                                class="edit-comment-button"
                                (click)="this.editComment(element)">
                            <mat-icon [ngClass]="this.EditCommentButtonDisabled ? 'row-action-disabled' : 'row-action'"
                                      title="{{this.getEditCommentTooltip(element)}}"
                                      matTooltip="{{this.getEditCommentTooltip(element)}}"
                                      [matTooltipPosition]="this.TooltipPosition">create</mat-icon>
                        </button>

                        <button mat-stroked-button
                                [disabled]="this.TrashCanButtonDisabled"
                                class="trash-can-button"
                                (click)="this.deleteComment(element)">
                            <mat-icon [ngClass]="this.TrashCanButtonDisabled ? 'row-action-disabled' : 'row-action'"
                                      title="{{this.TrashCanTooltip}}"
                                      matTooltip="{{this.TrashCanTooltip}}"
                                      [matTooltipPosition]="this.TooltipPosition">delete</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <!-- Define a user/group detail cell, a second row template. -->
            <ng-container matColumnDef="viewableByMembers"
                          [sticky]="this.columnIsSticky('viewableByMembers')">
                <td mat-cell *matCellDef="let element"
                    class="viewable-by-row-cell"
                    style="border-bottom-width: 0px;">
                    <div>
                        <ng-container *ngIf="!this.HasViewableByUsersAndGroups(element)">
                            <span>Currently, anyone who can view this Form can see this comment. To limit who can see it, select it by checking "Select" and then use the picker below to add user(s)/group(s).</span>
                        </ng-container>

                        <ng-container *ngIf="this.HasViewableByUsersAndGroups(element)">
                            <table style="{{this.CommentViewableByTableStyle}}">
                                <tr *ngFor="let viewableBy of this.ViewableByUsersAndGroups(element)"
                                    style="{{this.CommentViewableByTableRowStyle}}">
                                    <td class="viewable-by-text-td"><span>{{this.ViewableUserOrGroup(viewableBy)}}</span></td>
                                    <td class="viewable-by-type-td"><span>{{this.ViewableUserOrGroupType(viewableBy)}}</span></td>
                                    <td class="viewable-by-action-td">
                                        <mat-icon *ngIf="this.ViewableByCanMoveUp(element,viewableBy)"
                                                  class="move-viewable-by-up-mat-icon"
                                                  (click)="this.moveViewableByUp(element,viewableBy)"
                                                  [matTooltipPosition]="this.TooltipPosition"
                                                  matTooltip="{{this.MoveViewableByUpTooltip(viewableBy)}}">arrow_upward</mat-icon>
                                    </td>
                                    <td>
                                        <mat-icon *ngIf="this.ViewableByCanMoveDown(element,viewableBy)"
                                                  class="move-viewable-by-down-mat-icon"
                                                  (click)="this.moveViewableByDown(element,viewableBy)"
                                                  [matTooltipPosition]="this.TooltipPosition"
                                                  matTooltip="{{this.MoveViewableByDownTooltip(viewableBy)}}">arrow_downward</mat-icon>
                                    </td>
                                    <td>
                                        <mat-icon class="delete-viewable-by-mat-icon"
                                                  (click)="this.deleteViewableBy(element,viewableBy)"
                                                  [matTooltipPosition]="this.TooltipPosition"
                                                  matTooltip="{{this.DeleteViewableByTooltip(viewableBy)}}">delete</mat-icon>
                                    </td>
                                </tr>
                            </table>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <!-- Define the header row and data row template. -->
            <mat-header-row *matHeaderRowDef="this.DisplayedColumns; sticky: this.HeaderRowIsSticky"></mat-header-row>

            <mat-row *matRowDef="let row; columns: this.DisplayedColumns;"></mat-row>
            <mat-row *matRowDef="let row; columns: ['viewableByMembers']"
                     class="{{this.CommentViewableByClass(row)}}"
                     style="{{this.CommentViewableByStyle(row)}}">
            </mat-row>
        </table>
    </div>

    <div class="mat-picker-div">
        <!--VNEXT-614: KLW - Adding conditional to determine if control should be disabled-->
        <app-max-picker class="max-picker-component"
                        [scope]="'usersAndGroups'"
                        [pickerDisabled]="this.PickerInputDisabled || this.Disabled"
                        [userGroupPickerLabel]="this.AddViewableByUserOrGroupLabel"
                        [maxMemberStyle]="'width: 59%;'"
                        [addButtonName]="this.AddViewableByUserOrGroupButtonTitle"
                        [addButtonAdditionalStyle]="this.AddViewableByButtonAdditionalStyle"
                        [addButtonFlyoverText]="this.AddViewableByButtonFlyoverText"
                        [addButtonCanEnable]="this.AddViewableByButtonCanEnable"
                        (addButtonClicked)="this.addViewableByButtonClicked($event)"></app-max-picker>

    </div>
</div>
