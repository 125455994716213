import {
    AfterViewInit, Component, EventEmitter, OnInit, Output, Type as AngularCoreType, ViewChild, SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { TooltipPosition } from '@angular/material/tooltip';
import { CurrentUserService } from '../../../../security/current-user.service';
import { AlertDialogModel } from '../../../../shared/dialogs/alert/alert-dialog.component';
import { ContactInfo, ContactsFormFieldData, IContactInfo } from '../../../models/contacts/contacts-form-field.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { PickerItem } from '../../../models/picker-item.model';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { ItemTypeEnum } from '../../../enums/item-type.enum';
import { MatDialog } from '@angular/material/dialog';
import { EmailService } from '../../../services/email.service';
import { SafeUrl } from '@angular/platform-browser';
import { ConfirmationDialogComponent, ConfirmationDialogModel } from '../../../dialogs/confirmation/confirmation-dialog.component';
import { ConfirmationDialogEnums } from '../../../enums/confirmation-dialog.enum';


@Component({
    selector: 'app-contacts-form-field',
    templateUrl: './contacts-form-field.component.html',
    styleUrls: ['./contacts-form-field.component.scss', '../form-fields.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ContactsFormFieldComponent,
            multi: true
        }
    ]
})
export class ContactsFormFieldComponent extends FormFieldBaseComponent implements OnInit, AfterViewInit {
    // Properties.
    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();

    private numDisplayRows: number = 1;
    private contactsData: ContactsFormFieldData = new ContactsFormFieldData();
    private selfAddedFlag: boolean = false;

    @ViewChild(MatTable) table: MatTable<IContactInfo>;

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.BLANK_VALUE,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            FormFieldPropertyEnum.NUMBER_OF_DISPLAY_ROWS,
            FormFieldPropertyEnum.SHOW_CONTACTS_PROPERTIES
        ];

    private displayedColumns: string[] = [];

    //VNEXT-614: KLW - Adding property getter to determine if control should be disabled
    public get Disabled() {
        return this.readOnly;
    }

    private readonly numDisplayRowsValues: string[] = ['1', '2', '3', '4', '5'];

    // Constructor.
    public constructor(private currentUserService: CurrentUserService,
        private emailService: EmailService, //VNEXT-811: KLW - Needed for emailing contacts
        private dialog: MatDialog) { // Not yet using:  rendererParam: Renderer2
        super();
    }

    // Life cycle methods.
    public ngOnInit(): void {

        this.propertyUpdated(this.FormField, FormFieldPropertyEnum.NUMBER_OF_DISPLAY_ROWS);

        if (this.Mode === 'design') {
            if ((this.FormField.contactFieldNames == null) || (this.FormField.contactFieldNames.trim() == ''))
                this.FormField.contactFieldNames = 'name|email|phone';

            this.createExampleContacts();
        } else if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            // Note:  existing contacts, if any, will be loaded in method formInstanceElementReceived().
        }

        // Derived columns to display.
        this.deriveDisplayedColumns();

        // Emit 'onInit' to my parent component.
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);
    }

    public ngAfterViewInit(): void {
        return; // Can see @ViewChild() values when this method is called.
    }

    // Methods called by my HTML code.
    public columnIsSticky(colName: string): boolean {
        // Note:  this does not need to be a hard code value, but it is.
        return true;
    }
    public get HeaderRowIsSticky(): boolean {
        return true;
    }

    public get NumDisplayRows(): number {
        return this.numDisplayRows;
    }

    public getContactTableCssClasses(isDiv: boolean): string {
        //VNEXT-951: KLW - Set default number of contact rows to 5
        let cssClasses: string =
            (isDiv ?
                'mat-table-div contacts-mat-table-div-5-row' :
                'mat-elevation-z4 contacts-mat-table contacts-mat-table-5-row');

        if (this.FormField.displayRows != null) {
            switch (this.FormField.displayRows) {
                case '2':
                case '3':
                case '4':
                case '5':
                    cssClasses =
                        (isDiv ?
                            `mat-table-div contacts-mat-table-div-${this.FormField.displayRows}-rows` :
                            `mat-elevation-z4 contacts-mat-table contacts-mat-table-${this.FormField.displayRows}-rows`);
                    break;

                default:
                    break;
            }
        }

        return cssClasses;
    }

    public get ContactsListing(): IContactInfo[] {
        return this.contactsData.contacts;
    }

    public get DisplayedColumns(): string[] {
        return this.displayedColumns;
    }
    public getColumnStyle(columnName: string): string {
        let style: string = 'font-weight: 900;';

        if (columnName == 'action')
            style += 'width: 15%;';
        else {
            let numDataCols: number = this.displayedColumns.length - 1;
            if (numDataCols == 1)
                style += 'width: 85%;';
            else if (numDataCols == 2) {
                if (this.displayedColumns.includes('name')) {
                    if (columnName == 'name')
                        style += 'width: 55%;';
                    else
                        style += 'width: 30%;';
                } else
                    style += 'width: 42.5%;';
            } else if (numDataCols == 3) {
                if (columnName == 'name')
                    style += 'width: 45%;';
                else
                    style += 'width: 20%;';
            }
        }

        return style;
    }

    public get AddContactLabel(): string {
        return 'Add Contact';
    }
    public get AddContactButtonTitle(): string {
        return 'Add Contact';
    }
    public get AddContactButtonAdditionalStyle(): string {
        return '';
    }
    public get AddContactButtonFlyoverText(): string {
        return 'Add a person to the contacts listing';
    }
    public get AddMeButtonTitle(): string {
        return 'Add Me';
    }
    public get AddMeButtonCanEnable(): boolean {
        return ((this.Mode != 'design') && (!this.selfAddedFlag));
    }
    public get AddMeButtonFlyoverText(): string {
        return 'Add yourself to the contacts listing'
    }

    public get PickerInputDisabled(): boolean {
        return (this.mode === 'design');
    }

    public get TooltipPosition(): TooltipPosition {
        let position: TooltipPosition = 'below';

        return (position);
    }

    public get EnableContactEditing(): boolean {
        // Note:  check with Shaun to see if this needs to be configurable.
        return true;
    }
    public getEditContactTooltip(element: IContactInfo): string {
        return (element.editingEnabled ? 'End editing this contact' : 'Edit this contact');
    }
    public get EditContactButtonDisabled(): boolean {
        return (this.Mode === 'design');
    }

    public get TrashCanTooltip(): string {
        return 'Delete this contact';
    }
    public get TrashCanButtonDisabled(): boolean {
        return (this.mode === 'design');
    }

    //VNEXT-811: KLW - Needed for emailing contacts
    public get EnableEmailEditing(): boolean {
        // Note:  check with Shaun to see if this needs to be configurable.
        return true;
    }
    public get EmailToolTip(): string {
        return 'Email this contact';
    }
    public get EmailButtonDisabled(): boolean {
        return (this.Mode === 'design');
    }
    //VNEXT-951: KLW - Changes to email a contact list
    public get EmailAllButtonDisabled(): boolean {
        var retVal: boolean = false;

        if ((this.Mode === 'design')) {
            retVal = true;
        }

        if (this.ContactsListing) {
            if (this.ContactsListing.length <= 0) {
                retVal = true;
            }
        }
        else {
            retVal = true;
        }

        return retVal;
    }

    public get EmailAllToolTip(): string {
        return 'Email all contacts in the list';
    }

    public get ActivityListIcon(): string {
        return ItemTypeEnum.ICON;
    }

    //VNEXT-811: KLW - Changes to email a contact
    public showEmailDialog() {
        this.FormInstanceElement.BypassSitePopUpWarning = true;

        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: new ConfirmationDialogModel(`Email Created`, `An email has been created using your email client and it should display shortly`, { showOk: true, hideNo: true, dialogType: ConfirmationDialogEnums.EMAIL })
        });

        dialogRef.afterOpened().subscribe(() => {
            this.FormInstanceElement.BypassSitePopUpWarning = false;
        });
        dialogRef.afterClosed().subscribe(() => {
            this.FormInstanceElement.BypassSitePopUpWarning = false;
        });
    }
    public getEmail(email: string, contactName: string): SafeUrl {
        return this.emailService.composeInvEmail(email, contactName, this.formInstanceName, window.location.href);
    }

    //VNEXT-951: KLW - Changes to email a contact list
    public emailAll(): SafeUrl {
        var retVal: SafeUrl = '';

        if (this.ContactsListing) {
            if (this.ContactsListing.length > 0) {
                var emails = this.ContactsListing.map(x => x.email);
                retVal = this.emailService.composeAllEmail(emails, this.formInstanceName, window.location.href);
            }
        }

        return retVal;
    }

    // End methods called by my HTML code.

    // Override base class methods.
    public getProperties(): any {
        let hshProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties,
            displayRowsValues: this.numDisplayRowsValues,
            propertyUpdateRequired: true
        };

        return (hshProperties);
    }

    public getFormFieldClass(): AngularCoreType<any> {
        return (ContactsFormFieldComponent);
    }

    public hasNumericData(): boolean {
        return false;
    }

    // Override some FormFieldComponentBase methods.
    protected formInstanceElementReceived(): void {
        if (this.FormInstanceElement && this.FormInstanceElement.textValue) {
            this.contactsData = ContactsFormFieldData.deserializeData(this.FormInstanceElement);
        }
        this.checkSelfAdded();

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if (this.FormInstanceElement && this.FormInstanceElement.textValue) {
            this.contactsData = ContactsFormFieldData.deserializeData(this.FormInstanceElement);
        }
        this.checkSelfAdded();

        return;
    }

    public propertyUpdated(formField: FormField, propertyName: string): void {
        if (propertyName == FormFieldPropertyEnum.SHOW_CONTACTS_PROPERTIES) {
            this.deriveDisplayedColumns();
        } else if (propertyName == FormFieldPropertyEnum.NUMBER_OF_DISPLAY_ROWS) {
            let numRows: number = parseInt(this.FormField.displayRows);
            //VNEXT-1430: KLW - Due to changes from Angular 14 to 15, NaN cannot be use directly and instead we must use the method from the Numbers class
            //Number.IsNan()
            if (!Number.isNaN(numRows)) {
                this.numDisplayRows = numRows;

                if ((this.Mode === 'design') && (numRows != this.contactsData.contacts.length))
                    this.createExampleContacts();
            }
        }
    }

    // Handle controle events.
    public addContactButtonClicked(eventData: PickerItem): void {
        if (this.contactsData.contacts == null)
            this.contactsData.contacts = [];

        let contactAlreadyExists: boolean = this.contactsData.containsUser(eventData.key);
        if (contactAlreadyExists) {
            let errorData: AlertDialogModel = new AlertDialogModel("Duplicate Contact", `"${eventData.displayName}" cannot be added as the contact is already in the list.`);
            this.displayError.emit(errorData);
        } else {
            let contact: IContactInfo = new ContactInfo(eventData.key, eventData.displayName, eventData.secondaryDisplayName, eventData.phone, eventData.agencyShortName);
            this.contactsData.contacts.push(contact);
            this.table.renderRows();

            this.packageDataAndUpdateFlags();
        }

        //VNEXT-665: KLW - Set flag to clear Max Picker
        eventData['clearMemberInput'] = true;
    }

    public addMeButtonClicked(ignoredEventData: any): void {
        if (this.contactsData.contacts == null)
            this.contactsData.contacts = [];

        let contact: IContactInfo = new ContactInfo(this.currentUserService.user.maxId, this.currentUserService.user.displayName, this.currentUserService.user.emailAddress, this.currentUserService.user.phone);
        this.contactsData.contacts.push(contact);
        this.table.renderRows();

        this.packageDataAndUpdateFlags();

        //VNEXT-665: KLW - Set flag to clear Max Picker
        if (ignoredEventData != null)
            ignoredEventData['clearMemberInput'] = true;
    }

    public deleteContact(element: IContactInfo): void {
        this.contactsData.contacts = this.contactsData.contacts.filter(c => c.userId != element.userId);
        this.table.renderRows();

        this.packageDataAndUpdateFlags();
    }

    public editContact(element: IContactInfo): void {
        element.editingEnabled = (!element.editingEnabled);
    }

    public handleModelChange(element: IContactInfo, fieldName: string): void {
        // Note:  this method is only used during testing/troubleshooting.
        this.packageDataAndUpdateFlags();
    }
    // End methods that handle control events.

    // Implement helper methods.
    private createExampleContacts(): void {
        this.contactsData.contacts = [];
        let exampleContact: IContactInfo = new ContactInfo('NA', 'Jane Doe', 'janedoe@max.gov', '123-456-7890');
        this.contactsData.contacts.push(exampleContact);

        if (this.FormField.displayRows != null) {
            let numRows: number = parseInt(this.FormField.displayRows);
            //VNEXT-1430: KLW - Due to changes from Angular 14 to 15, NaN cannot be use directly and instead we must use the method from the Numbers class
            //Number.IsNan()
            if ((!Number.isNaN(numRows)) && (numRows > 1)) {
                for (let rowIndex: number = 1; rowIndex < numRows; rowIndex++) {
                    let phoneNumberChar: string = `${rowIndex + 1}`;
                    exampleContact = new ContactInfo(`NA_${rowIndex + 1}`, `Contact ${rowIndex + 1}`, `contact${rowIndex + 1}@max.gov`, `${phoneNumberChar.repeat(3)}-${phoneNumberChar.repeat(3)}-${phoneNumberChar.repeat(4)}`);
                    this.contactsData.contacts.push(exampleContact);
                }
            }
        }
    }

    private checkSelfAdded(): void {
        this.selfAddedFlag = this.contactsData.containsUser(this.currentUserService.user.maxId);
    }

    private packageDataAndUpdateFlags(): void {
        this.FormInstanceElement.textValue = JSON.stringify(this.contactsData);
        this.FormInstanceElement.UserUpdatedData = true;
        this.selfAddedFlag = this.contactsData.containsUser(this.currentUserService.user.maxId);
    }

    private deriveDisplayedColumns(): void {
        this.displayedColumns = ((this.FormField.contactFieldNames != null) && (this.FormField.contactFieldNames.trim() != '') ? this.FormField.contactFieldNames.split('|') : []);
        this.displayedColumns.push('action');
    }
}
