export class CrossSiteUserFavoriteFormsData {
    public formId: number;
    public formName: string;
    public siteId: number;
    public siteName: string;
    public folderId: number;
    public modifiedDate: Date;
    public modifiedBy: string;
    public workflowState: string;
    public isFavorite: boolean;
}
export class CrossSiteUserFavoriteFormsResults {
    public results: CrossSiteUserFavoriteFormsData[] = [];

    public resultsAsPlainObjects: object[] = []; // results translated into plain JS objects needed by Kendo Grid by service code

    public total: number = 5000;
}
