<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<div class="height-100-percent">
    <div class="flex-row-top-right" *ngIf="this.Mode === 'design'">
        <div class="fill-remaining-space">

            <!-- If there are instructions, display label first, then instructions then field -->
            <ng-container *ngIf="this.HasInstructions">
                <div class="design-mode-field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </ng-container>

            <mat-form-field appearance="outline"
                            [floatLabel]="'always'">
                <mat-label *ngIf="this.ShowLabel && !HasInstructions"
                           class="design-mode-field-label">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></mat-label>

                <input matInput
                       placeholder="{{this.PlaceholderText}}"
                       value="{{this.DefaultValue}}"
                       disabled />

            </mat-form-field>
            <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>

    <div class="flex-row-top-right height-100-percent" *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')">
        <div class="fill-remaining-space height-100-percent">
            <ng-container *ngIf="(this.ControlType === 'Reactive Forms')">
                <ng-container *ngIf="this.FormControl !== null">

                    <!-- If there are instructions, display label first, then instructions then field -->
                    <ng-container *ngIf="this.HasInstructions">
                        <div class="field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                    </ng-container>

                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <!-- If there are no instructions, display label integrated into the field -->
                        <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid"
                                   class="field-label">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></mat-label>

                        <ng-container *ngIf="!this.UseGridSpecificStyling">
                            <!--outside grid-->
                            <input matInput
                                   type="text"
                                   id="{{this.MatInputId}}"
                                   [readonly]="this.ReadOnly"
                                   class="null-tooltip-mat-input"
                                   placeholder="{{this.PlaceholderText}}"
                                   [formControl]="this.FormControl"
                                   (blur)="this.handleOnBlur()"
                                   (focus)="this.handleOnFocus($event)" />
                        </ng-container>
                        <ng-container *ngIf="this.UseGridSpecificStyling">
                            <!--inside grid-->
                            <textarea #gridOnlyTextArea
                                      matInput
                                      [readonly]="this.ReadOnly"
                                      class="height-100-percent"
                                      placeholder="{{this.PlaceholderText}}"
                                      [formControl]="this.FormControl"
                                      (blur)="this.handleOnBlur()"
                                      (focus)="this.handleOnFocus($event)"
                                      rows="{{this.NumGridOnlyTextAreaRows}}">
                            </textarea>
                        </ng-container>
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                </ng-container>
            </ng-container>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>
</div>
