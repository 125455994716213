import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, RouterState } from '@angular/router';
import { Logging } from '../shared/logging';
import { DataCollection } from '../shared/models/site-content/data-collection.model';
import { ClaimService } from '../shared/services/claim.service';
import { CurrentFolderService } from "../shared/services/current-folder.service";
import { CurrentSiteService } from '../shared/services/current-site.service';
import { DataCollectionService } from '../shared/services/data-collection.service';

@Injectable()
export class ClaimGuard implements CanActivate {

    SPECIAL_CASES = {
        SITE_SUMMARY: 'CanViewSiteSummary',
        SITE_PERMISSIONS: 'CanViewSitePermissions'
    }

    constructor(
        private router: Router,
        private claimService: ClaimService,
        private currentSiteService: CurrentSiteService,
        private currentFolderService: CurrentFolderService,
        private dataCollectionService: DataCollectionService) { }

    doClaimServiceCall(requiredClaim: string, site: DataCollection, route: ActivatedRouteSnapshot) {
        return this.claimService.has(requiredClaim, site).then(hasClaim => {
            //console.log("MESSAGE FROM ClaimGuard: " + requiredClaim + "?..." + hasClaim);
            if (hasClaim) {
                return true;
            } else {
                // See https://kirjai.com/dynamic-guard-redirects-angular/
                let siteId = route.parent.url[1]?.path;
                if (siteId && route.data.redirectToWhenCannotActivate) {
                    this.router.navigate(['site', siteId, route.data.redirectToWhenCannotActivate]);
                }
                return false;
            }
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        // Originally I wanted ClaimGuard to validate against claims obtained in the parent route's ClaimResolver
        // However, due to the issue reported here https://github.com/angular/angular/issues/24187 a resolver
        // on a parent route won't run until the child route's guard returns true...
        // So the guard gets claims directly from ClaimService rather than parent route's resolver

        let requiredClaim = route.data.requiredClaim;
        let site = route.parent.data.currentSite;

        // When first navigating to the default site route (Site Summary) site will be null because the Resolver
        // on a parent route doesn't run until a child route resolves. So in this case need to
        // use the DataCollectionService to get the site, so we can test the user's claims against it.
        // After the first nav within site routes, site is available from the parent route.
        // To prevent a double fetch of the Site from the server, CurrentSiteService is used which the
        // parent route's Resolver can use to get the Site set here.
        if ((requiredClaim == this.SPECIAL_CASES.SITE_SUMMARY || requiredClaim == this.SPECIAL_CASES.SITE_PERMISSIONS) && site == null) {
            let siteIdFromParentRoute = parseInt(route.parent.url[1].path);
            if (this.currentSiteService.isCurrentSite(siteIdFromParentRoute)) {
                return this.doClaimServiceCall(requiredClaim, this.currentSiteService.Site, route);
            } else {
                return this.dataCollectionService.get(siteIdFromParentRoute).then(site => {
                    this.currentSiteService.Site = site;
                    this.currentFolderService.Folder = site.getRootFolder();
                    return this.doClaimServiceCall(requiredClaim, site, route);
                });
            }
        } else {
            return this.doClaimServiceCall(requiredClaim, site, route);
        }
    }
}
