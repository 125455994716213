import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { GridFieldEditorComponent } from '../components/form-fields/grid-field-editor/grid-field-editor.component';

// A general purpose service for communicating across components within the UI
@Injectable()
export class CommunicationService {

    // Pharvey - 6/3/2024 - added for VNEXT-1294 to allow any unsaved changes in grids to be saved to pending before the form as a whole is saved
    private gridComponents: GridFieldEditorComponent[] = [];

    constructor() { }

    // The "Skip Navigation" link (an accessibility measure) is in the top-level component
    // and in order for it to be kept up to date, lower-level components need to notify
    // that it has changed.
    private skipNavLinkSubject = new Subject<string>();

    // This is what AppComponent subscribes to (see its constructor)
    public skipLinkChanged = this.skipNavLinkSubject.asObservable();

    // Called by components needing to notify that the skip link's url has changed
    // TODO: consider removing this and calls to it -- may not need to do this if skip to can be set
    // during initialization of AppComponent...
    public emitChangeToSkipNavLink(newLink: string, target: string = null) {
        newLink = newLink.split('#')[0]; // strip off anchor if present
        target = target == null ? "#main-content" : target;
        newLink = `${newLink}${target}`;
        this.skipNavLinkSubject.next(newLink);
    }

    // PHarvey - 6/3/2024 - for VNEXT-1294
    // I was going to put these methods in KendoGridService BUT that service is scoped
    // to each individual Grid and is not a singleton - I thought it would be confusing to also
    // have a singleton instance of it, so I placed them here in this generic service
    // (Perhaps it should go in a new, dedicated service?)
    public registerGrid(gridFieldEditorComponent: GridFieldEditorComponent) {
        this.gridComponents.push(gridFieldEditorComponent);
    }

    // Returns a Promise for all registered grids on a given Form. Each one is given the opportunity to save any unsaved rows.
    // The caller of this method can then wait for all of them to complete
    public saveAnyUnsavedGridData(): Promise<any> {
        let promises = [];
        for (let grid of this.gridComponents) {
            promises.push(grid.saveAnyUnsavedRows('communicationService'));
        }
        return Promise.all(promises);
    }

}
