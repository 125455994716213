import { ScriptableBaseComponent } from '../../components/scriptable-base/scriptable-base.component';
import { AdditionalElementInfo, IAdditionalElementInfo, INameToPrettyNameMap, IOperationCompletedServiceMap } from './additional-element-info.model';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
import { IElementTypeMetadata } from '../../interfaces/component-scripting/ielement-type-metadata';

export interface IScriptableElement {
    anElementSelector: string; // An HTML element selector.

    // Methods for identifying types of HTML elements.
    hasLinks(optionalAdditionalElementInfo?: IAdditionalElementInfo): IScriptableElement;
    hasButtons(optionalAdditionalElementInfo?: IAdditionalElementInfo): IScriptableElement;
    hasButtonsWithMatIcons(optionalAdditionalElementInfo?: AdditionalElementInfo): IScriptableElement;
    hasMatListItems(optionalAdditionalElementInfo?: IAdditionalElementInfo): IScriptableElement;
    hasMatIcons(optionalAdditionalElementInfo?: IAdditionalElementInfo): IScriptableElement;
    hasInput(optionalAdditionalElementInfo?: IAdditionalElementInfo): IScriptableElement;
    hasInputs(optionalAdditionalElementInfo?: IAdditionalElementInfo): IScriptableElement;
    hasMatCheckBox(optionalAdditionalElementInfo?: IAdditionalElementInfo): IScriptableElement;
    hasMatList(optionalAdditionalElementInfo?: IAdditionalElementInfo): IScriptableElement;
    hasMatSelect(optionalAdditionalElementInfo?: AdditionalElementInfo): IScriptableElement;

    // Methods for additional additional information for specified elements.
    hasInnerTextSelector(innerTextSelector: string): IScriptableElement;

    hasCustomPreprocessorFunction(preprocessingFunction: (driver: IBrowserDriver, elements: object, elementTypeMetadata: IElementTypeMetadata, additionalElementInfo: IAdditionalElementInfo) => void): IScriptableElement;
    hasCustomGetTextFunction(getTextFunction: (driver: IBrowserDriver, element: object, exitingTextValue: string, additionalElementInfo: IAdditionalElementInfo) => string): IScriptableElement;
    hasGetClickableElementFunction(getClickableElementFunction: (element: object) => object): IScriptableElement;

    hasToggleCheckboxMethodName(methodName: string): IScriptableElement;
    hasElementSubType(subtype: string): IScriptableElement;

    withName(name: string): IScriptableElement;
    withNames(names: string[]): IScriptableElement;
    withNameToPrettyNameMap(nameToPrettyNameMap: INameToPrettyNameMap): IScriptableElement; // This property is probably not going to be used.
    withStandardNameToPrettyStandardNameMap(standardNameToPrettyStandardNameMap: INameToPrettyNameMap): IScriptableElement;
    withElementsSubtypeMap(elementsSubtypeMap: INameToPrettyNameMap): IScriptableElement;
    withAddedDateTimeSuffix(hasSuffix?: boolean): IScriptableElement;
    withOperationCompletedServiceMap(operationCompletedServiceMap: IOperationCompletedServiceMap): IScriptableElement;

    allowsZeroElements(): IScriptableElement;
    withMaxTimeoutsLookingForScriptableElements(maxTimeoutsLookingForScriptableElements: number): IScriptableElement;

    //withCssFilter(filterExpr: string): IScriptableElement;
}
export class ScriptableElement implements IScriptableElement {
    // Properties (that not defined in the constructor).
    //private typeOfElementBeingDefined: string = null; // Note:  this property is not needed.

    // Constructor.
    public constructor(public anElementSelector: string, private component: ScriptableBaseComponent) { }

    // Methods for identifying types of HTML elements.
    public hasLinks(optionalAdditionalElementInfo: AdditionalElementInfo = null): IScriptableElement {
        this.component.elementHasLinks(this.anElementSelector, optionalAdditionalElementInfo);
        //this.typeOfElementBeingDefined = HtmlElementTypeNames.links_metadataKey;
        return this;
    }
    public hasButtons(optionalAdditionalElementInfo: AdditionalElementInfo = null): IScriptableElement {
        this.component.elementHasButtons(this.anElementSelector, optionalAdditionalElementInfo);
        return this;
    }
    public hasButtonsWithMatIcons(optionalAdditionalElementInfo: AdditionalElementInfo = null): IScriptableElement {
        this.component.elementHasButtonsWithMatIcons(this.anElementSelector, optionalAdditionalElementInfo);
        return this;
    }
    public hasMatListItems(optionalAdditionalElementInfo: AdditionalElementInfo = null): IScriptableElement {
        this.component.elementHasMatListItems(this.anElementSelector, optionalAdditionalElementInfo);
        return this;
    }
    public hasMatIcons(optionalAdditionalElementInfo: AdditionalElementInfo = null): IScriptableElement {
        this.component.elementHasMatIcons(this.anElementSelector, optionalAdditionalElementInfo);
        return this;
    }
    public hasInput(optionalAdditionalElementInfo: AdditionalElementInfo = null): IScriptableElement {
        this.component.elementHasInput(this.anElementSelector, optionalAdditionalElementInfo);
        return this;
    }
    public hasInputs(optionalAdditionalElementInfo: AdditionalElementInfo = null): IScriptableElement {
        this.component.elementHasInputs(this.anElementSelector, optionalAdditionalElementInfo);
        return this;
    }
    public hasMatCheckBox(optionalAdditionalElementInfo: AdditionalElementInfo = null): IScriptableElement {
        this.component.elementHasMatCheckBox(this.anElementSelector, optionalAdditionalElementInfo);
        return this;
    }
    public hasMatList(optionalAdditionalElementInfo: AdditionalElementInfo = null): IScriptableElement {
        this.component.elementHasMatList(this.anElementSelector, optionalAdditionalElementInfo);
        return this;
    }
    public hasMatSelect(optionalAdditionalElementInfo: AdditionalElementInfo = null): IScriptableElement {
        this.component.elementHasMatSelect(this.anElementSelector, optionalAdditionalElementInfo);
        return this;
    }
    
    // Methods for additional additional information for specified elements.
    public hasInnerTextSelector(innerTextSelector: string): IScriptableElement {
        this.component.hasInnerTextSelector(this.anElementSelector, innerTextSelector);
        return this;
    }

    public hasCustomPreprocessorFunction(preprocessingFunction: (driver: IBrowserDriver, elements: object, elementTypeMetadata: IElementTypeMetadata, additionalElementInfo: IAdditionalElementInfo) => void): IScriptableElement {
        this.component.elementHasCustomPreprocessingFunction(this.anElementSelector, preprocessingFunction);
        return this;
    }
    public hasCustomGetTextFunction(getTextFunction: (driver: IBrowserDriver, element: object, existingTextValue: string, additionalElementInfo: IAdditionalElementInfo) => string): IScriptableElement {
        this.component.elementHasCustomGetTextFunction(this.anElementSelector, getTextFunction);
        return this;
    }
    public hasGetClickableElementFunction(getClickableElementFunction: (element: object) => object): IScriptableElement {
        this.component.elementHasGetClickableElementFunction(this.anElementSelector, getClickableElementFunction);
        return this;
    }

    public hasToggleCheckboxMethodName(methodName: string): IScriptableElement {
        this.component.elementHasToggleCheckboxMethodName(this.anElementSelector, methodName);
        return this;
    }
    public hasElementSubType(subtype: string): IScriptableElement {
        this.component.elementHasSubtype(this.anElementSelector, subtype);
        return this;
    }

    public withName(name: string): IScriptableElement {
        this.component.elementHasName(this.anElementSelector, name);
        return this;
    }
    public withNames(names: string[]): IScriptableElement {
        this.component.elementHasNames(this.anElementSelector, names);
        return this;
    }
    public withNameToPrettyNameMap(nameToPrettyNameMap: INameToPrettyNameMap): IScriptableElement {
        this.component.elementHasNameToPrettyNamesMap(this.anElementSelector, nameToPrettyNameMap);
        return this;
    }
    public withStandardNameToPrettyStandardNameMap(standardNameToPrettyStandardNameMap: INameToPrettyNameMap): IScriptableElement {
        this.component.elementHasStandardNameToPrettyStandardName(this.anElementSelector, standardNameToPrettyStandardNameMap);
        return this;
    }
    public withElementsSubtypeMap(elementsSubtypeMap: INameToPrettyNameMap): IScriptableElement {
        this.component.elementHasElementSubtypesMaps(this.anElementSelector, elementsSubtypeMap);
        return this;
    }
    public withAddedDateTimeSuffix(hasSuffix: boolean = true): IScriptableElement {
        this.component.elementHasAddedDateTimeSuffix(this.anElementSelector, hasSuffix);
        return this;
    }
    public withOperationCompletedServiceMap(operationCompletedServiceMap: IOperationCompletedServiceMap): IScriptableElement {
        this.component.elementHasOperationCompletedServiceMap(this.anElementSelector, operationCompletedServiceMap);
        return this;
    }

    public allowsZeroElements(): IScriptableElement {
        this.component.elementAllowsZeroElements(this.anElementSelector);
        return this;
    }
    public withMaxTimeoutsLookingForScriptableElements(maxTimeoutsLookingForScriptableElements: number): IScriptableElement {
        this.component.elementHasMaxTimeoutsLookingForScriptableElements(this.anElementSelector, maxTimeoutsLookingForScriptableElements);
        return this;
    }

    /*
    public hasSelectorWithText(additionalElementInfo: AdditionalElementInfo): IScriptableElement {
        this.component.elementHasSelectorWithText(additionalElementInfo);
        return this;
    }
    */
    /*
    public withCssFilter(filterExpr: string): IScriptableElement {
        // TO DO:  CODE THIS METHOD!!!
        return this;
    }
    */
}
