<h1 mat-dialog-title
    class="draggable-dialogue-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
    Import Excel Data into Grid
</h1>

<form>
    <div mat-dialog-content
         class="mat-dialog-content-div">
        <div>
            <span>File: </span>
            <span class="filename-span">{{this.SelectedFileName}}</span>
        </div>
    </div>

    <div class="execution-time-div">
        <span>Execution Time:</span>
        <span [ngClass]="this.JobInProgress ? 'active-execution-time-span' : 'idle-execution-time-span'">{{this.JobTimeElapsed}}</span>
    </div>

    <div class="execution-status-div">
        <span>Execution Status:</span>
        <span [ngClass]="this.JobCompleted ? 'completed-execution-status-span' : 'incomplete-execution-status-span'">{{this.ImportStatus}}</span>
    </div>

    <div class="show-save-message-div">
        <small>{{this.DetailedSaveResultMessage}}</small>
    </div>

    <div class="execution-summary-div tall-execution-summary-div">
        <p [ngClass]="this.JobErrorOccurred ? 'execution-summary-p execution-summary-with-error-p' : 'execution-summary-p execution-summary-without-error-p'"
           data-placeholder="Details will display here ...">{{this.ExecutionSummaryText}}</p>
    </div>

    <div class="truncate-existing-grid-rows-div">
        <mat-checkbox name="truncate-existing-grid-rows-checkbox"
                      [(ngModel)]="this.TruncateExistingGridRows"
                      (ngModelChange)="this.truncateExistingGridRowsChanged()">
            Overwrite <strong>ALL</strong> existing records.
        </mat-checkbox>
        <br />
        <small>(Uncheck to append your data to the existing data)</small>
    </div>

    <div mat-dialog-actions>
        <ng-container *ngIf="this.ShowStartImportButton">
            <button mat-flat-button
                    type="submit"
                    color="primary"
                    class="theme-primary-btn"
                    [disabled]="this.DisableStartImportButton"
                    (click)="this.startExcelGridImport()">
                Start Import ...
            </button>
        </ng-container>

        <button mat-flat-button
                type="submit"
                color="accent"
                [disabled]="!this.JobInitiated"
                (click)="this.dismissClicked()">
            {{this.DismissButtonTitle}}
        </button>

        <button mat-flat-button
                type="submit"
                color="accent"
                [disabled]="!this.JobErrorOccurred"
                (click)="this.copyJobErrorTextToClipboard()">
            Copy Error Text
        </button>

        <button mat-flat-button
                [disabled]="this.JobInitiated"
                style="margin-left: 10px;"
                (click)="this.cancelClicked()">
            Cancel
        </button>
    </div>
</form>
