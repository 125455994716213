
export class JavaScriptUtil {
    // Note:  the following method does not copy methods, so a call to plainToClass() will be
    //        needed to convert the returned 'zombie' object to a full-fledged object copy.
    public static deepCopy(objectToCopy: any): any {
        // Note:  this deep cpoy technique is discussed in the following article:
        //            https://medium.com/@ashikmhussain.a/understanding-deep-copy-in-javascript-b1749c54de80
        let deepCopy: any = null;

        if (objectToCopy != null) {
            let objectJson: string = JSON.stringify(objectToCopy);
            deepCopy = JSON.parse(objectJson);
        }

        return deepCopy;
    }
}
