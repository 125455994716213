import { Injectable } from '@angular/core'; 

@Injectable()
export class BrowserStorageService {
    static readonly SESSION_SCOPE: number = 0;
    static readonly LOCAL_SCOPE: number = 1;

    public set(key: string, value: string, storageScope: number = BrowserStorageService.LOCAL_SCOPE): any {
        if (storageScope == BrowserStorageService.SESSION_SCOPE) {
            sessionStorage.setItem(key, value);
        } else {
            localStorage.setItem(key, value);
        }
    }

    /** Returns the string value from the indicated store, or null if the key does not exist. */
    public get(key: string, storageScope: number = BrowserStorageService.LOCAL_SCOPE): any {
        if (storageScope == BrowserStorageService.SESSION_SCOPE) {
            return sessionStorage.getItem(key);
        } else {
            return localStorage.getItem(key);
        }
    }

    public delete(key: string, storageScope: number = BrowserStorageService.LOCAL_SCOPE): void {
        if (storageScope == BrowserStorageService.SESSION_SCOPE) {
            sessionStorage.removeItem(key);
        } else {
            localStorage.removeItem(key);
        }
    }
}
