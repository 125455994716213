import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

// Define exported initialization data classes.
export class DisplayContentData {
    public constructor(public content: string, public tabName: string, public makeContentReadonly: boolean = true) {
    }
}

export interface IUpdateContent {
    newContentAdded(content: string, tabName: string): void;
}
export interface IContentUpdated {
    contentUpdated(updatedContent: string, tabName: string): void;
}

export class DisplayContentDialogInitData {
    public title: string = "";
    //public content: string;
    public contentData: DisplayContentData[] = [];

    //public contentLabel: string = 'Content';
    public numDisplayRows: number = 25;

    public constructor(titleParam: string, contentDataParam: DisplayContentData[], numDisplayRowsParam: number = 25) {
        this.title = titleParam;
        this.contentData = contentDataParam;
        this.numDisplayRows = numDisplayRowsParam;
    }
}

// Define an internally used class.
class DisplayContentDataWithFormControl {
    public contentData: DisplayContentData;
    public contentFormControl: UntypedFormControl; 

    public constructor(contentData: DisplayContentData, contentFormControl: UntypedFormControl) {
        this.contentData = contentData;
        this.contentFormControl = contentFormControl;
    }
}

@Component({
    selector: 'app-display-content',
    templateUrl: './display-content.dialog.html',
    styleUrls: ['./display-content.dialog.scss']
})
export class DisplayContentDialog implements OnInit, AfterViewInit {
    // Properties.
    @ViewChild('messageSpan') messageSpan: ElementRef<HTMLInputElement>;

    // Form/content-related properties.
    private bInitialized: boolean = false;
    private contentData: DisplayContentDataWithFormControl[] = [];
    private tabCount: number = 0;
    private tabNames: string[]= [];
    private selectedContentIndex: number = 0;

    private message: string = '';

    // Constructor.
    public constructor(@Inject(MAT_DIALOG_DATA) public initData: DisplayContentDialogInitData,
        private clipboard: Clipboard,
        private renderer: Renderer2)
    {
        return;
    }

    // Life cycle methods.
    public ngOnInit(): void {
        this.setupFormControls();
    }

    public ngAfterViewInit(): void {
        return;
    }

    /*
    public activateTab(tabGroup: any, index: number): void {
        //console.log('DisplayContent.changeTab() = ' + index);
        tabGroup.selectedIndex = index;
    }
    */
    public tabChanged(): void {
    }

    // Methods called by my HTML code.
    public get Title(): string {
        return (this.initData.title);
    }

    public get HasInitialized(): boolean {
        return (this.bInitialized);
    }
    public get TabCount(): number {
        return this.tabCount;
    }
    public TabContent(tabIndex: number): string {
        //return (this.initData.content);
        return (this.contentData[tabIndex].contentData.content);
    }
    public get TabNames(): string[] {
        return this.tabNames;
    }
    public TabName(tabIndex: number): string {
        return (this.contentData[tabIndex].contentData.tabName);
    }
    public get SelectedContentIndex(): number {
        return (this.selectedContentIndex);
    }
    public set SelectedContentIndex(indexParam: number) {
        this.selectedContentIndex = indexParam;
    }

    public get HasNoContent(): boolean {
        //return ((this.initData.content == null) || (this.initData.content.length == 0));
        return (this.contentData.length == 0)
    }
    public get ContentLabel(): string {
        //return (this.initData.contentLabel);
        return (this.contentData[this.selectedContentIndex].contentData.tabName);
    }
    public get NumContentControlRows(): number {
        // If we have a tabbed interface (multiple content
        // elements), make the text area a bit shorter.
        let numDisplayRows: number =
            (this.contentData.length > 1 ? this.initData.numDisplayRows - 3 : this.initData.numDisplayRows);
        return (numDisplayRows);
    }

    public ContentFormControl(tabIndex: number): UntypedFormControl {
        //return (this.contentFormControl);
        return (this.contentData[tabIndex].contentFormControl);
    }
    public DataIsReadOnly(tabIndex: number): boolean {
        let tabData: DisplayContentDataWithFormControl = this.contentData[tabIndex];
        return tabData.contentData.makeContentReadonly;
    }

    public get Message(): string {
        return (this.message);
    }

    // Handle dialogue actions.
    public copyContentToClipboard(): void {
        let content: string = this.contentData[this.selectedContentIndex].contentData.content;
        this.clipboard.copy(content);

        this.message = 'Content copied to clipboard!';
        if (this.messageSpan != null) {
            this.renderer.removeClass(this.messageSpan.nativeElement, 'message-span-invisible-font');
            this.renderer.addClass(this.messageSpan.nativeElement, 'message-span-visible-font');
        }

        setTimeout(() => {
            if (this.messageSpan != null) {
                this.renderer.removeClass(this.messageSpan.nativeElement, 'message-span-visible-font');
                this.renderer.addClass(this.messageSpan.nativeElement, 'message-span-invisible-font');
            } else {
                this.message = '';
            }            
        }, 3000);
    }

    // Helper methods.
    private setupFormControls(): void {
        for (let iContentInfo: number = 0; iContentInfo < this.initData.contentData.length; iContentInfo++) {
            let contentData: DisplayContentData = this.initData.contentData[iContentInfo];
            let controlProperties = {
                value: contentData.content,
                disabled: contentData.makeContentReadonly
            };
            //let contentDataWithFormControl: DisplayContentDataWithFormControl = new DisplayContentDataWithFormControl(contentData, new FormControl(contentData.content));
            let formControl = new UntypedFormControl(controlProperties);
            formControl.valueChanges.subscribe(updatedValue => {
                console.log('Value changed.');
            });
            let contentDataWithFormControl: DisplayContentDataWithFormControl = new DisplayContentDataWithFormControl(contentData, formControl);

            this.contentData.push(contentDataWithFormControl);
            this.tabNames.push(contentDataWithFormControl.contentData.tabName);
        }
        this.tabCount = this.contentData.length;

        this.bInitialized = true;
    }
}
