import { LayoutRow } from './layout-row.model';
import { Type } from 'class-transformer';
import { LayoutCell } from './layout-cell.model';
import { ICloneAndCopy } from '../../interfaces/clone-and-copy';
//import { ObjectPropertyUtil, MapOfStrings } from '../../utility-classes/object-property.util';

export class LayoutCellInfo {
    public constructor(public rowIndex: number, public cellIndex: number, public layoutCell: LayoutRow, public cell: LayoutCell) {
    }
}

export class FormLayout implements ICloneAndCopy {
    // Properties.
    public columnsDefault: number = 1;
    public alignment: string;
    public columnWidths: number[] = [12];

    @Type(() => LayoutRow)
    public rows: LayoutRow[] = [];

    // Implement the following public methods:
    //
    //     MaxRowCellCountWithContent();
    //     MaxCellColSpanWithContent();
    //
    //     moveRow();
    //     addBlankRow();
    //     addRowAbove();
    //     addRowBelow();
    //     deleteRow();
    //
    //     applyChanges();
    //
    //     addColumn();
    //
    //     addCellToEachRow();
    //     removeCellFromEachRow();
    //     removeCellsFromEachRow();
    //     removeColumns();
    //
    //     getCssClass();
    //
    //     clearSelection();
    //     selectedCells();
    //     findCellContaining()
    //
    //     fieldNameUpdated();
    //
    //     clone();
    //     copy();

    public get MaxRowCellCountWithContent(): number {
        let iDefaultCellWidth: number = 12 / this.columnsDefault;

        let iMaxColSpanCountCount: number = 0;

        for (let iRow: number = 0; iRow < this.rows.length; iRow++) {
            let row: LayoutRow = this.rows[iRow];

            let iRowColSpanCount: number = 0;
            let iRowMaxColSpanCount: number = 0;

            for (let iCell: number = 0; iCell < row.cells.length; iCell++) {
                let cell: LayoutCell = row.cells[iCell];
                let iCellWidth = cell.width;

                //let iCellColSpan = iCellWidth / iDefaultCellWidth;
                let iCellColSpan = (cell.CellOccupiedByFullRowWidthField ? 1 : iCellWidth / iDefaultCellWidth);
                cell.transientColSpan = iCellColSpan;

                iRowColSpanCount += iCellColSpan;

                // Does this cell have content?
                if ((cell.formFieldId > 0) ||
                    ((cell.name != null) && (cell.name.trim() != ''))) {
                    iRowMaxColSpanCount = iRowColSpanCount;
                }
            }

            if (iRowMaxColSpanCount > iMaxColSpanCountCount) {
                iMaxColSpanCountCount = iRowMaxColSpanCount;
            }
        }

        return (iMaxColSpanCountCount);
    }

    public get MaxCellColSpanWithContent(): number {
        let iDefaultCellWidth: number = 12 / this.columnsDefault;

        let iMaxColSpan: number = 0;

        for (let iRow: number = 0; iRow < this.rows.length; iRow++) {
            let row: LayoutRow = this.rows[iRow];

            for (let iCell: number = 0; iCell < row.cells.length; iCell++) {
                let cell: LayoutCell = row.cells[iCell];

                if (cell.hasContent) {
                    //let iColSpan: number = cell.getColSpan(iDefaultCellWidth);
                    let iColSpan: number = (cell.CellOccupiedByFullRowWidthField ? 1 : cell.getColSpan(iDefaultCellWidth));

                    if (iColSpan > iMaxColSpan) {
                        iMaxColSpan = iColSpan;
                    } // if
                } // if
            } // for
        } // for

        return (iMaxColSpan);
    }

    public getPositionOfRow(row: LayoutRow): number {
        let position: number = -1;

        for (let index: number = 0; index < this.rows.length; index++) {
            if (this.rows[index] == row) {
                position = index;
                break;
            }
        }

        return position;
    }

    public moveRow(from: number, to: number): boolean {
        let success: boolean = true;

        if (to < from) {
            this.rows.forEach(x => {
                if (x.position < from && x.position >= to) {
                    x.position = x.position + 1;
                }
            });
        }
        else if (to > from) {
            this.rows.forEach(x => {
                if (x.position > from && x.position <= to)
                    x.position = x.position - 1;
            });
        }
        else
            success = false;

        return success;
    }

    public addBlankRow(insertIndex: number = undefined): LayoutRow {
        let newRow: LayoutRow = this.createBlankRow();

        let index: number;

        // 07-30-2022 note:  the following condition does not seem correct:
        //
        //     "if (insertIndex) {"
        //
        // That condition fails if insertIndex equals zero.  Could that be
        // the correct intention of this code?  Need to discuss.
        // if (insertIndex) {
        if (insertIndex == 0) {
            index = insertIndex;
        } else if (insertIndex) {
            index = insertIndex;
        }
        else {
            index = this.rows.length;
        }

        newRow.position = index + 1;
        newRow.isLastRow = newRow.position == (this.rows.length + 1);

        if (!this.rows)
            this.rows = [];

        this.rows.splice(index, 0, newRow);

        if ((insertIndex == 0) || insertIndex) {
            for (let i: number = index+1; i < this.rows.length; i++) {
                this.rows[i].position = this.rows[i].position + 1;
            }
        }

        return newRow;
    }

    public addRowAbove(row: LayoutRow): LayoutRow {
        let newRow: LayoutRow = null;

        let rowIndex: number = this.findRowIndex(row);
        if (rowIndex >= 0) {
            //let insertIndex: number = (rowIndex > 0 ? rowIndex - 1 : 0);
            let insertIndex: number = rowIndex;
            newRow = this.addBlankRow(insertIndex);
        } 

        return newRow;
    }
    public addRowBelow(row: LayoutRow): LayoutRow {
        let newRow: LayoutRow = null;

        let rowIndex: number = this.findRowIndex(row);
        if (rowIndex >= 0) {
            let insertIndex: number = (rowIndex < this.rows.length ? rowIndex + 1 : rowIndex);
            newRow = this.addBlankRow(insertIndex);
        }

        return newRow;
    }

    public deleteRow(index: number) {
        this.rows = this.rows.filter(x => x.position != index + 1);

        for (let i: number = index; i < this.rows.length; i++) {
            this.rows[i].position = this.rows[i].position - 1;
        }
    }

    public get hasBlankRows(): boolean {
        let hasBlankRows: boolean = false;

        // Note:  ignore the last row.
        for (let index: number = 0; index < this.rows.length - 1; index++) {
            let row: LayoutRow = this.rows[index];
            if (!row.hasContent) {
                hasBlankRows = true;
                break;
            }
        }

        return hasBlankRows;
    }

    public deleteBlankRows(): void {
        let replacementRows: LayoutRow[] = [];

        // Note:  ignore the last row.
        for (let index: number = 0; index < this.rows.length - 1; index++) {
            let row: LayoutRow = this.rows[index];
            if (row.hasContent)
                replacementRows.push(row);
        }
        replacementRows.push(this.rows[this.rows.length - 1]);

        this.rows = replacementRows;
    }

    public applyChanges(): void {
        // Note:  the last row should have no content in it.
        let lastRow = this.rows.filter(x => x.isLastRow == true)[0];
        let priorDefaultCellCount: number = 0;

        if (lastRow) {
            let currentCells = lastRow.cells.length;
            priorDefaultCellCount = currentCells;

            // Assign column spans/column
            // counts to all existing cells.
            {
                let iPriorDefaultCellWidth: number = 12 / priorDefaultCellCount;

                for (let iRow: number = 0; iRow < this.rows.length; iRow++) {
                    let row: LayoutRow = this.rows[iRow];

                    for (let iCell: number = 0; iCell < row.cells.length; iCell++) {
                        let cell: LayoutCell = row.cells[iCell];
                        let iCellWidth: number = cell.width;

                        //let iCellColSpan = iCellWidth / iPriorDefaultCellWidth;
                        let iCellColSpan = Math.min(iCellWidth / iPriorDefaultCellWidth, this.columnsDefault);

                        cell.transientColSpan = iCellColSpan;
                    }
                }
            }

            while (currentCells != this.columnsDefault) {
                if (currentCells < this.columnsDefault) {
                    // Add a column to all rows.
                    //this.addColumn();
                    this.addCellToEachRow();

                    currentCells++;
                }
                else {
                    // Remove one or more columns from all rows.
                    //currentCells = this.removeColumns();
                    //this.removeCellFromEachRow();
                    let iNumCellsToRemove = currentCells - this.columnsDefault;
                    //this.removeCellsFromEachRow(currentCells);
                    this.removeCellsFromEachRow(currentCells, this.columnsDefault);

                    //currentCells--;
                    currentCells -= iNumCellsToRemove;
                }
            }
        }
        else {
            alert('formLayout.applyChanges():  error');
            return;
        }

        // Apply new styles to all cells
        // Note:  the original code assumes
        //        homogeneous cell widths for all rows.
        /*
        this.rows.forEach(row => {
            row.cells.forEach(cell => {
                cell.gridCssClass = this.getCssClass(cell.position - 1);
            });
        });
        */

        for (let iRow: number = 0; iRow < this.rows.length; iRow++) {
            let row: LayoutRow = this.rows[iRow];
            let rowCellCount: number = row.cells.length;

            // Calculate cell widths/Css classes based
            // on the colspan values calculated above.
            for (let iCell = 0; iCell < row.cells.length; iCell++) {
                let cell: LayoutCell = row.cells[iCell];

                let iCellWidth: number = (12 * cell.transientColSpan) / this.columnsDefault;
                cell.gridCssClass = `col-${iCellWidth}`;
            }
        } // for

        return;
    }

    public addColumn(): void {
        this.rows.forEach(row => {
            let newPos = row.cells.length;

            if (row.cells.length < this.columnsDefault) {
                let cell = new LayoutCell();
                cell.position = newPos + 1;
                cell.gridCssClass = this.getCssClass(cell.position - 1);
                row.cells.push(cell);
            }
        });
    }

    public addCellToEachRow(): void {
        // Note:  this method does essentially what the one above does,
        //        but it defers the calculation of the cell CSS class.
        for (let iRow: number = 0; iRow < this.rows.length; iRow++) {
            let row: LayoutRow = this.rows[iRow];

            if (row.cells.length < this.columnsDefault) {
                let newPos = row.cells.length;

                let cell = new LayoutCell();
                cell.position = newPos + 1;
                cell.gridCssClass = this.getCssClass(cell.position - 1);

                row.cells.push(cell);
            }
        }

        return;
    }

    public removeCellFromEachRow(): void {
        // Remove at most one cell per row.
        //
        // Note:  we will prevent the user
        //        from selecting a smaller
        //        column count than what is
        //        needed for all existing cells.
        for (let iRow: number = 0; iRow < this.rows.length; iRow++) {
            let row: LayoutRow = this.rows[iRow];

            if (row.cells.length > 1) {
                let poppedCell: LayoutCell = row.cells.pop();

                if (poppedCell.hasContent) {
                    // To do:  move this cell to the next row.
                } // if
            } // if
        } // for
        
        return;
    }

    public removeCellsFromEachRow(iPriorMaxCellCount: number, iMaxCellCount: number): void {
        let iNewMaxCellCount = this.columnsDefault;

        // Derive the new, default cell column width.
        let iPriorDefaultColWidth: number = (12 / iPriorMaxCellCount);
        //let iNewDefaultColWidth: number = (12 / this.columnsDefault);

        // Remove the specified number of cells from each row.
        let iRow: number = 0;
        let iRowCount = this.rows.length;

        //for (let iRow: number = 0; iRow < this.rows.length; iRow++) {
        while (iRow < iRowCount) {
            let row: LayoutRow = this.rows[iRow];

            // If we are already within the max cell count, do nothing/continue.
            if (row.cells.length <= iMaxCellCount) {
                iRow++;
                continue;
            }                

            // Add up row cell col spans.
            let iRowTotalColSpan: number = 0;
            //let newRow: LayoutRow = null;
            let newRowCells: LayoutCell[] = null;
            let iNewRowCellsWithContentCount: number = 0;

            for (let iCell: number = 0; iCell < row.cells.length; iCell++) {
                let cell: LayoutCell = row.cells[iCell];

                let iCellColSpan: number = cell.getColSpan(iPriorDefaultColWidth);

                //if (newRow != null) {
                if (newRowCells != null) {
                    // We already have a new cell -- use it.
                    //newRow.cells.push(cell);
                    newRowCells.push(cell);

                    if (cell.hasContent) {
                        iNewRowCellsWithContentCount++;
                    }
                } else if (iRowTotalColSpan + iCellColSpan <= iNewMaxCellCount) {
                    // Still within the allow width.
                    iRowTotalColSpan += iCellColSpan;
                } else {
                    // Push this and any subsequent
                    // cell to the next row.
                    //newRow = this.addBlankRow(iRow + 1);

                    //newRow.cells = [];
                    newRowCells = [];
                    //newRow.cells.push(cell);
                    newRowCells.push(cell);

                    if (cell.hasContent) {
                        iNewRowCellsWithContentCount++;
                    }
                } // if-else
            } // for

            // If we created a new row:
            //
            //     1. Pop the new rows cells from the prior row;
            //     2a. Check to see if the new row has any content;
            //     2b. Make sure it uses the full col span;
            //     2c. Add the new row; and
            //     2d. Update the while condition.
            if (newRowCells != null) {
                // 1. Pop the new rows cells from the prior row.
                let poppedCell: LayoutCell = null;
                //let iNumCellsToPop: number = newRow.cells.length;
                let iNumCellsToPop: number = newRowCells.length;
                while (iNumCellsToPop > 0) {
                    poppedCell = row.cells.pop();
                    iNumCellsToPop--;
                }

                // 2a. Do the new row cells contain any content?
                if (iNewRowCellsWithContentCount > 0) {
                    // 2b. Make sure it uses the full col span.
                    //while (newRow.cells.length < iNewMaxCellCount) {
                    while (newRowCells.length < iNewMaxCellCount) {
                        let blankCell: LayoutCell = new LayoutCell();
                        // Note:  column widths/styles get adjusted later.
                        blankCell.gridCssClass = `col-${iPriorDefaultColWidth}`;

                        //newRow.cells.push(blankCell);
                        newRowCells.push(blankCell);
                    }

                    // 2c. Update the while condition.
                    let newRow = this.addBlankRow(iRow + 1);
                    newRow.cells = newRowCells;

                    // 2d. Update the while condition.
                    iRowCount = this.rows.length;
                } // if
            }

            // Move to the next row.
            iRow++;
        } // while

        return;
    }

    public removeColumns(): number {
        for (let i: number = 0; i < this.rows.length; i++) {
            let row: LayoutRow = this.rows[i];
            let poppedCells: LayoutCell[] = [];

            if (row.cells.length > this.columnsDefault) {
                while (row.cells.length > this.columnsDefault) {
                    // get the last cell in the row
                    let cell = row.cells.pop();
                    if (cell.formFieldId > 0 || cell.name)
                        poppedCells.push(cell);
                }

                while (poppedCells.length > 0) {
                    // Insert row below
                    let newRow = this.addBlankRow(i + 1);

                    for (let j: number = 0; j < this.columnsDefault && poppedCells.length > 0; j++) {
                        let cell = poppedCells.pop();

                        cell.position = j + 1;

                        newRow.cells[j] = cell;
                    }
                }
            }
        }

        return this.columnsDefault;
    }

    public getCssClass(index: number): string {
        let columnWidth = this.columnWidths[index];
        if (columnWidth) {
            return 'col-' + columnWidth.toString();
        }
        else {
            console.log('Missing column width for column ' + index);
            return '';
        }
    }

    public clearSelection(exceptionCell: LayoutCell = null): void {
        // clear selected rows
        this.rows.forEach(x => x.isSelected = false);

        // clear selected cells 
        this.rows.forEach(x => {
            x.cells.forEach(y => {
                if ((exceptionCell == null) || (exceptionCell != y))
                    y.isSelected = false;
            })
        });
    }

    public get selectedCells(): LayoutCell[] {
        let selectedCells: LayoutCell[] = [];

        this.rows.forEach(r => {
            r.cells.forEach(c => {
                if (c.isSelected) {
                    selectedCells.push(c);
                }
            })
        });

        return selectedCells;
    }
    public get selectedCell(): LayoutCell {
        let selectedCells: LayoutCell[] = this.selectedCells;
        return (selectedCells != null) && (selectedCells.length == 1) ? selectedCells[0] : null;
    }
    public get selectedCellInfo(): LayoutCellInfo {
        let cellInfo: LayoutCellInfo = null;

        let numCellsSelected: number = 0;
        for (let rowIndex: number = 0; rowIndex < this.rows.length; rowIndex++) {
            let row: LayoutRow = this.rows[rowIndex];
            if (row.cells != null) {
                for (let cellIndex: number = 0; cellIndex < row.cells.length; cellIndex++) {
                    let cell: LayoutCell = row.cells[cellIndex];
                    if (cell.isSelected) {
                        numCellsSelected++;

                        if (cellInfo == null)
                            cellInfo = new LayoutCellInfo(rowIndex, cellIndex, row, cell);

                        break;
                    }
                }
            }            
        }

        return numCellsSelected == 1 ? cellInfo : null;
    }
    public getCellRowFor(cell: LayoutCell): LayoutRow {
        let resultLayoutRow: LayoutRow = null;

        for (let rowIndex: number = 0; rowIndex < this.rows.length; rowIndex++) {
            let layoutRow: LayoutRow = this.rows[rowIndex];
            if (layoutRow.cells != null) {
                for (let cellIndex: number = 0; cellIndex < layoutRow.cells.length; cellIndex++) {
                    let layoutCell: LayoutCell = layoutRow.cells[cellIndex];
                    if (layoutCell == cell) {
                        resultLayoutRow = layoutRow;
                        break;
                    }
                }
            }
        }

        return resultLayoutRow;
    }

    public findCellContaining(formFieldName: string): LayoutCell {
        let foundCell: LayoutCell = null;

        for (let rowIndex: number = 0; rowIndex < this.rows.length; rowIndex++) {
            let layoutRow: LayoutRow = this.rows[rowIndex];
            foundCell = layoutRow.cells.find(c => c.name == formFieldName);
            if (foundCell != null)
                break;
        }

        return foundCell;
    }
    public findCellInfoContaining(formFieldName: string): LayoutCellInfo {
        let foundCellInfo: LayoutCellInfo = null;

        for (let rowIndex: number = 0; rowIndex < this.rows.length; rowIndex++) {
            let layoutRow: LayoutRow = this.rows[rowIndex];

            if (layoutRow.cells != null) {
                for (let cellIndex: number = 0; cellIndex < layoutRow.cells.length; cellIndex++) {
                    let cell: LayoutCell = layoutRow.cells[cellIndex];
                    if (cell.name == formFieldName) {
                        foundCellInfo = new LayoutCellInfo(rowIndex, cellIndex, layoutRow, cell);
                        break;
                    }
                }
            }            
        }

        return foundCellInfo;
    }

    public findCellInfoContainingFieldId(formFieldId: number): LayoutCellInfo {
        let foundCellInfo: LayoutCellInfo = null;

        for (let rowIndex: number = 0; rowIndex < this.rows.length; rowIndex++) {
            let layoutRow: LayoutRow = this.rows[rowIndex];

            if (layoutRow.cells != null) {
                for (let cellIndex: number = 0; cellIndex < layoutRow.cells.length; cellIndex++) {
                    let cell: LayoutCell = layoutRow.cells[cellIndex];
                    if (cell.formFieldId == formFieldId) {
                        foundCellInfo = new LayoutCellInfo(rowIndex, cellIndex, layoutRow, cell);
                        break;
                    }
                }
            }
        }

        return foundCellInfo;
    }

    public fieldNameUpdated(originalFieldName: string, fieldId: number, updatedFieldName: string): void {
        for (let iRow: number = 0; iRow < this.rows.length; iRow++) {
            let row: LayoutRow = this.rows[iRow];

            for (let iCol: number = 0; iCol < row.cells.length; iCol++) {
                let cell: LayoutCell = row.cells[iCol];

                if (cell.name == originalFieldName) {
                    if ((fieldId == 0) || (fieldId == cell.formFieldId)) {
                        cell.name = updatedFieldName;

                        break; // 09-27-2021 change:  only update a first reference to this field.
                    }
                } // if
            } // for
        } // for
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let layoutClone: FormLayout = new FormLayout();

        layoutClone.columnsDefault = this.columnsDefault;
        layoutClone.alignment = this.alignment;

        layoutClone.columnWidths = [];
        if (this.columnWidths && (this.columnWidths.length > 0)) {
            for (let iIndex: number = 0; iIndex < this.columnWidths.length; iIndex++) {
                let iColWidth: number = this.columnWidths[iIndex];

                layoutClone.columnWidths.push(iColWidth);
            }
        }

        layoutClone.rows = [];
        if (this.rows && (this.rows.length > 0)) {
            for (let iRow: number = 0; iRow < this.rows.length; iRow++) {
                let row: LayoutRow = this.rows[iRow];

                let rowCopy: LayoutRow = <LayoutRow>row.clone();
                layoutClone.rows.push(rowCopy);
            }
        }

        /*
        let mapOfPropertiesToSkip: MapOfStrings = {
        };
        let mismatchedPropertyNames = ObjectPropertyUtil.propertyValuesMatch(this, layoutClone, mapOfPropertiesToSkip);
        */

        return (layoutClone);
    }

    public copy(objectToCopy: ICloneAndCopy): void {
        let layoutToCopy: FormLayout = <FormLayout>objectToCopy;

        this.columnsDefault = layoutToCopy.columnsDefault;
        this.alignment = layoutToCopy.alignment;

        this.columnWidths = [];
        if (layoutToCopy.columnWidths && (layoutToCopy.columnWidths.length > 0)) {
            for (let iIndex: number = 0; iIndex < layoutToCopy.columnWidths.length; iIndex++) {
                let iColWidth: number = layoutToCopy.columnWidths[iIndex];

                this.columnWidths.push(iColWidth);
            }
        }

        this.rows = [];
        if (layoutToCopy.rows && (layoutToCopy.rows.length > 0)) {
            for (let iRow: number = 0; iRow < layoutToCopy.rows.length; iRow++) {
                let rowToCopy: LayoutRow = layoutToCopy.rows[iRow];

                let rowCopy: LayoutRow = <LayoutRow>rowToCopy.clone();
                this.rows.push(rowCopy);
            }
        }

        return;
    }

    public selectFirstCell(): boolean {
        let cellSelected: boolean = false;

        this.clearSelection();

        let row: LayoutRow = this.rows[0];
        if ((row.cells != null) && (row.cells.length > 0)) {
            row.cells[0].isSelected = true;
            cellSelected = true;
        }

        return cellSelected;
    }
    public selectNextCell(): boolean {
        let cellSelected: boolean = false;

        let selectedCells: LayoutCell[] = this.selectedCells;
        if ((selectedCells != null) && (selectedCells.length == 1)) {
            let cell: LayoutCell = selectedCells[0];
            let row: LayoutRow = this.getCellRowFor(cell);
            let cellPosition: number = row.getPositionOfCell(cell);

            if (cellPosition >= 0) {
                this.clearSelection();

                if (cellPosition < row.cells.length - 1) {
                    row.cells[cellPosition + 1].isSelected = true;
                    cellSelected = true;
                } else {
                    let rowPosition: number = this.getPositionOfRow(row);
                    if (rowPosition < this.rows.length - 1)
                        row = this.rows[rowPosition + 1];
                    else
                        row = row = this.rows[0];

                    if ((row.cells != null) && (row.cells.length > 0)) {
                        row.cells[0].isSelected = true;
                        cellSelected = true;
                    }
                }
            }
        }

        return cellSelected;
    }
    public selectLastCell(): boolean {
        let cellSelected: boolean = false;

        this.clearSelection();

        let rowNum: number = this.rows.length - 1;
        let row: LayoutRow = this.rows[rowNum];

        if ((row.cells != null) && (row.cells.length > 0)) {
            let cell: LayoutCell = row.cells[row.cells.length - 1];
            cell.isSelected = true;
            cellSelected = true;
        }

        return cellSelected;
    }
    public getCellOffset(): number {
        let cellOffset: number = -1;

        let selectedCells: LayoutCell[] = this.selectedCells;
        if ((selectedCells != null) && (selectedCells.length == 1)) {
            let selectedCell: LayoutCell = selectedCells[0];
            let selectedRow: LayoutRow = this.getCellRowFor(selectedCell);
            let cellPosition: number = selectedRow.getPositionOfCell(selectedCell);

            let cellIndex: number = 0;
            for (let rowIndex: number = 0; rowIndex < this.rows.length; rowIndex++) {
                let row: LayoutRow = this.rows[rowIndex];
                if (row == selectedRow) {
                    if ((row.cells != null) && (cellPosition < row.cells.length)) {
                        cellIndex += cellPosition;
                        cellOffset = cellIndex;
                        break;
                    }
                } else
                    cellIndex += (row.cells != null ? row.cells.length : 0);
            }
        }

        return cellOffset;
    }

    public findFirstCellWithContent(): LayoutCellInfo {
        let firstCellWithContent: LayoutCellInfo = null;

        for (let iRow: number = 0; (iRow < this.rows.length) && (firstCellWithContent == null); iRow++) {
            let row: LayoutRow = this.rows[iRow];

            for (let iCol: number = 0; iCol < row.cells.length; iCol++) {
                let cell: LayoutCell = row.cells[iCol];

                if (cell.hasContent) {
                    firstCellWithContent = new LayoutCellInfo(iRow, iCol, row, cell);

                    break;
                }
            } // for
        } // for

        return firstCellWithContent;
    }

    // Helper methods.
    private createBlankRow(): LayoutRow {
        let newRow = new LayoutRow();

        newRow.cells = [];

        for (let i: number = 0; i < this.columnsDefault; i++) {
            let cell = new LayoutCell();

            cell.position = i + 1;
            cell.gridCssClass = this.getCssClass(i);

            newRow.cells.push(cell);
        }

        return newRow;
    }

    private findRowIndex(rowToFind: LayoutRow): number {
        let rowIndex: number = -1; // Means the row was not found.

        if (this.rows != null) {
            for (let index: number = 0; index < this.rows.length; index++) {
                let row: LayoutRow = this.rows[index];

                if (row.position == rowToFind.position) {
                    rowIndex = index;
                    break;
                }
            }
        }

        return rowIndex;
    }
}
