<div class="modal" id="loadingMask" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" style="width: 10px !important; margin-top: 300px;">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>
</div>


 <!--Added separate spinner/mask for polling operations so we can control it seperately and not run into overlapping loads and polls-->

<div class="modal" id="pollingMask" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" style="width: 10px !important; margin-top: 300px;">
        <mat-spinner color="warn" [diameter]="50"></mat-spinner>
    </div>
</div>



<!--<mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>-->
