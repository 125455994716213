import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
//import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';

import { HtmlElementInfo } from '../../models/component-scripting/html-element-info.model';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
//import { ComponentHierarchyService, IComponentHierarchyChanged } from '../../services/component-hierarchy.service';
import { StringUtil } from '../../utility-classes/string.util';
import { ISelectedElementInfo } from '../../models/component-scripting/iselected-element-info.interface';
import { SelectedComponentData } from '../../models/component-scripting/selected-component-data.model';
import { JQueryBrowserDriverService } from '../../services/jquery-browser-driver.service';
import { HtmlElementTypeService } from '../../services/html-element-type.service';
import { ElementTypeMetadata } from '../../models/component-scripting/element-type-metadata.model';
import { SelectedNodeElementComponent } from '../selected-node-element/selected-node-element.component';
import { UITestActionRecorderService } from '../../services/ui-test-action-recorder.service';
import { ElementTypeInfo } from '../../models/component-scripting/element-type-info.model';

@Component({
    selector: 'app-selected-component-info',
    templateUrl: './selected-component-info.component.html',
    styleUrls: ['./selected-component-info.component.scss']
})
export class SelectedComponentInfoComponent implements OnInit, OnChanges {
    // Properties.
    @Input() selectedComponent: ITestableComponent = null;

    private selectedComponentInfo: SelectedComponentData = null;

    @ViewChild('selectedNodeElementComponent') selectedNodeElementComponent: SelectedNodeElementComponent;

    // Constructor.
    public constructor(private jqueryBrowserDriverService: JQueryBrowserDriverService,
        private htmlElementTypeService: HtmlElementTypeService,
        private uiTestActionRecorderService: UITestActionRecorderService)
    {
    }

    public ngOnInit(): void {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedComponent'] != null) {
            let simpleChange: SimpleChange = changes['selectedComponent'];
            if (simpleChange != null) {
                let testableComponent: ITestableComponent = <ITestableComponent><unknown>simpleChange.currentValue;
                if (testableComponent != null)
                    this.handleComponentSelected(testableComponent);
                else
                    this.selectedComponentInfo = null;
            }
        }
    }

    // Methods called by my HTML code.
    public get ComponentSelected(): boolean {
        return this.selectedComponentInfo != null;
    }
    public get SelectedComponent(): ITestableComponent {
        return this.selectedComponentInfo != null ? this.selectedComponentInfo.component : null;
    }
    public get SelectedElementInfo(): ISelectedElementInfo {
        let selectedElementInfo: ISelectedElementInfo = <ISelectedElementInfo>this.selectedComponentInfo;
        return selectedElementInfo;
    }
    public get ComponentHasElements(): boolean {
        let hasElements: boolean = false;

        if (this.selectedComponentInfo != null) {
            hasElements = this.selectedComponentInfo.componentElementTypes.length > 0;
        }

        return hasElements;
    }
    public get ComponentNoElementsMessage(): string {
        let message: string = '';

        if ((this.selectedComponentInfo != null) && (!this.ComponentHasElements))
            message = `Component ${this.selectedComponentInfo.component.tagName.toLowerCase()} has no elements`;

        return message;
    }
    public get ComponentElementTypes(): string[] {
        return this.selectedComponentInfo.componentElementTypes;
    }
    public getElementTypeTitle(elementType: string): string {
        // Lookup the element type metadata for this element type.
        let elementTypeMetadata: ElementTypeMetadata = this.htmlElementTypeService.getMetadataForElementType(elementType);
        return (elementTypeMetadata.prettyElementTypeTitle != null ? elementTypeMetadata.prettyElementTypeTitle : StringUtil.capitalize(elementType));
    }
    public getComponentElementsOfType(elementType: string): HtmlElementInfo[] {
        return this.selectedComponentInfo.mapOfElementsByType[elementType];
    }
    public getElementTitle(elementInfo: HtmlElementInfo): string {
        return elementInfo.elementTitle;
    }
    public getElementTooltip(elementInfo: HtmlElementInfo): string {
        let tooltip = elementInfo.elementSubtype != null ? `${elementInfo.elementTitle} (${elementInfo.elementSubtype})` : `${elementInfo.elementTitle}`;
        // TO DO:  ADD AN ELEMENT SUB-TYPE, IF ONE HAS BEEN SPECIFIED.
        return tooltip;
    }
    public get TooltipPosition(): TooltipPosition {
        return 'right';
    }

    public get ElementSelected(): boolean {
        return this.selectedComponentInfo != null ? this.selectedComponentInfo.selectedElement != null : false;
    }

    public get ComponentDisplayTabNames(): string[] {
        let tabNames: string[] = [];

        if (this.selectedComponentInfo != null) {
            for (let tabName in this.selectedComponentInfo.mapOfElementTypesByTabName)
                tabNames.push(tabName);
        }

        return tabNames;
    }
    public getComponentElementsForTab(displayTabName: string): HtmlElementInfo[] {
        let elementData: HtmlElementInfo[] = [];

        if (this.selectedComponentInfo != null) {
            let tabElementInfo: ElementTypeInfo = this.selectedComponentInfo.mapOfElementTypesByTabName[displayTabName];

            if (tabElementInfo.elements != null) {
                elementData = tabElementInfo.elements;
            } else {
                let elementsForType: HtmlElementInfo[] = this.selectedComponentInfo.mapOfElementsByType[tabElementInfo.elementType];

                if (elementsForType != null) {
                    if ((tabElementInfo.elementSubtypes != null) && (tabElementInfo.elementSubtypes.length > 0))
                        elementData = elementsForType.filter(e => (e.displayTabName == displayTabName) && tabElementInfo.elementSubtypes.includes(e.elementSubtype));
                    else
                        elementData = elementsForType.filter(e => (e.displayTabName == displayTabName));
                }
            }
        }

        return elementData;
    }
        
    // Handle control events.
    public elementTypeIndexChanged(eventData: any): void {
        // NOTE:  THIS METHOD IS PRESENTLY A NOOP.
    }
    public onSelectedElementChanged(eventData: any): void {
        let elementType: string = this.selectedComponentInfo.componentElementTypes[this.selectedComponentInfo.selectedElementTypeIndex];
        let elements: HtmlElementInfo[] = this.selectedComponentInfo.mapOfElementsByType[elementType];

        this.selectedComponentInfo.selectedElement = elements.find(e => e.elementTitle == this.selectedComponentInfo.selectedElementName);
    }

    public onSelectedElementClicked(elementType: string, elementInfo: HtmlElementInfo): void {
        this.selectedComponentInfo.selectedElementName = elementInfo.elementTitle;

        // Note:  disabling the following line as the information logged would seem to be unnecessary.
        //this.uiTestActionRecorderService.selectElement(elementType, elementInfo);

        //let elementType: string = this.selectedComponentInfo.componentElementTypes[this.selectedComponentInfo.selectedElementTypeIndex];
        let elements: HtmlElementInfo[] = this.selectedComponentInfo.mapOfElementsByType[elementType];
        if (elements == null)
            throw `SelectComponentInfoComponent.onSelectedElementClicked():  could not find elements of type '${elementType}'.`;
        this.selectedComponentInfo.selectedElement = elements.find(e => e.elementTitle == this.selectedComponentInfo.selectedElementName);

        if (this.selectedComponentInfo.selectedElement != null) {
            this.selectedComponentInfo.selectedElementHasProperties = this.selectedComponentInfo.selectedElement.elementTypeMetadata.hasProperties(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element) &&
                (this.selectedComponentInfo.selectedElement.elementTypeMetadata.getProperties(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element).length > 0);
            this.selectedComponentInfo.selectedElementHasActions = this.selectedComponentInfo.selectedElement.elementTypeMetadata.hasActions(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);
            this.selectedComponentInfo.selectedElementHasInputs = this.selectedComponentInfo.selectedElement.elementTypeMetadata.hasInputs(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);
        }
    }
    public onSelectedElementDoubleClicked(elementType: string, elementInfo: HtmlElementInfo): void {
        if (elementInfo.elementTypeMetadata.hasActions(this.jqueryBrowserDriverService, elementInfo.element)) {
            if (this.selectedNodeElementComponent != null)
                this.selectedNodeElementComponent.onSelectedElementActionClicked();
            else {
                let getClickableElementFunction: (element: object) => object = elementInfo.additionalElementInfo != null ? elementInfo.additionalElementInfo.getClickableElementFunction : null;
                //elementInfo.elementTypeMetadata.click(this.jqueryBrowserDriverService, this.selectedComponent, elementInfo.element, getClickableElementFunction);
                elementInfo.elementTypeMetadata.click(this.jqueryBrowserDriverService, this.uiTestActionRecorderService, this.selectedComponent, elementInfo, elementInfo.element, getClickableElementFunction);
            }                
        } else if (elementInfo.elementTypeMetadata.hasInputs(this.jqueryBrowserDriverService, elementInfo.element)) {
            if (this.selectedNodeElementComponent != null)
                this.selectedNodeElementComponent.editSelectedInputValue();
        }
    }

    public onElementInfoClicked(elementInfo: HtmlElementInfo): void {
        this.onSelectedElementClicked(elementInfo.elementType, elementInfo);
    }
    public onElementInfoDoubleClicked(elementInfo: HtmlElementInfo): void {
        this.onSelectedElementDoubleClicked(elementInfo.elementType, elementInfo);
    }

    public getHtmlElementInfoFor(elementMetadataKey: string, elementSubtype: string, elementTitle: string, callNumber: number = 0): HtmlElementInfo {
        let htmlElementInfo: HtmlElementInfo = null;

        if (this.selectedComponentInfo != null) {            
            if (elementSubtype != null) {
                let elementTypeInfo: ElementTypeInfo = this.selectedComponentInfo.mapOfElementTypesByTabName[elementSubtype];
                if ((elementTypeInfo != null) && (elementTypeInfo.elements != null)) {
                    //htmlElementInfo = elementTypeInfo.elements.find(e => e.elementTitle == elementTitle);
                    htmlElementInfo = elementTypeInfo.elements.find(e => e.elementTitle.trim() == elementTitle);
                }                    
            } else {
                let elements: HtmlElementInfo[] = this.selectedComponentInfo.mapOfElementsByType[elementMetadataKey];
                if (elements != null) {
                    //htmlElementInfo = elements.find(e => e.elementTitle == elementTitle);
                    htmlElementInfo = elements.find(e => e.elementTitle.trim() == elementTitle);
                }                    
            }
        }

        // If the looked up information is null, an error, repeat this call one time to facilitate troubleshooting.
        if ((htmlElementInfo == null) && (callNumber == 0))
            this.getHtmlElementInfoFor(elementMetadataKey, elementSubtype, elementTitle, 1);

        return htmlElementInfo;
    }

    // Helper methods.
    private handleComponentSelected(component: ITestableComponent): void {
        this.selectedComponentInfo = SelectedComponentData.handleComponentSelected(component, this.uiTestActionRecorderService);
    }    
}
