import { Type, plainToClass } from 'class-transformer';

import { Workflow } from '../site-content/workflow.model';

export class PermissionsPreviewBase {
    // Properties.
    public id: number; // Note:  this property is not used by is required.

    public dataCollectionId: number; // The data collection for which this preview is being generated.

    // Permissions preview input properties.
    @Type(() => Workflow)
    public workflowHierarchy: Workflow[];

    // Constructor.
    protected constructor(objectToCopy: PermissionsPreviewBase) {
        if (objectToCopy != null) {
            this.id = objectToCopy.id;

            this.dataCollectionId = objectToCopy.dataCollectionId;

            this.workflowHierarchy = [];
            if (objectToCopy.workflowHierarchy != null) {
                for (let iWorkflow: number = 0; iWorkflow < objectToCopy.workflowHierarchy.length; iWorkflow++) {
                    let workflowAsObject: Workflow = objectToCopy.workflowHierarchy[iWorkflow];
                    let workflow: Workflow = plainToClass(Workflow, workflowAsObject);

                    this.workflowHierarchy.push(workflow);
                }
            }
        }
    }
}
