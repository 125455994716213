import {
    Directive,
    Input,
    HostListener,
    ElementRef
} from '@angular/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { DatePipe } from '@angular/common';

// Define, export a directive used to format a date 'matInput' control.
//
// Note:  much of the code in the following directive was taken from the following article:
//        https://medium.com/angular-in-depth/angular-material-matinput-control-with-thousands-separation-ebcbb7b027f4.
@Directive({
    selector: '[dateFormat]',
    providers:
        [
            { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: CustomDateFormatDirective }

            // Note:  could not make the directive implement NG_VALUE_ACCESSOR as component
            //        DateFormFieldComponent implements NG_VALUE_ACCESSOR (I forget the exact
            //        error message, but it will come back if the following code is enabled).
            /*
            {
                provide: NG_VALUE_ACCESSOR,
                useExisting: CustomDateFormatDirective,
                multi: true,
            }
            */
        ]
})
export class CustomDateFormatDirective {
    // Properties.
    @Input() formatString: string = null;

    private _value: string | null;
    private fnOnChange = (_ignored: any) => { }; // Initial, NOOP function.

    // Constructor.
    public constructor(private elementRef: ElementRef<HTMLInputElement>) {
        return;
    }

    // Life cycle methods.
    /*
    public ngOnInit(): void {
        return;
    }

    public ngOnDestroy(): void {
        return;
    }
    */
    // End life cycle methods.

    // Implement MAT_INPUT_VALUE_ACCESSOR.
    public get value(): string | null {
        return this._value;
    }

    @Input('value')
    public set value(value: string | null) {
        this._value = value;
        this.formatValue(value);
    }

    // Implement one of the NG_VALUE_ACCESSOR methods but not the interface itself
    // (per comments above).  As this directive does not implement NG_VALUE_ACCESSOR,
    // the following writeValue() method is not called by the Angular framework.
    // Instead, it it calls manually from code in DateFormFieldComponent.
    public writeValue(value: any): void {
        this._value = value;
        this.formatValue(this._value); // format Value
    }

    /*
    writeValue(value: any) {
        this._value = value;
        this.formatValue(this._value); // format Value
    }

    registerOnChange(fn: (value: any) => void) {
        this.fnOnChange = fn;
    }

    registerOnTouched() {
    }
    */
    // End NG_VALUE_ACCESSOR implementation.

    // Handle control methods.
    /*
    @HostListener('input', ['$event.target.value'])
    onInput(value) {
        // here we cut any non numerical symbols    
        //this._value = value.replace(/[^\d.-]/g, '');
        return;
    }
    */

    @HostListener('blur')
    _onBlur() {
        if (this.elementRef && this.elementRef.nativeElement && this.elementRef.nativeElement.value) {
            this.formatValue(this.elementRef.nativeElement.value);
        }

        return;
    }

    @HostListener('focus')
    onFocus() {
        //this.unFormatValue(); // remove commas for editing purpose
        return;
    }
    // End handling of control methods.

    // Private helper methods.
    private formatValue(value: string | null): void {
        if (value !== null) {
            this.elementRef.nativeElement.value = this.applyDateFormatting(value);
        } else {
            this.elementRef.nativeElement.value = '';
        }

        return;
    }

    private applyDateFormatting(value: string | null): string {
        //var datePipe: DatePipe = this.getDatePipe();
        let datePipe: DatePipe = new DatePipe('en-US');
        let formattedValue: string = datePipe.transform(value, this.formatString);

        return (formattedValue);
    }

    // Define a public version of applyDateFormatting() so the logic for such formatting
    // is only contained in this directive class (rather than being repeated in class DateFormFieldComponent).
    public static applyDateFormattingUsing(formatString: string, value: string): string {
        let datePipe: DatePipe = new DatePipe('en-US');
        let formattedValue: string = datePipe.transform(value, formatString);

        return (formattedValue);
    }

    /*
    private getDatePipe(): DatePipe {
        let datePipe: DatePipe = new DatePipe('en-US');
 
        return (datePipe);
    }
    */
}
