// Note:  named this file a bit differently so the name would not be excessively long.

//import { FormFieldConstraintBase } from './form-field-constraint-base.model';
import { FormField } from './../form-field.model';
import { FormInstanceElement } from './../form-instance-element.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../interfaces/iform-field-constraint.interface';
import { ICloneAndCopy } from '../../../interfaces/clone-and-copy';
import { FormFieldConstraintBase } from './form-field-constraint-base.model';
//import { SiteFormFieldConstraintProperties } from './site-form-field-constraint-props.model';
import { FormFieldConstraintTypeEnum } from '../../../enums/form-field-constraint-type.enum';

export class FormFieldSelectOptionsConstraint extends FormFieldConstraintBase implements IFormFieldConstraint, ICloneAndCopy {
    // Properties.
    protected selectOptionsConstraintValue: string;

    // Constructor.
    public constructor(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        super(dataCollectionId, constraintId, FormFieldConstraintTypeEnum.SelectOptions, constraintName);

        this.selectOptionsConstraintValue = constraintValue;
    }

    // Implement IFormFieldConstraint.
    // Property-related methods.
    public get type(): string {
        return FormFieldConstraintTypeEnum.SelectOptions;
    }

    public get constraintExpression(): string {
        return this.selectOptionsConstraintValue;
    }
    public set constraintExpression(value: string) {
        this.selectOptionsConstraintValue = value;
    }

    public get constraintExpressionSummary(): string {
        let constraintExprSummary: string = this.selectOptionsConstraintValue;

        const maxLength: number = 128;
        if ((this.selectOptionsConstraintValue != null) && (this.selectOptionsConstraintValue.length > maxLength)) {
            constraintExprSummary = '';

            let formField: FormField = new FormField();
            formField.selectOptions = this.selectOptionsConstraintValue;
            let selectOptions: string[] = FormField.ParseSelectOptions(formField);

            let index: number = 0;
            while ((index < selectOptions.length) && (constraintExprSummary.length < maxLength)) {
                if (index > 0)
                    constraintExprSummary += '|';

                constraintExprSummary += selectOptions[index++];
            }  
        }

        return constraintExprSummary;
    }

    // Not relevant for this field type
    public get constraintFailureMessage(): string {
        return null;
    }
    public set constraintFailureMessage(value: string) {
        // noop
    }

    // Logic-related methods.
    public validate(formField: FormField, formInstanceElement: FormInstanceElement): void {
        // TO DO:  IMPLEMENT THIS METHOD.
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let clone: FormFieldSelectOptionsConstraint = new FormFieldSelectOptionsConstraint(this.dataCollectionId, this.constraintId, this.constraintName, this.selectOptionsConstraintValue);

        //clone.constraintId = this.constraintId;
        //clone.constraintName = this.constraintName;
        //clone.selectOptionsConstraintValue = this.selectOptionsConstraintValue;

        return clone;
    }

    public copy(objectToCopyParam: ICloneAndCopy): void {
        let objectToCopy: FormFieldSelectOptionsConstraint = <FormFieldSelectOptionsConstraint>objectToCopyParam;

        this.dataCollectionId = objectToCopy.dataCollectionId;
        this.constraintId = objectToCopy.constraintId;
        this.constraintType = objectToCopy.constraintType;
        this.constraintName = objectToCopy.constraintName;
        this.selectOptionsConstraintValue = objectToCopy.selectOptionsConstraintValue;
    }
}

export class FormFieldSelectOptionsConstraintWithMetrics extends FormFieldSelectOptionsConstraint implements IFormFieldConstraintWithMetrics {
    // Properties.
    public useCount: number = 0;

    // Constructor.
    public constructor(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        super(dataCollectionId, constraintId, constraintName, constraintValue);
    }
}
