// Note:  this class provides string-related static methods.
export class StringUtil {
    public static isEmptyString(value: string): boolean {
        return ((value == null) || (value.toString().trim() == ''));
    }

    public static arrayToDelimitedList(array: string[], delimiter: string = ','): string {
        let list: string = '';

        if (array != null) {
            for (let index: number = 0; index < array.length; index++) {
                let value: string = array[index];

                if (index > 0)
                    list += delimiter;
                list += value;
            }
        }

        return list;
    }
    public static delimitedListToArray(list: string, delimiter: string = ','): string[] {
        let array: string[] = list.split(delimiter);
        return array;
    }

    public static capitalize(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    public static singular(value: string): string {
        let result: string = value;

        if ((value != null) && (value.length >= 1)) {
            let length: number = value.length;
            let lastChar: string = value.charAt(length - 1);
            if (lastChar.toLowerCase() == 's')
                result = value.slice(0, length - 1);
        }

        return result;
    }

    public static arrayToString(array: string[]): string {
        let concatenatedValues: string = '';

        for (let index: number = 0; index < array.length; index++) {
            let value: string = array[index];

            if ((index > 0) && (concatenatedValues != ''))
                concatenatedValues += '\r\n';
            if (value != null)
                concatenatedValues += value;
        }

        return concatenatedValues;
    }

    // Constructor cannot be called.
    private constructor() { }
}
