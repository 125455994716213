import { FormField, SecondaryGridCascadingFieldTransientData } from '../form-builder/form-field.model';
import { FieldDefinitionService } from '../../services/field-definition.service';
import { FieldDefinition } from '../form-builder/field-definition.model';
import { IFieldDefinitionLogic } from '../../interfaces/ifield-definition-logic.interface';
import { FormInstance } from '../site-content/form-instance.model';

export interface IFormFieldNameToFormField {
    [formFieldName: string]: FormField;
}

export class GridConfig {
    // Properties.
    private mode: string;
    private fieldDefinitionService: FieldDefinitionService;
    private iNextColumnDefId: number = 1;
    private columnDefs: FormField[] = [];
    private isFootnote: boolean = false;
    private hshFormFieldMNametoFormField: IFormFieldNameToFormField = {};

    private cachedGridColumnDefs: FormField[] = null; // Can be used by my caller ... no logic for this field whatsoever in this class.
    private conditionalLogicColumnDefs: FormField[] = null;

    // Constructor.
    // pharv - 3/5/2024 - added formInstance param. It's needed for VNEXT-1135 - flexible selection fields (for which FormInstance is needed to look up values of all flex fields)
    public constructor(mode: string, fieldDefinitionService: FieldDefinitionService, existingColDefsParam: FormField[] = null, formInstance: FormInstance = null) {
        this.mode = mode;
        this.fieldDefinitionService = fieldDefinitionService;

        if (existingColDefsParam && existingColDefsParam.length > 0) {
            this.columnDefs = existingColDefsParam;

            for (let iCol: number = 0; iCol < this.columnDefs.length; iCol++) {
                let columnDef: FormField = this.columnDefs[iCol];

                if ((columnDef.name != null) && (columnDef.name.trim() != "")) {
                    let strId: string = columnDef.name.substring(1);
                    let iClientId: number = parseInt(strId);
                    //VNEXT-1430: KLW - Due to changes from Angular 14 to 15, NaN cannot be use directly and instead we must use the method from the Numbers class
                    //Number.IsNan()
                    if (!Number.isNaN(iClientId)) {
                        columnDef.gridColClientId = iClientId;
                    } else {
                        this.assignUniqueClientId(iCol);
                    }
                } else {
                    this.assignUniqueClientId(iCol);
                }

                columnDef.fieldOrder = iCol;

                // Save the form field in a hash by name.
                this.hshFormFieldMNametoFormField[columnDef.name] = columnDef;
            }

            this.setNextColumnDefId();
        } // if


        return;
    }

    // Accessor methods.
    // Getter methods.
    public get ColumnCount(): number {
        return (this.columnDefs ? this.columnDefs.length : 0);
    }

    public get ColumnDefs(): FormField[] {
        return (this.mode == 'design' ? this.ColumnDefsWithVirtualFormFields : this.columnDefs);
    }
    public getRuntimeColumnDefsWithConfiguredVirtualFormFields(fieldDefinitionService: FieldDefinitionService): FormField[] {
        let columnDefs: FormField[] = this.ColumnDefs;

        for (let iCol: number = 0; iCol < columnDefs.length; iCol++) {
            let columnDef: FormField = columnDefs[iCol];
            GridConfig.configureAnyVirtualFormFields(iCol, columnDef, fieldDefinitionService, columnDefs);
        }

        return columnDefs;
    }

    public get CachedGridColumnDefs(): FormField[] {
        return this.cachedGridColumnDefs;
    }
    public set CachedGridColumnDefs(value: FormField[]) {
        this.cachedGridColumnDefs = value;
    }

    public get ConditionalLogicColumnDefs(): FormField[] {
        return this.conditionalLogicColumnDefs;
    }
    public set ConditionalLogicColumnDefs(value: FormField[]) {
        this.conditionalLogicColumnDefs = value;
    }
    // Define two static methods for dealing with grid virtual child form fields:
    //
    //     hasColumnDefWithVirtualFormFieldsFor();
    //      
    public static hasColumnDefWithVirtualFormFieldsFor(childFormFields: FormField[], fieldDefinitionService: FieldDefinitionService): boolean {
        let hasVirtualFields: boolean = false;

        for (let index: number = 0; index < childFormFields.length; index++) {
            let colDef: FormField = childFormFields[index];

            let fieldDef: FieldDefinition = fieldDefinitionService.getFieldDefinition(colDef.fieldDefinitionClassName);
            if ((fieldDef != null) && (fieldDef.customLogicHandler != null)) {
                if (fieldDef.customLogicHandler.hasVirtualSiblingFormFieldsFor(colDef)) {
                    hasVirtualFields = true;

                    break;
                }
            }
        }

        return hasVirtualFields;
    }
    public static getChildAndAnyVirtualFormFieldsFor(childFormFields: FormField[], fieldDefinitionService: FieldDefinitionService, maxClientId: number): FormField[] {
        let resultFormFields: FormField[] = [];

        for (let index: number = 0; index < childFormFields.length; index++) {
            let colDef: FormField = childFormFields[index];
            resultFormFields.push(colDef);

            let fieldDef: FieldDefinition = fieldDefinitionService.getFieldDefinition(colDef.fieldDefinitionClassName);
            if ((fieldDef != null) && (fieldDef.customLogicHandler != null)) {
                let virtualFormFields: FormField[] = fieldDef.customLogicHandler.getVirtualSiblingFormFieldsFor(colDef);

                if ((virtualFormFields != null) && (virtualFormFields.length > 0)) {
                    for (let virtualIndex: number = 0; virtualIndex < virtualFormFields.length; virtualIndex++) {
                        let virtualFormField: FormField = virtualFormFields[virtualIndex];
                        virtualFormField.clientId = ++maxClientId;
                        resultFormFields.push(virtualFormField);
                    }

                    // Save the virtual form fields in the source form field from which they were created.
                    colDef.transientVirtualFormFields = virtualFormFields;
                }
            } else if (fieldDef == null) {
                ; // Note:  this can happen when an entire page is refreshed as it can take time for the field definitions to load from the first/only time from server.
            } else
                console.log('GridFormFieldComponent.GridColumnDefs():  fieldDef.customLogicHandler() is null.');
        }

        return resultFormFields;
    }
    public static getVirtualSiblingFormFieldsFor(formField: FormField, fieldLogic: IFieldDefinitionLogic): FormField[] {
        let virtualFormFields: FormField[] = fieldLogic.getVirtualSiblingFormFieldsFor(formField);
        return virtualFormFields;
    }
    public static getMaxClientIdFor(childFormFields: FormField[]): number {
        let maxClientId: number = 0;

        for (let index: number = 0; index < childFormFields.length; index++) {
            if (childFormFields[index].clientId > maxClientId)
                maxClientId = childFormFields[index].clientId;
        }

        return maxClientId;
    }
    private get ColumnDefsWithVirtualFormFields(): FormField[] {
        let maxClientId: number = GridConfig.getMaxClientIdFor(this.columnDefs);
        let resultFormFields = GridConfig.getChildAndAnyVirtualFormFieldsFor(this.columnDefs, this.fieldDefinitionService, maxClientId);
        return resultFormFields;
    }

    public get IsFootnote(): boolean {
        return (this.isFootnote);
    }

    public setIsFootnote(isFootnoteParam: boolean) {
        this.isFootnote = isFootnoteParam;
    }

    // Additional query attribute methods.
    public getColumnDef(iColIndex: number): FormField {
        let colDef: FormField = null;

        if (this.columnDefs && (iColIndex >= 0) && (iColIndex < this.columnDefs.length)) {
            colDef = this.columnDefs[iColIndex];
        }

        return (colDef);
    }

    public getColumnDefAtIndex(index: number, fieldDefinitionClassName: string = null): FormField {
        let formField: FormField = ((this.columnDefs != null) && (index < this.columnDefs.length) ? this.columnDefs[index] : null); length

        if ((fieldDefinitionClassName != null) && (formField != null) && (formField.fieldDefinitionClassName != fieldDefinitionClassName))
            formField = null;

        return formField;
    }

    public getColumnDefByName(strColName: string): FormField {
        let formField: FormField = this.hshFormFieldMNametoFormField[strColName];

        return (formField);
    }

    public getColumnDefByClientId(iClientId: number): FormField {
        let columnDef: FormField = null;

        let searchColDefs: FormField[] = this.columnDefs.filter(cd => cd.gridColClientId == iClientId);
        if (searchColDefs && (searchColDefs.length == 1)) {
            columnDef = searchColDefs[0];
        }

        return (columnDef);
    }

    // Other lookup method(s).
    public ColumnIndex(formField: FormField): number {
        let iColIndex: number = -1;

        for (let iCol: number = 0; iCol < this.columnDefs.length; iCol++) {
            let colDef: FormField = this.columnDefs[iCol];

            if (colDef.id == formField.id) {
                iColIndex = iCol;

                break;
            }
        }

        return (iColIndex);
    }

    public get GridColumnNames(): string[] {
        let astrColumnNames: string[] = [];

        for (let iColDef: number = 0; iColDef < this.columnDefs.length; iColDef++) {
            let gridColDef: FormField = this.columnDefs[iColDef];

            astrColumnNames.push(gridColDef.name);
        }

        return (astrColumnNames);
    }

    public getColumnIndex(columnFormField: FormField): number {
        let iColIndex: number = -1;

        if (this.columnDefs) {
            for (let iCol: number = 0; iCol < this.columnDefs.length; iCol++) {
                let col: FormField = this.columnDefs[iCol];

                if (columnFormField.gridColClientId == col.gridColClientId) {
                    iColIndex = iCol;

                    break;
                } // if
            } // for
        } // if

        return (iColIndex);
    }

    // Data manipulation methods.
    public addGridColumn(fieldDefinitionClassNameParam: string): FormField {
        let iNewColumnId: number = this.iNextColumnDefId++;

        let newColumnDef: FormField = new FormField();

        newColumnDef.gridColClientId = iNewColumnId;

        this.setFieldOrderOnNewColumnDef(newColumnDef);

        newColumnDef.name = `c${iNewColumnId}`; // Create short names that can be used in the saved data.
        newColumnDef.fieldDefinitionClassName = fieldDefinitionClassNameParam;
        newColumnDef.displayName = newColumnDef.name;

        let fieldDef: FieldDefinition = this.fieldDefinitionService.getFieldDefinition(fieldDefinitionClassNameParam);
        newColumnDef.maxLength = fieldDef.customLogicHandler.getDefaultMaxLengthWhenInGrid();

        this.columnDefs.push(newColumnDef);

        this.setNextColumnDefId();

        return newColumnDef;
    }

    // Added for VNEXT-1312
    private setFieldOrderOnNewColumnDef(newColumnDef: FormField) {
        if (!this.columnDefs || this.columnDefs.length == 0) {
            newColumnDef.fieldOrder = 1;
        } else {
            let fieldOrders = this.columnDefs.map(x => { return x.fieldOrder; });
            let maxFieldOrder = Math.max(...fieldOrders);
            newColumnDef.fieldOrder = maxFieldOrder + 1;
        }
    }

    public removeGridColumn(iColumnClientId: number): boolean {
        let iNumExistingColumns = this.columnDefs.length;

        this.columnDefs = this.columnDefs.filter(cd => cd.gridColClientId !== iColumnClientId);

        this.setNextColumnDefId();

        return (iNumExistingColumns !== this.columnDefs.length);
    }

    public AdditionalCellDivStyleFor(columnDef: FormField): string {
        let strAddlCssClass: string = 'instance-and-preview-form-field-mat-cell-div';

        return (strAddlCssClass);
    }

    // Implement private helper methods.
    private setNextColumnDefId(): void {
        this.iNextColumnDefId = this.columnDefs.length + 1;
        let bNextColumnDefIdValidated: boolean = false;

        while (!bNextColumnDefIdValidated) {
            bNextColumnDefIdValidated = true;
            let strExistingColNameCheck: string = `c${this.iNextColumnDefId}`; // Cannot rename columns (if data already exists).

            for (let iCol: number = 0; iCol < this.columnDefs.length; iCol++) {
                let colDef: FormField = this.columnDefs[iCol];

                if (colDef.gridColClientId === this.iNextColumnDefId) {
                    bNextColumnDefIdValidated = false;
                } else if (colDef.name == strExistingColNameCheck) {
                    bNextColumnDefIdValidated = false;
                }
            } // for

            if (!bNextColumnDefIdValidated) {
                this.iNextColumnDefId++;
            } // if
        } // while

        return;
    }

    private assignUniqueClientId(iColIndexParam: number): void {
        let columnDef: FormField = this.columnDefs[iColIndexParam];

        let errorMsg = `GridConfig constructor:  encountered existing column ${columnDef.id} with an invalid 'name' attribute '${columnDef.name}'.`
        console.log(errorMsg);

        let iTestClientId: number = iColIndexParam + 1;
        let bClientIdFound: boolean = false;

        while (!bClientIdFound) {
            bClientIdFound = true;

            for (let iCol: number = 0; iCol < this.columnDefs.length; iCol++) {
                if (iCol != iColIndexParam) {
                    if (this.columnDefs[iCol].gridColClientId == iTestClientId) {
                        bClientIdFound = false;
                        iTestClientId++;

                        break;
                    } // if
                } // if
            } // for
        } // while

        columnDef.gridColClientId = iTestClientId;

        return;
    }

    public removeAnyCircularDependencies(): void {
        let colDefs: FormField[] = this.ColumnDefs;
        if (colDefs != null) {
            for (let index: number = 0; index < colDefs.length; index++) {
                let colDef: FormField = colDefs[index];
                colDef.removeAnyCircularDependencies();
            }
        }
    }

    // configureAnyVirtualFormFields() -- handle any virtual form fields for the current column/form field.
    //
    // Question:  what is going on in the following method and why is it necessary?
    //     Answer:  in a grid cascading dropdown field (not the ordinary, form-based cascading dropdown field), the first of the
    //              cascading fields, the leftmost grid column of the multi-column grid cascading dropdown field, needs to
    //              pass information to the susbsequent cascading fields.  Specifically, that first cascading field passes three
    //              pieces of information:
    //
    //                  1. Primary Field Component -- this allows the second cascading fields to access the first field's component;
    //                  2. Primary Field Config -- this is the configuration that should be shared by all of the related cascading
    //                                             field; and
    //                  3. Primary Field Data -- this is the data structure that must be shared by all of the related cacading fields.
    //
    //              Note:  the above three fields are now packaged in an instance of class SecondaryGridCascadingFieldTransientData.
    //
    //              In the following method, in the for loop, two main operations occur:
    //
    //                  a. Config and Data Copied -- the configuration and data class instances are copied from the generated
    //                                               sibling virtual form fields into the column definitions that will be passed
    //                                               to the secondary cascading field components as their form fields; and
    //                  b. Primary Field Virtual Fields Replaced -- we want the primary cascading field to have a reference to the
    //                                                              exact same copy of the grid column def/form field that will be
    //                                                              passed to the secondary cascading fields.  This makes coherent
    //                                                              communication from the primary cascading field to the secondary
    //                                                              cascading fields work correctly.
    //
    //              Other considerations:  while the secondary cascading fields' access to the primary field's component should be
    //              all that they would need to reference the primary field's configuration and data, there appear to be some life
    //              cycle inconsistencies and/or race conditions that make depending on the primary field's component not reliable
    //              in all situations; in such cases, the configuration or data object is used.  Having the primary field's
    //              component can be helpful, so it is copied/made available to the secondary cascading fields as well.  Now that
    //              three fields are packaged within a SecondaryGridCascadingFieldTransientData instance, it should be clearer that
    //              all three of these fields are generally provided as a package deal if possible ... it's just that the timing of
    //              copying/making available the primary field's component is not 100% reliable.
    private static configureAnyVirtualFormFields(iCol: number, columnDef: FormField, fieldDefinitionService: FieldDefinitionService, columnDefs: FormField[]): void {
        let fieldDef: FieldDefinition = fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName);
        let virtualSiblingFormFields: FormField[] = GridConfig.getVirtualSiblingFormFieldsFor(columnDef, fieldDef.customLogicHandler);
        if ((columnDef.transientVirtualFormFields == null) && (virtualSiblingFormFields != null) && (virtualSiblingFormFields.length > 0)) {
            columnDef.transientVirtualFormFields = [];

            // Replace the virtual form fields with the ones just created.
            for (let virtualFFIndex: number = 0; virtualFFIndex < virtualSiblingFormFields.length; virtualFFIndex++) {
                let siblingVirtualFormField: FormField = virtualSiblingFormFields[virtualFFIndex];
                let corresspondingColDef: FormField = columnDefs[iCol + 1 + virtualFFIndex];

                // a. Config and Data Copied -- see above for the comment.
                if (corresspondingColDef.secondaryGridCascadingFieldTransientData == null)
                    corresspondingColDef.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData(null, null, null);
                corresspondingColDef.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig = siblingVirtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig;
                corresspondingColDef.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData = siblingVirtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData;

                // b. Primary Field Virtual Fields Replaced -- see above for the comment.
                columnDef.transientVirtualFormFields[virtualFFIndex] = corresspondingColDef;
            }
        }
    }
}
