import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { DefaultFieldDefinitionClientLogic } from './default-field-def-client-logic';
import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { FormInstanceGridRow } from '../../site-content/form-instance-grid-row.model';

export class MetadataFieldDefinitionLogicBase extends DefaultFieldDefinitionClientLogic implements IFieldDefinitionLogic {
    // Constructor.
    public constructor(protected fieldName: string) {
        super();
    }

    public unpackTemplateInstancesRow(formField: FormField, colValue: FormInstanceElement, row: FormInstanceGridRow): any {
        let fieldValue: string = row[formField.name];
        return fieldValue;
    }
}
