import { DefaultFieldDefinitionClientLogic } from "./default-field-def-client-logic";
import { FormField } from '../form-field.model';

export class GridFieldDefLogic extends DefaultFieldDefinitionClientLogic {

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }

    public isATypeOfGridField(formField: FormField): boolean {
        return true;
    }
}
