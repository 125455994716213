// Note:  this class provides date-related static methods.
export class DateUtil {
    // Static data.
    private static shortDaysOfWeek: string[] =
        ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    private static shortMonthsOfYear: string[] =
        ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // UTC Date Methods.
    public static DateToUTCDate(strDate: string, dayOffset: number = 0, hourOffset: number = 0): Date {
        let iTimeSince_Jan01_1970: number = Date.parse(strDate);
        let dateValue: Date = new Date(iTimeSince_Jan01_1970);

        if (dayOffset != 0)
            dateValue.setDate(dateValue.getDate() + dayOffset);
        dateValue.setHours(hourOffset);
        dateValue.setMinutes(0);
        dateValue.setSeconds(0);

        return dateValue;
    }

    public static DateToSecondsSinceJan1_1970(strDate: string): number {
        let iTimeSince_Jan01_1970: number = Date.parse(strDate);
        return iTimeSince_Jan01_1970;
    }

    public static DateToUTCDateString(strDate: string, dayOffset: number = 0, hourOffset: number = 0): string {
        let dateValue: Date = this.DateToUTCDate(strDate, dayOffset, hourOffset);

        let strUTCDate: string = dateValue.toISOString();

        return strUTCDate;
    }

    public static TodayToUTCDate(dayOffset: number = 0, hourOffset: number = 0): Date {
        let dateValue: Date = new Date();

        if (dayOffset != 0)
            dateValue.setDate(dateValue.getDate() + dayOffset);
        dateValue.setHours(dateValue.getDate() + hourOffset);
        dateValue.setMinutes(0);
        dateValue.setSeconds(0);

        return dateValue;
    }

    public static TodayToUTCDateString(dayOffset: number = 0, hourOffset: number = 0): string {
        let dateValue: Date = this.TodayToUTCDate(dayOffset, hourOffset);

        let strUTCDate: string = dateValue.toISOString();

        return strUTCDate;
    }

    public static AsMMDDYYYY(date: Date): string {
        let month: string = (date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`);
        // Note:  use date.getDate() to get the day of the month.  Use date.getDay() to get the day of the week.
        let day: string = (date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`)
        let dateText: string = `${month}-${day}-${date.getFullYear()}`;

        return dateText;
    }

    // Methods related to dates formatted as the following example:
    //
    //     'Mon Jan 01 1900 00:00:00 GMT-0500 (Eastern Standard Time)'
    //
    // For our purposes, we will refer to the above format as the 'Long' format.
    public static longFormatTodayString(dayOffset: number = 0): string {
        let today: Date = new Date();
        //let dateWithOffset: Date = (dayOffset == 0 ? today : today.setDate(today.getDate() + dayOffset));
        if (dayOffset != 0)
            today.setDate(today.getDate() + dayOffset);

        let day: string = this.shortDaysOfWeek[today.getDay()];
        let month: string = this.shortMonthsOfYear[today.getMonth()];

        let dateText: string = DateUtil.getLongDate(day, month, today.getHours());

        return dateText;
    }

    public static convertDateToLocalTime(dateToConvert: Date): void {
        // Convert the date to local time.
        let local_date: Date = new Date();
        let iTimeOffsetInMinutes: number = local_date.getTimezoneOffset();

        dateToConvert.setHours(dateToConvert.getHours() - (iTimeOffsetInMinutes / 60));

        return;
    }

    private static getLongDate(day: string,
        month: string,
        hour: number,
        gmtOffset: string = 'GMT-0500',
        timeZone: string = 'Eastern Standard Time'): string {
        let longDate: string = `${day} ${month} ${hour}00 00:00:00 ${gmtOffset} (${timeZone})`;

        return longDate;
    }


}
