import { Type } from 'class-transformer';

import {
    STRUCTURE_PROPERTY_SELECT,
    STRUCTURE_PROPERTY_MODIFIED_BY,
    STRUCTURE_PROPERTY_MODIFIED_DATE,
    STRUCTURE_PROPERTY_NAME,
    STRUCTURE_PROPERTY_STATUS,
    STRUCTURE_PROPERTY_TEMPLATE
} from "../../components/list-view/grid-view/grid-view.component";

export interface IFieldRoleNamesChanged {
    roleNamesChangedFor(fieldConfig: StructureFieldConfig): void;
}

// Note:  the following data structure/class is based on https://maxjira.max.gov/browse/VNEXT-1340.
export class StructureFieldConfig {
    // Properties.
    public disableForSpecifiedRoles: boolean = false
    public roleNames: string[] = [];
    private fieldConfigRoleChangeHandler: IFieldRoleNamesChanged = null;

    // Constructor.
    public constructor(public fieldName: string) { }

    public setFieldConfigRoleChangedHandler(fieldConfigRoleChangeHandler: IFieldRoleNamesChanged): void {
        this.fieldConfigRoleChangeHandler = fieldConfigRoleChangeHandler;
    }

    // Getter, setter methods.
    public get DisableForSpecifiedRoles(): boolean {
        return this.disableForSpecifiedRoles;
    }
    public set DisableForSpecifiedRoles(value: boolean) {
        this.disableForSpecifiedRoles = value;
    }

    public get RoleNames(): string[] {
        return this.roleNames;
    }
    public set RoleNames(value: string[]) {
        this.roleNames = value;

        if (this.fieldConfigRoleChangeHandler != null)
            this.fieldConfigRoleChangeHandler.roleNamesChangedFor(this);
    }

    /*
    public get HasInconsistentConfig(): boolean {
        let hasInconsistentConfig: boolean = this.disableForSpecifiedRoles && (this.roleNames.length == 0);

        return hasInconsistentConfig;
    }
    */
    // Note:  the following getter methods returns a non-null string if there is a configuration error.
    public get ConfigInconsistencyError(): string {
        let error: string = null;

        if (this.disableForSpecifiedRoles && this.roleNames.length == 0) {
            error = `Please select one or more roles names for disabled field '${this.fieldName}'`;
        }

        return error;
    }
}
export class ConfigureStructureFields {
    // Constants.
    public static FormName_FieldName: string = "Form Name";
    public static ModifiedOn_FieldName: string = "Modified On";
    public static ModifiedBy_FieldName: string = "Modified By";
    public static FormTemplateName_FieldName: string = "Form Template Name";
    public static Status_FieldName: string = "Status";

    // Define a mapping of the names used in this class to the property names used in the structure's grid view listing.
    private static structureFieldNamesToMyFieldNamesMap = null;

    // Static data
    private static readonly fieldNames: string[] =
        [
            ConfigureStructureFields.FormName_FieldName,
            ConfigureStructureFields.ModifiedOn_FieldName,
            ConfigureStructureFields.ModifiedBy_FieldName,
            ConfigureStructureFields.FormTemplateName_FieldName,
            ConfigureStructureFields.Status_FieldName
        ];

    // Properties.
    @Type(() => StructureFieldConfig)
    private fieldConfigs: StructureFieldConfig[] = [];

    // Constructor.
    private constructor() {
        if (ConfigureStructureFields.structureFieldNamesToMyFieldNamesMap == null) {
            ConfigureStructureFields.structureFieldNamesToMyFieldNamesMap = {
                "Form Name": STRUCTURE_PROPERTY_NAME,
                "Modified On": STRUCTURE_PROPERTY_MODIFIED_DATE,
                "Modified By": STRUCTURE_PROPERTY_MODIFIED_BY,
                "Form Template Name": STRUCTURE_PROPERTY_TEMPLATE,
                "Status": STRUCTURE_PROPERTY_STATUS
            }
        }
    }

    public setFieldConfigRoleChangedHandler(fieldConfigRoleChangeHandler: IFieldRoleNamesChanged): void {
        if (this.fieldConfigs != null) {
            for (let index: number = 0; index < this.fieldConfigs.length; index++) {
                let fieldConfig: StructureFieldConfig = this.fieldConfigs[index];
                fieldConfig.setFieldConfigRoleChangedHandler(fieldConfigRoleChangeHandler);
            }
        }
    }

    // Getter/getter-like method(s).
    public get FieldConfigs(): StructureFieldConfig[] {
        return this.fieldConfigs;
    }

    public getRoleNamesForField(fieldName: string): string[] {
        let roleNames: string[] = [];

        let fieldConfig: StructureFieldConfig = this.fieldConfigs.find(fc => fc.fieldName == fieldName);
        if (fieldConfig != null)
            roleNames = fieldConfig.roleNames;

        return roleNames;
    }

    public get HasAtLeastOneHiddenField(): boolean {
        let hasAtLeastOneHiddenField: boolean = false;

        if (this.fieldConfigs != null) {
            let hiddenFields: StructureFieldConfig[] = this.fieldConfigs.filter(fc => fc.disableForSpecifiedRoles);
            hasAtLeastOneHiddenField = hiddenFields.length > 0;
        }

        return hasAtLeastOneHiddenField;
    }

    // Note:  the following getter methods returns a non-null string if there is a configuration error.
    public get ConfigInconsistencyError(): string {
        let error: string = null;

        if (this.fieldConfigs != null) {
            for (let index: number = 0; index < this.fieldConfigs.length; index++) {
                error = this.fieldConfigs[index].ConfigInconsistencyError;
                if (error != null)
                    break;
            }
        }

        return error;
    }

    public filterStructureColumnNames(defaultColumnNames: string[], userRoleNames: string[]): string[] {
        let filteredColumnNames: string[] = defaultColumnNames;

        let disabledFieldDefs: StructureFieldConfig[] = this.fieldConfigs.filter(fc => fc.disableForSpecifiedRoles == true);
        for (let index: number = 0; index < disabledFieldDefs.length; index++) {
            // If the user is in ANY of the groups not allowed to see the current, then we must filter out that field from the result list.
            let disabledFieldDef: StructureFieldConfig = disabledFieldDefs[index];
            let intersectedRoleNames: string[] = disabledFieldDef.RoleNames.filter(rn => userRoleNames.includes(rn));
            let disabledForUserGroups: boolean = intersectedRoleNames.length > 0;

            if (disabledForUserGroups) {
                let disabledStructureFieldName: string = ConfigureStructureFields.structureFieldNamesToMyFieldNamesMap[disabledFieldDef.fieldName];

                if (disabledStructureFieldName != null) {
                    filteredColumnNames = filteredColumnNames.filter(fcn => fcn != disabledStructureFieldName);
                }
            }
        }

        return filteredColumnNames;
    }

    // Static methods.
    public static createEmptyConfig(): ConfigureStructureFields {
        let config: ConfigureStructureFields = new ConfigureStructureFields();

        for (let index: number = 0; index < ConfigureStructureFields.fieldNames.length; index++) {
            let fieldConfig: StructureFieldConfig = new StructureFieldConfig(ConfigureStructureFields.fieldNames[index]);
            config.fieldConfigs.push(fieldConfig);
        }

        return config;
    }

    public static parseFromJson(jsonConfig: string): ConfigureStructureFields {
        let configObject: ConfigureStructureFields = <ConfigureStructureFields>JSON.parse(jsonConfig);
        //let config = plainToClass(ConfigureStructureFields, configObject);
        let config: ConfigureStructureFields = new ConfigureStructureFields();
        config.fieldConfigs = [];
        if (configObject.fieldConfigs != null) {
            for (let index: number = 0; index < configObject.fieldConfigs.length; index++) {
                let fieldConfigObject: StructureFieldConfig = <StructureFieldConfig>configObject.fieldConfigs[index];
                if ((fieldConfigObject.fieldName != null) && (fieldConfigObject.fieldName.trim() != '')) {
                    let fieldConfig: StructureFieldConfig = new StructureFieldConfig(fieldConfigObject.fieldName);
                    fieldConfig.disableForSpecifiedRoles = fieldConfigObject.disableForSpecifiedRoles;
                    fieldConfig.roleNames = fieldConfigObject.roleNames;

                    config.fieldConfigs.push(fieldConfig);
                }                
            }
        }

        // Make sure there is an entry for ever field.
        if (config.fieldConfigs == null)
            config.fieldConfigs = [];
        for (let index: number = 0; index < ConfigureStructureFields.fieldNames.length; index++) {
            let fieldName: string = ConfigureStructureFields.fieldNames[index];
            let fieldConfig: StructureFieldConfig = config.fieldConfigs.find(fc => fc.fieldName == fieldName);
            if (fieldConfig == null) {
                let fieldConfig: StructureFieldConfig = new StructureFieldConfig(ConfigureStructureFields.fieldNames[index]);
                config.fieldConfigs.push(fieldConfig);
            }
        }

        return config;
    }

    public static toJsonString(config: ConfigureStructureFields): string {
        let json: string = JSON.stringify(config);
        return json;
    }
}
