import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { IFormPreRenderFilter } from '../../../interfaces/iform-pre-render-filter.interface';
import { Form } from '../form.model';
import { FormField } from '../form-field.model';
import { HideFromPreRenderFilter } from './hide-from-pre-render-filter';
import { ShowToHideFromCustomLogicBase } from './show-to-hide-from-logic-base';

export class HideFromFieldDefinitionCustomLogic extends ShowToHideFromCustomLogicBase implements IFieldDefinitionLogic { // IFormPreRenderFilter {
    public constructor() {
        super();
    };

    // Implement some IFieldDefinitionLogic methods.
    public pushFormPreRenderFilterFor(formField: FormField): IFormPreRenderFilter {
        let filter: IFormPreRenderFilter = null;
        // As a show to or hide from has a begin and an end form field, only return a filter for the begin field.
        if (formField.isConditionalBeginElement)
            filter = new HideFromPreRenderFilter(formField);
        return filter;
    }
    public popFormPreRenderFilter(filter: IFormPreRenderFilter, formField: FormField): boolean {
        let pop: boolean = false;

        if (filter instanceof HideFromPreRenderFilter) {
            let showToFilter = <HideFromPreRenderFilter>filter;
            let filterFormField: FormField = showToFilter.FormField;

            pop = ShowToHideFromCustomLogicBase.popFilter(filterFormField, formField);
        }

        return pop;
    }

    // Implement IFormPreRenderFilter.
    // Note:  investigate to see if support for interface IFormPreRenderFilter is needed in this class.
    /*
    public allowFormInstanceElement(form: Form, formField: FormField, userRoleNames: string[], formInstanceElement): boolean {
        return false;
    }
    public markFieldAsReadOnly(presentlyUnusedForm: Form, presentlyUnusedFormField: FormField, userRoleNames: string[], presentlyUnusedFormInstanceElement): boolean {
        return false; // A default behavior only.
    }
    */

    public filterType(): string {
        return null;
    }
}
