import { Injectable } from '@angular/core';

// Define an enumeration of status updates.
export enum ProgressStatusUpdateEnum {
    PROGRESS_UPDATED = 1,
    PROGRESS_COMPLETED = 2
}

// Define a progress update class.
export class ProgressUpdateInfo {
    public statusValue: number;
    public bufferValue: number;

    constructor(statusValue: number, bufferValue: number) {
        this.statusValue = statusValue;
        this.bufferValue = bufferValue;
    }

    public completed(): boolean {
        return this.statusValue == 100 && this.bufferValue == 100;
    }

    public canHide(): boolean {
        return this.statusValue == null && this.bufferValue == null;
    }
}

// This could be expanded to include settings for status and buffer
export class ProgressConfig {
    public msgDuring: string;
    public msgOnComplete: string;

    constructor(msgDuring: string, msgOnComplete: string) {
        this.msgDuring = msgDuring;
        this.msgOnComplete = msgOnComplete;
    }

    // Returns aProgressConfig instance with defaults set
    // Could be expanded to include defaults for status and buffer
    public static default(): ProgressConfig {
        return new ProgressConfig(null, null);
    }
}

// Define a notification interface (a component
// can implement this to get notifications).
export interface IProgressStatusUpdateListener {
    //set ProgressListenerId(idParam: number);
    //setProgressListenerId(idParam: number): void;
    //getProgressListenerId(): number;
    progressListenerId: number;

    progressUpdated(statusMessage: ProgressStatusUpdateEnum,
        statusText: string,
        status: ProgressUpdateInfo): void;
}

// Define/implement the service.
@Injectable()
export class ProgressIndicatorService {
    // Instance data.
    private iNextListenerId: number = 1;
    private listeners: IProgressStatusUpdateListener[] = [];

    // Service methods.
    public registerListener(listener: IProgressStatusUpdateListener): void {
        listener.progressListenerId = this.iNextListenerId;
        this.iNextListenerId++;

        this.listeners.push(listener);

        return;
    }

    public removeListener(listener: IProgressStatusUpdateListener): void {
        this.listeners =
            this.listeners.filter(l => l.progressListenerId !== listener.progressListenerId);

        return;
    }

    // Could create more of these helpers if warranted
    public completed50Percent(statusText: string): void {
        this.updateProgress(50, 75, statusText);
    }

    public completed100Percent(statusText: string): void {
        this.updateProgress(100, 100, statusText);
    }

    public updateProgress(statusValue: number, bufferValue: number, statusText: string): void {
        let progressInfo = new ProgressUpdateInfo(statusValue, bufferValue);

        if (progressInfo.completed()) {
            // Nested setTimeouts aim for a smooth, simulated completion 
            // This can be revisited and perhaps involve checking http Event
            setTimeout(() => {
                this.update(statusText, progressInfo);
                setTimeout(() => {
                    this.hideProgress();
                }, 0);
            }, 0);
        } else if (progressInfo.canHide()) {
            this.hideProgress();
        } else {
            this.update(statusText, progressInfo);
        }
    }

    private update(statusText: string, progressInfo: ProgressUpdateInfo): void {
        // Notify listeners of progress changes.
        for (var iListener: number = 0; iListener < this.listeners.length; iListener++) {
            var listener: IProgressStatusUpdateListener = this.listeners[iListener];

            if (progressInfo) {
                listener.progressUpdated(ProgressStatusUpdateEnum.PROGRESS_UPDATED, statusText, progressInfo);
            } else {
                listener.progressUpdated(ProgressStatusUpdateEnum.PROGRESS_COMPLETED, statusText, null);
            }
        }

        return;
    }

    // hides the progress indicator
    public hideProgress(): void {
        this.update(null, null);
    }

    // Using the progress indicator as a message center - it's not actually
    // displaying progress, just a message which expires after a few seconds
    public message(msg: string, timer?: number): void {
        let showFor = timer || 2000;
        for (var iListener: number = 0; iListener < this.listeners.length; iListener++) {
            var listener: IProgressStatusUpdateListener = this.listeners[iListener];

            var info = new ProgressUpdateInfo(100, 100);
            listener.progressUpdated(ProgressStatusUpdateEnum.PROGRESS_UPDATED, msg, info);
            setTimeout(() => {
                this.hideProgress();
            }, showFor);

        }
    }
}
