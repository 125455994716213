import { Component, OnInit, Renderer2, Output, EventEmitter, Type as AngularCoreType, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DecimalInputFormFieldBaseComponent } from '../num-input-form-field-base/decimal-input-form-field-base.component';
import { DecimalFormatDirective } from '../../../directives/decimal-format-directive';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';

@Component({
    selector: 'app-decimal-form-field',
    templateUrl: './decimal-form-field.component.html',
    styleUrls:
        [
            './decimal-form-field.component.scss',
            '../form-fields.scss',
            '../num-input-form-field-base/num-input-form-field-base.component.scss',
            '../currency-form-field/currency-form-field.component.scss'
        ],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DecimalFormFieldComponent,
            multi: true
        }
    ]
})
export class DecimalFormFieldComponent extends DecimalInputFormFieldBaseComponent implements OnInit {
    // Properties.
    private userChangedFieldValue: boolean = false;
    private formattedPreviewValue: string = '';
    public hasFocus: boolean = false;

    @ViewChild(DecimalFormatDirective, { read: DecimalFormatDirective }) decimalFormatDirective: DecimalFormatDirective;

    public constructor(rendererParam: Renderer2) {
        super(rendererParam);
        this.matInputId = this.generateUniqueId('decimal');
    }

    // Life cycle methods.
    public ngOnInit(): void {
        super.ngOnInit();
    }

    // Form field methods.
    public getProperties(): any {
        let properties: any = super.getProperties();

        let propertyNames: FormFieldPropertyEnum[] = <FormFieldPropertyEnum[]>properties['properties'];
        propertyNames.push(FormFieldPropertyEnum.NUM_DIGITS_AFTER_DECIMAL_POINT);
        propertyNames.push(FormFieldPropertyEnum.SHOW_TRAILING_ZEROES_AFTER_DECIMAL_POINT);

        return properties;
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (DecimalFormFieldComponent);
    }

    public onChange() {
        this.formattedPreviewValue = this.decimalFormatDirective.applyDecimalFormatting(this.FormControl.value);
    }

    // Methods called by my HTML code.
    public get DecimalStepIncrementExpr(): number {
        let increment: number = 1;
        for (let index: number = 0; index < this.FormField.numDigitsAfterDecimalPoint; index++)
            increment /= 10;
        return increment;
    }

    public get DigitsToShow(): number {
        return (this.FormField.numDigitsAfterDecimalPoint);
    }

    public get ShowTrailing(): boolean {
        return (this.FormField.showTrailingZeroesAfterDecimalPoint);
    }

    public get NumberFormatPreview(): string {
        return this.formattedPreviewValue;
    }

    // Define a method called before a form instance save.
    public CheckFieldForValidation(): void {
        super.CheckFieldForValidation();
    }

    public saveData(formInstance: any): void {
        if (!this.userChangedFieldValue) {
            if ((this.FormInstanceElement.transientOriginalDecimalValue != null) && (this.FormInstanceElement.transientOriginalDecimalValue != this.FormInstanceElement.decimalValue))
                this.FormInstanceElement.decimalValue = this.FormInstanceElement.transientOriginalDecimalValue;
        }
        this.userChangedFieldValue = false;
    }

    // Override a protected method.
    protected writeValueTriggered(): void {
        super.writeValueTriggered();

        if (this.controlData.formControl) {
            if (this.FormInstanceElement.decimalValue != null) {
                if (this.decimalFormatDirective) {
                    this.decimalFormatDirective.writeValue(this.FormInstanceElement.decimalValue);
                    this.controlData.formControl.setValue(this.decimalFormatDirective.value);
                }
            }
        }
    }

    public formFieldUpdated(): void {
        this.writeValueTriggered();
    }

    public handleOnModelChange(): void {
        if (this.decimalFormatDirective) {
            this.decimalFormatDirective.writeValue(this.FormInstanceElement['unformattedDecimalValue']);
            this.FormInstanceElement['unformattedDecimalValue'] = null;
        }
        super.handleOnModelChange();

        return;
    }

    public handleOnFocus(eventData: FocusEvent): void {
        this.hasFocus = true;
        this.formattedPreviewValue = this.decimalFormatDirective.applyDecimalFormatting(this.FormControl.value);
    }

    public handleOnBlur() {
        this.hasFocus = false;
    }
}
