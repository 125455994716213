import {
    Component,
    Renderer2,
    OnInit,
    AfterViewInit,
    Output,
    EventEmitter,
    Type as AngularCoreType
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms'; // Used for Reactive Forms

import { FormFieldMode } from '../form-field-mode.enum';
import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
//import { InputFormFieldBaseComponent } from '../input-form-field-base/input-form-field-base.component';
import { NumericInputFormFieldBaseComponent } from '../num-input-form-field-base/num-input-form-field-base.component';
import { IntegerInputFormFieldBaseComponent } from '../num-input-form-field-base/integer-input-form-field-base.component';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';

// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-integer-form-field',
    templateUrl: './integer-form-field.component.html',
    styleUrls: [
        './integer-form-field.component.scss',
        '../form-fields.scss',
        '../num-input-form-field-base/num-input-form-field-base.component.scss'
    ],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: IntegerFormFieldComponent,
            multi: true
        }
    ]
})
export class IntegerFormFieldComponent extends IntegerInputFormFieldBaseComponent implements OnInit {//, AfterViewInit {
    // Properties.
    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();

    private readonly formFieldProperties: string[] =
        [FormFieldPropertyEnum.NAME,
        FormFieldPropertyEnum.FIELD_GROUP,
        FormFieldPropertyEnum.REQUIRED,
        FormFieldPropertyEnum.DISPLAY_NAME,
        FormFieldPropertyEnum.BLANK_VALUE,
        FormFieldPropertyEnum.HELP_TEXT,
        FormFieldPropertyEnum.PLACEHOLDER_TEXT,
        FormFieldPropertyEnum.TOOL_TIP,
        FormFieldPropertyEnum.DEFAULT_VALUE,
        FormFieldPropertyEnum.MIN_VALUE,
        FormFieldPropertyEnum.MAX_VALUE,
        FormFieldPropertyEnum.SHOW_PREVIEW,
        FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
        FormFieldPropertyEnum.GRID_COLUMN_WIDTH
        ];

    // Constructor.
    constructor(rendererParam: Renderer2) {
        super(rendererParam);

        this.matInputId = this.generateUniqueId('integer');

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties
        };

        return (hshProperties);
    }

    // Life cycle methods.
    public ngOnInit(): void {
        /*
        if (this.Mode === FormFieldMode.DESIGN) {
            this.onInit.emit({ formField: this.formField, properties: this.formFieldNames });
        }
        */
        //this.onInit.emit({ component: this, formField: this.formField, properties: this.formFieldNames });
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);

        return;
    }

    // Accessor methods.
    /*
    public get DesignModePreviewValue(): string {
        let previewValue: string = this.DefaultValue;

        if (previewValue == '') {
            previewValue = '0';
        }

        return (previewValue);
    }
    */

    // Override base class methods.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                // If I have no value assigned but
                // have a default value, apply it now.
                if (this.FormInstanceElement.UserUpdatedData != true) {
                    this.setDefaultIntegerValue();
                }

                // Use a base class method to set up an integer form group.
                this.setupIntegerFormControl();
            } // if (this.controlType === ControlType.REACTIVE_FORMS)
        } // if ((this.Mode === 'preview') || (this.Mode === 'instance'))

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                this.SetupFormControlFromWriteValue();
            } // if (this.controlType === ControlType.REACTIVE_FORMS)
        } // if ((this.Mode === 'preview') || (this.Mode === 'instance'))

        return;
    }

    public formFieldUpdated(): void {
        this.writeValueTriggered();
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): string {
        if (!formInstanceElementParam.UserUpdatedData) {
            FormFieldBaseComponent.setDefaultIntegerValueFor(formFieldParam, formInstanceElementParam);
        }

        let strValue: string =
            (formInstanceElementParam.intValue ?
                new Intl.NumberFormat('en-us', { minimumFractionDigits: 0 }).format(formInstanceElementParam.intValue) :
                '');

        return (strValue);
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (IntegerFormFieldComponent);
    }

    public hasNumericData(): boolean {
        return (true);
    }
    public getNumericValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): number {
        if (!formInstanceElementParam.UserUpdatedData) {
            FormFieldBaseComponent.setDefaultIntegerValueFor(formFieldParam, formInstanceElementParam);
        }

        //return (formInstanceElementParam.intValue ? formInstanceElementParam.intValue : 0);
        let numValue: number = 0;
        if (formInstanceElementParam.intValue) {
            if (typeof formInstanceElementParam.intValue == 'number') {
                numValue = formInstanceElementParam.intValue;
            } else {
                numValue = parseInt((<any>formInstanceElementParam.intValue).toString());
                //VNEXT-1430: KLW - Due to changes from Angular 14 to 15, NaN cannot be use directly and instead we must use the method from the Numbers class
                //Number.IsNan()
                if (Number.isNaN(numValue))
                    numValue = 0;
            }
        }
        return numValue;
    }
}
