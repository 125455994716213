import { AsyncJobService } from '../../services/async-job.service';
import { AsyncJob } from '../../models/async-job.model';
import { IJobSummaryText } from '../../models/export-import/i-job-summary-text';

export const JOB_COMPLETED_MESSAGE: string = 'Completed';

export abstract class AsyncJobDialogBase {
    // Properties.
    protected asyncJobInfo: AsyncJob = null;
    protected timeoutsSinceJobInitiated: number = 0;
    protected jobInProgress: boolean = false;
    protected jobCompleted: boolean = false;
    protected jobErrorOccurred: boolean = false;
    protected jobErrorText: string = null;
    protected jobCompletedMessageText: string = null;
    protected intervalTimeoutId: any = null;
    private jobCompletedCallback: any = null;
    private jobUpdatedCallback: any = null;
    private timeoutTimeInMilliseconds: number = 1000; // Default value only.

    // Constructor.
    protected constructor(private asyncJobService: AsyncJobService) {
        return;
    }

    // Abstract method.
    public abstract get ComponentClassName(): string;

    protected resetIdleTimer() {
        const event = new CustomEvent("ResetIdleTimer")
        document.dispatchEvent(event);
    }

    // Implement one methods required by interface IAsyncJobMonitor.
    public getAppDataForRestoration(asyncJob: AsyncJob): string {
        let appData = {
            componentName: this.ComponentClassName,
            jobKey: asyncJob.jobKey
        };

        let appDataString: string = JSON.stringify(appData);
        return appDataString;
    }

    // Methods that can be accessed by component HTML code.
    public get JobInProgress(): boolean {
        return (this.jobInProgress);
    }

    public get JobTimeElapsed(): string {
        let time: string = null;

        if (this.timeoutsSinceJobInitiated < 60) {
            time = `${this.timeoutsSinceJobInitiated} seconds`;
        } else {
            let iMinutes: number = Math.floor(this.timeoutsSinceJobInitiated / 60);
            let iSeconds: number = (this.timeoutsSinceJobInitiated % 60);

            if (iSeconds < 10) {
                time = `${iMinutes}:0${iSeconds}`;
            } else {
                time = `${iMinutes}:${iSeconds}`;
            }
        }

        return (time);
    }

    public reInit(): void {
        this.asyncJobInfo = null;
        this.timeoutsSinceJobInitiated = 0;
        this.jobInProgress = false;
        this.jobCompleted = false;
        this.jobErrorOccurred = false;
        this.jobErrorText = null;
        this.jobCompletedMessageText = null;
        this.intervalTimeoutId = null;
        this.jobCompletedCallback = null;
        this.jobUpdatedCallback = null;
    }

    public get JobInitiated(): boolean {
        let bJobInitiated: boolean = (this.asyncJobInfo != null);

        return (bJobInitiated);
    }

    public get JobCompleted(): boolean {
        let bJobCompleted: boolean = ((this.asyncJobInfo != null) && (!this.jobInProgress));

        return (bJobCompleted);
    }

    public get JobErrorOccurred(): boolean {
        return (this.jobErrorOccurred);
    }

    public set JobErrorOccurred(jobErrorOccurred: boolean) {
        this.jobErrorOccurred = jobErrorOccurred;
    }

    public get JobErrorText(): string {
        return (this.jobErrorText);
    }

    // Handle some control events.

    // Method to start monitoring a job.
    protected startMonitoringJob(asyncJobInfo: AsyncJob, jobCompletedCallback: (asyncJob: AsyncJob) => void, jobUpdatedCallback: (asyncJob: AsyncJob) => void = null, timeoutTimeInMilliseconds: number = 1000): void
    {
        // Save the provided data.
        this.asyncJobInfo = asyncJobInfo;
        this.asyncJobInfo.appData = this.getAppDataForRestoration(this.asyncJobInfo);

        this.jobCompletedCallback = jobCompletedCallback;
        this.jobUpdatedCallback = jobUpdatedCallback;
        this.timeoutTimeInMilliseconds = timeoutTimeInMilliseconds;

        // Update status and set a recurring timeout.
        this.jobInProgress = true;
        this.intervalTimeoutId = setInterval(this.handleIntervalTimeout, this.timeoutTimeInMilliseconds);

        return;
    }

    // Helper methods available to derived classes.
    protected deriveExecutionSummaryText(metrics: IJobSummaryText): string {
        let summaryText: string = '';

        if (this.jobErrorOccurred && (this.jobErrorText != null) && (this.jobErrorText.trim() != '')) {
            summaryText = this.jobErrorText;

            if (metrics != null) {
                if ((metrics.getErrorLog() != null) && (metrics.getErrorLog().trim() != '')) {
                    summaryText += "\r\n";
                    summaryText += metrics.getErrorLog();
                } else if ((metrics.getWarningLog() != null) && (metrics.getWarningLog().trim() != '')) {
                    summaryText += "\r\n";
                    summaryText += metrics.getWarningLog();
                }
            }
        } else if (metrics != null) {
            summaryText = metrics.getJobSummaryText();
        }

        return (summaryText);
    }

    protected parseCreatedSiteId(asyncJob: AsyncJob): number {
        let iSiteId: number = -1; // Indicates an error.

        if ((asyncJob.stdOut != null) && (asyncJob.stdOut.trim() != '')) {
            let siteParts: string[] = asyncJob.stdOut.split(':');
            if ((siteParts != null) && (siteParts.length == 2)) {
                iSiteId = parseInt(siteParts[1]);
            }
        }

        return (iSiteId);
    }
    protected parseResultObjectId(asyncJob: AsyncJob): number {
        let iObjectId: number = -1; // Indicates an error.

        if ((asyncJob.stdOut != null) && (asyncJob.stdOut.trim() != '')) {
            let siteParts: string[] = asyncJob.stdOut.split(':');
            if ((siteParts != null) && (siteParts.length == 2)) {
                iObjectId = parseInt(siteParts[1]);
            }
        }

        return (iObjectId);
    }

    // Private helper methods.
    private handleIntervalTimeout = (): void => {
        this.timeoutsSinceJobInitiated++;

        // Every five timeouts, check the status of the copy job.
        if (this.timeoutsSinceJobInitiated % 2 == 0) {
            this.asyncJobService.getJob(this.asyncJobInfo.jobKey).then(asyncJob => {
                if (asyncJob.status == AsyncJob.STATUS_COMPLETE) {
                    this.jobInProgress = false;
                    this.jobCompleted = true;
                    this.jobCompletedMessageText = asyncJob.stdOut;
                } else if (asyncJob.status == AsyncJob.STATUS_COMPLETE_ERROR) {
                    this.jobErrorOccurred = true;
                    this.jobCompleted = true;
                    this.jobInProgress = false;
                    this.jobErrorText = asyncJob.stdError;
                    this.jobCompletedMessageText = asyncJob.stdOut;
                }

                if (this.jobCompleted) {
                    clearInterval(this.intervalTimeoutId);

                    // If the caller provided a job completed callback, call it now.
                    if (this.jobCompletedCallback != null) {
                        this.jobCompletedCallback(asyncJob);
                    }
                } else if (this.jobUpdatedCallback != null) {

                    this.jobUpdatedCallback(asyncJob);
                }
            });
        }
    }

}
