
export enum TokenCode {
    TOKEN_END_OF_INPUT = 'endOfInput',
    TOKEN_SYNTAX_ERROR = 'syntaxError',

    TOKEN_COMMA = 'comma',
    TOKEN_SEMICOLON = 'semicolon',
    TOKEN_STRING_SINGLE_QUOTES = 'stringInSingleQuotes',
    TOKEN_LEFT_PAREN = 'leftParen',
    TOKEN_RIGHT_PAREN = 'rightParen',
    TOKEN_IDENTIFIER = 'identifier',
    TOKEN_NUMBER = 'number',
    TOKEN_TRUE = 'true',
    TOKEN_FALSE = 'false',
    TOKEN_NULL = 'null',

    TOKEN_FUNCTION_CALL = 'functionCall'
};
