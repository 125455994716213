import { ClickableElementTypeMetadata } from './clickable-element-type-metadata.model';
import { ElementCountExpected } from './element-count-expected.enum';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
import { HtmlElementTypeNames } from './html-element-type-names.model';
import { AdditionalElementInfo } from './additional-element-info.model';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
import { HtmlElementInfo } from './html-element-info.model';

export class MatIconsElementTypeMetadata extends ClickableElementTypeMetadata {
    public constructor() {
        super('mat-icon', HtmlElementTypeNames.matIcons_metadataKey, ElementCountExpected.Singular_or_Plural);
        this.prettyElementTypeTitle = 'Material Icons';
    }

    public getTitle = (driver: IBrowserDriver, element: object, additionalElementInfo: AdditionalElementInfo = null): string => {
        let title: string = driver.getMatIconTitle(element);
        return title;
    }

    //public click(driver: IBrowserDriver, component: ITestableComponent, element: object, getClickableElementFunction: (element: object) => object): void {
    public click(driver: IBrowserDriver, recorderService: ITestActionRecorderService, component: ITestableComponent, htmlElementInfo: HtmlElementInfo, element: object, getClickableElementFunction: (element: object) => object): void {
        //super.click(driver, component, element, getClickableElementFunction);
        super.click(driver, recorderService, component, htmlElementInfo, element, getClickableElementFunction);

        /*        
        if ((element['parentNode'] != null) && (element['parentNode']['parentNode'] != null))
            driver.clickButton(component, element['parentNode']['parentNode']);
        */
    }

}
