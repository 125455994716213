export class AsyncJob {

    public static readonly STATUS_NEW: string = "New";
    public static readonly STATUS_RUNNING: string = "Running";
    public static readonly STATUS_COMPLETE: string = "Completed";
    public static readonly STATUS_COMPLETE_ERROR: string = "Completed with error";

    public jobKey: string;
    public jobDescription: string;
    public status: string;
    public appData: string; // Added 05-02-2022 to correspond to the new property in the server's corresponding view model class.
    public userId: string;
    public errorDetails: string;
    public stdOut: string;
    public stdError: string;
    public whenCreated: string;
    public whenUpdated: string;
    public whenInitiated: string;
    public whenCompleted: string;

    public id: number = -1; // we just need this in order for generics to work on base service

    // Define transient properties.
    //public transientDataCollectionId: number;
}
