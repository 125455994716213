// Note:  named this file a bit differently so the name would not be excessively long.
import { plainToClass } from 'class-transformer';

//import { FormFieldConstraintBase } from './form-field-constraint-base.model';
import { FormField } from './../form-field.model';
import { FormInstanceElement } from './../form-instance-element.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../interfaces/iform-field-constraint.interface';
import { ICloneAndCopy } from '../../../interfaces/clone-and-copy';
import { FormFieldConstraintBase } from './form-field-constraint-base.model';
import { FormFieldConstraintTypeEnum } from '../../../enums/form-field-constraint-type.enum';

export class NumericRangeDataForJsonSerialization {
    // Properties.
    public minValue: number = null;
    public maxValue: number = null;

    // Constructor.
    public constructor(minValue: number, maxValue: number) {
        this.minValue = minValue;
        this.maxValue = maxValue;
    }

    // JSON-related methods.
    public toJson(): string {
        let json: string = JSON.stringify(this);
        return json;
    }
    public static fromJson(json: string): NumericRangeDataForJsonSerialization {
        let object = ((json != null) && (json.trim() != '') ? JSON.parse(json) : null);
        let result: NumericRangeDataForJsonSerialization = (object != null ? plainToClass(NumericRangeDataForJsonSerialization, object) : null);
        return result;
    }
}

export class FormFieldNumericRangeConstraint extends FormFieldConstraintBase implements IFormFieldConstraint, ICloneAndCopy {
    // Properties.
    public minValue: number = null;
    public maxValue: number = null;

    // Constructor.
    /*
    public constructor(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        super(dataCollectionId, constraintId, FormFieldConstraintTypeEnum.NumericRange, constraintName);

        if (constraintValue != null)
            this.constraintExpression = constraintValue;
    }
    */
    public constructor() {
        super(0, 0, FormFieldConstraintTypeEnum.NumericRange, null);
    }

    // Static methods.
    // constructConstraint() -- a constructor-like method.
    public static constructConstraint(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        let constraint = new FormFieldNumericRangeConstraint();

        constraint.dataCollectionId = dataCollectionId;
        constraint.constraintId = constraintId;
        constraint.constraintType = FormFieldConstraintTypeEnum.NumericRange;
        constraint.constraintName = constraintName;
        if (constraintValue != null)
            constraint.constraintExpression = constraintValue;

        return constraint;
    }

    // Implement IFormFieldConstraint.
    // Property-related methods.
    public get type(): string {
        return FormFieldConstraintTypeEnum.NumericRange;
    }

    public get constraintExpression(): string {
        let objectForSerialization = new NumericRangeDataForJsonSerialization(this.minValue, this.maxValue);
        let json = objectForSerialization.toJson();
        return json;
    }
    public set constraintExpression(value: string) {
        let serializationObject: NumericRangeDataForJsonSerialization = NumericRangeDataForJsonSerialization.fromJson(value);
        if (serializationObject != null) {
            this.minValue = serializationObject.minValue;
            this.maxValue = serializationObject.maxValue;
        } else {
            this.minValue = null;
            this.maxValue = null;
        }
    }

    public get constraintExpressionSummary(): string {
        let summary: string = '';

        if ((this.minValue != null) && (this.maxValue != null))
            summary = `${this.minValue} to ${this.maxValue}`;
        else if (this.minValue != null)
            summary = `Equal to or greater than ${this.minValue}`;
        else if (this.maxValue != null)
            summary = `Less than or equal to ${this.maxValue}`;

        return summary;
    }

    // Not relevant for this field type
    public get constraintFailureMessage(): string {
        return null;
    }
    public set constraintFailureMessage(value: string) {
        // noop
    }

    // Logic-related methods.
    public validate(formField: FormField, formInstanceElement: FormInstanceElement): void {
        // TO DO:  IMPLEMENT THIS METHOD.
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        //let clone: FormFieldNumericRangeConstraint = new FormFieldNumericRangeConstraint(this.dataCollectionId, this.constraintId, this.constraintName);
        let clone = FormFieldNumericRangeConstraint.constructConstraint(this.dataCollectionId, this.constraintId, this.constraintName);

        //clone.constraintId = this.constraintId;
        //clone.constraintName = this.constraintName;
        clone.minValue = this.minValue;
        clone.maxValue = this.maxValue;

        return clone;
    }

    public copy(objectToCopyParam: ICloneAndCopy): void {
        let objectToCopy: FormFieldNumericRangeConstraint = <FormFieldNumericRangeConstraint>objectToCopyParam;

        this.dataCollectionId = objectToCopy.dataCollectionId;
        this.constraintId = objectToCopy.constraintId;
        this.constraintType = objectToCopy.constraintType;
        this.constraintName = objectToCopy.constraintName;
        this.minValue = objectToCopy.minValue;
        this.maxValue = objectToCopy.maxValue;
    }
}

export class FormFieldNumericRangeConstraintWithMetrics extends FormFieldNumericRangeConstraint implements IFormFieldConstraintWithMetrics {
    // Properties.
    public useCount: number = 0;

    // Constructor.
    public constructor(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        //super(dataCollectionId, constraintId, constraintName, constraintValue);
        super();

        this.dataCollectionId = dataCollectionId;
        this.constraintId = constraintId;
        this.constraintName = constraintName;

        if (constraintValue != null)
            this.constraintExpression = constraintValue;
    }
}
