import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormRendererComponent } from '../components/form-renderer/form-renderer.component';
import { FormField } from '../models/form-builder/form-field.model';
import { ProgressIndicatorService } from './progress-indicator.service';
import { ChangedSelectionFieldValues } from '../models/flexible-selection-fields/changed-selection-field-values.model';
//import { ListConstraintValue } from '../models/flexible-selection-fields/list-constraint-value.model';
import { ListConstraintColumn } from '../models/flexible-selection-fields/list-constraint-column.model';
import { SelectionFieldValueChangeInstructions } from '../models/flexible-selection-fields/selection-field-value-change-instructions.model';
import { CollectApiServiceBase } from './collect-api-base.service';
import { FormInstanceElement } from '../models/form-builder/form-instance-element.model';
import { environment } from '../../../environments/environment';
import { FormInstance } from '../models/site-content/form-instance.model';
import { FlexibleSelectionFieldInstructionsRequest } from '../models/flexible-selection-fields/flexible-selection-field-instructions-request.model';
import { FlexibleSelectionFieldType } from '../models/form-builder/form-field-types';
import { Subject, Observable } from "rxjs";

export interface FieldIdToSingleOption { [key: number]: string }

@Injectable()
export class FlexibleSelectionFieldService extends CollectApiServiceBase<SelectionFieldValueChangeInstructions> {

    private formRenderer: FormRendererComponent;
    private newGridFieldValues: ChangedSelectionFieldValues[];
    private gridsUseCachedInstructions: boolean;
    private previouslySelectedValues: FormInstanceElement[];
    // pharvey - added 4/2/2024 for VNEXT-1231
    private autoSelectFieldValuesSubject: Subject<FieldIdToSingleOption> = new Subject<FieldIdToSingleOption>(); // allows changes to be pushed to subscribers
    private autoSelectFieldValues: FieldIdToSingleOption = null; // allows proactive look ups

    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'flexibleSelectionField', SelectionFieldValueChangeInstructions)
    }

    public requestFlexibleFieldInstructions(id: number) {
        let url = `${this.url}/${this.endpoint}/getFlexibleFieldInstructionsForFormInstance/${id}`;
        this.http.get<SelectionFieldValueChangeInstructions>(url)
            .toPromise()
            .then(instructions => {
                this.updateTargetFields(instructions);
            });
    }

    public selectionFieldValueChanged(
        formField: FormField,
        formInstance: FormInstance,
        formInstanceElement: FormInstanceElement,
        parentValues: FormInstanceElement[],
        gridRowId: number = 0,
        gridColumnDefs: FormField[] = null): Promise<boolean> {

        if (this.formRenderer.hasDependentFields(formField)) {
            // the values of a flex field's parent may be needed to disambiguate selectable values. This is true for those cases where multiple options have the *same* dependent values
            // For example MANY different Bureaus have the same account name -- "Salaries and Expenses" -- so when "Salaries and Expenses" is selected, the server algorithm
            // needs to know for which Bureau it was selected. In that case, parentValues would contain the selected Bureau(s)
            parentValues = parentValues ? parentValues : this.previouslySelectedValues;

            // For VNEXT-1259 - pick up here
            // I am relying on sending a FormInstance expecting it to contain all the previous selections, BUT for GridRows it WON'T
            // So maybe I need a new construct which contains the chain of fields and their selected values - whether from the FormInstance or from the GridRow?
            // OR maybe I just need to make sure that the FormInstanceElements GridCellData is updated?

            // 1. Get the FormInstanceElement for this field from the parent field?
            //console.log('FORMINSTANCE');
            //console.log(formInstance);
            //console.log('FORMFIELD?');
            //console.log(formField);
            //for (let el of formInstance.formInstanceElements) {
            //    console.log(`Element: field=${el.formFieldId} value=${el.TextValue} matches?=${}`);
            //}

            let payload = new FlexibleSelectionFieldInstructionsRequest(formInstance, formInstanceElement, parentValues, gridRowId, gridColumnDefs);
            let url = `${this.url}/${this.endpoint}/GetFlexibleFieldInstructionsForField`;
            return this.http.post<SelectionFieldValueChangeInstructions>(url, payload)
                .toPromise()
                .then(instructions => {
                    this.updateTargetFields(instructions, formInstance, gridColumnDefs);
                    let hasDependentGridFields = this.newGridFieldValues?.length > 0;

                    // If a flex field has dependent fields that are in a Grid, we need to force those grid fields to use what's in the cache
                    // This is because the source field's value is still only in the UI, not on the server, so calls to the server to get
                    // instructions will come back with instructions for the previously saved selection of the field.
                    // THINK ABOUT:     Probably, the most robust way to handle this is to save the value of a source field when it changes.
                    //                  Since the calulation of instructions is done on the server, this would ensure the value is always
                    //                  correct and would enable the client code to be simpler. PROBLEM WITH THIS: CLicking "Discard" wouldn't
                    //                  discard this change. So Maybe this saved value could be saved as part of the pending edit? T
                    if (hasDependentGridFields && !this.gridsUseCachedInstructions && gridColumnDefs == null) {
                        this.gridsUseCachedInstructions = true;
                    }

                    let iAmNotAGridField = this.newGridFieldValues?.filter(x => { return x.formFieldId == formField.id }).length == 0;

                    this.previouslySelectedValues = formInstanceElement.childFormInstanceElements;

                    return iAmNotAGridField && hasDependentGridFields;
                });
        }
    }

    // This method is called when a grid row is opened for editing (it is NOT called when flex fields in a row have their selections changed)
    // If gridsUseCachedInstructions is true, the method does NOT make a call to the server but rather uses instructions returned by
    // a previous call to get instructions
    public getFlexibleSelectFieldInstructionsForGridRow(formInstanceId: number, rowData: any, gridColFieldDefs: FormField[], formInstance: FormInstance = null) {
        this.autoSelectFieldValues = {};
        let flexFields = gridColFieldDefs.filter(x => { return x.fieldDefinitionClassName == FlexibleSelectionFieldType })
        if (flexFields.length == 0) return;

        // TODO: This needs to be thought through further. Currently only the first flex field can depend on a field outside the grid
        let firstFlexField = flexFields[0];

        let parentEl = formInstance.formInstanceElements.find(x => x.formFieldId == firstFlexField.dependsOnParentFormFieldId);

        if (this.gridsUseCachedInstructions) {
            for (let newFieldValues of this.newGridFieldValues) {
                let formFieldId = newFieldValues.formFieldId;
                let targetFormField: FormField = gridColFieldDefs.filter(x => { return x.id == formFieldId; })[0];
                let options = newFieldValues.constraintValues.map(x => { return x.textValue; });
                targetFormField.setSelectOptions(options.join('|'));
                this.updateAutoSelectFieldValues(options, targetFormField);
            }
        }
        else if (rowData && rowData[firstFlexField.name]) {
            let formInstanceElement = new FormInstanceElement();
            formInstanceElement.childFormInstanceElements = [];
            let values = rowData[firstFlexField.name]?.split(","); // this could be problematic, but we need to split the display values into child formInstanceElements
            for (let value of values) {
                let childEl = new FormInstanceElement();
                childEl.ValueType = "text";
                childEl.TextValue = value;
                formInstanceElement.childFormInstanceElements.push(childEl);
            }
            formInstanceElement.formInstanceId = formInstanceId;
            formInstanceElement.formFieldId = firstFlexField.id;

            let payload = new FlexibleSelectionFieldInstructionsRequest(formInstance, formInstanceElement, parentEl.childFormInstanceElements, 0, gridColFieldDefs, true);
            let url = `${this.url}/${this.endpoint}/GetFlexibleFieldInstructionsForField`;
            this.http.post<SelectionFieldValueChangeInstructions>(url, payload)
                .toPromise()
                .then(instructions => {
                    this.updateTargetFields(instructions);
                    for (let newFieldValues of this.newGridFieldValues) {
                        let formFieldId = newFieldValues.formFieldId;
                        let targetFormField: FormField = gridColFieldDefs.filter(x => { return x.id == formFieldId; })[0];
                        let options = newFieldValues.constraintValues.map(x => { return x.textValue; });
                        targetFormField.setSelectOptions(options.join('|'));
                        this.updateAutoSelectFieldValues(options, targetFormField);
                    }
                });
        } else {
            for (let newFieldValues of this.newGridFieldValues) {
                let formFieldId = newFieldValues.formFieldId;
                let targetFormField: FormField = gridColFieldDefs.filter(x => { return x.id == formFieldId; })[0];
                if (targetFormField) {
                    let options = newFieldValues.constraintValues.map(x => { return x.textValue; });
                    targetFormField.setSelectOptions(options.join('|'));
                    this.updateAutoSelectFieldValues(options, targetFormField);
                }
            }
        }

        this.autoSelectFieldValuesSubject.next(this.autoSelectFieldValues);
    }

    // Takes a SelectionFieldValueChangeInstructions object and uses the formRenderer
    // to update the options of all fields referenced in it
    private updateTargetFields(instructions: SelectionFieldValueChangeInstructions, formInstance: FormInstance = null, gridColumnDefs: FormField[] = null) {
        this.autoSelectFieldValues = {};
        for (let newFieldValues of instructions.fieldSelectableValues) {
            let formFieldId = newFieldValues.formFieldId;
            let targetFormField: FormField = this.formRenderer.form.formFields.filter(x => { return x.id == formFieldId; })[0];
            let options = newFieldValues.constraintValues.map(x => { return x.textValue; });
            if (targetFormField) {
                targetFormField.setSelectOptions(options.join('|'));
                this.updateAutoSelectFieldValues(options, targetFormField);
            } else if (gridColumnDefs) {
                let targetGridField = gridColumnDefs.filter(x => { return x.id == formFieldId; })[0];
                if (targetGridField) {
                    targetGridField.setSelectOptions(options.join('|'));
                    this.updateAutoSelectFieldValues(options, targetGridField);
                }
            } else {
                // moved from the start of the method as it was preventing initialization of grid flex
                // fields when default values were set on form-level flex fields
                this.newGridFieldValues = [];   
                // if the field wasn't found it means its a field in a grid which is not
                // exposed to formRenderer, so here we cache them ready for when a user
                // clicks on a grid row to edit it, at which point it will use these cached
                // values to populate the options of any flex fields in it
                this.newGridFieldValues.push(newFieldValues);
            }
        }
        this.autoSelectFieldValuesSubject.next(this.autoSelectFieldValues);
    }

    private updateAutoSelectFieldValues(options: string[], targetFormField: FormField) {
        if (options.length == 1) {
            this.autoSelectFieldValues[targetFormField.id] = options[0];
        }
    }

    // Allows Flex Field components to subscribe in case any need to auto-select a single value
    public get AutoSelectFieldValuesSubject(): Observable<any> {
        return this.autoSelectFieldValuesSubject.asObservable();
    }
    // Also provide a way to get values, not just subscribe to them
    // This is needed for fields in grids which don't get intialized
    // the same way as form level fields
    public get AutoSelectFieldValues(): any {
        return this.autoSelectFieldValues;
    }

    // For the identifield ListValue constraint, returns its available columns
    public GetColumns(formFieldConstraintId: number): Promise<ListConstraintColumn[]> {
        let url = `${this.url}/${this.endpoint}/GetColumns/${formFieldConstraintId}`;
        return this.http.get<ListConstraintColumn[]>(url)
            .toPromise()
            .then(cols => {
                return cols;
            });
    }

    public SetDependsOnParentFormFieldId(formFieldId, dependsOnParentFormFieldId): Promise<FormField[]> {
        let url = `${this.url}/${this.endpoint}/SetDependsOnParentFormFieldId/${formFieldId}/${dependsOnParentFormFieldId}`;
        return this.http.get<FormField[]>(url)
            .toPromise()
            .then(ffs => {
                return ffs;
            });
    }

    public get FormRenderer(): FormRendererComponent {
        return this.formRenderer;
    }

    public set FormRenderer(value: FormRendererComponent) {
        this.formRenderer = value;
    }
}
