import { DefaultFieldDefinitionClientLogic } from './default-field-def-client-logic';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { CascadingDropDownFormFieldConfig } from '../../../models/cascading-dropdown/cascading-dropdown-config.model';
import { CascadingDropDownFormFieldData } from '../../../models/cascading-dropdown/cascading-dropdown-data.model';

export class CascadingDropdownFieldDefLogic extends DefaultFieldDefinitionClientLogic {
    public constructor() {
        super();
    }

    public allowsHtmlInDisplayValue(): boolean {
        return true;
    }

    // Note:  need to implement sorting logic.

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        let displayValue: string = '';

        if ((!formInstanceElementParam.UserUpdatedData) || (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        } else if ((formFieldParam.jsonConfig != null) && (formFieldParam.jsonConfig.trim() != '') && (formInstanceElementParam.textValue != null) && (formInstanceElementParam.textValue.trim() != '')) {
            let config: CascadingDropDownFormFieldConfig = CascadingDropDownFormFieldConfig.parseJsonConfig(formFieldParam.jsonConfig);

            displayValue = CascadingDropDownFormFieldData.getDisplayValue(config, formInstanceElementParam.textValue);
        }

        return displayValue;
    }
    

    public hasVirtualSiblingFormFieldsFor(formField: FormField): boolean {
        return true; // TODO fully implement this
    }

    public getVirtualSiblingFormFieldsFor(formField: FormField): FormField[] {
        let virtualFormFields: FormField[] = [];

        if (formField.jsonConfig != null && formField.jsonConfig.trim() != '') {
            let cascadingDropdownConfig = CascadingDropDownFormFieldConfig.parseJsonConfig(formField.jsonConfig);

            if ((cascadingDropdownConfig != null) && (cascadingDropdownConfig.NumLevels > 0)) {
                for (let index: number = 0; index < cascadingDropdownConfig.DropDownHeaders.length; index++) {
                    let header: string = cascadingDropdownConfig.DropDownHeaders[index];

                    let virtualFormField: FormField = new FormField();

                    virtualFormField.transientFieldIsVirtual = true;
                    virtualFormField.primaryFieldName = formField.name;
                    virtualFormField.fieldOrder = formField.fieldOrder;
                    virtualFormField.fieldSecondaryOrder = index;
                    virtualFormField.name = `${formField.name}_vff${index}`;
                    virtualFormField.displayName = header;

                    virtualFormFields.push(virtualFormField);
                }
            }
        }

        return virtualFormFields;
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }

    public filterType(): string {
        return 'text';
    }

    public isFilterable(): boolean {
        return true;
    }
}
