import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { count } from 'rxjs/operators';
import { IFilesDroppedOnStructure } from '../../../../views/site/structure/structure.file-drop.event';
import { ConfirmationDialogComponent, ConfirmationDialogModel } from '../../../dialogs/confirmation/confirmation-dialog.component';
import { MoveListItemDialog } from '../../../dialogs/move-list-item/move-list-item.dialog';
import { ConfirmationDialogEnums } from '../../../enums/confirmation-dialog.enum';
import { DragDropEnum } from '../../../enums/drag-drop.enum';
import { ItemTypeEnum } from '../../../enums/item-type.enum';
import { IItem } from '../../../interfaces/iitem.interface';
import { IListItem } from '../../../interfaces/ilist-item.interface';
import { Folder } from '../../../models/site-content/folder.model';
import { FormInstance } from '../../../models/site-content/form-instance.model';
import { DragDropService } from '../../../services/drag-drop.service';
import { UtilityHelper } from '../../../utility.helper';
import { IButtonConfig } from '../list-view-button-config.model';
import {
    ListItemDragActionData, ListItemDragActionEnum
} from '../list-view-drag-action.model';
import { ListItemMoveData } from '../list-view-item-move-action';
import { ListViewHelper } from '../list-view.helper';


@Component({
    selector: 'app-base-view',
    templateUrl: './base-view.component.html',
    styleUrls: ['./base-view.component.scss']
})
export class BaseViewComponent implements OnInit, OnChanges {
    // Inputs.
    @Input() list: IListItem[];
    @Input() filterTerm: string; // used to filter the list
    @Input() parentTitle: string;
    @Input() listType: string;
    @Input() currentFolder: Folder;
    @Input() totalListCount: number;

    // Sorting related inputs/properties
    @Input() typeSortOrderConfig: string[];
    // pjh - when adding a new type of IListItem, need to add it to typeSortOrder
    // TODO: refactor this, ListView shouldn't need to know anything about the things its listing out
    // when viewing multiple entities in the same view some should display before others - this structure determines that order
    @Input() typeSortOrder: string[] = [
        ItemTypeEnum.FOLDER,
        ItemTypeEnum.FORM_INSTANCE,
        ItemTypeEnum.WORKFLOW,
        ItemTypeEnum.FORM,
        ItemTypeEnum.ATTACH_W_URLS,
        ItemTypeEnum.DATACOLLECTION,
        ItemTypeEnum.DOCUMENT_PUBLISH_SETTINGS,
        ItemTypeEnum.FORM_INSTANCE_ELEMENT_FOR_HISTORY,
        ItemTypeEnum.LOG_MESSAGE,
        ItemTypeEnum.NOTIFICATION,
        ItemTypeEnum.SUBSCRIPTION
    ];
    @Input() sortDirection: string; // does it make sense to have it here -- won't it vary Tile vs. Grid view
    @Input() defaultSortColumn: string = 'position'; // if no sort is specified, this property will be sorted by. 
    private sortableColumns: string[] = ['id', 'position', 'name', 'description', 'modifiedDate', 'modifiedBy', 'status'];

    // ----- IMPORTANT! -----
    // To get the correct selected/not-selected styling on icons/rows, make certain that you are setting < app - list - view[selectedItemId]="" > correctly.
    // When it's null, the non-selected styling will be applied, and when it's a valid ListItem id, the selected formatting will be applied 
    @Input() selectedItemId: number;
    @Input() selectedItemActionId: string; // a combination of entity id and type and action btn
    @Input() itemActionButtons: IButtonConfig[];  // icons that appear on each row/tile
    @Input() listItemsAreDraggable: boolean = false;

    @Output() editClick: EventEmitter<any> = new EventEmitter();
    @Output() itemActionClick: EventEmitter<any> = new EventEmitter();
    @Output() itemContextMenu: EventEmitter<any> = new EventEmitter();
    @Output() dragAction: EventEmitter<ListItemDragActionData> = new EventEmitter<ListItemDragActionData>();
    @Output() filesDropped: EventEmitter<any> = new EventEmitter<any>();
    @Output() itemMoved: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectionsSet: EventEmitter<IListItem[]> = new EventEmitter<IListItem[]>();

    private itemDragDataStart: ListItemDragActionData = null;
    private itemDragDataEnd: ListItemDragActionData = null;

    public dragDropService: DragDropService = null;

    private itemBeingDragged: boolean = false;

    // Constructor.
    constructor(
        public dialog: MatDialog,
        public injectedDragDropService: DragDropService) {
        this.dragDropService = injectedDragDropService;
        return;
    }

    // Life cycle methods.
    public ngOnInit() {
        return;
    }

    // listen for changes to @Input() selectedItemId since formatting depends on this property
    public ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedItemId) {
            this.setSelected({ id: changes.selectedItemId.currentValue });
        }
    }

    // Define methods called by the tile or grid HTML code.
    public getItemTitle(item: IListItem): string {
        let title: string = item.getTitle();

        return (title);
    }

    public isTypeFormInstance(item: IItem): boolean {
        return UtilityHelper.isTypeFormInstance(item);
    }

    public isTypeFolder(item: IItem): boolean {
        return UtilityHelper.isTypeFolder(item);
    }

    public isTypeAttachment(item: IItem): boolean {
        return UtilityHelper.isTypeAttachment(item);
    }

    public isTypeBreadcrumb(item: IItem): boolean {
        return UtilityHelper.isTypeBreadcrumb(item);
    }

    public get ItemIsBeingDraggedBase(): boolean {
        return (this.itemBeingDragged);
    }

    public get ItemDragDataStart(): ListItemDragActionData {
        return this.itemDragDataStart;
    }

    public onDragDrop(dropEvent, targetOfDrop: IListItem, passedItems: any = null, reorder: boolean = false) {
        // pharv -- added for VNEXT-556 -- This method is called from the (onDrop) binding on <tr>'s
        // AND ALSO from (onDrop) handlers for re-ordering divs. The method is thus called twice
        // when a user drops on a reordering zone div, and this code prevents the method executing
        // twice in this case
        let droppedOnElement = dropEvent.nativeEvent.srcElement;
        if (droppedOnElement.className == 'reorder-drop-zone' && !reorder) {
            return;
        }

        let droppedItems: IListItem[];

        if (passedItems) {
            droppedItems = passedItems;
        }
        else if (dropEvent.items) {
            droppedItems = dropEvent.items;
        } else if (this.itemDragDataStart.items) {
            droppedItems = this.itemDragDataStart.items;
        }

        if (droppedItems.filter(x => { return x.getId() == targetOfDrop.getId() }).length > 0) return; // don't proceed if the dragged item and the item dropped on are the same

        let itemData: ListItemMoveData = new ListItemMoveData(droppedItems, targetOfDrop, reorder);

        this.itemMoved.emit(itemData);
    } 

    public allowDrop(ev) { 
        UtilityHelper.allowDrop(ev);
    }

    public dropOnViewWhiteSpace(ev: DragEvent) {
        UtilityHelper.allowDrop(ev);

        let folderID = -1;
        let siteID = -1;

        if (this.currentFolder)
            folderID = this.currentFolder.getId();

        this.uploadDroppedFiles(ev, folderID, siteID);
    }

    // Not to be confused with onDragDrop() -- see below
    public dropGridOrTileObject(ev: DragEvent, item: IItem) {
        UtilityHelper.allowDrop(ev);

        if (this.isTypeFolder(item)) {
            this.uploadDroppedFiles(ev, item.getId(), -1);
        }
    }

    public selectedItems(passedSelectionModel: SelectionModel<IListItem>, passedItem: any = null): IListItem[] {
        let retVal: IListItem[] = null;

        if (passedSelectionModel) {
            if (passedSelectionModel.selected.length > 0) {
                retVal = passedSelectionModel.selected as IListItem[];
            }
        }

        if (retVal == null) {
            retVal = [passedItem] as IListItem[];
        }

        return retVal;
    }

    // Defines the sorting algorithm used by both GridView and ListView.
    // It takes into account that the view may be displaying more than one entity, as is the case with the structure screen
    public doSortListData(data: IListItem[], sortDirection: string, sortColumn: string = null): IListItem[] {

        let types = [];
        let sorted: IListItem[] = data;
        let retVal: IListItem[] = [];
        
        if (sortColumn) {
            let findItem = this.sortableColumns.find(x => x == sortColumn);

            if (findItem) {
                sorted = [];

                for (let i = 0, max = data.length; i < max; i++) {
                    let type = data[i].getType();

                    if (sorted[type] == undefined) {
                        sorted[type] = [];
                        types.push(type);
                    }
                    sorted[type].push(data[i]);
                }

                this.typeSortOrder.forEach((currentValue) => {
                    let toConcat = sorted[currentValue];

                    if (toConcat) {
                        this.sortArrayInDirection(toConcat, sortDirection, sortColumn);

                        retVal = retVal.concat(toConcat);
                    }
                });

            }
            else {
                retVal = this.sortArrayInDirection(sorted, sortDirection, sortColumn);
            }
        }
        else {
            retVal = this.sortArrayInDirection(sorted, sortDirection, sortColumn);
        }


        return retVal;
    }

    public getActionButtonTitle(button: IButtonConfig, item: IListItem): string {
        let title = `${button.label} - ${item.name}`;
        if (button.isDisabled(item)) {
            title = `${title} (Disabled)`
        }
        return title;
    }

    // Called when an icon action on a row or tile is clicked
    public onItemActionClick(event: any, item: IListItem, buttonConfig: IButtonConfig): void {
        console.log('onItemActionClick');
        event.stopPropagation();

        if (buttonConfig.isDisabled(item)) return;

        let eventData = { originalEvent: event, item: item, button: buttonConfig };
        console.log(eventData);

        if (event instanceof KeyboardEvent) {
            return;
        }

        this.unsetSelected();

        // 01-08-2021 note:  replaced the 'if' statement/block
        //                   with only the code within the block.
        //
        // Add'l notes:  investigated this more and found that
        // property 'this.selectedItemId' is getting reset to
        // null via a call to ngOnChanges() when a calling
        // component, e.g. the StructureComponent, resets the
        // selected folder or form instance to null as the
        // selected object is an input to this component.
        // For StructureComponent, that occurs in method
        // deselectSelected(), called when the user clicks on,
        // among other options, the 'X' to close the drawer;
        // however, if the user uses the keyboard, typing
        // 'Escape', to close the drawer, deselectSelected()
        // never gets called and the subsequent call to open
        // the drawer, e.g. the drawer with folder 'info',
        // this method returns null for eventData.item.id,
        // causing the call to retrieve the folder's info
        // to fail.
        //
        // As a result of the above notes, I am replacing
        // the 'if' block (again) with the single line of
        // code to this.setSelected(), make sure this method
        // returns a non-null value for eventData.item.id
        // pending a conversation with Paul.
        /*
        if (item.id !== tmpId) {
            this.setSelected(item, buttonConfig);
        }
        */

        this.setSelected(item, buttonConfig);

        item.setId(this.selectedItemId); // this may be null when toggling off
        this.itemActionClick.emit(eventData);
    }

    public onEditClick(event: any, item: any): void {
        event.stopPropagation();
        this.editClick.emit({ originalEvent: event, item: item });
    }

    //TEAMS-424: KLW - Add in an optional parameter to pass a view's selection of items when then context menu is invoked
    public onItemContextMenu(event: any, item: any, selectedItems: any = null): void {
        event.preventDefault();
        event.stopPropagation();

        let eventData = { originalEvent: event, item: item, selectedItems: selectedItems };
        this.itemContextMenu.emit(eventData);
    }

    // 05-18-2020 note:  changed the protection level on the following method as 
    //                   it needs to be accessed from derive classes' .html files.
    public generateItemActionId(item: IListItem, button: IButtonConfig) {
        return `${item.getId()}-${item.getType()}-${button.value}`;
    }

    public onItemDragStart(event: any, items: IListItem[]): void {
        this.itemDragDataStart = new ListItemDragActionData();
        this.itemDragDataStart.dragEventData = event;
        this.itemDragDataStart.items = items;
        this.itemDragDataStart.dragAction = ListItemDragActionEnum.DRAG_START;

        this.dragDropService.setItems(event, items);

        this.dragAction.emit(this.itemDragDataStart);
        this.itemBeingDragged = true;

        return;
    }

    public onItemDragEnd(event: any, items: IListItem[]): void {
        this.itemDragDataEnd = new ListItemDragActionData();
        this.itemDragDataEnd.dragEventData = event;
        this.itemDragDataEnd.items = items;
        this.itemDragDataEnd.dragAction = ListItemDragActionEnum.DRAG_END;

        this.dragDropService.setItems(event, items);

        this.dragAction.emit(this.itemDragDataEnd);
        this.itemBeingDragged = false;

        return;
    }

    // Implement private methods.

    private uploadDroppedFiles(ev: DragEvent, folderId: number, siteId: number) {
        if (ev.dataTransfer.files) {

            let files = ev.dataTransfer.files;
            if (files.length > 0) {

                let toPass: IFilesDroppedOnStructure = {
                    Files: Array.from(ev.dataTransfer.files),
                    FolderId: folderId,
                    Reload: true,
                }

                this.filesDropped.emit(toPass);
            }
        }
    }

    private getFileNames(items: IItem[]): string[] {
        let retVal: string[] = [];

        if (items.length > 0) {
            retVal = items.map(x => x.name);
        }

        return retVal;
    }


    private setSelected(item: any, button?: IButtonConfig): void {
        this.selectedItemId = item.id;
        if (button) {
            this.selectedItemActionId = this.generateItemActionId(item, button);
        }
    }

    private unsetSelected(): void {
        this.selectedItemId = null;
        this.selectedItemActionId = null;
    }

    // Returned passedArray sorted accoring to direction and sortColumn
    // If direction is neither asc nor desc, the passedArray will be returned in its original order
    private sortArrayInDirection(passedArray, direction: string, sortColumn: string): any {
        if (sortColumn == ListViewHelper.NO_SORT) {
            return passedArray;
        }

        if (direction == "asc") {
            passedArray.sort((a: IListItem, b: IListItem) => {
                if (sortColumn == 'status') {
                    return this.isGreaterThan(a.getStatus(), b.getStatus());
                } else if (sortColumn == 'position') {
                    return this.isGreaterThan(a.getPosition(), b.getPosition());
                } else {
                    return this.isGreaterThan(a[sortColumn], b[sortColumn]);
                }

            });
        }

        if (direction == "desc") {
            passedArray.sort((a: IListItem, b: IListItem) => {
                if (sortColumn == 'status') {
                    return this.isGreaterThan(b.getStatus(), a.getStatus());
                } else if (sortColumn == 'position') {
                    return this.isGreaterThan(b.getPosition(), a.getPosition());
                } else {
                    return this.isGreaterThan(b[sortColumn], a[sortColumn]);
                }
            });
        }

        return passedArray;
    }

    private isGreaterThan(a, b): number {
        if (isNaN(a) || isNaN(b)) {
            let aAsLowercaseString = (a + '').toLowerCase();
            let bAsLowercaseString = (b + '').toLowerCase();

            return aAsLowercaseString > bAsLowercaseString ? 1 : -1;
        } else {
            return a > b ? 1 : -1;
        }
    }
}
