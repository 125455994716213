import { IFormPreRenderFilter } from '../../../interfaces/iform-pre-render-filter.interface';
import { Form } from '../form.model';
import { FormField } from '../form-field.model';
import { PreRenderFilterBase } from './pre-render-filter-base';

export class ShowToPreRenderFilter extends PreRenderFilterBase implements IFormPreRenderFilter {
    // Properties.
    private formField: FormField;

    // Constructor.
    public constructor(formField: FormField) {
        super();
        this.formField = formField;
    }

    // Getter method.
    public get FormField(): FormField {
        return this.formField;
    }

    // Implement IFormPreRenderFilter.
    public allowFormInstanceElement(unusedForm: Form, unusedFormField: FormField, userRoleNames: string[], unusedFormInstanceElement): boolean {
        let allow: boolean = false;

        if ((this.formField.showToHideFromRoleNames != null) && (this.formField.showToHideFromRoleNames.trim() != '')) {
            let configuredRoleNames: string[] = this.formField.showToHideFromRoleNames.split(',');
            if ((configuredRoleNames.length > 0) && (userRoleNames.length > 0)) {
                for (let index: number = 0; index < userRoleNames.length; index++) {
                    let userRoleName: string = userRoleNames[index];
                    if (configuredRoleNames.indexOf(userRoleName) >= 0) {
                        allow = true;
                        break;
                    }
                }
            }
        }

        return allow;
    }
}
