import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { plainToClass } from 'class-transformer';

import { environment } from '../../../environments/environment';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { CollectApiServiceBase } from './collect-api-base.service';
import { AsyncJob } from '../models/async-job.model';
import { SiteIcon } from '../models/site-icons/site-icon.model';

@Injectable()
export class SiteIconService extends CollectApiServiceBase<SiteIcon> {
    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'siteIcon', SiteIcon)
    }

    // Implement formatResponse()
    public formatResponse(data: SiteIcon): SiteIcon {
        let obj = plainToClass(SiteIcon, data);
        return (obj);
    }

    // Implement service methods.
    public addSiteIcon(dataCollectionId: number, iconName: string, iconFile: File): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/addSiteIcon/${dataCollectionId}/named/${iconName}`;

        const formData: FormData = new FormData();
        formData.append('file', iconFile, iconFile.name);

        var httpHeaders: HttpHeaders = new HttpHeaders();
        const req = new HttpRequest('POST', url, formData, {
            reportProgress: true,

            headers: httpHeaders
        });

        return this.http.post<AsyncJob>(url, formData)
            .toPromise()
            .then(response => {
                let asyncJob: AsyncJob = response['value']; // Not sure why this has to be done, but perhaps it's because the server is return IActionResult.
                let result = plainToClass(AsyncJob, asyncJob);
                return result;
            })
            .catch(this.handleError);
    }

    public deleteSiteIcon(siteIconId: number): Promise<SiteIcon> {
        let url = `${this.url}/${this.endpoint}/${siteIconId}`;

        return this.http.delete<SiteIcon>(url)
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public listSiteIcons(dataCollectionId: number): Promise<SiteIcon[]> {
        let url = `${this.url}/${this.endpoint}/listSiteIcons/${dataCollectionId}`;

        return this.http.get<SiteIcon[]>(url)
            .toPromise()
            .then(response => {
                let result: SiteIcon[] = [];
                if ((response != null) && (response.length > 0)) {
                    for (let index: number = 0; index < response.length; index++) {
                        let siteIcon: SiteIcon = this.formatResponse(response[index]);
                        result.push(siteIcon);
                    }
                }
                return result;
            });
    }

    public getSiteIcon(siteIconId: number): Promise<SiteIcon> {
        let url = `${this.url}/${this.endpoint}/get/${siteIconId}`;

        return this.http.get<SiteIcon>(url)
            .toPromise()
            .then(response => {
                let result = this.formatResponse(response);
                return result;
            });
    }
}
