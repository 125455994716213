import { IViewModel } from '../../interfaces/view-model.interface';
import { Type, plainToClass } from 'class-transformer';

import { NotificationRecipient } from './notification-recipient.model';
import { IListItem } from '../../interfaces/ilist-item.interface';
import { IconFormatEnum } from '../../enums/icon-format.enum';
import { ItemTypeEnum } from '../../enums/item-type.enum';
import { IListItemBase } from '../ilist-item-base.model';

export class NotificationInstance extends IListItemBase implements IViewModel, IListItem {
    // Define primitive properties.
    // Key.
    public id: number = 0;

    public notificationTemplateId: number;
    public notificationTemplateName: string;

    public notificationText: string;

    public notificationSentFlag: boolean;
    public notificationSentDate: Date;

    @Type(() => NotificationRecipient)
    public recipients: NotificationRecipient[];

    ///////////////////////////// IListItem Implementation ///////////////////////////////
    public setId(idParam: number): void {
        this.id = idParam;
    }
    public getExtraInfo(): string {
        return this.notificationText;
    }
    public getValue(property: string): string {
        return "";
    }
    public userCanDelete(): boolean {
        throw new Error('Method not implemented.');
    }
    public getPosition(): number {
        throw new Error('Method not implemented.');
    }
    public modifiedDate: Date;
    public modifiedBy: string;
    public getStatus(): string {
        return 'N/A';
    }
    public getIconType(): IconFormatEnum {
        return IconFormatEnum.MAT_ICON;
    }
    public getIcon(): string {
        return 'notifications';
    }
    public canBeDeleted(): boolean {
        throw new Error('Method not implemented.');
    }
    public update(obj: any, type: string, icon?: string, position?: number): void {
        throw new Error('Method not implemented.');
    }
    public getUniqueId(): string {
        throw new Error('Method not implemented.');
    }
    public getOriginalVersionId(): number {
        throw new Error('Method not implemented.');
    }
    public findAndUpdateFrom(items: any, obj: any): void {
        throw new Error('Method not implemented.');
    }
    public isMagicStructure(): boolean {
        throw new Error('Method not implemented.');
    }
    public getChildCount(): number {
        throw new Error('Method not implemented.');
    }
    public getModifiedBy(): string {
        return this.modifiedBy;
    }
    public getModifiedDate(): Date {
        return this.modifiedDate;
    }
    public name: string;
    public getName(): string {
        return this.notificationText;
    }
    public getId(): number {
        return this.id;
    }
    public getType(): string {
        return ItemTypeEnum.NOTIFICATION;
    }
    public getParentId(): number {
        throw new Error('Method not implemented.');
    }
}
