import { IViewModel } from '../../interfaces/view-model.interface';
import { IListItem } from '../../interfaces/ilist-item.interface';
import { IconFormatEnum } from '../../enums/icon-format.enum';
import { FormInstance } from '../site-content/form-instance.model';
import { FormInstanceElement } from './form-instance-element.model';
import { ItemTypeEnum } from '../../enums/item-type.enum';
import { IListItemBase } from '../ilist-item-base.model';

// The motivation for this class is that I didn't want to muddy FormInstanceElement
// by making it implement IListItem, whereas FormInstance already implements it
export class FormInstanceElementForHistory extends IListItemBase implements IViewModel, IListItem {
    public id: number;

    public value: string;

    public comment: string;

    public version: number;

    public formInstanceId: number;

    public formInstanceVersion: number;

    public originalFormInstanceId: number;

    public originalFormInstanceElementId: number;

    public get isOriginalVersion() {
        return this.originalFormInstanceElementId === this.id;
    }

    // IItem impl...
    public name: string;

    public getId(): number {
        return this.id;
    };

    public getType(): string {
        return (ItemTypeEnum.FORM_INSTANCE_ELEMENT_FOR_HISTORY); // TODO
    };

    public getParentId(): number {
        throw new Error('Method not implemented.');
    };

    // IListItem impl...

    public setId(idParam: number): void {
        //throw new Error('Method not implemented.');
    };

    public getTitle(): string {
        return "";
    };

    public getExtraInfo(): string {
        return this.comment ?? "";
    };

    public getValue(property: string): string {
        property = property.toLowerCase();
        return this[property];
    };

    // Always returns the original/current FormInstance's id, even if this is an older version
    public getOriginalVersionId(): number {
        return this.version == 0 ? this.id : this.originalFormInstanceId ?? this.id;
    }

    public userCanDelete(): boolean {
        throw new Error('Method not implemented.');
    };

    public getPosition(): number {
        return 0;
    };

    public modifiedDate: Date;

    public modifiedBy: string;

    public getStatus(): string {
        return null;
    };

    public getIconType(): IconFormatEnum {
        throw new Error('Method not implemented.');
    };
    public getIcon(): string {
        throw new Error('Method not implemented.');
    };

    // 04-13-2022 note:  no one was using the following method, setIcon().  Also
    //                   removed its NOOP implementation in a number of classes.
    //setIcon(iconParam: string): void;

    public canBeDeleted(): boolean {
        throw new Error('Method not implemented.');
    };

    public update(obj: any, type: string, icon?: string, position?: number): void {
        throw new Error('Method not implemented.');
    };

    public getUniqueId(): string {
        throw new Error('Method not implemented.');
    };

    public findAndUpdateFrom(items, obj: any): void {
        throw new Error('Method not implemented.');
    };

    public isMagicStructure(): boolean {
        throw new Error('Method not implemented.');
    };

    public getChildCount(): number {
        throw new Error('Method not implemented.');
    }
    public getModifiedBy(): string {
        return this.modifiedBy;
    };
    public getModifiedDate(): Date {
        return this.modifiedDate;
    }
}
