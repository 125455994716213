import { DropEvent } from 'ng-drag-drop';
import { IListItem } from '../../interfaces/ilist-item.interface';

export class ListItemMoveData {

    private movedItems: IListItem[];
    private targetOfMove: IListItem;
    private reorder: boolean;

    public constructor(movedItems: IListItem[], targetOfMove: IListItem, reorder: boolean = false) {
        this.movedItems = movedItems;
        this.targetOfMove = targetOfMove;
        this.reorder = reorder;
    }

    public get MovedItems(): IListItem[] {
        return this.movedItems;
    }

    public get TargetOfMove(): IListItem {
        return this.targetOfMove;
    }

    public get Reorder(): boolean {
        return this.reorder;
    }

    public get MovedItemsNames(): string[] {
        let retVal: string[] = [];
        if (this.movedItems && this.movedItems.length > 0) {
            retVal = this.movedItems.map(x => x.name);
        }
        return retVal;
    }

    public get NothingMoved(): boolean {
        return this.movedItems == null || this.movedItems.length == 0;
    }
}
