import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { plainToClass } from 'class-transformer';

import { environment } from '../../../environments/environment';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { CollectApiServiceBase } from './collect-api-base.service';
import { FormInstance } from '../models/site-content/form-instance.model';
import { AsyncJob } from '../models/async-job.model';
import { XmlFileFormatEnum } from '../enums/xml-file-format.enum';
import { ExcelPasteData } from '../models/grid/excel-paste-data.model';

@Injectable()
export class ImportDataService extends CollectApiServiceBase<FormInstance> {
    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'importData', FormInstance)
    }

    // Service methods.
    public importFormInstanceFiles(formInstanceDataFiles: File[], folderId: number): Promise<AsyncJob> {
        let url: string = `${this.url}/${this.endpoint}/importSimplifiedFormInstanceFilesInto/${folderId}`

        // Package file data and create an HTTP POST request.
        const formData: FormData = this.packageDataFiles(formInstanceDataFiles);

        var httpHeaders: HttpHeaders = new HttpHeaders();
        const req = new HttpRequest('POST', url, formData, {
            reportProgress: true,

            headers: httpHeaders
        });

        return this.http.post<AsyncJob>(url, formData)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    // Implement service methods that could well be phased out.
    public importFormInstances(formInstanceDataZipFile: File, xmlFileFormat: XmlFileFormatEnum, folderId: number, optionalFormId: number = 0): Promise<AsyncJob> {
        // Determine the target URL.
        let url: string = null;

        if (optionalFormId > 0) {
            url = (xmlFileFormat == XmlFileFormatEnum.VERBOSE_FORMAT ?
                `${this.url}/${this.endpoint}/importFormInstancesInto/${folderId}/usingForm/${optionalFormId}` :
                `${this.url}/${this.endpoint}/importSimplifiedFormInstancesInto/${folderId}/usingForm/${optionalFormId}`);
        } else {
            url = (xmlFileFormat == XmlFileFormatEnum.VERBOSE_FORMAT ?
                `${this.url}/${this.endpoint}/importFormInstancesInto/${folderId}` :
                `${this.url}/${this.endpoint}/importSimplifiedFormInstancesInto/${folderId}`);
        }

        // Package file data and create an HTTP POST request.
        const formData: FormData = new FormData();
        formData.append('file', formInstanceDataZipFile, formInstanceDataZipFile.name);

        var httpHeaders: HttpHeaders = new HttpHeaders();
        const req = new HttpRequest('POST', url, formData, {
            reportProgress: true,

            headers: httpHeaders
        });

        return this.http.post<AsyncJob>(url, formData)
            .toPromise()
            .then(res => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    public importExcelFileIntoGrid(excelFile: File, gridFormInstanceElementId: number, truncateExistingGridRows: boolean = false): Promise<AsyncJob> {
        let url: string = `${this.url}/${this.endpoint}/importExcelFileIntoGrid/${gridFormInstanceElementId}/${truncateExistingGridRows}`;

        const formData: FormData = new FormData();
        formData.append('file', excelFile, excelFile.name);

        var httpHeaders: HttpHeaders = new HttpHeaders();
        const req = new HttpRequest('POST', url, formData, {
            reportProgress: true,

            headers: httpHeaders
        });

        return this.http.post<AsyncJob>(url, formData)
            .toPromise()
            .then(res => {
                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    public importExcelPasteDataIntoGrid(pasteData: string, gridFormInstanceElementId: number, truncateExistingGridRows: boolean = false): Promise<AsyncJob> {
        let url: string = `${this.url}/${this.endpoint}/importExcelPasteDataIntoGrid/${gridFormInstanceElementId}/${truncateExistingGridRows}`;

        let pasteDataObject: ExcelPasteData = new ExcelPasteData(pasteData);

        var httpHeaders: HttpHeaders = new HttpHeaders();
        const req = new HttpRequest('POST', url, pasteDataObject, {
            reportProgress: true,

            headers: httpHeaders
        });

        return this.http.post<AsyncJob>(url, pasteDataObject)
            .toPromise()
            .then(res => {
                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    // Helper methods.
    private formatAsyncJobResponse(data: AsyncJob): AsyncJob {
        var obj = plainToClass(AsyncJob, data);

        return (obj);
    }

    private packageDataFiles(dataFiles: File[]): FormData {
        const formData: FormData = new FormData();
        for (var iFile: number = 0; iFile < dataFiles.length; iFile++) {
            let dataFile: File = dataFiles[iFile];
            formData.append('file', dataFile, dataFile.name);
        }

        return (formData);
    }
}
