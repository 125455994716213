import { FieldDefinition, FieldDefinitionAllowDropFlags } from '../field-definition.model';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
//import { IFormPreRenderFilter } from '../../../interfaces/iform-pre-render-filter.interface';
import { ShowToFieldType, HideFromFieldType } from '../form-field-types';
import { LayoutCell } from '../layout-cell.model';
import { Form } from '../form.model';
import { FormField } from '../form-field.model';
import { DefaultFieldDefinitionClientLogic } from './default-field-def-client-logic';

export abstract class ShowToHideFromCustomLogicBase extends DefaultFieldDefinitionClientLogic implements IFieldDefinitionLogic {
    // Constructor.
    public constructor() {
        super();
    }

    // Implement some IFieldDefinitionLogic methods.
    public checkAllowsNestedDrop(fieldDefinition: FieldDefinition, allowsDropFlags: FieldDefinitionAllowDropFlags): void {
        allowsDropFlags.allowDrop = ((fieldDefinition.className == ShowToFieldType) || (fieldDefinition.className == HideFromFieldType));
        if (!allowsDropFlags.allowDrop)
            allowsDropFlags.dropErrorMessage = `The dropped field cannot be placed within the existing field`; // Note:  this error message is not yet being used.
    }

    public fieldDraggedToTrash(formField: FormField, form: Form): void {
        // Find the form field's matching form field.  That is, if the deleted field
        // is a "Show To" field begin, find the corresponding "Show To" field end.
        let matchingFormField: FormField = form.formFields.find(ff => (ff.fieldDefinitionClassName == formField.fieldDefinitionClassName) && (ff.isConditionalBeginElement == !formField.isConditionalBeginElement) && (ff.isConditionalEndElement == !formField.isConditionalEndElement));
        // Find the matching form field's cell.
        let matchingFieldCell: LayoutCell = form.formLayout.findCellContaining(matchingFormField.name);

        if (matchingFormField != null) {
            // Mark the corresponding/matching field as deleted.
            matchingFormField.isDeleted = true;
        }
        if (matchingFieldCell != null) {
            // Mark the matching field's cell as empty.
            matchingFieldCell.clearContent();
        }
    }

    public get canHaveInstructions(): boolean {
        return false;
    }

    public hasCustomFormLayoutStylesFor(formField: FormField): boolean {
        return formField.isConditionalEndElement; // We want to make end elements use less vertical space.
    }
    public customFormLayoutStylesFor(formField: FormField): string {
        return (formField.isConditionalEndElement ? 'min-height: 30px' : '');
    }

    public hasCustomFormFieldLayoutStylesFor(formField: FormField): boolean {
        return formField.isConditionalEndElement; // We want to make end elements use less vertical space.
    }
    public customFormFieldLayoutStylesFor(formField: FormField): string {
        return (formField.isConditionalEndElement ? 'margin-bottom: 0px' : '');
    }

    // Methods common to show to and hide from custom logic handlers.
    protected static popFilter(filterFormField: FormField, formField: FormField): boolean {
        let pop: boolean = false;

        if ((filterFormField.fieldDefinitionClassName == formField.fieldDefinitionClassName) &&
            filterFormField.isConditionalBeginElement &&
            formField.isConditionalEndElement &&
            (filterFormField.matchingBeginOrEndFieldName == formField.name) &&
            (formField.matchingBeginOrEndFieldName == filterFormField.name)) {
            pop = true;
        }

        return pop;
    }
}
