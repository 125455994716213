import { ImportMetricsBase } from './import-metrics-base';
import { IJobSummaryText } from './i-job-summary-text';

export class ImportExcelDataIntoGridMetrics extends ImportMetricsBase {
    // Properties.
    public numRowsImported: number;
    public numRowsFailedValidation: number;

    private static readonly propertyNames: string[] =
        [
            // Properties from MetricsBase.
            'progressLog',
            'warningLog',
            'errorLog',

            'jobSummaryMessage',

            'exceptionLoggedAndJobTerminated',

            // My properties.
            'numRowsImported',
            'numRowsFailedValidation'
        ];

    // Constructor.
    public constructor(jsonData: Object) {
        super(ImportExcelDataIntoGridMetrics.propertyNames, jsonData);
    }
}
