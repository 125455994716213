import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class KeepAliveService {
    private url;

    constructor(private http: HttpClient) {
        // doesn't matter what this is so long as it's lightweight
        let apiUrl = environment.apiUrl.endsWith('/') ? environment.apiUrl.slice(0, -1) : environment.apiUrl; // remove trailing slash if present
        this.url = `${apiUrl}/frameworkapi/BuildInfo/BuildInfo?ForKeepAlive=true`;
        console.log(apiUrl);
    }

    public pingTheBackend(): void {
        this.http.get(this.url).subscribe(o => {});
    }

}
