import { ItemTypeEnum } from '../enums/item-type.enum';
import { IHasIdAndName } from '../interfaces/has-id-and-name.interface';
import { IListItem } from '../interfaces/ilist-item.interface';
import { IViewModel } from '../interfaces/view-model.interface';
import { DataCollection } from './site-content/data-collection.model';
import { IListItemBase } from './ilist-item-base.model';
import { IconFormatEnum } from '../enums/icon-format.enum';

// This maps to XsltStylesheet on the backend
export class DocumentPublishingSettings extends IListItemBase implements IViewModel, IHasIdAndName, IListItem{

    id: number;
    name: string;
    outputType: string;
    xslt: string;
    viewableBy: string;
    viewableByMaxGroup: string;
    siteId: number;

    modifiedDate: Date;
    modifiedBy: string;

    private fileExtensions: any = {'PDF':'pdf', 'Word':'docx', 'Excel':'xlsx', 'HTML':'html', 'XML':'xml'}

    public get FileName(): string {
        return `${this.name}.${this.fileExtensions[this.outputType]}`;
    }

    public setId(idParam: number): void {
        this.id = idParam;
    }
    public userCanDelete(): boolean {
        return true;
    }
    public getPosition(): number {
        return this.id; // id forms a default attribute for ordering 
    }
    public getStatus(): string {
        return this.outputType;
    }
    public getExtraInfo(): string {
        return "";
    }
    public getValue(property: string): string {
        return "";
    }
    public getIconType(): IconFormatEnum {
        return IconFormatEnum.MAT_ICON;
    }
    public getIcon(): string {
        return this.fileExtensions[this.outputType];
    }
    public setIcon(iconParam: string): void {
        throw new Error('Method not implemented.');
    }
    public canBeDeleted(): boolean {
        return true; // TODO
    }
    public update(obj: any, type: string, icon?: string, position?: number): void {
        throw new Error('Method not implemented.');
    }
    public getUniqueId(): string {
        throw new Error('Method not implemented.');
    }
    public getChildCount(): number {
        throw new Error('Method not implemented.');
    }
    public getModifiedBy(): string {
        return this.modifiedBy;
    }
    public getModifiedDate(): Date {
        return this.modifiedDate;
    }

    public findAndUpdateFrom(items: any, obj: any): void {
        throw new Error('Method not implemented.');
    }
    public getType(): string {
        return ItemTypeEnum.DOCUMENT_PUBLISH_SETTINGS;
    }
    public getParentId(): number {
        throw new Error('Method not implemented.');
    }
    public getId(): number {
        return this.id;
    }

    public getOriginalVersionId(): number {
        return -1;
    }

    public getName(): string {
        return this.name;
    }
    public setName(nameParam: string): void {
        this.name = nameParam;
    }
    public typeName(): string {
        throw new Error('Method not implemented.');
    }

    public hasDescriptionField(): boolean {
        return false;
    }
    public getDescription(): string {
        return null;
    }
    public setDescription(val: string) {
        return;
    }

    // Define HasIdAndName interface methods that have no meaning for this class.
    public getChangeWorkflowStateDialogTitle(): string { return null; }
    public setChangeWorkflowStateDialogTitle(value: string): void { }

    public getTransitionConfirmationDialogMessage(): string { return null; }
    public setTransitionConfirmationDialogMessage(value: string): void { }

    public getTakeUserToSiteHomePageAfterTransitionApplied(): boolean { return false; }
    public setTakeUserToSiteHomePageAfterTransitionApplied(value: boolean): void { }

    public getPropertiesDrawerTitle(): string {
        return null;
    }
    // End HasIdAndName interface methods that have no meaning for this class.
}
