import { FormField } from "../../../models/form-builder/form-field.model";
import { FieldNameHashes } from "./field-name-hashes";
import { GridRowDef } from "../../../models/grid/grid-row.model";
import { IGridRow } from "../../../interfaces/grid-row.interface";
import { IFieldNameToFormField } from "../../../interfaces/iform-field-component";

export class FormulaEvaluationHelper {
    CalculationError: string;
    FormField: FormField;
    FieldNameHashes: FieldNameHashes = new FieldNameHashes();

    private gridRow: GridRowDef;

    public get RoundTo(): number {
        let roundTo = 100;
        if (this.FormField.roundToNumDigitsAfterDecimalPoint) {
            roundTo = Math.pow(10, this.FormField.roundToNumDigitsAfterDecimalPoint);
        }
        return roundTo;
    }

    constructor() {
    }

    public init(formField: FormField, gridRow: IGridRow, gridRowColumnDefs: FormField[]): FormulaEvaluationHelper {
        this.FormField = formField;
        this.gridRow = (gridRow as GridRowDef);
        let variableNameToField: IFieldNameToFormField = {};
        let nameToField: IFieldNameToFormField = {};
        let fieldIdToFieldName = {};

        let col = 0;
        for (let field of gridRowColumnDefs) {
            col++;
            variableNameToField[`col${col}`] = field;
            nameToField[field.name] = field;
            fieldIdToFieldName[field.gridColClientId] = field.name;
        }

        this.FieldNameHashes.hshDisplayVariableNameToFormField = variableNameToField;
        this.FieldNameHashes.hshColNameToFormField = nameToField;
        this.FieldNameHashes.hshFieldIdToFieldName = fieldIdToFieldName;
        
        return this;
    }

    public set(formField: FormField, fieldNameHashes: FieldNameHashes) {
        this.FormField = formField;
        this.FieldNameHashes = fieldNameHashes;

        return this;
    }
}
