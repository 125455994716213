import { ElementTypeMetadata } from './element-type-metadata.model';
import { ElementCountExpected } from './element-count-expected.enum';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
import { HtmlElementInfo } from './html-element-info.model';
import { OperationCompletedServiceInfo } from './additional-element-info.model';

export abstract class ClickableElementTypeMetadata extends ElementTypeMetadata {
    protected constructor(htmlElementSelector: string, metadataKey: string, elementCountExpected: ElementCountExpected) {
        super(htmlElementSelector, metadataKey, elementCountExpected);
    }

    // Override metadata methods.
    public hasProperties = (driver: IBrowserDriver, element: object): boolean => {
        return true;
    }

    public hasActions = (driver: IBrowserDriver, element: object): boolean => {
        return true;
    }
    public getActions = (driver: IBrowserDriver, element: object): string[] => {
        let actions: string[] = [];

        let title: string = this.getTitle(driver, element);
        actions.push(title);

        return actions;
    }

    public click(driver: IBrowserDriver, recorderService: ITestActionRecorderService, component: ITestableComponent, htmlElementInfo: HtmlElementInfo, element: object, getClickableElementFunction: (element: object) => object): void {
        let operationCompletedServiceInfo: OperationCompletedServiceInfo = this.getAnyOperationCompletedServiceInfoFor(htmlElementInfo);
        recorderService.clickButton(htmlElementInfo.elementTitle, htmlElementInfo.elementTypeMetadata.metadataKey, htmlElementInfo.elementSubtype, operationCompletedServiceInfo != null ? operationCompletedServiceInfo.serviceName : null, operationCompletedServiceInfo != null ? operationCompletedServiceInfo.operationName : null);

        let elementToClick = getClickableElementFunction != null ? getClickableElementFunction(element) : element;
        driver.clickButton(component, elementToClick);
    }
    public doubleClick(driver: IBrowserDriver, component: ITestableComponent, element: object, getClickableElementFunction: (element: object) => object): void {
        let elementToClick = getClickableElementFunction != null ? getClickableElementFunction(element) : element;
        driver.doubleClickButton(component, elementToClick);
    }
    /*
    public recordActionClick(recorderService: ITestActionRecorderService, htmlElementInfo: HtmlElementInfo): void {
        recorderService.elementActionClicked(htmlElementInfo.elementTitle, htmlElementInfo.elementTypeMetadata.metadataKey, htmlElementInfo.elementSubtype);
    }
    */

    // Protected methods.
    protected getAnyOperationCompletedServiceInfoFor(htmlElementInfo: HtmlElementInfo): OperationCompletedServiceInfo {
        let operationCompletedServiceInfo: OperationCompletedServiceInfo = null;
        if ((htmlElementInfo.additionalElementInfo != null) && (htmlElementInfo.additionalElementInfo.operationCompletedServiceMap != null) && (htmlElementInfo.additionalElementInfo.operationCompletedServiceMap[htmlElementInfo.elementTitle] != null)) 
            operationCompletedServiceInfo = htmlElementInfo.additionalElementInfo.operationCompletedServiceMap[htmlElementInfo.elementTitle];

        return operationCompletedServiceInfo;
    }
}
