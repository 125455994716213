export class CurrentUser {
    groups: string[];
    samlAuthenticationStatementAuthMethod: string;
    userClassification: string;
    bureauName: string;
    maxAuthenticationGroups: string;
    phone: string;
    userStatus: string;
    authMethodAtPartnerIdp: string;
    bureauCode: string;
    partnerEntityId: string;
    eAuthLOA: string;
    middleName: string;
    orgTag: string;
    maxId: string;
    groupList: string;
    emailAddress: string;
    agencyCode: string;
    agencyName: string;
    lastName: string;
    firstName: string;
    // computed values
    displayName: string;
    authenticationGroups: string[];
    accessibleSites: boolean;

    get isSystemAdmin(): boolean {
        return this.groups.indexOf('COLLECT-VNEXT-SYSTEM-ADMINS') > -1;
    }

    get isSiteCreator(): boolean {
        return this.groups.indexOf('COLLECT-VNEXT-SITE-CREATORS') > -1;
    }

    get isProdSiteCreator(): boolean {
        return this.groups.indexOf('COLLECT-VNEXT-PROD-SITE-CREATORS(NONFED)') > -1;
    }

    get hasAccessibleSites(): boolean {
        return this.accessibleSites;
    }
}
