import { GridFormInstanceElementWrapper } from './grid-form-instance-element-wrapper.model';
import { FormField } from '../form-builder/form-field.model';
import { IFieldDefinitionLogic } from '../../interfaces/ifield-definition-logic.interface';
import { GridRowDef } from './grid-row.model';
import { IFormFieldComponent } from '../../interfaces/iform-field-component';

// Define classes, an interface used in column total logic.
export class NumericFormInstanceElementWrapper {
    public gridRowDef: GridRowDef;
    public formInstanceElementWrapper: GridFormInstanceElementWrapper;
    public previousNumericValue: number;
};

export class NumericColumnTotalInfo {
    // Properties.
    public numericComponent: IFormFieldComponent = null;
    public columnDef: FormField = null;

    //public columnCellData: GridFormInstanceElementWrapper[] = null;
    public columnCellData: NumericFormInstanceElementWrapper[] = null;

    public columnCellTotal: number = 0;
};

