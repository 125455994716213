<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<div class="height-100-percent">
    <div class="flex-row-top-right" *ngIf="this.Mode === 'design'">
        <div class="fill-remaining-space">
            <!-- If there are instructions, display label first, then instructions then field -->
            <ng-container *ngIf="this.HasInstructions">
                <div class="design-mode-field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </ng-container>

            <mat-form-field appearance="outline" [floatLabel]="'always'" class="full-width">
                <mat-label *ngIf="this.ShowLabel && !HasInstructions"
                           class="design-mode-field-label">
                    {{this.DisplayName}} <span *ngIf="this.FormField.required"
                                               style="color:red">*</span>
                </mat-label>

                <input matInput placeholder="{{this.htmlPlaceHolderText}}" value="{{this.htmlDefaultValue}}" disabled />

            </mat-form-field>
            <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>

    <div class="flex-row-top-right height-100-percent"
         *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')"
         [ngClass]="this.ReadOnly ? 'form-field-margin-top' : ''">
        <div class="fill-remaining-space height-100-percent">
            <ng-container *ngIf="(this.ControlType === 'Reactive Forms')">
                <ng-container *ngIf="this.FormControl !== null">

                    <!-- If there are instructions, display label first, then instructions then field -->
                    <ng-container *ngIf="this.HasInstructions">
                        <div class="field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                    </ng-container>

                    <div *ngIf="this.ReadOnly; else htmlLinkNotReadOnly">
                        <!--  TEAMS-894: KLW - If we try to use the normal behavior of href, it will go through the routing we have setup and will alway put the environment url
                             before the link. To get around this, disable the href link and handle the opening of the link in a separate method -->
                        <a class="html-display"
                           href=""
                           disabled
                           (click)="this.openLink()">{{this.FormControlValue}}</a>
                    </div>

                    <ng-template #htmlLinkNotReadOnly>
                        <mat-form-field appearance="outline" [floatLabel]="'always'" class="full-width">
                            <!-- If there are no instructions, display label integrated into the field -->
                            <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid"
                                       class="field-label">
                                {{this.DisplayName}} <span *ngIf="this.FormField.required"
                                                           style="color:red">*</span>
                            </mat-label>

                            <ng-container *ngIf="!this.UseGridSpecificStyling">
                                <input matInput
                                       type="text"
                                       id="{{this.MatInputId}}"
                                       [readonly]="this.ReadOnly"
                                       class="null-tooltip-mat-input"
                                       placeholder="{{this.htmlPlaceHolderText}}"
                                       [formControl]="this.FormControl"
                                       (blur)="this.handleOnBlur()"
                                       (focus)="this.handleOnFocus($event)" />
                            </ng-container>
                            <ng-container *ngIf="this.UseGridSpecificStyling">
                                <input #gridOnlyTextArea
                                       matInput
                                       class="height-100-percent"
                                       placeholder="{{this.htmlPlaceHolderText}}"
                                       [formControl]="this.FormControl"
                                       (blur)="this.handleOnBlur()"
                                       (focus)="this.handleOnFocus($event)" />
                            </ng-container>
                        </mat-form-field>
                    </ng-template>
                    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                </ng-container>
            </ng-container>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>
</div>
