import { IFormPreRenderFilter } from '../../../interfaces/iform-pre-render-filter.interface';
import { Form } from '../form.model';
import { FormField } from '../form-field.model';

export class PreRenderFilterBase implements IFormPreRenderFilter {
    // Implement default IFormPreRenderFilter behavior.
    public allowFormInstanceElement(presentlyUnusedForm: Form, presentlyUnusedFormField: FormField, userRoleNames: string[], presentlyUnusedFormInstanceElement): boolean {
        return true; // A default behavior only.
    }
    public markFieldAsReadOnly(presentlyUnusedForm: Form, presentlyUnusedFormField: FormField, userRoleNames: string[], presentlyUnusedFormInstanceElement): boolean {
        return false; // A default behavior only.
    }

    // Constructor.
    protected constructor() {
    }
}
