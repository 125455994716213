import { IFieldDefinitionLogic } from '../../interfaces/ifield-definition-logic.interface';
import { IViewModel } from '../../interfaces/view-model.interface';

export class FieldDefinitionAllowDropFlags {
    public allowDrop: boolean;
    public dropErrorMessage: string;
}

// Note:  interface IFieldDefinitionLogic plays much the same role as interface
//        IFieldDefinition within the server code.  While the server's IFieldDefinition
//        interface is widely used, we are just starting to use interface
//        IFieldDefinitionLogic in the client code.
//
//        Some existing functionality presently performed by components like the form
//        builder can be rolled into implementatios of this interface in order to reduce
//        the amount of conditional logic required in components, like the form builder,
//        that manage form fields of different types.
//
// Note:  interface IFieldDefinitionLogic has been moved to file
//        ifield-definition-client-logic-interface.ts in directory ./field-defs.

export class FieldDefinition implements IViewModel {
    // Properties.
    public id: number;
    public toolBoxGroup: string;
    public className: string; // Note:  this is the full field definition class name.
    public get fieldDefinitionClassName(): string {
        return this.className;
    }

    public displayName: string;
    public description: string;

    public icon: any[];
    public iconName: string;
    public iconType: string;
    public thumbNail: any[];

    public canBeDroppedOnForm: boolean; // Added 02-23-2021.
    public canBeDroppedOnFormAsCell: boolean; // Added 07-30-2022.
    public canBeDroppedOnGrid: boolean;
    public canBeDroppedOnSiteHomePage: boolean;

    public cannotBeSplitOnForm: boolean; // If true, the user cannot split the space use by this field.

    public hasCDataContent: boolean; // Defined on server, not relevant on client.

    // Properties related to "Show To"/"Hide From" logic.
    public occupiesEntireFormRow: boolean;
    public hasBeginAndEndElements: boolean; // Change this property name to 'createsFormRegion';

    public allowsFieldNesting: boolean;
    public isShowToConditional: boolean;
    public isHideFromConditional: boolean;
    public appliesRoleBasedBehavior: boolean; // Previously:  public isShowToHideFromConditional: boolean;
    //public occupiesEntireRow: boolean;

    // Properties related to "special form actions".
    public hasSpecialFormAction: boolean;
    public specialFormAction: string;

    // Additional metadata property/properties.
    public isNumeric: boolean; // Note:  this property is derived from a server method of the same name.
    public isTextual: boolean; // Added 01-12-2023.
    public isFootnote: boolean; // Added 01-12-2023.

    // Flag indicating that the field type is a beta feature.
    public isBetaFeature: boolean;

    public controlTypeHint: string; // Added 01-12-2023.

    public isMetadataField: boolean; // Added 02-07-2024.

    public shortFieldDefClassName: string; // Added 01-12-2023.

    public valueType: string; // Added 01-12-2023.
    public formInstanceElementPropertyName: string;
    public get formInstElemPropName(): string {
        return this.formInstanceElementPropertyName;
    }

    public availableFieldConditionalLogicOperators: string;

    // Custom logic handler.
    public customLogicHandler: IFieldDefinitionLogic = null;
}
