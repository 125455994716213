import { FormField } from "../form-builder/form-field.model";
import { FormFieldBaseComponent } from '../../components/form-fields/form-field-base/form-field-base.component';;
import { IFormFieldComponent } from '../../interfaces/iform-field-component';

export class ComponentAndFormField {
    // Properties.
    private component: FormFieldBaseComponent;
    //private component: IFormFieldComponent;
    private formField: FormField;

    // Constructor.
    public constructor(componentParam: FormFieldBaseComponent, formFieldParam: FormField) {
    //public constructor(componentParam: IFormFieldComponent, formFieldParam: FormField) {
        this.component = componentParam;
        this.formField = formFieldParam;

        return;
    }

    // Getter methods.
    public get Component(): FormFieldBaseComponent {
    //public get Component(): IFormFieldComponent {
        return (this.component);
    }
    /*
    public get ComponentAsInterface(): IFormFieldComponent {
        return (this.component);
    }
    */
    public get FormField(): FormField {
        return (this.formField);
    }
}
