// Define a single class that implements
// commonly used IListItem properties/behavior.
export class IListItemBase {
    // Primitive properties.
    public itemIndex: number;

    public name: string = null;

    // Implement the IListItem getTitle() method in a standard way.
    public getName(): string {
        return this.name;
    }

    public getTitle(): string {
        let title: string = `Click to view ${this.name}`;

        return (title);
    }

    public isMagicStructure(): boolean {
        return false;
    }

    // Default constructor.
    public constructor() {
        return;
    }
}
