import { IViewModel } from '../../interfaces/view-model.interface';

export interface IFormContentElement {
    id: number;

    valueType: string;

    intValue: number;
    doubleValue: number;
    decimalValue: number;
    customValue: string;
    textValue: string;
    booleanValue: boolean;

    childFormInstanceElements: IFormContentElement[];
}

export interface IRowCellDataHash {
    [columnKey: string]: IFormContentElement;
}

// This class maps to ViewModels.GridRow on the server
export class GridRowViewModel implements IViewModel {
    public id: number;

    public isPendingEditFor_GridRowId: number;

    public formInstanceElementId: number;

    public formInstanceId: number;

    public formFieldId: number;

    public isDeleted: boolean;

    public rowIndex: number;

    public cellDataHash: IRowCellDataHash;

    // Note:  please see the comment defined above the 'GridKeyColumns' property in model class FormField
    //        for information about grid row keys, how they are comprised, how the key values are used, etc.
    public rowKeyValue: string;

    // Begin ignored fields.
    public intValue: number;
    public doubleValue: number;
    public decimalValue: number;
    public textValue: string;
    public customValue: string;
    public booleanValue: boolean;
    // End ignored fields.
}
