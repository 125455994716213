import { Type } from 'class-transformer';

import { IViewModel } from '../interfaces/view-model.interface';
import { WorkflowTransitionPermission } from './workflow-transition-permission.model';
import { WorkflowPermissionBase } from './workflow-permission-base.model';
import { IHasIdAndName } from '../interfaces/has-id-and-name.interface';
import { IHasWorkflowPermissions } from '../interfaces/has-workflow-permissions.interface';
import { ICloneAndCopy } from '../interfaces/clone-and-copy';
import { WorkflowState } from './workflow-state.model';

export class WorkflowTransition implements IViewModel,
    IHasIdAndName, IHasWorkflowPermissions, ICloneAndCopy {
    // Key.
    public id: number = 0;
    public getId(): number {
        return (this.id);
    }

    // Client-only key (not saved in the database).
    public clientId: number = 0;

    // Implement IHasIdAndName methods.
    public getName(): string {
        return (this.name);
    }
    public setName(nameParam: string): void {
        this.name = nameParam;
    }

    public static readonly TypeName: string = 'WorkflowTransition';
    public typeName(): string {
        return (WorkflowTransition.TypeName);
    }

    public hasDescriptionField(): boolean {
        return true;
    }
    public getDescription(): string {
        return this.description;
    }
    public setDescription(val: string) {
        this.description = val;
    }

    // Define new HasIdAndName interface methods related to optional workflow transition customization.
    public getChangeWorkflowStateDialogTitle(): string {
        return this.changeWorkflowStateDialogTitle;
    }
    public setChangeWorkflowStateDialogTitle(value: string): void {
        this.changeWorkflowStateDialogTitle = value;
    }

    public getTransitionConfirmationDialogMessage(): string {
        return this.transitionConfirmationDialogMessage;
    }
    public setTransitionConfirmationDialogMessage(value: string): void {
        this.transitionConfirmationDialogMessage = value;
    }

    public getTakeUserToSiteHomePageAfterTransitionApplied(): boolean {
        return this.takeUserToSiteHomePageAfterTransitionApplied;
    }
    public setTakeUserToSiteHomePageAfterTransitionApplied(value: boolean): void {
        this.takeUserToSiteHomePageAfterTransitionApplied = value;
    }
    // End new HasIdAndName interface methods related to optional workflow transition customization.

    // Primitives.
    public name: string;

    public description: string; // Added 04-08-2022.
    
    public workflowId: number;
    public startStateId: number;
    public endStateId: number;

    //TEAMS-178: KLW - State orders needed for the workflow transition buttons
    public startStateSortOrder: number;
    public endStateSortOrder: number;
    public isCurrentState: boolean = false;

    public startStateName: string; // For save new workflows when state Ids have not yet been assigned.
    public endStateName: string; 

    // Client-only keys (not saved in the database).
    public transientStartStateClientId: number = 0;
    public transientEndStateClientId: number = 0;
    public transientClientOrder: number;

    // Begin new properties related to https://maxjira.max.gov/browse/VNEXT-1337.
    public changeWorkflowStateDialogTitle: string; // If not set to a value, dialogue title "Change Workflow State" is used.
    public transitionConfirmationDialogMessage: string; // If not set to a value, confirmation message "Are you sure you want to [current state] to [new state]?" is used.

    public takeUserToSiteHomePageAfterTransitionApplied: boolean;
    // End new properties related to https://maxjira.max.gov/browse/VNEXT-1337.

    public transientStatesBeforeTransition: WorkflowState[];
    public transientStatesAfterTransition: WorkflowState[];

    public getPropertiesDrawerTitle(): string {
        return "Workflow Transition Properties";
    }

    // Collections.
    @Type(() => WorkflowTransitionPermission)
    public permissions: WorkflowTransitionPermission[];

    // Implement interface IHasWorkflowPermissions.
    public getPropertiesTitle(): string {
        let title: string = `Permissions:  ${this.name}`;

        return (title);
    }

    // getPermissionNames():  returns the permission names show to a user.
    public getPermissionNames(): string[] {
        let names: string[] = [
            'Transition'
        ];

        return (names);
    }

    // getPermissionFlagNames():  returns the name(s) of permission flags,
    // without the 'can' portion, applicable within this model class.
    public getPermissionFlagNames(): string[] {
        return (['Edit']);
    }

    public getSimplePermissionNames(): string[] {
        return (this.getPermissionNames());
    }

    public getPermissions(): WorkflowPermissionBase[] {
        // Make sure the following workflow state permissions
        // are set:  workflowId, stateId, and stateName.
        if (this.permissions &&
            (this.permissions.length > 0) &&
            !this.permissions[0].transientParentPropertiesSet) {
            for (let iPermission: number = 0; iPermission < this.permissions.length; iPermission++) {
                let permission: WorkflowTransitionPermission = this.permissions[iPermission];

                permission.workflowId = this.workflowId;
                permission.transitionId = this.id;
                permission.transitionName = this.name;

                permission.transientParentPropertiesSet = true;
            }
        }

        // Return the permissions.
        return (this.permissions);
    }

    public get Permissions(): WorkflowPermissionBase[] {
        return (this.getPermissions());
    }

    public get PermissionCount(): number {
        let permissions: WorkflowPermissionBase[] = this.getPermissions();
        let permissionCount: number = (permissions != null ? permissions.length : 0);

        return (permissionCount);
    }

    public addPermission(permissionParam: WorkflowPermissionBase): boolean {
        let permission: WorkflowTransitionPermission = new WorkflowTransitionPermission();
        permission.workflowId = this.workflowId;
        permission.transitionId = this.id;
        permission.transitionName = this.name;
        permission.copyFieldsFrom(permissionParam);

        this.ensurePermissions();
        this.permissions.push(permission);

        return (true);
    }

    public updatePermission(permissionParam: WorkflowPermissionBase): boolean {
        this.ensurePermissions();

        let permissions: WorkflowTransitionPermission[] = this.permissions.filter(p => p.roleName == permissionParam.roleName);
        let bHavePermission: boolean = (permissions.length === 1);

        if (bHavePermission) {
            let permission: WorkflowTransitionPermission = permissions[0];
            permission.copyFieldsFrom(permissionParam);
        }

        return (bHavePermission);
    }

    public removePermission(permissionParam: WorkflowPermissionBase): boolean {
        this.ensurePermissions();

        let iNumPermissions = this.permissions.length;

        this.permissions = this.permissions.filter(p => p.roleName != permissionParam.roleName);

        return (this.permissions.length != iNumPermissions);
    }

    public replacePermissionsForRole(roleId: number, workflowTransitionPermissions: WorkflowTransitionPermission[]) {
        this.ensurePermissions();

        this.permissions = this.permissions.filter(p => p.roleId != roleId);
        for (let iPermission: number = 0; iPermission < workflowTransitionPermissions.length; iPermission++) {
            let workflowTransitionPermission: WorkflowTransitionPermission = workflowTransitionPermissions[iPermission];

            this.permissions.push(workflowTransitionPermission);
        }
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let clone: WorkflowTransition = new WorkflowTransition();

        clone.id = this.id;

        clone.name = this.name;
        clone.description = this.description;

        clone.workflowId = this.workflowId;

        clone.startStateId = this.startStateId;
        clone.endStateId = this.endStateId;

        //TEAMS-178: KLW - State orders for end and start states of the transition
        clone.startStateSortOrder = this.startStateSortOrder;
        clone.endStateSortOrder = this.endStateSortOrder;

        clone.startStateName = this.startStateName;
        clone.endStateName = this.endStateName;

        //clone.startStateClientId = this.startStateClientId;
        //clone.endStateClientId = this.endStateClientId;
        clone.transientStartStateClientId = this.transientStartStateClientId;
        clone.transientEndStateClientId = this.transientEndStateClientId;

        clone.permissions = [];
        if (this.permissions && (this.permissions.length > 0)) {
            for (let iPerm: number = 0; iPerm < this.permissions.length; iPerm++) {
                let permission: WorkflowTransitionPermission = this.permissions[iPerm];

                clone.permissions.push(permission);
            }
        }

        return (clone);
    }

    public copy(objectToCopyParam: ICloneAndCopy): void {
        let objectToCopy: WorkflowTransition = <WorkflowTransition>objectToCopyParam;

        this.id = objectToCopy.id;

        this.name = objectToCopy.name;
        this.description = objectToCopy.description;

        this.workflowId = objectToCopy.workflowId;

        this.startStateId = objectToCopy.startStateId;
        this.endStateId = objectToCopy.endStateId;

        this.startStateName = objectToCopy.startStateName;
        this.endStateName = objectToCopy.endStateName;

        //this.startStateClientId = objectToCopy.startStateClientId;
        //this.endStateClientId = objectToCopy.endStateClientId;
        this.transientStartStateClientId = objectToCopy.transientStartStateClientId;
        this.transientEndStateClientId = objectToCopy.transientEndStateClientId;

        this.permissions = [];
        if (objectToCopy.permissions && (objectToCopy.permissions.length > 0)) {
            for (let iPerm: number = 0; iPerm < objectToCopy.permissions.length; iPerm++) {
                let permission: WorkflowTransitionPermission = objectToCopy.permissions[iPerm];

                this.permissions.push(permission);
            }
        }

        return;
    }

    // Implement private helper methods.
    private ensurePermissions(): void {
        if (!this.permissions) {
            this.permissions = [];
        }

        return;
    }
}
