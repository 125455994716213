<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<ng-template #noAutoComplete>
    <div>
        <div *ngIf="this.Mode === 'design'" class="flex-row-top-right">
            <div class="fill-remaining-space">
                <!-- If there are instructions, display label first, then instructions then field -->
                <ng-container *ngIf="this.HasInstructions">
                    <div class="design-mode-field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                </ng-container>

                <div>
                    <mat-form-field appearance="outline" class="dropdown-mat-form-field">
                        <mat-label class="design-mode-field-label" *ngIf="this.ShowLabel && !HasInstructions">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></mat-label>

                        <mat-select disabled [(ngModel)]="this.DefaultDropdownValue">
                            <mat-option [value]="-1">
                                {{this.DefaultDropdownValueText}}
                            </mat-option>
                            <mat-option *ngFor="let hshOption of this.ConfiguredOptions"
                                        [value]="hshOption.OptionId"
                                        title="{{hshOption.OptionText}}">
                                {{hshOption.OptionText}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
        </div>

        <div *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')"
             class="flex-row-top-right">
            <div class="fill-remaining-space">
                <ng-container *ngIf="this.FormGroup !== null">

                    <!-- If there are instructions, display label first, then instructions then field -->
                    <ng-container *ngIf="this.HasInstructions">
                        <div class="field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                    </ng-container>

                    <form [formGroup]="this.FormGroup">
                        <mat-form-field appearance="outline"
                                        [floatLabel]="'always'"
                                        class="dropdown-mat-form-field">
                            <!-- If there are no instructions, display label integrated into the field -->
                            <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid"
                                       class="field-label">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></mat-label>

                            <mat-select formControlName="dropdown_form"
                                        [disabled]="this.ReadOnly"
                                        (blur)="this.handleValidationOnBlur()">
                                <mat-option *ngFor="let strSelectOption of this.SelectOptions"
                                            title="{{strSelectOption}}"
                                            [value]="strSelectOption">
                                    {{strSelectOption}}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                    </form>
                </ng-container>

                <ng-container *ngIf="(! this.SelectOptions) || (this.SelectOptions.length === 0)">
                    <span>This control has no select options defined</span>
                </ng-container>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
        </div>
    </div>
</ng-template>


<ng-template #singleDropDown>
    <div *ngIf="this.AutocompleteEnabled && !this.IsDesign; else noAutoComplete">
        <app-autocomplete #singledropdownauto
                          [title]="this.DropDownOptionsTitle"
                          (selectedItems)="selectChange($event)"
                          (onBlur)="this.blurValidation()">
        </app-autocomplete>
    </div>
    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
</ng-template>

<div class="outer-div">
    <div class="flex-row-top-right">

        <div class="fill-remaining-space">
            <ng-template [ngTemplateOutlet]="singleDropDown"></ng-template>
        </div>

        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>
</div>

