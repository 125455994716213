import { Type, plainToClass } from 'class-transformer';

import { Workflow } from '../site-content/workflow.model';
import { VirtualFolderPermissionsHierarchyRecord } from './virtualFolderPerHierarchyRecord.model';
import { PermissionsPreviewBase } from './permissions-preview-base.model';

export class PermissionsPreviewVirtualFolderHierarchyRequest extends PermissionsPreviewBase {
    /*
    // Define an ignored Id field to correspond
    // to the client model class definition.
    public id: number;

    public dataCollectionId: number;

    // Define permissions preview inputs.
    @Type(() => Workflow)
    public workflowHierarchy: Workflow[];
    */

    // Properties.
    public allRoleNames: string[];

    public virtualFolderId: number;
    public selectedRoleName: string;

    // Permissions preview result properties.
    @Type(() => VirtualFolderPermissionsHierarchyRecord)
    public hierarchyRecords: VirtualFolderPermissionsHierarchyRecord[];

    // Constructor.
    public constructor(objectToCopy: PermissionsPreviewVirtualFolderHierarchyRequest = null) {
        super(objectToCopy);

        if (objectToCopy != null) {
            /*
            this.id = objectToCopy.id;

            this.dataCollectionId = objectToCopy.dataCollectionId;

            this.workflowHierarchy = [];
            if (objectToCopy.workflowHierarchy != null) {
                for (let iWorkflow: number = 0; iWorkflow < objectToCopy.workflowHierarchy.length; iWorkflow++) {
                    let workflowAsObject: Workflow = objectToCopy.workflowHierarchy[iWorkflow];
                    let workflow: Workflow = plainToClass(Workflow, workflowAsObject);

                    this.workflowHierarchy.push(workflow);
                }
            }
            */

            this.allRoleNames = objectToCopy.allRoleNames;

            this.virtualFolderId = objectToCopy.virtualFolderId;
            this.selectedRoleName = objectToCopy.selectedRoleName;

            this.hierarchyRecords = [];
            if (objectToCopy.hierarchyRecords != null) {
                for (let iRecord: number = 0; iRecord < objectToCopy.hierarchyRecords.length; iRecord++) {
                    let recordAsObject: VirtualFolderPermissionsHierarchyRecord = objectToCopy.hierarchyRecords[iRecord];
                    let record: VirtualFolderPermissionsHierarchyRecord = plainToClass(VirtualFolderPermissionsHierarchyRecord, recordAsObject);

                    this.hierarchyRecords.push(record);
                }
            }
        }
    }
}
