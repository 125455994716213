import { plainToClass } from 'class-transformer';
import { Injectable } from '@angular/core';

import { CollectApiServiceBase } from './collect-api-base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ProgressIndicatorService } from './progress-indicator.service';
import { FolderPermissionExpression } from '../models/site-metadata/folder-permission-expression.model';
import { SiteETLRequest } from '../models/site-etl/site-etl-request.model';
import { AsyncJob } from '../models/async-job.model';

@Injectable({
    providedIn: 'root'
})
export class SiteETLService extends CollectApiServiceBase<SiteETLRequest> {
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'siteETL', SiteETLRequest)
    }

    public formatResponse(data: SiteETLRequest): SiteETLRequest {
        let obj = plainToClass(SiteETLRequest, data);
        return (obj);
    }

    // Implement service methods.
    public generateSiteETLJob(dataCollectionId: number): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/generateSiteETLJob/${dataCollectionId}`;

        return this.http.get<any[]>(url)
            .toPromise()
            .then(response => {
                //let asyncJob: AsyncJob = response;
                let result = plainToClass(AsyncJob, response);
                return result;

            })
            .catch(this.handleError);    
    }

    public runSiteETLJob(dataCollectionId: number): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/runSiteETLJob/${dataCollectionId}`;

        return this.http.get<any[]>(url)
            .toPromise()
            .then(response => {
                //let asyncJob: AsyncJob = response;
                let result = plainToClass(AsyncJob, response);
                return result;

            })
            .catch(this.handleError);    
    }

    // Method for getting an async job's result URL.
    public getResultFileDownloadUrl(asyncJob: AsyncJob, expectedDownloadFileName: string): string {
        let url = `${this.url}/${this.endpoint}/JobKey/${asyncJob.jobKey}/FileDownloadName/${expectedDownloadFileName}`;

        return (url);
    }

}
