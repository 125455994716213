import { Type, plainToClass } from 'class-transformer';

import { TargetFieldConditionalLogicInstructions } from './target-field-logic-instructions';

export class SourceFieldChangeConditionalLogicInstructions {
    public triggeringSourceFieldId: number;

    @Type(() => TargetFieldConditionalLogicInstructions)
    public targetFieldInstructions: TargetFieldConditionalLogicInstructions[];
}
