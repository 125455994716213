import { ElementTypeMetadata } from './element-type-metadata.model';
import { AdditionalElementInfo } from './additional-element-info.model';

export class ScriptableElementsToFind {
    public elements: object = null;
    public elementsFound: boolean = false;

    public constructor(public anElementSelector: string, public elementTypeMetadata: ElementTypeMetadata, public additionalElementInfo: AdditionalElementInfo = null) {
    }
}
