import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import { FieldDefinitionService } from '../services/field-definition.service';
import { FormInstanceService } from '../services/form-instance.service';

@Injectable()
export class FormInstanceResolver implements Resolve<any> {
    constructor(private formInstanceService: FormInstanceService,
        private fieldDefinitionService: FieldDefinitionService,
        private readonly titleService: Title) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        if (this.isFormInstance(state.url)) {
            // Make sure field definitions have been
            // loaded before loading the form instance.
            // 11-10-2022 note:  need to find a simpler way to load all field definition objects from the server.
            return this.fieldDefinitionService.getAll().then(ignoredResponse => {
                return this.loadFormInstanceContent(state);
            });
            //return null;
        }
        return null;
    }

    private isFormInstance(url): boolean {
        return url.indexOf('/form/') > -1 || url.indexOf('/version/') > -1;
    }

    private getIdAndVersion(url: string): any {
        let ret = {};
        let segments = url.split('/');
        let lastSeg = segments[segments.length - 1].split('?');
        ret['id'] = +lastSeg[0];
        if (lastSeg.length == 2) {
            ret['isVersion'] = lastSeg[1].indexOf('true') > -1; // TODO: clean this up
        }
        return ret;
    }

    private getIdFromUrl(url: string): number {
        let segments = url.split('/');
        return +segments[segments.length - 1];
    }

    private loadFormInstanceContent(state: RouterStateSnapshot): Promise<any> {
        let obj = this.getIdAndVersion(state.url);
        let id = obj.id;
        let isVersion = obj.isVersion;
        return this.formInstanceService.getContent(id, isVersion).then(response => {
            if (response && response.name) {
                this.titleService.setTitle(environment.sitename + ' - ' + response.name);
            }
            return response;
        });
    }
}
