import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { DefaultFieldDefinitionClientLogic } from './default-field-def-client-logic';
import { FormField } from '../form-field.model';

export class FormTemplateInstancesFieldDefLogic extends DefaultFieldDefinitionClientLogic implements IFieldDefinitionLogic {
    // Note:  this class can be enhanced to allow for custom form template instances field definition client logic.
    public constructor() {
        super();
    }

    // Override default method implementations where appropriate.
    public isATypeOfGridField(formField: FormField): boolean {
        return true;
    }
}
