import { Type, plainToClass } from 'class-transformer';

//import { Workflow } from '../workflow.model';
import { VirtualFolderPermissionsHierarchyRecord } from './virtualFolderPerHierarchyRecord.model';
import { PermissionsPreviewBase } from './permissions-preview-base.model';

export class PermissionsPreviewAllVirtualFoldersHierarchyRequest extends PermissionsPreviewBase {
    // Properties.
    public allRoleNames: string[];

    public distinctFolderIdList: string;

    // Permissions preview result properties.
    @Type(() => VirtualFolderPermissionsHierarchyRecord)
    public hierarchyRecords: VirtualFolderPermissionsHierarchyRecord[];

    // Constructor.
    public constructor(objectToCopy: PermissionsPreviewAllVirtualFoldersHierarchyRequest = null) {
        super(objectToCopy);

        if (objectToCopy != null) {
            this.allRoleNames = objectToCopy.allRoleNames;
            this.distinctFolderIdList = objectToCopy.distinctFolderIdList;

            this.hierarchyRecords = [];
            if (objectToCopy.hierarchyRecords != null) {
                for (let iRecord: number = 0; iRecord < objectToCopy.hierarchyRecords.length; iRecord++) {
                    let recordAsObject: VirtualFolderPermissionsHierarchyRecord = objectToCopy.hierarchyRecords[iRecord];
                    let record: VirtualFolderPermissionsHierarchyRecord = plainToClass(VirtualFolderPermissionsHierarchyRecord, recordAsObject);

                    this.hierarchyRecords.push(record);
                }
            }

        }
    }
}
