import { DropdownFieldDefLogic } from './dropdown-field-def-logic';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormFieldBaseComponent } from '../../../components/form-fields/form-field-base/form-field-base.component';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';

export class RadioButtonsFieldDefLogic extends DropdownFieldDefLogic {
    public constructor() {
        super();
    };

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            let selectOptions: string[] = FormField.ParseSelectOptions(formFieldParam);
            FormFieldBaseComponent.staticSetDefaultTextSelectValue(formFieldParam, formInstanceElementParam, selectOptions);

            formInstanceElementParam.TextValue = formInstanceElementParam.textValue;
        }

        return (formInstanceElementParam.textValue);
    }

    public fieldCanBeUsedInSiteHomePageConditionalLogic(formField: FormField): boolean {
        return true;
    }
    public createSiteHomePageFormInstanceElementFor(formField: FormField): FormInstanceElement {
        let formInstanceElement = new FormInstanceElement();
        formInstanceElement.formFieldId = formField.id;
        formInstanceElement.formFieldName = formField.name;
        formInstanceElement.formField = formField;
        formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeText;
        formInstanceElement.textValue = 'Debugging radio buttons ...';
        return formInstanceElement;
    }
}
