import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-grid-paste-kendo',
    templateUrl: './grid-paste-kendo.dialog.html',
    styleUrls: ['./grid-paste-kendo.dialog.scss']
})
export class GridPasteKendoDialog implements OnInit {

    public pasteData: string;
    public parseInfo: string = ' ';
    public parseError: string = ' ';
    public disableCopyButton: boolean = true;
    public showLengthWarning: boolean = false;

    public constructor(@Inject(MAT_DIALOG_DATA) public griddata: { colCount: number, rowCount: number, gridHasData: boolean, pasteMode: string, pasteData: string }) { }

    ngOnInit() { }

    clickCancel() {
        this.griddata.pasteData = '';
    }

    handleNgOnChange() {
        this.disableCopyButton = false;
        let pasteDataLines: string[];
        let pasteColCount: number;

        this.showLengthWarning = false;
        pasteDataLines = this.pasteData.split(/\r\n|\r|\n/);
        if (pasteDataLines.length > 10) this.showLengthWarning = true;
        pasteColCount = pasteDataLines[0].split('\t').length;

        this.parseInfo = pasteColCount + (pasteColCount > 1 ? ' columns' : ' column') + ' in pasted data, ' + this.griddata.colCount + (this.griddata.colCount > 1 ? ' columns' : ' column') + ' in grid';

        if (pasteColCount < this.griddata.colCount) {
            this.parseError = 'Grid has more columns than pasted data.';
            this.disableCopyButton = true;
        }
        else if (pasteColCount > this.griddata.colCount) {
            this.parseError = 'Pasted data has more columns than grid.';
            this.disableCopyButton = true;
        }
        else {
            this.parseInfo = '';
            this.parseError = '';
            this.disableCopyButton = false;
        }

        this.griddata.pasteData =  this.pasteData;
    }
}
