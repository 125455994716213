import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Settings } from '../models/settings.model';

@Injectable()
export class SettingsService {

    constructor(private http: HttpClient) {
        
    }

    private get(): Promise<Settings> {
        var url = 'assets/data/settings.json';

        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response;
            })
            .catch(this.handleError);
    } 

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    public loadSettings(): Promise<Settings> {
        return this.get();
    }
}
