import { DecimalFieldDefLogic } from './decimal-field-def-logic';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../form-instance-element.model';
import { IGridRow, INumericValuesHashByFieldName } from '../../../interfaces/grid-row.interface';
import { FormField } from '../form-field.model';
import { FieldDefinitionService } from '../../../services/field-definition.service';
import { FieldDefinition } from '../../../models/form-builder/field-definition.model';
import { FormulaFormFieldComponent } from '../../../components/form-fields/formula-form-field/formula-form-field.component';
import { FieldNameHashes } from '../../../components/form-fields/formula-form-field/field-name-hashes';
import { FormulaEvaluationHelper } from '../../../components/form-fields/formula-form-field/formula-evaluation-helper';
import { FormulaEvaluator } from '../../../components/form-fields/formula-form-field/formula-evaluator';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { AppInjector } from '../../../../app.module';
import { GridRowDef } from '../../../models/grid/grid-row.model';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';

import * as math from 'mathjs';

export class FormulaFieldDefLogic extends DecimalFieldDefLogic {
    // Constructor.
    public constructor(private fieldDefinitionServce: FieldDefinitionService) {
        super();
    };

    // This method returns the result of the formula calculation for display in a grid row including formatting it per field settings
    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[]): string {
        let displayValue: string = '';
        if (gridRowColumnDefs != null) {

            let hshFieldValues: INumericValuesHashByFieldName = gridRow.getNumericValuesHashByFieldName(this.fieldDefinitionServce);
            let formulaEvaluationHelper = new FormulaEvaluationHelper().init(formFieldParam, gridRow, gridRowColumnDefs);

            // I hadn't noticed that this class already has its own evaluateFormula()
            let result = FormulaEvaluator.evaluateFormula(formulaEvaluationHelper, formFieldParam.formula, hshFieldValues);
            displayValue = result.error ? result.error : result.result;
        } else {
            // not sure if this actualy does anything, doesn't seem to as of 8/4/2023
            if (formInstanceElementParam.doubleValue != null) {
                if (formFieldParam.showDigitsWithCommandSeparators) {
                    let formatter = new Intl.NumberFormat('en-us', { minimumFractionDigits: 0 });
                    displayValue = formFieldParam.roundResultToWholeNumber ? formatter.format(Math.round(formInstanceElementParam.doubleValue)) : formatter.format(formInstanceElementParam.doubleValue);
                } else
                    displayValue = formFieldParam.roundResultToWholeNumber ? Math.round(formInstanceElementParam.doubleValue).toString() : formInstanceElementParam.doubleValue.toString();
            }
        }

        if (formFieldParam.showDigitsWithCommandSeparators) {
            // Try to convert the display value to a number to format it according to its
            // locale, e.g.with embedded commas in the U.S.for a number greater than 999.
            let displayValueAsNumber: number = parseFloat(displayValue);
            if (!isNaN(displayValueAsNumber))
                displayValue = displayValueAsNumber.toLocaleString();
        }

        // Has this field been configured to show a leading dollar sign?
        if (formFieldParam.showDollarSignPrefix)
            displayValue = `$ ${displayValue}`;

        return displayValue;
    }

    public requiresFieldValueUpdate(): boolean {
        return true;
    }

    // Calulates the result and sets it as the value of the underlying form instance element
    public formFieldValuesUpdated(fieldDefinitionService: FieldDefinitionService, otherFormFields: FormField[], otherFormInstanceElements: FormInstanceElement[], gridRow: IGridRow, formField: FormField, formInstanceElementToUpdate: FormInstanceElement): void {
        let hshFieldValues: INumericValuesHashByFieldName = gridRow.getNumericValuesHashByFieldName(this.fieldDefinitionServce);
        let formulaEvaluationHelper = new FormulaEvaluationHelper().init(formField, gridRow, otherFormFields);

        // I hadn't noticed that this class already has its own evaluateFormula()
        let hshResult = FormulaEvaluator.evaluateFormula(formulaEvaluationHelper, formField.formula, hshFieldValues);

        if (hshResult) {
            if (!hshResult['error']) {
                formInstanceElementToUpdate.DoubleValue = hshResult['result'];
                formInstanceElementToUpdate.DecimalValue = hshResult['result'];
                formInstanceElementToUpdate.valueType = FormInstanceElementValueTypeEnum.TypeDouble;
            }
        }
    }

    // Not sure if this is actually getting called from anywhere
    public getNumericValue(formFieldParam: FormField, otherFormFields: FormField[], formInstanceElementParam: FormInstanceElement, gridRowParam: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): number {
        let formulaEvaluationHelper = new FormulaEvaluationHelper();

        if ((processingPhase === FormFieldProcessingPhaseEnum.EDITING_DATA) || (processingPhase === FormFieldProcessingPhaseEnum.LOADING_DATA) || (processingPhase === FormFieldProcessingPhaseEnum.CREATING_DATA)) {
            let fieldDefinitionService: FieldDefinitionService = AppInjector.get(FieldDefinitionService);
            let hshFieldValues: INumericValuesHashByFieldName = gridRowParam.getNumericValuesHashByFieldName(fieldDefinitionService);

            let parser: any = math.parser();
            let otherFormInstanceElements: FormInstanceElement[] = [];
            let gridRow: GridRowDef = <GridRowDef>gridRowParam;
            if (gridRow.FormInstanceElementWrappers != null) {
                for (let index: number = 0; index < gridRow.FormInstanceElementWrappers.length; index++) {
                    let formInstanceElementWrapper: GridFormInstanceElementWrapper = gridRow.FormInstanceElementWrappers[index];
                    if (formInstanceElementWrapper.formInstanceElement != formInstanceElementParam)
                        otherFormInstanceElements.push(formInstanceElementWrapper.formInstanceElement);
                }
            }

            formulaEvaluationHelper.FieldNameHashes = FormulaFieldDefLogic.buildFieldNameHashes(fieldDefinitionService, otherFormFields, otherFormInstanceElements);
            formulaEvaluationHelper.FormField = formFieldParam;

            if ((parser != null) && (hshFieldValues != null) && (formulaEvaluationHelper.FieldNameHashes.hshColNameToFormField != null) && (formFieldParam.formula != null) && (formFieldParam.formula.trim() !== '')) {

                let hshResult = FormulaEvaluator.evaluateFormula(formulaEvaluationHelper, formFieldParam.formula, hshFieldValues);

                if (hshResult != null) {
                    if (!hshResult['error']) {
                        formInstanceElementParam.DoubleValue = hshResult['result'];
                    }
                }
            }
        }

        return formInstanceElementParam.doubleValue;
    }

    private static displayNameToVariableName(formFieldParam: FormField): string {
        let variableName: string = formFieldParam.displayName.toLowerCase().replace(/ /g, '_');

        return (variableName);
    }

    private static buildFieldNameHashes(fieldDefinitionService: FieldDefinitionService, otherFormFields: FormField[], otherFormInstanceElements: FormInstanceElement[]): FieldNameHashes {
        let fieldNameHashes: FieldNameHashes = new FieldNameHashes();
        fieldNameHashes.hshColNameToFormField = {};
        fieldNameHashes.hshDisplayVariableNameToFormField = {};
        fieldNameHashes.hshFieldIdToFieldName = {};
        let hshDebugFieldValues: any = {};

        for (let index: number = 0; index < otherFormFields.length; index++) {
            let otherFormField: FormField = otherFormFields[index];
            let otherFormInstanceElement: FormInstanceElement = otherFormInstanceElements[index];

            fieldNameHashes.hshColNameToFormField[otherFormField.name] = otherFormField;
            fieldNameHashes.hshFieldIdToFieldName[otherFormField.id] = otherFormField.name;

            // Determine the field's value.
            let numValue: number = 0.0;

            let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(otherFormField.fieldDefinitionClassName);

            if (fieldDefinition.isNumeric) {
                if (otherFormInstanceElement != null) {
                    numValue = otherFormInstanceElement.toNumber(otherFormField, fieldDefinition.formInstanceElementPropertyName);
                }
            }

            // Save the value in the values hash.            
            hshDebugFieldValues[`col${index + 1}`] = numValue;

            hshDebugFieldValues[`col${otherFormField.id}`] = numValue;

            if (otherFormField.displayName != null) {
                let displayVariableName: string = FormulaFieldDefLogic.displayNameToVariableName(otherFormField);
                fieldNameHashes.hshDisplayVariableNameToFormField[displayVariableName] = otherFormField;
                hshDebugFieldValues[displayVariableName] = numValue;
            }
        }

        return fieldNameHashes;
    }


    public filterType(): string {
        return 'text';
    }

    public isFilterable(): boolean {
        return false;
    }
}
