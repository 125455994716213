
// Define a class used to keep track of loading data progress.
export class LoadingDataProgressInfo {
    public readonly arrPageSizeOptions: number[] =
        [
            //2,
            25,
            50,
            100,
            250
        ];
    public readonly iPageSize: number = 250;

    public isLoadingGridData: boolean = false;
    public loadingDataMessage: string = null;
    public iLoadingDataProgressValue: number = 0;
    public iLoadingDataProgressBufferValue: number = 0;

    public bShowPaginator: boolean = false;
}
