import { IViewModel } from '../../interfaces/view-model.interface';

export class NotificationRecipient implements IViewModel {
    // Define primitive properties.
    // Key.
    public id: number = 0;

    public recipientType: string;
    public recipient: string;

    public recipientFirstName: string;
    public recipientLastName: string;
}
