import { Type } from 'class-transformer';
import { plainToClass } from 'class-transformer';

import { IViewModel } from '../../interfaces/view-model.interface';
import { Share } from '../share.model';

import { ICloneAndCopy } from '../../interfaces/clone-and-copy';
import { CsvOptionsFileData } from '../../models/csv-data/csv-options-file-data.model';
import { IFormFieldConstraintLiaison } from '../../components/form-builder/properties/form-field-properties/constraints/ifield-constraint-liason.interface';
import { IFormFieldConstraint } from '../../interfaces/iform-field-constraint.interface';
import { FixedFirstColumnValues } from '../grid/fixed-first-column-values.model';
import { CascadingDropDownFormFieldConfig } from '../cascading-dropdown/cascading-dropdown-config.model';
import { CascadingDropDownFormFieldData } from '../cascading-dropdown/cascading-dropdown-data.model';
import { GridCascadingDropDownFormFieldComponent } from '../../components/form-fields/grid-cascading-dropdown/grid-cascading-dropdown.component';
import { FormFieldListConstraintColumn } from '../../components/form-builder/field-configuration/flexible-selection-field-configuration/flexible-selection-field-configuration.component';
import { JavaScriptUtil } from '../../utility-classes/javascript.util';

// Define an internally used class.
class FieldDefinitionMetadata {
    public fieldDefinitionClassName: string;
    public shortFieldDefClassName: string; // Future (if needed).

    public isNumeric: boolean;
    public isTextual: boolean;
    public isFootnote: boolean; // Added 05-13-2022.

    public controlTypeHint: string;

    public formInstElemPropName: string; // The string selector to use to get a value ...
    // ... from an instance of class FormInstanceElement.
}
interface IFieldDefinitionMetadataByFieldDefName {
    [fieldDefinitionName: string]: FieldDefinitionMetadata;
}

// Define a display form enum.
export enum DisplayFormatEnum {
    HORIZONTAL = 'Horizontal',
    HORIZONTALTABS = 'Horizontal Tabs',
    VERTICAL = 'Vertical'
}

// Define a class for managing grid cascading dropdown virtual form field runtime resources.
export class GridCascadingDropDownRuntimeData {
    public config: CascadingDropDownFormFieldConfig;
    public data: CascadingDropDownFormFieldData;
    public primaryComponent: GridCascadingDropDownFormFieldComponent;

    public constructor(config: CascadingDropDownFormFieldConfig) {
        this.config = config;
    }
}

export class SecondaryGridCascadingFieldTransientData {
    public transientPrimaryGridCascadingDropDownComponent: GridCascadingDropDownFormFieldComponent; // NOTE:  THIS PROPERTY CAN REPLACE THE TWO PRIOR PROPERTIES.
    public transientCascadingDropdownConfig: CascadingDropDownFormFieldConfig; // 09-08-2022 note:  this value is set for form fields other than the first grid cascading dropdown form field.
    public transientCascadingDropdownData: CascadingDropDownFormFieldData;

    public constructor(transientPrimaryGridCascadingDropDownComponent: GridCascadingDropDownFormFieldComponent, transientCascadingDropdownConfig: CascadingDropDownFormFieldConfig, transientCascadingDropdownData: CascadingDropDownFormFieldData) {
        this.transientPrimaryGridCascadingDropDownComponent = transientPrimaryGridCascadingDropDownComponent;
        this.transientCascadingDropdownConfig = transientCascadingDropdownConfig;
        this.transientCascadingDropdownData = transientCascadingDropdownData;
    }
}

// Define, export class FormField.
export class FormField implements IViewModel, ICloneAndCopy {
    public id: number = 0; // Database Id

    public clientId: number = 0; // Transient, client-side only Id.

    // Note:  the following Id is managed by a grid, essentially within its own name space.
    public gridColClientId: number = 0; // Like the above 'clientId' but only used for grid column form fields.

    public isDeleted: boolean = false;
    public transientIsDeleted: boolean = false; // Added 08-04-2022:  used for show to/hide from logic in form 'Preview' mode.

    public name: string;
    public transientNameUsedWhileEditingName: string;
    public get Name(): string {
        return this.name;
    }
    public set Name(value: string) {
        this.name = value;
    }

    public alias: string; // Added for VNEXT-900 -- enables aliasing of columns in a FormTemplateInstance Grid

    public transientToBeAppliedInternalName: string;

    public fieldDefinitionClassName: string;
    public fieldGroup: string;
    public required: boolean;

    // Display
    public displayName: string;
    public displayFormat: string;
    public blankValue: string;
    public helpText: string;
    public helpTextFormat: string;
    public defaultValue: string;
    public placeholderText: string;
    public toolTip: string;
    public displayRows: string;
    public gridColumnWidth: number; 

    public selectOptions: string;
    public maxSelections: number; // added for VNEXT-1193 (multi select flex fields)
    public transientParsedSelectOptions: string[]; // Not saved on the server.
    public selectOptionsConstraintId: number = null; //0;
    public selectOptionsConstraintName: string;
    public selectOptionsConstraintValue: string;

    // Undelete
    public iconName: string;
    public iconType: string;

    //public modifiedDate: Date;
    public createdDate: Date;
    public createdBy: string;
    public modifiedDate: Date;
    public modifiedBy: string;

    //TEAMS-424: KLW - Add the view model property to determine if a form field has an annotation
    public hasFootnote: boolean = false;
    public isFootnote: boolean = false;

    //VNEXT-384: KLW - Implementing Autocomplete
    public autocomplete: boolean = false;

    //VNEXT-519: KLW - Refinements to the Type ahead functionality
    public autocomplete_StartsWith: boolean = false;
    public autocomplete_Contains: boolean = false;

    //VNEXT-980: KLW - Property to set the number of Kendo grid rows to display
    public displayKendoGridRows: number; //default

    // Universal values
    public jsonConfig: string;

    // Cascading dropdown properties.
    public cascadingDropdownConstraintId: number = null; //0;
    public cascadingDropdownConstraintName: string;
    public cascadingDropdownConstraintValue: string;
    public transientShowCascadingDropdownIndicesInPreviewMode: boolean;

    // String values
    public maxLength: number;

    public regex: string;
    public regularExpressionConstraintMessage: string;
    public regularExpressionConstraintId: number = null; //0;
    public regularExpressionConstraintName: string;
    public regularExpressionConstraintValue: string;

    // Numeric values
    public minValue: number;
    public maxValue: number;
    public numericRangeConstraintId: number = null;
    public numericRangeConstraintName: string;
    public numericRangeConstraintMinValue: number;
    public numericRangeConstraintMaxValue: number;

    public numDigitsAfterDecimalPoint: number = 2; // A default value.
    public roundToNumDigitsAfterDecimalPoint: number = 2; // A default value.
    public showTrailingZeroesAfterDecimalPoint: boolean;

    // Date values.
    public minDate: string; // Must be parseable by Date.parse().
    public maxDate: string; // Must be parseable by Date.parse().
    public dateRangeConstraintId: number = null; //0;
    public dateRangeConstraintName: string;
    public dateRangeConstraintMinDate: string;
    public dateRangeConstraintMaxDate: string;

    // New list value constraints:  simple list value constraint and cascading list value constraint.
    public formFieldListConstraintColumns: FormFieldListConstraintColumn[]; // support association of multiple constraint columns with the form field
    public ListConstraintColumnId: number; // the id of the record which represents the column within a cascade that this field renders options for
    public dependsOnParentFormFieldId: number; // the id of the record that represents the field within a cascade that determines options for this field
    public listValuesConstraintId: number = null; // Note:  this is the same Id for either type of list values constraint.
    public simpleListValuesConstraintValue: string;
    public cascadingListValuesConstraintValue: string;
    public fieldHasConfiguration: boolean = false; //show configuration panel in form designer

    public listValuesConstraintName: string = ''; // Note:  this is the same name for either type of list values constraint.
    public listConstraintColumnName: string = '';
    public dependsOnParentFormFieldName: string = '';
    public dependsOnParentFormFieldDefinitionClassName: string = null; // added for metadata field handling

    // Custom values
    public customValidationCriteria: string;

    // Grid-related values.
    public readOnly: boolean;
    // Note:  sometimes it's helpful to wrap an attribute, in the following case 'readOnly',
    //        with accessor methods during troubleshooting and possibly development.
    /*
    public _readOnly: boolean;
    public set readOnly(value: boolean) {
        this._readOnly = value;
    }
    public get readOnly(): boolean {
        return this._readOnly;
    }
    */

    // Define attributes related to constraints.
    public constraintName: string;
    public constraintJson: string;

    public fieldOrder: number; // Added 06-12-2020.
    public primaryFieldName: string; // Added 09-06-2022 to support grid cascading dropdown form fields.
    public fieldSecondaryOrder: number; // Added 08-31-2022 to help with grid cascading dropdown fields.

    // isMetadataField.
    public isMetadataField: boolean;
    public multipleFieldIds: string;

    // 04-09-2024 Note:  if the 'GridKeyColumns' (next) value is set to one, it means that the 
    //                   first column in the associated grid field ('GridKeyColumns' only applies
    //                   to grid form fields) comprises each grid row's unique key.  If the value
    //                   is set to two or more, it means that the first N columns in the grid's 
    //                   definition comprise the grid's composite key.
    //
    //                   Grid with any type of key, a single column key or a composite key, can
    //                   only be manipulated using grid Excel data imports; that is, users cannot
    //                   edit the rows for such grids.
    //
    //                   When Excel import data references a key that already exists in the grid,
    //                   the existing row must be deleted and a new row with the same row number
    //                   added to replace it.
    public gridKeyColumns: number = 0;

    public hideGridFiltering: boolean; // https://maxjira.max.gov/browse/VNEXT-1252
    public disableGridGrouping: boolean; // https://maxjira.max.gov/browse/VNEXT-1271

    public formsGridOrdersJson: string; // https://maxjira.max.gov/browse/VNEXT-1305

    // Implement methods.
    public setSelectOptions(selectOptions: string) {
        this.selectOptions = selectOptions;
    }

    public get GetDisplayKendoGridRows() {
        return this.displayKendoGridRows;
    }

    // Define transient grid cascading dropdown data.
    public transientVirtualFormFields: FormField[]; // 09-08-2022 note:  this value is only set for the first of N grid cascading dropdown form fields.
    public get TransientVirtualFormFields(): FormField[] {
        return this.transientVirtualFormFields;
    }
    public setTransientVirtualFormFields(value: FormField[]): void {
        this.transientVirtualFormFields = value;
    }
    public setTransientVirtualFormField(index: number, value: FormField): void {
        if ((this.transientVirtualFormFields != null) && (this.transientVirtualFormFields.length > index))
            this.transientVirtualFormFields[index] = value;
    }
    public setupSecondaryFieldTransientData(component: GridCascadingDropDownFormFieldComponent, config: CascadingDropDownFormFieldConfig, data: CascadingDropDownFormFieldData): void {
        for (let index: number = 0; index < this.transientVirtualFormFields.length; index++) {
            let virtualFormField: FormField = this.transientVirtualFormFields[index];

            if (virtualFormField.secondaryGridCascadingFieldTransientData == null)
                //virtualFormField.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData();
                virtualFormField.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData(null, null, null);
            virtualFormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent = component;
            virtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig = config;
            virtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData = data;
        }
    }
    public configureAnySecondaryComponents(component: GridCascadingDropDownFormFieldComponent): void {
        if (this.transientVirtualFormFields != null) {
            for (let index: number = 0; index < this.transientVirtualFormFields.length; index++) {
                let virtualFormField: FormField = this.transientVirtualFormFields[index];
                //virtualFormField.transientCascadingDropdownData = this.myData;
                //virtualFormField.transientCascadingDropdownData = this.dropDownData;
                if (virtualFormField.secondaryGridCascadingFieldTransientData == null)
                    //virtualFormField.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData();
                    virtualFormField.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData(null, null, null);
                //virtualFormField.transientPrimaryGridCascadingDropDownComponent = this;
                virtualFormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent = component;
            }
        }
    }
    public secondaryGridCascadingFieldTransientData: SecondaryGridCascadingFieldTransientData = null;
    public transientFieldIsVirtual: boolean = false;
    public removeAnyCircularDependencies(): void {
        //if (this.IsPrimaryCascadingField) {
        if (!this.transientFieldIsVirtual) {
            // We need to set property 'transientVirtualFormFields' to null prior to a save
            // as it would cause a JSON serialization exception.  Since this form field type
            // is only used in grids, and grid form fields components are deleted/destroyed
            // as a part of a grid save operation, there is no need to restore the value for
            // this property.
            if (this.transientVirtualFormFields != null)
                this.transientVirtualFormFields = null;
        } else if ((this.secondaryGridCascadingFieldTransientData != null) && (this.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent != null)) {
            // This property needs to be set to null for the same reason as in the prior block.
            this.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent = null;
        }

        if (this.childFormFields != null) {
            for (let index: number = 0; index < this.childFormFields.length; index++) {
                let childField: FormField = this.childFormFields[index];
                childField.removeAnyCircularDependencies();
            }
        }
    }

    public isHidden: boolean; // Added 04-24-2020.

    // A formula/calculation (added 05-05-2020)
    public formula: string;
    public transientFormulaError: string; // Note:  this field is not saved in the database.
    public roundResultToWholeNumber: boolean; // Added 04-26-2023
    public showDigitsWithCommandSeparators: boolean; // Added 04-26-2023
    public showDollarSignPrefix: boolean; // Added 04-26-2023

    public transientUrlBuilderUrlTemplate: string; // Added 10-17-2024 (gets saved into UrlBuilderConfigJson column of FormField in the DB)
    public transientUrlBuilderButtonLabel: string // Added 10-17-2024 (gets saved into UrlBuilderConfigJson column of FormField in the DB)

    public instructionsText: string; // Added 05-20-2020.
    public htmlText: string; // Added 05-20-2020.
    public iframeSource: string; // pharv added 4/18/22

    public showPreview: boolean; // Added 10-06-2020.

    public showFooter: boolean; // Added 01-16-2021.

    // Define a property used by the contacts form field component.
    public contactFieldNames: string;

    // 07-05-2022:  added a property for a grid's optional fixed first column of data.
    public fixedFirstColumnJson: string = null;

    public valuesAreFixed: boolean = false;
    public transientFixedFirstColumnValues: FixedFirstColumnValues = null;

    // Define properties related to conditional section/set of rows display logic.
    public isConditionalBeginElement: boolean; // Note:  need to add to the server's FormField definition.
    public isConditionalEndElement: boolean; // Note:  need to add to the server's FormField definition.
    public matchingBeginOrEndFieldName: string; // Note:  this property, saved in the database, tells us the identity of the matching conditional field.
    public transientConditionalNestingLevel: number = 0; // 0 => no conditional logic; 1 => one conditional field; 2 => one conditional within another; and so on.
    public transientMatchingBeginOrEndField: FormField = null; // Note:  this is not persisted in a database.
    // Note:  property 'transientBeginFieldDisplayName', next, allows a conditional end field to display a meaningful name during a form save operation.
    public transientBeginFieldDisplayName: string = null; // Note:  this is not persisted in a database.
    public cannotBeSplitOnForm: boolean = false; // Note:  need to add to the server's FormField definition.
    public showToHideFromRoleNames: string;
    // End properties related to conditional section/set of rows display logic.

    // 06-15-2023:  add a couple of grid-related properties.
    public gridColumnBehavior: string = "ShowInRowDataOnly";
    public gridColumnCanBeEditedByRoles: string = null;
    public gridColumnCanBeViewedByRoles: string = null;
    public gridFormLayout: string = null; // For likely future use.

    public formTemplateId: number = 0;

    // Begin fields related to a site home page template instances grid.  
    public formTemplateInstancesGridJsonConfig: string; // Saved as a JSON string, storing configurations for configured fields.
    public openFormInstancesInNewTab: boolean = false;
    public formTemplateInstanceActionButtonsConfig: string = null;
    // End fields related to a site home page template instances grid.

    // Begin fields related to a custom site banner.
    public centerBannerText: string;
    public leftBannerText: string;
    public rightBannerText: string;
    // End fields related to a custom site banner.

    // Begin fields related to a grid field custom Excel import/export template.
    public excelImportExportTemplateBase64Encoded: string;
    public excelImportExportTemplateStartingRowIndex: number;
    public excelImportExportTemplateStartingColumnIndex: number;
    // End fields related to a grid field custom Excel import/export template.

    public hideFormFieldBody: boolean; // VNEXT-1378:  optional hiding of a grid's body when only Excel import and export are allowed (no direct user data entry).

    public hideStructureComponent: boolean = false;
    public hideLeftSideMenu: boolean = false;

    // Transient/not-persisted values.
    public base64FileReadResult: string;
    public csvOptionsData: CsvOptionsFileData;

    // 07-02-2024 note:  added the following property, derived rather than saved in the database, to allow a forms grid
    //                   to know if it has pending conditional logic, specifically a pending filter operation.
    public hasPendingConditionalLogic: boolean;

    // 09-06-2024 note:  added the following property that is not stored in the database but rather
    //                   derived from other data.  Sending this back to the client allows the client to
    //                   suppress/hide form instance fields that are subject to field conditional logic.
    public fieldIsTargetOfConditionalLogic: boolean;

    // Define a method for informing a form field of a constraint update.
    // This technique could be used for other property updates, for for
    // now it is being used only for constraints.
    private noopConstraintUpdated(updatedConstraint: IFormFieldConstraint, constraintLiaison: IFormFieldConstraintLiaison): void {
        // NOTE:  THIS METHOD IS A NOOP BY DESIGN.
    }
    private constraintUpdatedCallback: (IFormFieldConstraint, IFormFieldConstraintLiaison) => void = this.noopConstraintUpdated;
    public constraintUpdated(updatedConstraint: IFormFieldConstraint, formFieldConstraintLiaison: IFormFieldConstraintLiaison): void {
        this.constraintUpdatedCallback(updatedConstraint, formFieldConstraintLiaison);
    }
    public setConstraintUpdatedCallback(callback: (IFormFieldConstraint, IFormFieldConstraintLiaison) => void): void {
        this.constraintUpdatedCallback = callback;
    }
    public resetConstraintUpdatedCallback(): void {
        this.constraintUpdatedCallback = this.noopConstraintUpdated;
    }
    public defaultAcceptsConstraintUpdateFrom(formField: FormField): boolean {
        // Note:  this default representation returns false.
        return false;
    }
    private acceptsConstraintUpdateFromCallback: (FormField) => boolean = this.defaultAcceptsConstraintUpdateFrom;
    public acceptsConstraintUpdateFrom(formField: FormField): boolean {
        return this.acceptsConstraintUpdateFromCallback(formField);
    }
    public setAcceptsConstraintUpdateFrom(callback: (FormField) => boolean): void {
        this.acceptsConstraintUpdateFromCallback = callback;
    }
    public resetAcceptsConstraintUpdateFromCallback(): void {
        this.acceptsConstraintUpdateFromCallback = this.defaultAcceptsConstraintUpdateFrom;
    }
    // End properties/methods related to constraint updates.

    // For sharing this form field.
    @Type(() => Share)
    public share: Share;

    // Child form fields (for the grid form field)
    @Type(() => FormField)
    public childFormFields: FormField[] = [];

    public parentFormField: FormField;
    public parentFormFieldId: number;

    // Define transient, client-side only data.
    private fieldDefMetadata: FieldDefinitionMetadata = null;
    public transientSelectedGridRow: number = -1; // Used by a grid to 'remember' the selected row.

    // 06-18-2022 note:  adding a new property, 'formFieldNumberOnClient'
    // to assign each form field a unique id to facilitate troubleshooting.
    //
    // Static property 'numFormFieldsCreatedOnClient' is used to keep
    // track of the number of form field objects created on the client.
    public readonly formFieldNumberOnClient: number;
    private static numFormFieldsCreatedOnClient: number = 0;

    // Constructor.
    public constructor() {
        this.formFieldNumberOnClient = ++FormField.numFormFieldsCreatedOnClient;
        return;
    }

    //TEAMS-424: KLW - Method to call from Form Wrapper to see if a form field has an annotation
    public get HasFootnote() {
        return this.hasFootnote;
    }

    // Method for understanding a form field's metadata.
    /*
    public get IsNumeric(): boolean {
        this.getMetadata();

        return (this.fieldDefMetadata.isNumeric);
    }

    public get IsTextual(): boolean {
        this.getMetadata();

        return (this.fieldDefMetadata.isTextual);
    }

    public get IsShowToOrHideFromField(): boolean {
        return ((this.fieldDefinitionClassName == ShowToFieldType) || (this.fieldDefinitionClassName == HideFromFieldType));
    }

    public get ControlTypeHint(): string {
        this.getMetadata();

        return (this.fieldDefMetadata.controlTypeHint);
    }

    public get FormInstanceElementPropName(): string {
        this.getMetadata();

        return (this.fieldDefMetadata.formInstElemPropName);
    }
    */

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        //console.log('FormField.clone()');

        let clone: FormField = new FormField();

        clone.id = this.id;

        clone.clientId = this.clientId;

        clone.gridColClientId = this.gridColClientId;

        clone.isDeleted = this.isDeleted;

        clone.name = this.name;

        clone.fieldDefinitionClassName = this.fieldDefinitionClassName;
        clone.fieldGroup = this.fieldGroup;

        clone.required = this.required;

        clone.displayName = this.displayName;
        clone.displayFormat = this.displayFormat;
        clone.blankValue = this.blankValue;

        clone.helpText = this.helpText;
        clone.helpTextFormat = this.helpTextFormat;

        clone.defaultValue = this.defaultValue;

        clone.placeholderText = this.placeholderText;
        clone.toolTip = this.toolTip;

        clone.displayRows = this.displayRows;

        clone.selectOptions = this.selectOptions;
        clone.selectOptionsConstraintId = this.selectOptionsConstraintId;
        clone.selectOptionsConstraintName = this.selectOptionsConstraintName;
        clone.selectOptionsConstraintValue = this.selectOptionsConstraintValue;

        clone.hasFootnote = this.hasFootnote;

        clone.jsonConfig = this.jsonConfig;
        clone.cascadingDropdownConstraintId = this.cascadingDropdownConstraintId;
        clone.cascadingDropdownConstraintName = this.cascadingDropdownConstraintName;
        clone.cascadingDropdownConstraintValue = this.cascadingDropdownConstraintValue;

        clone.maxLength = this.maxLength;

        clone.regex = this.regex;
        clone.regularExpressionConstraintId = this.regularExpressionConstraintId;
        clone.regularExpressionConstraintName = this.regularExpressionConstraintName;
        clone.regularExpressionConstraintValue = this.regularExpressionConstraintValue;
        clone.regularExpressionConstraintMessage = this.regularExpressionConstraintMessage;

        clone.minValue = this.minValue;
        clone.maxValue = this.maxValue;
        clone.numericRangeConstraintId = this.numericRangeConstraintId;
        clone.numericRangeConstraintName = this.numericRangeConstraintName;
        clone.numericRangeConstraintMinValue = this.numericRangeConstraintMinValue;
        clone.numericRangeConstraintMaxValue = this.numericRangeConstraintMaxValue;

        clone.numDigitsAfterDecimalPoint = this.numDigitsAfterDecimalPoint;
        clone.roundToNumDigitsAfterDecimalPoint = this.roundToNumDigitsAfterDecimalPoint;

        clone.minDate = this.minDate;
        clone.maxDate = this.maxDate;
        clone.dateRangeConstraintId = this.dateRangeConstraintId;
        clone.dateRangeConstraintName = this.dateRangeConstraintName;
        clone.dateRangeConstraintMinDate = this.dateRangeConstraintMinDate;
        clone.dateRangeConstraintMaxDate = this.dateRangeConstraintMaxDate;

        clone.customValidationCriteria = this.customValidationCriteria;

        clone.readOnly = this.readOnly;

        clone.constraintName = this.constraintName;
        clone.constraintJson = this.constraintJson;

        clone.fieldOrder = this.fieldOrder;
        clone.primaryFieldName = this.primaryFieldName;

        clone.isHidden = this.isHidden;

        clone.formula = this.formula;
        clone.transientFormulaError = this.transientFormulaError;
        clone.roundResultToWholeNumber = this.roundResultToWholeNumber;
        clone.showDigitsWithCommandSeparators = this.showDigitsWithCommandSeparators;
        clone.showDollarSignPrefix = this.showDollarSignPrefix;

        clone.instructionsText = this.instructionsText;
        clone.htmlText = this.htmlText;

        clone.showPreview = this.showPreview;
        clone.showFooter = this.showFooter;

        clone.contactFieldNames = this.contactFieldNames;

        clone.fixedFirstColumnJson = this.fixedFirstColumnJson;
        clone.valuesAreFixed = this.valuesAreFixed;
        clone.transientFixedFirstColumnValues = this.transientFixedFirstColumnValues;

        // Begin properties related to conditional section/set of rows display logic.
        clone.isConditionalBeginElement = this.isConditionalBeginElement;
        clone.isConditionalEndElement = this.isConditionalEndElement;
        clone.cannotBeSplitOnForm = this.cannotBeSplitOnForm;
        clone.showToHideFromRoleNames = this.showToHideFromRoleNames;
        // End properties related to conditional section/set of rows display logic.

        clone.gridColumnBehavior = this.gridColumnBehavior;
        clone.gridColumnCanBeEditedByRoles = this.gridColumnCanBeEditedByRoles;
        clone.gridColumnCanBeViewedByRoles = this.gridColumnCanBeViewedByRoles;
        clone.gridFormLayout = this.gridFormLayout;

        clone.formTemplateId = this.formTemplateId;

        //clone.selectedFormTemplatePropertyNames = this.selectedFormTemplatePropertyNames;
        clone.formTemplateInstancesGridJsonConfig = this.formTemplateInstancesGridJsonConfig;
        clone.openFormInstancesInNewTab = this.openFormInstancesInNewTab;
        clone.formTemplateInstanceActionButtonsConfig = this.formTemplateInstanceActionButtonsConfig;

        clone.centerBannerText = this.centerBannerText;
        clone.leftBannerText = this.leftBannerText;
        clone.rightBannerText = this.rightBannerText;

        clone.autocomplete = this.autocomplete;
        clone.autocomplete_Contains = this.autocomplete_Contains;
        clone.autocomplete_StartsWith = this.autocomplete_StartsWith;

        clone.displayKendoGridRows = this.displayKendoGridRows; //VNEXT-980: KLW - Property to set the number of Kendo grid rows to display

        clone.excelImportExportTemplateBase64Encoded = this.excelImportExportTemplateBase64Encoded;
        clone.excelImportExportTemplateStartingRowIndex = this.excelImportExportTemplateStartingRowIndex;
        clone.excelImportExportTemplateStartingColumnIndex = this.excelImportExportTemplateStartingColumnIndex;

        clone.hideStructureComponent = this.hideStructureComponent;
        clone.hideLeftSideMenu = this.hideLeftSideMenu;

        clone.base64FileReadResult = this.base64FileReadResult;
        clone.csvOptionsData = this.csvOptionsData;

        // 10-04-2022 note:  added the following lines to copy properties that were not being copied in this method.
        clone.iframeSource = this.iframeSource;
        clone.iconName = this.iconName;
        clone.iconType = this.iconType;
        clone.fieldSecondaryOrder = this.fieldSecondaryOrder;
        clone.matchingBeginOrEndFieldName = this.matchingBeginOrEndFieldName;
        clone.createdBy = this.createdBy;
        clone.createdDate = this.createdDate;
        clone.modifiedBy = this.modifiedBy;
        clone.modifiedDate = this.modifiedDate;

        clone.transientVirtualFormFields = this.transientVirtualFormFields;
        // End 10-04-2022 changes.

        clone.listValuesConstraintId = this.listValuesConstraintId;
        clone.ListConstraintColumnId = this.ListConstraintColumnId;

        clone.isMetadataField = this.isMetadataField;
        clone.multipleFieldIds = this.multipleFieldIds;

        clone.gridKeyColumns = this.gridKeyColumns;

        clone.hideGridFiltering = this.hideGridFiltering;
        clone.disableGridGrouping = this.disableGridGrouping;

        if (this.share != null) {
            let shareCopy: Share = <Share>this.share.clone();

            clone.share = shareCopy;
        } else
            clone.share = null;

        if (this.childFormFields && (this.childFormFields.length > 0)) {
            clone.childFormFields = [];

            for (let iChild: number = 0; iChild < this.childFormFields.length; iChild++) {
                let childFF: FormField = this.childFormFields[iChild];

                let childFFCopy: FormField = <FormField>childFF.clone();
                clone.childFormFields.push(childFFCopy);
            }
        }

        return (clone);
    }

    public copy(objectToCopy: ICloneAndCopy): void {
        //console.log('FormField.copy()..........................');
        let ffToCopy: FormField = <FormField>objectToCopy;

        this.id = ffToCopy.id;

        this.clientId = ffToCopy.clientId;

        this.gridColClientId = ffToCopy.gridColClientId;

        this.isDeleted = ffToCopy.isDeleted;

        this.name = ffToCopy.name;

        this.fieldDefinitionClassName = ffToCopy.fieldDefinitionClassName;
        this.fieldGroup = ffToCopy.fieldGroup;

        this.required = ffToCopy.required;

        this.displayName = ffToCopy.displayName;
        this.displayFormat = ffToCopy.displayFormat;
        this.blankValue = ffToCopy.blankValue;

        this.helpText = ffToCopy.helpText;
        this.helpTextFormat = ffToCopy.helpTextFormat;

        this.defaultValue = ffToCopy.defaultValue;

        this.placeholderText = ffToCopy.placeholderText;
        this.toolTip = ffToCopy.toolTip;

        this.displayRows = ffToCopy.displayRows;

        this.selectOptions = ffToCopy.selectOptions;
        this.selectOptionsConstraintId = ffToCopy.selectOptionsConstraintId;
        this.selectOptionsConstraintName = ffToCopy.selectOptionsConstraintName;
        this.selectOptionsConstraintValue = ffToCopy.selectOptionsConstraintValue;

        this.hasFootnote = ffToCopy.hasFootnote;

        this.autocomplete = ffToCopy.autocomplete; //VNEXT-384: KLW - Implementing Autocomplete

        //VNEXT-519: KLW - Refinements to the Type ahead functionality
        this.autocomplete_StartsWith = ffToCopy.autocomplete_StartsWith;
        this.autocomplete_Contains = ffToCopy.autocomplete_Contains;



        this.jsonConfig = ffToCopy.jsonConfig;
        this.cascadingDropdownConstraintId = ffToCopy.cascadingDropdownConstraintId;
        this.cascadingDropdownConstraintName = ffToCopy.cascadingDropdownConstraintName;
        this.cascadingDropdownConstraintValue = ffToCopy.cascadingDropdownConstraintValue;

        this.maxLength = ffToCopy.maxLength;

        this.regex = ffToCopy.regex;
        this.regularExpressionConstraintId = ffToCopy.regularExpressionConstraintId;
        this.regularExpressionConstraintName = ffToCopy.regularExpressionConstraintName;
        this.regularExpressionConstraintValue = ffToCopy.regularExpressionConstraintValue;

        this.minValue = ffToCopy.minValue;
        this.maxValue = ffToCopy.maxValue;
        this.numericRangeConstraintId = ffToCopy.numericRangeConstraintId;
        this.numericRangeConstraintName = ffToCopy.numericRangeConstraintName;
        this.numericRangeConstraintMinValue = ffToCopy.numericRangeConstraintMinValue;

        this.numDigitsAfterDecimalPoint = ffToCopy.numDigitsAfterDecimalPoint;
        this.roundToNumDigitsAfterDecimalPoint = ffToCopy.roundToNumDigitsAfterDecimalPoint;

        this.minDate = ffToCopy.minDate;
        this.maxDate = ffToCopy.maxDate;
        this.dateRangeConstraintId = ffToCopy.dateRangeConstraintId;
        this.dateRangeConstraintName = ffToCopy.dateRangeConstraintName;
        this.dateRangeConstraintMinDate = ffToCopy.dateRangeConstraintMinDate;
        this.dateRangeConstraintMaxDate = ffToCopy.dateRangeConstraintMaxDate;

        this.customValidationCriteria = ffToCopy.customValidationCriteria;

        this.readOnly = ffToCopy.readOnly;

        this.constraintName = ffToCopy.constraintName;
        this.constraintJson = ffToCopy.constraintJson;

        this.fieldOrder = ffToCopy.fieldOrder;
        this.primaryFieldName = ffToCopy.primaryFieldName;

        this.isHidden = ffToCopy.isHidden;

        this.formula = ffToCopy.formula;
        this.transientFormulaError = ffToCopy.transientFormulaError;
        this.roundResultToWholeNumber = ffToCopy.roundResultToWholeNumber;
        this.showDigitsWithCommandSeparators = ffToCopy.showDigitsWithCommandSeparators;
        this.showDollarSignPrefix = ffToCopy.showDollarSignPrefix;

        this.instructionsText = ffToCopy.instructionsText;
        this.htmlText = ffToCopy.htmlText;

        this.showPreview = ffToCopy.showPreview;
        this.showFooter = ffToCopy.showFooter;

        this.contactFieldNames = ffToCopy.contactFieldNames;

        this.fixedFirstColumnJson = ffToCopy.fixedFirstColumnJson;
        this.valuesAreFixed = ffToCopy.valuesAreFixed;
        this.transientFixedFirstColumnValues = ffToCopy.transientFixedFirstColumnValues;

        // Begin properties related to conditional section/set of rows display logic.
        this.isConditionalBeginElement = ffToCopy.isConditionalBeginElement;
        this.isConditionalEndElement = ffToCopy.isConditionalEndElement;
        this.cannotBeSplitOnForm = ffToCopy.cannotBeSplitOnForm;
        this.showToHideFromRoleNames = ffToCopy.showToHideFromRoleNames;
        // End properties related to conditional section/set of rows display logic.

        this.gridColumnBehavior = ffToCopy.gridColumnBehavior;
        this.gridColumnCanBeEditedByRoles = ffToCopy.gridColumnCanBeEditedByRoles;
        this.gridColumnCanBeViewedByRoles = ffToCopy.gridColumnCanBeViewedByRoles;
        this.gridFormLayout = ffToCopy.gridFormLayout;

        this.formTemplateId = ffToCopy.formTemplateId;

        //this.selectedFormTemplatePropertyNames = ffToCopy.selectedFormTemplatePropertyNames;
        this.formTemplateInstancesGridJsonConfig = ffToCopy.formTemplateInstancesGridJsonConfig;
        this.openFormInstancesInNewTab = ffToCopy.openFormInstancesInNewTab;
        this.formTemplateInstanceActionButtonsConfig = ffToCopy.formTemplateInstanceActionButtonsConfig;

        this.centerBannerText = ffToCopy.centerBannerText;
        this.leftBannerText = ffToCopy.leftBannerText;
        this.rightBannerText = ffToCopy.rightBannerText;

        this.autocomplete = ffToCopy.autocomplete;
        this.autocomplete_Contains = ffToCopy.autocomplete_Contains;
        this.autocomplete_StartsWith = ffToCopy.autocomplete_StartsWith;

        this.displayKendoGridRows = ffToCopy.displayKendoGridRows; //VNEXT-980: KLW - Property to set the number of Kendo grid rows to display

        this.excelImportExportTemplateBase64Encoded = ffToCopy.excelImportExportTemplateBase64Encoded;
        this.excelImportExportTemplateStartingRowIndex = ffToCopy.excelImportExportTemplateStartingRowIndex;
        this.excelImportExportTemplateStartingColumnIndex = ffToCopy.excelImportExportTemplateStartingColumnIndex;

        this.formTemplateInstancesGridJsonConfig = ffToCopy.formTemplateInstancesGridJsonConfig;
        this.hideStructureComponent = ffToCopy.hideStructureComponent;
        this.hideLeftSideMenu = ffToCopy.hideLeftSideMenu;

        this.base64FileReadResult = ffToCopy.base64FileReadResult;
        this.csvOptionsData = ffToCopy.csvOptionsData;

        this.listValuesConstraintId = ffToCopy.listValuesConstraintId;
        this.ListConstraintColumnId = ffToCopy.ListConstraintColumnId;

        this.isMetadataField = ffToCopy.isMetadataField;
        this.multipleFieldIds = ffToCopy.multipleFieldIds;

        this.gridKeyColumns = ffToCopy.gridKeyColumns;

        this.hideGridFiltering = ffToCopy.hideGridFiltering;
        this.disableGridGrouping = ffToCopy.disableGridGrouping;

        if (ffToCopy.share != null) {
            let shareCopy: Share = <Share>ffToCopy.share.clone();

            this.share = shareCopy;
        }

        if (ffToCopy.childFormFields && (ffToCopy.childFormFields.length > 0)) {
            this.childFormFields = [];

            for (let iChild: number = 0; iChild < ffToCopy.childFormFields.length; iChild++) {
                let childFF: FormField = ffToCopy.childFormFields[iChild];

                let childFFCopy: FormField = <FormField>childFF.clone();
                this.childFormFields.push(childFFCopy);
            }
        }

        return;
    }

    public deepCopy(formFieldToCopy: FormField): FormField {
        let ffDeepCopy: FormField = null;

        if (formFieldToCopy != null) {
            let objectCopy: any = JavaScriptUtil.deepCopy(formFieldToCopy);
            ffDeepCopy = plainToClass(FormField, objectCopy);
            ffDeepCopy.copy(formFieldToCopy);
        }

        return ffDeepCopy;
    }

    // Implement methods.
    public assignFrom(cloneObj: any): any {
        // Original code:  assign attributes from the original form field.
        for (let attribute in cloneObj) {
            this[attribute] = cloneObj[attribute];
        }

        // 11-03-2021 new code:  any attribute in the updated form field
        // that is not in the original form field needs to be reset.
        /*
        for (let attribute in this) {
            if (cloneObj[attribute] == undefined) {
                this[attribute] = undefined;
            } else if (cloneObj[attribute] == null) {
                this[attribute] = null;
            }
        }
        */

        return this;
    }

    public static ParseSelectOptions(formField: FormField): string[] {
        let arrSelectOptions: string[] = [];

        if ((formField.selectOptions !== undefined) &&
            (formField.selectOptions !== null) &&
            (formField.selectOptions.trim() !== '')) {
            arrSelectOptions = formField.selectOptions.split('|');
        }

        return (arrSelectOptions);
    }

    public static DateToUtcDate(strDate: string): any {
        let iTimeSince_Jan01_1970: number = Date.parse(strDate);
        let dateValue: Date = new Date(iTimeSince_Jan01_1970);
        //this.convertDateToLocalTime(dateValue);
        let strUTCDate: string = dateValue.toISOString();
        dateValue = new Date(strUTCDate);

        let hshResult = {
            utcDate: strUTCDate,
            dateValue: dateValue
        }

        return (hshResult);
    }

    public static sortFormFieldsAlphabetically(formFields: FormField[]): void {
        formFields.sort(this.compareFormFieldsAlphabetically);
    }

    // Helper methods.
    private static compareFormFieldsAlphabetically = (formField1: FormField, formField2: FormField): number => {
        let formField1Name: string = (formField1.displayName != null) && (formField1.displayName.trim() != '') ? formField1.displayName : formField1.name;
        let formField2Name: string = (formField2.displayName != null) && (formField2.displayName.trim() != '') ? formField2.displayName : formField2.name;

        let result = formField1Name.localeCompare(formField2Name);

        return result;
    }
}
