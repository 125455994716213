import { Type } from 'class-transformer';

import { IViewModel } from '../interfaces/view-model.interface';

export class VnextReleaseFeatures implements IViewModel {
    public id: number = 0;

    public newFeatures: string;
    public comingSoonFeatures: string;
}
