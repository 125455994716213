//import { ICallsMethod } from '../../interfaces/component-scripting/icalls-method';
import { ScriptableFunctionBase } from './scriptable-function-base';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { ComponentHierarchyService } from '../../services/component-hierarchy.service';
import { FunctionExecutionStatus, IScriptableFunction } from '../../interfaces/iscriptable-function';

export class CallMethodScriptableFunction extends ScriptableFunctionBase implements IScriptableFunction {// implements IClicksButton {
    public constructor() {
        super();
    }

    public get callableName(): string {
        return 'callMethod';
    }

    public execute(testActionService: ITestActionRecorderService, params: string[]): FunctionExecutionStatus {
        let executionStatus: FunctionExecutionStatus = FunctionExecutionStatus.Failed;

        if ((params != null) && (params.length >= 1)) {
            let componentHierarchyService: ComponentHierarchyService = testActionService.componentHierarchyService;
            //let tagName: string = params[0];
            //let component: ITestableComponent = componentHierarchyService.findComponentTreeNodeByTagFromRoot(tagName);
            let methodName: string = params[0];

            let methodParams: string[] = [];
            for (let paramsIndex: number = 1; paramsIndex < params.length; paramsIndex++)
                methodParams.push(params[paramsIndex]);

            testActionService.callMethod(methodName, methodParams);
            executionStatus = FunctionExecutionStatus.Completed;
        }

        return executionStatus;
    }
}
