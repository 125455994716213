import { SortDescriptor, State, CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";

export class SiteGridStateInfo {
    public editedRowIndex: number;

    public gridState: State = {
        sort: [],
        skip: 0,
        take: 500,
        filter: {
            filters: [],
            logic: 'and'
        }
    };
}

export class SiteListColumnDef {
    public fieldName: string;
    public title: string;
    public width: string;
}
