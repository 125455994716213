import { ItemTypeEnum } from './enums/item-type.enum';
import { IItem } from './interfaces/iitem.interface';

export class UtilityHelper {

    public static findAndAssignFrom(array: any[], cloneObj: any) {
        for (let f of array) {
            if (f.id === cloneObj.id) {
                var target = f;
                UtilityHelper.assignFrom(target, cloneObj);
            }
        }
    }

    public static assignFrom(targetObj: any, cloneObj: any): void {
        for (var attr in cloneObj) {
            targetObj[attr] = cloneObj[attr];
        }
    }

    public static tryParseJson(str): string {
        try {
            let parsed = JSON.parse(str);
            return parsed;
        } catch (e) {
            return null;
        }
    }

    // Safely peer down nested properties of an object
    // Example Usage...
    // var currentSite = UtilityHelper.getNestedProperty(activatedRoute.snapshot.parent, 'data', 'currentSite');
    // ... when you're not sure data and currentSite will exist
    public static getNestedProperty(obj, ...propertyChain): any {
        return propertyChain.reduce((obj, level) => obj && obj[level], obj)
    }

    public static runWhenStackClear(callback) {
        setTimeout(callback, 0);
    }

    public static blockDrop(ev: DragEvent) {
        ev.preventDefault();
        ev.stopPropagation();
    }

    public static allowDrop(ev: DragEvent) {
        ev.preventDefault();
        ev.stopPropagation();
    }

    public static isTypeFormInstance(item: IItem): boolean {
        if (item)
            return (item.getType().localeCompare(ItemTypeEnum.FORM_INSTANCE, undefined, { sensitivity: 'base' })) == 0;
    }

    public static isTypeFolder(item: IItem): boolean {
        if (item)
            return (item.getType().localeCompare(ItemTypeEnum.FOLDER, undefined, { sensitivity: 'base' })) == 0;
    }

    public static isTypeAttachment(item: IItem): boolean {
        if (item)
            return (item.getType().localeCompare(ItemTypeEnum.ATTACH_W_URLS, undefined, { sensitivity: 'base' })) == 0;
    }

    public static isTypeBreadcrumb(item: IItem): boolean {
        if (item)
            return (item.getType().localeCompare(ItemTypeEnum.BREADCRUMB, undefined, { sensitivity: 'base' })) == 0;
    }
}
