export enum ProgressBarConstants {
    DETERMINATE_MODE = 'determinate',
    INDETERMINATE_MODE = 'indeterminate',
    BUFFER_MODE = 'buffer',
    QUERY_MODE = 'query',

    THEME_PALETTE_PRIMARY = 'primary',
    THEME_PALETTE_ACCENT = 'accent',
    THEME_PALETTE_WARN = 'warn'
}
