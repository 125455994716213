<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<!--
Note: this field is not currently using reative forms
-->
<div class="rich_wrapper">
    <div class="flex-row-top-right">
        <div class="fill-remaining-space">
            <!-- All modes -->
            <div class="flex-row-top-right">
                <!-- *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')" -->
                <div class="fill-remaining-space">
                    <div class="field-label" *ngIf="this.DisplayName">
                        <span *ngIf="!this.FormField.required">
                            <span>{{this.DisplayName}}</span>
                        </span>
                        <span *ngIf="this.FormField.required">
                            <span>{{this.DisplayName}}</span>&nbsp;<span style="color:red">*</span>
                        </span>

                        <span *ngIf="ShowHistoryLink">
                            <a style="cursor:pointer;" (click)="onHistoryClick($event)" class="noprint">History</a>
                        </span>
                    </div>
                    <ng-container *ngIf="this.HasInstructions"><ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container></ng-container>

                    <div *ngIf="ShowHistory" class="history">
                        <app-form-instance-history [historyItem]="this.FormInstanceElementForHistory"
                                                   [formInstance]="this.formInstance"
                                                   [showHistoryForRichTextField]="true"
                                                   (view)="onViewVersion($event)"
                                                   (restore)="onVersionRestore($event)"
                                                   (close)="onCloseHistory($event)"></app-form-instance-history>
                    </div>

                    <div *ngIf="VersionToShow" class="version">
                        <strong>{{versionToShow.getExtraInfo()}}</strong>
                        <div [innerHtml]="versionToShow.value | safe: 'html'"></div>
                    </div>

                    <ng-container *ngIf="(this.ControlType === 'Reactive Forms') && (this.FormControl !== null)">
                        <kendo-editor name="kendo-editor"
                                      [formControl]="this.FormControl"
                                      style="height: 100%;"
                                      [resizable]="true"
                                      [iframeCss]="{ content: this.getEditorCssDefaults() }">
                            <kendo-toolbar [ngClass]="this.ReadOnly ? 'hide-toolbar' : ''">
                                <kendo-toolbar-buttongroup *ngIf="includeSimpleTextButtons()">
                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup *ngIf="includeAllButtons()">
                                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-dropdownlist *ngIf="includeAllButtons()" kendoEditorFormat></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist *ngIf="includeAllButtons()" kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist *ngIf="includeAllButtons()" kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-colorpicker *ngIf="includeAllButtons()" kendoEditorForeColor [paletteSettings]="{palette:'basic'}"></kendo-toolbar-colorpicker>
                                <kendo-toolbar-colorpicker *ngIf="includeAllButtons()" kendoEditorBackColor [paletteSettings]="{palette:'basic'}"></kendo-toolbar-colorpicker>
                                <kendo-toolbar-buttongroup *ngIf="includeAllButtons()">
                                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlightRightButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup *ngIf="includeAllButtons()">
                                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup *ngIf="includeBulletButtons()">
                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-editor-insert-table-button *ngIf="includeAllButtons()"></kendo-editor-insert-table-button>
                                <kendo-toolbar-buttongroup *ngIf="includeAllButtons()">
                                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup *ngIf="includeAllButtons()">
                                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup *ngIf="includeAllButtons()">
                                    <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                            </kendo-toolbar>
                        </kendo-editor>
                    </ng-container>
                    <!-- Note:  Keep the ngModel approach around just in case -->
                    <ng-container *ngIf="false">
                        <kendo-editor name="kendo-editor"
                                      [(ngModel)]="this.HtmlValue"
                                      style="height: 100%;"
                                      [resizable]="true"
                                      [iframeCss]="{ content: this.getEditorCssDefaults() }">
                            <kendo-toolbar [ngClass]="this.ReadOnly ? 'hide-toolbar' : ''">
                                <kendo-toolbar-buttongroup *ngIf="includeSimpleTextButtons()">
                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup *ngIf="includeAllButtons()">
                                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-dropdownlist *ngIf="includeAllButtons()" kendoEditorFormat></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist *ngIf="includeAllButtons()" kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist *ngIf="includeAllButtons()" kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-colorpicker *ngIf="includeAllButtons()" kendoEditorForeColor [paletteSettings]="{palette:'basic'}"></kendo-toolbar-colorpicker>
                                <kendo-toolbar-colorpicker *ngIf="includeAllButtons()" kendoEditorBackColor [paletteSettings]="{palette:'basic'}"></kendo-toolbar-colorpicker>
                                <kendo-toolbar-buttongroup *ngIf="includeAllButtons()">
                                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlightRightButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup *ngIf="includeBulletButtons()">
                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-editor-insert-table-button *ngIf="includeAllButtons()"></kendo-editor-insert-table-button>
                                <kendo-toolbar-buttongroup *ngIf="includeAllButtons()">
                                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup *ngIf="includeAllButtons()">
                                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup *ngIf="includeAllButtons()">
                                    <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                            </kendo-toolbar>
                        </kendo-editor>
                    </ng-container>
                    <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
                </div>
            </div>
        </div>
        <div [ngClass]="{'tooltip-with-instructions':(FormField.instructionsText != null && FormField.instructionsText != '')}">
            <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
        </div>
    </div>
</div>
