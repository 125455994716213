import { Type, plainToClass } from 'class-transformer';

import { IViewModel } from '../interfaces/view-model.interface';
import { RoleGroupMember } from './role-group-member.model';
import { SiteLevelRoleMember } from './site-level-role-member.model';

export class Role implements IViewModel {
    public id: number;
    public dataCollectionId: number;
    public name: string;
    public isSiteLevel: boolean = false;
    public isSiteAdmin: boolean;

    @Type(() => RoleGroupMember)
    public members: RoleGroupMember[];
    @Type(() => SiteLevelRoleMember)
    public siteLevelRoleMembers: SiteLevelRoleMember[]; // Added 10-15-2023.

    // Returns the _selected_ members of the Role
    // Implement the above method using the original name.
    public memberCount(): number {
        return this.members.filter(m => m.selected == true).length;
    }
    // Note:  I am giving the following method an awkward name to distinguish from the previously named method memberCount(), now selectedMemberCount().
    public countOfAllRoleGroupMembers(): number {
        return this.members != null ? this.members.length : 0;
    }
    public countOfSiteLevelMembers(): number {
        return this.siteLevelRoleMembers != null ? this.siteLevelRoleMembers.length : 0;
    }

    /*
    public getMembersAsSiteLevelMembers(): SiteLevelRoleMember[] {
        let siteLevelMembers: SiteLevelRoleMember[] = [];

        if (this.members != null) {
            for (let index: number = 0; index < this.members.length; index++) {
                let roleGroupMember: RoleGroupMember = this.members[index];

                let siteLevelRoleMember = new SiteLevelRoleMember(roleGroupMember, this);
                siteLevelMembers.push(siteLevelRoleMember);
            }
        }

        return siteLevelMembers;
    }
    */
}
