import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { MetadataFieldDefinitionLogicBase } from './metadata-field-def-logic-base';
import { TextFieldDefinitionLogicBase } from './text-logic-base';

export class UserRolesFieldDefinitionLogic extends TextFieldDefinitionLogicBase implements IFieldDefinitionLogic {
    // Constructor.
    public constructor() {
        super();
    }
}
