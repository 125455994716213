//import { Type } from 'class-transformer';

import { IViewModel } from '../../interfaces/view-model.interface';
import { ICloneAndCopy } from '../../interfaces/clone-and-copy';

//export class FormConditionalLogicAction {
export class FormFieldAction implements IViewModel, ICloneAndCopy {
    // Instance data.
    public id: number = 0;

    //public targetFieldClientId: number = 0; // This can be ignored by the server.
    public targetFieldId: number = 0;
    public targetFieldName: string = null; // Must be the internal form field name, not the display name.
    public childFieldName: string; // 06-25-2024:  added this property.
    public filterOperator: string; // 06-25-2024:  added this property.

    public targetFormFieldDefaultAttributes: string = null; // Added 03-04-2024.

    public actionName: string;

    public actionOrder: number = 0;

    // Constructor.
    public constructor(idParam: number = 0) {
        this.id = idParam;

        return;
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let clone: FormFieldAction = new FormFieldAction();

        FormFieldAction.copyPropertiesFromTo(this, clone);

        return (clone);
    }

    public copy(objectToCopy: ICloneAndCopy): void {
        let actionToCopy: FormFieldAction = <FormFieldAction>objectToCopy;

        FormFieldAction.copyPropertiesFromTo(actionToCopy, this);

        return;
    }

    // Helper methods.
    private static copyPropertiesFromTo(fromAction: FormFieldAction, toAction: FormFieldAction): void {
        toAction.id = fromAction.id;

        //toAction.targetFieldClientId = fromAction.targetFieldClientId;
        toAction.targetFieldId = fromAction.targetFieldId;
        toAction.targetFieldName = fromAction.targetFieldName;

        toAction.actionName = fromAction.actionName;
        toAction.actionOrder = fromAction.actionOrder;
    }
}
