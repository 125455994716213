<h1 mat-dialog-title
    class="draggable-dialogue-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
    Edit Value
</h1>

<form>
    <div mat-dialog-content>
        <mat-form-field appearance="outline">
            <mat-label>Value</mat-label>
            <input matInput
                   [formControl]="this.ValueFormControl"
                   placeholder="Edit value"
                   name="value"
                   autocomplete="off"
                   required />
        </mat-form-field>

        <mat-checkbox [formControl]="this.HasDateTimeSuffixFormControl"
                      class="add-date-time-suffix-checkbox">
            Add Date/Time Suffix
        </mat-checkbox>

    </div>

    <div mat-dialog-actions>
        <button mat-flat-button type="submit" color="primary" class="theme-primary-btn"
                [disabled]="this.OkButtonDisabled"
                (click)="this.okButtonClicked()">
            OK
        </button>
        <button mat-flat-button [mat-dialog-close]="null" style="margin-left: 10px;">Cancel</button>
    </div>
</form>
