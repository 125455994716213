//import { ISelectsComponent } from '../../interfaces/component-scripting/iselects-component';
import { ScriptableFunctionBase } from './scriptable-function-base';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
import { ComponentHierarchyService, IComponentHierarchyChanged } from '../../services/component-hierarchy.service';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { FunctionExecutionStatus, IScriptableFunction } from '../../interfaces/iscriptable-function';
import { ScriptableComponentStatus } from '../../enums/component-scripting/scriptable-component-status.enum';

export class SelectComponentScriptableFunction extends ScriptableFunctionBase implements IScriptableFunction, IComponentHierarchyChanged {
    private componentHierarchyService: ComponentHierarchyService = null;
    private testActionService: ITestActionRecorderService = null;
    private tagName: string = null;
    private selectedComponentInitialized: boolean = false;
    //private numCheckExecutionCallsSinceComponentInitialized: number = 0;

    public constructor() {
        super();
    }

    public get callableName(): string {
        return 'selectComponent';
    }

    public execute(testActionService: ITestActionRecorderService, params: string[]): FunctionExecutionStatus {
        // (Re)initialize instance data since this function instance can be called multiple times with different parameters.
        if (this.componentHierarchyService == null) {
            this.componentHierarchyService = testActionService.componentHierarchyService;
            this.componentHierarchyService.addHierarchyChangedHandler(this);
        }
        if (this.testActionService == null)
            this.testActionService = testActionService;
        this.tagName = null;
        this.selectedComponentInitialized = false;
        this.numCheckExecutionCallsSinceComponentInitialized = 0;

        let executionStatus: FunctionExecutionStatus = FunctionExecutionStatus.Failed;

        if ((params != null) && (params.length == 1)) {
            this.tagName = params[0];
            let component: ITestableComponent = this.componentHierarchyService.findComponentTreeNodeByTagFromRoot(this.tagName);

            if ((component != null) && (component.InitializationStatus == ScriptableComponentStatus.InitializationComplete)) {
                testActionService.selectComponent(component);
                executionStatus = FunctionExecutionStatus.Completed;
            }
            else
                executionStatus = FunctionExecutionStatus.Verifying;
        }

        return executionStatus;
    }

    public checkExecutionStatus(testActionService: ITestActionRecorderService, params: string[]): FunctionExecutionStatus {
        let executionStatus: FunctionExecutionStatus = this.selectedComponentInitialized && (this.numCheckExecutionCallsSinceComponentInitialized > 0) ? FunctionExecutionStatus.Completed : FunctionExecutionStatus.Verifying;
        if (this.selectedComponentInitialized)
            this.numCheckExecutionCallsSinceComponentInitialized++;
        return executionStatus;
    }

    // Implement IComponentHierarchyChanged methods.
    public componentAdded(component: ITestableComponent) { } // NOOP
    public componentRemoved(component: ITestableComponent, currentParentComponent: ITestableComponent): void { } // NOOP
    public hierarchyChanged(): void { } // NOOP
    public componentInitialized(component: ITestableComponent, initializationStatus: ScriptableComponentStatus): void {
        if ((this.tagName != null) && (this.tagName == component.tagName)) {
            this.testActionService.selectComponent(component);
            this.selectedComponentInitialized = true;
        }            
    }
}
