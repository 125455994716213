<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<mat-drawer-container (backdropClick)="this.deselectAttachment()" class="site-list">
    <mat-drawer-content>
        <!--//TEAMS-424: KLW - The file-uploader is needed to add attachments, so we must hide it-->
        <div style="display: none">
            <app-file-uploader [dataCollectionId]="this.DataCollectionId"
                               [folderId]="this.FolderId"
                               [formInstanceId]="this.FormInstanceId"
                               [formFieldId]="this.FormField.id"
                               [attachmentCount]="this.AttachmentCount"
                               (filesUploaded)="this.handleFilesUploaded($event)"></app-file-uploader>
        </div>
        <div class="{{this.Mode =='design' ? 'design-mode-field-label' : 'field-label'}}"><mat-label *ngIf="this.ShowLabel">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></mat-label></div>
        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
        <app-list-view [list]="this.AttachmentItems"
                       [listItemsAreDraggable]="true"
                       [listType]="'attachment'"
                       [columnsConfig]="['select', 'name', 'description', 'createdByName', 'createdDate', 'status']"
                       [buttonConfig]="this.buttonConfig"
                       [selectedId]="this.getSelectedId()"
                       [hasCheckboxes]="this.hasCheckboxes"
                       [hideActionButtons]="this.hideActionButtons"
                       (itemActionClick)="doOnItemAction($event)"
                       (itemContextMenu)="doOnItemContextMenu($event)"
                       (itemDeleted)="doOnItemDelete($event)"
                       (editClick)="openDownloadUrl($event)"
                       (itemMoved)="doOnItemMoved($event)"
                       (buttonClick)="doOnButtonClick($event)">
        </app-list-view>

        <div style="visibility: hidden; position: fixed"
             [style.left]="this.ContextMenuPositionX"
             [style.top]="this.ContextMenuPositionY"
             [matMenuTriggerFor]="itemContextMenu">
        </div>

        <mat-menu #itemContextMenu="matMenu">
            <!--VNEXT-891: KLW - Turn off all features except for Download -->
            <!--VNEXT-707: KLW - Add option for Edit in App for SharePoint attachments-->
            <!-- <button mat-menu-item *ngIf="this.OnlyShowDelete" (click)="this.openEditInAppUrl()">
                <mat-icon>edit</mat-icon>
                <span>Edit in App</span>
            </button>

            <button mat-menu-item *ngIf="this.ShowEditInBrowser || this.OnlyShowDelete" (click)="this.openEditInBrowserUrl()">
                <mat-icon>edit</mat-icon>
                <span>Edit in Browser</span>
            </button>

            <button mat-menu-item *ngIf="this.OnlyShowDelete" (click)="this.openViewInBrowserUrl()">
                <mat-icon>preview</mat-icon>
                <span>View in Browser</span>
            </button> -->

            <button mat-menu-item *ngIf="this.OnlyShowDelete" (click)="this.openDownloadUrl()">
                <mat-icon>file_download</mat-icon>
                <span>Download</span>
            </button>

            <button mat-menu-item (click)="this.matMenuDeleteAttachment()">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
            </button>
        </mat-menu>
    </mat-drawer-content>
    <mat-drawer #details
                *ngIf="this.SelectedAttachment"
                (keydown.escape)="this.deselectAttachment()"
                mode="over"
                position="end"
                [opened]="this.SelectedAttachment != null"
                class="drawer-padding"
                disableClose>
        <mat-toolbar>
            <span class="drawer-title"> <i class="drawer-icon" [appIcon]="this.SelectedAttachment"></i>  {{this.SelectedAttachment.name}}</span>
            <span class="info-drawer-toolbar-spacer"></span>
            <button mat-icon-button (click)="this.deselectAttachment()" tabindex="-1"><mat-icon>close</mat-icon></button>
        </mat-toolbar>

        <mat-list>
            <mat-list-item class="list-header">Properties</mat-list-item>
            <mat-list-item>
                <div>Name</div>
            </mat-list-item>
            <mat-list-item>
                <mat-form-field [appearance]="this.EditInputAppearance">
                    <input matInput [value]="this.EditFileName" [disabled]="!this.EditEnabled" class="edit-text-color" (focusout)="this.EditFocusOut($event, 'name')" />
                    <div *ngIf="!this.EditEnabled;else file_extension" mat-icon-button matSuffix>
                        <button mat-button aria-label="Clear">
                            <i class="edit-icon" matSuffix mat-list-icon [appIcon] matIconText="edit" appIconItemType="{{this.ActivityListIcon}}" (click)="this.EditButtonClicked()"></i>
                        </button>
                    </div>
                    <ng-template #file_extension appearance="none" mat-icon-button matSuffix>
                        <div>.{{this.FileExtension}}</div>
                    </ng-template>

                </mat-form-field>
            </mat-list-item>

            <mat-list-item>
                <div>Description</div>
            </mat-list-item>
            <mat-list-item>
                <mat-form-field [appearance]="this.EditInputAppearance">
                    <input matInput [value]="this.SelectedItemDescription" [disabled]="!this.EditEnabled" class="edit-text-color" (focusout)="this.EditFocusOut($event, 'desc')" />
                    <div *ngIf="!this.EditEnabled;" mat-icon-button matSuffix>
                        <button mat-button aria-label="Clear">
                            <i class="edit-icon" matSuffix mat-list-icon [appIcon] matIconText="edit" appIconItemType="{{this.ActivityListIcon}}" (click)="this.EditButtonClicked()"></i>
                        </button>
                    </div>
                </mat-form-field>
            </mat-list-item>

            <div *ngIf="this.canPreview(this.SelectedAttachment.name)">
                <mat-list-item class="list-header">Preview</mat-list-item>
                <iframe style="height: 200px; width: 400px;"
                        [src]="this.SelectedAttachment.miniViewUrl | safe: 'resourceUrl'"
                        role="presentation"
                        tabindex="-1"></iframe>
                <div class="line-seperator"></div>
            </div>

            <div *ngIf="this.AttachmentInfo">
                <div class="line-seperator"></div>
                <mat-list>
                    <mat-list-item class="list-header">Recent Activity</mat-list-item>
                    <mat-list-item *ngFor="let history of this.AttachmentInfo">
                        <i class="activity-icon" mat-list-icon [appIcon] matIconText="account_circle" appIconItemType="{{this.ActivityListIcon}}"></i>
                        <div mat-line><b>{{history.createdByName}}</b> made edits</div>
                        <div mat-line>{{history.createdDate | date: 'medium'}}</div>
                        <div mat-line>Version: {{history.versionLabel}}</div>
                    </mat-list-item>
                </mat-list>
            </div>

            <div class="line-seperator"></div>
            <mat-list-item class="list-header">Information</mat-list-item>

            <mat-list-item>
                <div mat-line>Type</div>
                <div mat-line>{{this.FileExtension | uppercase}} File</div>
            </mat-list-item>

            <mat-list-item>
                <div mat-line>Modified</div>
                <div mat-line>{{this.ModifiedDate | date: 'medium'}}</div>
            </mat-list-item>

            <mat-list-item>
                <div mat-line>Path</div>
                <div mat-line>docs > {{this.DataCollectionId}} > {{this.FolderId}} > {{this.FormInstanceId}} > {{this.FormFieldId}} > {{this.SelectedAttachment.name}}</div>
            </mat-list-item>

            <mat-list-item>
                <div mat-line>Size</div>
                <div mat-line>{{formatBytes(this.SelectedAttachment.size)}}</div>
            </mat-list-item>
        </mat-list>

        <div class="button-wrapper">
            <button mat-raised-button color="primary" class="button-class" (click)="saveClicked()">OK</button>
            <button mat-flat-button color="basic" class="button-class" (click)="cancelClicked()">Cancel</button>
        </div>

        <div id="details-drawer-keyboard-focus-div" tabindex="-1"></div>

    </mat-drawer>
</mat-drawer-container>
