import { Component, OnInit, Input, Output, ComponentFactoryResolver } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

import { GridAllModesDataSource } from '../../../models/grid/grid-data-source.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { GridFormFieldComponent } from '../grid-form-field/grid-form-field.component';
import { FormFieldTypeAndNameService } from '../../../services/form-field-type-and-name.service';
import { FieldTypeAndName, IFieldTypeToFieldInfo, } from '../../../services/form-field-type-and-name.service';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { IFormFieldDisplayHint } from '../form-field-base/form-field-base.component';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { FieldDefinitionService } from '../../../services/field-definition.service';
import { IFieldNameToFormField, IFormFieldComponent } from '../../../interfaces/iform-field-component';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { ComponentAndFormField } from '../../../models/grid/component-and-form-field-model';
import { GridRowDef } from '../../../models/grid/grid-row.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { KendoGridBaseComponent } from '../kendo-grid-base/kendo-grid-base.component';

// Define constants used below.
export const GRID_ROW_ID_KEY: string = 'gridRowId';

// This is the base class for both GridFieldDesigner and GridFieldEditor

@Component({
    selector: 'app-DO-NOT-USE-grid-designer-editor-base',
    templateUrl: './grid-designer-editor-base.html',
    styleUrls: ['../grid-form-field/grid-form-field.component.scss']
})
export class GridDesignerEditorBase extends KendoGridBaseComponent {
    // Input(s).
    @Input() gridFormFieldComponent: GridFormFieldComponent;
    @Input() isFootnotesGrid: boolean = false;

    // Constructor.
    protected constructor(protected formFieldTypeAndNameService: FormFieldTypeAndNameService,
        protected fieldDefinitionService: FieldDefinitionService,
        protected resolver: ComponentFactoryResolver) {
        super();
    }

    // Begin called by HTML code.
    public get AllModesDataSource(): GridAllModesDataSource {
        return this.gridFormFieldComponent.AllModesDataSource;
    }

    public get GridColumnDefs(): FormField[] {
        return this.gridFormFieldComponent.GridColumnDefs;
    }
    public set GridColumnDefs(colDefs: FormField[]) {
        this.gridFormFieldComponent.GridColumnDefs = colDefs;
    }
    public get ExpandableGridColumnDefs(): FormField[] {
        // Note:  this method is not yet properly implemented.
        return this.gridFormFieldComponent.GridColumnDefs;
    }
    public GridColumnNames(bIncludeAddColumn: boolean = false): string[] {
        let astrColumnNames: string[] = this.gridFormFieldComponent.GridConfig.GridColumnNames;
        astrColumnNames = this.GridColumnDefs.map(ff => ff.name);

        if (bIncludeAddColumn) {
            astrColumnNames.push("Add Grid Column");
        }

        return (astrColumnNames);
    }

    public get FieldTypesAndNames(): FieldTypeAndName[] {
        let fieldTypesAndNameFields = this.formFieldTypeAndNameService.getAllGridFieldTypesAndNameFields();
        return fieldTypesAndNameFields;
    }

    public get FormFieldDisplayHints(): IFormFieldDisplayHint {
        return this.gridFormFieldComponent.FormFieldDisplayHints;
    }

    public get ReadOnly(): boolean {
        return this.gridFormFieldComponent.ReadOnly;
    }

    public get ShowNumericTotalsFooter(): boolean {
        return (this.gridFormFieldComponent.FormField ? this.gridFormFieldComponent.FormField.showFooter === true : false);
    }

    public get TooltipPosition(): TooltipPosition {
        let position: TooltipPosition = 'below';
        return position;
    }

    public getKendoGridColumnWidth(columnDef: FormField): number {
        return 300;
    }

    public getColumnTooltip(iColIndex: number, hshColumnDef: FormField): string {
        let columnTooltip: string = ''; // A default value.

        if (hshColumnDef.toolTip && (hshColumnDef.toolTip.trim() !== '')) {
            columnTooltip = hshColumnDef.toolTip;
        }

        return columnTooltip;
    }
    public get Mode(): string {
        return this.gridFormFieldComponent.Mode;
    }
    // End called by HTML code.

    // Handle ngModel logic.
    public handleNgModelChangeLogic(gridRow: GridRowDef, formField: FormField, wrapper: GridFormInstanceElementWrapper, processingPhase: FormFieldProcessingPhaseEnum = FormFieldProcessingPhaseEnum.EDITING_DATA): void {
        let formInstanceElement: FormInstanceElement = wrapper.formInstanceElement;

        // Update the wrapper's 'stand-in' value.
        let fieldTypeAndName: FieldTypeAndName = this.formFieldTypeAndNameService.getFieldTypeAndField(formField.fieldDefinitionClassName);
        let fieldLogicHandler: IFieldDefinitionLogic = fieldTypeAndName != null ? this.fieldDefinitionService.getFieldDefinition(fieldTypeAndName.fieldType).customLogicHandler : null;

        if ((fieldTypeAndName != null) && (fieldLogicHandler != null)) {
            //let fieldDefinition: FieldDefinition = this.fieldDefinitionService.getFieldDefinition(fieldTypeAndName.fieldType);
            //let fieldLogicHandler: IFieldDefinitionLogic = fieldDefinition.customLogicHandler;
            wrapper.standinDisplayValue = fieldLogicHandler.getDisplayValue(formField, wrapper.formInstanceElement, gridRow, processingPhase);
        }

        // If any fields are listening for value changes, let them know about this update.
        let iColIndex: number = this.gridFormFieldComponent.GridConfig.ColumnIndex(formField);
        let affectedComponents: IFormFieldComponent[] = null;

        if (iColIndex >= 0) {
            affectedComponents = this.notifyComponentsOfValueChanges(iColIndex, formField, formInstanceElement, gridRow);
        }

        // If we have a numeric totals footer.
        if (this.ShowNumericTotalsFooter && (fieldTypeAndName != null) && fieldLogicHandler.hasNumericData) {
            let numericValue: number = fieldTypeAndName.componentRepresentative.getNumericValue(formField, formInstanceElement, gridRow, FormFieldProcessingPhaseEnum.CALCULATING_COLUMN_TOTAL)

            this.gridFormFieldComponent.AllModesDataSource.cellValueChanged(gridRow, formField, fieldLogicHandler, numericValue);

            // Were other components affected by this change?
            // Note:  "other components" would most likely be a formula form field.
            if ((affectedComponents != null) && (affectedComponents.length > 0)) {
                for (let iComp: number = 0; iComp < affectedComponents.length; iComp++) {
                    let comp: IFormFieldComponent = affectedComponents[iComp];
                    let compFormField: FormField = comp.getFormField();
                    let compFormInstanceElement: FormInstanceElement = comp.getFormInstanceElement();

                    let affectedComponentNumericValue: number = comp.getNumericValue(compFormField, compFormInstanceElement, gridRow, FormFieldProcessingPhaseEnum.CALCULATING_COLUMN_TOTAL);
                    this.gridFormFieldComponent.AllModesDataSource.cellValueChanged(gridRow, compFormField, fieldLogicHandler, affectedComponentNumericValue);
                }
            }
        }

        return;
    }

    public gridColumnInit(hshEventData: any, hshUnusedColumnDef: FormField): void {
        // Note:  will want to redo the implementation of the method called on the next line
        //        to stop using component 'representatives', switching to using FieldDefintion
        //        and field definition custom logic classes instead.
        //
        //        Once that is done, the code can also be moved into this method and out of
        //        class GridFormFieldComponent.
        //this.gridFormFieldComponent.gridColumnInit(hshEventData, hshUnusedColumnDef);
        // Note:  the following port of method gridColumnInit() is not yet being used.
        let component: FormFieldBaseComponent = hshEventData['component'];

        if (component != null) {
            // Increment the grid form field count.
            this.gridFormFieldComponent.RuntimeData.iNumRowFormFieldsInitialized++;

            // Save this component.  Replace any prior component that used this form field.
            let componentFormField: FormField = component.FormField;

            this.gridFormFieldComponent.RuntimeMetadata.addOrReplaceAllComponents(component, componentFormField);
        }

        // Also pass this event on to my parent.
        // Since this component is responsible for passing on
        // notifications to child / column components, replace
        // the component in the event with this component.

        // Have all row form field components been created?
        if (this.gridFormFieldComponent.RuntimeData.iNumRowFormFieldsInitialized == this.gridFormFieldComponent.GridConfig.ColumnCount) {
            // Need to notify any calculated form fields of the other field values.
            let colDefs: FormField[] = this.gridFormFieldComponent.GridConfig.ColumnDefs;

            this.gridFormFieldComponent.RuntimeData.iNumRowFormFieldsInitialized = 0; // This instance variable is irrelevant until another row is selected.
        } // if
    }

    // Define protected methods.
    protected raiseException(errorMessage: string): void {
        // Note:  this method merely provides a single entry to report
        //        exceptions, a convenience when troubleshooting.
        throw errorMessage;
    }

    // Helper methods.
    private notifyComponentsOfValueChanges(iColIndex: number, formField: FormField, formInstanceElement: FormInstanceElement, gridRow: IGridRow): IFormFieldComponent[] {
        let result: IFormFieldComponent[] = [];

        if (formInstanceElement.transientValuesHash && formInstanceElement.transientValuesHash[GRID_ROW_ID_KEY]) {
            let iGridRowId: number = formInstanceElement.transientValuesHash[GRID_ROW_ID_KEY];

            // Note:  since the optimization that only one row of controls can be active at a give time,
            //        we know that any dynamic/user-entered value change will affect the any active calculated
            //        form field.  Hence, we do not need to compare row id values anymore.
            if (iGridRowId != 0) {
                for (let iComponent: number = 0;
                    iComponent < this.gridFormFieldComponent.RuntimeMetadata.AllComponentsCount;
                    iComponent++) {
                    let componentAndFormField: ComponentAndFormField = this.gridFormFieldComponent.RuntimeMetadata.AllComponents[iComponent];
                    let component: FormFieldBaseComponent = componentAndFormField.Component;

                    if (component.requiresFieldValueUpdate()) {
                        this.notifyComponentOfFieldNames(component);

                        let bComponentAffected: boolean = component.formFieldValueUpdated(iColIndex, formField, formInstanceElement, gridRow);
                        if (bComponentAffected) {
                            result.push(component);
                        }
                    }
                } // for
            } // if
        } // if

        return result;
    }

    private notifyComponentOfFieldNames(component: FormFieldBaseComponent): void {
        // Assemble a hash of all component names.
        let astrFieldNames: string[] = [];
        let hshColNameToFormField: IFieldNameToFormField = {};

        for (let iComponent: number = 0; iComponent < this.gridFormFieldComponent.RuntimeMetadata.AllComponentsCount; iComponent++) {
            let component: FormFieldBaseComponent = this.gridFormFieldComponent.RuntimeMetadata.getAllComponentsFormFieldComponent(iComponent);

            let columnFormField: FormField = this.gridFormFieldComponent.RuntimeMetadata.getAllComponentsFormField(iComponent);
            component.FormField = columnFormField;

            let iCol: number = this.gridFormFieldComponent.GridConfig.getColumnIndex(columnFormField) + 1;

            let colName: string = `col${iCol}`;
            astrFieldNames.push(colName);

            hshColNameToFormField[colName] = columnFormField;
        }

        component.receiveFormFieldNames(astrFieldNames, hshColNameToFormField);
    }
}
