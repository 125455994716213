import { TokenCode } from './token-code.enum';

export class Token {
    // Instance data.
    //private code: number = -1;
    private code: TokenCode = null;
    private value: string = null;

    // Constructor.
    //public constructor(tokenCode: number, tokenValue: string = null) {
    public constructor(tokenCode: TokenCode, tokenValue: string = null) {
        this.code = tokenCode;
        if (tokenValue != null)
            this.value = tokenValue;
    }

    // Getter methods.
    //public get Code(): number {
    public get Code(): TokenCode {
        return this.code;
    }
    public get Value(): string {
        return this.value;
    }
}
