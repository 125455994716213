import { Injectable } from '@angular/core';

import { ITestableComponent } from '../interfaces/itestable-component.interface';
import { StringUtil } from '../utility-classes/string.util';
import { IElementTypeMetadata } from '../interfaces/component-scripting/ielement-type-metadata';
import { HtmlElementInfo } from '../models/component-scripting/html-element-info.model';
//import { ComponentMethodMetadata } from '../models/component-scripting/component-methods-metadata.model';
import { ITestActionRecorderService } from '../interfaces/itest-action-recorder-service.interface';
import { ComponentHierarchyService } from './component-hierarchy.service';
import { IRegisterOperationCompletedCallback } from '../interfaces/ioperation-completed.intefaces';

import { AppInjector } from '../../app.module';

// Define a class for log-related data.
class TestActionLogData {
    public logToConsole: boolean = true;
    public testActionLog: string[] = [];

    public clearTestActions(): void {
        this.testActionLog = [];
    }
}

enum TestableAction {
    None = 'None',
    SelectComponent = 'SelectComponent',
    SetInputValue = 'SetInputValue',
    ClickButton = 'ClickButton',
    ClickLink = 'ClickLink',
    CallMethod = 'CallMethod'
}
class TestActionInfo {
    public constructor(public currentAction: TestableAction = TestableAction.None, public lastActionPerformed: TestableAction = TestableAction.None) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class UITestActionRecorderService implements ITestActionRecorderService { //ISelectsComponent, IClicksButton, IClicksLink, IInputsValue, ICallsMethod, 
    // Properties.
    private logData: TestActionLogData = new TestActionLogData();
    //private previousActionIsSelectComponent: boolean = false;
    private testActionInfo: TestActionInfo = new TestActionInfo();

    // Constructor.
    public constructor() {
    }

    // Getter method(s).
    public get TestActionLog(): string[] {
        return this.logData.testActionLog;
    }
    public get TestActionLogText(): string {
        return StringUtil.arrayToString(this.logData.testActionLog);
    }
    public get LogIsEmpty(): boolean {
        return this.logData.testActionLog.length == 0;
    }

    public clearLog(): void {
        this.logData.testActionLog = [];
    }

    public setLogText(testActions: string[]): void {
        this.logData.testActionLog = testActions;
    }

    // Begin ITestActionRecorderService method.
    // Resources
    public get componentHierarchyService(): ComponentHierarchyService {
        let service: ComponentHierarchyService = AppInjector.get(ComponentHierarchyService);
        return service;
    }

    public getRegisterOperationCompletedCallbackFor(serviceName: string): IRegisterOperationCompletedCallback {
        // Note:  this method presently returns null by design.
        return null;
    }

    // Methods for recording test actions.
    public selectComponent(component: ITestableComponent): void {
        this.beginAction(TestableAction.SelectComponent);

        let message: string = `selectComponent('${component.tagName.toLowerCase()}');`;
        this.saveLogMessage(message);

        //this.previousActionIsSelectComponent = true;
        this.actionCompleted();
    }

    public selectElement(elementType: string, elementInfo: HtmlElementInfo): void {
        let message: string = `selectElement('${elementType}', '${elementInfo.elementTitle}');`;
        this.saveLogMessage(message);
    }
    public clickButton(elementTitle: string, elementMetadataKey: string, elementSubtype: string, operationCompletedServiceName: string, operationName: string): void {
        this.beginAction(TestableAction.ClickButton);

        let elementSubtypeText: string = elementSubtype != null ? `'${elementSubtype}'` : 'null';
        let operationCompletedServiceText: string = operationCompletedServiceName != null ? `'${operationCompletedServiceName}'` : 'null';
        let operationNameText: string = operationName != null ? `'${operationName}'` : 'null';
        let message: string = `clickButton('${elementTitle}', '${elementMetadataKey}', ${elementSubtypeText}, ${operationCompletedServiceText}, ${operationNameText});`;
        this.saveLogMessage(message);

        this.actionCompleted();
    }
    public clickLink(elementTitle: string, elementMetadataKey: string, elementSubtype: string, operationCompletedServiceName: string, operationName: string): void {
        this.beginAction(TestableAction.ClickLink);

        let elementSubtypeText: string = elementSubtype != null ? `'${elementSubtype}'` : 'null';
        let operationCompletedServiceText: string = operationCompletedServiceName != null ? `'${operationCompletedServiceName}'` : 'null';
        let operationNameText: string = operationName != null ? `'${operationName}'` : 'null';
        let message: string = `clickLink('${elementTitle}', '${elementMetadataKey}', ${elementSubtypeText}, ${operationCompletedServiceText}, ${operationNameText});`;
        this.saveLogMessage(message);

        this.actionCompleted();
    }
    //public callMethod(methodName: string, componentMethodMetadata: ComponentMethodMetadata, parameters: string[]): void {
    //public callMethod(component: ITestableComponent, methodName: string, parameters: string[]): void {
    public callMethod(methodName: string, parameters: string[]): void {
        this.beginAction(TestableAction.CallMethod);

        let message: string = `callMethod('${methodName}'`;
        // Process any parameters.
        if ((parameters != null) && (parameters.length > 0)) {
            for (let index: number = 0; index < parameters.length; index++) {
                let parameter: string = parameters[index];

                /*
                let parameterIsString: boolean = false;
                if ((componentMethodMetadata.argumentTypeNames != null) && (componentMethodMetadata.argumentTypeNames.length > index) && (componentMethodMetadata.argumentTypeNames[index].toLowerCase() == 'string'))
                    parameterIsString = true;
                */
                let parameterIsString: boolean = true;

                let passParameterText: string = parameterIsString ? `, '${parameter}'` : `, ${parameter}`;
                message += passParameterText;
            }
        }

        message += ');';
        this.saveLogMessage(message);

        this.actionCompleted();
    }

    //public setInputValue(elementInfo: HtmlElementInfo, elementTypeMetadata: IElementTypeMetadata, value: string, addDateTimeSuffix: boolean = false): void {
    //public setInputValue(elementTitle: string, elementMetadataKey: string, value: string, addDateTimeSuffix: boolean): void {
    public setInputValue(elementTitle: string, elementMetadataKey: string, elementSubtype: string, value: string, addDateTimeSuffix: boolean): void {
        this.beginAction(TestableAction.SetInputValue);

        let addDateTimeSuffixText: string = addDateTimeSuffix ? 'true' : 'false';
        //let message: string = `setInputValue('${elementInfo.elementTitle}', '${value}', ${addDateTimeSuffixText});`;
        //let message: string = `setInputValue('${elementTitle}', '${elementMetadataKey}', '${value}', ${addDateTimeSuffixText});`;
        let message: string = elementSubtype != null ?
            `setInputValue('${elementTitle}', '${elementMetadataKey}', '${elementSubtype}', '${value}', ${addDateTimeSuffixText});` :
            `setInputValue('${elementTitle}', '${elementMetadataKey}', null, '${value}', ${addDateTimeSuffixText});`;
        this.saveLogMessage(message);

        this.actionCompleted();
    }
    public getInputValue(element: object, elementTypeMetadata: IElementTypeMetadata): string {
        let value: string = null;
        // TO DO:  CODE THIS METOHD.
        return value;
    }

    // Helper methods.
    private beginAction(action: TestableAction): void {
        this.testActionInfo.currentAction = action;

        if (action == TestableAction.SelectComponent) {
            if (this.testActionInfo.lastActionPerformed == TestableAction.SelectComponent)
                this.popLogMessage();
        } else if (action == TestableAction.SetInputValue) {
            if (this.testActionInfo.lastActionPerformed == TestableAction.SetInputValue)
                this.popLogMessage();
        }
    }
    private actionCompleted(): void {
        this.testActionInfo.lastActionPerformed = this.testActionInfo.currentAction;
        this.testActionInfo.currentAction = TestableAction.None;
    }
    private saveLogMessage(message: string): void {
        // Check for a duplicate log message.
        let dupMessage: boolean = false;

        if (this.logData.testActionLog.length > 0) {
            if (message == this.logData.testActionLog[this.logData.testActionLog.length - 1])
                dupMessage = true;
        }

        if (!dupMessage)
            this.logData.testActionLog.push(message);
    }
    private popLogMessage(): string {
        let poppedMessage: string = this.logData.testActionLog.length > 0 ? this.logData.testActionLog.pop() : null;
        return poppedMessage;
    }
}
