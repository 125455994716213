import { HttpClient } from '@angular/common/http';
import { Directive, OnInit, OnDestroy } from '@angular/core';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { SiteGridDataService } from '../services/site-grid-data.service';

@Directive({
    selector: '[appSiteGridRemoteBinding]',
    exportAs: 'remoteBindingDirectiveRef'
})
export class SiteGridRemoteBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {

    private serviceSubscription: Subscription;
    private showAllForAdmin: boolean;

    constructor(private http: HttpClient, grid: GridComponent, private siteGridDataService: SiteGridDataService) {
        super(grid);
    }

    public ngOnInit() {
        this.serviceSubscription = this.siteGridDataService.subscribe((data) => {
            // this code called whenever the service has new data to present, triggered by calls to the rebind() callback function
            // (which DataBindingDirective takes care of calling when grid state changes)
            this.grid.loading = false;
            this.grid.data = data.resultsAsPlainObjects;
            this.notifyDataChange();
        });

        super.ngOnInit();
        this.rebind();
    }

    public get ShowAllForAdmin(): boolean {
        return this.showAllForAdmin;
    }
    public set ShowAllForAdmin(value: boolean) {
        this.showAllForAdmin = value;
    }

    // A callback which the DataBindingDirective super class takes care of calling automatically when the grid's state changes
    public rebind(): void {
        this.grid.loading = true;
        if (this.showAllForAdmin) {
            this.siteGridDataService.getAllForSystemAdmin(this.state);
        } else {
            this.siteGridDataService.read(this.state);
        }
    }

    public ngOnDestroy(): void {
        if (this.serviceSubscription) {
            this.serviceSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

}
