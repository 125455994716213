import { environment } from '../../../environments/environment';
import { CurrentUser } from '../../security/current-user.model';
import { Logging } from '../logging';
import { DataCollection } from './site-content/data-collection.model';

// Added to model security claims for the current user
export class UserClaims {
    public id: number; // max id of user who claims this represents
    public claims: Set<string> = new Set();

    constructor(user: CurrentUser, site: DataCollection = null) {

        if (user.isSystemAdmin) {
            this.claims.add('CanViewAdmin');
            this.claims.add('CanViewAdminAbout');
            this.claims.add('CanViewAdminSettings');
            this.claims.add('CanViewAdminUserInfo');
            this.claims.add('CanViewAdminAllSites');
        }

        //VNEXT-787 in prod, members of COLLECT-VNEXT-PROD-SITE-CREATORS(NONFED)
        let apiUrl = environment.apiUrl.toLowerCase();
        if (apiUrl.indexOf('.test.') < 0 && apiUrl.indexOf('.stage.') < 0 && apiUrl.indexOf('.sandbox.') < 0) {
            if (user.isProdSiteCreator) {
                this.claims.add('CanCreateSite');
            }
        }
        else {
            //console.log('Not production; user.isSiteCreator is ' + user.isSiteCreator + ' and user.isSystemAdmin is ' + user.isSystemAdmin);
            //members of COLLECT-VNEXT-SITE-CREATORS
            //or COLLECT-VNEXT-SYSTEM-ADMINS
            if (user.isSiteCreator || user.isSystemAdmin) {
                this.claims.add('CanCreateSite');
            }
        }

        if (user.hasAccessibleSites || user.isSystemAdmin) {
            this.claims.add('CanViewSiteList');
        }

        if (site) {
            if (site.siteIsAdministerable) {
                this.claims.add('CanViewSiteStructure');
                this.claims.add('CanViewSiteSummary');
                this.claims.add('CanViewSiteFormCatalog');
                this.claims.add('CanViewSitePermissions');
                this.claims.add('CanViewSiteWorkflowCatalog');
                this.claims.add('CanViewSiteManagement');
            }
            if (site.currentUserCanSeeSimplePermissions) {
                this.claims.add('CanViewSitePermissions');
            }
            // Note:  discuss the next four lines with Paul before enabling them.
            if (site.treeIsBuildable) { // || site.treeIsViewable) {
                this.claims.add('CanViewSiteStructure');
            } else if (site.currentUserCanSeeStructure)
                this.claims.add('CanViewSiteStructure');
        }
    }

    // Returns true if user has claim 
    public has(claim: string): boolean {
        // TODO: THIS IS TEMPORARY, REMOVE IT AFTER DEV
        if (environment.userGrantedAllClaims) {
            //console.warn("WARNING: UserClaims.has() is using environment.userGrantedAllClaims...");
            return true;
        }
        if (claim == null) return true;

        return this.claims.has(claim);
    }
}
