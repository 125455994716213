import { plainToClass } from "class-transformer";

import { HeaderValuePair } from '../csv-data/header-value-pair.model';

export class FixedFirstColumnValues {
    // Properties.
    public columnName: string;
    public values: string[] = [];

    // Constructor.
    public constructor(columnName: string) {
        this.columnName = columnName;
    }

    // Static methods.
    public static createFromHeaderValuePairs(columnName: string, linesOfHeaderValuePairs: HeaderValuePair[][]): FixedFirstColumnValues {
        let fixedValues: FixedFirstColumnValues = new FixedFirstColumnValues(columnName);

        if (linesOfHeaderValuePairs != null) {
            for (let index: number = 0; index < linesOfHeaderValuePairs.length; index++) {
                let lineHeaderValuePairs: HeaderValuePair[] = linesOfHeaderValuePairs[index];
                if ((lineHeaderValuePairs != null) && (lineHeaderValuePairs.length >= 1)) {
                    let value: string = lineHeaderValuePairs[0].ValueText;
                    fixedValues.values.push(value);
                }
            }
        }

        return fixedValues;
    }

    public static createFromJson(json: string): FixedFirstColumnValues {
        let object: any = JSON.parse(json);
        let fixedValues: FixedFirstColumnValues = (object != null ? plainToClass(FixedFirstColumnValues, object) : null);
        return fixedValues;
    }
}
