<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<!-- Using same layout vis-à-vis tooltips, but checkbox may benefit from custom approach -->
<div class="flex-row-top-left" *ngIf="this.Mode === 'design'">
    <div>
        <!-- If there are instructions, display label first, then instructions then field -->
        <ng-container *ngIf="this.HasInstructions">
            <div class="design-mode-field-label" *ngIf="this.DisplayName">{{this.DisplayName}}</div>
            <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
        </ng-container>

        <mat-checkbox [(ngModel)]="this.DefaultCheckboxValue"
                      (ngModelChange)="this.handleOnModelChange()"
                      disabled
                      class="set-left-align">
            <span *ngIf="!this.HasInstructions">{{this.DisplayName}}</span>
        </mat-checkbox>
        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
</div>

<div class="flex-row-top-left" *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')">
    <div>

        <!-- If there are instructions, display label first, then instructions then field -->
        <ng-container *ngIf="this.HasInstructions">
            <div class="field-label" *ngIf="this.DisplayName">{{this.DisplayName}}</div>
            <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
        </ng-container>

        <ng-container *ngIf="(this.ControlType === 'Reactive Forms') && (this.FormControl !== null)">
            <mat-checkbox [formControl]="this.FormControl"
                          id="{{this.MatInputId}}"
                          #matCheckbox
                          (blur)="this.handleOnBlur()"
                          (focus)="this.handleOnFocus($event)"
                          class="set-left-align">
                <span *ngIf="!this.HasInstructions">{{this.DisplayName}}</span>
            </mat-checkbox>
        </ng-container>
    </div>
    <div style="margin-top:-25px;">
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>
</div>
