export class Attachment {
    // Key.
    public id: number = 0;

    // Primitives.
    public name: string;

    public dataCollectionId: number;

    // Additional properties.
    // NOTE:  additional properties TBD.
}

// This class models custom properties of attachments. These are stored in the
// attachment itself in SharePoint in a CustomProperties field, but exposed in the application
// as the properties encapsulated here
export class AttachmentProperties {
    public description: string;
    public displayOrder: number;

    public constructor(description: string, displayOrder: number) {
        this.description = description;
        this.displayOrder = displayOrder;
    }
}

