import { GridFormInstanceElementWrapper } from './grid-form-instance-element-wrapper.model';
import { FormField } from '../form-builder/form-field.model';
import { IFieldDefinitionLogic } from '../../interfaces/ifield-definition-logic.interface';
import { GridRowDef } from './grid-row.model';

export class SortGridRowsByFormField {
    public constructor(private sortField: FormField, private fieldLogic: IFieldDefinitionLogic, private sortAscending: boolean) { }

    public compareGridRows = (gridRow1: GridRowDef, gridRow2: GridRowDef): number => {
        let cellValue1: GridFormInstanceElementWrapper = gridRow1.getFormInstanceElementWrapper(this.sortField);
        let cellValue2: GridFormInstanceElementWrapper = gridRow2.getFormInstanceElementWrapper(this.sortField);

        let compareResult: number = (this.sortAscending ? this.fieldLogic.compareAscendingFor(this.sortField, cellValue1.formInstanceElement, cellValue2.formInstanceElement) : this.fieldLogic.compareDescendingFor(this.sortField, cellValue1.formInstanceElement, cellValue2.formInstanceElement));
        return compareResult;
    }
}

export class SortGridRowsByRowId {
    public constructor() { }

    public compareGridRows = (gridRow1: GridRowDef, gridRow2: GridRowDef): number => {
        let compareResult: number = 0;

        if (gridRow1.RowIndex > gridRow2.RowIndex)
            compareResult = 1;
        else if (gridRow1.RowIndex < gridRow2.RowIndex)
            compareResult = -1;

        return compareResult;
    }
}
