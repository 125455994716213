import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ProgressIndicatorService } from './progress-indicator.service';
import { CollectApiServiceBase } from './collect-api-base.service';
import { NotificationTemplate } from '../models/notifications/notification-template.model';
import { NotificationInstance } from '../models/notifications/notification-instance.model';
import { DataCollectionMilestone } from '../models/milestones/data-collection-milestone.model';

@Injectable()
export class NotificationService extends CollectApiServiceBase<NotificationTemplate> {
    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'notification', NotificationTemplate)
    }

    // Overrides.
    formatResponse(data: NotificationTemplate): NotificationTemplate {
        var obj = plainToClass(NotificationTemplate, data);

        return (obj);
    }

    // Service methods.
    public findAllForCategory(category: string): Promise<NotificationTemplate[]> {
        let url = `${this.url}/${this.endpoint}/findAllForCategory/${category}`;

        //this.updateProgress(50, 75, `Getting notification templates ...`);

        let observable: Observable<NotificationTemplate[]> = this.http.get<NotificationTemplate[]>(url);

        return observable.toPromise()
            .then(response => {
                let templates: NotificationTemplate[] = [];

                if (response != null) {
                    for (let iTemplate: number = 0; iTemplate < response.length; iTemplate++) {
                        let oTemplate = response[iTemplate];
                        let template: NotificationTemplate = plainToClass(NotificationTemplate, oTemplate);

                        templates.push(template);
                    }
                }

                //this.updateProgress(100, 100, `Completed`);

                return (templates);
            })
            .catch(this.handleError);
    }

    public getSiteMilestoneTemplatePreview(milestone: DataCollectionMilestone): Promise<NotificationInstance> {
        let url = `${this.url}/${this.endpoint}/getSiteMilestoneTemplatePreview`;

        //this.updateProgress(50, 75, `Requestion notification preview ...`);

        //return this.http.get<NotificationInstance>(url)
        return this.http.post<DataCollectionMilestone>(url, milestone)
            .toPromise()
            .then(response => {
                let notification: NotificationInstance = null;

                if (response != null) {
                    notification = plainToClass(NotificationInstance, response);
                }

                //this.updateProgress(100, 100, `Completed`);

                return (notification);
            })
            .catch(this.handleError);
    }

    public getUserNotifications(dataCollectionId: number): Promise<NotificationInstance[]> {
        let url = `${this.url}/${this.endpoint}/getUserNotifications/${dataCollectionId}`;

        this.updateProgress(50, 75, `Getting notification templates ...`);

        let observable: Observable<NotificationInstance[]> = this.http.get<NotificationInstance[]>(url);

        return observable.toPromise()
            .then(response => {
                let notifications: NotificationInstance[] = [];

                if (response != null) {
                    for (let iNotification: number = 0; iNotification < response.length; iNotification++) {
                        let oNotification = response[iNotification];
                        let notification: NotificationInstance = plainToClass(NotificationInstance, oNotification);

                        notifications.push(notification);
                    }
                }

                this.updateProgress(100, 100, `Completed`);

                return (notifications);
            })
            .catch(this.handleError);
    }

}
