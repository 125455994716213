import { IViewModel } from '../interfaces/view-model.interface';
import { ICloneAndCopy } from '../interfaces/clone-and-copy';

export class ShareMember implements IViewModel, ICloneAndCopy {
    // Properties.
    public id: number;

    public memberType: string; // User or Group
    public member: string; // User or group Id.

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        var memberCopy: ShareMember = new ShareMember();

        memberCopy.id = this.id;

        memberCopy.memberType = this.memberType;
        memberCopy.member = this.member;

        return(memberCopy)
    }

    public copy(objectToCopy: ICloneAndCopy): void {
        var memberToCopy: ShareMember = <ShareMember>objectToCopy;

        this.id = memberToCopy.id;

        this.memberType = memberToCopy.memberType;
        this.member = memberToCopy.member;

        return;
    }
}
