import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from "@progress/kendo-data-query";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { DataCollection } from '../models/site-content/data-collection.model';
import { ProgressIndicatorService } from './progress-indicator.service';
import { environment } from '../../../environments/environment';
import { SiteGridData } from '../models/site-content/site-grid-data.model';
import { CustomDateFormatDirective } from '../directives/custom-date-format.directive';

@Injectable({
    providedIn: 'root'
})
export class SiteGridDataService extends BehaviorSubject<SiteGridData> {

    private url = environment.apiUrl;
    private endpoint: string = 'api/dataCollection';
    private data: object[] = [];
    private loading = false;
    private gridData: SiteGridData;

    public constructor(private http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(new SiteGridData());
    }

    public read(gridState: State): void {
        this.fetch(gridState, 'getAllForUser')
            .pipe(
                tap((data) => {
                    this.gridData = data;
                    this.loading = false;
                })
            )
            .subscribe((data) => {
                super.next(data);
            });
    }
    
    public getAllForSystemAdmin(gridState: State) {
        this.fetch(gridState, 'getAllForSystemAdmin')
            .pipe(
                tap((data) => {
                    this.gridData = data;
                    this.loading = false;
                })
            )
            .subscribe((data) => {
                super.next(data);
            });
    }

    private fetch(gridState: State, action: string): Observable<SiteGridData> {

        let baseUrl = this.url.endsWith('/') ? this.url : `${this.url}/`;
        let url = `${baseUrl}${this.endpoint}/${action}`;

        return <Observable<SiteGridData>><unknown>this.http.post<SiteGridData>(url, gridState)
            .pipe(
                map((dataFromServer) => {
                    dataFromServer.resultsAsPlainObjects = [];
                    for (let dc of dataFromServer.results) {
                        var plainObj = {
                            id: dc.id,
                            name: dc.name,
                            modifiedDate: CustomDateFormatDirective.applyDateFormattingUsing("yyyy-MM-dd HH:mm:ss", dc.modifiedDate.toString()),
                            modifiedBy: dc.modifiedBy,
                            modifiedByUserName: dc.modifiedByUserName,
                            lastAccessedDate: CustomDateFormatDirective.applyDateFormattingUsing("yyyy-MM-dd HH:mm:ss", dc.lastAccessedByCurrentUser?.toString()),
                            description: dc.description,
                            purpose: dc.purpose,
                            currentWorkflowState: dc.currentStatus,
                            isFavorite: dc.isFavorite,
                            siteIsAdministerable: dc.siteIsAdministerable
                        };
                        dataFromServer.resultsAsPlainObjects.push(plainObj);
                    }
                    return dataFromServer;
                })
            );
    }
}
