import { Type, plainToClass } from "class-transformer";

import { FormInstanceElement } from '../form-builder/form-instance-element.model';
import { PickerItem } from '../picker-item.model';
import { UserTypeEnum } from './user-type.enum';
import { IUserOrGroupInfo } from './iuser-or-group-info.interface';
import { ICommentInfo } from './icomment-info.interface';
import { CommentUserOrGroupInfo } from './comment-user-or-group';
import { CommentInfo } from './comment-info';

// Define comments data types.

// Define a contacts data structure.
export class CommentsFormFieldData {
    // Property.
    private nextClientId: number = 1;
    @Type(() => CommentInfo)
    //private comments: ICommentInfo[] = [];
    private comments: CommentInfo[] = [];

    // Constructor.
    public constructor() { }

    // Getter/setter methods.
    public get Comments(): ICommentInfo[] {
        return this.comments;
    }
    public get CommentCount(): number {
        return (this.comments != null ? this.comments.length : 0);
    }

    public get SelectedComment(): ICommentInfo {
        let comment = this.comments.find(c => c.isSelected);
        return comment;
    }

    // Method(s).
    public addNewComment(commenter: string, commentTimestamp: Date): ICommentInfo {
        this.deselectAllComments();

        let comment: CommentInfo = new CommentInfo('', commenter, commentTimestamp);
        comment.clientId = this.nextClientId++;
        comment.isSelected = true;
        comment.editingEnabled = true;
        this.comments.push(comment);

        return comment;
    }

    public addComment(comment: CommentInfo): void {
        comment.clientId = this.nextClientId++;
        this.comments.push(comment);
    }

    public popComments(popCount: number): CommentInfo[] {
        let poppedComments: CommentInfo[] = [];

        let numPopped: number = 0;
        while ((numPopped < popCount) && (this.comments.length > 0)) {
            let poppedComment: CommentInfo = this.comments.pop();
            poppedComments.push(poppedComment);
            numPopped++;
        }

        return poppedComments;
    }

    public selectComment(comment: CommentInfo): void {
        let selectedComment = this.SelectedComment;
        if (selectedComment != null)
            selectedComment.isSelected = false;

        comment.isSelected = true;
    }

    public deleteComment(commentToDelete: ICommentInfo): ICommentInfo {
        this.comments = this.comments.filter(c => c.clientId != commentToDelete.clientId);
        return commentToDelete;
    }
    public deleteCommentById(clientId: number): ICommentInfo {
        let deletedComment = this.comments.find(c => c.clientId == clientId);
        this.comments = this.comments.filter(c => c.clientId != clientId);
        return deletedComment;
    }

    public deselectAllComments(): void {
        for (let index: number = 0; index < this.comments.length; index++) {
            let comment: ICommentInfo = this.comments[index];
            comment.isSelected = false;
        }
    }

    public addViewableByToSelectedComment(userOrGroupData: PickerItem): void {
        let selectedComment = this.SelectedComment;

        if (selectedComment != null) {
            let userType: UserTypeEnum = (userOrGroupData.isUser ? UserTypeEnum.User : UserTypeEnum.Group);
            let viewableBy: CommentUserOrGroupInfo = new CommentUserOrGroupInfo(userType, userOrGroupData.key, userOrGroupData.displayName);
            selectedComment.viewableByUsersAndGroups.push(viewableBy);
        }
    }

    public addViewableByToComment(userOrGroupData: PickerItem, comment: ICommentInfo): void {
        let userType: UserTypeEnum = (userOrGroupData.isUser ? UserTypeEnum.User : UserTypeEnum.Group);
        let viewableBy: CommentUserOrGroupInfo = new CommentUserOrGroupInfo(userType, userOrGroupData.key, userOrGroupData.displayName);
        comment.viewableByUsersAndGroups.push(viewableBy);
    }

    public deleteViewableBy(comment: ICommentInfo, viewableBy: IUserOrGroupInfo): void {
        if (comment.viewableByUsersAndGroups != null) {
            comment.viewableByUsersAndGroups = comment.viewableByUsersAndGroups.filter(vb => vb.clientId != viewableBy.clientId);
        }
    }
    public moveViewableByUp(comment: ICommentInfo, viewableBy: IUserOrGroupInfo): void {
        this.moveViewableBy(comment, viewableBy, -1);
    }
    public moveViewableByDown(comment: ICommentInfo, viewableBy: IUserOrGroupInfo): void {
        this.moveViewableBy(comment, viewableBy, 1);
    }

    public isViewableByUser(comment: ICommentInfo, userMaxId: string): boolean {
        let containsUser: boolean = false;

        //let userObject: CommentUserOrGroupInfo = comment.viewableByUsersAndGroups.find(u => u.isUser && (u.userOrGroupId == userMaxId));
        let userObject: CommentUserOrGroupInfo = comment.viewableByUsersAndGroups.find(u => (u.userType == UserTypeEnum.User) && (u.userOrGroupId == userMaxId));
        containsUser = (userObject != null);

        return containsUser;
    }

    // Static method.
    public static deserializeData(formInstanceElement: FormInstanceElement): CommentsFormFieldData {
        let data: CommentsFormFieldData = null;

        if (formInstanceElement.textValue && (formInstanceElement.textValue.trim() != '')) {
            let hshData: any = JSON.parse(formInstanceElement.textValue);
            data = plainToClass(CommentsFormFieldData, hshData);
        } else
            data = new CommentsFormFieldData();

        return data;
    }

    // Helper methodes.
    private moveViewableBy(comment: ICommentInfo, viewableBy: IUserOrGroupInfo, indexChange: number): void {
        if (comment.viewableByUsersAndGroups != null) {
            let currentIndex = comment.viewableByUsersAndGroups.indexOf(viewableBy);
            let removedViewableBy = comment.viewableByUsersAndGroups.splice(currentIndex, 1);
            comment.viewableByUsersAndGroups.splice(currentIndex + indexChange, 0, removedViewableBy[0]);
        }
    }
}
