import { Type } from 'class-transformer';

import { IViewModel } from '../../interfaces/view-model.interface';
import { FormField } from './form-field.model';
import { GridRowViewModel } from './grid-row.model';
//import { AttachmentReference } from './attachment-reference.model';
import { AttachmentInfoWithUrls } from '../attachment/attachment-info-with-urls.model';

// Define the valid values for
// FormInstanceElement property 'valueType'.
export enum FormInstanceElementValueTypeEnum {
    TypeText = 'text',
    TypeInteger = 'integer',
    TypeDouble = 'double',
    TypeDecimal = 'decimal',
    TypeBoolean = 'boolean',

    TypeMultiBoolean = 'multiBoolean',
    TypeMultiText = 'multiText',
    TypeGridRows = 'gridRows'
}

export class FormInstanceElement implements IViewModel {
    public id: number = 0;

    public modifiedBy: string;
    public modifiedByUserName: string;
    public modifiedDate: Date;

    public formInstanceId: number = -1;
    public formFieldId: number = -1;

    public parentFormInstanceElementId: number = -1;
    public formField: FormField;
    public formFieldName: string;

    //VNEXT-951: KLW - Changes to bypass leave site pop-up warning
    private _bypassSitePopUpWarning: boolean = false;

    public valueType: string;
    public get ValueType(): string {
        return this.valueType;
    }
    public set ValueType(val: string) {
        this.valueType = val;
    }

    public intValue: number = 0;
    public get IntValue(): number {
        return this.intValue;
    }
    public set IntValue(val: number) {
        this.intValue = val;
    }
    public doubleValue: number = 0.0;
    public get DoubleValue(): number {
        return this.doubleValue;
    }
    public set DoubleValue(val: number) {
        this.doubleValue = val;
    }

    public decimalValue: number = 0.0;
    public get DecimalValue(): number {
        return this.decimalValue;
    }
    public set DecimalValue(val: number) {
        this.decimalValue = val;
    }
    // Define a transient/client-only field used by the decimal form field.
    public transientOriginalDecimalValue: number;

    public customValue: string = null;
    public get CustomValue(): string {
        return this.customValue;
    }
    public set CustomValue(val: string) {
        this.customValue = val;
    }
    public textValue: string = null;
    public get TextValue(): string {
        return this.textValue;
    }
    public set TextValue(val: string) {
        this.textValue = val;
    }

    public booleanValue: boolean = false;
    public get BooleanValue(): boolean {
        return this.booleanValue;
    }
    public set BooleanValue(val: boolean) {
        this.booleanValue = val;
    }

    public isDeleted: boolean = false; // Added 12-30-2020.

    public isGridData: boolean = false; // Added for VNEXT-917 - to indicate that a cell's data in a Home Page comes from a Grid

    public transientGridCascadingDropDownName: string;

    //public formField: FormField;
    //public formInstance: FormInstance
    // Child element (for the grid form field).
    //public childElements: IRowCellDataHash[];

    // 12-31-2020:  changed the name of the following property from childElements
    //              to childGridRows to avoid confusion now that this class also
    //              contains new member childFormInstanceElements.
    public totalChildGridRows: number;
    @Type(() => GridRowViewModel)
    public childGridRows: GridRowViewModel[];

    // 03-04-2021 note:  replaced AttachmentReference
    //                   with AttachmentInfoWithUrls.
    /*
    @Type(() => AttachmentReference)
    public attachmentReferences: AttachmentReference[];
    */
    @Type(() => AttachmentInfoWithUrls)
    public attachmentDetails: AttachmentInfoWithUrls[];

    @Type(() => FormInstanceElement)
    public childFormInstanceElements: FormInstanceElement[];

    // 02-22-2021 note:  added the following properties for footnotes.
    public totalFootnoteRows: number;
    @Type(() => GridRowViewModel)
    public childFootnoteRows: GridRowViewModel[];
    // End 02-22-2021 new footnote properties.

    // Define transient data (not saved in the database).
    //public transientInitializationCompleted: boolean = true;
    public transientValuesHash: any = {};
    //public transientFormField: any = null; // If assigned, this will be an instance of class FormField.
    //public transientFormInstanceElementWrapper: any = null; // If assigned, this will be an instance of class GridFormInstanceElementWrapper.
    //public transientColumnId: number; // This field is used by the grid form field.
    public transientInSetupFlag: boolean = false;
    //public transientValueSetFlag: boolean = false;
    private transientUserSetValueFlag: boolean = false;

    // Define methods related to users changing data.
    public set UserUpdatedData(bUpdatedParam: boolean) {
        this.transientUserSetValueFlag = bUpdatedParam;
    }

    public get UserUpdatedData(): boolean {
        return (this.transientUserSetValueFlag);
    }
 
    //VNEXT-951: KLW - Changes to bypass leave site pop-up warning
    public set BypassSitePopUpWarning(value: boolean) {
        this._bypassSitePopUpWarning = value;
    }

    public get BypassSitePopUpWarning(): boolean {
        return this._bypassSitePopUpWarning;
    }

    // Define helper methods.
    public toNumber(formField: FormField, propName: string): number {
        let property: number = <number>this[propName];

        return (property != null ? property : 0);
    }

    // Load from de-serialized network data.
    public static loadFromDeserializedNetworkData(data: FormInstanceElement): FormInstanceElement {
        let formInstanceElement: FormInstanceElement = new FormInstanceElement();

        formInstanceElement.loadFromDeserializedNetworkData(data);

        return (formInstanceElement);
    }

    public copyFieldValuesFrom(sourceFormInstanceElement: FormInstanceElement): void {
        this.textValue = sourceFormInstanceElement.textValue;
        this.intValue = sourceFormInstanceElement.intValue;
        this.decimalValue = sourceFormInstanceElement.decimalValue;
        this.booleanValue = sourceFormInstanceElement.booleanValue;
    }

    public clone(): FormInstanceElement {
        let clone: FormInstanceElement = new FormInstanceElement();

        clone.formFieldId = this.formFieldId;

        clone.valueType = this.valueType;

        clone.textValue = this.textValue;
        clone.intValue = this.intValue;
        clone.decimalValue = this.decimalValue;
        clone.booleanValue = this.booleanValue;

        return clone;
    }
    public copy(sourceFormInstanceElement: FormInstanceElement): void {
        this.valueType = sourceFormInstanceElement.valueType;

        this.textValue = sourceFormInstanceElement.textValue;
        this.intValue = sourceFormInstanceElement.intValue;
        this.decimalValue = sourceFormInstanceElement.decimalValue;
        this.booleanValue = sourceFormInstanceElement.booleanValue;
    }

    // Helper methods.
    private loadFromDeserializedNetworkData(data: FormInstanceElement): void {
        this.id = data.id;

        this.formInstanceId = data.formInstanceId;
        this.formFieldId = data.formFieldId;

        this.valueType = data.valueType;

        this.intValue = data.intValue;
        this.doubleValue = data.doubleValue;
        this.decimalValue = data.decimalValue;
        this.customValue = data.customValue;
        this.textValue = data.textValue;
        this.booleanValue = data.booleanValue;

        this.totalChildGridRows = data.totalChildGridRows;
        this.childGridRows = data.childGridRows;

        //TEAMS-424: KLW - Assign the ChildFootnoteRows and TotalFootnoteRows
        this.childFootnoteRows = data.childFootnoteRows;
        this.totalFootnoteRows = data.totalFootnoteRows;

        this.childFormInstanceElements = data.childFormInstanceElements;

        //TEAMS-424: KLW - Attachments from the api call do not have an id associated with the object, for now have to set it through this code
        if (data.attachmentDetails) {
            let id = 0;

            this.attachmentDetails = [];
            data.attachmentDetails.forEach(x => {
                x.id = id;
                this.attachmentDetails.push(x);
                id++;
            });
        }

        return;
    }
}
