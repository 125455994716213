import { plainToClass } from "class-transformer";

import { CascadingDropDownFormFieldConfig } from './cascading-dropdown-config.model';
import { HeaderValuePair } from '../csv-data/header-value-pair.model';
import { FormInstanceElement } from '../form-builder/form-instance-element.model';

// Note:  defining class CachedDropDownValuesForHeader to make it a bit simpler to work with an array of arrays.
export class CachedDropDownValuesForHeader {
    public headerIndex: number = -1;                 // Will be replaced by a valid option.
    public previousHeaderSelectedIndex: number = -1; // Will be replaced by a valid option.
    public headerValuePairs: HeaderValuePair[] = null;

    public constructor(headerIndex: number) {
        this.headerIndex = headerIndex;
    }
}

export class CascadingDropDownFormFieldData {
    // Properties.
    public dropDownHeaders: string[];
    public _dropDownValues: number[];
    public dropDownTextValues: string[]; // Per VNEXT-409:  save dropdown values as text, not a numeric index.
    public dropDownSavedValues: string[]; // pharv, 8/4/22 - added for VNEXT-320
    public formInstanceElements: FormInstanceElement[]; // Note:  this is presently only used by the grid-only cascading dropdown form field component class.
    public lastHeaderSelectedIndex: number = -1

    public cachedHeaders: CachedDropDownValuesForHeader[];

    // Constructor.
    public constructor() {
        return;
    }

    // Instance methods.
    public emptyCache(config: CascadingDropDownFormFieldConfig): void {
        if (config != null) {
            this.cachedHeaders = [];
            for (let index: number = 0; index < config.dropDownHeaders.length; index++)
                this.cachedHeaders.push(new CachedDropDownValuesForHeader(index));
        }
    }
    public clearCacheValuesPastHeader(headerIndex: number): void {
        for (let index: number = headerIndex; index < this.dropDownHeaders.length; index++)
            this.cachedHeaders.push(new CachedDropDownValuesForHeader(index));
    }

    // Getter/setter methods.
    private numberOfDropDownValuesGets: number = 0;
    public get dropDownValues(): number[] {
        this.numberOfDropDownValuesGets++;
        //console.log(`dropDownValues(${this.numberOfDropDownValuesGets}) ...`);
        let a = 1;
        if ((this._dropDownValues != null) && (this._dropDownValues.length >= 1) && (this._dropDownValues[0] == 1)) {
            //console.log(`dropDownValues(${this.numberOfDropDownValuesGets}):  VALUE CHANGED.`);
            a = 2;
        }
            
        return this._dropDownValues;
    }
    public set dropDownValues(value: number[]) {
        this._dropDownValues = value;
    }
    public setDropDownValue(index: number, value: number): void {
        if ((this._dropDownValues != null) && (this._dropDownValues.length > index))
            this._dropDownValues[index] = value;
    }
    public setDropDownTextValue(index: number, value: string): void {
        if ((this.dropDownTextValues != null) && (this.dropDownTextValues.length > index))
            this.dropDownTextValues[index] = value;
    }

    // Static method.
    public static deserializeDropDownData(textValue: string, config: CascadingDropDownFormFieldConfig): CascadingDropDownFormFieldData {
        let hshData: any = JSON.parse(textValue);
        if (hshData.formInstanceElements != null)
            hshData.formInstanceElements = null;
        let data: CascadingDropDownFormFieldData = plainToClass(CascadingDropDownFormFieldData, hshData);
        data.emptyCache(config);
        data.formInstanceElements = [];
        data.cachedHeaders = [];

        // Did the configuration change?
        if (config.dropDownHeaders != null) {
            if ((config.dropDownHeaders.length > data.dropDownHeaders.length) ||
                (config.dropDownHeaders.length > data.dropDownValues.length) ||
                (config.dropDownHeaders.length > data.dropDownTextValues.length) ||
                (config.dropDownHeaders.length > data.dropDownSavedValues.length) ||
                (config.dropDownHeaders.length > data.cachedHeaders.length) ||
                (config.dropDownHeaders.length > data.formInstanceElements.length))
            {
                for (let index: number = data.dropDownHeaders.length; index < config.dropDownHeaders.length; index++)
                    data.dropDownHeaders.push('');
                for (let index: number = data.dropDownValues.length; index < config.dropDownHeaders.length; index++)
                    data.dropDownValues.push(0);
                for (let index: number = data.dropDownTextValues.length; index < config.dropDownHeaders.length; index++)
                    data.dropDownTextValues.push('');
                for (let index: number = data.dropDownSavedValues.length; index < config.dropDownHeaders.length; index++)
                    data.dropDownSavedValues.push('');
                for (let index: number = data.cachedHeaders.length; index < config.dropDownHeaders.length; index++)
                    data.cachedHeaders.push(new CachedDropDownValuesForHeader(index));
                for (let index: number = data.formInstanceElements.length; index < config.dropDownHeaders.length; index++)
                    data.formInstanceElements.push(new FormInstanceElement());
            } else if ((config.dropDownHeaders.length < data.dropDownHeaders.length) ||
                (config.dropDownHeaders.length < data.dropDownValues.length) ||
                (config.dropDownHeaders.length < data.dropDownTextValues.length) ||
                (config.dropDownHeaders.length < data.dropDownSavedValues.length) ||
                (config.dropDownHeaders.length < data.cachedHeaders.length) ||
                (config.dropDownHeaders.length < data.formInstanceElements.length))
            {
                while (data.dropDownHeaders.length > config.dropDownHeaders.length)
                    data.dropDownHeaders.pop();
                while (data.dropDownValues.length > config.dropDownHeaders.length)
                    data.dropDownValues.pop();
                while (data.dropDownTextValues.length > config.dropDownHeaders.length)
                    data.dropDownTextValues.pop();
                while (data.dropDownSavedValues.length > config.dropDownHeaders.length)
                    data.dropDownSavedValues.pop();
                while (data.cachedHeaders.length > config.dropDownHeaders.length)
                    data.cachedHeaders.pop();
                while (data.formInstanceElements.length > config.dropDownHeaders.length)
                    data.formInstanceElements.pop();
            }
        }

        return data;
    }

    public static createEmptyDataFromConfig(config: CascadingDropDownFormFieldConfig): CascadingDropDownFormFieldData {
        let data: CascadingDropDownFormFieldData = new CascadingDropDownFormFieldData();

        if (config != null) {
            data.dropDownHeaders = config.dropDownHeaders;
            data.dropDownValues = CascadingDropDownFormFieldData.createDropDownValuesFromHeaders(config.dropDownHeaders);
            data.dropDownTextValues = CascadingDropDownFormFieldData.createDropDownTextValuesFromHeaders(config.dropDownHeaders); // new for VNEXT-320
            data.dropDownSavedValues = CascadingDropDownFormFieldData.createDropDownSavedValuesFromHeaders(config.dropDownHeaders);
            data.formInstanceElements = CascadingDropDownFormFieldData.createDropDownFormInstanceElementsFromHeaders(config.dropDownHeaders);

            // Note:  property 'formInstanceElements' is only being used by the grid version of the cascading dropdown
            //        component class, so we will probably want to move the property into a derived class.
            //data.formInstanceElements = [];
            data.cachedHeaders = [];
            for (let index: number = 0; index < config.dropDownHeaders.length; index++) {
                //let blankDummyFormInstanceElement = new FormInstanceElement();
                //data.formInstanceElements.push(blankDummyFormInstanceElement);

                data.cachedHeaders.push(new CachedDropDownValuesForHeader(index));
            }
        } else {
            console.log('createEmptyDataFromConfig():  config is null.');
        }

        return data;
    }

    private static createDropDownValuesFromHeaders(headers: string[]): number[] {
        let dropDownValues: number[] = [];

        for (let iHeader: number = 0; iHeader < headers.length; iHeader++) 
            dropDownValues.push(0);

        return (dropDownValues);
    }
    private static createDropDownTextValuesFromHeaders(headers: string[]): string[] {
        let dropDownTextValues: string[] = [];

        for (let iHeader: number = 0; iHeader < headers.length; iHeader++)
            dropDownTextValues.push('');

        return dropDownTextValues;
    }
    private static createDropDownFormInstanceElementsFromHeaders(headers: string[]): FormInstanceElement[] {
        let formInstanceElements: FormInstanceElement[] = [];

        for (let iHeader: number = 0; iHeader < headers.length; iHeader++)
            formInstanceElements.push(new FormInstanceElement());

        return formInstanceElements;
    }

    // Added for VNEXT-320 -- parralel the approach used for createDropDownValuesFromHeaders but setting nulls 
    public static createDropDownSavedValuesFromHeaders(headers: string[]): string[] {
        var dropDownValues: string[] = [];

        for (var iHeader: number = 0; iHeader < headers.length; iHeader++) {
            dropDownValues.push(null);
        }

        return (dropDownValues);
    }

    // Returns a string which is used to display the value of field when it is in view (rather than edit) mode
    public static getDisplayValue(config: CascadingDropDownFormFieldConfig, textValue: string): string {
        let displayValue: string = '';
        let currentLevel: number = 0;
        let currentHeaderValuePairs: HeaderValuePair[] = config.dropDownOptionsAsHierarchy;

        if (config != null) {
            let myData: CascadingDropDownFormFieldData = JSON.parse(textValue);
            if ((myData != null) && (myData.dropDownValues != null) && (myData.dropDownValues.length > 0)) {
                for (let valueLevel: number = 0; valueLevel < myData.dropDownValues.length; valueLevel++) {
                    let iValue: number = myData.dropDownValues[valueLevel];
                    let savedValue: string = myData.dropDownSavedValues[valueLevel];
                    let iValueIndex: number = iValue - 1; // Adjust for one-based index.

                    if ((iValue > 0) && (currentHeaderValuePairs != null) && (iValueIndex < currentHeaderValuePairs.length)) {
                        let valuePair: HeaderValuePair = currentHeaderValuePairs[iValueIndex];
                        let header: string = valuePair.Header;
                        let value: string = valuePair.ValueText;

                        // pharv - 01/28-2022 - do some html formatting to better layout
                        // the selected values (and with headings) when displayed in a grid
                        if (currentLevel > 0)
                            displayValue += '<br/>';
                        displayValue += `<strong>${header}</strong>: ${savedValue || value}`;

                        currentLevel++;
                        currentHeaderValuePairs = valuePair.ChildValuePairs;
                    } else {
                        break; // Stop processing values.
                    } // if-else
                } // for
            } // if
        }

        return displayValue;
    }
}
