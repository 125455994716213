<div class="add-member-control-wrapper">
    <form [formGroup]="this.AddMemberFormGroup"
          class="picker-form">
        <mat-form-field appearance="outline"
                        [floatLabel]="'always'"
                        [ngClass]="this.PickerDisabled ? 'disabled-add-member-mat-form-field' : 'add-member-mat-form-field'"
                        style="{{this.MaxMemberStyle}}">
            <mat-label>{{this.UserGroupPickerLabel}}</mat-label>

            <input matInput
                   id="add-member-input"
                   formControlName="member"
                   [matAutocomplete]="addMemberAutoComplete"
                   cdkFocusInitial>

            <span *ngIf="this.ShowLoadingIndicator" matSuffix>
                <mat-spinner [diameter]="25"></mat-spinner>
            </span>

            <mat-autocomplete #addMemberAutoComplete
                              [panelWidth]="750"
                              [displayWith]="this.DisplayUserName">
                <mat-option *ngFor="let pickerItem of this.PickerItems | async"
                            [value]="pickerItem">
                    <span title="{{pickerItem.fullDisplay}}">{{pickerItem.fullDisplay}}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <button mat-flat-button matSuffix
                class="add-member-btn theme-primary-btn"
                style="{{this.AddButtonAdditionalStyle}}"
                color="primary"
                [disabled]="this.DisableAddButton"
                matTooltip="{{this.AddButtonFlyoverText}}"
                [matTooltipPosition]="this.TooltipPosition"
                (click)="this.addMembership()">
            {{this.AddButtonName}}
        </button>

        <!--VNEXT-614: KLW - Adding conditional to determine if control should be disabled-->
        <ng-container *ngIf="this.SecondButtonName != null">
            <button mat-flat-button matSuffix
                    class="add-member-btn theme-primary-btn"
                    style=""
                    color="primary"
                    [disabled]="this.DisableSecondButton || this.PickerDisabled"
                    matTooltip="{{this.SecondButtonFlyoverText}}"
                    [matTooltipPosition]="this.TooltipPosition"
                    (click)="this.addMember()">
                {{this.SecondButtonName}}
            </button>
        </ng-container>
    </form>
</div>
