import { IJobSummaryText } from './i-job-summary-text';

export class MetricsBase implements IJobSummaryText {
    // Properties.
    // Logs.
    public progressLog: string;
    public warningLog: string;
    public errorLog: string;

    // Job summary message.
    public jobSummaryMessage: string;

    // Implement IJobSummaryText.
    public getProgressLog(): string {
        return (this.progressLog);
    }
    public getWarningLog(): string {
        return (this.warningLog);
    }
    public getErrorLog(): string {
        return (this.errorLog);
    }

    public getJobSummaryText(): string {
        return (this.jobSummaryMessage);
    }

    // Constructor.
    protected constructor(readonly propertyNames: string[], jsonData: Object) {
        if (propertyNames != null) {
            for (let iProp: number = 0; iProp < propertyNames.length; iProp++) {
                let propertyName: string = propertyNames[iProp];
                let capPropertyName: string = MetricsBase.capitalizeFirstLetter(propertyName);

                if (jsonData[capPropertyName] != null) {
                    this[propertyName] = jsonData[capPropertyName];
                }
            }
        }
    }

    // Private helper methods.
    private static capitalizeFirstLetter(str: string): string {
        return (str.charAt(0).toUpperCase() + str.slice(1));
    }
}
