import { FormField, SecondaryGridCascadingFieldTransientData } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { DefaultFieldDefinitionClientLogic } from './default-field-def-client-logic';
import { GridCascadingDropDownFieldType } from '../form-field-types';
import { CascadingDropDownFormFieldConfig } from '../../cascading-dropdown/cascading-dropdown-config.model';
import { TextFieldDefinitionLogicBase } from './text-logic-base';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';

export class GridCascadingDropdownFieldDefLogic extends DefaultFieldDefinitionClientLogic {
    public constructor() {
        super();
    };

    // Override two methods:
    //
    //     hasVirtualSiblingFormFieldsFor();
    //     getVirtualSiblingFormFieldsFor().
    public hasVirtualSiblingFormFieldsFor(formField: FormField): boolean {
        let hasVirtualFields: boolean = false;

        if ((formField.fieldDefinitionClassName == GridCascadingDropDownFieldType) &&
            (formField.jsonConfig != null) && (formField.jsonConfig.trim() != '')) {
            let cascadingDropdownConfig = CascadingDropDownFormFieldConfig.parseJsonConfig(formField.jsonConfig);

            hasVirtualFields = ((cascadingDropdownConfig != null) && (cascadingDropdownConfig.NumLevels > 0));
        }

        return hasVirtualFields;
    }
    public getVirtualSiblingFormFieldsFor(formField: FormField): FormField[] {
        let virtualFormFields: FormField[] = [];

        if ((formField.fieldDefinitionClassName == GridCascadingDropDownFieldType) &&
            (formField.jsonConfig != null) && (formField.jsonConfig.trim() != '')) {
            let cascadingDropdownConfig = CascadingDropDownFormFieldConfig.parseJsonConfig(formField.jsonConfig);

            if ((cascadingDropdownConfig != null) && (cascadingDropdownConfig.NumLevels > 0)) {
                for (let index: number = 1; index < cascadingDropdownConfig.DropDownHeaders.length; index++) {
                    let header: string = cascadingDropdownConfig.DropDownHeaders[index];

                    let virtualFormField: FormField = new FormField();

                    virtualFormField.transientFieldIsVirtual = true;
                    virtualFormField.primaryFieldName = formField.name;
                    virtualFormField.fieldOrder = formField.fieldOrder;
                    virtualFormField.fieldSecondaryOrder = index;
                    //virtualFormField.transientCascadingDropdownConfig = cascadingDropdownConfig;
                    virtualFormField.name = `${formField.name}_vff${index}`;
                    virtualFormField.displayName = header;
                    virtualFormField.fieldDefinitionClassName = GridCascadingDropDownFieldType;

                    // Set transient data.
                    //
                    // TO DO:  put all transient data into a single class.
                    if (virtualFormField.secondaryGridCascadingFieldTransientData == null)
                        //virtualFormField.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData();
                        virtualFormField.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData(null, null, null);
                    //virtualFormField.transientCascadingDropdownConfig = formField.transientCascadingDropdownConfig;
                    //virtualFormField.transientCascadingDropdownData = formField.transientCascadingDropdownData;
                    virtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig = (formField.secondaryGridCascadingFieldTransientData != null ? formField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig : null);
                    if (virtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig == null)
                        virtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig = cascadingDropdownConfig;
                    virtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData = (formField.secondaryGridCascadingFieldTransientData != null ? formField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData : null);

                    virtualFormFields.push(virtualFormField);
                    /*
                    for (let priorIndex: number = 0; priorIndex < priorFormFields.length; priorIndex++) {
                        let priorFormField: FormField = priorFormFields[priorIndex];
                        if (priorFormField.transientSecondaryFormFields == null)
                            priorFormField.transientSecondaryFormFields = [];
                        priorFormField.transientSecondaryFormFields.push(virtualFormField);
                    }
                    */
                } // for

                //formField.transientSecondaryFormFields = virtualFormFields;
            }
        }

        return virtualFormFields;
    }

    public displayDeleteIconInGridFor(formField: FormField): boolean {
        let display: boolean = (formField.primaryFieldName == null);
        return display;
    }

    // Sorting.
    public compareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        let compareResult: number = TextFieldDefinitionLogicBase.staticCompareAscendingFor(formField, value1, value2);
        return compareResult;
    }

    // pasteDataForInto():  only allow the pasting of values that are valid for a given form field.
    public pasteDataForInto(formField: FormField, formInstanceElement: FormInstanceElement, pasteValue: string): void {
        // Note:  this method needs to prevent pasting in data to a fixed column/column of fixed values if one exists.

        // TO DO:  CODE THIS METHOD.
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        return (formInstanceElementParam ? formInstanceElementParam.textValue : null);
    }

    public filterType(): string {
        return 'text';
    }

    public isFilterable(): boolean {
        return true;
    }
}
