import { Component, OnInit, SimpleChanges, Input, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
//import { MenuItem, TreeNode } from 'primeng/api';
//import { Tree } from 'primeng/tree';
import { TooltipPosition } from '@angular/material/tooltip';

//import { HtmlElementInfo } from '../../models/component-scripting/html-element-info.model';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { ComponentHierarchyService, IComponentHierarchyChanged } from '../../services/component-hierarchy.service';
//import { ElementTypeMetadata } from '../../models/component-scripting/element-type-metadata.model';
import { HtmlPropertyValue } from '../../models/component-scripting/html-property-value.model';
import { HtmlInputValue } from '../../models/component-scripting/html-input-value.model';
//import { HtmlElementTypeService } from '../../services/html-element-type.service';
import { EditValueDialog, EditValueResult } from '../../dialogs/edit-value/edit-value.dialog';
//import { DisplayContentData, DisplayContentDialog, DisplayContentDialogInitData } from '../../dialogs/display-content/display-content.dialog';
import { UITestActionRecorderService } from '../../services/ui-test-action-recorder.service';
//import { UITestExecutionService } from '../../services/ui-test-execution.service';
//import { ServiceClassCatalogueService, ServiceClassInfo } from '../../services/service-class-catalogue.service';
import { ISelectedElementInfo } from '../../models/component-scripting/iselected-element-info.interface';
import { JQueryBrowserDriverService } from '../../services/jquery-browser-driver.service';

@Component({
    selector: 'app-selected-node-element',
    templateUrl: './selected-node-element.component.html',
    styleUrls: ['./selected-node-element.component.scss']
})
export class SelectedNodeElementComponent implements OnInit {
    @Input() selectedComponentInfo: ISelectedElementInfo = null;

    public constructor(private componentHierarchyService: ComponentHierarchyService,
        private jqueryBrowserDriverService: JQueryBrowserDriverService,
        private uiTestActionRecorderService: UITestActionRecorderService,
        private dialog: MatDialog) {
    }

    public ngOnInit(): void {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedComponentInfo'] != null) {
            let testableComponent: ITestableComponent = <ITestableComponent><unknown>changes['selectedComponentInfo'];
            //this.handleComponentSelected(testableComponent);
        }
    }

    // Define/implement methods called by my HTML code.
    public get ElementSelected(): boolean {
        return this.selectedComponentInfo != null ? this.selectedComponentInfo.selectedElement != null : false;
    }
    public get SelectedElementType(): string {
        return this.selectedComponentInfo.selectedElement != null ? this.selectedComponentInfo.selectedElement.elementType : null;
    }
    public get SelectedElementName(): string {
        return this.selectedComponentInfo.selectedElement != null ? this.selectedComponentInfo.selectedElement.elementTitle : null;
    }
    public set SelectedElementName(values: string) {
        this.selectedComponentInfo.selectedElementName = values[0];
        /*
        let elementType: string = this.selectedComponentInfo.componentElementTypes[this.selectedComponentInfo.selectedElementTypeIndex];
        let elements: HtmlElementInfo[] = this.selectedComponentInfo.mapOfElementsByType[elementType];
        this.selectedComponentInfo.selectedElement = elements.find(e => e.elementTitle == this.selectedComponentInfo.selectedElementName);
        */
    }
    public get SelectedElementHasProperties(): boolean {
        //let metadata = this.htmlElementTypeService.getMetadataForElementType(this.selectedComponentInfo.selectedElement.elementType);
        let hasProperties: boolean = this.selectedComponentInfo.selectedElement.elementTypeMetadata.hasProperties(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);

        if (hasProperties) {
            let properties: HtmlPropertyValue[] = this.selectedComponentInfo.selectedElement.elementTypeMetadata.getProperties(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);
            if ((properties == null) || (properties.length == 0))
                hasProperties = false;
        }

        return hasProperties;
    }
    public get CachedSelectedElementHasProperties(): boolean {
        return this.selectedComponentInfo.selectedElementHasProperties;
    }
    public get SelectedElementProperties(): HtmlPropertyValue[] {
        let properties: HtmlPropertyValue[] = [];

        if (this.selectedComponentInfo.selectedElement.elementTypeMetadata.hasProperties(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element))
            properties = this.selectedComponentInfo.selectedElement.elementTypeMetadata.getProperties(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);

        return properties;
    }
    public get SelectedElementHasActions(): boolean {
        return this.selectedComponentInfo.selectedElement.elementTypeMetadata.hasActions(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);
    }
    public get CachedSelectedElementHasActions(): boolean {
        return this.selectedComponentInfo.selectedElementHasActions;
    }
    public get SelectedElementActions(): string[] {
        let actions: string[] = [];

        if (this.selectedComponentInfo.selectedElement.elementTypeMetadata.hasActions(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element))
            actions = this.selectedComponentInfo.selectedElement.elementTypeMetadata.getActions(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element)

        return actions;
    }
    public get SelectedElementHasInputs(): boolean {
        return this.selectedComponentInfo.selectedElement.elementTypeMetadata.hasInputs(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);
    }
    public get CachedSelectedElementHasInputs(): boolean {
        return this.selectedComponentInfo.selectedElementHasInputs;
    }
    public get SelectedElementInputs(): HtmlInputValue[] {
        let inputs: HtmlInputValue[] = [];

        if (this.selectedComponentInfo.selectedElement.elementTypeMetadata.hasInputs(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element))
            inputs = this.selectedComponentInfo.selectedElement.elementTypeMetadata.getInputs(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);

        return inputs;
    }
    public get SelectedElementInput(): HtmlInputValue {
        let inputs = this.selectedComponentInfo.selectedElement.elementTypeMetadata.getInputs(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);
        return inputs[0];
    }
    public get SelectedElementInputValue(): string {
        let inputs = this.selectedComponentInfo.selectedElement.elementTypeMetadata.getInputs(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);
        return inputs[0].inputValue;
    }
    public getInputName(input: HtmlInputValue): string {
        return input.inputTitle;
    }
    /*
    public getInputValue(input: HtmlInputValue): string {
        return input.inputValue;
    }
    */
    public get TooltipPosition(): TooltipPosition {
        return 'right';
    }
    public get SelectedElementIsInput(): boolean {
        let isInput: boolean = false;

        if ((this.selectedComponentInfo != null) && (this.selectedComponentInfo.selectedElement != null))
            isInput = this.selectedComponentInfo.selectedElement.elementTypeMetadata.hasInputs(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);

        return isInput;
    }

    // Handle control events.
    public onSelectedElementActionClicked(): void {
        if ((this.selectedComponentInfo != null) && (this.selectedComponentInfo.selectedElement != null) && (this.selectedComponentInfo.selectedElement.element != null)) {
            //this.uiTestActionRecorderService.elementActionClicked(this.selectedComponentInfo.selectedElement.elementTitle);
            //this.selectedComponentInfo.selectedElement.elementTypeMetadata.recordActionClick(this.uiTestActionRecorderService, this.selectedComponentInfo.selectedElement);

            let getClickableElementFunction: (element: object) => object = this.selectedComponentInfo.selectedElement.additionalElementInfo != null ? this.selectedComponentInfo.selectedElement.additionalElementInfo.getClickableElementFunction : null;
            if ((this.selectedComponentInfo.selectedElement.additionalElementInfo != null) && (this.selectedComponentInfo.selectedElement.additionalElementInfo.useDoubleClick != null) && (this.selectedComponentInfo.selectedElement.additionalElementInfo.useDoubleClick == true)) {
                this.selectedComponentInfo.selectedElement.elementTypeMetadata.doubleClick(this.jqueryBrowserDriverService, this.selectedComponentInfo.component, this.selectedComponentInfo.selectedElement.element, getClickableElementFunction);
            }
            else {
                //this.selectedComponentInfo.selectedElement.elementTypeMetadata.click(this.jqueryBrowserDriverService, this.selectedComponentInfo.component, this.selectedComponentInfo.selectedElement.element, getClickableElementFunction);
                this.selectedComponentInfo.selectedElement.elementTypeMetadata.click(this.jqueryBrowserDriverService, this.uiTestActionRecorderService, this.selectedComponentInfo.component, this.selectedComponentInfo.selectedElement, this.selectedComponentInfo.selectedElement.element, getClickableElementFunction);
            }                
        }
    }
    /*
    public onSelectedElementActionDoubleClicked(): void {
        if ((this.selectedComponentInfo != null) && (this.selectedComponentInfo.selectedElement != null) && (this.selectedComponentInfo.selectedElement.element != null)) {
            let message: string = `click('${this.selectedComponentInfo.selectedElement.elementTitle}');`;
            this.componentHierarchyService.logTestAction(message);

            this.selectedComponentInfo.selectedElement.elementTypeMetadata.doubleClick(this.jqueryBrowserDriverService, this.selectedComponentInfo.component, this.selectedComponentInfo.selectedElement.element);
        }
    }
    */

    public editSelectedInputValue(): void {
        let inputs: HtmlInputValue[] = this.selectedComponentInfo.selectedElement.elementTypeMetadata.getInputs(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);

        if ((inputs != null) && (inputs.length > 0)) {
            let input: HtmlInputValue = inputs[0];

            this.editInputValue(input);
        }
    }

    // Helper methods.
    private editInputValue(input: HtmlInputValue): void {
        let dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = true;
        dialogConfig.width = '400px';
        dialogConfig.height = '250px';
        dialogConfig.data = input.inputValue;

        const dialogRef = this.dialog.open(EditValueDialog, dialogConfig);
        dialogRef.afterClosed().subscribe(updatedValue => {
            //input.inputValue = updatedValue;
            input.inputValue = updatedValue.value;

            //this.selectedComponentInfo.selectedElement.elementTypeMetadata.setValue(this.jqueryBrowserDriverService, this.selectedComponentInfo.component, this.selectedComponentInfo.selectedElement.element, updatedValue.value);
            this.selectedComponentInfo.selectedElement.elementTypeMetadata.setValue(this.jqueryBrowserDriverService, this.uiTestActionRecorderService, this.selectedComponentInfo.component, this.selectedComponentInfo.selectedElement, updatedValue.value);

            //this.uiTestActionRecorderService.setInputValue(this.selectedComponentInfo.selectedElement.elementTitle, this.selectedComponentInfo.selectedElement.elementTypeMetadata.metadataKey, updatedValue.value, false);
        });
    }

}
