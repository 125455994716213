import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Menu } from '../models/navigation/menu.model';
import { environment } from '../../../environments/environment';
import { ReturnStatement } from '@angular/compiler';

//import 'rxjs/add/operator/toPromise';

@Injectable()
export class MenuService {
    private serviceUrl = '';

    constructor(private http: HttpClient) {
        this.serviceUrl = environment.apiUrl + "api/collection/";
    }

    getAll(): Promise<Menu[]> {
        var url = this.serviceUrl;

        url = 'assets/data/menus.json';

        return this.http.get(url)
            .toPromise()
            .then((response: Menu[]) => {
                var navs: Menu[];
                navs = response;
                return navs;
            })
            .catch(this.handleError);
    }

    get(id): Promise<Menu> {
        var url = this.serviceUrl;

        url = 'assets/data/menus.json';

        return this.http.get(url)
            .toPromise()
            .then((response: Menu[]) => {
                var navs: Menu[];
                navs = response;
                var nav = navs.filter(x => x.id == id)[0];

                return nav;
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    public loadAppMenu(): Promise<Menu> {
        return this.get('app');
    }

    public loadCollectionMenu(): Promise<Menu> {
        return this.get('collection');
    }
}
