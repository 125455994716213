/*
 * Enumeration of icon file names, and what extensions they map to.
 * Please keep items alphabetical.
 *
 * How to implement https://developer.microsoft.com/en-us/fluentui#/styles/web/icons
 *
 * Mapping taken from https://raw.githubusercontent.com/microsoft/fluentui/master/packages/react-file-type-icons/src/FileTypeIconMap.ts
 *
 * Check what the icon looks like by using this link, just change the size (48) and the name (svg):
 * https://static2.sharepointonline.com/files/fabric/assets/item-types/48/archive.svg
 * 
 */

import { IconFormatEnum } from '../enums/icon-format.enum';

export interface IFileTypeIconMap {
    key: string;
    value: IFileTypeIcon;
}

export interface IFileTypeIcon {
    iconFormat: string;
    extensions?: string[];
    canpreview?: boolean;
}

export enum FabricIconSizes {
    SMALL = 16,
    MEDIUM = 48,
    LARGE = 96
}

export class FabricFileTypeIconMapping {

    public FileTypeIconMap: IFileTypeIconMap[] = [
        {
            key: 'accdb',
            value: {
                extensions: [
                    'accdb',
                    'mdb'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'archive',
            value: {
                extensions: [
                    '7z',
                    'ace',
                    'arc',
                    'arj',
                    'dmg',
                    'gz',
                    'iso',
                    'lzh',
                    'pkg',
                    'rar',
                    'sit',
                    'tgz',
                    'tar',
                    'z',
                    'zip'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'audio',
            value: {
                extensions: [
                    'aif',
                    'aiff',
                    'aac',
                    'alac',
                    'amr',
                    'ape',
                    'au',
                    'awb',
                    'dct',
                    'dss',
                    'dvf',
                    'flac',
                    'gsm',
                    'm4a',
                    'm4p',
                    'mid',
                    'mmf',
                    'mp3',
                    'oga',
                    'ra',
                    'rm',
                    'wav',
                    'wma',
                    'wv'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'calendar',
            value: {
                extensions: [
                    'ical',
                    'icalendar',
                    'ics',
                    'ifb',
                    'vcs'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'code',
            value: {
                extensions: [
                    'abap',
                    'ada',
                    'adp',
                    'ahk',
                    'as',
                    'as3',
                    'asc',
                    'ascx',
                    'asm',
                    'asp',
                    'awk',
                    'bash',
                    'bash_login',
                    'bash_logout',
                    'bash_profile',
                    'bashrc',
                    'bat',
                    'bib',
                    'bsh',
                    'build',
                    'builder',
                    'c',
                    'cbl',
                    'c++',
                    'capfile',
                    'cc',
                    'cfc',
                    'cfm',
                    'cfml',
                    'cl',
                    'clj',
                    'cls',
                    'cmake',
                    'cmd',
                    'coffee',
                    'config',
                    'cpp',
                    'cpt',
                    'cpy',
                    'cs',
                    'cshtml',
                    'cson',
                    'csproj',
                    'css',
                    'ctp',
                    'cxx',
                    'd',
                    'ddl',
                    'di',
                    'disco',
                    'dml',
                    'dtd',
                    'dtml',
                    'el',
                    'emakefile',
                    'erb',
                    'erl',
                    'f',
                    'f90',
                    'f95',
                    'fs',
                    'fsi',
                    'fsscript',
                    'fsx',
                    'gemfile',
                    'gemspec',
                    'gitconfig',
                    'go',
                    'groovy',
                    'gvy',
                    'h',
                    'h++',
                    'haml',
                    'handlebars',
                    'hbs',
                    'hcp',
                    'hh',
                    'hpp',
                    'hrl',
                    'hs',
                    'htc',
                    'hxx',
                    'idl',
                    'iim',
                    'inc',
                    'inf',
                    'ini',
                    'inl',
                    'ipp',
                    'irbrc',
                    'jade',
                    'jav',
                    'java',
                    'js',
                    'json',
                    'jsp',
                    'jsproj',
                    'jsx',
                    'l',
                    'less',
                    'lhs',
                    'lisp',
                    'log',
                    'lst',
                    'ltx',
                    'lua',
                    'm',
                    'mak',
                    'make',
                    'manifest',
                    'master',
                    'md',
                    'markdn',
                    'markdown',
                    'mdown',
                    'mkdn',
                    'ml',
                    'mli',
                    'mll',
                    'mly',
                    'mm',
                    'mud',
                    'nfo',
                    'opml',
                    'osascript',
                    'p',
                    'pas',
                    'patch',
                    'php',
                    'php2',
                    'php3',
                    'php4',
                    'php5',
                    'phtml',
                    'pl',
                    'pm',
                    'pod',
                    'pp',
                    'profile',
                    'ps1',
                    'ps1xml',
                    'psd1',
                    'psm1',
                    'pss',
                    'pt',
                    'py',
                    'pyw',
                    'r',
                    'rake',
                    'rb',
                    'rbx',
                    'rc',
                    'rdf',
                    're',
                    'reg',
                    'rest',
                    'resw',
                    'resx',
                    'rhtml',
                    'rjs',
                    'rprofile',
                    'rpy',
                    'rss',
                    'rst',
                    'ruby',
                    'rxml',
                    's',
                    'sass',
                    'scala',
                    'scm',
                    'sconscript',
                    'sconstruct',
                    'script',
                    'scss',
                    'sgml',
                    'sh',
                    'shtml',
                    'sml',
                    'svn-base',
                    'swift',
                    'sql',
                    'sty',
                    'tcl',
                    'tex',
                    'textile',
                    'tld',
                    'tli',
                    'tmpl',
                    'tpl',
                    'vb',
                    'vi',
                    'vim',
                    'vmg',
                    'webpart',
                    'wsp',
                    'wsdl',
                    'xhtml',
                    'xoml',
                    'xsd',
                    'xslt',
                    'yaml',
                    'yaws',
                    'yml',
                    'zsh',
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'contact',
            value: {
                extensions: [
                    'vcf'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'csv',
            value: {
                extensions: [
                    'csv'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON,
                canpreview: true
            } as IFileTypeIcon
        },
        {
            key: 'desktopfolder',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'docset',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'documentfolder',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'docx',
            value: {
                extensions: [
                    'doc', 'docm', 'docx', 'docb'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON,
                canpreview: true
            } as IFileTypeIcon
        },
        {
            key: 'dotx',
            value: {
                extensions: [
                    'dot', 'dotm', 'dotx'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'email',
            value: {
                extensions: [
                    'eml', 'msg', 'ost', 'pst'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'exe',
            value: {
                extensions: [
                    'application', 'appref-ms', 'apk', 'app', 'appx', 'exe', 'ipa', 'msi', 'xap'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'folder',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'font',
            value: {
                extensions: [
                    'ttf', 'otf', 'woff'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'fluid',
            value: {
                extensions: [
                    'b', 'fluid'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'genericfile',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'html',
            value: {
                extensions: [
                    'htm', 'html', 'mht'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'link',
            value: {
                extensions: [
                    'lnk', 'link', 'url', 'website', 'webloc'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'linkedfolder',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'listitem',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'splist',
            value: {
                extensions: [
                    'listitem'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'model',
            value: {
                extensions: [
                    '3ds',
                    '3mf',
                    'blend',
                    'cool',
                    'dae',
                    'df',
                    'dwfx',
                    'dwg',
                    'dxf',
                    'fbx',
                    'glb',
                    'gltf',
                    'holo',
                    'layer',
                    'layout',
                    'max',
                    'mcworld',
                    'mtl',
                    'obj',
                    'off',
                    'ply',
                    'skp',
                    'stp',
                    'stl',
                    't',
                    'thl',
                    'x',
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'mpp',
            value: {
                extensions: [
                    'mpp'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'mpt',
            value: {
                extensions: [
                    'mpt'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'multiple',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'one',
            value: {
                extensions: [
                    'note', 'one'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON,
                canpreview: true
            } as IFileTypeIcon
        },
        {
            key: 'onetoc',
            value: {
                extensions: [
                    'ms-one-stub', 'onetoc', 'onetoc2', 'onepkg'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON,
                canpreview: true
            } as IFileTypeIcon
        },
        {
            key: 'pbiapp',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'pdf',
            value: {
                extensions: [
                    'pdf'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'photo',
            value: {
                extensions: [
                    'arw',
                    'bmp',
                    'cr2',
                    'crw',
                    'dic',
                    'dicm',
                    'dcm',
                    'dcm30',
                    'dcr',
                    'dds',
                    'dib',
                    'dng',
                    'erf',
                    'gif',
                    'heic',
                    'heif',
                    'ico',
                    'jfi',
                    'jfif',
                    'jif',
                    'jpe',
                    'jpeg',
                    'jpg',
                    'kdc',
                    'mrw',
                    'nef',
                    'orf',
                    'pct',
                    'pict',
                    'png',
                    'pns',
                    'psb',
                    'psd',
                    'raw',
                    'tga',
                    'tif',
                    'tiff',
                    'wdp',
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'photo360',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'picturesfolder',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'potx',
            value: {
                extensions: [
                    'pot', 'potm', 'potx'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'powerbi',
            value: {
                extensions: [
                    'pbids', 'pbix'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'ppsx',
            value: {
                extensions: [
                    'pps', 'ppsm', 'ppsx'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON,
                canpreview: true
            } as IFileTypeIcon
        },
        {
            key: 'pptx',
            value: {
                extensions: [
                    'ppt', 'pptm', 'pptx', 'sldx', 'sldm'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON,
                canpreview: true
            } as IFileTypeIcon
        },
        {
            key: 'presentation',
            value: {
                extensions: [
                    'odp', 'gslides', 'key'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'pub',
            value: {
                extensions: [
                    'pub'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'spo',
            value: {
                extensions: [
                    'aspx', 'classifier'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'sponews',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'spreadsheet',
            value: {
                extensions: [
                    'odc', 'ods', 'gsheet', 'numbers', 'tsv'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'stream',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'rtf',
            value: {
                extensions: [
                    'epub', 'gdoc', 'odt', 'rtf', 'wri', 'pages'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON,
                canpreview: true
            } as IFileTypeIcon
        },
        {
            key: 'sharedfolder',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'sway',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'sysfile',
            value: {
                extensions: [
                    'bak',
                    'bin',
                    'cab',
                    'cache',
                    'cat',
                    'cer',
                    'class',
                    'dat',
                    'db',
                    'dbg',
                    'dl_',
                    'dll',
                    'ithmb',
                    'jar',
                    'kb',
                    'ldt',
                    'lrprev',
                    'pkpass',
                    'ppa',
                    'ppam',
                    'pdb',
                    'rom',
                    'thm',
                    'thmx',
                    'vsl',
                    'xla',
                    'xlam',
                    'xlb',
                    'xll',
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'txt',
            value: {
                extensions: [
                    'dif', 'diff', 'readme', 'out', 'plist', 'properties', 'text', 'txt'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'vaultclosed',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'vaultopen',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'vector',
            value: {
                extensions: [
                    'ai',
                    'ait',
                    'cvs',
                    'dgn',
                    'gdraw',
                    'pd',
                    'emf',
                    'eps',
                    'fig',
                    'ind',
                    'indd',
                    'indl',
                    'indt',
                    'indb',
                    'ps',
                    'svg',
                    'svgz',
                    'wmf',
                    'oxps',
                    'xps',
                    'xd',
                    'sketch',
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'video',
            value: {
                extensions: [
                    '3g2',
                    '3gp',
                    '3gp2',
                    '3gpp',
                    'asf',
                    'avi',
                    'dvr-ms',
                    'flv',
                    'm1v',
                    'm4v',
                    'mkv',
                    'mod',
                    'mov',
                    'mm4p',
                    'mp2',
                    'mp2v',
                    'mp4',
                    'mp4v',
                    'mpa',
                    'mpe',
                    'mpeg',
                    'mpg',
                    'mpv',
                    'mpv2',
                    'mts',
                    'ogg',
                    'qt',
                    'swf',
                    'ts',
                    'vob',
                    'webm',
                    'wlmp',
                    'wm',
                    'wmv',
                    'wmx',
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'video360',
            value: {
                extensions: [
                    ''
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'vsdx',
            value: {
                extensions: [
                    'vdx', 'vsd', 'vsdm', 'vsdx', 'vsw', 'vdw'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'vssx',
            value: {
                extensions: [
                    'vss', 'vssm', 'vssx'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'vstx',
            value: {
                extensions: [
                    'vst', 'vstm', 'vstx', 'vsx'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'xlsx',
            value: {
                extensions: [
                    'xlc', 'xls', 'xlsb', 'xlsm', 'xlsx', 'xlw'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON,
                canpreview: true
            } as IFileTypeIcon
        },
        {
            key: 'xltx',
            value: {
                extensions: [
                    'xlt', 'xltm', 'xltx'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON,
                canpreview: true
            } as IFileTypeIcon
        },
        {
            key: 'xml',
            value: {
                extensions: [
                    'xaml', 'xml', 'xsl'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        },
        {
            key: 'xsn',
            value: {
                extensions: [
                    'xsn'
                ],
                iconFormat: IconFormatEnum.FABRIC_ICON
            } as IFileTypeIcon
        }
    ];
}
