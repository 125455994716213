import { Type as AngularCoreType } from '@angular/core';

import { ComponentMethodMetadata } from './component-methods-metadata.model';
import { ComponentMethodsService } from './component-methods.service';

// Define a function used for handling @Scriptable() method annotations.
export function Scriptable(userFriendlyMethodName: string = null, parameterNames: string[] = null, parametersTypeInfo: string[] = null) {
    const decorator: MethodDecorator = (target: Object, propertyKey: string | symbol, description) => {
        const targetType = Reflect.getMetadata('design:type', target, propertyKey);

        if ((targetType != null) && (targetType.constructor.name == 'Function')) {
            const args = Reflect.getMetadata('design:paramtypes', target, propertyKey).map(c => c.name);
            const ret = Reflect.getMetadata('design:returntype', target, propertyKey);
            let returnType: string = '';
            if (ret == null)
                returnType = 'void';
            else
                returnType = ret.name;

            let componentClass = <AngularCoreType<any>>target;
            let componentClassName: string = componentClass.constructor.name;
            let methodName: string = propertyKey.toString();
            let componentMethodMetadata: ComponentMethodMetadata = new ComponentMethodMetadata(componentClass, methodName, args, returnType);
            componentMethodMetadata.userFriendlyName = userFriendlyMethodName;
            componentMethodMetadata.parameterNames = parameterNames;
            componentMethodMetadata.parametersTypeInfo = parametersTypeInfo;

            ComponentMethodsService.saveComponentMethodMetadataFor(componentClassName, componentMethodMetadata);
        }
    }

    return decorator
}
