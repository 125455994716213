import { Component, OnInit, SimpleChange, Input, OnChanges, EventEmitter, Output, HostBinding } from '@angular/core';
import { Menu } from '../../models/navigation/menu.model';
import { Router, ActivatedRoute } from '@angular/router';

import { MenuItem } from '../../models/navigation/menu-item.model';
import { CommunicationService } from '../../services/communication.service';
import { UserClaims } from '../../models/user-claims.model';
import { BrowserStorageService } from '../../services/browserstorage.service';
import { BrowserStorageEnums } from '../../enums/browserstorage.enum';
import { Logging } from '../../logging';
import { DataCollectionStatus } from '../../models/data-collection-status.model';
import { UtilityHelper } from '../../utility.helper';
import { IconFormatEnum } from '../../enums/icon-format.enum';
import { NavigationService } from '../../services/navigation.service';
import { CurrentSiteService } from '../../services/current-site.service';
import { QueryParamsService } from '../../services/query-params.service';

declare let $: any;

// TODO: Consider using ListKeyManager from a11y package to manage menu's accessibility
@Component({
    selector: 'app-side-nav-container',
    templateUrl: './side-nav-container.component.html',
    styleUrls: ['./side-nav-container.component.scss']
})
export class SideNavContainerComponent implements OnInit, OnChanges {

    public menuOption: string;
    public opened: boolean = true;

    @Input()
    menu: Menu;

    @Input()
    userClaims: UserClaims;

    @Input()
    title: string;

    @Input()
    status: string;

    private userToggled: boolean = false;

    // matches $sidenav-width in the scss file. See its use below for comment on why it's needed
    // (For reference: we may be able to pull the scss variable into the TS so wouldn't have to define it twice:
    // see https://mattferderer.com/use-sass-variables-in-typescript-and-javascript)
    private readonly SIDENAV_WIDTH: string = '250px';

    public constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private communicationService: CommunicationService,
        private browserStorageService: BrowserStorageService,
        private navigationService: NavigationService,
        private currentSiteService: CurrentSiteService,
        private queryParamsService: QueryParamsService) {
    }

    public ngOnInit() {
        let url = this.router.url.split('#')[0]; // strip off any anchor ref from url
        this.communicationService.emitChangeToSkipNavLink(url);
        this.setOpenedState();
        this.setMenuExpansion(this.opened);
    }

    public ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if (changes['menu'] && changes['menu'].previousValue != changes['menu'].currentValue) {
            this.menu.navItems.forEach(x => {
                if (x.text == "Go Back") {    // Look into adding a type for this
                    x.route = this.browserStorageService.get(BrowserStorageEnums.PREV);
                    this.browserStorageService.set(BrowserStorageEnums.PREVNAVBREADCRUMBSURL, x.route); // TODO - this gets set before BreadcrumbsComponent reads it - need to address this
                }
            });

            this.selectCurrentRoute();
        }
    }

    // Getter methods.
    public menuTitle(): string { 
        //return this.title ? this.title : this.menu.title;

        return "Site Menu";
    }

    public get siteWorkflowState(): string {
        let workflowState: string = this.status;

        if ((this.currentSiteService.Site != null) && (this.currentSiteService.Site.folders != null) && (this.currentSiteService.Site.folders[0] != null) && (this.currentSiteService.Site.folders[0].currentWorkflowState != null))
            workflowState = this.currentSiteService.Site.folders[0].currentWorkflowState;

        return workflowState;
    }
    public get uppercaseSiteWorkflowState(): string {
        let workflowState: string = this.siteWorkflowState.toUpperCase();
        return workflowState;
    }

    public get ShowAppChrome(): boolean {
        //return !this.hideAppChrome;
        return !this.queryParamsService.HideAppChrome;
    }

    public selectCurrentRoute(): void {
        let urls = this.router.url.split('/');

        for (let item of this.menu.navItems) {

            if (this.foundItem(urls, item, item.text, false))
                break;

            //if (item.subItems) {
            //    for (let si of item.subItems) {
            //        if (this.foundItem(urls, si, item.text, true))
            //            break;
            //    }
            //}
        }
    }

    // Returns true if Site is in the Setup or Closed workflow state
    public showStatusInTitle(): boolean {
        //return this.status == DataCollectionStatus.SETUP || this.status == DataCollectionStatus.CLOSED
        return this.siteWorkflowState == DataCollectionStatus.SETUP || this.siteWorkflowState == DataCollectionStatus.CLOSED
    }

    public foundItem(urls: string[], item: MenuItem, opt: string, isChild: boolean): boolean {
        let found = false;

        if (urls[urls.length - 1].toString() == item.route || (isChild && this.router.url.endsWith(item.route))) {
            this.openMenu(opt);

            found = true;
        }

        return found;
    }

    public toggleMenu() {
        this.toggleOpenedState();
        this.setMenuExpansion(this.opened);
    }

    // Methods called by my HTMM code.
    public get IconFormatMatIcon(): string {
        return (IconFormatEnum.MAT_ICON);
    }
    public get IconFormatSvg(): string {
        return (IconFormatEnum.SVG_ICON);
    }

    public HasMatIcon(item: any): boolean {
        let bHasMatIcon: boolean =
            ((item.iconFormat == undefined) || (item.iconFormat == IconFormatEnum.MAT_ICON));

        return (bHasMatIcon);
    }
    public HasSvgIcon(item: any): boolean {
        let bHasSvgIcon: boolean =
            ((item.iconFormat != null) && (item.iconFormat == IconFormatEnum.SVG_ICON));

        return (bHasSvgIcon);
    }

    // checks storage to see if sidenav should display opened or collapsed 
    private setOpenedState() {
        let savedOpenState = this.browserStorageService.get(BrowserStorageEnums.SIDE_NAV_OPEN);
        if (savedOpenState == null || savedOpenState == 'true') {
            this.opened = true;
        } else {
            this.opened = false;
        }
    }

    // if nav is opened, sets state to close it, if it's closed, sets state to open it
    private toggleOpenedState() {
        let savedOpenState = this.browserStorageService.get(BrowserStorageEnums.SIDE_NAV_OPEN);
        if (savedOpenState == null || savedOpenState == 'true') {
            this.opened = false;
        } else {
            this.opened = true;
        }
        this.browserStorageService.set(BrowserStorageEnums.SIDE_NAV_OPEN, '' + this.opened);
    }

    public get AppSideNavClasses(): string {
        let cls = 'app-sidenav noprint';
        if (!this.opened) {
            cls = `${cls} collapsed`
        }
        return cls;
    }

    public get AppSideNavContentClasses(): string {
        let cls = 'app-sidenav-content';
        if (!this.opened) {
            cls = `${cls} collapsed`
        }
        return cls;
    }

    public ShowHomePageMenuItem(item: any): boolean {
        //***If this nav link/item is a beta feature AND beta features aren't enabled don't display
        //JJ - VNEXT-1052 - make all beta feature display permanently so commenting out this if statement
        //if (item.betaFeature && !this.currentSiteService.Site?.betaFeaturesEnabled) {
        //    return false;
        //}

        if (this.currentSiteService.Site?.SiteHomePage?.isActive) {
            return item?.showWhenSiteHomePageEnabledAndActive == null || item?.showWhenSiteHomePageEnabledAndActive == true;
        } else {
            return !item?.showWhenSiteHomePageEnabledAndActive;
        }
    }

    // sets the css classes that implement the opening/closing of the nav
    setMenuExpansion(opened: boolean = true) {
        if (opened) {
            // Spent a while trying to get to the bottom of this and control it in a better way but had to move on...
            // When expanding the left sidenav, Angular (I presume) is inserting a style attribute like this...
            // <mat-sidenav-content ... style="margin-left: 251px;">
            // BUT if I 1) reload the page with the sidenav previously collapsed and 2) then expand the menu
            // the style attribute does _not_ get set, and so the menu expands but the content doesn't shift over
            // so this is a hack to ensure it gets set. There must be a better way, like I said...
            let content = $('.app-sidenav-content');
            UtilityHelper.runWhenStackClear(() => {
                if (this.ShowAppChrome)
                    content.attr("style", `margin-left:${this.SIDENAV_WIDTH}`);
            });
        }
    }

    openMenu(opt: string) {
        this.menuOption = opt;
    }

    public navigateTo(urlSegment: string, goBack: boolean = false) {
        urlSegment = urlSegment ? urlSegment.split('#')[0] : '';
        let url = "";
        if (urlSegment.startsWith('/')) {
            url = urlSegment;
        } else {
            url = this.activatedRoute.snapshot.url.join('/') + '/' + urlSegment;
        }

        if (goBack) {

            let previousUrl = this.navigationService.popOffPreviousUrl();

            this.router.navigateByUrl(previousUrl).then(x => {
                this.communicationService.emitChangeToSkipNavLink(url);
                let mainContent = $('#main-content');
                if (mainContent) {
                    mainContent.focus();
                }
            });
        } else {
            // nav to the route, and then set focus on the main content div rather than keep focus
            // on the nav menu itself (users will need to Shift+Tab their way back to nav menu)
            this.router.navigateByUrl(url).then(x => {
                this.communicationService.emitChangeToSkipNavLink(url);
                let mainContent = $('#main-content');
                if (mainContent) {
                    mainContent.focus();
                }
            });
        }
    }

    public blockDrop(ev: DragEvent) {
        UtilityHelper.blockDrop(ev);
    }

    //checkDefault(opt: string) {
    //    console.log(opt);
    //    let menuItem = this.menu.navItems.filter(x => x.text == opt)[0];

    //    if (menuItem.defaultState && menuItem.defaultState == 'closed')
    //        this.expandMenu(false);
    //    else if (!this.userToggled)
    //        this.expandMenu(true);
    //}

    //childClick(opt: string) {
    //    this.checkDefault(opt);
    //}
}
