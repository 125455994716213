export class PickerItem {
    public key: string;
    public displayName: string;
    public secondaryDisplayName: string;
    public fullDisplay: string;
    public isUser: boolean;
    public isActive: boolean;
    public phone: string;    
    public phoneExt: string;
    public agencyShortName: string;

    constructor(obj: any) {
        this.key = obj.key;
        this.displayName = obj.displayName;
        this.secondaryDisplayName = obj.secondaryDisplayName;
        this.isUser = obj.isUser;
        this.isActive = obj.isActive;
        this.fullDisplay = `${this.displayName} - ${this.secondaryDisplayName}`;
        this.phone = obj.phone;
        this.phoneExt = obj.phoneExt;
        this.agencyShortName = obj.agencyShortName;
    }
}
