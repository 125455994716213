import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { plainToClass } from 'class-transformer';

import { environment } from '../../../environments/environment';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { CollectApiServiceBase } from './collect-api-base.service';
import { FormInstance } from '../models/site-content/form-instance.model';
import { AsyncJob } from '../models/async-job.model';
import { XmlFileFormatEnum } from '../enums/xml-file-format.enum';
import { ExcelSiteFormDataExportRequest } from '../models/export-import/excelSiteFormDataExportRequest';
import { ExcelFolderFormDataExportRequest } from '../models/export-import/excelFolderFormDataExportRequest';

@Injectable()
export class ExcelExportService extends CollectApiServiceBase<FormInstance> {
    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'excelExport', FormInstance)
    }

    // Implement service methods.
    public exportSiteFormDataForSelectedForms(dataCollectionId: number, selectedFormNames: string[]): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/exportSiteFormDataForSelectedForms`;

        let exportRequest: ExcelSiteFormDataExportRequest = new ExcelSiteFormDataExportRequest(dataCollectionId, selectedFormNames);

        return this.http.post<AsyncJob>(url, exportRequest)
            .toPromise()
            .then(response => {
                let asyncJob: AsyncJob = this.formatAsyncJobResponse(response);
                let result = {
                    dataCollectionId: dataCollectionId,
                    asyncJob: asyncJob
                };
                return result;
            })
            .catch(this.handleError);
    }

    public exportSiteFormData(dataCollectionId: number): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/exportSiteFormDefsAndFormData/${dataCollectionId}`;

        return this.http.get<AsyncJob>(url)
            .toPromise()
            .then(response => {
                //let result = plainToClass(FormInstance, response);
                //return result;
                let asyncJob: AsyncJob = this.formatAsyncJobResponse(response);
                //return response;
                let result = {
                    dataCollectionId: dataCollectionId,
                    asyncJob: asyncJob
                };
                return result;
            })
            .catch(this.handleError);
    }

    public exportFolderFormDataForSelectedForms(folderId: number, selectedFormNames: string[]): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/exportFolderFormDataForSelectedForms`;

        let exportRequest: ExcelFolderFormDataExportRequest = new ExcelFolderFormDataExportRequest(folderId, selectedFormNames);

        return this.http.post<AsyncJob>(url, exportRequest)
            .toPromise()
            .then(response => {
                return this.formatAsyncJobResponse(response);
            })
            .catch(this.handleError);
    }

    public exportFolderFormData(folderId: number): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/exportFolderFormDefsAndFormData/${folderId}`;

        return this.http.get<AsyncJob>(url)
            .toPromise()
            .then(response => {
                let asyncJob: AsyncJob = this.formatAsyncJobResponse(response);
                let result = {
                    folderId: folderId,
                    asyncJob: asyncJob
                };
                return result;
            })
            .catch(this.handleError);
    }

    // Implement additional public methods.
    public getResultFileDownloadUrl(asyncJob: AsyncJob, expectedDownloadFileName: string): string {
        let url = `${this.url}/${this.endpoint}/JobKey/${asyncJob.jobKey}/FileDownloadName/${expectedDownloadFileName}`;

        return (url);
    }

    // Helper methods.
    private formatAsyncJobResponse(data: AsyncJob): AsyncJob {
        let obj = plainToClass(AsyncJob, data);

        return (obj);
    }
}
