import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Type as AngularCoreType
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
//import { FormFieldPropertyEnum } from '../../form-builder/properties/form-field-properties/form-field-properties.component';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';

@Component({
    selector: 'app-instructions-form-field',
    templateUrl: './instructions-form-field.component.html',
    styleUrls: ['./instructions-form-field.component.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InstructionsFormFieldComponent,
            multi: true
        }
    ]
})
export class InstructionsFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    // Properties.
    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();

    private readonly formFieldNames: string[] =
        [
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.PLACEHOLDER_TEXT,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT
        ]

    // Constructor.
    constructor()
    {
        super();

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldNames
        };

        return (hshProperties);
    }

    public get canHaveFieldConditionalLogic(): boolean {
        return false;
    }

    // Override/implement the method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (InstructionsFormFieldComponent);
    }

    // Life cycle methods.
    ngOnInit(): void {
        //this.onInit.emit({ component: this, formField: this.formField, properties: this.formFieldNames });
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);

        return;
    }

    // Accessor methods.
}
