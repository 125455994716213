<!--<browser-check></browser-check>-->
<ng-container>
    <app-loading-spinner></app-loading-spinner>
    <div class="app-container" (click)="appClick()">
        <!--
            Spent a long time trying to make the display of this conditional but use of ngIf kept throwing
            Angular "Expression Changed" error because of the fact that the value of skipLink initialized
            and then reset asynchronously by children of AppComponent. Tried everything I could think of.
        -->
        <!--
             05-19-2020 note:  disabling the following anchor tag as it is causing errors like the following:

             zone-evergreen.js:172 Uncaught Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed
             after it was checked. Previous value for 'href': 'null'. Current value: '/site/234/structure#main-content'.
        -->
        <!--
        <a class="skipnav" href="{{skipLink}}" tabindex="0">Skip Navigation Menu</a>
            -->

        <div *ngIf="this.showHeader" class="app-header" role="banner">
            <!-- VNEXT-1181: KLW - For Banner Color based on environment implementation -->
            <mat-toolbar class="app-header theme-app-header noprint" 
                         [style.background-color]="this.BannerColor"
                         (drop)="this.blockDrop($event)"
                         (dragover)="this.blockDrop($event)">

                <img src="../assets/svg/omb-seal.svg" class="seal">

                <a *ngIf="this.ShowAppChrome" class="logo-text-mark theme-logo-text" [routerLink]="'/'" title="OMB Collect Home">
                    <span tabindex="-1" [routerLink]="['/']">{{this.SiteName}}</span>
                </a>
                <div *ngIf="!this.ShowAppChrome" class="logo-text-mark theme-logo-text" title="OMB Collect Home">
                    <span tabindex="-1" >{{this.SiteName}}</span>
                </div>

                <!--
                <span class="fill-remaining-space">
                </span>
                -->
                <app-progress-indicator class="fill-remaining-space"></app-progress-indicator>

                <span>
                    <!--VNEXT-1054: KLW - Changes for the interim homepage -->
                    <loginout #logInOutComponentId></loginout>
                </span>
            </mat-toolbar>
        </div>

        <div *ngIf="this.ShowBanner" class="app-banner-text-fields"
            [ngStyle]="{'background-color': this.BackgroundColor}">
            <div class="banner-text-field"><span [ngStyle]="{'color': this.TextColor}">{{this.LeftText}}</span></div>
            <div class="banner-text-field"><span [ngStyle]="{'color': this.TextColor}">{{this.CenterText}}</span></div>
            <div class="banner-text-field"><span [ngStyle]="{'color': this.TextColor}">{{this.RightText}}</span></div>
        </div>

        <div class="app-body no-overflow">
            <!-- VNEXT-1054: KLW - Changes for the interim homepage -->
            <router-outlet (activate)="this.doOnActiviation($event)"
                (deactivate)="this.deactivateAll()"></router-outlet>
        </div>

        <!-- VNEXT-1054: KLW - Changes for the interim homepage -->
        <!-- <div *ngIf="this.ShowFooter" class="app-footer">
            <div class="app-footer-content">
                <img src="../assets/svg/omb-seal.svg" class="footer-seal">
                <span class="footer-office-text">
                     Office of Management and Budget
                </span>
            </div>
        </div>  -->
    </div>
</ng-container>
