import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { DropdownFieldDefLogic } from './dropdown-field-def-logic';
import { TextFieldDefinitionLogicBase } from './text-logic-base';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';

export class MultiDropdownFieldDefLogic extends DropdownFieldDefLogic {
    public constructor() {
        super();
    };

    // pasteDataForInto():  only allow the pasting of values that are valid for a given form field.
    public pasteDataForInto(formField: FormField, formInstanceElement: FormInstanceElement, pasteValue: string): void {
        // Note:  this method is not yet implemented.
    }

    // Sorting.
    public compareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        let compareResult: number = MultiDropdownFieldDefLogic.staticCompareAscendingFor(formField, value1, value2);
        return compareResult;
    }
    public static staticCompareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        let compareResult: number = 0;

        if ((value1.childFormInstanceElements != null) && (value2.childFormInstanceElements != null)) {
            let childIndex: number = 0;

            do {
                if ((childIndex < value1.childFormInstanceElements.length) && (childIndex < value2.childFormInstanceElements.length)) {
                    compareResult = TextFieldDefinitionLogicBase.staticCompareAscendingFor(formField, value1.childFormInstanceElements[childIndex], value2.childFormInstanceElements[childIndex]);
                } else if (childIndex < value1.childFormInstanceElements.length) {
                    compareResult = 1;
                } else if (childIndex < value2.childFormInstanceElements.length) {
                    compareResult = -1;
                }

                childIndex++;
            } while ((compareResult == 0) && ((childIndex < value1.childFormInstanceElements.length) || (childIndex < value2.childFormInstanceElements.length)));
        } else if (value1.childFormInstanceElements != null) {
            compareResult = 1;
        } else if (value2.childFormInstanceElements != null) {
            compareResult = -1;
        }

        return compareResult;
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        let value: string = '';

        if (formInstanceElementParam.childFormInstanceElements &&
            (formInstanceElementParam.childFormInstanceElements.length > 0)) {
            let iNumValuesSelected: number = 0;

            for (let iChild: number = 0; iChild < formInstanceElementParam.childFormInstanceElements.length; iChild++) {
                let child: FormInstanceElement = formInstanceElementParam.childFormInstanceElements[iChild];

                if (!child.isDeleted) {
                    if (iNumValuesSelected > 0) {
                        value += ',';
                    }

                    value += child.textValue;
                    iNumValuesSelected++;
                }
            }
        }

        return (value);
    }

    public filterType(): string {
        return 'text';
    }

    public isFilterable(): boolean {
        return true;
    }
}
