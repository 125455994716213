// Note:  this enumeration is used to give a hint
//        to a form field class about what processing
// phase is occurring.A value of this enumeration
// is passed to ...
export enum FormFieldProcessingPhaseEnum {
    CREATING_DATA = 'Creating', 
    LOADING_DATA = 'Loading',
    EDITING_DATA = 'Editing',
    CALCULATING_COLUMN_TOTAL = 'Calc Column Total'
}
