import { DragDropEnum } from "../../../../../src/app/shared/enums/drag-drop.enum"

export interface IFilesDroppedOnStructure {
    Files: File[];
    FolderId: number;
    Reload: boolean;
}

export class FilesDroppedOnStructureEvent {
    private files;
    private toDropFolderId: number;
    private toDropSiteId: number;
    private reload: boolean;
    private folderName: string;
    private objectType: string;

    public constructor(passedFiles) {
        this.files = passedFiles;
    }

    public getFiles() {
        return this.files;
    }


    public setToDropFolderId(id: number) {
        this.toDropFolderId = id;
    }

    public getToDropFolderId(): number {
        return this.toDropFolderId;
    }


    public setToDropSiteId(id: number) {
        this.toDropSiteId = id;
    }

    public getToDropSiteId(): number {
        return this.toDropSiteId;
    }


    public setReload(value: boolean) {
        this.reload = value;
    }

    public getReload(): boolean {
        return this.reload;
    }


    public setFolderName(name: string) {
        this.folderName = name;
    }

    public getFolderName(): string {
        return this.folderName;
    }


    public setObjectType(objectType: DragDropEnum) {
        this.objectType = objectType;
    }

    public getObjectType(): string {
        return this.objectType;
    }

    public getSiteOrFolder(): DragDropEnum {
        let retVal: DragDropEnum;

        if (this.objectType == DragDropEnum.FORM)
            retVal = DragDropEnum.FOLDER;
        else if (this.objectType == DragDropEnum.FORM_TEMPLATE)
            retVal = DragDropEnum.FORM_TEMPLATE_CATALOG;

        return retVal;
    }
}

