import { LayoutCell } from './layout-cell.model';
import { Type } from 'class-transformer';
import { ICloneAndCopy } from '../../interfaces/clone-and-copy';

export class LayoutRow implements ICloneAndCopy {
    // Properties.
    public position: number = 0;

    @Type(() => LayoutCell)
    public cells: LayoutCell[] = [];

    public isSelected: boolean = false;
    public isLastRow: boolean = false;

    // Begin transition fields used only in client code.
    public transientIsDeleted: boolean = false; // Note:  this is a client-only property.
    //public transientSetHeightToZero: boolean = false;
    // End transition fields used only in client code.

    // Getter methods.
    public get hasContent(): boolean {
        let bHasContent: boolean = false;

        for (let iCell: number = 0; iCell < this.cells.length; iCell++) {
            let cell: LayoutCell = this.cells[iCell];

            if (cell.hasContent) {
                bHasContent = true;

                break;
            }
        }

        return (bHasContent);
    }

    public getPositionOfCell(cell: LayoutCell): number {
        let position: number = -1;

        if (this.cells != null) {
            for (let index: number = 0; index < this.cells.length; index++) {
                if (this.cells[index] == cell) {
                    position = index;
                    break;
                }
            }
        }        

        return position;
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let newRow: LayoutRow = new LayoutRow();

        newRow.position = this.position;

        newRow.cells = [];
        if (this.cells && (this.cells.length > 0)) {
            for (let iCell: number = 0; iCell < this.cells.length; iCell++) {
                let cell: LayoutCell = this.cells[iCell];

                let cellClone: LayoutCell = <LayoutCell>cell.clone();
                newRow.cells.push(cellClone);
            }
        }

        newRow.isSelected = this.isSelected;
        newRow.isLastRow = this.isLastRow;

        return (newRow);
    }

    public copy(objectToCopy: ICloneAndCopy): void {
        let rowToCopy: LayoutRow = <LayoutRow>objectToCopy;

        this.position = rowToCopy.position;

        this.cells = [];
        if (rowToCopy.cells && (rowToCopy.cells.length > 0)) {
            for (let iCell: number = 0; iCell < rowToCopy.cells.length; iCell++) {
                let cellToCopy: LayoutCell = rowToCopy.cells[iCell];

                let cellCopy: LayoutCell = <LayoutCell>cellToCopy.clone();
                this.cells.push(cellCopy);
            }
        }

        this.isSelected = rowToCopy.isSelected;
        this.isLastRow = rowToCopy.isLastRow;

        return;
    }
}
