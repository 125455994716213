import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { FormInstanceService } from '../services/form-instance.service';
import { SiteResponseManagementService } from '../services/site-response-management.service';

@Injectable()
export class SiteResponseManagementResolver implements Resolve<any> {
    constructor(private siteResponseManagementService: SiteResponseManagementService,
        private formInstanceService: FormInstanceService,
        private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        // Get the site's data collection ID.
        let dataCollectionId = route.params['id']; // Get the data collection ID.

        if ((dataCollectionId === undefined) || (dataCollectionId === null) || (dataCollectionId.toString().trim() === '')) {
            dataCollectionId = '4'; // This is the ID of an existing data collection
                      // --need to remove this code after testing!!!
        }

        // Get a form instance ID (for initial form instance testing only -- REMOVE AFTER TESTING).
        //let formInstanceId = route.params['id'];
        /*
        let formInstanceId = null;

        if ((formInstanceId === undefined) || (formInstanceId === null) || (formInstanceId.toString().trim() === '')) {
            formInstanceId = '11'; // This is the ID of an existing form.
        }
        */

        /*
        return this.formInstanceService.get(id).then(response => {
            return response;
        });
        */

        return this.siteResponseManagementService.get(dataCollectionId).then(response => {
            return (response);
        })
    }
}
