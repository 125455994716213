import { Injectable, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';

//declare let $: any; // jQuery

import { ElementTypeMetadata } from '../models/component-scripting/element-type-metadata.model';
import { LinksElementTypeMetadata } from '../models/component-scripting/links-element-type-metadata.model';
import { ButtonsElementTypeMetadata, ButtonsWithMatIconsElementTypeMetadata } from '../models/component-scripting/buttons-element-type-metadata.model';
import { MatListItemsElementTypeMetadata } from '../models/component-scripting/matlistitems-element-type-metadata.model';
import { MatIconsElementTypeMetadata } from '../models/component-scripting/maticons-element-type-metadata.model';
import { InputElementTypeMetadata, InputsElementTypeMetadata } from '../models/component-scripting/input-element-type-metadata.model';
import { ComponentMethodElementTypeMetadata } from '../models/component-scripting/comp-method-element-type-metadata.model';
import { MatCheckBoxElementTypeMetadata } from '../models/component-scripting/matcheckbox-element-type-metadata.model';
import { MatSelectElementTypeMetadata } from '../models/component-scripting/matselect-element-type-metadata.model';

@Injectable({
    providedIn: 'root'
})
export class HtmlElementTypeService {
    // Static data.
    private readonly elementTypeMetadata: ElementTypeMetadata[] =
        [
            new LinksElementTypeMetadata(),
            new ButtonsElementTypeMetadata(),
            new ButtonsWithMatIconsElementTypeMetadata(),
            new MatListItemsElementTypeMetadata(),
            new MatIconsElementTypeMetadata(),
            new InputElementTypeMetadata(),
            new InputsElementTypeMetadata(),
            new MatCheckBoxElementTypeMetadata(),
            new MatSelectElementTypeMetadata(),

            new ComponentMethodElementTypeMetadata(this.dialog) // Treating class methods like HTML element types.
        ];

    // Constructor.
    public constructor(private dialog: MatDialog) { }

    // Service methods.
    public getElementTypeMetadata(): ElementTypeMetadata[] {
        return this.elementTypeMetadata;
    }
    public getMetadataForElementType(elementType: string, throwExceptionOnError: boolean = true): ElementTypeMetadata {
        let metadata: ElementTypeMetadata = this.elementTypeMetadata.find(md => md.metadataKey == elementType);
        if ((metadata == null) && throwExceptionOnError)
            throw `getMetadataForElementType():  cannot find metadata for element type '${elementType}'.`;
        return metadata;
    }
}
