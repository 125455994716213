import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MenuItem, TreeNode } from 'primeng/api';
import { MatInput } from '@angular/material/input';
import { TooltipPosition } from '@angular/material/tooltip';

import { HtmlElementInfo, IComponentElementsByElementType } from '../../models/component-scripting/html-element-info.model';
//import { ElementTypeMetadata } from '../../models/component-scripting/element-type-metadata.model';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { ComponentHierarchyService, IComponentHierarchyChanged } from '../../services/component-hierarchy.service';
import { ComponentTreeNode, FlatComponentTreeNode } from '../../models/component-hierarchy/component-tree-node.model';
//import { HtmlInputValue } from '../../models/component-scripting/html-input-value.model';
import { HtmlElementTypeService } from '../../services/html-element-type.service';
//import { EditValueDialog } from '../../dialogs/edit-value/edit-value.dialog';
import { DisplayContentData, DisplayContentDialog, DisplayContentDialogInitData } from '../display-content/display-content.dialog';
import { UITestActionRecorderService } from '../../services/ui-test-action-recorder.service';
import { UITestExecutionService } from '../../services/ui-test-execution.service';
import { ServiceClassCatalogueService, ServiceClassInfo } from '../../services/service-class-catalogue.service';
import { ISelectedElementInfo } from '../../models/component-scripting/iselected-element-info.interface';
import { SelectedComponentData } from '../../models/component-scripting/selected-component-data.model';
//import { ComponentMethodMetadata, ComponentMethodsMetadata, ComponentMethodsService } from '../../models/component-scripting/component-methods-metadata.model';
import { JQueryBrowserDriverService } from '../../services/jquery-browser-driver.service';
//import { HtmlElementTypeNames } from '../../models/component-scripting/html-element-type-names.model';
import { ScriptableComponentStatus } from '../../enums/component-scripting/scriptable-component-status.enum';
import { ServiceAsTestableComponent } from '../../models/component-scripting/service-as-testable-component.model';
//import { ElementTypeInfo } from '../../models/component-scripting/element-type-info.model';
import { UITestDirectiveParser, ParsedTestCommands } from '../../utility-classes/parse/ui-test-directive-parser';
import { IScriptableFunction, FunctionExecutionStatus } from '../../interfaces/iscriptable-function';
import { Tuple } from '../../models/parse/tuple.model';
import { Token } from '../../models/parse/token.model';
import { TokenCode } from '../../models/parse/token-code.enum';
import { ScriptableFunctionService } from '../../services/scriptable-function.service';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
//import { IElementTypeMetadata } from '../../interfaces/component-scripting/ielement-type-metadata';
//import { ComponentMethodMetadata } from '../../models/component-scripting/component-methods-metadata.model';
import { SelectOptionsDialog } from '../select-options/select-options.dialog';
import { SelectedComponentInfoComponent } from '../../components/selected-component-info/selected-component-info.component';
import { IOperationCompletedCallback, IRegisterOperationCompletedCallback } from '../../interfaces/ioperation-completed.intefaces';
import { FormBuilderService } from '../../services/form-builder.service';
import { FolderService } from '../../services/folder.service';
import { FormInstanceService } from '../../services/form-instance.service';

declare let $: any; // jQuery

export class UITestCenterDialogManager {
    public constructor() {
    }

    // Open the dialogue.
    public openDialogUsing(dialog: MatDialog): MatDialogRef<UITestCenterDialog, any> {
        let dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = false;
        //dialogConfig.width = '800px';
        //dialogConfig.height = '325px';
        dialogConfig.data = null; // No data yet.

        const dialogRef = dialog.open(UITestCenterDialog, dialogConfig);
        dialogRef.afterClosed().subscribe(naResult => {
            // Note:  presently there is no logic in this block by design.
        });

        return dialogRef;
    }
}

class SelectedServiceData {
    public constructor(public serviceInfo: ServiceClassInfo) {
    } 

    public serviceIsVisible: boolean = true;
}

class ReplayTestActionInfo {
    public scriptCommands: ParsedTestCommands = null;
    public currentTupleIndex: number = 0;
    public lastExecutionStatus: FunctionExecutionStatus = FunctionExecutionStatus.NotStarted;
    public numCheckExecutionStatusCalls: number = 0;
    public readonly maxCheckExecutionStatusCalls: number = 50;

    public constructor(scriptCommands: ParsedTestCommands) {
        this.scriptCommands = scriptCommands;
    }
}

@Component({
    selector: 'app-ui-test-center',
    templateUrl: './ui-test-center.dialog.html',
    styleUrls: ['./ui-test-center.dialog.scss']
})
export class UITestCenterDialog implements OnInit, AfterViewInit, OnDestroy, IComponentHierarchyChanged, ITestActionRecorderService {
    // Properties.
    //private treeData: TreeNode[] = [];
    private cachedTreeData: TreeNode[] = [];
    public selectedTreeNode: TreeNode = null;

    //@ViewChild('gfgtree') tree!: Tree;
    private selectedComponentInfo: SelectedComponentData = null;
    //private selectedServiceInfo: ServiceClassInfo = null;
    public readonly serviceClassNames: string[] = [];
    private selectedServiceInfo: SelectedServiceData = null;
    private replayTestActionInfo: ReplayTestActionInfo = null;

    @ViewChild('selectedComponentInfoComponent') selectedComponentInfoComponent: SelectedComponentInfoComponent;

    // Constructor.
    public constructor(public dialogRef: MatDialogRef<UITestCenterDialog>,//, @Inject(MAT_DIALOG_DATA) public initData: UITestCenterDialogManager) { }
        private myComponentHierarchyService: ComponentHierarchyService,
        private uiTestActionRecorderService: UITestActionRecorderService,
        //private uiTestExecutionService: UITestExecutionService,
        private serviceCatalogueService: ServiceClassCatalogueService,
        private htmlElementTypeService: HtmlElementTypeService,
        private jqueryBrowserDriverService: JQueryBrowserDriverService,
        private scriptableFunctionService: ScriptableFunctionService,
        private formBuilderService: FormBuilderService,
        private folderService: FolderService,
        private formInstanceService: FormInstanceService,
        private dialog: MatDialog)
    {
        let servicesCatalogue: ServiceClassInfo[] = this.serviceCatalogueService.serviceClassCatalogue;
        if (servicesCatalogue != null) {
            for (let index: number = 0; index < servicesCatalogue.length; index++) {
                let serviceInfo: ServiceClassInfo = servicesCatalogue[index];
                this.serviceClassNames.push(serviceInfo.className);
            }
        }
    }

    // Life cycle methods.
    public ngOnInit(): void {
        this.componentHierarchyService.UITestCenterDialog = this;
        this.componentHierarchyService.addHierarchyChangedHandler(this);
    }

    public ngAfterViewInit(): void {
    }

    public ngOnDestroy(): void {
        this.componentHierarchyService.UITestCenterDialog = null;
        this.componentHierarchyService.removeHierarchyChangedHandler(this);
    }

    // Methods called by my HTML code.
    public get Title(): string {
        return 'UI Test Center';
    }

    public get TreeNodes(): TreeNode[] {
        if ((this.cachedTreeData == null) || (this.cachedTreeData.length == 0))
            this.cachedTreeData = this.componentHierarchyService.HierarchyAsTreeNodes;
        //this.cachedTreeData = this.componentHierarchyService.HierarchyAsTreeNodes;
        
        return this.cachedTreeData;
    }
    public get SelectedTreeNode(): TreeNode {
        return this.selectedTreeNode;
    }
    public set SelectedTreeNode(node: TreeNode) {
        this.selectedTreeNode = node;
    }

    public get ComponentSelected(): boolean {
        return (this.selectedComponentInfo != null) && (this.selectedComponentInfo.componentIsVisible == true);
    }
    public get ServiceSelected(): boolean {
        return (this.selectedServiceInfo != null) && (this.selectedServiceInfo.serviceIsVisible == true);
    }
    public get SelectedServiceInfo(): ServiceClassInfo {
        //return this.selectedServiceInfo;
        return this.selectedServiceInfo != null ? this.selectedServiceInfo.serviceInfo : null;
    }
    public get SelectedComponent(): ITestableComponent {
        return this.selectedComponentInfo != null ? this.selectedComponentInfo.component : null;
    }
    public get SelectedElementInfo(): ISelectedElementInfo {
        let selectedElementInfo: ISelectedElementInfo = <ISelectedElementInfo>this.selectedComponentInfo;
        return selectedElementInfo;
    }
    public get ComponentHasElements(): boolean {
        let hasElements: boolean = false;

        if (this.selectedComponentInfo != null) {
            hasElements = this.selectedComponentInfo.componentElementTypes.length > 0;
        }

        return hasElements;
    }
    public get ComponentNoElementsMessage(): string {
        let message: string = '';

        if ((this.selectedComponentInfo != null) && (!this.ComponentHasElements))
            message = `Component ${this.selectedComponentInfo.component.tagName.toLowerCase()} has no elements`;

        return message;
    }

    public get TooltipPosition(): TooltipPosition {
        return 'right';
    }
    public get SelectedElementIsInput(): boolean {
        let isInput: boolean = false;

        if ((this.selectedComponentInfo != null) && (this.selectedComponentInfo.selectedElement != null))
            isInput = this.selectedComponentInfo.selectedElement.elementTypeMetadata.hasInputs(this.jqueryBrowserDriverService, this.selectedComponentInfo.selectedElement.element);

        return isInput;
    }

    public get ReplayDisabled(): boolean {
        return this.uiTestActionRecorderService.LogIsEmpty;
    }

    // Implement ITestActionRecorderService methods.
    public get componentHierarchyService(): ComponentHierarchyService {
        return this.myComponentHierarchyService;
    }

    public getRegisterOperationCompletedCallbackFor(serviceName: string): IRegisterOperationCompletedCallback {
        let registerCallback: IRegisterOperationCompletedCallback = null;

        if (serviceName == 'formBuilderService')
            registerCallback = this.formBuilderService;
        else if (serviceName == 'folderService')
            registerCallback = this.folderService;
        else if (serviceName == 'formInstanceService')
            registerCallback = this.formInstanceService;

        return registerCallback;
    }

    public selectComponent(component: ITestableComponent): void {
        this.handleComponentSelected(component);
    }

    public clickButton(elementTitle: string, elementMetadataKey: string, elementSubtype: string, operationCompletedServiceName: string, operationName: string): void {
        if (this.selectedComponentInfoComponent != null) {
            let htmlElementInfo = this.selectedComponentInfoComponent.getHtmlElementInfoFor(elementMetadataKey, elementSubtype, elementTitle);
            if (htmlElementInfo != null) {
                this.selectedComponentInfoComponent.onElementInfoClicked(htmlElementInfo);
                this.selectedComponentInfoComponent.onElementInfoDoubleClicked(htmlElementInfo);
            }                
        }
    }
    public clickLink(elementTitle: string, elementMetadataKey: string, elementSubtype: string, operationCompletedServiceName: string, operationName: string): void {
        this.clickButton(elementTitle, elementMetadataKey, elementSubtype, operationCompletedServiceName, operationName);
    }
    public setInputValue(elementTitle: string, elementMetadataKey: string, elementSubtype: string, value: string, addDateTimeSuffix: boolean): void {
        if ((this.selectedComponentInfoComponent != null) && (this.selectedComponentInfo != null) && (this.selectedComponentInfo.component != null)) {
            let htmlElementInfo = this.selectedComponentInfoComponent.getHtmlElementInfoFor(elementMetadataKey, elementSubtype, elementTitle);
            if (htmlElementInfo != null) {
                let matInput: MatInput = this.selectedComponentInfo.component.getMatInputWithElement(htmlElementInfo.element);
                if (matInput != null)
                    this.jqueryBrowserDriverService.sendKeysTo(htmlElementInfo.element, matInput, value);
            }
        }        
    }

    //public callMethod(component: ITestableComponent, methodName: string, parameters: string[]): void {
    public callMethod(methodName: string, parameters: string[]): void {
        if (this.selectedComponentInfo != null) {
            if ((parameters == null) || (parameters.length == 0)) {
                this.selectedComponentInfo.component[methodName]();
            } else {
                let fiveParameters: string[] = [];
                for (let index: number = 0; index < parameters.length; index++)
                    fiveParameters.push(parameters[index]);
                while (fiveParameters.length < 5)
                    fiveParameters.push(null);

                this.selectedComponentInfo.component[methodName](fiveParameters[0], fiveParameters[1], fiveParameters[2], fiveParameters[3], fiveParameters[4]);
            }
        }
    }
    // End ITestActionRecorderService methods.

    // Handle control events.
    public loadTestActions(): void {
        let dialogRef = this.dialog.open(SelectOptionsDialog, {
            width: '50%',
            autoFocus: true,
            data: {
                options: '',
                title: 'Paste Test Actions',
                label: 'Paste test actions here'
            }
        });

        dialogRef.afterClosed().subscribe(pastedData => {
            if (pastedData != null) {
                let actions: string[] = pastedData.split('\n');
                this.uiTestActionRecorderService.setLogText(actions);
            }
        });
    }

    public replayTest(): void {
        let testableActions: string = this.uiTestActionRecorderService.TestActionLogText;
        let parser: UITestDirectiveParser = new UITestDirectiveParser();
        let scriptCommands: ParsedTestCommands = parser.parseTuples(testableActions, this.scriptableFunctionService);

        if (scriptCommands.errorMessage == null) {
            this.replayTestActionInfo = new ReplayTestActionInfo(scriptCommands);

            if (this.replayTestActionInfo.scriptCommands.tuples.length > 0)
                this.continueScriptExecution();
        } else {
            alert(scriptCommands.errorMessage);
        }

        /*
        for (let index: number = 0; index < scriptCommands.tuples.length; index++) {
            let tuple: Tuple = scriptCommands.tuples[index];

            if (tuple.OpCode == TokenCode.TOKEN_FUNCTION_CALL) {
                let scriptableFunction: IScriptableFunction = <IScriptableFunction>tuple.LeftValue;
                let functionParamTokens: Token[] = <Token[]>tuple.RightValue;
                let functionParamStrings: string[] = [];
                for (let paramIndex: number = 0; paramIndex < functionParamTokens.length; paramIndex++) {
                    let token: Token = functionParamTokens[paramIndex];
                    let paramString: string = null;
                    if ((token.Code == TokenCode.TOKEN_STRING_SINGLE_QUOTES) || (token.Code == TokenCode.TOKEN_NUMBER))
                        paramString = token.Value;
                    else if (token.Code == TokenCode.TOKEN_NULL)
                        paramString = null;
                    else
                        paramString = null;
                    functionParamStrings.push(paramString);
                }

                scriptableFunction.execute(this, functionParamStrings);
            }            
        }
        */
    }
    private continueScriptExecution(): void {
        if ((this.replayTestActionInfo != null) && (this.replayTestActionInfo.currentTupleIndex < this.replayTestActionInfo.scriptCommands.tuples.length)) {
            let tuple: Tuple = this.replayTestActionInfo.scriptCommands.tuples[this.replayTestActionInfo.currentTupleIndex];

            if (tuple.OpCode == TokenCode.TOKEN_FUNCTION_CALL) {
                let scriptableFunction: IScriptableFunction = <IScriptableFunction>tuple.LeftValue;
                let functionParamTokens: Token[] = <Token[]>tuple.RightValue;
                let functionParamStrings: string[] = [];
                for (let paramIndex: number = 0; paramIndex < functionParamTokens.length; paramIndex++) {
                    let token: Token = functionParamTokens[paramIndex];
                    let paramString: string = null;
                    if ((token.Code == TokenCode.TOKEN_STRING_SINGLE_QUOTES) || (token.Code == TokenCode.TOKEN_NUMBER))
                        paramString = token.Value;
                    else if (token.Code == TokenCode.TOKEN_NULL)
                        paramString = null;
                    else
                        paramString = null;
                    functionParamStrings.push(paramString);
                }

                if ((this.replayTestActionInfo.lastExecutionStatus == FunctionExecutionStatus.NotStarted) || (this.replayTestActionInfo.lastExecutionStatus == FunctionExecutionStatus.Completed)) {
                    this.replayTestActionInfo.numCheckExecutionStatusCalls = 0;
                    this.replayTestActionInfo.lastExecutionStatus = scriptableFunction.execute(this, functionParamStrings);
                } else if (this.replayTestActionInfo.lastExecutionStatus == FunctionExecutionStatus.Verifying) {
                    if (this.replayTestActionInfo.numCheckExecutionStatusCalls < this.replayTestActionInfo.maxCheckExecutionStatusCalls) {
                        this.replayTestActionInfo.lastExecutionStatus = scriptableFunction.checkExecutionStatus(this, functionParamStrings);
                        this.replayTestActionInfo.numCheckExecutionStatusCalls++;
                    } else {
                        this.replayTestActionInfo.lastExecutionStatus = FunctionExecutionStatus.Failed;
                    }                    
                }

                if (this.replayTestActionInfo.lastExecutionStatus == FunctionExecutionStatus.Completed)
                    this.replayTestActionInfo.currentTupleIndex++;
                else if (this.replayTestActionInfo.lastExecutionStatus == FunctionExecutionStatus.Failed)
                    alert('Script execution failed.');
            } else {
                alert('Script execution:  encountered an unknown opCode.');
            }            

            if ((this.replayTestActionInfo.currentTupleIndex < this.replayTestActionInfo.scriptCommands.tuples.length) &&
                ((this.replayTestActionInfo.lastExecutionStatus == FunctionExecutionStatus.Completed) || (this.replayTestActionInfo.lastExecutionStatus == FunctionExecutionStatus.Verifying)))
            {
                setTimeout(() => {
                    // Give the components a change to execute any life cycle events.
                    this.continueScriptExecution();
                }, 100);                
            } else {
                // Script execution completed.
                if ((this.replayTestActionInfo.currentTupleIndex == this.replayTestActionInfo.scriptCommands.tuples.length) && (this.replayTestActionInfo.lastExecutionStatus == FunctionExecutionStatus.Completed))
                    alert('The test script completed successfully.');
                this.replayTestActionInfo = null;
            }                
        }
    }

    public displayLogs(): void {
        let testableActions: string = this.uiTestActionRecorderService.TestActionLogText;
        let log: string = this.componentHierarchyService.LogText;
        let contentData: DisplayContentData[] =
            [
                { content: testableActions, tabName: 'Test Actions Log', makeContentReadonly: false },
                { content: log, tabName: 'Diagnostic Log', makeContentReadonly: true }
            ];
        let initData: DisplayContentDialogInitData = new DisplayContentDialogInitData('Test Logs', contentData);

        let dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = true;
        dialogConfig.width = '800px';
        dialogConfig.height = '615px';
        dialogConfig.data = initData;

        const dialogRef = this.dialog.open(DisplayContentDialog, dialogConfig);
        dialogRef.afterClosed().subscribe(na => {
            // NOTE:  NOTHING TO DO HERE BY DESIGN.
        });
    }
    public clearLogs(): void {
        this.uiTestActionRecorderService.clearLog();
        this.componentHierarchyService.clearLogs();
    }

    // Handle control events.
    public objectTypeIndexChanged(selectedTab: number): void {
        // NOTE:  THIS METHOD IS PRESENTLY A NOOP.
    }

    public treeDivClick(eventData: any): void {
    }

    public onTreeNodeDrop(eventData: any): void {
        // Note:  this functionality is presently not being used/not implemented.
    }
    public nodeSelect(event: Object): void {
        let treeNode: ComponentTreeNode = event['node'];
        let component: ITestableComponent = treeNode.Component;

        this.handleComponentSelected(component);
    }
    public handleNodeClicked(flatNode: FlatComponentTreeNode): void {
        let component: ITestableComponent = flatNode.Component;

        this.handleComponentSelected(component);
    }

    public nodeExpand(event: any): void {
        // Note:  this functionality is presently not being used/not implemented.
    }

    public onServiceClassClicked(serviceClassName: string): void {
        let servicesCatalogue: ServiceClassInfo[] = this.serviceCatalogueService.serviceClassCatalogue;
        let serviceInfo = servicesCatalogue.find(si => si.className == serviceClassName);
        let serviceAsTestableComponent: ServiceAsTestableComponent = new ServiceAsTestableComponent(serviceInfo, this.htmlElementTypeService);

        this.handleComponentSelected(serviceAsTestableComponent);
    }

    public dismissDialogue(): void {
        this.dialogRef.close(null);
    }

    //Implement IComponentHierarchyChanged methods
    public componentAdded = (component: ITestableComponent): void => {
        // Nothing to do here as we need to wait for the component to initialize/identify its scriptable elements.
        this.cachedTreeData = null;
    }
    public componentRemoved = (component: ITestableComponent, currentParentComponent: ITestableComponent): void => {
        this.cachedTreeData = null;

        if ((this.selectedComponentInfo != null) && (this.selectedComponentInfo.component == component))
            this.selectedComponentInfo = null;

        if ((this.selectedComponentInfo == null) || (this.selectedComponentInfo.component != currentParentComponent)) {
            this.tryToFindAndSelectComponentNode(currentParentComponent);
            this.handleComponentSelected(currentParentComponent);
        }            
    }
    public hierarchyChanged = (): void => {
        // NOOP.
    }
    public componentInitialized(component: ITestableComponent, initializationStatus: ScriptableComponentStatus): void {
        // Make the newly initialized component the selected component.
        if (initializationStatus == ScriptableComponentStatus.InitializationComplete) {
            this.tryToFindAndSelectComponentNode(component);
            this.handleComponentSelected(component);

            if ((this.selectedComponentInfo != null) && (this.selectedComponentInfo.component == component)) {
                // The same component has initialized again.  Need to let the instance of <app-selected-component-info/> see this change.
                let tempSelectedComponentInfo: SelectedComponentData = this.selectedComponentInfo;
                this.selectedComponentInfo = null;
                setTimeout(() => {
                    this.selectedComponentInfo = tempSelectedComponentInfo;
                }, 0);                
            }
        }            
    }
    private tryToFindAndSelectComponentNode(component: ITestableComponent): void {
        // Make sure we have the latest tree data.
        //this.treeData = this.componentHierarchyService.HierarchyAsTreeNodes;
        this.cachedTreeData = this.componentHierarchyService.HierarchyAsTreeNodes;

        // Try to find the specified node in the hierarchy.
        let componentTreeNode: ComponentTreeNode = this.componentHierarchyService.findComponentTreeNodeIn(<ComponentTreeNode>this.cachedTreeData[0], component);
        if (componentTreeNode != null) {
            let treeNode: TreeNode = <TreeNode>componentTreeNode;
            this.selectedTreeNode = treeNode;
        }
    }

    // Helper methods.
    private handleComponentSelected(component: ITestableComponent): void {
        /*
        this.uiTestActionRecorderService.selectComponent(component);

        this.selectedComponentInfo = new SelectedComponentData(component);
        this.selectedComponentInfo.componentElementTypes = this.selectedComponentInfo.component.elementTypes;
        if ((this.selectedComponentInfo.componentElementTypes != null) && (this.selectedComponentInfo.componentElementTypes.length > 0)) {
            for (let index: number = 0; index < this.selectedComponentInfo.componentElementTypes.length; index++) {
                let elementType: string = this.selectedComponentInfo.componentElementTypes[index];
                let elements: HtmlElementInfo[] = this.selectedComponentInfo.component.getElementsOfType(elementType);

                // Save elements by type.
                this.selectedComponentInfo.mapOfElementsByType[elementType] = elements;

                // Save elements by tab name as well.
                this.selectedComponentInfo.mapElementsByTabNames(elementType, elements);
            }
        }
        */
        this.selectedComponentInfo = SelectedComponentData.handleComponentSelected(component, this.uiTestActionRecorderService, true);
    }
}
