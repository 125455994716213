import { FormField } from "../../models/form-builder/form-field.model";
import { FormFieldBaseComponent } from "../../components/form-fields/form-field-base/form-field-base.component";
import { ComponentAndFormField } from '../../models/grid/component-and-form-field-model';

// Define a class used to keep track of components
export class RuntimeMetadata {
    // Properties.
    private maxRowsOfTextToDisplayOnUnselectedGridRows: number = 0;

    private allComponentFormFieldPairs: ComponentAndFormField[] = [];

    // Methods (to push more logic into this
    // class, out of the grid form field class).
    public allocateAllComponentsList(): void {
        this.allComponentFormFieldPairs = [];

        return;
    }

    public addToAllComponentsList(componentParam: FormFieldBaseComponent, formFieldParam: FormField): void {
        let pair: ComponentAndFormField = new ComponentAndFormField(componentParam, formFieldParam);
        this.allComponentFormFieldPairs.push(pair);

        return;
    }

    // Getter/Setter methods.
    public get MaxRowsOfTextToDisplayOnUnselectedGridRows(): number {
        return this.maxRowsOfTextToDisplayOnUnselectedGridRows;
    }

    public set MaxRowsOfTextToDisplayOnUnselectedGridRows(value: number) {
        this.maxRowsOfTextToDisplayOnUnselectedGridRows = value;
    }

    public get AllComponentsCount(): number {
        return (this.allComponentFormFieldPairs ? this.allComponentFormFieldPairs.length : 0);
    }
    public get AllComponents(): ComponentAndFormField[] {
        return this.allComponentFormFieldPairs;
    }

    public getAllComponentsFormFieldComponent(index: number): FormFieldBaseComponent {
        let componentResult: FormFieldBaseComponent = null;

        if (this.allComponentFormFieldPairs &&
            (index >= 0) &&
            (index < this.allComponentFormFieldPairs.length)) {
            componentResult = this.allComponentFormFieldPairs[index].Component;
        }

        return (componentResult);
    }
    public getAllComponentsFormField(index: number): FormField {
        let formFieldResult: FormField = null;

        if (this.allComponentFormFieldPairs &&
            (index >= 0) &&
            (index < this.allComponentFormFieldPairs.length)) {
            formFieldResult = this.allComponentFormFieldPairs[index].FormField;
        }

        return (formFieldResult);
    }
    // Note:  the initial implementation of this method does not account
    //        for multiple columns with the same component representative!!!
    //public get AllNumericComponents(): FormFieldBaseComponent[] {
    public get AllNumericComponents(): ComponentAndFormField[] {
        let numericFFComponents: ComponentAndFormField[] =
            (this.allComponentFormFieldPairs ?
                //this.compLists.allComponents.filter(c => c.hasNumericData() == true) : []);
                this.allComponentFormFieldPairs.filter(p => p.Component.hasNumericData() === true) :
                []);

        //let componentsResult: FormFieldBaseComponent[] = [];
        let resultPairs: ComponentAndFormField[] = [];

        for (let iPair: number = 0; iPair < numericFFComponents.length; iPair++) {
            let pair: ComponentAndFormField = numericFFComponents[iPair];

            //componentsResult.push(pair.Component);
            resultPairs.push(pair);
        }

        //return (componentsResult);
        return resultPairs;
    }


    // Replacement component methods.
    public addOrReplaceAllComponents(componentParam: FormFieldBaseComponent,
        formFieldParam: FormField): void {
        this.allComponentFormFieldPairs =
            this.allComponentFormFieldPairs.filter(p => p.FormField.gridColClientId !== formFieldParam.gridColClientId);

        let pair: ComponentAndFormField =
            new ComponentAndFormField(componentParam, formFieldParam);
        this.allComponentFormFieldPairs.push(pair);

        return;
    }

}
