//TEAMS-424: KLW - Create an enum to represent all object types in vNext so the 
export enum ItemTypeEnum {
    FORM = 'form',
    FORM_INSTANCE_NO_DASH = 'forminstance',
    FORM_INSTANCE = 'form-instance',
    FORM_INSTANCE_ELEMENT_FOR_HISTORY = 'form-instance-el-for-hist',
    LOG_MESSAGE = 'log-message',
    MAGIC_FORM_INSTANCE = 'magic-form-instance',
    FOLDER = 'folder',
    MAGIC_FOLDER = 'magic-folder',
    WORKFLOW = 'Workflow',
    ROLE = 'role',
    ICON = 'icon',
    ATTACH_W_URLS = 'AttachmentInfoWithUrls',
    FILE = 'File',
    DATACOLLECTION = 'datacollection',
    ATTACHMENT_ACTIVITY = 'AttachmentActivity',
    DOCUMENT_PUBLISH_SETTINGS = 'DocPublishSettings',
    NOTIFICATION = 'notification',
    SUBSCRIPTION = 'subscription',
    BREADCRUMB = 'breadcrumb',
    FABRIC_ICON = 'fabricIcon',
    SVG_ICON = 'svgIcon'
}
