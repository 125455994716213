import { IViewModel } from '../interfaces/view-model.interface';

export class RoleGroupMember implements IViewModel { // 10-12-2023 note:  changed the name of this class to match the corresponding server-side class.
    public id: number;

    public member: string;
    public memberType: string;
    public memberId: string;  //MAX User ID
    public selected: boolean; // used for UI display purposes only
    public readonly isInherited: boolean;

    public roleName: string; // Added 10-11-2023.

    private USER: string = 'User';
    private GROUP: string = 'Group';

    public constructor(member: string, isUser: boolean) {
        //console.log('isUser='+isUser);
        this.member = member;
        this.memberType = isUser ? this.USER : this.GROUP;
    }
}
