import { plainToClass } from 'class-transformer';
import { Injectable } from '@angular/core';
import { CollectApiServiceBase } from './collect-api-base.service';
import { Folder } from '../models/site-content/folder.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Logging } from '../logging';
import { FormInstance } from '../models/site-content/form-instance.model';
import { AsyncJobService } from './async-job.service';
import { AsyncJob } from '../models/async-job.model';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { BrowserStorageService } from './browserstorage.service';
import { ListViewEnum, ListViewHelper } from '../components/list-view/list-view.helper';
import { Form } from '../models/form-builder/form.model';

@Injectable()
export class FolderService extends CollectApiServiceBase<Folder> {

    constructor(
        http: HttpClient,
        progressIndicatorService: ProgressIndicatorService,
        private jobService: AsyncJobService,
        private localStorageService: BrowserStorageService) {
        super(http, progressIndicatorService, environment.apiUrl, 'folder', Folder)
    }

    formatResponse(data: Folder): Folder {
        let obj = plainToClass(Folder, data);
        return (obj);
    }

    public create(item: Folder): Promise<Folder> {
        let result = super.create(item);
        return result;
    }

    // pharv - 7/29/2022 for VNEXT-303
    public getAvailableForms(folderId: number): Promise<Form[]> {
        let url = `${this.url}/${this.endpoint}/${folderId}/AvailableForms/`;
        return this.http.get<Form[]>(url)
            .toPromise()
            .then(res => {
                // 07-26-2024 note:  updating this code to return an array of Form instances, not instances of
                //                   zombie objects with only the data properites of an instance of class Form.
                let resultForms: Form[] = [];
                if (res != null) {
                    for (let index: number = 0; index < res.length; index++) {
                        let zombieObject: Object = res[index];
                        let formObject: Form = plainToClass(Form, zombieObject);
                        resultForms.push(formObject);
                    }
                }
                //return res;
                return resultForms;
            });
    }

    // Array of Folder Ids the user has permission to add Forms to
    public getWriteableFolderIds(dataCollectionId: number): Promise<number[]> {
        let baseUrl = this.url.endsWith('/') ? this.url : `${this.url}/`;
        let url = `${baseUrl}${this.endpoint}/GetWriteableFolders/${dataCollectionId}`;

        return this.http.get<number[]>(url).toPromise().then(response => {
            return response;
        });
    }

    // Returns a Folder whose collections of Folders and FormIstances honor the offset, maxToReturn and sortConfig an filter params
    public getWithPagination(folderId: number, showProgress = true, offset: number = 0, maxToReturn: number = ListViewHelper.PAGE_SIZE, filterTerm: string = null): Promise<Folder> {

        let sortObj = ListViewHelper.getSortObjFor(ListViewEnum.STRUCTURE) || ListViewHelper.DEFAULT_SORT;
        if (sortObj?.active == null || sortObj.active == 'position' || sortObj.active == ListViewHelper.NO_SORT) {
            sortObj.active = 'DEFAULT_SORT';
            sortObj.direction = 'asc';
        }

        let url: string = `${this.url}/${this.endpoint}/getWithPagination/${folderId}/${offset}/${maxToReturn}/${sortObj.active}/${sortObj.direction}`;
        if (showProgress) this.updateProgress(25, 75, "Loading folder contents...");
        if (filterTerm != null) {
            url = `${url}?q=${filterTerm}`;
        }

        return this.http.get<Folder>(url)
            .toPromise()
            .then(res => {
                let folder = this.formatResponse(res);
                if (showProgress) this.hideProgressIndicator();
                return folder;
            });
    }

    public getRootFolderForSite(siteId: number): Promise<Folder> {
        let url = `${this.url}/${this.endpoint}/getRootFolderForSite/${siteId}`;
        this.updateProgress(25, 50, "Loading site structure...");

        let lStartTime: number = performance.now();

        return this.http.get<Folder>(url)
            .toPromise()
            .then(response => {
                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let folder = this.formatResponse(response);

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);
                this.hideProgressIndicator();
                return folder;
            })
            .catch(this.handleError);
    }

    public createRoleGroup(folderId: number, members: any[]): Promise<any[]> {
        let url = `${this.url}/${this.endpoint}/${folderId}/CreateRoleGroup`;
        return this.http.post<any[]>(url, members)
            .toPromise()
            .then(members => {
                return members;
            })
            .catch(this.handleError);
    }

    public updateRoleGroup(folderId: number, roleId: number, members: any[]): Promise<any[]> {
        let url = `${this.url}/${this.endpoint}/${folderId}/UpdateRoleGroup/${roleId}`;
        return this.http.put<any[]>(url, members)
            .toPromise()
            .then(members => {
                return members;
            })
            .catch(this.handleError);
    }

    public deleteRoleGroup(folderId: number): Promise<any> {
        let url = `${this.url}/${this.endpoint}/${folderId}/DeleteRoleGroup`;
        return this.http.delete<any[]>(url)
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public reorderChildFolders(orderObj: any, idOfParentFolder: number): Promise<any> {
        let url = `${this.url}/${this.endpoint}/repositionChildren/${idOfParentFolder}`;

        return this.http.put<any[]>(url, orderObj)
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public rename(item: Folder): Promise<Folder> {
        let url = `${this.url}/${this.endpoint}/rename/`;

        this.updateProgress(50, 75, `Renaming folder ...`);
        return this.http.put<Folder>(url, item)
            .toPromise()
            .then(response => {
                let obj = this.formatResponse(response);
                this.updateProgress(100, 100, `Renamed folder`);
                return obj;
            })
            .catch(this.handleError);
    }

    public reorderFormInstances(orderObj: any, idOfParentFolder): Promise<Folder> {
        let url = `${this.url}/${this.endpoint}/reorderFormInstances/${idOfParentFolder}`;

        return this.http.put<any[]>(url, orderObj)
            .toPromise()
            .then(response => {
                return response;
            })
            .catch(this.handleError);
    }

    public moveFolder(idOfMovedFolder: number, idOfNewParent: number, progressConfig: ProgressConfig = ProgressConfig.default()) {
        let msg = progressConfig.msgDuring || this.progressMsg('Moving', 'Folder');
        this.updateProgress(50, 75, msg);
        let url = `${this.url}/${this.endpoint}/move/${idOfMovedFolder}/into/${idOfNewParent}`;
        return this.http.put<any[]>(url, {})
            .toPromise()
            .then(res => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Moved', 'Folder');
                this.updateProgress(100, 100, msg);
                return res;
            })
            .catch(this.handleError);
    }

    public moveFolders(folderIds: number[], idOfNewParent: number, progressConfig: ProgressConfig = ProgressConfig.default()) {
        let msg = progressConfig.msgDuring || this.progressMsg('Moving', 'Folder(s)');
        this.updateProgress(50, 75, msg);
        let url = `${this.url}/${this.endpoint}/MoveFoldersTo/${idOfNewParent}`;
        return this.http.put<any[]>(url, folderIds)
            .toPromise()
            .then(res => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Moved', 'Folders');
                this.updateProgress(100, 100, msg);
                return res;
            })
            .catch(this.handleError);
    }

    // NOTE: the endpoint is prefixed with "async" to hit AsyncFolderController
    // and the expected response is an AsyncJob which contains the jobKey required to
    // poll for a result
    public copyFolders(idOfNewParent: number, folderIds: number[]): Promise<AsyncJob> {
        let url = `${this.url}/async${this.endpoint}/CopyFoldersTo/${idOfNewParent}`;
        return this.http.post<AsyncJob>(url, folderIds)
            .toPromise()
            .catch(this.handleError);
    }

    public moveFormInstances(formInstanceIds: number[], idOfNewParent: number, progressConfig: ProgressConfig = ProgressConfig.default()) {
        let msg = progressConfig.msgDuring || this.progressMsg('Moving', 'Form Instance');
        this.updateProgress(50, 75, msg);
        let url = `${this.url}/${this.endpoint}/MoveFormInstancesTo/${idOfNewParent}`;
        return this.http.put<any[]>(url, formInstanceIds)
            .toPromise()
            .then(res => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Moved', 'Form Instance(s)');
                this.updateProgress(100, 100, msg);
                return res;
            })
            .catch(this.handleError);
    }

    public moveFormInstance(formInstanceId: number, idOfNewParent: number, progressConfig: ProgressConfig = ProgressConfig.default()) {
        let msg = progressConfig.msgDuring || this.progressMsg('Moving', 'Form Instance');
        this.updateProgress(50, 75, msg);
        let url = `${this.url}/${this.endpoint}/Move/FormInstance/${formInstanceId}/to/${idOfNewParent}`;
        return this.http.put<any[]>(url, {})
            .toPromise()
            .then(res => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Moved', 'Form Instance');
                this.updateProgress(100, 100, msg);
                return res;
            })
            .catch(this.handleError);
    }

    public copyFormInstances(folderId: number, formInstances: FormInstance[], createReferences: boolean): Promise<FormInstance[]> {
        let url = createReferences ?
            `${this.url}/${this.endpoint}/Copy/FormInstanceRefs/To/${folderId}` :
            `${this.url}/${this.endpoint}/Copy/FormInstances/To/${folderId}`;

        this.progressIndicatorService.completed50Percent("Pasting Form Instance(s)...");
        return this.http.post<any[]>(url, formInstances)
            .toPromise()
            .then(fis => {
                this.progressIndicatorService.completed100Percent("Pasted Form Instance(s)");
                let list = [];
                for (let fi of fis) {
                    list.push(Object.assign(new FormInstance(), fi));
                }
                return list;
            })
            .catch(this.handleError)
    }

    public transitionFolder(folder: Folder, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<Folder> {
        let url: string = `${this.url}/${this.endpoint}/${folder.id}/Transition`;

        let msg = progressConfig.msgDuring || this.progressMsg('Updating');
        this.updateProgress(50, 75, msg);
        return this.http.put<Folder>(url, folder)
            .toPromise()
            .then(res => {
                let obj = this.formatResponse(res);
                msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                this.updateProgress(100, 100, msg);

                return obj;
            })
            .catch(this.handleError);
    }

    // Protected methods.
    protected operationCompleted(operationName: string): void {
        // Notify any registered callbacks that the save operation has completed.
        this.notifyAnyCallbacksOperationCompleted(operationName);
    }
}
