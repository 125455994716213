import { Type, plainToClass } from 'class-transformer';

import { IViewModel } from '../../interfaces/view-model.interface';
import { Workflow } from '../site-content/workflow.model';
import { Role } from '../role.model';
import { PermissionPreviewNode } from './permission-preview-node.model';
import { PermissionsPreviewBase } from './permissions-preview-base.model';

export class PermissionsPreview extends PermissionsPreviewBase implements IViewModel {
    // Properties.
    /*
    public id: number; // Note:  this property is not used by is required.

    public dataCollectionId: number; // The data collection for which this preview is being generated.

    // Permissions preview input properties.
    @Type(() => Workflow)
    public workflowHierarchy: Workflow[];
    */

    public selectedRoleNames: string[] = null; // null => return results for all roles.

    // Permissions preview result properties.
    @Type(() => PermissionPreviewNode)
    public permissionNodes: PermissionPreviewNode[];

    public distinctFolderIdList: string;

    // Constructor.
    public constructor(objectToCopy: PermissionsPreview = null) {
        super(objectToCopy);

        if (objectToCopy != null) {
            /*
            this.id = objectToCopy.id;

            this.dataCollectionId = objectToCopy.dataCollectionId;

            this.workflowHierarchy = [];
            if (objectToCopy.workflowHierarchy != null) {
                for (let iWorkflow: number = 0; iWorkflow < objectToCopy.workflowHierarchy.length; iWorkflow++) {
                    let workflowAsObject: Workflow = objectToCopy.workflowHierarchy[iWorkflow];
                    let workflow: Workflow = plainToClass(Workflow, workflowAsObject);

                    this.workflowHierarchy.push(workflow);
                }
            }
            */

            this.selectedRoleNames = objectToCopy.selectedRoleNames;

            this.permissionNodes = [];
            if (objectToCopy.permissionNodes != null) {
                for (let iNode: number = 0; iNode < objectToCopy.permissionNodes.length; iNode++) {
                    let nodeAsObject: PermissionPreviewNode = objectToCopy.permissionNodes[iNode];
                    let permissionNode: PermissionPreviewNode = plainToClass(PermissionPreviewNode, nodeAsObject);

                    this.permissionNodes.push(permissionNode);
                }
            }

            this.distinctFolderIdList = objectToCopy.distinctFolderIdList;
        }
    }
}
