import { IHasIdAndName } from '../interfaces/has-id-and-name.interface';
import { ICloneAndCopy } from '../interfaces/clone-and-copy';

export abstract class WorkflowPermissionBase implements IHasIdAndName, ICloneAndCopy {
    public abstract setId(value: number): void; // Note:  getId() is already defined in interface IHasIdAndName.

    public get id(): number {
        return this.getId();
    }
    public set id(value: number) {
        this.setId(value);
    }

    // Properties.
    public roleId: number = 0;
    public roleName: string = null;
    public roleIsSiteAdmin: boolean = false;

    // Client-only key (not saved in the database).
    public clientId: number = 0; // The client/transition id of the underlying workflow state or workflow transition.

    public canEdit: boolean = false;
    public canView: boolean = false;
    public canGrant: boolean = false;
    public canDelete: boolean = false;
    public canBuildForms: boolean = false;
    public canBuildWorkflows: boolean = false;
    public canBuildFolders: boolean = false;
    public canBuildFormInstances: boolean = false;
    public canBuildRoles: boolean = false;

    public canDoAll: boolean = false; // 08-03-2021 note:  this is only used for the configure roles dialogue.

    //VNEXT-392: KLW - New properties for suggesting permissions
    public suggestCanEdit: boolean = false;
    public suggestCanView: boolean = false;
    public suggestCanGrant: boolean = false;
    public suggestCanDelete: boolean = false;
    public suggestCanBuildForms: boolean = false;
    public suggestCanBuildWorkflows: boolean = false;
    public suggestCanBuildFolders: boolean = false;
    public suggestCanBuildFormInstances: boolean = false;
    public suggestCanBuildRoles: boolean = false;

    // Define a transient, client-only property.
    public transientIsExpanded: boolean = false;
    public transientParentPropertiesSet: boolean = false;

    // Methods.
    public copyFieldsFrom(sourcePermissionBase: WorkflowPermissionBase): void {
        this.roleId = sourcePermissionBase.roleId;
        this.roleName = sourcePermissionBase.roleName;
        this.roleIsSiteAdmin = sourcePermissionBase.roleIsSiteAdmin;

        this.canEdit = sourcePermissionBase.canEdit;
        this.canView = sourcePermissionBase.canView;
        this.canGrant = sourcePermissionBase.canGrant;
        this.canDelete = sourcePermissionBase.canDelete;
        this.canBuildForms = sourcePermissionBase.canBuildForms;
        this.canBuildWorkflows = sourcePermissionBase.canBuildWorkflows;
        this.canBuildRoles = sourcePermissionBase.canBuildRoles;
        this.canBuildFolders = sourcePermissionBase.canBuildFolders;
        this.canBuildFormInstances = sourcePermissionBase.canBuildFormInstances;

        //VNEXT-392: KLW - Set the new properties
        this.suggestCanEdit = sourcePermissionBase.suggestCanEdit;
        this.suggestCanView = sourcePermissionBase.suggestCanView;
        this.suggestCanGrant = sourcePermissionBase.suggestCanGrant;
        this.suggestCanDelete = sourcePermissionBase.suggestCanDelete;
        this.suggestCanBuildForms = sourcePermissionBase.suggestCanBuildForms;
        this.suggestCanBuildWorkflows = sourcePermissionBase.suggestCanBuildWorkflows;
        this.suggestCanBuildRoles = sourcePermissionBase.suggestCanBuildRoles;
        this.suggestCanBuildFolders = sourcePermissionBase.suggestCanBuildFolders;
        this.suggestCanBuildFormInstances = sourcePermissionBase.suggestCanBuildFormInstances;


        return;
    }

    // Default implementation which can be overridden
    public hasPermissions() {
        return (
            this.canView ||
            this.canEdit ||
            this.canGrant ||
            this.canDelete ||
            this.canBuildFolders ||
            this.canBuildFormInstances ||
            this.canBuildForms ||
            this.canBuildRoles ||
            this.canBuildWorkflows);
    }

    // Provide dummy implementations of interfaces IHasIdAndName, ICloneAndCopy.
    // Expect my derived classes to provide actual implementations of these interface.
    //
    // Dummy implementations for IHasIdAndName.
    public getId(): number {
        this.notImplemented('getId');

        return (-1); // Unreachable.
    }

    //public name: string;
    getName(): string {
        this.notImplemented('getName');

        return (null); // Unreachable.
    }
    setName(nameParam: string): void {
        this.notImplemented('setName');
    }

    public typeName(): string {
        this.notImplemented('typeName');

        return (null);
    }

    hasDescriptionField(): boolean {
        return false;
    }
    getDescription(): string {
        return null;
    }
    setDescription(val: string) {
        return;
    }

    public getPropertiesDrawerTitle(): string {
        return null;
    }

    // Define HasIdAndName interface methods that have no meaning for this class.
    public getChangeWorkflowStateDialogTitle(): string { return null; }
    public setChangeWorkflowStateDialogTitle(value: string): void { }

    public getTransitionConfirmationDialogMessage(): string { return null; }
    public setTransitionConfirmationDialogMessage(value: string): void { }

    public getTakeUserToSiteHomePageAfterTransitionApplied(): boolean { return false; }
    public setTakeUserToSiteHomePageAfterTransitionApplied(value: boolean): void { }
    // End HasIdAndName interface methods that have no meaning for this class.

    // Dummy implementations for ICloneAndCopy.
    public clone(): ICloneAndCopy {
        this.notImplemented('clone');

        return (null); // Not reachable.
    }

    copy(objectToCopy: ICloneAndCopy): void {
        this.notImplemented('copy');
    }

    // Private methods.
    private notImplemented(methodName: string): void {
        let errorMsg: string = `WorkflowPermissionBase.${methodName}() is not implemented.`;
        throw (errorMsg);
    }
}
