// Note:  named this file a bit differently so the name would not be excessively long.

import { FormField } from './../form-field.model';
import { FormInstanceElement } from './../form-instance-element.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../interfaces/iform-field-constraint.interface';
import { ICloneAndCopy } from '../../../interfaces/clone-and-copy';
import { FormFieldConstraintBase } from './form-field-constraint-base.model';
import { FormFieldConstraintTypeEnum } from '../../../enums/form-field-constraint-type.enum';
import { CascadingDropDownFormFieldConfig } from '../../cascading-dropdown/cascading-dropdown-config.model';

export class FormFieldListValuesConstraint extends FormFieldConstraintBase implements IFormFieldConstraint, ICloneAndCopy {
    // Properties.

    private selectOptionsValue: string;
    private jsonConfig: string;
    private configForConstraintExpressionSummary: CascadingDropDownFormFieldConfig = null;

    // Constructor.
    protected constructor(constraintType: string, dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        //super(dataCollectionId, constraintId, FormFieldConstraintTypeEnum.SimpleListValues, constraintName);
        super(dataCollectionId, constraintId, constraintType, constraintName);

        if (this.constraintType == FormFieldConstraintTypeEnum.SimpleListValues)
            this.selectOptionsValue = constraintValue;
        else
            this.jsonConfig = constraintValue;
    }

    public static createSimpleListValuesConstraintFrom(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null): FormFieldListValuesConstraint {
        //super(dataCollectionId, constraintId, FormFieldConstraintTypeEnum.SimpleListValues, constraintName);
        let constraint: FormFieldListValuesConstraint = new FormFieldListValuesConstraint(FormFieldConstraintTypeEnum.SimpleListValues, dataCollectionId, constraintId, constraintName, constraintValue);

        constraint.selectOptionsValue = constraintValue;

        return constraint;
    }
    public static createCascadingListValuesConstraintFrom(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null): FormFieldListValuesConstraint {
        //super(dataCollectionId, constraintId, FormFieldConstraintTypeEnum.SimpleListValues, constraintName);
        let constraint: FormFieldListValuesConstraint = new FormFieldListValuesConstraint(FormFieldConstraintTypeEnum.CascadingListValues, dataCollectionId, constraintId, constraintName, constraintValue);

        constraint.jsonConfig = constraintValue;

        return constraint;
    }

    // Getters/setters.
    public get SelectOptionsValue(): string {
        return this.selectOptionsValue;
    }
    public set SelectOptionsValue(value: string) {
        this.selectOptionsValue = value;
    }

    public get JsonConfig(): string {
        return this.jsonConfig;
    }
    public set JsonConfig(value: string) {
        this.jsonConfig = value;
    }

    // Implement IFormFieldConstraint.
    // Property-related methods.
    public get type(): string {
        //return FormFieldConstraintTypeEnum.SimpleListValues;
        return this.constraintType;
    }

    public get constraintExpression(): string {
        let expr: string = null;

        if (this.constraintType == FormFieldConstraintTypeEnum.SimpleListValues)
            expr = this.selectOptionsValue;
        else if (this.constraintType == FormFieldConstraintTypeEnum.CascadingListValues)
            expr = this.jsonConfig;

        return expr;
    }
    public set constraintExpression(value: string) {
        if (this.constraintType == FormFieldConstraintTypeEnum.SimpleListValues)
            this.selectOptionsValue = value;
        else if (this.constraintType == FormFieldConstraintTypeEnum.CascadingListValues)
            this.jsonConfig = value;
    }

    public get constraintExpressionSummary(): string {
        //let constraintExprSummary: string = this.selectOptionsValue;
        let constraintExprSummary: string = '';

        const maxLength: number = 128;
        if ((this.selectOptionsValue != null) && (this.selectOptionsValue.trim() != '')) { //(this.selectOptionsValue.length > maxLength)) {
            constraintExprSummary = this.selectOptionsValue;

            if (this.selectOptionsValue.length > maxLength) {
                constraintExprSummary = '';

                let formField: FormField = new FormField();
                formField.selectOptions = this.selectOptionsValue;
                let selectOptions: string[] = FormField.ParseSelectOptions(formField);

                let index: number = 0;
                while ((index < selectOptions.length) && (constraintExprSummary.length < maxLength)) {
                    if (index > 0)
                        constraintExprSummary += '|';

                    constraintExprSummary += selectOptions[index++];
                }
            }
        } else if ((this.jsonConfig != null) && (this.jsonConfig.trim() != '')) {
            if (this.configForConstraintExpressionSummary == null)
                this.configForConstraintExpressionSummary = CascadingDropDownFormFieldConfig.parseJsonConfig(this.jsonConfig);
            
            if (this.configForConstraintExpressionSummary != null) {
                for (let index: number = 0; index < this.configForConstraintExpressionSummary.dropDownHeaders.length; index++) {
                    let header: string = this.configForConstraintExpressionSummary.dropDownHeaders[index];
                    if (index > 0)
                        constraintExprSummary += ' -> ';
                    constraintExprSummary += header;
                }
            }
        }

        return constraintExprSummary;
    }

    // Logic-related methods.
    public validate(formField: FormField, formInstanceElement: FormInstanceElement): void {
        // TO DO:  IMPLEMENT THIS METHOD.
    }

    // Static methods.
    // constructConstraint() -- a constructor-like method.
    /*
    public static constructConstraint(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        let constraint = new FormFieldListValuesConstraint(dataCollectionId, constraintId, constraintName, constraintValue);

        return constraint;
    }
    */


    // Not relevant for this field type
    public get constraintFailureMessage(): string {
        return null;
    }
    public set constraintFailureMessage(value: string) {
        // noop
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let clone: FormFieldListValuesConstraint = new FormFieldListValuesConstraint(this.constraintType, this.dataCollectionId, this.constraintId, this.constraintName, this.selectOptionsValue);

        return clone;
    }

    public copy(objectToCopyParam: ICloneAndCopy): void {
        let objectToCopy: FormFieldListValuesConstraint = <FormFieldListValuesConstraint>objectToCopyParam;

        this.dataCollectionId = objectToCopy.dataCollectionId;
        this.constraintId = objectToCopy.constraintId;
        this.constraintType = objectToCopy.constraintType;
        this.constraintName = objectToCopy.constraintName;
        this.selectOptionsValue = objectToCopy.selectOptionsValue;
        this.jsonConfig = objectToCopy.jsonConfig;
    }
}

export class FormFieldListValuesConstraintWithMetrics extends FormFieldListValuesConstraint implements IFormFieldConstraintWithMetrics {
    // Properties.
    public useCount: number = 0;

    // Constructor.
    private constructor(constraintType: string, dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        super(constraintType, dataCollectionId, constraintId, constraintName, constraintValue);
    }

    public static createSimpleListValuesConstraintFrom(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null): FormFieldListValuesConstraintWithMetrics {
        let constraint: FormFieldListValuesConstraintWithMetrics = new FormFieldListValuesConstraintWithMetrics(FormFieldConstraintTypeEnum.SimpleListValues, dataCollectionId, constraintId, constraintName, constraintValue);

        constraint.SelectOptionsValue = constraintValue;

        return constraint;
    }
    public static createCascadingListValuesConstraintFrom(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null): FormFieldListValuesConstraintWithMetrics {
        //super(dataCollectionId, constraintId, FormFieldConstraintTypeEnum.SimpleListValues, constraintName);
        let constraint: FormFieldListValuesConstraintWithMetrics = new FormFieldListValuesConstraintWithMetrics(FormFieldConstraintTypeEnum.CascadingListValues, dataCollectionId, constraintId, constraintName, constraintValue);

        constraint.JsonConfig = constraintValue;

        return constraint;
    }
}
