import { IViewModel } from '../../interfaces/view-model.interface';

export class SiteIcon implements IViewModel {
    public id: number;

    public dataCollectionId; number;

    public name: string;
    public description: string;

    public originalFileName: string;
    public iconMimeType: string;

    public iconUrl: string; // The URL that can be used to serve this icon.
    public iconImgTag: string; // The icon's complete <img/> tag.
}
