import { DefaultFieldDefinitionClientLogic } from './default-field-def-client-logic';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { FormField } from '../form-field.model';
import { ConditionalLogicRuleOperator } from '../../field-conditional-logic/rule-operator.model';
import { FormFieldComparisonOperatorNameEnum } from '../../field-conditional-logic/enums';
//import { IOperandSymbolToCompareMethodName, allFieldOperators } from '../../field-conditional-logic/conditional-logic-operators.model';
import { FieldConditionalLogicService, IOperandSymbolToCompareMethodName } from '../../../services/field-conditional-logic.service';

export abstract class BinaryComparisonTypesFieldDefinitionLogicBase extends DefaultFieldDefinitionClientLogic implements IFieldDefinitionLogic {
    // Static data.
    //private static myFieldOperators: ConditionalLogicRuleOperator[] = allFieldOperators.filter(o => o.appliesToTextFields);
    private static myFieldOperators: ConditionalLogicRuleOperator[] = FieldConditionalLogicService.AllTextFieldOperators;

    private static operatorSymbolsToMethodNames: IOperandSymbolToCompareMethodName = null;

    // Constructor.
    public constructor() {
        if (BinaryComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames == null) {
            BinaryComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames = {};

            BinaryComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.Equals] = 'equals';
            BinaryComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.DoesNotEqual] = 'doesNotEqual';
            BinaryComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.InListOfValues] = 'inListOfValues';
            BinaryComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.NotInListOfValues] = 'notInListOfValues';
            BinaryComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.StartsWith] = 'startsWith';
            BinaryComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.EndsWith] = 'endsWith';
            BinaryComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.Contains] = 'contains';
        }

        super();
    }

    // Abstract methods.
    protected abstract formatValueForCompare(value: any): any;

    // IFieldDefinitionLogic methods.
    public getAvailableOperatorsFor(formField: FormField): ConditionalLogicRuleOperator[] {
        return BinaryComparisonTypesFieldDefinitionLogicBase.myFieldOperators;
    }
    public compare(leftOperand: any, operatorSymbol: string, rightOperand: any): boolean {
        let leftFormattedOperand: any = this.formatValueForCompare(leftOperand);
        let rightFormattedOperand: any = this.formatValueForCompare(rightOperand);

        let comparisonMethodName = BinaryComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[operatorSymbol];
        if (comparisonMethodName == null) {
            let errorMsg = `BinaryComparisonTypesFieldDefinitionLogicBase.compare():  this field type does not support comparion operator '${operatorSymbol}'.`;
            throw errorMsg;
        }

        let result: boolean = this[comparisonMethodName](leftFormattedOperand, rightFormattedOperand);
        return result;
    }

    // Helper methods.
    private equals(leftOperand: any, rightOperand: any): boolean {
        return leftOperand == rightOperand;
    }
    private doesNotEqual(leftOperand: any, rightOperand: any): boolean {
        return leftOperand != rightOperand;
    }
    private inListOfValues(leftOperand: any, rightOperand: any): boolean {
        let inResult: boolean = false;

        if ((rightOperand != null) && (rightOperand.toString() != '')) {
            let listOfValues: string[] = rightOperand.toString().split('|');

            if (listOfValues != null) {
                for (let index: number = 0; index < listOfValues.length; index++) {
                    let value: string = listOfValues[index];

                    if (leftOperand == value) {
                        inResult = true;
                        break;
                    }
                }
            }
        }

        return inResult;
    }
    private notInListOfValues(leftOperand: any, rightOperand: any): boolean {
        return !this.inListOfValues(leftOperand, rightOperand);
    }
    private startsWith(leftOperand: any, rightOperand: any): boolean {
        let result: boolean = false;

        if ((leftOperand != null) && (rightOperand != null))
            result = leftOperand.toString().startsWith(rightOperand.toString());

        return result;
    }
    private endsWith(leftOperand: any, rightOperand: any): boolean {
        let result: boolean = false;

        if ((leftOperand != null) && (rightOperand != null))
            result = leftOperand.toString().endsWith(rightOperand.toString());

        return result;
    }
    private contains(leftOperand: any, rightOperand: any): boolean {
        let result: boolean = false;

        if ((leftOperand != null) && (rightOperand != null))
            result = leftOperand.toString().includes(rightOperand.toString());

        return result;
    }
}
