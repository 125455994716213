import { IFormContentElement } from '../form-builder/grid-row.model';

// Define a class used for serializing grid cell data.
export class GridCellDataForSerialization implements IFormContentElement {
    public id: number;

    public valueType: string;

    public intValue: number;
    public doubleValue: number;
    public decimalValue: number;
    public customValue: string;
    public textValue: string;
    public booleanValue: boolean;

    public childFormInstanceElements: GridCellDataForSerialization[];
};
