<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">

<ng-container>
    <!--
            cdkDrag
        cdkDragRootElement=".cdk-overlay-pane"
        cdkDragHandle
        class="draggable-dialogue-header">

    -->
    <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="draggable-dialogue-header">
        {{this.Title}}
    </h1>

    <div mat-dialog-content
         class="dialog-content-div">
        <div class="tree-and-selected-node-div">
            <mat-tab-group [selectedIndex]="0"
                           (selectedIndexChange)="this.objectTypeIndexChanged($event)"
                           class="object-types-mat-tab-group">
                <mat-tab label="Components">
                    <div class="tree-div"
                         (click)="this.treeDivClick($event)">
                        <!-- Display the tree. -->
                        <p-tree id="gfgtree"
                                [style]="{'height':'95%','overflow':'auto'}"
                                [value]="this.TreeNodes"
                                selectionMode="single"
                                [(selection)]="this.SelectedTreeNode"
                                [draggableNodes]="false"
                                [droppableNodes]="false"
                                emptyMessage="..."
                                (onNodeDrop)="this.onTreeNodeDrop($event)"
                                (onNodeSelect)="this.nodeSelect($event)"
                                (onNodeExpand)="this.nodeExpand($event)">
                        </p-tree>

                        <!--<app-component-hierarchy (nodeClicked)="this.handleNodeClicked($event)"></app-component-hierarchy>-->
                    </div>
                </mat-tab>

                <!-- Disable the 'Services' tab for now. -->
                <!--
                <mat-tab label="Services">
                    <div class="services-div">
                        <mat-selection-list #selectedServiceClass
                                            [multiple]="false">
                            <ng-container *ngFor="let serviceClassName of this.serviceClassNames">
                                <mat-list-option [value]="serviceClassName"
                                                 (click)="this.onServiceClassClicked(serviceClassName)">
                                    {{serviceClassName}}
                                </mat-list-option>
                            </ng-container>
                        </mat-selection-list>

                    </div>
                </mat-tab>
                    -->
            </mat-tab-group>

            <!-- Display the selected node, if any. -->
            <div class="selected-node-div">
                <!--*ngIf="!this.ServiceSelected"-->
                <app-selected-component-info #selectedComponentInfoComponent
                                             [selectedComponent]="this.SelectedComponent"></app-selected-component-info>
            </div>
        </div>
    </div>
    <div mat-dialog-actions
         class="mat-dialog-actions-div">
        <button mat-flat-button
                color="primary"
                [disabled]="false"
                style="margin-left: 10px;"
                (click)="this.dismissDialogue()">
            Dismiss
        </button>

        <div class="take-up-space-div"></div>
        <button mat-flat-button
                [disabled]="false"
                style="margin-left: 0px; background: lightblue;"
                (click)="this.loadTestActions()">
            Load Actions ...
        </button>
        <button mat-flat-button
                [disabled]="this.ReplayDisabled"
                style="margin-left: 10px; background: lightblue;"
                (click)="this.replayTest()">
            Replay
        </button>
        <div class="take-up-space-div"></div>

        <button mat-flat-button
                [disabled]="false"
                style="margin-left: 10px; background: lightblue;"
                (click)="this.displayLogs()">
            Logs ...
        </button>
        <button mat-flat-button
                [disabled]="false"
                style="margin-left: 10px; background: lightblue;"
                (click)="this.clearLogs()">
            Clear Logs
        </button>
    </div>
</ng-container>
