import { Token } from '../../models/parse/token.model';
import { TokenCode } from '../../models/parse/token-code.enum';

export class Tokenizer {
    // Constants.
    private static readonly ZERO: string = '0';
    private static readonly NINE: string = '9';

    private static readonly LITTLE_A: string = 'a';
    private static readonly LITTLE_Z: string = 'z';
    private static readonly BIG_A: string = 'A';
    private static readonly BIG_Z: string = 'Z';

    // Instance data.
    private strInput: string = null;
    private iInputLength: number = -1;
    private iInputIndex: number = 0;

    private token: Token = null;
    private strTokenValue: string = null;
    private bTokenPutBack: boolean = false;

    // Construcor.
    public constructor(strInputParam: string) {
        this.strInput = strInputParam;
        this.iInputLength = (this.strInput != null ? this.strInput.length : 0);
        this.iInputIndex = 0;

        this.token = null;
        this.strTokenValue = null;
        this.bTokenPutBack = false;

        return;
    }

    // Methods.
    // Get token method.
    public getToken(): Token {
        if (this.bTokenPutBack) {
            this.bTokenPutBack = false;

            return (this.token);
        } else if (this.iInputIndex < this.iInputLength) {
            let ch = ' ';
            let bEndOfInput: boolean = false;
            let bCharIsWhiteSpace: boolean = false;

            // Eat white space.
            do {
                if (this.iInputIndex < this.iInputLength) {
                    ch = this.getChar();

                    bCharIsWhiteSpace = this.isWhiteSpace(ch);
                } else {
                    bEndOfInput = true;

                    this.token = new Token(TokenCode.TOKEN_END_OF_INPUT);
                }
            } while ((!bEndOfInput) && bCharIsWhiteSpace);

            // If we are not at the end of the 
            // input, see what token we might have.
            if (!bEndOfInput) {
                try {
                    if (ch == '(') {
                        this.token = new Token(TokenCode.TOKEN_LEFT_PAREN, "(");
                    }
                    else if (ch == ')') {
                        this.token = new Token(TokenCode.TOKEN_RIGHT_PAREN, ")");
                    } else if (ch == ',') {
                        this.token = new Token(TokenCode.TOKEN_COMMA, ",");
                    } else if (ch == ';') {
                        this.token = new Token(TokenCode.TOKEN_SEMICOLON, ",");
                    } else if (ch == '\'') {
                        this.strTokenValue = this.getStringToken('\'');
                        this.token = new Token(TokenCode.TOKEN_STRING_SINGLE_QUOTES, this.strTokenValue);
                    } else if (this.isDigit(ch)) {
                        this.strTokenValue = this.getNumberToken(ch);
                        this.token = new Token(TokenCode.TOKEN_NUMBER, this.strTokenValue);
                    }
                    else if (this.isAscii(ch)) {
                        this.strTokenValue = this.getIdentifierOrKeywordTokenText(ch);
                        //this.token = new Token(TokenCode.TOKEN_IDENTIFIER, this.strTokenValue);
                        this.token = Tokenizer.getIdentifierOrKeywordTokenFromText(this.strTokenValue);
                    }
                    else {
                        this.token = new Token(TokenCode.TOKEN_SYNTAX_ERROR);
                    }
                }
                catch (Exception) {
                    this.token = new Token(TokenCode.TOKEN_SYNTAX_ERROR);
                }
            }
        } else {
            // There is no more input.
            this.token = new Token(TokenCode.TOKEN_END_OF_INPUT);
        }

        // Return the token.
        return (this.token);
    }

    // Helper methods.
    private getChar(): string {
        let ch: string = '\0';

        if (this.iInputIndex < this.iInputLength) {
            ch = this.strInput[this.iInputIndex++];
        } else {
            this.syntaxError();
        }

        return (ch);
    }

    private putBackChar(): void {
        if (this.iInputIndex > 0) {
            this.iInputIndex -= 1;
        }
        else {
            this.syntaxError();
        }

        return;
    }

    private getStringToken(chQuote: string): string {
        let tokenBuf: string = '';

        if (this.iInputIndex < this.iInputLength) {
            // *************************
            // Get the string characters.
            // *************************

            let ch = this.getChar();

            let bEndOfInput = false;
            let bGotQuoteChar = false;

            do {
                tokenBuf += ch;

                if (this.iInputIndex < this.iInputLength) {
                    ch = this.getChar();

                    bGotQuoteChar = (ch == chQuote ? true : false);

                    /*
                    // If the string literal is a "'" character, and 
                    // we are encountering a "'s" value, then keep parsing the string.

                    if (bGotQuoteChar)
                    {
                        char nextCh = this.peekChar();

                        if ((nextCh != '\0') && (nextCh == chQuote))
                        {
                            bGotQuoteChar = false;
                            this.skipChar();
                        }
                    }
                    */
                }
                else {
                    bEndOfInput = true;
                }
            } while ((!bEndOfInput) && (!bGotQuoteChar));

            // **************************
            // Did we complete the string? 
            // **************************

            if (bGotQuoteChar) {
                // Nothing more to do.
            }
            else {
                this.syntaxError();
            }
        }

        // ****************************
        // Return the contructed symbol.
        // ****************************

        //string strValue = tokenBuf.ToString();

        return tokenBuf;

    }

    private getNumberToken(chDigit: string): string {
        let tokenBuf = '';

        tokenBuf += chDigit;

        // Scan remaining numbers, if any.
        if (this.iInputIndex < this.iInputLength) {
            let ch = this.getChar();

            let bEndOfInput = false;

            while (this.isDigit(ch) && (!bEndOfInput)) {
                tokenBuf += ch;

                if (this.iInputIndex < this.iInputLength) {
                    ch = this.getChar();
                }
                else {
                    bEndOfInput = true;
                }
            }

            {
                // Note:  this method does not presently contain logic to
                //        scan a decimal point and any ensuing numbers.
            }

            if (!bEndOfInput) {
                this.putBackChar();
            }
        }

        // Return the number token value.

        return tokenBuf;
    }

    private getIdentifierOrKeywordTokenText(chLetter: string): string {
        let tokenBuf: string = '';

        tokenBuf += chLetter;

        // Get remaining identifier characters, if any.
        if (this.iInputIndex < this.iInputLength) {
            let ch = this.getChar();

            let bEndOfInput = false;

            while ((!bEndOfInput) && (this.isAscii(ch) || this.isDigit(ch) || ch == '_')) {
                tokenBuf += ch;

                if (this.iInputIndex < this.iInputLength) {
                    ch = this.getChar();
                } else {
                    bEndOfInput = true;
                }
            } // while

            // Put back a character if we 
            // are not at the end of input.
            if (!bEndOfInput) {
                this.putBackChar();
            }
        } // if

        // Return the scanned identifier.

        return tokenBuf;
    }

    private static getIdentifierOrKeywordTokenFromText(strTokenValue: string): Token {
        let token: Token = null;

        if (strTokenValue == 'true')
            token = new Token(TokenCode.TOKEN_TRUE, strTokenValue);
        else if (strTokenValue == 'false')
            token = new Token(TokenCode.TOKEN_FALSE, strTokenValue);
        else if (strTokenValue == 'null')
            token = new Token(TokenCode.TOKEN_NULL, strTokenValue);
        else
            token = new Token(TokenCode.TOKEN_IDENTIFIER, strTokenValue);

        return token;
    }

    private isWhiteSpace(ch: string): boolean {
        let bIsWhiteSpace: boolean =
            (ch == ' ') ||
            (ch == '\r') ||
            (ch == '\n') ||
            (ch == '\t');

        return (bIsWhiteSpace);
    }

    private isAscii(ch: string): boolean {
        let bIsAscii = false;

        if ((ch >= Tokenizer.LITTLE_A) && (ch <= Tokenizer.LITTLE_Z)) {
            bIsAscii = true;
        } else if ((ch >= Tokenizer.BIG_A) && (ch <= Tokenizer.BIG_Z)) {
            bIsAscii = true;
        }

        return (bIsAscii);
    }

    private isDigit(ch: string): boolean {
        let bIsDigit = ((ch >= Tokenizer.ZERO) && (ch <= Tokenizer.NINE));

        return (bIsDigit);
    }

    private syntaxError(): void {
        throw "Syntax error.";
    }
}
