<h1 mat-dialog-title
    class="draggable-dialogue-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
    {{this.Title}}
</h1>

<div mat-dialog-content>
    <mat-form-field appearance="outline" [floatLabel]="'always'" class="textarea">
        <mat-label>
            {{this.Label}}</mat-label>
        <textarea matInput
                  [(ngModel)]="this.options"
                  rows="10"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="primary" class="theme-primary-btn" [mat-dialog-close]="this.options" [disabled]="options==null">OK</button>
    <button mat-flat-button [mat-dialog-close]="null" style="margin-left: 10px;">Cancel</button>
</div>
