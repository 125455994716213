<kendo-grid #grid="remoteBindingDirectiveRef"
            appSiteGridRemoteBinding
            [height]="600"
            [pageSize]="this.PageSize"
            [skip]="this.Skip"
            [sort]="this.Sort"
            scrollable="virtual"
            [rowClass]="Helper.applyClickedRowClassToClickedRow"
            (cellClick)="Helper.setIndexOfClickedRow($event)"
            [(filter)]="Helper.Filters"
            [rowHeight]="20"
            [sortable]="SortSettings"
            [filterable]="true">

    <ng-template kendoGridToolbarTemplate>
        <button *ngIf="ShowAllForAdminButton" class="action-btn" mat-raised-button color="primary" (click)="toggleShowAllForAdmin()">{{ShowAllForAdminButtonText}}</button>
        <button *ngIf="ShowAllForAdminButton" class="action-btn" mat-raised-button color="primary" (click)="handleNewSite()">New Site</button>
        <button *ngIf="ShowAllForAdminButton" class="action-btn" mat-raised-button color="primary" (click)="handleSiteImport()">Import Site</button>
        <ng-container *ngIf="Helper.IsBeingFiltered">
            <button class="clear-all-filters-btn" mat-stroked-button (click)='Helper.handleClearFilters()'>Clear All Filters</button>
        </ng-container>
    </ng-template>


    <kendo-grid-column *ngIf="!ShowAllForAdmin"
                       [width]="40"
                       field="{{FavoriteColumnDef.fieldName}}"
                       title=""
                       filter="boolean"
                       [filterable]="true"
                       [sortable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            <mat-icon class="command-btn" kendoButton (click)="toggleFavorite(dataItem)" title="Fave this Site">{{favIcon(dataItem)}}</mat-icon>
        </ng-template>
        <kendo-grid-messages filterIsTrue="★" filterIsFalse="☆"></kendo-grid-messages>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="ShowAllForAdmin"
                       [width]="40"
                       field="{{FavoriteColumnDef.fieldName}}"
                       title=""
                       [filterable]="false"
                       [sortable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            <mat-icon class="command-btn" kendoButton (click)="toggleSiteAdmin(dataItem)" title="Toggle Site Admin">{{showAllForAdminIcon(dataItem)}}</mat-icon>
        </ng-template>
    </kendo-grid-column>


    <kendo-grid-command-column [width]="CommandColumnWidth" class="!k-text-center">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="command-column-buttons">
                <button class="button" kendoButton kendoGridFocusable (click)="openSite($event, dataItem)" title="Open Site">Open</button>
                <mat-icon *ngIf="ShowDeleteButtons" class="command-btn" kendoButton (click)="deleteSite($event, dataItem)" title="Delete Site">delete</mat-icon>
            </div>
        </ng-template>
    </kendo-grid-command-column>

    <ng-container *ngFor="let columnDef of this.ColumnDefs; let iColIndex=index">
        <kendo-grid-column *ngIf="ShowAllForAdmin || (!ShowAllForAdmin && columnDef.fieldName != 'id')"
                           field="{{columnDef.fieldName}}"
                           title="{{columnDef.title}}"
                           [filterable]="true">
            <!-- [width]="columnDef.width"-->
        </kendo-grid-column>
    </ng-container>
</kendo-grid>
