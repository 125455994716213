import { MetricsBase } from './metrics-base.model';

export class ImportMetricsBase extends MetricsBase {
    // Import summary message.
    //public importSummaryMessage: string;

    // Constructor.
    protected constructor(readonly propertyNames: string[], jsonData: Object) {
        super(propertyNames, jsonData);
    }
}
