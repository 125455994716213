import { Type, plainToClass } from "class-transformer";

import { ICommentInfo } from './icomment-info.interface';
import { CommentUserOrGroupInfo } from './comment-user-or-group';
import { UserTypeEnum } from './user-type.enum';
import { IUserOrGroupInfo } from './iuser-or-group-info.interface';

export class CommentInfo implements ICommentInfo {
    // Static data.
    private static nextClientId: number = 1;

    // Properties.
    public clientId: number;

    public comment: string;

    public commenter: string;

    public commentTimestamp: Date;

    public isSelected: boolean = false;
    public get SelectedText(): string {
        return (this.isSelected ? 'Selected' : 'Select');
    }

    public isExpanded: boolean;

    public get ViewableByText(): string {
        let text = 'Anyone';

        if ((this.viewableByUsersAndGroups != null) && (this.viewableByUsersAndGroups.length > 0)) {
            let numViewableBy: number = this.viewableByUsersAndGroups.length;
            let numUsers: number = this.viewableByUsersAndGroups.filter(vb => vb.userType == UserTypeEnum.User).length;

            if (numUsers == 0)
                text = (this.viewableByUsersAndGroups.length == 1 ? '1 group' : `${numViewableBy} groups`);
            else if (numUsers == numViewableBy)
                text = (this.viewableByUsersAndGroups.length == 1 ? '1 user' : `${numViewableBy} users`);
            else
                text = `${numViewableBy} users/groups`;
        }

        return text;
    }

    @Type(() => CommentUserOrGroupInfo)
    public viewableByUsersAndGroups: CommentUserOrGroupInfo[] = [];

    public isFirstViewableBy(viewableBy: IUserOrGroupInfo): boolean {
        return ((this.viewableByUsersAndGroups != null) && (this.viewableByUsersAndGroups.length >= 1) && (this.viewableByUsersAndGroups[0].clientId == viewableBy.clientId));
    }
    public isLastViewableBy(viewableBy: IUserOrGroupInfo): boolean {
        let isLast = false;
        if ((this.viewableByUsersAndGroups != null) && (this.viewableByUsersAndGroups.length > 0)) {
            let lastIndex = this.viewableByUsersAndGroups.length - 1;
            isLast = (this.viewableByUsersAndGroups[lastIndex].clientId == viewableBy.clientId);
        }
        return isLast;
    }

    public editingEnabled: boolean = false;

    // Constructor.
    public constructor(comment: string, commenter: string, commentTimestamp: Date) {
        this.clientId = CommentInfo.nextClientId++;

        this.comment = comment;
        this.commenter = commenter;
        this.commentTimestamp = commentTimestamp;
    }

    public toggleSelected(): void {
        this.isSelected = !this.isSelected;
    }
}
