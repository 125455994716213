//loader.interceptor.ts
import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

declare let $: any;

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {

    public loadingTimeoutId: any; 
    private showLoadingSpinner: boolean = true;
    public pollingTimeoutId: any;
    private showPollingSpinner: boolean = false;

    constructor(private loaderService: LoaderService) {

        this.showLoadingMask();

        this.loaderService.isLoading.subscribe((v) => {
            if (v) {
                if (!this.showLoadingSpinner) {
                    this.showLoadingSpinner = true;
                    this.loadingTimeoutId = setTimeout(this.showLoadingMask, 600);
                }   
            }
            else {
                this.hideLoadingMask();
                clearTimeout(this.loadingTimeoutId);
                this.showLoadingSpinner = false;
            }
        });

        // PH: Added this to support polling operations where we want "manually" start the spinner
        // animation when polling starts, and stop it when polling completes.
        // Usage:
        // Have LoaderService injected where you need it, then before starting the long-running call do...
        // this.loaderService.isPolling.next(true);
        // and then after polling completes do...
        // this.loaderService.isPolling.next(false);
        this.loaderService.isPolling.subscribe((v) => {
            if (v) {
                if (!this.showPollingSpinner) {
                    this.showPollingSpinner = true;
                    this.pollingTimeoutId = setTimeout(this.showPollingMask, 1000);
                }
            }
            else {
                this.hidePollingMask();
                clearTimeout(this.pollingTimeoutId);
                this.showPollingSpinner = false;
            }
        });
    }


    ngOnInit() {
    }

    private showLoadingMask() {
        $('#loadingMask').modal();
    }
    private hideLoadingMask() {
        $('#loadingMask').modal('hide');
    }

    private showPollingMask() {
        $('#pollingMask').modal();
    }
    private hidePollingMask() {
        $('#pollingMask').modal('hide');
    }
}
