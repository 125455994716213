import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ComponentFactoryResolver, ViewContainerRef, Renderer2, Type as AngularCoreType, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms'; // Used for Reactive Forms
import { ControlType, ValidationInfo, ValidationMessageInfo, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { TextInputFormFieldBaseComponent } from '../input-form-field-base/text-input-form-field-base.component';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';

// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngModel)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-short-text-form-field',
    templateUrl: './short-text-form-field.component.html',
    styleUrls: ['./short-text-form-field.component.scss', '../form-fields.scss'],
    encapsulation: ViewEncapsulation.None,

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ShortTextFormFieldComponent,
            multi: true
        }
    ]
})
export class ShortTextFormFieldComponent extends TextInputFormFieldBaseComponent implements OnInit, AfterViewInit {
    // Properties.
    // Note:  inputs have been moved into my base class.

    @Output() onInit = new EventEmitter();

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.REQUIRED,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.GRID_COLUMN_WIDTH,
            FormFieldPropertyEnum.BLANK_VALUE,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.PLACEHOLDER_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.DEFAULT_VALUE,
            FormFieldPropertyEnum.MAX_LENGTH,
            FormFieldPropertyEnum.DISPLAY_FORMAT, // NOTE:  temporarily using display format to display optional validations.
            FormFieldPropertyEnum.CONSTRAINT,
            FormFieldPropertyEnum.REGEX,
            FormFieldPropertyEnum.READ_ONLY,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT
        ];

    private readonly validationOptions: ValidationInfo[] =
        [
            {
                DISPLAY_NAME: 'None',
                IS_CUSTOM_REGULAR_EXPRESSION: false,
                REGULAR_EXPRESSION: null,
                ERROR_MESSAGE: null
            },
            {
                DISPLAY_NAME: 'Email',
                IS_CUSTOM_REGULAR_EXPRESSION: false,
                REGULAR_EXPRESSION: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                ERROR_MESSAGE: 'must contain a valid email address'
            },
            {
                DISPLAY_NAME: 'Phone Number',
                IS_CUSTOM_REGULAR_EXPRESSION: false,
                REGULAR_EXPRESSION: /^[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]$/,
                ERROR_MESSAGE: 'must contain a phone number in NNN-NNN-NNNN format'
            },
            {
                DISPLAY_NAME: 'Custom Regular Expression',
                IS_CUSTOM_REGULAR_EXPRESSION: true,
                REGULAR_EXPRESSION: null,
                ERROR_MESSAGE: 'must adhere to the configured regular expression constraint'
            }
        ];

    @ViewChild('gridOnlyTextArea') gridOnlyTextAreaElement: ElementRef;

    // Constructor.
    constructor(rendererParam: Renderer2) {
        super(rendererParam);

        this.matInputId = this.generateUniqueId('shortText');

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let validationNames: string[] = [];
        for (let iValidation: number = 0; iValidation < this.validationOptions.length; iValidation++) {
            let validationInfo: ValidationInfo = this.validationOptions[iValidation];

            validationNames.push(validationInfo.DISPLAY_NAME);
        }

        let hshOnInitProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties,
            constraintNames: validationNames,
            customRegexConstraintName: 'Custom Regular Expression'
        };
        hshOnInitProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT] = 190;
        hshOnInitProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT] = 'px';

        return hshOnInitProperties;
    }

    // Override the method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (ShortTextFormFieldComponent);
    }

    // Life cycle methods.
    public ngOnInit(): void {
        // Set a default constraint value.
        if (this.FormField) {
            if (!this.FormField.constraintName) {
                this.FormField.constraintName = "None";
            }
        }

        let hshOnInitProperties = this.getProperties();

        this.onInit.emit(hshOnInitProperties);

        return;
    }

    public ngAfterViewInit(): void {
        // Note:  this is where a @ViewChild() definition should be resolved.
    }

    public get NumGridOnlyTextAreaRows(): string {
        // Note:  if property this.gridOnlyTextAreaElement is resolved, use it.
        let numRowsText: string = '2';

        return numRowsText;
    }

    // Override methods defined in my base class.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                // If I have no value assigned but
                // have a default value, apply it now.
                this.setDefaultTextValue();

                // Use a base class method to
                // set up a decimal form group.
                this.setupTextFormControl();
            } // if (this.controlType === ControlType.REACTIVE_FORMS)
        } // if ((this.Mode === 'preview') || (this.Mode === 'instance'))

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                this.SetupFormControlFromWriteValue();
            } // if (this.controlType === ControlType.REACTIVE_FORMS)
        } // if ((this.Mode === 'preview') || (this.Mode === 'instance'))

        return;
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): string {
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        }

        return (formInstanceElementParam.textValue);
    }

    // Override
    protected buildValidatorFunctionsAndMessages(
        arrValidatorsParam: ValidatorFn[],
        arrValidationMessagesParam: ValidationMessageInfo[]): void {
        // First call super.
        super.buildValidatorFunctionsAndMessages(arrValidatorsParam, arrValidationMessagesParam);

        // If I have a selected validation format, apply it here.
        // Note:  temporarily using display format as an optional validation format.
        if (this.FormField.constraintName && (this.FormField.constraintName.trim() !== '')) {
            let arrValidationOption: ValidationInfo[] = this.validationOptions.filter(vi => vi.DISPLAY_NAME === this.FormField.constraintName);

            if (arrValidationOption && (arrValidationOption.length === 1)) {
                let validationOption: ValidationInfo = arrValidationOption[0];

                // Don't apply both a named validation and a regular expression.
                // A this.FormField.regex expression would have been applied in
                // the above call to the base class's method.
                if (!this.FormField.regex) {
                    arrValidatorsParam.push(Validators.pattern(validationOption.REGULAR_EXPRESSION));

                    arrValidationMessagesParam.push({ type: 'pattern', message: `${this.DisplayName} ${validationOption.ERROR_MESSAGE}` });
                }
            }
        }

        // Done.
        return;
    }
}
