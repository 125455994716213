import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ProgressIndicatorService } from './progress-indicator.service';
import { EventSubscription } from '../models/event-subscription.model';
import { CollectApiServiceBase } from './collect-api-base.service';
import { EventNameEnum } from '../enums/event-subscription.enum';
import { FormInstance } from '../models/site-content/form-instance.model';
import { Folder } from '../models/site-content/folder.model';

@Injectable()
export class EventSubscriptionService extends CollectApiServiceBase<EventSubscription> {

    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'eventSubscription', EventSubscription)
    }

    public getFormInstanceSubscriptions(eventName: EventNameEnum, formInstance: FormInstance): Promise<EventSubscription[]> {
        let url: string = `${this.url}/${this.endpoint}/getFormInstanceSubscriptions/${eventName}/${formInstance.id}`;
        return this.http.get<EventSubscription[]>(url)
            .toPromise()
            .then(res => {
                let ret = [];
                for (let sub of res) {
                    ret.push(this.formatResponse(sub));
                }
                return ret;
            });
    }

    public getFolderSubscriptions(eventName: EventNameEnum, folder: Folder): Promise<EventSubscription[]>  {

        let url: string = `${this.url}/${this.endpoint}/getFolderSubscriptions/${eventName}/${folder.id}`;
        return this.http.get<EventSubscription[]>(url)
            .toPromise()
            .then(res => {
                let ret = [];
                for (let sub of res) {
                    ret.push(this.formatResponse(sub));
                }
                return ret;
            });
    }

    public subscribe(subscriptions: EventSubscription[]) {
        let url: string = `${this.url}/${this.endpoint}/subscribe`;
        return this.http.post(url, subscriptions)
            .toPromise()
            .then(res => {
                return res;
            });

    }

    public unsubscribe(subscriptions: EventSubscription[]) {
        let subscriptionIds = subscriptions.map(x => x.id);
        let url: string = `${this.url}/${this.endpoint}/unsubscribe?optOut=true`;
        return this.http.post(url, subscriptionIds)
            .toPromise()
            .then(res => {

            });
    }

    public unsubscribeFromFormInstance(formInstanceId: number) {
        let url: string = `${this.url}/${this.endpoint}/unsubscribeFromFormInstance/${formInstanceId}`;
        return this.http.delete(url)
            .toPromise()
            .then(res => {

            });
    }

    // Overrides.
    formatResponse(data: EventSubscription): EventSubscription {
        var obj = plainToClass(EventSubscription, data);
        obj.eventName = Object.values(EventNameEnum)[data.eventName];
        return (obj);
    }

}
