import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-http-error',
    templateUrl: './http-error.component.html',
    styleUrls: ['./http-error.component.scss']
})
export class HttpErrorComponent implements OnInit {
    private message: string;
    private url: string;
    constructor(
        private location: Location,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.url = this.router.url;
        if (this.url == '/404') {
            this.message = 'Not Found';
        }
        this.updateUrlStateToRoot();
    }

    public get Message(): string {
        return this.message;
    }

    public get Url(): string {
        return this.url;
    }
    // This updates the url without navigating to it.
    // Enables the user to reload and go to landing page
    private updateUrlStateToRoot() {
        this.location.replaceState('/');
    }

}
