import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { MultiDropdownFieldDefLogic } from './multi-dropdown-field-def-logic';
import { TextFieldDefinitionLogicBase } from './text-logic-base';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';

export class MultiCheckboxFieldDefLogic extends MultiDropdownFieldDefLogic {
    public constructor(){
        super();
    }

    // Sorting.
    public compareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        let compareResult: number = 0;

        // Compare.
        if ((value1.childFormInstanceElements != null) && (value2.childFormInstanceElements != null)) {
            let textValue1: string = MultiCheckboxFieldDefLogic.buildCompareValueFor(formField, value1);
            let textValue2: string = MultiCheckboxFieldDefLogic.buildCompareValueFor(formField, value2);

            compareResult = TextFieldDefinitionLogicBase.staticCompareAscending(textValue1, textValue2);
        } else if (value1.childFormInstanceElements != null) {
            compareResult = 1;
        } else if (value2.childFormInstanceElements != null) {
            compareResult = -1;
        }

        return compareResult;
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        let value: string = '';

        //let selectOptions: string[] = this.SelectOptions;
        let selectOptions: string[] = FormField.ParseSelectOptions(formFieldParam);

        if (formInstanceElementParam.childFormInstanceElements &&
            (formInstanceElementParam.childFormInstanceElements.length > 0)) {
            let iNumValuesSelected: number = 0;

            for (let iChild: number = 0; iChild < formInstanceElementParam.childFormInstanceElements.length; iChild++) {
                let child: FormInstanceElement = formInstanceElementParam.childFormInstanceElements[iChild];

                if (!child.isDeleted) {
                    if ((iChild < selectOptions.length) && (child.booleanValue == true)) {
                        if (iNumValuesSelected > 0) {
                            value += ',';
                        }

                        value += selectOptions[iChild];
                        iNumValuesSelected++;
                    }
                }
            }
        }

        return (value);
    }

    public getPropertiesRequiredByGrid(formField: FormField): any {
        let hshProperties = {
        };
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT] = 100;
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT] = 'px';

        return hshProperties;
    }

    // Helper methods.
    private static buildCompareValueFor(formField: FormField, value: FormInstanceElement): string {
        // Parse select options.
        if (formField.transientParsedSelectOptions == null)
            formField.transientParsedSelectOptions = FormField.ParseSelectOptions(formField);

        let textValue: string = '';

        if (value.childFormInstanceElements != null) {
            for (let childIndex: number = 0; (childIndex < value.childFormInstanceElements.length) && (childIndex < formField.transientParsedSelectOptions.length); childIndex++) {
                if (value.childFormInstanceElements[childIndex].booleanValue == true) {
                    if (textValue != '')
                        textValue += ',';

                    textValue += formField.transientParsedSelectOptions[childIndex];
                }
            }
        }

        return textValue;
    }

    public filterType(): string {
        return 'text';
    }
}
