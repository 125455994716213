import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { InlineContent } from '../models/site-content/inline-content.model';
import { ProgressIndicatorService } from './progress-indicator.service';
import { FormInstance } from '../models/site-content/form-instance.model';

@Injectable({
    providedIn: 'root'
})
export class InlineContentService extends CollectApiServiceBase<InlineContent> {
    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'inlinecontent', InlineContent)
    }

    public getContent(formInstanceId: number, formFieldId: number): Promise<string> {
        const options = {
            responseType: 'text' as const,
        };
        let url = `${this.url}/${this.endpoint}/getContent/${formInstanceId}/${formFieldId}`;
        return this.http.get(url, options)
            .toPromise()
            .then(content => {
                return content.replace('Downloaded Account', '');
            })
            .catch(this.handleError);
    }


    public getUrl(formInstance: FormInstance, formFieldId: number): Promise<string> {
        const options = {
            responseType: 'text' as const,
        };

        let url = `${this.url}/${this.endpoint}/getUrl/${formInstance.id}/${formFieldId}`;
        return this.http.post(url, formInstance, options)
            .toPromise()
            .then(url => {
                return url;
            })
            .catch(this.handleError);
    }

    public OLD_getUrl(formInstanceId: number, formFieldId: number): Promise<string> {
        const options = {
            responseType: 'text' as const,
        };
        
        let url = `${this.url}/${this.endpoint}/getUrl/${formInstanceId}/${formFieldId}`;
        return this.http.get(url, options)
            .toPromise()
            .then(url => {
                return url;
            })
            .catch(this.handleError);
    }

    // THIS IS TEMPORARY JUST FOR DEVELOMENT - THROW IT AWAY
    public getUrlFromGridRow(formInstanceId: number, formFieldId: number): Promise<string> {
        const options = {
            responseType: 'text' as const,
        };
        let url = `${this.url}/${this.endpoint}/getUrlFromGridRow/${formInstanceId}/${formFieldId}`;
        return this.http.get(url, options)
            .toPromise()
            .then(url => {
                return url;
            })
            .catch(this.handleError);
    }
}
