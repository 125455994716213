<div class="site-list-flex-wrapper" (keydown)="onKeyDown($event)"
     (drop)="dropOnViewWhiteSpace($event)"
     (dragover)="allowDrop($event)">
    <div *ngFor="let item of this.FilteredList"
         class="tile"
         [ngClass]="{selected: item.id === selectedItemId }"
         [class.show-tile-border]="!this.ItemIsBeingDraggedBase"
         (click)="onEditClick($event, item); this.rowClicked(item)"
         (contextmenu)="onItemContextMenu($event, item, this.selectedItems(this.Selection)); this.onRightClick(item)"
         title="{{this.getItemTitle(item)}}"
         tabindex="0"
         (keyup.enter)="onEditClick($event, item)"
         droppable
         [dropScope]="'item'"
         (onDrop)="this.onDragDrop($event,item)"
         [class.FormInstance]="this.isTypeFormInstance(item)"
         [class.Attachment]="this.isTypeAttachment(item)"
         (drop)="this.dropGridOrTileObject($event, item)"
         (dragover)="allowDrop($event)">
        <div class="tile-container">

            <div class="tile-icon-actionbuttons">

                <div class="tile-top">
                    <mat-checkbox (click)="$event.stopPropagation(); this.setSelectedItem($event, item)"
                                  (change)="$event ? this.Selection.toggle(item) : null"
                                  [checked]="this.Selection.isSelected(item)"
                                  [ngClass]="{'selected-show': this.Selection.isSelected(item)}"
                                  class="single-action-button checkbox-action-button rounded-checkbox">
                    </mat-checkbox>

                    <div class="action-buttons">
                        <div *ngFor="let btn of itemActionButtons" class="single-action-button">
                            <ng-container *ngIf="btn.appliesTo(item)">
                                <mat-icon *ngIf="btn.iconFormatFor(item) == 'MatIcon'"
                                          [ngClass]="{selected:generateItemActionId(item, btn) === selectedItemActionId}"
                                          title="{{getActionButtonTitle(btn, item)}}"
                                          fontSet="material-icons-outlined"
                                          class="action-icon"
                                          [class]="this.HideActionButtonsClass"
                                          tabindex="0"
                                          (click)="onItemActionClick($event, item, btn)"
                                          (keyup.enter)="onItemActionClick($event, item, btn)">
                                    {{btn.iconFor(item)}}
                                </mat-icon>

                                <mat-icon *ngIf="btn.iconFormatFor(item) == 'SvgIcon'"
                                          svgIcon="{{btn.iconFor(item)}}"
                                          class="action-icon mat-icon-svg"
                                          [class]="this.HideActionButtonsClass"
                                          [ngClass]="{selected:generateItemActionId(item, btn) === selectedItemActionId}"
                                          title="{{getActionButtonTitle(btn, item)}}"
                                          fontSet="material-icons-outlined"
                                          tabindex="0"
                                          (click)="onItemActionClick($event, item, btn)"
                                          (keyup.enter)="onItemActionClick($event, item, btn)">
                                </mat-icon>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="tile-icon-container">
                    <ng-container *ngIf="item.getIconType() == 'SvgIcon'">
                        <!--
                        <i *ngIf="!item.canBeDeleted()"
                           [appIcon] svgIcon="{{item.getIcon()}}" appIconItemType="svgIcon"
                           class="tile-icon"></i>
                        <i *ngIf="item.canBeDeleted()"
                           [appIcon] svgIcon="{{item.getIcon()}}" appIconItemType="svgIcon"
                           class="tile-icon"></i>
                        -->
                        <mat-icon *ngIf="!item.canBeDeleted()"
                                  svgIcon="{{item.getIcon()}}"
                                  class="tile-icon svg-tile-icon"></mat-icon>
                        <mat-icon *ngIf="item.canBeDeleted()"
                                  svgIcon="{{item.getIcon()}}"
                                  class="tile-icon svg-tile-icon"
                                  draggable
                                  [dragScope]="'item'"
                                  [dragData]="item"
                                  (onDragStart)="this.TileItemDragged(item); this.onItemDragStart($event, this.selectedItems(this.Selection, item))"
                                  (onDragEnd)="this.onItemDragEnd($event, this.selectedItems(this.Selection, item))"
                                  [class.FormInstance]="this.isTypeFormInstance(item)"
                                  [class.Attachment]="this.isTypeAttachment(item)"></mat-icon>
                    </ng-container>
                    <ng-container *ngIf="item.getIconType() != 'SvgIcon'">
                        <mat-icon *ngIf="!item.canBeDeleted()"
                                  class="tile-icon"
                                  [appIcon]="item"></mat-icon>
                        <mat-icon *ngIf="item.canBeDeleted()"
                                  class="tile-icon"
                                  [appIcon]="item"
                                  draggable
                                  [dragScope]="'item'"
                                  [dragData]="item"
                                  (onDragStart)="this.TileItemDragged(item); this.onItemDragStart($event, this.selectedItems(this.Selection, item))"
                                  (onDragEnd)="this.onItemDragEnd($event, this.selectedItems(this.Selection, item))"
                                  [class.FormInstance]="this.isTypeFormInstance(item)"
                                  [class.Attachment]="this.isTypeAttachment(item)"></mat-icon>
                    </ng-container>
                </div>
            </div>
            <div class="tile-namePlate">
                <div class="tile-name">{{ item.name }}</div>
                <div class="tile-subText">{{ item.modifiedDate | date: 'MMMM d, YYYY' }}</div>
            </div>
        </div>
    </div>
</div>
