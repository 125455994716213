import { Component, OnInit, HostListener, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';

import { environment } from '../environments/environment';
import { CurrentUserService } from "./security/current-user.service";
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { filter } from 'rxjs/operators';
import { CommunicationService } from './shared/services/communication.service';
import { LandingPageComponent } from './views/landing-page/landing-page.component';
import { ConfirmationDialogComponent, ConfirmationDialogModel } from './shared/dialogs/confirmation/confirmation-dialog.component';
import { LogInOutComponent } from './security/loginout/loginout.component';
import { KeepAliveService } from './shared/services/keep-alive.service';
import { ConfirmationDialogEnums } from './shared/enums/confirmation-dialog.enum';
import { SettingsService } from './shared/services/settings.service';
import { Settings } from './shared/models/settings.model';
import { BrowserStorageService } from './shared/services/browserstorage.service';
import { BrowserStorageEnums } from './shared/enums/browserstorage.enum';
import { MatomoInjector, MatomoTracker } from 'ngx-matomo';
import { UtilityHelper } from './shared/utility.helper';
import { FieldDefinitionService } from './shared/services/field-definition.service';
import { NavigationService } from './shared/services/navigation.service';
import { ScriptableBaseComponent } from './shared/components/scriptable-base/scriptable-base.component';
import { QueryParamsService } from './shared/services/query-params.service';
import { AttachmentService } from './shared/services/attachment.service';
import { FormFieldTypeAndNameService } from './shared/services/form-field-type-and-name.service';
import { CurrentSiteService } from './shared/services/current-site.service';
import { ItemTypeEnum } from './shared/enums/item-type.enum';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],

    //providers: [{ provide: ParentComponentFinder, useExisting: forwardRef(() => ScriptableBaseComponent) }]
})
export class AppComponent extends ScriptableBaseComponent implements OnInit {
    // Properties.
    millsecondsSinceLastInteraction = 0;
    showHeader = true;
    @HostListener('keyup', ['$event'])
    @HostListener('window:mousemove', ['$event'])
    @HostListener('document:ResetIdleTimer', ['$event'])
    resetTimer(event: any) {
        this.millsecondsSinceLastInteraction = 0;
    }

    //VNEXT-1054: KLW - Changes for the interim homepage
    @ViewChild('logInOutComponentId') logInOutComponent: LogInOutComponent;

    private currentUrl: string = '/';
    public skipLink: string = null;
    private settings: Settings;

    public constructor(
        private router: Router,
        private route: ActivatedRoute,
        public currentUserService: CurrentUserService,
        public currentSiteService: CurrentSiteService, //VNEXT-1066: KLW - Code for Banner implementation
        private communicationService: CommunicationService,
        private keepAliveService: KeepAliveService,
        public dialog: MatDialog,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private settingsService: SettingsService,
        private localStorageService: BrowserStorageService,
        private matomoInjector: MatomoInjector,
        private fieldDefinitionService: FieldDefinitionService,
        private navigationService: NavigationService,
        private queryParamsService: QueryParamsService,
        private renderer: Renderer2,
        private dateAdapter: DateAdapter<Date>,
        private attachmentService: AttachmentService,
        private formFieldTypeAndNameService: FormFieldTypeAndNameService,
        elForBaseClass: ElementRef,
    ) {
        super(elForBaseClass);

        this.router.events.pipe(
            filter(e => e instanceof NavigationStart)
        ).subscribe((e: NavigationStart) => {
            let url = e.url.toLowerCase();
            if (url.indexOf('maxhealthcheck') > 0 || url.indexOf('printable-form') > 0) {
                this.showHeader = false;
            }

            //VNEXT-235: KLW - This is to capture the refresh of a page and depending on what is contained in the URL, then redirect to the URL that represents the new
            // workflow or form instance
            if (e.url.toLowerCase().indexOf(("design-workflow/0?folderId").toLowerCase()) > 0) {
                let workflowURLRefresh: string = localStorageService.get(BrowserStorageEnums.NEW_WORKFLOW_REFRESH);

                if (workflowURLRefresh) {
                    this.router.navigate([workflowURLRefresh]);
                    this.clearWorkflowURLRefresh();
                }
            }

            if (e.url.toLowerCase().indexOf(("design/0?folderId").toLowerCase()) > 0) {
                let formInstanceURLRefresh: string = localStorageService.get(BrowserStorageEnums.NEW_FORM_INSTANCE_REFRESH);

                if (formInstanceURLRefresh) {
                    this.router.navigate([formInstanceURLRefresh]);
                    this.clearFormInstanceURLRefresh();
                }
            }

            //VNEXT-235: KLW - Be sure to clear any saved URLs after every route to prevent possible bugs 
            this.clearWorkflowURLRefresh();
            this.clearFormInstanceURLRefresh();

            localStorageService.set(BrowserStorageEnums.GOTO, e.url);
        });

        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        ).subscribe((e: NavigationEnd) => {
            this.navigationService.pushOnUrl(this.currentUrl);
            this.currentUrl = e.url;
            let nav = this.router.getCurrentNavigation();
            if (nav.extras.state != null && nav.extras.state.menuText != null) {
                localStorageService.set(BrowserStorageEnums.PREVNAVBREADCRUMBSLABEL, nav.extras.state.menuText);
            } else {
                localStorageService.set(BrowserStorageEnums.PREVNAVBREADCRUMBSLABEL, 'Go Back');
            }
        });

        this.matomoInjector.init(
            environment.matomoConfig.url,
            environment.matomoConfig.id
        );

        // Make additional icons available to the <mat-icon/> tag.
        this.loadAppMatIcons();

        this.settingsService.loadSettings().then(response => {
            this.settings = response;
            this.listenForTimeout();
        });

        // Initialize the grid field type and name service (which cannot get all required parameters injected, at least not without a null injected value exception).
        this.formFieldTypeAndNameService.initializeFieldTypesAndNames(this.renderer, this.dateAdapter, this.attachmentService, this.dialog);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        setTimeout(() => {
            // PJH - VNEXT-591 - added isForcedLogout check to prevent call to
            // backend when the user has been logged out
            if (this.applicationIsAvailableToTheUser() && !this.currentUserService.isForcedLogout) {
                // Retrieve field definitions so they can be cached.
                this.fieldDefinitionService.getAll().then(fieldDefinitions => {
                    // Note:  nothing more to do here by design.
                });
            }
        }, 100);
    }

    private applicationIsAvailableToTheUser() {
        return this.router.url !== '/unavailable';
    }

    // Getter method(s).
    public get ShowAppChrome(): boolean {
        return !this.queryParamsService.HideAppChrome;
    }

    public blockDrop(ev: DragEvent) {
        UtilityHelper.blockDrop(ev);
    }

    //VNEXT-1054: KLW - Changes for the interim homepage
    //public login() {
    //    this.logInOutComponent.logIn();
    //}

    //VNEXT-235: KLW - Methods to set and clear the URLs for new workflow and form instances
    public setWorkflowURLRefresh(url: string) {
        this.localStorageService.set(BrowserStorageEnums.NEW_WORKFLOW_REFRESH, url);
    }

    public clearWorkflowURLRefresh() {
        this.localStorageService.set(BrowserStorageEnums.NEW_WORKFLOW_REFRESH, '');
    }

    public setFormInstanceURLRefresh(url: string) {
        this.localStorageService.set(BrowserStorageEnums.NEW_FORM_INSTANCE_REFRESH, url);
    }

    public clearFormInstanceURLRefresh() {
        this.localStorageService.set(BrowserStorageEnums.NEW_FORM_INSTANCE_REFRESH, '');
    }

    public appClick(): void {
        return;
    } 

    //VNEXT-1066: KLW - Code for Banner implementation
    public get ShowBanner(): boolean {
        return this.LeftText != '' ||
            this.CenterText != '' ||
            this.RightText != '';
    }

    //VNEXT-1181: KLW - For Banner Color based on environment implementation
    public get BannerColor(): string {
        let retVal: string = '#000030';

        if (environment.bannerColor)
            retVal = environment.bannerColor;

        return retVal;
    }


    public get SealImagePath(): string {
        return "P:\TFS_NEW\DotNetCore\Solutions\Collect.vNext\Collect.vNext.UI\src\assets\images\mono-seal.png";
    }

    public get FileType(): string {
        return ItemTypeEnum.FILE;
    }

    public get LeftText(): string {
        let retVal: string = '';

        if (this.currentSiteService.Site)
            if (this.currentSiteService.Site.leftBannerText)
                retVal = this.currentSiteService.Site.leftBannerText;

        return retVal;
    }

    public get CenterText(): string {
        let retVal: string = '';

        if (this.currentSiteService.Site)
            if (this.currentSiteService.Site.centerBannerText)
                retVal = this.currentSiteService.Site.centerBannerText;

        return retVal;
    }

    public get RightText(): string {
        let retVal: string = '';

        if (this.currentSiteService.Site)
            if (this.currentSiteService.Site.rightBannerText)
                retVal = this.currentSiteService.Site.rightBannerText;

        return retVal;
    }

    public get BackgroundColor(): string {
        let retVal: string = '';

        if (this.currentSiteService.Site)
            if (this.currentSiteService.Site.backgroundColor)
                retVal = this.currentSiteService.Site.backgroundColor;

        return retVal;
    }

    public get TextColor(): string {
        let retVal: string = '';

        if (this.currentSiteService.Site)
            if (this.currentSiteService.Site.textColor)
                retVal = this.currentSiteService.Site.textColor;

        return retVal;
    }


    // handler for the template's <router-outlet> activate event
    // start listening for updates to the skipLink.
    // It's possible that this isn't ultimately needed but leaving it for now as a kind
    // of catch all. Some navigation events in the application reset focus such that hitting
    // TAB takes the user to the Skip Nav link, and so we need to make sure it gets set properly
    // but perhaps these cases should be explored to eliminate such resetting of focus...if
    // we got to that point, we'd only need to set the skip link on the inititial loading
    // of AppComponent and not need to listen for updates
    public doOnActiviation(component: any) {
        //console.log(component.route.snapshot);
        //var url = component.route.snapshot._routerState.url.split('#')[0];
        //this.skipLink = `${url}#main-content`;

        this.communicationService.skipLinkChanged.subscribe(newLink => {
            this.skipLink = newLink;
        });

        if (!(component instanceof LandingPageComponent)) {
            this.showFooter = false;
            return;
        }
        else {
            const landingPageComponent: LandingPageComponent = component;

            this.showFooter = true;

            this.loginEmitterSubscription = landingPageComponent.loginEmitter.subscribe(() => {
                this.logInOutComponent.logIn();
            });
        }
    }

    public loginEmitterSubscription: Subscription;
    public showFooter: boolean = false;

    public get ShowFooter(): boolean {
        return this.showFooter;
    }

    public deactivateAll() {

        this.showFooter = false;

        if (this.loginEmitterSubscription)
            this.loginEmitterSubscription.unsubscribe();
    }

    //VNEXT-1066: KLW - Property to get site name
    public get SiteName() {
        return environment.sitename;
    }

    // If no user activity detected after n minutes, logs the user out after giving them a warning
    private listenForTimeout(): void {
        let interval = 60 * this.settings.interval;           // check every set interval time in minutes
        let timeoutAfter = interval * this.settings.timeoutAfter;   // timeout after x minutes of inactivity    
        let warn = interval * this.settings.warn;            // warn the user after x minutes

        let timeout = setInterval(() => {
            if (this.currentUserService.user != null) {
                this.millsecondsSinceLastInteraction += interval;
                if (this.millsecondsSinceLastInteraction >= timeoutAfter) {
                    clearInterval(timeout);
                    this.logInOutComponent.logOut();
                } else if (this.millsecondsSinceLastInteraction == warn) {
                    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                        data: new ConfirmationDialogModel(
                            `Session Expiration Warning`,
                            `Your session is about to expire. Do you wish to keep using the application?`,
                            { minutes: this.settings.timeoutAfter - this.settings.warn, seconds: 0, hideNo: false, dialogType: ConfirmationDialogEnums.WARNING }
                        )
                    });
                    dialogRef.disableClose = true;
                    dialogRef.afterClosed().subscribe(yes => {
                        if (yes) {
                            this.millsecondsSinceLastInteraction = 0;
                        } else {
                            clearInterval(timeout);
                            this.logInOutComponent.logOut();
                        }
                    });
                } else {
                    //console.log(this.millsecondsSinceLastInteraction / 1000 + ' seconds have passed since user last moved mouse...');
                    this.keepAliveService.pingTheBackend();
                }
            }
        }, interval);
    }

    private loadAppMatIcons(): void {
        // Load SVG icons, giving them names that allow them to be used in the <mat-icon/> tag.
        //
        // Example usage within a component's .html file:
        //     <mat-icon svgIcon="trash"> </mat-icon>

        let bellFillUrl: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bell-fill.svg");
        this.matIconRegistry.addSvgIcon("bell-fill", bellFillUrl);

        let bellOutlineUrl: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bell.svg");
        this.matIconRegistry.addSvgIcon("bell-outline", bellOutlineUrl);

        let notificationAddUrl: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/notification-add.svg");
        this.matIconRegistry.addSvgIcon("notification-add", notificationAddUrl);

        let puzzlePieceUrl: SafeResourceUrl =
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/puzzle_piece.svg");
        this.matIconRegistry.addSvgIcon("puzzle_piece", puzzlePieceUrl);

        let trashUrl: SafeResourceUrl =
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/trash.svg");
        this.matIconRegistry.addSvgIcon("trash", trashUrl);

        this.matIconRegistry.addSvgIcon("plug",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/plug.svg"));

        this.matIconRegistry.addSvgIcon("pencil",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/pencil.svg"));

        this.matIconRegistry.addSvgIcon("logic",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/logic.svg"));
        this.matIconRegistry.addSvgIcon("predicate-logic",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/predicate-logic.svg"));
        this.matIconRegistry.addSvgIcon("predicate-logic-2",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/predicate-logic-2.svg"));

        this.matIconRegistry.addSvgIcon("footnote",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/text-footnote-svgrepo-com.svg"));

        this.matIconRegistry.addSvgIcon("bootstrap-star",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bootstrap-star.svg"));
        this.matIconRegistry.addSvgIcon("bootstrap-star-filled",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bootstrap-star-filled.svg"));
        this.matIconRegistry.addSvgIcon("bootstrap-star-filled-blue",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bootstrap-star-filled-blue.svg"));
        this.matIconRegistry.addSvgIcon("unlock",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bootstrap-unlock.svg"));
        this.matIconRegistry.addSvgIcon("bootstrap-grid",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bootstrap-grid.svg"));
        this.matIconRegistry.addSvgIcon("bootstrap-forward",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bootstrap-forward.svg"));
        this.matIconRegistry.addSvgIcon("bootstrap-tools",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bootstrap-tools.svg"));
        this.matIconRegistry.addSvgIcon("bootstrap-arrows-expand",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bootstrap-arrows-expand.svg"));
        this.matIconRegistry.addSvgIcon("bootstrap-flag",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bootstrap-flag.svg"));
        this.matIconRegistry.addSvgIcon("bootstrap-calculator",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bootstrap-calculator.svg"));
        this.matIconRegistry.addSvgIcon("bootstrap-recycle",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bootstrap-recycle.svg"));

        // pharv
        this.matIconRegistry.addSvgIcon("is-not-site-admin",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/file-person.svg"));
        this.matIconRegistry.addSvgIcon("is-site-admin",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/file-person-fill.svg"));

        // pharv for VNEXT-6
        this.matIconRegistry.addSvgIcon("person-fill",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/person-fill.svg"));
        this.matIconRegistry.addSvgIcon("person-fill-blue",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/person-fill-blue.svg"));
        this.matIconRegistry.addSvgIcon("person-fill-lightgrey",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/person-fill-lightgrey.svg"));

        // Vertical line.
        this.matIconRegistry.addSvgIcon("vertical-line",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/vertical-line.svg"));

        this.matIconRegistry.addSvgIcon("microsoft-excel",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/microsoft-excel-2019.svg"));
        this.matIconRegistry.addSvgIcon("magic-folder",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/magic-folder-2.svg"));
        this.matIconRegistry.addSvgIcon("magic-form",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/magic-form-2.svg"));

        // Material SVG icons (also available in other formats).
        this.matIconRegistry.addSvgIcon("material-new-folder",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-new-folder.svg"));
        this.matIconRegistry.addSvgIcon("material-post-add",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-post-add.svg"));
        this.matIconRegistry.addSvgIcon("material-file-upload",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-file-upload.svg"));
        this.matIconRegistry.addSvgIcon("material-file-download",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-file-download.svg"));
        this.matIconRegistry.addSvgIcon("material-publish",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-publish.svg"));
        this.matIconRegistry.addSvgIcon("material-export-notes",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-export-notes.svg"));

        this.matIconRegistry.addSvgIcon("material-save",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-save.svg"));
        this.matIconRegistry.addSvgIcon("material-restart-alt",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-restart-alt.svg"));
        this.matIconRegistry.addSvgIcon("material-print",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-print.svg"));
        this.matIconRegistry.addSvgIcon("material-file-copy",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-file-copy.svg"));

        this.matIconRegistry.addSvgIcon("material-north",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-north.svg"));
        this.matIconRegistry.addSvgIcon("material-south",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-south.svg"));
        this.matIconRegistry.addSvgIcon("material-decimal-increase",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-decimal-increase.svg"));
        this.matIconRegistry.addSvgIcon("material-decimal-increase-24x24",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/material-decimal-increase-24x24.svg"));

        this.matIconRegistry.addSvgIcon("black-and-white-excel",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/black-and-white-excel.svg"));
    }
}
