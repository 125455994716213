import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { HtmlElementInfo, IComponentElementsByElementType, IDisplayTabNameToElementType } from './html-element-info.model';
import { ISelectedElementInfo } from './iselected-element-info.interface';
import { ElementTypeInfo } from './element-type-info.model';
import { UITestActionRecorderService } from '../../services/ui-test-action-recorder.service';

export class SelectedComponentData implements ISelectedElementInfo { // Note:  presently ISelectedElementInfo only exposes some of the properties in this class?  We will probably change that.
    public component: ITestableComponent;
    public componentIsVisible: boolean = true;

    public componentElementTypes: string[];
    public selectedElementTypeIndex: number = 0;
    public mapOfElementsByType: IComponentElementsByElementType = {};
    public mapOfElementTypesByTabName: IDisplayTabNameToElementType = {};

    public selectedElementName: string = null;
    public selectedElement: HtmlElementInfo = null;
    public selectedElementHasProperties: boolean = false;
    public selectedElementHasActions: boolean = false;
    public selectedElementHasInputs: boolean = false;

    public constructor(selectedComponent: ITestableComponent) {
        this.component = selectedComponent;
    }

    public static handleComponentSelected(component: ITestableComponent, uiTestActionRecorderService: UITestActionRecorderService, recordAction: boolean = false): SelectedComponentData {
        if (recordAction)
            uiTestActionRecorderService.selectComponent(component);

        let selectedComponentInfo: SelectedComponentData = new SelectedComponentData(component);
        selectedComponentInfo.componentElementTypes = selectedComponentInfo.component.elementTypes;
        if ((selectedComponentInfo.componentElementTypes != null) && (selectedComponentInfo.componentElementTypes.length > 0)) {
            for (let index: number = 0; index < selectedComponentInfo.componentElementTypes.length; index++) {
                let elementType: string = selectedComponentInfo.componentElementTypes[index];
                let elements: HtmlElementInfo[] = selectedComponentInfo.component.getElementsOfType(elementType);

                // Save elements by type.
                selectedComponentInfo.mapOfElementsByType[elementType] = elements;

                // Save elements by tab name as well.
                selectedComponentInfo.mapElementsByTabNames(elementType, elements);
            }
        }

        return selectedComponentInfo;
    }

    public mapElementsByTabNames(elementType: string, elements: HtmlElementInfo[]): void {
        for (let elIndex: number = 0; elIndex < elements.length; elIndex++) {
            let elementInfo: HtmlElementInfo = elements[elIndex];

            let elementTypeInfo: ElementTypeInfo;

            if (elementInfo.displayTabName != null) {
                elementTypeInfo = this.mapOfElementTypesByTabName[elementInfo.displayTabName];
                if (elementTypeInfo == null) {
                    elementTypeInfo = new ElementTypeInfo(elementInfo.elementType, [elementInfo.elementSubtype], elementInfo.displayTabName);
                    this.mapOfElementTypesByTabName[elementInfo.displayTabName] = elementTypeInfo;
                } else if (elementTypeInfo.elementSubtypes.find(st => st == elementInfo.elementSubtype) == null)
                    elementTypeInfo.elementSubtypes.push(elementInfo.elementSubtype);
            } else {
                // Use the element type as the tab name.
                elementTypeInfo = this.mapOfElementTypesByTabName[elementType];
                if (elementTypeInfo == null) {
                    elementTypeInfo = new ElementTypeInfo(elementInfo.elementType, [elementInfo.elementSubtype], elementInfo.elementType);
                    this.mapOfElementTypesByTabName[elementType] = elementTypeInfo;
                } else if (elementTypeInfo.elementSubtypes.find(st => st == elementInfo.elementSubtype) == null)
                    elementTypeInfo.elementSubtypes.push(elementInfo.elementSubtype);
            }

            if ((elementInfo.elementType == null) || (elementInfo.elementType.trim() == '') || (elementInfo.displayTabName == null) || (elementInfo.displayTabName.trim() == ''))
                console.log('SelectComponentNodeComponent.handleComponentSelected():  need to add additional metadata.');
            else {
                if (elementTypeInfo.elements == null)
                    elementTypeInfo.elements = [];
                elementTypeInfo.elements.push(elementInfo);
            }
        }

    }
}
