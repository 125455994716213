<h1 mat-dialog-title
    class="draggable-dialogue-header title-h"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
    {{this.Title}}
</h1>

<div mat-dialog-content
     class="edit-and-view-features-div">
    <div class="edit-features-div">
        <mat-form-field appearance="outline"
                        [floatLabel]="'always'"
                        class="content-mat-form-field">
            <mat-label>Enter New Release Features</mat-label>

            <textarea matInput
                      class="current-release-features-textarea"
                      [formControl]="this.EditFormControl"
                      rows="{{this.NumNewFeaturesTextAreaRows}}"
                      cols="{{this.NumNewFeaturesTextAreaCols}}"></textarea>
        </mat-form-field>

    </div>

    <div class="horizontal-spacer-div"></div>

    <div class="view-features-div">
        <small>Preview</small>
        <div [innerHTML]="this.NewReleaseFeaturesHtml"
             class="release-features-preview-div"></div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button
            type="submit"
            color="primary"
            class="theme-primary-btn"
            [disabled]="this.SavingReleaseFeaturesFlag"
            (click)="this.saveReleaseFeatures()">
        {{this.SaveButtonText}}
    </button>

    <button mat-flat-button
            [disabled]="false"
            style="margin-left: 10px;"
            (click)="this.cancelOrCloseClicked()">
        Close
    </button>
</div>
