import { ITestActionRecorderService } from './itest-action-recorder-service.interface';

export enum FunctionExecutionStatus {
    NotStarted = 'NotStarted',
    Completed = 'Completed',
    Verifying = 'Verifying',
    Failed = 'Failed'
}

export interface IScriptableFunction {
    get callableName(): string;

    get minNumParams(): number;
    get maxNumParams(): number;
    validateParams(params: string[]): boolean;

    execute(testActionService: ITestActionRecorderService, params: string[]): FunctionExecutionStatus;
    checkExecutionStatus(testActionService: ITestActionRecorderService, params: string[]): FunctionExecutionStatus;
}
