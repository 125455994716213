import {
    HttpClient
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IAutoCompleteItemData } from '../interfaces/iautocomplete-item-data.interface';
import { IAutoCompleteServiceCall } from '../interfaces/iautocomplete-service-call.interface';
import { Logging } from '../logging';

@Injectable()
export class AutocompleteService // extends CollectApiServiceBase<Autocomplete> {
{
    constructor(private http: HttpClient) {
    }

    public isSearching = new BehaviorSubject(false);
    public totalCount: number = 0;

    search(query: string, max: number = 10, selectedItems: string[], fie_id: number): Promise<IAutoCompleteItemData[]> {
        if ((!query) || (query.length == 0)) { return EMPTY.toPromise(); }

        this.isSearching.next(true);

        var url = `${environment.apiUrl}/api/autocomplete/search/${fie_id}/${query}/${max}`;

        return this.http
            .get<IAutoCompleteServiceCall>(url)
            .toPromise()
            .then(response => {
                var data: IAutoCompleteItemData[] = [];

                response.startsWithResults.forEach(x => {

                    var isSelected: boolean = selectedItems.filter(y => y == x).length > 0;

                    data.push({ item: x, selected: isSelected } as IAutoCompleteItemData);
                });

                response.containsResults.forEach(x => {

                    var isSelected: boolean = selectedItems.filter(y => y == x).length > 0;

                    data.push({ item: x, selected: isSelected } as IAutoCompleteItemData);
                });

                //VNEXT-558: KLW - Implement sorting options by ascending order
                data.sort();

                this.isSearching.next(false);
                this.totalCount = response.totalCount;

                return data;
            })
            .catch(this.handleError);
    }

    startsWith(query: string, max: number = 10, selectedItems: string[], fie_id: number): Promise<IAutoCompleteItemData[]> {
        if ((!query) || (query.length == 0)) { return EMPTY.toPromise(); }

        this.isSearching.next(true);

        var url = `${environment.apiUrl}/api/autocomplete/startswith/${fie_id}/${query}/${max}`;

        return this.http
            .get<IAutoCompleteServiceCall>(url)
            .toPromise()
            .then(response => {
                var data: IAutoCompleteItemData[] = [];

                response.startsWithResults.forEach(x => {

                    var isSelected: boolean = selectedItems.filter(y => y == x).length > 0;

                    data.push({ item: x, selected: isSelected } as IAutoCompleteItemData);
                });

                //VNEXT-558: KLW - Implement sorting options by ascending order
                data.sort();

                this.isSearching.next(false);
                this.totalCount = response.totalCount;

                return data;
            })
            .catch(this.handleError);
    }

    contains(query: string, max: number = 10, selectedItems: string[], fie_id: number): Promise<IAutoCompleteItemData[]> {
        if ((!query) || (query.length == 0)) { return EMPTY.toPromise(); }

        this.isSearching.next(true);

        var url = `${environment.apiUrl}/api/autocomplete/contains/${fie_id}/${query}/${max}`;

        return this.http
            .get<IAutoCompleteServiceCall>(url)
            .toPromise()
            .then(response => {
                var data: IAutoCompleteItemData[] = [];

                response.containsResults.forEach(x => {

                    var isSelected: boolean = selectedItems.filter(y => y == x).length > 0;

                    data.push({ item: x, selected: isSelected } as IAutoCompleteItemData);
                });

                //VNEXT-558: KLW - Implement sorting options by ascending order
                data.sort();

                this.isSearching.next(false);
                this.totalCount = response.totalCount;

                return data;
            })
            .catch(this.handleError);
    }

    handleError(error: Response | any): Promise<any> {
        Logging.log(error);
        return Promise.reject(error.message || error);
    }
}
