declare let $: any; // jQuery

export class HtmlInputValue {
    public constructor(public element: object, public inputTitle: string, private _inputValue: string) {
    }

    // Getters/setters.
    public get inputValue(): string {
        return this._inputValue;
    }
    public set inputValue(value: string) {
        this._inputValue = value;

        $(this.element).val(value);
    }
}
