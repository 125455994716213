import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from "class-transformer";

import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { FormField } from '../models/form-builder/form-field.model';
import { ProgressIndicatorService, ProgressConfig } from './progress-indicator.service';
import { FormFieldSelectOptionsConstraint } from '../models/form-builder/field-constraints/field-select-options-constraint.model';
import { FormFieldRegularExpressionConstraint } from '../models/form-builder/field-constraints/field-regular-expression-constraint.model';
import { FormFieldCascadingDropdownConstraint } from '../models/form-builder/field-constraints/field-cascading-dropdown-constraint.model';
import { FormFieldDateRangeConstraint } from '../models/form-builder/field-constraints/field-date-range-constraint.model';
import { FormFieldNumericRangeConstraint } from '../models/form-builder/field-constraints/field-numeric-range-constraint.model';
import { FormFieldConstraintBase, FormFieldConstraintViewModel } from '../models/form-builder/field-constraints/form-field-constraint-base.model';
import { SiteFormFieldConstraintProperties } from '../models/form-builder/field-constraints/site-form-field-constraint-props.model';
import { CascadingDropdownConfigRow } from '../models/site-metadata/cascading-drop-down-config-row.model';
//import { FormFieldSimpleListValuesConstraint } from '../models/form-builder/field-constraints/field-simple-list-values-constraint.model';
//import { FormFieldCascadingListValuesConstraint } from '../models/form-builder/field-constraints/field-cascading-list-value-constraint.model';
import { FormFieldListValuesConstraint } from '../models/form-builder/field-constraints/form-field-list-values-constraint.model';

@Injectable({
    providedIn: 'root'
})
export class FormFieldService extends CollectApiServiceBase<FormField>{
    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'formField', FormField)
    }

    public formatResponse(data: FormField): FormField {
        let obj = plainToClass(FormField, data);

        return obj;
    }

    // Services methods.
    public getOrCreateMetaDataField(field: FormField): Promise<FormField> {
        let url = `${this.url}/${this.endpoint}/getOrCreateMetaDataField`;

        return this.http.post<FormField>(url, field)
            .toPromise()
            .then(res => {
                return res;
            });
    }

    // Create constraints.
    public createSelectOptionsConstraint(constraint: FormFieldSelectOptionsConstraint): Promise<FormFieldSelectOptionsConstraint> {
        let url = `${this.url}/${this.endpoint}/createSelectOptionsConstraint`;
        this.updateProgress(50, 75, `Creating constraint ...`);

        return this.http.post<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                let result = plainToClass(FormFieldSelectOptionsConstraint, serverResult);
                this.updateProgress(100, 100, `Constraint created`);
                return result;
            })
            .catch(this.handleError);
    }

    public updateSelectOptionsConstraint(constraint: FormFieldSelectOptionsConstraint): Promise<FormFieldSelectOptionsConstraint> {
        let url = `${this.url}/${this.endpoint}/updateSelectOptionsConstraint`;
        this.updateProgress(50, 75, `Updating constraint ...`);

        return this.http.put<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                let result = plainToClass(FormFieldSelectOptionsConstraint, serverResult);
                this.updateProgress(100, 100, `Constraint updated`);
                return result;
            })
            .catch(this.handleError);
    }

    public createRegularExpressionConstraint(constraint: FormFieldRegularExpressionConstraint): Promise<FormFieldRegularExpressionConstraint> {
        let url = `${this.url}/${this.endpoint}/createRegularExpressionConstraint`;
        this.updateProgress(50, 75, `Creating constraint ...`);

        return this.http.post<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                let result = plainToClass(FormFieldRegularExpressionConstraint, serverResult);
                this.updateProgress(100, 100, `Constraint created`);
                return result;
            })
            .catch(this.handleError);
    }

    public updateRegularExpressionConstraint(constraint: FormFieldRegularExpressionConstraint): Promise<FormFieldRegularExpressionConstraint> {
        let url = `${this.url}/${this.endpoint}/updateRegularExpressionConstraint`;
        this.updateProgress(50, 75, `Updating constraint ...`);

        return this.http.put<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                let result = plainToClass(FormFieldRegularExpressionConstraint, serverResult);
                this.updateProgress(100, 100, `Constraint updated`);
                return result;
            })
            .catch(this.handleError);
    }

    public createCascadingDropdownConstraint(constraint: FormFieldCascadingDropdownConstraint): Promise<FormFieldCascadingDropdownConstraint> {
        let url = `${this.url}/${this.endpoint}/createCascadingDropdownConstraint`;
        this.updateProgress(50, 75, `Creating constraint ...`);

        return this.http.post<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                let result = plainToClass(FormFieldCascadingDropdownConstraint, serverResult);
                this.updateProgress(100, 100, `Constraint created`);
                return result;
            })
            .catch(this.handleError);
    }

    public updateCascadingDropdownConstraint(constraint: FormFieldCascadingDropdownConstraint): Promise<FormFieldCascadingDropdownConstraint> {
        let url = `${this.url}/${this.endpoint}/updateCascadingDropdownConstraint`;
        this.updateProgress(50, 75, `Updating constraint ...`);

        return this.http.put<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                let result = plainToClass(FormFieldCascadingDropdownConstraint, serverResult);
                this.updateProgress(100, 100, `Constraint updated`);
                return result;
            })
            .catch(this.handleError);
    }

    public createDateRangeConstraint(constraint: FormFieldDateRangeConstraint): Promise<FormFieldDateRangeConstraint> {
        let url = `${this.url}/${this.endpoint}/createDateRangeConstraint`;
        this.updateProgress(50, 75, `Creating constraint ...`);

        return this.http.post<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                // Note:  the following 'plainToClass()' call is not working,
                //        presumably due to the class's constructor.  Will
                //        plan on using 'plainToClass()' again once the issue
                //        with the constructor is resolved.
                //let result = plainToClass(FormFieldDateRangeConstraint, serverResult);
                /*
                let result = FormFieldDateRangeConstraint.createConstraint(serverResult['dataCollectionId'], serverResult['constraintId'], serverResult['constraintName']);
                result.minDate = serverResult['minDate'];
                result.maxDate = serverResult['maxDate'];
                */
                let result = FormFieldService.constructDateRangeConstraintFrom(serverResult);

                this.updateProgress(100, 100, `Constraint created`);
                return result;
            })
            .catch(this.handleError);
    }

    public updateDateRangeConstraint(constraint: FormFieldDateRangeConstraint): Promise<FormFieldDateRangeConstraint> {
        let url = `${this.url}/${this.endpoint}/updateDateRangeConstraint`;
        this.updateProgress(50, 75, `Updating constraint ...`);

        return this.http.put<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                // See note in the prior method.
                //let result = plainToClass(FormFieldDateRangeConstraint, serverResult);
                let result = FormFieldService.constructDateRangeConstraintFrom(serverResult);

                this.updateProgress(100, 100, `Constraint updated`);
                return result;
            })
            .catch(this.handleError);
    }

    public createNumericRangeConstraint(constraint: FormFieldNumericRangeConstraint): Promise<FormFieldNumericRangeConstraint> {
        let url = `${this.url}/${this.endpoint}/createNumericRangeConstraint`;
        this.updateProgress(50, 75, `Creating constraint ...`);

        return this.http.post<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                // Note:  the following 'plainToClass()' call is not working,
                //        presumably due to the class's constructor.  Will
                //        plan on using 'plainToClass()' again once the issue
                //        with the constructor is resolved.
                //let result = plainToClass(FormFieldNumericRangeConstraint, serverResult);
                let result = FormFieldService.constructNumericRangeConstraintFrom(serverResult);

                this.updateProgress(100, 100, `Constraint created`);
                return result;
            })
            .catch(this.handleError);
    }

    public updateNumericRangeConstraint(constraint: FormFieldNumericRangeConstraint): Promise<FormFieldNumericRangeConstraint> {
        let url = `${this.url}/${this.endpoint}/updateNumericRangeConstraint`;
        this.updateProgress(50, 75, `Updating constraint ...`);

        return this.http.put<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                // See note in prior method.
                //let result = plainToClass(FormFieldNumericRangeConstraint, serverResult);
                let result = FormFieldService.constructNumericRangeConstraintFrom(serverResult);

                this.updateProgress(100, 100, `Constraint updated`);
                return result;
            })
            .catch(this.handleError);
    }

    // Define create and update methods for the two new types of list value constraints:  simple list value constraints and cascading list value constraints.
    public createSimpleListValuesConstraint(constraint: FormFieldListValuesConstraint): Promise<FormFieldListValuesConstraint> {
        let url = `${this.url}/${this.endpoint}/createSimpleListValuesConstraint`;
        this.updateProgress(50, 75, `Creating constraint ...`);

        return this.http.post<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                // Note:  the following 'plainToClass()' call is not working,
                //        presumably due to the class's constructor.  Will
                //        plan on using 'plainToClass()' again once the issue
                //        with the constructor is resolved.
                let result = FormFieldService.constructSimpleListValuesConstraintFrom(serverResult);

                this.updateProgress(100, 100, `Constraint created`);
                return result;
            })
            .catch(this.handleError);
    }

    public updateSimpleListValuesConstraint(constraint: FormFieldListValuesConstraint): Promise<FormFieldListValuesConstraint> {
        let url = `${this.url}/${this.endpoint}/updateSimpleListValueConstraint`;
        this.updateProgress(50, 75, `Updating constraint ...`);

        return this.http.put<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                // See note in prior method.
                let result = FormFieldService.constructSimpleListValuesConstraintFrom(serverResult);

                this.updateProgress(100, 100, `Constraint updated`);
                return result;
            })
            .catch(this.handleError);
    }

    public createCascadingListValuesConstraint(constraint: FormFieldListValuesConstraint): Promise<FormFieldListValuesConstraint> {
        let url = `${this.url}/${this.endpoint}/createCascadingListValuesConstraint`;
        this.updateProgress(50, 75, `Creating constraint ...`);

        return this.http.post<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                // Note:  the following 'plainToClass()' call is not working,
                //        presumably due to the class's constructor.  Will
                //        plan on using 'plainToClass()' again once the issue
                //        with the constructor is resolved.
                let result = FormFieldService.constructCascadingListValuesConstraintFrom(serverResult);

                this.updateProgress(100, 100, `Constraint created`);
                return result;
            })
            .catch(this.handleError);
    }

    public updateCascadingListValuesConstraint(constraint: FormFieldListValuesConstraint): Promise<FormFieldListValuesConstraint> {
        let url = `${this.url}/${this.endpoint}/updateCascadingListValueConstraint`;
        this.updateProgress(50, 75, `Updating constraint ...`);

        return this.http.put<any[]>(url, constraint)
            .toPromise()
            .then(serverResult => {
                // See note in prior method.
                let result = FormFieldService.constructCascadingListValuesConstraintFrom(serverResult);

                this.updateProgress(100, 100, `Constraint updated`);
                return result;
            })
            .catch(this.handleError);
    }

    // Get constraints of various types.
    public getSiteNamedSelectOptions(dataCollectionId: number): Promise<FormFieldSelectOptionsConstraint[]> {
        let url = `${this.url}/${this.endpoint}/getSiteNamedSelectOptions/${dataCollectionId}`;

        return this.http.get<FormFieldSelectOptionsConstraint[]>(url)
            .toPromise()
            .then(selectOptionsList => {
                let resultList: FormFieldSelectOptionsConstraint[] = [];
                if ((selectOptionsList != null) && (selectOptionsList.length > 0)) {
                    for (let index: number = 0; index < selectOptionsList.length; index++) {
                        let selectOptionsData: Object = selectOptionsList[index];
                        let constraint: FormFieldSelectOptionsConstraint = plainToClass(FormFieldSelectOptionsConstraint, selectOptionsData);
                        resultList.push(constraint);
                    }
                }
                return resultList;
            })
            .catch(this.handleError);
    }

    public getSiteNamedRegularExpressions(dataCollectionId: number): Promise<FormFieldRegularExpressionConstraint[]> {
        let url = `${this.url}/${this.endpoint}/getSiteNamedRegularExpressions/${dataCollectionId}`;

        return this.http.get<FormFieldRegularExpressionConstraint[]>(url)
            .toPromise()
            .then(regexList => {
                let resultList: FormFieldRegularExpressionConstraint[] = [];
                if ((regexList != null) && (regexList.length > 0)) {
                    for (let index: number = 0; index < regexList.length; index++) {
                        let regexData: Object = regexList[index];
                        let constraint: FormFieldRegularExpressionConstraint = plainToClass(FormFieldRegularExpressionConstraint, regexData);
                        resultList.push(constraint);
                    }
                }
                return resultList;
            })
            .catch(this.handleError);
    }

    public getSiteNamedCascadingDropdowns(dataCollectionId: number): Promise<FormFieldCascadingDropdownConstraint[]> {
        let url = `${this.url}/${this.endpoint}/getSiteNamedCascadingDropdowns/${dataCollectionId}`;

        return this.http.get<FormFieldCascadingDropdownConstraint[]>(url)
            .toPromise()
            .then(regexList => {
                let resultList: FormFieldCascadingDropdownConstraint[] = [];
                if ((regexList != null) && (regexList.length > 0)) {
                    for (let index: number = 0; index < regexList.length; index++) {
                        let regexData: Object = regexList[index];
                        let constraint: FormFieldCascadingDropdownConstraint = plainToClass(FormFieldCascadingDropdownConstraint, regexData);
                        resultList.push(constraint);
                    }
                }
                return resultList;
            })
            .catch(this.handleError);
    }

    public getSiteNamedDateRanges(dataCollectionId: number): Promise<FormFieldDateRangeConstraint[]> {
        let url = `${this.url}/${this.endpoint}/getSiteNamedDateRanges/${dataCollectionId}`;

        return this.http.get<FormFieldDateRangeConstraint[]>(url)
            .toPromise()
            .then(constraintsList => {
                let resultList: FormFieldDateRangeConstraint[] = [];
                if ((constraintsList != null) && (constraintsList.length > 0)) {
                    for (let index: number = 0; index < constraintsList.length; index++) {
                        let constraintData: Object = constraintsList[index];
                        // pharv - 11/9/2022 -- per notes elsewhere in this Service, plainToClass was not working and was resulting in nulls for minDate
                        let constraint: FormFieldDateRangeConstraint = FormFieldService.constructDateRangeConstraintFrom(constraintData);
                        resultList.push(constraint);
                    }
                }
                return resultList;
            })
            .catch(this.handleError);
    }

    public getSiteNamedNumericRanges(dataCollectionId: number): Promise<FormFieldNumericRangeConstraint[]> {
        let url = `${this.url}/${this.endpoint}/getSiteNamedNumericRanges/${dataCollectionId}`;

        return this.http.get<FormFieldNumericRangeConstraint[]>(url)
            .toPromise()
            .then(constraintsList => {
                let resultList: FormFieldNumericRangeConstraint[] = [];
                if ((constraintsList != null) && (constraintsList.length > 0)) {
                    for (let index: number = 0; index < constraintsList.length; index++) {
                        let constraintData: Object = constraintsList[index];
                        // pharv - 11/9/2022 -- per notes elsewhere in this Service, plainToClass was not working and was resulting in nulls for minValue and maxValue
                        let constraint: FormFieldNumericRangeConstraint = FormFieldService.constructNumericRangeConstraintFrom(constraintData);
                        resultList.push(constraint);
                    }
                }
                return resultList;
            })
            .catch(this.handleError);
    }

    public getSiteNamedListValueConstraints(dataCollectionId: number): Promise<FormFieldListValuesConstraint[]> {
        let url = `${this.url}/${this.endpoint}/getSiteNamedListValueConstraints/${dataCollectionId}`;

        return this.http.get<FormFieldListValuesConstraint[]>(url)
            .toPromise()
            .then(constraintsList => {
                let resultList: FormFieldListValuesConstraint[] = [];
                if ((constraintsList != null) && (constraintsList.length > 0)) {
                    for (let index: number = 0; index < constraintsList.length; index++) {
                        let constraintData: Object = constraintsList[index];
                        let constraint: FormFieldListValuesConstraint = FormFieldService.constructListValuesConstraintFrom(constraintData);
                        resultList.push(constraint);
                    }
                }
                return resultList;
            })
            .catch(this.handleError);
    }

    public getSiteNamedSimpleListValueConstraints(dataCollectionId: number): Promise<FormFieldListValuesConstraint[]> {
        let url = `${this.url}/${this.endpoint}/getSiteNamedSimpleListValueConstraints/${dataCollectionId}`;

        return this.http.get<FormFieldListValuesConstraint[]>(url)
            .toPromise()
            .then(constraintsList => {
                let resultList: FormFieldListValuesConstraint[] = [];
                if ((constraintsList != null) && (constraintsList.length > 0)) {
                    for (let index: number = 0; index < constraintsList.length; index++) {
                        let constraintData: Object = constraintsList[index];
                        // pharv - 11/9/2022 -- per notes elsewhere in this Service, plainToClass was not working and was resulting in nulls for minValue and maxValue
                        let constraint: FormFieldListValuesConstraint = FormFieldService.constructSimpleListValuesConstraintFrom(constraintData);
                        resultList.push(constraint);
                    }
                }
                return resultList;
            })
            .catch(this.handleError);
    }

    public getSiteNamedCascadingListValueConstraints(dataCollectionId: number): Promise<FormFieldListValuesConstraint[]> {
        let url = `${this.url}/${this.endpoint}/getSiteNamedCascadingListValueConstraints/${dataCollectionId}`;

        return this.http.get<FormFieldListValuesConstraint[]>(url)
            .toPromise()
            .then(constraintsList => {
                let resultList: FormFieldListValuesConstraint[] = [];
                if ((constraintsList != null) && (constraintsList.length > 0)) {
                    for (let index: number = 0; index < constraintsList.length; index++) {
                        let constraintData: Object = constraintsList[index];
                        // pharv - 11/9/2022 -- per notes elsewhere in this Service, plainToClass was not working and was resulting in nulls for minValue and maxValue
                        let constraint: FormFieldListValuesConstraint = FormFieldService.constructCascadingListValuesConstraintFrom(constraintData);
                        resultList.push(constraint);
                    }
                }
                return resultList;
            })
            .catch(this.handleError);
    }

    public getAllSiteNamedConstraints(dataCollectionId: number): Promise<SiteFormFieldConstraintProperties[]> {
        let url = `${this.url}/${this.endpoint}/getAllSiteNamedConstraints/${dataCollectionId}`;

        return this.http.get<SiteFormFieldConstraintProperties[]>(url)
            .toPromise()
            .then(constraintsList => {
                let resultList: SiteFormFieldConstraintProperties[] = [];
                if ((constraintsList != null) && (constraintsList.length > 0)) {
                    for (let index: number = 0; index < constraintsList.length; index++) {
                        let constraintData: Object = constraintsList[index];
                        let constraint: SiteFormFieldConstraintProperties = plainToClass(SiteFormFieldConstraintProperties, constraintData);
                        resultList.push(constraint);
                    }
                }
                return resultList;
            })
            .catch(this.handleError);
    }

    // Export a cascading dropdown constraint's configuration.
    public getCascadingDropdownConstraintConfiguration(constraintId: number, wrapFieldDataInDoubleQuotes: boolean, fieldDelimiter: string = ','): Promise<CascadingDropdownConfigRow[]> {
        let url = `${this.url}/${this.endpoint}/getCascadingDropdownConstraintConfiguration/${constraintId}/wrapFields/${wrapFieldDataInDoubleQuotes}/fieldDelimiter/${fieldDelimiter}`;

        return this.http.get<CascadingDropdownConfigRow[]>(url)
            .toPromise()
            .then(configRows => {
                //msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                //this.updateProgress(100, 100, msg);

                //return this.formatAsyncJobResponse(res);
                return configRows;
            })
            .catch(this.handleError);
    }

    // Get a constraint by Id.
    public getNamedConstraint(constraintId: number): Promise<FormFieldConstraintViewModel> {
        let url = `${this.url}/${this.endpoint}/getConstraint/${constraintId}`;

        return this.http.get<FormFieldConstraintBase>(url)
            .toPromise()
            .then(constraintVM => {
                let constraint: FormFieldConstraintViewModel = plainToClass(FormFieldConstraintViewModel, constraintVM);
                return constraint;
            })
            .catch(this.handleError);
    }

    // Delete a constraint.
    public deleteNamedConstraint(constraintId: number): Promise<FormFieldConstraintBase> {
        let url = `${this.url}/${this.endpoint}/deleteNamedConstraint/${constraintId}`;
        this.updateProgress(50, 75, `Deletomg constraint ...`);

        return this.http.delete<FormFieldConstraintBase>(url)
            .toPromise()
            .then(res => {
                this.updateProgress(100, 100, `Constraint deleted`);
                return res;
            })
            .catch(this.handleError);
    }

    // Helper methods.
    private static constructDateRangeConstraintFrom(serverResult: any): FormFieldDateRangeConstraint {
        let result = FormFieldDateRangeConstraint.constructConstraint(serverResult['dataCollectionId'], serverResult['constraintId'], serverResult['constraintName']);
        result.minDate = serverResult['minDate'];
        result.maxDate = serverResult['maxDate'];

        return result;
    }

    private static constructNumericRangeConstraintFrom(serverResult: any): FormFieldNumericRangeConstraint {
        let result = FormFieldNumericRangeConstraint.constructConstraint(serverResult['dataCollectionId'], serverResult['constraintId'], serverResult['constraintName']);
        result.minValue = serverResult['minValue'];
        result.maxValue = serverResult['maxValue'];

        return result;
    }

    private static constructListValuesConstraintFrom(serverResult: any): FormFieldListValuesConstraint {
        let result: FormFieldListValuesConstraint = null;

        if (serverResult != null) {
            if (serverResult['selectOptionsValue'] != null) {
                result = this.constructSimpleListValuesConstraintFrom(serverResult);
            } else {
                result = this.constructCascadingListValuesConstraintFrom(serverResult);
            }
        }
        return result;
    }

    private static constructSimpleListValuesConstraintFrom(serverResult: any): FormFieldListValuesConstraint {
        let result: FormFieldListValuesConstraint = null;

        if (serverResult != null) {
            //result = FormFieldListValuesConstraint.constructConstraint(serverResult['dataCollectionId'], serverResult['constraintId'], serverResult['constraintName']);
            result = FormFieldListValuesConstraint.createSimpleListValuesConstraintFrom(serverResult['dataCollectionId'], serverResult['constraintId'], serverResult['constraintName']);
            result.constraintExpression = serverResult['selectOptionsValue'];
        }

        return result;
    }

    private static constructCascadingListValuesConstraintFrom(serverResult: any): FormFieldListValuesConstraint {
        let result: FormFieldListValuesConstraint = null;

        //result = FormFieldListValuesConstraint.constructConstraint(serverResult['dataCollectionId'], serverResult['constraintId'], serverResult['constraintName']);
        result = FormFieldListValuesConstraint.createCascadingListValuesConstraintFrom(serverResult['dataCollectionId'], serverResult['constraintId'], serverResult['constraintName']);
        result.constraintExpression = serverResult['jsonConfig'];

        return result;
    }

}
