import {
    Component,
    Renderer2,
    OnInit,
    Output,
    EventEmitter,
    Type as AngularCoreType,
    ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Validators, ValidatorFn } from '@angular/forms'; // Used for Reactive Forms
import { ControlType, ValidationInfo, ValidationMessageInfo } from '../form-field-base/form-field-base.component';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { TextInputFormFieldBaseComponent } from '../input-form-field-base/text-input-form-field-base.component';

@Component({
    selector: 'app-html-link-field',
    templateUrl: './html-link-field.component.html',
    styleUrls: ['./html-link-field.component.scss', '../form-fields.scss'], //VNEXT-984: KLW - Fixing issue where instructions weren't showing
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: HtmlLinkFieldComponent,
            multi: true
        }
    ]

})
export class HtmlLinkFieldComponent extends TextInputFormFieldBaseComponent implements OnInit {
    // Properties.
    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.REQUIRED,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.BLANK_VALUE,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.MAX_LENGTH,
            FormFieldPropertyEnum.DISPLAY_FORMAT, // NOTE:  temporarily using display format to display optional validations.
            FormFieldPropertyEnum.CONSTRAINT,
            FormFieldPropertyEnum.READ_ONLY,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT
        ];

    private readonly beginURlText: string = "https://";

    private readonly validationOptions: ValidationInfo[] =
        [
            {
                DISPLAY_NAME: 'HTML Regular Expression',
                IS_CUSTOM_REGULAR_EXPRESSION: true,
                REGULAR_EXPRESSION: /^https:\/\/.*$/,
                ERROR_MESSAGE: 'must begin with https://'
            }
        ];

    @Output() onInit = new EventEmitter();

    // Constructor.
    constructor(rendererParam: Renderer2) {
        super(rendererParam);

        this.matInputId = this.generateUniqueId('html');

        return;
    }

    protected doSetFocus(): void {
        let inputSelector: string = `#${this.matInputId}`;

        // if (inputSelector) {
        //     let matInput: any = this.renderer.selectRootElement(inputSelector);
        //     matInput.focus();
        // }

        return;
    }

    // Life cycle methods.
    public ngOnInit(): void {
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);

        return;
    }

    public get FormControlValue(): string {
        var retVal = "";

        if (this.FormControl != null)
            if (this.FormControl.value != null)
                retVal = this.FormControl.value;

        return retVal;
    }

    public get htmlPlaceHolderText() {
        return this.beginURlText;
    }

    public get htmlDefaultValue() {
        return this.beginURlText;
    }

    public openLink() {
        var linkToOpen = this.FormControlValue;

        if (linkToOpen != "")
            window.open(linkToOpen, '_blank').focus();

        return false;
    }


    // Override some base class methods.
    public getProperties(): any {

        let hshProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties
        };

        return (hshProperties);
    }

    public getFormFieldClass(): AngularCoreType<any> {
        return (HtmlLinkFieldComponent);
    }
    
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                this.SetupFormControlFromWriteValue();

                if (this.FormControlValue == "")
                    this.FormControl.setValue(this.htmlDefaultValue);
            } // if (this.controlType === ControlType.REACTIVE_FORMS)
        } // if ((this.Mode === 'preview') || (this.Mode === 'instance'))

        return;
    }

    // Override
    protected buildValidatorFunctionsAndMessages(
        arrValidatorsParam: ValidatorFn[],
        arrValidationMessagesParam: ValidationMessageInfo[]): void {
        // First call super.
        super.buildValidatorFunctionsAndMessages(arrValidatorsParam, arrValidationMessagesParam);

        arrValidatorsParam.push(Validators.pattern(this.validationOptions[0].REGULAR_EXPRESSION));
        arrValidationMessagesParam.push({ type: 'pattern', message: `${this.DisplayName} ${this.validationOptions[0].ERROR_MESSAGE}` });

        // Done.
        return;
    }
}
