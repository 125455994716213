<h2 mat-dialog-title
    class="draggable-dialogue-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
    {{this.title}}
</h2>

<div *ngIf="!ngFormGroup">Loading...</div>
<div *ngIf="ngFormGroup">
    <mat-dialog-content>
        <div class="container">
            <form *ngIf="ngFormGroup" matForm [formGroup]="ngFormGroup">
                <div class="sub-container">
                    <div class="sub-sub-container1">
                        <div>
                            <mat-form-field appearance="outline" class="input">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="name" required />
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Type</mat-label>
                                <mat-select formControlName="outputType">
                                    <mat-option *ngFor="let type of outputTypes" [value]="type.name">{{type.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="sub-sub-container2">
                        <mat-label>Viewable by:</mat-label>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="radio-group" formControlName="viewableBy">
                            <mat-radio-button class="radio-button" [value]="VIEWABLE_BY_ALL">All</mat-radio-button>
                            <mat-radio-button class="radio-button" [value]="VIEWABLE_BY_SITE_ADMINS">Site Admins Only</mat-radio-button>
                            <mat-radio-button class="radio-button" [value]="VIEWABLE_BY_NONE">No one</mat-radio-button>
                            <mat-radio-button class="radio-button" [value]="VIEWABLE_BY_GROUP">Members of MAX Group...</mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="ShowGroupPicker">
                            <app-max-picker class="max-picker-component"
                                            [maxMemberStyle]="'width: 70%;'"
                                            [addButtonName]="'Set Group'"
                                            [scope]="'groupsOnly'"
                                            (addButtonClicked)="onGroupSet($event)">
                            </app-max-picker>
                        </div>
                        <div *ngIf="ShowSelectedMaxGroup" class="viewable-by-max-group">
                            {{this.ngFormGroup.value.viewableByMaxGroup}} (<a (click)="this.ngFormGroup.value.viewableByMaxGroup = null;">Change</a>)
                        </div>
                    </div>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="text-area">
                        <mat-label>Paste XSLT Here</mat-label>
                        <textarea matInput formControlName="xslt" class="text-area" rows="20"></textarea>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-flat-button
                [disabled]="!this.ngFormGroup.valid || !this.ngFormGroup.dirty"
                type="submit"
                color="primary"
                class="theme-primary-btn"
                [mat-dialog-close]="this.ngFormGroup.value">
            Ok
        </button>
        <button mat-flat-button [mat-dialog-close]="null" style="margin-left: 10px;">Cancel</button>
    </mat-dialog-actions>
</div>
