import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { DataCollection } from '../models/site-content/data-collection.model';
import { CurrentSiteService } from '../services/current-site.service';
import { DataCollectionService } from '../services/data-collection.service';

@Injectable()
export class DataCollectionResolver implements Resolve<DataCollection> {
    constructor(
        private dataCollectionService: DataCollectionService,
        private currentSiteService: CurrentSiteService,
        private router: Router) { }

    // The resolve method here acts as a guard.
    // A route guard wouldn't work because route guards get fired _before_ resolvers
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<DataCollection> {
        let id = route.params['id'];
        // If the current site has already been set, just return it without going to the server
        if (this.currentSiteService.isCurrentSite(id)) {
            return new Promise((resolve, reject) => {
                return resolve(this.currentSiteService.Site)
            })
        } else {
            return this.dataCollectionService.get(id).then(site => {
                if (site && site.id > 0) {
                    this.currentSiteService.Site = site;
                    return this.currentSiteService.Site;
                } else {
                    this.router.navigate(['/404'], { skipLocationChange: true });
                    return null;
                }
            });
        }
    }
}
