import { Type, plainToClass } from 'class-transformer';

import { TargetFieldConditionalLogicInstructions } from './target-field-logic-instructions';

export class SiteHomePageLoadedConditionalLogicInstructions {
    public dataCollectionId: number;

    @Type(() => TargetFieldConditionalLogicInstructions)
    public targetFieldInstructions: TargetFieldConditionalLogicInstructions[];
}
