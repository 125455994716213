import {
    Component,
    OnInit,
    ViewChild,
    Inject
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, forkJoin } from 'rxjs';
import { ItemTypeEnum } from '../../enums/item-type.enum';

import {
    AttachmentService,
    IFileUploadProgressHash,
    FileUploadProgress
} from '../../services/attachment.service';

// Define a class of required Id values.
export class FileUploadDialogKeyValues {
    public dataCollectionId: number;
    public folderId: number;
    public formInstanceId: number;
    public formFieldId: number;
    public attachmentCount: number;
}

// Define a hash of progress/upload values.
export interface IFileUploadProgressValueHash {
    //[fileName: string]: number;
    [fileName: string]: FileUploadProgress;
}

// Define an internally used class.
class FileUploadRequestCallerData {
    public fileName: string;
    public component: FileUploadDialogComponent;
}

class ProgressFileNameAndObservable {
    public fileName: string;
    public observable: Observable<FileUploadProgress>;
}

// Note:  the code in this dialog component was derived from the following tutorial:
//        https://malcoded.com/posts/angular-file-upload-component-with-express/.

@Component({
    selector: 'app-file-upload-dialog',
    templateUrl: './file-upload-dialog.component.html',
    styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {
    // Instance data.
    @ViewChild('file') file;
    //private files: Set<File> = new Set();

    // State-related instance data.
    // Define two related progress hashes and a related array.
    private hshProgressObservables: IFileUploadProgressHash = null;
    private hshProgressValues: IFileUploadProgressValueHash = null;
    private iNumProgressValuesComplete: number = 0;
    //private allProgressFileNamesAndObservables: ProgressFileNameAndObservable[] = null;
    // Define a button text value.
    private primaryButtonText: string = 'Upload';
    // Define status/state values.
    private canBeClosed: boolean = true;
    private showCancelButton: boolean = true;
    private uploading: boolean = false;
    private uploadSuccessful: boolean = false;

    // Constructor.
    constructor(private dialogRef: MatDialogRef<FileUploadDialogComponent>,
        private attachmentService: AttachmentService,
        @Inject(MAT_DIALOG_DATA) public initData: FileUploadDialogKeyValues)
    {
        return;
    }

    // Life cycle methods.
    ngOnInit(): void {
        return;
    }

    // Accessor methods (used by my .html file).
    public get CanBeClosed(): boolean {
        return (this.canBeClosed);
    }

    public get Uploading(): boolean {
        return (this.uploading);
    }

    public get UploadSuccessful(): boolean {
        return (this.uploadSuccessful);
    }

    public get FileType(): string {
        return ItemTypeEnum.FILE;
    }


    files: Array<File> = [];

    public get Files(): Array<File> {
        return (this.files);
    }

    public get Progress(): IFileUploadProgressHash {
        //return (this.progress);
        return (this.hshProgressObservables);
    }

    public GetProgressFor(file: File, index: number): number {
        //let iProgress: number = this.Progress[file.name].progress;
        //let iProgress: number = this.hshProgressValues[file.name];
        let progressInfo: FileUploadProgress = this.hshProgressValues[file.name];
        let iProgress = progressInfo.progressValue;

        if (iProgress == 100)
            this.deleteFile(index);

        return (iProgress);
    }

    public get ShowCancelButton(): boolean {
        return (this.showCancelButton);
    }

    public get PrimaryButtonText(): string {
        return (this.primaryButtonText);
    }

    // Handle control eventes.
    public addFiles(): void {
        this.file.nativeElement.click();

        return;
    }

    //public onFilesAdded(): void {
    //    // Compile the set of selected files.
    //    const files: { [key: string]: File } = this.file.nativeElement.files;

    //    for (let key in files) {
    //        if (!isNaN(parseInt(key))) {
    //            this.files.add(files[key]);
    //        }
    //    }

    //    return;
    //}

    //public static fnProgressSubscribor = function (progressData: FileUploadProgress) {
    public static fnProgressSubscribor(progressData: FileUploadProgress): void {
        //console.log("Received progress ...");

        // Call into a private method
        // to perform the actual work.
        let component: FileUploadDialogComponent = progressData.callerData;

        component.handleFileUploadProgressUpdate(progressData);

        return;
    }

    //public uploadFilesOrCloseDialog = (dummyValue: string) => {
    public uploadFilesOrCloseDialog(): void {
        // if everything was uploaded
        // already, close the dialog.
        if (this.uploadSuccessful) {
            //return this.dialogRef.close();
            this.dialogRef.close(this.hshProgressValues);

            return;
        }

        // set the component state to "uploading"
        this.uploading = true;

        // start the upload and save the progress map
        //this.progress = this.attachmentService.upload(this.files);
        this.hshProgressObservables =
            //this.attachmentService.upload(this.files, this);
            this.attachmentService.uploadFiles(
                this.initData.dataCollectionId,
                this.initData.folderId,
                this.initData.formInstanceId,
                this.initData.formFieldId,
                new Set(this.files),
                this);

        // convert the progress map into an array
        let allProgressObservables = [];
        this.hshProgressValues = {};
        this.iNumProgressValuesComplete = 0;
        //this.allProgressFileNamesAndObservables = [];

        //for (let key in this.progress) {
        //for (let key in this.hshProgressObservables) {
        for (let fileName in this.hshProgressObservables) {
            //allProgressObservables.push(this.progress[key].progress);
            //allProgressObservables.push(this.hshProgressObservables[key].progress);
            //allProgressObservables.push(this.hshProgressObservables[fileName].progress);
            let progressObservable = this.hshProgressObservables[fileName].progress;
            allProgressObservables.push(progressObservable);

            let progressFileNameAndObservable: ProgressFileNameAndObservable =
            {
                fileName: fileName,
                observable: progressObservable
            };
            //this.allProgressFileNamesAndObservables.push(progressFileNameAndObservable);

            let initialProgressInfo: FileUploadProgress = new FileUploadProgress();
            //this.hshProgressValues[fileName] = 0; // zero percent
            this.hshProgressValues[fileName] = initialProgressInfo;

            /*
            let fnProgressSubscribor = function (progressValue: any) {
                // TO DO:  CODE THIS BLOCK.
                console.log("Received progress ...");
            }
            */
            progressObservable.subscribe(FileUploadDialogComponent.fnProgressSubscribor);
        }

        // Adjust the state variables

        // The OK-button should have the text "Finish" now
        this.primaryButtonText = 'Finish';

        // The dialog should not be closed while uploading
        this.canBeClosed = false;
        this.dialogRef.disableClose = true;

        // Hide the cancel-button
        this.showCancelButton = false;

        // When all progress-observables are completed...
        // The call to forkJoin() was not working,
        // so I decided to replace it with code above.
        /*
        forkJoin(allProgressObservables).subscribe(end => {
            // ... the dialog can be closed again...
            this.canBeClosed = true;
            this.dialogRef.disableClose = false;

            // ... the upload was successful...
            this.uploadSuccessful = true;

            // ... and the component is no longer uploading
            this.uploading = false;
        });
        */

        forkJoin(allProgressObservables).subscribe(end => {
            this.dialogRef.close(this.hshProgressValues);
        });

        return;
    }

    currentFileUploaded = '';

    // Implement private helper methods.
    private handleFileUploadProgressUpdate(progressData: FileUploadProgress): void {
        // Update this file's progress value.
        if (this.hshProgressValues[progressData.fileName] != null) {

            this.currentFileUploaded = 'Uploading: ' + progressData.fileName;

            //this.hshProgressValues[progressData.fileName] = progressData.progressValue;
            this.hshProgressValues[progressData.fileName] = progressData;

            if (progressData.progressValue == 100) {
                this.iNumProgressValuesComplete++;
            }

            this.iNumProgressValuesComplete++;

            if (this.iNumProgressValuesComplete == Object.keys(this.hshProgressValues).length) {
                // ... the dialog can be closed again...
                this.canBeClosed = true;
                this.dialogRef.disableClose = false;

                // ... the upload was successful...
                this.uploadSuccessful = true;

                // ... and the component is no longer uploading
                this.uploading = false;

            
            }
        }

        return;
    }


 

    /**
     * on file drop handler
     */
    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    fileEntered: boolean = false;

    onFileEntered(passedVal: boolean) {
        this.fileEntered = passedVal;
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        this.files.splice(index, 1);

        //Do this to avoid ExpressionChangedAfterItHasBeenCheckedError
        //if (this.files.length < 1)
        //    this.hasFiles = false;
    }

    /**
     * Simulate the upload process
     */
    uploadFilesSimulator(index: number) {
        //setTimeout(() => {
        //    if (index === this.files.length) {
        //        return;
        //    } else {
        //        const progressInterval = setInterval(() => {
        //            if (this.files[index].progress === 100) {
        //                clearInterval(progressInterval);
        //                this.uploadFilesSimulator(index + 1);
        //            } else {
        //                this.files[index].progress += 5;
        //            }
        //        }, 200);
        //    }
        //}, 1000);
    }

    //hasFiles: boolean = false;

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            //Check if the files array already contains the file
            if (!this.files.some(file => file.name == item.name))
                this.files.push(item);
        }
        //this.uploadFilesSimulator(0);

        //Do this to avoid ExpressionChangedAfterItHasBeenCheckedError
        //if (this.files.length > 0)
        //    this.hasFiles = true;
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals = 0.0) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

     
     

}
