declare var ExecutionTimeEnvironment: any;

export const environment = {
    debug: false,
    production: false,
    apiUrl: ExecutionTimeEnvironment.WEBAPIURL,
    loginSiteUrl: ExecutionTimeEnvironment.WEBAPIURL,
    loginPage: "login",
    logoutPage: "logout",
    userGrantedAllClaims: false, // THIS IS TEMPORARY!
    suppressUnsavedChangesWarning: false,
    matomoConfig: {
        url: 'https://webmetrics.max.gov/',
        id: 51
    },
    sitename: 'OMB Collect', //VNEXT-1066: KLW - Variable to set site name
    bannerColor: ExecutionTimeEnvironment.BANNER_COLOR //VNEXT-1181: KLW - For Banner Color based on environment implementation
};
