
import { Token } from '../../models/parse/token.model';
import { TokenCode } from '../../models/parse/token-code.enum';
import { Tokenizer } from './tokenizer';
import { Tuple, FunctionCallTuple } from '../../models/parse/tuple.model';
import { ScriptableFunctionService } from '../../services/scriptable-function.service';
import { IScriptableFunction } from '../../interfaces/iscriptable-function';

export class ParsedTestCommands {
    public errorMessage: string = null;
    public tuples: Tuple[] = [];
}

export class UITestDirectiveParser {
    // Properties.
    private tokenizer: Tokenizer = null;
    private token: Token = null;

    // Constructor.
    public constructor() { }

    // Parse method.
    public parseTuples(script: string, scriptableFunctionService: ScriptableFunctionService): ParsedTestCommands {
        //let tuples: Tuple[] = [];
        let parsedCommands: ParsedTestCommands = new ParsedTestCommands();

        // Create my tokenizer.
        this.tokenizer = new Tokenizer(script);

        let endOfInput: boolean = false;
        let firstToken: Token = this.expectAnyToken();
        while (!endOfInput) {
            // Expect an identifier.            
            if (firstToken.Code == TokenCode.TOKEN_IDENTIFIER) {
                let nextToken: Token = this.expectAnyToken();
                if (nextToken.Code == TokenCode.TOKEN_LEFT_PAREN) {
                    // Parse this function call notation.
                    let functionCallTuple: Tuple = this.parseFunctionCall(firstToken);

                    // Validate the function's name and parameters.
                    let functionToken: Token = <Token>functionCallTuple.LeftValue;
                    let functionName: string = functionToken.Value;
                    let functionParams: string[] = <string[]>functionCallTuple.RightValue;
                    let scriptableFunction: IScriptableFunction = scriptableFunctionService.getScriptableFunctionNamed(functionName);
                    if (scriptableFunction == null) {
                        parsedCommands.errorMessage = `UITestDirectiveParser.parseTuples():  cannot find a scriptable function named '${functionName}'.`;
                        endOfInput = true;
                    } else if (!scriptableFunction.validateParams(functionParams)) {
                        parsedCommands.errorMessage = `UITestDirectiveParser.parseTuples():  the parameters for function '${functionName}' could not be validated.`;
                        endOfInput = true;
                    } else {
                        // Things look good.
                        let functionCallTuple: FunctionCallTuple = new FunctionCallTuple(scriptableFunction, functionParams);
                        parsedCommands.tuples.push(functionCallTuple);
                    }

                    if (!endOfInput) {
                        // Start parsing the next command/directive, if any.
                        firstToken = this.expectAnyToken();
                        if (firstToken.Code == TokenCode.TOKEN_SEMICOLON)
                            firstToken = this.expectAnyToken();
                    }
                } else {
                    //this.syntaxError();
                    parsedCommands.errorMessage = this.SyntaxErrorMessage;
                    endOfInput = true;
                }
            } else if (firstToken.Code == TokenCode.TOKEN_END_OF_INPUT) {
                endOfInput = true;
            } else {
                //this.syntaxError();
                parsedCommands.errorMessage = this.SyntaxErrorMessage;
                endOfInput = true;
            }                
        } 

        //return tuples;
        return parsedCommands;
    }

    public parseTuple(inputParam: string): Tuple {
        let result: Tuple = null;

        // Create my tokenizer.
        this.tokenizer = new Tokenizer(inputParam);

        // We only expect to see an identifier, a string,
        // a number, or a function call (which starts with
        // an identifier).

        let idOrStringToken: Token = this.expectAnyToken(); 
        if ((idOrStringToken.Code != TokenCode.TOKEN_IDENTIFIER) &&
            (idOrStringToken.Code != TokenCode.TOKEN_STRING_SINGLE_QUOTES) &&
                (idOrStringToken.Code != TokenCode.TOKEN_NUMBER))
        {
            this.syntaxError();
        }

        let nextToken: Token = this.expectAnyToken();

        if (nextToken.Code == TokenCode.TOKEN_END_OF_INPUT)
        {
            result = new Tuple(idOrStringToken.Code, idOrStringToken.Value);
        } else if ((idOrStringToken.Code == TokenCode.TOKEN_IDENTIFIER) && (nextToken.Code == TokenCode.TOKEN_LEFT_PAREN))
            result = this.parseFunctionCall(idOrStringToken);
        else
            this.syntaxError();

        return result;
    }

    // Helper methods.
    private parseFunctionCall(idToken: Token): Tuple {
        let functionCallTuple: Tuple = null;

        // Get the first parameter.
        let firstParamOrRightParen: Token = this.expectAnyToken();
        if (firstParamOrRightParen.Code == TokenCode.TOKEN_RIGHT_PAREN)
        {
            // This function call has zero parameters.
        }
        else if ((firstParamOrRightParen.Code != TokenCode.TOKEN_STRING_SINGLE_QUOTES) &&
            (firstParamOrRightParen.Code != TokenCode.TOKEN_NUMBER) &&
            (firstParamOrRightParen.Code != TokenCode.TOKEN_TRUE) &&
            (firstParamOrRightParen.Code != TokenCode.TOKEN_FALSE) &&
            (firstParamOrRightParen.Code != TokenCode.TOKEN_NULL))
        {
            // Unexpected token/syntax error.
            this.syntaxError();
        }

        let functionParams: Token[] = null;

        // See if we have more parameters.
        //Token rightParen = this.expect((int)Tokenizer.TokenCode.TOKEN_RIGHT_PAREN);
        let nextToken: Token = (firstParamOrRightParen.Code == TokenCode.TOKEN_RIGHT_PAREN ? firstParamOrRightParen : this.expectAnyToken());

        if (nextToken.Code == TokenCode.TOKEN_RIGHT_PAREN)
        {
            // We are done ... no more parameters to parse.
            let lstParams: Token[] = [];

            if (firstParamOrRightParen.Code != TokenCode.TOKEN_RIGHT_PAREN)
            {
                lstParams.push(firstParamOrRightParen);
            }

            functionParams = lstParams;
        } else if (nextToken.Code == TokenCode.TOKEN_COMMA)
        {
            // We have more parameters.
            let lstParams: Token[] = this.parseAdditionalFunctionParams(firstParamOrRightParen);

            functionParams = lstParams;
        } else
        {
            // Unexpected token/syntax error.
            this.syntaxError();
        }

        // Package the result.
        functionCallTuple = new Tuple(TokenCode.TOKEN_FUNCTION_CALL, idToken, functionParams);

        return functionCallTuple;
    }

    private parseAdditionalFunctionParams(firstParam: Token): Token[] {
        let lstParamTokens: Token[] = [];

        lstParamTokens.push(firstParam);

        let bContinueParsingParameters: boolean = true;

        do {
            // Parse remaining parameters.
            let nextToken: Token = this.expectAnyToken();

            if ((nextToken.Code == TokenCode.TOKEN_STRING_SINGLE_QUOTES) ||
                (nextToken.Code == TokenCode.TOKEN_NUMBER) ||
                (nextToken.Code == TokenCode.TOKEN_TRUE) ||
                (nextToken.Code == TokenCode.TOKEN_FALSE) ||
                (nextToken.Code == TokenCode.TOKEN_NULL))
            {
                lstParamTokens.push(nextToken);
            } else {
                this.syntaxError();
            }

            nextToken = this.expectAnyToken();

            if (nextToken.Code == TokenCode.TOKEN_RIGHT_PAREN)
            {
                bContinueParsingParameters = false;
            } else if (nextToken.Code == TokenCode.TOKEN_COMMA)
            {
                // This is OK.  We will continue parsing parameters.
            } else
            {
                // Error.
                this.syntaxError();
            }
        } while (bContinueParsingParameters);

        return lstParamTokens;
    }

    private expectAnyToken(): Token {
        this.token = this.tokenizer.getToken();

        if (this.token == null)
            this.syntaxError();

        return this.token;
    }

    //private expect(iTokenCode: number): Token {
    private expect(iTokenCode: TokenCode): Token {
        this.token = this.tokenizer.getToken();

        if (this.token == null) {
            this.syntaxError();
        } else if (this.token.Code != iTokenCode) {
            this.syntaxError();
        }

        return (this.token);
    }

    private syntaxError(): void {
        throw "Syntax error.";
    }
    private get SyntaxErrorMessage(): string {
        return "Syntax error.";
    }
}
