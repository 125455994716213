import { FormField } from '../../../../../models/form-builder/form-field.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../../../interfaces/iform-field-constraint.interface';
import { FormFieldNumericRangeConstraint, FormFieldNumericRangeConstraintWithMetrics } from '../../../../../models/form-builder/field-constraints/field-numeric-range-constraint.model';
import { CachedFormFieldService } from '../../../../../services/form-field-with-cache.service';
import { IFormFieldConstraintLiaison } from './ifield-constraint-liason.interface';
import { NumericRangeDataForJsonSerialization } from '../../../../../models/form-builder/field-constraints/field-numeric-range-constraint.model';
//import { ISiteFormFieldConstraints } from './isite-form-field-constraints.interface';
import { FieldConstraintLiaisonBase } from './field-constraint-liaison-base';
import { SiteFormFieldConstraintProperties } from '../../../../../models/form-builder/field-constraints/site-form-field-constraint-props.model';
import { IntegerFieldType, CurrencyFieldType } from '../../../../../models/form-builder/form-field-types';
import { FormFieldConstraintTypeEnum, FormFieldConstraintTypeForUsersEnum } from '../../../../../enums/form-field-constraint-type.enum';

export class FormFieldNumericRangeConstraintLiaison extends FieldConstraintLiaisonBase implements IFormFieldConstraintLiaison {
    // Constructor.
    public constructor() {
        super();
    }

    // Implement IFormFieldConstraintLiaison.
    // Getter/setter-like methods.
    public constraintTypeForEndUser(): string {  // A user-friendly constraint type name.
        return FormFieldConstraintTypeForUsersEnum.NumericRange;
    }

    public managesConstraintsOfType(): string {
        return FormFieldConstraintTypeEnum.NumericRange;
    }

    public getPrimaryPropertyName(): string {
        return 'minValue';
    }

    public getConstraintId(formField: FormField): number {
        return formField.numericRangeConstraintId;
    }
    public setConstraintId(formField: FormField, value: number): void {
        formField.numericRangeConstraintId = value;
    }

    public getConstraintName(formField: FormField): string {
        return formField.numericRangeConstraintName;
    }
    public setConstraintName(formField: FormField, value: string): void {
        formField.numericRangeConstraintName = value;
    }

    public getConstraintValue(formField: FormField): string {
        let serializationObject = new NumericRangeDataForJsonSerialization(formField.numericRangeConstraintMinValue, formField.numericRangeConstraintMaxValue);
        let json: string = serializationObject.toJson();
        return json;
    }
    public setConstraintValue(formField: FormField, value: string): void {
        if ((value == null) || (value.trim() == '')) {
            formField.minValue = null;
            formField.maxValue = null;
            formField.numericRangeConstraintMinValue = null;
            formField.numericRangeConstraintMaxValue = null;
        } else {
            let serializationObject = NumericRangeDataForJsonSerialization.fromJson(value);
            if (serializationObject != null) {
                formField.numericRangeConstraintMinValue = serializationObject.minValue;
                formField.numericRangeConstraintMaxValue = serializationObject.maxValue;
            }
        }
    }

    // not relevant for this field type
    public getConstraintFailureMessage(formField: FormField): string {
        return null;
    }
    public setConstraintFailureMessage(formField: FormField, value: string): void {
        // noop
    }

    public getConstraintFormFieldPropertyValue(formField: FormField): string {
        let serializationObject = new NumericRangeDataForJsonSerialization(formField.minValue, formField.maxValue);
        let value = serializationObject.toJson();
        return value;
    }

    public copyConstraintValueToCorrespondFormFieldProperty(formField: FormField, value: string): void {
        let serializationObject = NumericRangeDataForJsonSerialization.fromJson(value);
        if (serializationObject != null) {
            formField.minValue = serializationObject.minValue;
            formField.maxValue = serializationObject.maxValue;
        }
    }

    // Create a new, empty constraint.
    public createNewConstraint(dataCollectionId: number): IFormFieldConstraintWithMetrics {
        return new FormFieldNumericRangeConstraintWithMetrics(dataCollectionId);
    }

    public createNewConstraintFrom(constraintParam: IFormFieldConstraint): IFormFieldConstraintWithMetrics {
        let constraint = <FormFieldNumericRangeConstraint>constraintParam;
        let newConstraint = new FormFieldNumericRangeConstraintWithMetrics(constraint.dataCollectionId, constraint.id, constraint.name, constraint.constraintExpression);
        return newConstraint;
    }

    // Package constraint values as a constraint.
    public packageConstraint(dataCollectionId: number, formField: FormField): IFormFieldConstraint {
        let serializationObject = new NumericRangeDataForJsonSerialization(formField.minValue, formField.maxValue);
        let constraintExpr: string = serializationObject.toJson();
        //let packagedConstraint = new FormFieldNumericRangeConstraint(dataCollectionId, formField.numericRangeConstraintId, formField.numericRangeConstraintName, constraintExpr); //formField.numericRangeConstraintValue);
        let packagedConstraint = FormFieldNumericRangeConstraint.constructConstraint(dataCollectionId, formField.numericRangeConstraintId, formField.numericRangeConstraintName, constraintExpr); //formField.numericRangeConstraintValue);

        return packagedConstraint;
    }
    public updateOrCreateConstraint(cachedFormFieldService: CachedFormFieldService, updatedConstraintParam: IFormFieldConstraint): void {
        let updatedConstraint: FormFieldNumericRangeConstraint = <FormFieldNumericRangeConstraint>updatedConstraintParam;
        cachedFormFieldService.updateOrCreateNumericRangeConstraint(updatedConstraint);
    }

    // Methods related to applying form field constraint
    // updates to a form field that uses the same constraint.
    public get createNamedConstraintMethodName(): string {
        return 'createNumericRangeConstraint';
    }
    public get updateNamedConstraintMethodName(): string {
        return 'updateNumericRangeConstraint';
    }
    public get updateOrCreateNamedConstraintMethodName(): string {
        return 'updateOrCreateNumericRangeConstraint';
    }
    public get siteNamedConstraintsMethodName(): string {
        return 'getSiteNamedNumericRanges';
    }

    // Get the field definition class name(s) associated with this type of constraint.
    public get usedByFormFieldClasses(): string[] {
        return [IntegerFieldType, CurrencyFieldType];
    }

    public applyConstraintPropertiesToFormField(constraint: IFormFieldConstraint, formField: FormField) {
        formField.numericRangeConstraintId = constraint.id;
        formField.numericRangeConstraintName = constraint.name;
        let numericRangeConstraint = <FormFieldNumericRangeConstraint>constraint;
        formField.numericRangeConstraintMinValue = numericRangeConstraint.minValue;
        formField.numericRangeConstraintMaxValue = numericRangeConstraint.maxValue;
        formField.minValue = numericRangeConstraint.minValue;
        formField.maxValue = numericRangeConstraint.maxValue;
    }

    // A method to create a constraint from the results of an all site named constraints query.
    public createFromConstraintProperties(dataCollectionId: number, constraintProperties: SiteFormFieldConstraintProperties): IFormFieldConstraintWithMetrics {
        let constraint: FormFieldNumericRangeConstraintWithMetrics = new FormFieldNumericRangeConstraintWithMetrics(dataCollectionId, constraintProperties.constraintId, constraintProperties.constraintName, null);
        constraint.minValue = constraintProperties.minValue;
        constraint.maxValue = constraintProperties.maxValue;
        constraint.useCount = constraintProperties.useCount;
        return constraint;
    }
}
