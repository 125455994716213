import { FieldDefinition } from '../field-definition.model';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { LayoutCell } from '../layout-cell.model';
import { LayoutRow } from '../layout-row.model';
import { Form } from '../form.model';
import { FormObjectDroppedFlags } from '../form-object-dropped-flags.model';
import { DefaultFieldDefinitionClientLogic } from './default-field-def-client-logic';

export class AddRowAboveFieldDefinitionCustomLogic extends DefaultFieldDefinitionClientLogic implements IFieldDefinitionLogic {
    public constructor() {
        super();
    };

    // Implement IFieldDefinitionLogic.
    public performSpecialFormAction(form: Form, row: LayoutRow, dropCell: LayoutCell, definition: FieldDefinition, objectDroppedFlags: FormObjectDroppedFlags): void {
        form.formLayout.addRowAbove(row);
    }
}
