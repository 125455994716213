import {
    Component,
    OnInit,
    Renderer2,
    OnDestroy,
    Type as AngularCoreType
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
//import { Type, classToPlain, plainToClass } from "class-transformer";

import { CascadingDropDownFormFieldBaseComponent } from '../drop-down-form-field-base/drop-down-form-field-base.component';
import { HeaderValuePair } from '../../../models/csv-data/header-value-pair.model';
import { CascadingDropDownFormFieldConfig } from '../../../models/cascading-dropdown/cascading-dropdown-config.model';
import { CascadingDropDownFormFieldData } from '../../../models/cascading-dropdown/cascading-dropdown-data.model';
import { FormField, SecondaryGridCascadingFieldTransientData } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { StringUtil } from '../../../utility-classes/string.util';
import { CurrentUserService } from '../../../../security/current-user.service';
import { FormModeEnum } from '../../../enums/form-mode.enum';
import { FormFieldConfigurationSaveReason } from '../../../enums/form-field-save-config-reason.enum';

// Define a class used by the component defined below.
class GridCascadingDummyAndTransientData {
    public dummyDropDownValues: number[] = [0];
    public dummyDropDownOptions: HeaderValuePair[] = [];

    //public formInstanceElements: FormInstanceElement[] = [];
}

@Component({
    selector: 'app-grid-cascading-dropdown-form-field',
    //templateUrl: './grid-cascading-dropdown.component.html',
    //templateUrl: '../cascading-drop-down-form-field/cascading-drop-down-form-field.component.html',
    templateUrl: '../drop-down-form-field-base/drop-down-form-field-base.component.html',
    styleUrls: [
        //'../cascading-drop-down-form-field/cascading-drop-down-form-field.component.scss',
        '../drop-down-form-field-base/drop-down-form-field-base.component.scss',
        '../form-fields.scss'
    ],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: GridCascadingDropDownFormFieldComponent,
            multi: true
        }
    ]
})
export class GridCascadingDropDownFormFieldComponent extends CascadingDropDownFormFieldBaseComponent implements OnInit, OnDestroy {
    // Properties.
    //private dummyDropDownValues: number[] = [0];
    //private dummyDropDownOptions: string[] = ['TBD'];
    //private dummyDropDownOptions: HeaderValuePair[] = [];
    private transientData: GridCascadingDummyAndTransientData = new GridCascadingDummyAndTransientData();

    // Constructor.
    public constructor(private renderer: Renderer2,
        private injectedCurrentUserServiceForBaseClass: CurrentUserService) {
        super(injectedCurrentUserServiceForBaseClass);
    }

    // Life cycle methods.
    public ngOnInit(): void {
        if (this.IsPrimaryCascadingField) {
            if ((this.FormField.cascadingDropdownConstraintValue != null) && (this.FormField.jsonConfig == null))
                this.FormField.jsonConfig = this.FormField.cascadingDropdownConstraintValue;

            this.saveJsonConfig(this.FormField.jsonConfig);
            this.parseConfig();
        } else {
            ; // this.myConfig = this.formField.transientCascadingDropdownConfig;
            //this.myConfig = this.Config;
        }

        if (this.mode != 'design') {
            //if (this.formField.fieldSecondaryOrder == null) {
            if (GridCascadingDropDownFormFieldComponent.isPrimaryCascadingField(this.FormField)) {
                // This is the first grid cascading dropdown form field in the series.
                //this.myData = CascadingDropDownFormFieldData.createEmptyDataFromConfig(this.myConfig);
                //this.dropDownData = CascadingDropDownFormFieldData.createEmptyDataFromConfig(this.myConfig);
                this.createEmptyDataFromConfig();
                this.unpackFormInstanceElement();

                if (this.FormField.transientVirtualFormFields != null) {
                    /*
                    for (let index: number = 0; index < this.FormField.transientVirtualFormFields.length; index++) {
                        let virtualFormField: FormField = this.FormField.transientVirtualFormFields[index];
                        //virtualFormField.transientCascadingDropdownData = this.myData;
                        if (virtualFormField.secondaryGridCascadingFieldTransientData == null)
                            virtualFormField.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData();
                        //virtualFormField.transientPrimaryGridCascadingDropDownComponent = this;
                        //virtualFormField.transientCascadingDropdownConfig = this.Config;
                        //virtualFormField.transientCascadingDropdownData = this.ProtectedAccessDropDownData; //this.dropDownData;
                        virtualFormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent = this;
                        virtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig = this.Config;
                        virtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData = this.ProtectedAccessDropDownData; //this.dropDownData;
                    }
                    */
                    this.setupSecondaryFieldTransientData();
                } else {
                    //console.log('Investigate');
                    // Note:  this branch can execute when the user saves a form instance.
                }
            } else {
                // This is a subsequent form field in the grid cascading dropdown form field series.
                //this.myData = this.formField.transientCascadingDropdownData;
                //this.myData = this.Data; // this.formField.transientCascadingDropdownData;
                this.unpackFormInstanceElement();
            }

            this.saveMyFormInstanceElementInDropDownData();
        }

        let dummyDropDownOption: HeaderValuePair = new HeaderValuePair();
        //this.dummyDropDownOptions.push(dummyDropDownOption);
        this.transientData.dummyDropDownOptions.push(dummyDropDownOption);

        //super.ngOnInit();
        // Let my parent know what properties I support.
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);
    }

    // IMPORTANT METHOD:  this method needs to be called when the primary grid cascading form field is setting up.
    //                    Presently this method is called in two places:
    //
    //                        ngOnInit() and
    //                        unpackFormInstanceElement().
    //
    //                    Basically, this method allows the primary of N grid cascading form fields to make its
    //                    data visible to the sibling/secondary/virtual grid cascading form fields.
    //
    //                    Without doing this, the secondary grid cascading form fields could end up looking at
    //                    old/obsolete data.
    //
    //                    The data setup here is, importantly, accessed by secondary grid cascading dropdown
    //                    form fields in the "get Config" and "get Data()" methods.  When data is retrieved
    //                    from the server, either on a form instance load or after a form instance save, failing
    //                    to perform the setup executed in the following method will cause the secondary grid
    //                    cascading form fields to see obsolete data in the "get Data()" method.
    //
    //                    I (SWH) will work on trying to figure out a simpler way for this to work, but in the
    //                    meantime I am adding this comment to convey the importance of the fields this method
    //                    configures.
    private setupSecondaryFieldTransientData(): void {
        /*
        for (let index: number = 0; index < this.FormField.transientVirtualFormFields.length; index++) {
            let virtualFormField: FormField = this.FormField.transientVirtualFormFields[index];
            
            if (virtualFormField.secondaryGridCascadingFieldTransientData == null)
                //virtualFormField.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData();
                virtualFormField.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData(null, null, null);
            virtualFormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent = this;
            virtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig = this.Config;
            virtualFormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData = this.ProtectedAccessDropDownData; //this.dropDownData;
        }
        */
        this.FormField.setupSecondaryFieldTransientData(this, this.Config, this.ProtectedAccessDropDownData);
    }

    protected get hasDisplayFormatProperty(): boolean {
        return false;
    }

    public configureAnySecondaryComponents(): void {
        if ((this.mode != 'design') && GridCascadingDropDownFormFieldComponent.isPrimaryCascadingField(this.FormField)) {
            /*
            if (this.FormField.transientVirtualFormFields != null) {
                for (let index: number = 0; index < this.FormField.transientVirtualFormFields.length; index++) {
                    let virtualFormField: FormField = this.FormField.transientVirtualFormFields[index];
                    //virtualFormField.transientCascadingDropdownData = this.myData;
                    //virtualFormField.transientCascadingDropdownData = this.dropDownData;
                    if (virtualFormField.secondaryGridCascadingFieldTransientData == null)
                        //virtualFormField.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData();
                        virtualFormField.secondaryGridCascadingFieldTransientData = new SecondaryGridCascadingFieldTransientData(null, null, null);
                    //virtualFormField.transientPrimaryGridCascadingDropDownComponent = this;
                    virtualFormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent = this;
                }
            }
            */
            this.FormField.configureAnySecondaryComponents(this);
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    // Define methods called from secondary grid cascading dropdown form field
    // component to the primary grid cascading dropdown form field of the group.
    public get UsesMainFieldLabel(): boolean {
        return false;
    }
    public get IsPrimaryCascadingField(): boolean {
        return GridCascadingDropDownFormFieldComponent.isPrimaryCascadingField(this.FormField);
    }
    public get IsSecondaryCascadingField(): boolean {
        return GridCascadingDropDownFormFieldComponent.isSecondaryCascadingField(this.FormField);
    }
    public get HasConfig(): boolean {
        let hasConfig: boolean = false;

        if (this.IsPrimaryCascadingField)
            //hasConfig = this.myConfig != null;
            hasConfig = this.ProtectedAccessConfig != null;
        //else if (this.IsSecondaryCascadingField && (this.formField.transientPrimaryGridCascadingDropDownComponent != null))
        else if (this.IsSecondaryCascadingField && (this.FormField.secondaryGridCascadingFieldTransientData != null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent != null))
            //hasConfig = this.formField.transientPrimaryGridCascadingDropDownComponent.myConfig != null;
            //hasConfig = this.formField.transientPrimaryGridCascadingDropDownComponent.ProtectedAccessConfig != null;
            hasConfig = this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent.ProtectedAccessConfig != null;
        //else if (this.IsSecondaryCascadingField && (this.formField.transientCascadingDropdownConfig != null))
        else if (this.IsSecondaryCascadingField && (this.FormField.secondaryGridCascadingFieldTransientData != null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig != null))
            hasConfig = true;

        return hasConfig;
    }
    public get Config(): CascadingDropDownFormFieldConfig {
        //return this.myConfig;
        let result: CascadingDropDownFormFieldConfig = null;

        if (this.IsPrimaryCascadingField)
            //result = this.myConfig;
            result = this.ProtectedAccessConfig;
        //else if (this.IsSecondaryCascadingField && (this.formField.transientPrimaryGridCascadingDropDownComponent != null))
        else if (this.IsSecondaryCascadingField && (this.FormField.secondaryGridCascadingFieldTransientData != null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent != null))
            //result = this.formField.transientPrimaryGridCascadingDropDownComponent.Config;
            result = this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent.Config;
        //else if (this.IsSecondaryCascadingField && (this.formField.transientCascadingDropdownConfig != null))
        else if (this.IsSecondaryCascadingField && (this.FormField.secondaryGridCascadingFieldTransientData != null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig != null))
            //result = this.formField.transientCascadingDropdownConfig;
            result = this.FormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownConfig;
        else {
            throw `GridCascadingDropDownFormFieldComponent.Config():  cannot get a value`;
        }

        return result;
    }
    public get HasData(): boolean {
        let hasData: boolean = false;

        if (this.IsPrimaryCascadingField)
            //hasData = this.dropDownData != null;
            hasData = this.ProtectedAccessDropDownData != null;
        //else if (this.IsSecondaryCascadingField && (this.formField.transientPrimaryGridCascadingDropDownComponent != null))
        else if (this.IsSecondaryCascadingField && (this.FormField.secondaryGridCascadingFieldTransientData != null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent != null))
            //hasData = this.formField.transientPrimaryGridCascadingDropDownComponent.dropDownData != null;
            //hasData = this.formField.transientPrimaryGridCascadingDropDownComponent.ProtectedAccessDropDownData != null;
            hasData = this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent.ProtectedAccessDropDownData != null;
        //else if (this.IsSecondaryCascadingField && (this.formField.transientCascadingDropdownData != null))
        else if (this.IsSecondaryCascadingField && (this.FormField.secondaryGridCascadingFieldTransientData != null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData != null))
            //hasData = this.formField.transientCascadingDropdownData != null;
            hasData = this.FormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData != null;

        return hasData;
    }
    public get Data(): CascadingDropDownFormFieldData {
        //return this.myData;
        let result: CascadingDropDownFormFieldData = null;

        if (this.IsPrimaryCascadingField) {
            //result = this.myData;
            //result = this.dropDownData;
            result = this.ProtectedAccessDropDownData;
            //} else if (this.IsSecondaryCascadingField && (this.formField.transientPrimaryGridCascadingDropDownComponent != null)) {
        } else if (this.IsSecondaryCascadingField && (this.FormField.secondaryGridCascadingFieldTransientData! == null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent != null)) {
            //result = this.formField.transientPrimaryGridCascadingDropDownComponent.Data;
            result = this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent.Data;
            //} else if (this.IsSecondaryCascadingField && (this.formField.transientCascadingDropdownData != null)) {
        } else if (this.IsSecondaryCascadingField && (this.FormField.secondaryGridCascadingFieldTransientData != null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData != null)) {
            //result = this.formField.transientCascadingDropdownData;
            result = this.FormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData;
        } else {
            throw `GridCascadingDropDownFormFieldComponent.Data():  cannot get a value`;
        }

        return result;
    }
    public get LastHeaderSelectedIndex(): number {
        //return this.lastHeaderSelectedIndex;
        let result: number = -1;

        if (this.IsPrimaryCascadingField) {
            //result = this.lastHeaderSelectedIndex;
            result = this.ProtectedAccessLastHeaderSelectedIndex;
            //} else if (this.IsSecondaryCascadingField && (this.formField.transientPrimaryGridCascadingDropDownComponent != null)) {
        } else if (this.IsSecondaryCascadingField && (this.FormField.secondaryGridCascadingFieldTransientData != null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent != null)) {
            //result = this.formField.transientPrimaryGridCascadingDropDownComponent.LastHeaderSelectedIndex;
            result = this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent.LastHeaderSelectedIndex;
            //} else if (this.IsSecondaryCascadingField && (this.formField.transientCascadingDropdownData != null)) {
        } else if (this.IsSecondaryCascadingField && (this.FormField.secondaryGridCascadingFieldTransientData != null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData != null)) {
            //result = this.formField.transientCascadingDropdownData.lastHeaderSelectedIndex;
            result = this.FormField.secondaryGridCascadingFieldTransientData.transientCascadingDropdownData.lastHeaderSelectedIndex;
        } else {
            throw `GridCascadingDropDownFormFieldComponent.LastHeaderSelectedIndex():  cannot get a value`;
        }

        return result;
    }
    public set LastHeaderSelectedIndex(value: number) {
        if (this.IsPrimaryCascadingField)
            //this.lastHeaderSelectedIndex = value;
            this.ProtectedAccessLastHeaderSelectedIndex = value;
        //else if (this.IsSecondaryCascadingField && (this.formField.transientPrimaryGridCascadingDropDownComponent != null))
        else if (this.IsSecondaryCascadingField && (this.FormField.secondaryGridCascadingFieldTransientData != null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent != null))
            //this.formField.transientPrimaryGridCascadingDropDownComponent.LastHeaderSelectedIndex = value;
            this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent.LastHeaderSelectedIndex = value;
        else {
            throw `GridCascadingDropDownFormFieldComponent.LastHeaderSelectedIndex():  cannot set a value`;
        }
    }
    // End methods called by HTML code.

    public registerVirtualFormFieldFormInstanceElement(virtualFormField: FormField, formInstanceElement: FormInstanceElement): void {
        if (this.HasData && (virtualFormField.fieldSecondaryOrder != null) &&
            (this.Data.formInstanceElements != null) &&
            //(this.transientData.formInstanceElements != null) &&
            (virtualFormField.fieldSecondaryOrder < this.Data.formInstanceElements.length)) {
            //(virtualFormField.fieldSecondaryOrder < this.transientData.formInstanceElements.length)) {
            this.Data.formInstanceElements.splice(virtualFormField.fieldSecondaryOrder, 1, formInstanceElement);
            //this.transientData.formInstanceElements.splice(virtualFormField.fieldSecondaryOrder, 1, formInstanceElement);
        } else {
            let errorMsg = `registerVirtualFormFieldFormInstanceElement():  cannot register a form instance element for virtual form field '${virtualFormField.name}'!`;
            throw errorMsg;
            //console.log(errorMsg);
        }
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (GridCascadingDropDownFormFieldComponent);
    }

    // Override my required minimum height and other base class methods.
    protected parseConfig(): void {
        super.parseConfig();

        if (this.IsPrimaryCascadingField && (this.Config != null)) {
            /*
            this.transientData.formInstanceElements = [];
            for (let index: number = 0; index < this.Config.dropDownHeaders.length; index++) {
                let blankDummyFormInstanceElement = new FormInstanceElement();
                this.transientData.formInstanceElements.push(blankDummyFormInstanceElement);
            }
            */
        }
    }
    protected get displayPropertiesEditIcon(): boolean {
        return GridCascadingDropDownFormFieldComponent.isPrimaryCascadingField(this.FormField);
    }

    protected get minHeightRequired(): number {
        return 70;
    }

    public get AdditionalMatFormFieldStyles(): string {
        return 'margin-top: 0px;';
    }

    // Override some methods called by my HTML code.
    public get DropDownHeaders(): string[] {
        let dropdownHeaders: string[] = [];

        //if (true) {
        //if ((this.myConfig != null) &&
        if ((this.ProtectedAccessConfig != null) &&
            //(this.formField.primaryFieldName == null)) {
            GridCascadingDropDownFormFieldComponent.isPrimaryCascadingField(this.FormField)) {
            //let configHeaders: string[] = this.myConfig.dropDownHeaders;
            let configHeaders: string[] = this.Config.dropDownHeaders;

            if ((configHeaders != null) && (configHeaders.length > 0))
                dropdownHeaders.push(configHeaders[0]);
            //} else if ((this.formField.transientCascadingDropdownConfig != null) &&
        } else if (this.HasConfig &&
            (this.FormField.primaryFieldName != null) &&
            (this.FormField.fieldSecondaryOrder != null)) {
            //GridCascadingDropDownFormFieldComponent.isSecondaryCascadingField(this.formField)) {
            //let configHeaders: string[] = this.formField.transientCascadingDropdownConfig.dropDownHeaders;
            let configHeaders: string[] = this.Config.dropDownHeaders;

            if ((configHeaders != null) &&
                //(this.formField.fieldSecondaryOrder != null) &&
                //(this.formField.fieldSecondaryOrder < configHeaders.length)) {
                GridCascadingDropDownFormFieldComponent.isSecondaryCascadingField(this.FormField)) {
                dropdownHeaders.push(configHeaders[this.FormField.fieldSecondaryOrder]);
            }
        }
        /*
        } else {
            dropdownHeaders = super.DropDownHeaders;
            // More logic to do here.
        }
        */

        return dropdownHeaders;
    }

    public get HeaderIndexOffset(): number {
        let headerIndexOffset: number = 0;

        if ((this.mode != 'design') && (this.FormField.fieldSecondaryOrder != null))
            headerIndexOffset = this.FormField.fieldSecondaryOrder;

        return headerIndexOffset;
    }
    public DropDownOptionsForHeader(iHeader: number): HeaderValuePair[] {
        //let headerValuePairs = (this.mode == 'design' ? this.dummyDropDownOptions : super.DropDownOptionsForHeader(iHeader));
        let headerValuePairs = (this.mode == 'design' ? this.transientData.dummyDropDownOptions : super.DropDownOptionsForHeader(iHeader));
        return headerValuePairs;
    }
    private privateDropDownOptionsForHeader(iHeader: number): HeaderValuePair[] {
        //let headerValuePairs = (this.mode == 'design' ? this.dummyDropDownOptions : super.DropDownOptionsForHeader(iHeader));
        let headerValuePairs = (this.mode == 'design' ? this.transientData.dummyDropDownOptions : super.DropDownOptionsForHeader(iHeader));
        return headerValuePairs;
    }
    public get DropDownValues(): number[] {
        //let dropDownValues = (this.mode == 'design' ? this.dummyDropDownValues : super.DropDownValues);
        //let dropDownValues = this.dummyDropDownValues;
        let dropDownValues = this.transientData.dummyDropDownValues;
        if (this.mode != 'design') {
            //let myHeaderIndex = (this.formField.fieldSecondaryOrder == null ? 0 : this.formField.fieldSecondaryOrder);
            //dropDownValues = super.DropDownValues.slice(0, myHeaderIndex);
            dropDownValues = super.DropDownValues;
        }
        return dropDownValues;
    }

    // Override other base class methods.
    protected formInstanceElementReceived(): void {
        super.formInstanceElementReceived();

        this.FormInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeText;

        if (this.mode != 'design') {
            // Save my form instance element in 'myData'.
            this.saveMyFormInstanceElementInDropDownData();
        }
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        super.formInstanceElementReceived();

        this.FormInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeText;

        if (this.mode != 'design') {
            // Save my form instance element in 'myData'.
            this.saveMyFormInstanceElementInDropDownData();
        }
    }

    public saveConfiguration(form: any, reasonForSave: FormFieldConfigurationSaveReason): void {
        if (this.IsPrimaryCascadingField) {
            super.saveConfiguration(form, reasonForSave);
        }

    }
    public saveConfigurationCompleted(form: any): void {
    }

    public saveData(formInstance: any): void {
        super.saveData(formInstance);

        if (this.IsPrimaryCascadingField) {
            // We need to set property 'transientVirtualFormFields' to null prior to a save
            // as it would cause a JSON serialization exception.  Since this form field type
            // is only used in grids, and grid form fields components are deleted/destroyed
            // as a part of a grid save operation, there is no need to restore the value for
            // this property.
            if (this.HasData)
                this.Data.formInstanceElements = null;
            if (this.FormField.transientVirtualFormFields != null)
                this.FormField.transientVirtualFormFields = null;
            //} else if (this.formField.transientPrimaryGridCascadingDropDownComponent != null) {
        } else if ((this.FormField.secondaryGridCascadingFieldTransientData != null) && (this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent != null)) {
            // This property needs to be set to null for the same reason as in the prior block.
            //this.formField.transientPrimaryGridCascadingDropDownComponent = null;
            this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent = null;
        }

        super.saveData(formInstance);

        return;
    }
    public saveCompleted(formInstance: any): void {
        super.saveCompleted(formInstance);
    }

    protected unpackFormInstanceElement(): void {
        let hasConfig: boolean =
            (this.IsPrimaryCascadingField && (this.ProtectedAccessConfig != null)) ||
            //((this.formField.transientPrimaryGridCascadingDropDownComponent != null) && (this.formField.transientPrimaryGridCascadingDropDownComponent.ProtectedAccessConfig != null));
            ((this.FormField.secondaryGridCascadingFieldTransientData != null) &&
                (this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent != null) &&
                (this.FormField.secondaryGridCascadingFieldTransientData.transientPrimaryGridCascadingDropDownComponent.ProtectedAccessConfig != null));

        if (hasConfig) {
            if (this.IsPrimaryCascadingField) {
                this.createEmptyDataFromConfig();
                this.setupSecondaryFieldTransientData();
            }
            this.saveMyFormInstanceElementInDropDownData();

            if ((this.FormInstanceElement != null) && (this.FormInstanceElement.textValue != null) && (this.FormInstanceElement.textValue.trim() != '')) {
                let myHeaderIndex: number = (this.IsPrimaryCascadingField ? 0 : this.FormField.fieldSecondaryOrder);
                //this.Data.dropDownValues[myHeaderIndex] = 0; // Start with this and try to find a non-zero value.
                this.Data.setDropDownValue(myHeaderIndex, 0);
                let options: HeaderValuePair[] = this.privateDropDownOptionsForHeader(myHeaderIndex);
                if (options != null) {
                    let option = options.find(hvp => hvp.ValueText == this.FormInstanceElement.textValue);
                    if (option != null) {
                        //this.Data.dropDownValues[myHeaderIndex] = option.Index;
                        this.Data.setDropDownValue(myHeaderIndex, option.Index);
                        this.Data.setDropDownTextValue(myHeaderIndex, this.FormInstanceElement.textValue);

                        if (this.LastHeaderSelectedIndex < myHeaderIndex)
                            this.LastHeaderSelectedIndex = myHeaderIndex;
                    }
                }
            } // if
        }
    }

    // Define methods still to be implemented.
    public getSelectedValue(value: string, optionIndex: number, headerIndex: number): string {
        // Note:  this method might require enhancements ... will test to determine.
        return value;
    }

    /*
    public displayDeleteIconInGrid(): boolean {
        return this.IsPrimaryCascadingField;
    }
    */

    public dropDownValueChanged(iHeaderParam: number): void {
        let debugIndex: number = 0;
        super.dropDownValueChanged(iHeaderParam);
        if (this.mode != 'design') {
            debugIndex = iHeaderParam;
        }
    }
    protected headerValueChanged(iHeader: number, selectedValue: number): void {
        // Note:  this method can be overriden by derived classes.
        if ((this.Data != null) && (this.Data.formInstanceElements != null) && (iHeader < this.Data.formInstanceElements.length)) {
            //if ((this.Data != null) && (this.transientData.formInstanceElements != null) && (iHeader < this.transientData.formInstanceElements.length)) {
            let formInstanceElement = this.Data.formInstanceElements[iHeader];
            //let formInstanceElement = this.transientData.formInstanceElements[iHeader];
            this.saveHeaderValue(iHeader, formInstanceElement);
        }
    }
    protected saveChangedDataToFormInstanceElement(): void {
        super.saveChangedDataToFormInstanceElement();

        this.saveHeaderValue(this.HeaderIndexOffset, this.FormInstanceElement);

        // As this is the last method called when a dropdown value is changed,
        // let us print out all of the cascading dropdown values here (while
        // we are in development mode).
        //this.printDropDownValueData();
    }
    private saveHeaderValue(myHeaderIndex: number, formInstanceElement: FormInstanceElement): void {
        if (this.Data != null) {
            if (myHeaderIndex < this.Data.dropDownValues.length) {
                let myOneBasedIndexValue = this.Data.dropDownValues[myHeaderIndex];
                let dropDownValuesForMyHeader: HeaderValuePair[] = this.DropDownOptionsForHeader(myHeaderIndex);
                let myDropDownValue: HeaderValuePair = dropDownValuesForMyHeader.find(ddv => ddv.Index == myOneBasedIndexValue);
                if (myDropDownValue != null) {
                    formInstanceElement.textValue = myDropDownValue.ValueText;
                } else {
                    formInstanceElement.textValue = null;
                }
            }
        }
    }
    /*
    private printDropDownValueData(): void {
        if ((this.Data != null) && (this.transientData.formInstanceElements != null)) {
            for (let index: number = 0; index < this.transientData.formInstanceElements.length; index++) {
                let formInstanceElement: FormInstanceElement = this.transientData.formInstanceElements[index];
            }
        }
    }
    */

    public pseudoStatic_getDisplayValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): string {
        return (formInstanceElementParam ? formInstanceElementParam.textValue : null);
    }

    public pseudoStatic_pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper, formField: FormField): void {
        // 10-19-2022 notes:  presently paste data validation is not being performed in this method or the
        //                    method with the same signature in the form version of the cascading dropdown
        //                    form field.
        //
        //                    As this method is being called on a "stand in" component instance, one that
        //                    is not instantiated as a visible form field, we cannot use instance data
        //                    such as that used in getter method this.HeaderIndexOffset.  Additionally,
        //                    we will need to pass in a FormField parameter to this method in order to
        //                    allow this implementation of the method to know what cascading dropdown
        //                    form field to use for paste value validation.
        //
        //                    We should consider making it clear when a method cannot assume that its
        //                    instance data can be used.
        //let headerValuePairs: HeaderValuePair[] = this.DropDownOptionsForHeader(this.HeaderIndexOffset);

        if ((elementWrapper != null) && (elementWrapper.formInstanceElement != null)) {
            elementWrapper.formInstanceElement.textValue = value;
            elementWrapper.formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeText;
            elementWrapper.standinDisplayValue = value;
        }
    }

    // Override base class methods.
    protected csvOptionsFileProcessed(): void {
        if ((this.ProtectedAccessConfig != null) && (this.ProtectedAccessConfig.dropDownHeaders != null) && (this.ProtectedAccessConfig.dropDownHeaders.length > 0)) {
            if (StringUtil.isEmptyString(this.FormField.displayName) || (this.FormField.displayName == this.FormField.name)) {
                this.FormField.displayName = this.ProtectedAccessConfig.dropDownHeaders[0];
            }
        }

        // Also call super.
        super.csvOptionsFileProcessed();
    }

    // Private helper methods.
    private saveMyFormInstanceElementInDropDownData(): void {
        if ((this.FormField != null) &&
            //(this.formField.transientPrimaryGridCascadingDropDownComponent != null) &&
            (this.HasData) && (this.Data != null) && (this.Data.formInstanceElements != null) &&
            //(this.Data != null) && (this.transientData.formInstanceElements != null) &&
            (this.HeaderIndexOffset < this.Data.formInstanceElements.length)) {
            //(this.HeaderIndexOffset < this.transientData.formInstanceElements.length)) {
            // Save my field name in the form instance element (development code only).
            this.FormInstanceElement.transientGridCascadingDropDownName = this.FormField.name;

            this.Data.formInstanceElements.splice(this.HeaderIndexOffset, 1, this.FormInstanceElement);
            //this.transientData.formInstanceElements.splice(this.HeaderIndexOffset, 1, this.FormInstanceElement);
        }
    }

    /*
    private saveAllFormInstanceElementsInDropDownData(): void {
        if (GridCascadingDropDownFormFieldComponent.isPrimaryCascadingField(this.formField) &&
            (this.Data != null) && (this.Data.formInstanceElements != null)) {
            this.saveMyFormInstanceElementInDropDownData();
        }
    }
    */

    private static isPrimaryCascadingField(formField: FormField): boolean {
        return (formField.primaryFieldName == null);
    }
    private static isSecondaryCascadingField(formField: FormField): boolean {
        return ((formField.primaryFieldName != null) && (formField.fieldSecondaryOrder != null));
    }

    protected jsonConfigSaved(): void {
        if (this.mode == FormModeEnum.INSTANCE) {
            //this.formField.jsonConfig = null; // Cannot set this to null for a grid cascading dropdown form field.
            this.FormField.cascadingDropdownConstraintValue = null;
        }
    }
    protected preparingToSaveToServer(): void {
        if (this.IsPrimaryCascadingField) {
            if (this.FormField.cascadingDropdownConstraintValue != null)
                this.FormField.jsonConfig = null;
        }
    }
}
