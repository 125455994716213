import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationDialogModel, ConfirmationDialogComponent } from '../../shared/dialogs/confirmation/confirmation-dialog.component';
import { ConfirmationDialogEnums } from '../enums/confirmation-dialog.enum';
import { IListItem } from '../interfaces/ilist-item.interface';

@Injectable()
export class DeleteOnConfirmDialogService {
     //Define a deleteOnConfirm() method.
    public deleteOnConfirm(dialogParam: MatDialog,
        entity: string,
        callback: Function) {
        let dialogRef = dialogParam.open(ConfirmationDialogComponent, {
            data: new ConfirmationDialogModel(`Delete ${entity}`, `Are you sure you want to delete this ${entity}? The operation cannot be undone.`)
        });
        dialogRef.afterClosed().subscribe(yes => {
            if (yes) {
                callback();
            }
        });

        return;
    }

    public deleteOnConfirmMultipleItems(dialogParam: MatDialog,
        items: IListItem[],
        callback: Function) {

        let title = 'Delete Item';
        let desc = 'Are you sure you want to delete the item';


        if (items.length > 1) {
            title += 's';
            desc += 's:\n\n';
        }
        else {
            desc += ':\n\n';
        }

        items.forEach(x => {
            desc += x.name + '\n';
        });

        desc += '\n';

        desc +=  `The operation cannot be undone!`;

        let dialogRef = dialogParam.open(ConfirmationDialogComponent, {
            data: new ConfirmationDialogModel(title, desc, { hideNo: false, dialogType: ConfirmationDialogEnums.WARNING }),
            panelClass: 'dialog-confirm'
        });
        dialogRef.afterClosed().subscribe(yes => {
            if (yes) {
                callback();
            }
        });

        return;
    }

}
