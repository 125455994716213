import { FormField } from '../../../../../models/form-builder/form-field.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../../../interfaces/iform-field-constraint.interface';
import { FormFieldRegularExpressionConstraint, FormFieldRegularExpressionConstraintWithMetrics } from '../../../../../models/form-builder/field-constraints/field-regular-expression-constraint.model';
//import { ISiteFormFieldConstraints } from './isite-form-field-constraints.interface';
import { CachedFormFieldService, FlushCachedServiceUponFormSave } from '../../../../../services/form-field-with-cache.service';
import { IFormFieldConstraintLiaison } from './ifield-constraint-liason.interface';
import { FieldConstraintLiaisonBase } from './field-constraint-liaison-base';
import { SiteFormFieldConstraintProperties } from '../../../../../models/form-builder/field-constraints/site-form-field-constraint-props.model';
import { ShortTextFieldType } from '../../../../../models/form-builder/form-field-types';
import { FormFieldConstraintTypeEnum, FormFieldConstraintTypeForUsersEnum } from '../../../../../enums/form-field-constraint-type.enum';

export class FormFieldRegularExpressionConstraintLiaison extends FieldConstraintLiaisonBase implements IFormFieldConstraintLiaison {
    // Properties.
    //private formField: FormField = null;

    // Constructor.
    public constructor() {
        super();
    }

    // Implement IFormFieldConstraintLiaison.
    // Getter/setter-like methods.
    public constraintTypeForEndUser(): string {  // A user-friendly constraint type name.
        return FormFieldConstraintTypeForUsersEnum.RegularExpression;
    }

    public managesConstraintsOfType(): string {
        return FormFieldConstraintTypeEnum.RegularExpression;
    }

    public getPrimaryPropertyName(): string {
        return 'regex';
    }

    public getConstraintId(formField: FormField): number {
        return formField.regularExpressionConstraintId;
    }
    public setConstraintId(formField: FormField, value: number): void {
        formField.regularExpressionConstraintId = value;
    }

    public getConstraintName(formField: FormField): string {
        return formField.regularExpressionConstraintName;
    }
    public setConstraintName(formField: FormField, value: string): void {
        formField.regularExpressionConstraintName = value;
    }

    public getConstraintValue(formField: FormField): string {
        return formField.regularExpressionConstraintValue;
    }
    public setConstraintValue(formField: FormField, value: string): void {
        formField.regularExpressionConstraintValue = value;
    }

    public getConstraintFailureMessage(formField: FormField): string {
        return formField.regularExpressionConstraintMessage;
    }
    public setConstraintFailureMessage(formField: FormField, value: string): void {
        formField.regularExpressionConstraintMessage = value;
    }

    public getConstraintFormFieldPropertyValue(formField: FormField): string {
        return formField.regex;
    }

    public copyConstraintValueToCorrespondFormFieldProperty(formField: FormField, value: string): void {
        formField.regex = value;
    }

    // Create a new, empty constraint.
    public createNewConstraint(dataCollectionId: number): IFormFieldConstraintWithMetrics {
        return new FormFieldRegularExpressionConstraintWithMetrics(dataCollectionId);
    }

    public createNewConstraintFrom(constraintParam: IFormFieldConstraint): IFormFieldConstraintWithMetrics {
        let constraint = <FormFieldRegularExpressionConstraint>constraintParam;
        let newConstraint = new FormFieldRegularExpressionConstraintWithMetrics(constraint.dataCollectionId, constraint.id, constraint.name, constraint.constraintExpression);
        return newConstraint;
    }

    // Package constraint values as a constraint.
    public packageConstraint(dataCollectionId: number, formField: FormField): IFormFieldConstraint {
        let packagedConstraint = new FormFieldRegularExpressionConstraint(
            dataCollectionId,
            formField.regularExpressionConstraintId,
            formField.regularExpressionConstraintName,
            formField.regularExpressionConstraintValue,
            formField.regularExpressionConstraintMessage
        );

        return packagedConstraint;
    }
    public updateOrCreateConstraint(cachedFormFieldService: CachedFormFieldService, updatedConstraintParam: IFormFieldConstraint): void {
        let updatedConstraint: FormFieldRegularExpressionConstraint = <FormFieldRegularExpressionConstraint>updatedConstraintParam;
        cachedFormFieldService.updateOrCreateRegularExpressionConstraint(updatedConstraint);
    }

    // Methods related to applying form field constraint
    // updates to a form field that uses the same constraint.
    public get createNamedConstraintMethodName(): string {
        return 'createRegularExpressionConstraint';
    }
    public get updateNamedConstraintMethodName(): string {
        return 'updateRegularExpressionConstraint';
    }
    public get updateOrCreateNamedConstraintMethodName(): string {
        return 'updateOrCreateRegularExpressionConstraint';
    }
    public get siteNamedConstraintsMethodName(): string {
        return 'getSiteNamedRegularExpressions';
    }

    public get usedByFormFieldClasses(): string[] {
        return [ShortTextFieldType];
    }

    public applyConstraintPropertiesToFormField(constraint: IFormFieldConstraint, formField: FormField) {
        formField.regularExpressionConstraintId = constraint.id;
        formField.regularExpressionConstraintName = constraint.name;
        formField.regularExpressionConstraintValue = constraint.constraintExpression;
        formField.regularExpressionConstraintMessage = constraint.constraintFailureMessage;
        formField.regex = constraint.constraintExpression;
    }

    // A method to create a constraint from the results of an all site named constraints query.
    public createFromConstraintProperties(dataCollectionId: number, constraintProperties: SiteFormFieldConstraintProperties): IFormFieldConstraintWithMetrics {
        let constraint: FormFieldRegularExpressionConstraintWithMetrics =
            new FormFieldRegularExpressionConstraintWithMetrics(
                dataCollectionId,
                constraintProperties.constraintId,
                constraintProperties.constraintName,
                constraintProperties.regularExpressionValue,
                constraintProperties.regularExpressionMessage);
        constraint.useCount = constraintProperties.useCount;
        return constraint;
    }
}
