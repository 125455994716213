import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from "class-transformer";

import { Role } from '../models/role.model';
import { CollectApiServiceBase } from './collect-api-base.service';
import { environment } from '../../../environments/environment';
import { RoleGroupMember } from '../models/role-group-member.model';
import { RolesWrapper } from '../models/roles-wrapper.model';
import { ProgressIndicatorService } from './progress-indicator.service';

@Injectable()
export class RoleService extends CollectApiServiceBase<Role> {
    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'role', Role);
    }

    public formatResponse(data: Role): Role {
        let obj = plainToClass(Role, data);

        return (obj);
    }

    // Note:  the following method is commented out as the server implementation is presently disabled.
    /*
    public getRolesForSite(siteId: number): Promise<Role[]> {
        let url = `${this.url}/${this.endpoint}/GetRolesForSite/${siteId}`;

        return this.getSiteRoles(url);
    }
    */

    public getFolderRoles(folderId: number): Promise<RolesWrapper> {
        let url = `${this.url}/${this.endpoint}/GetRolesForFolder/${folderId}`;
        return this.getRoles(url);
    }

    public getFolderRoleGroupMembers(folderId: number): Promise<any> {
        let url = `${this.url}/${this.endpoint}/GetFolderRoleGroupMembers/${folderId}`;
        return this.http.get<any[]>(url)
            .toPromise()
            .then(members => {
                return (members);
            })
            .catch(this.handleError);    
    }


    public addMember(roleId: number, folderId: number, member: RoleGroupMember): Promise<RoleGroupMember>{
        let url = `${this.url}/${this.endpoint}/${roleId}/${folderId}/AddRoleMember`;
        
        return this.http.post<RoleGroupMember>(url, member)
            .toPromise()
            .then(member => {
                return member;
            })
            .catch(this.handleError);
    }

    public deleteMember(roleGroupMemberId: number, folderId: number): Promise<RoleGroupMember> {
        let url = `${this.url}/${this.endpoint}/DeleteRoleGroupMember/${roleGroupMemberId}/${folderId}`;

        return this.http.delete(url)
            .toPromise()
            .then(member => {
                return member;
            })
            .catch(this.handleError);
    }

    public deleteAllRoleMembers(roleId: number, folderId: number): Promise<RoleGroupMember> {
        let url = `${this.url}/${this.endpoint}/DeleteRoleMembers/${roleId}/${folderId}`;

        return this.http.delete(url)
            .toPromise()
            .then(member => {
                return member;
            })
            .catch(this.handleError);
    }

    public getSiteRoles(dataCollectionId: number): Promise<Role[]> {
        let url = `${this.url}/${this.endpoint}/GetSiteRoles/${dataCollectionId}`;

        return this.http.get<Role[]>(url)
            .toPromise()
            .then(response => {
                let roles: Role[] = [];
                if ((response != null) && (response.length > 0)) {
                    for (let index: number = 0; index < response.length; index++) {
                        let role: Role = this.formatResponse(response[index]);
                        roles.push(role);
                    }
                }
                return roles;
            });
    }

    public getRolesUsedInSite(dataCollectionId: number): Promise<string[]> {
        let url = `${this.url}/${this.endpoint}/GetRolesUsedInSite/${dataCollectionId}`;

        return this.http.get<string[]>(url)
            .toPromise()
            .then(roleNames => {
                return roleNames;
            });
    }

    public getUserSiteRoleNames(dataCollectionId: number): Promise<string[]> {
        let url = `${this.url}/${this.endpoint}/GetUserSiteRoleNames/${dataCollectionId}`;

        return this.http.get<string[]>(url)
            .toPromise()
            .then(roleNames => {
                return roleNames;
            });
    }

    // Helpers
    /*
    private getSiteRoles(url: string): Promise<Role[]> {
        return this.http.get<Role[]>(url)
            .toPromise()
            .then(roleObjects => {
                // make sure Roles are fully fledged objects with helper methods and not just data
                let roles: Role[] = [];

                if (roleObjects && roleObjects.length > 0) {
                    for (let iRole: number = 0; iRole < roleObjects.length; iRole++) {
                        let roleObject: object = roleObjects[iRole];

                        let role: Role = plainToClass(Role, roleObject);

                        roles.push(role);
                    }
                }

                return (roles);
            })
            .catch(this.handleError);
    }
    */

    private getRoles(url: string): Promise<RolesWrapper> {
        return this.http.get<RolesWrapper>(url)
            .toPromise()
            .then(roleWrapper => {
                // make sure Roles are fully fledged objects with helper methods and not just data
                roleWrapper.roles = roleWrapper.roles.map(r => { return Object.assign(new Role(), r) });
                return roleWrapper;
            })
            .catch(this.handleError.bind(this)); // .bind added so handleError() has access to this
    }
}
