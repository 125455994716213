import { IListItem } from '../../interfaces/ilist-item.interface';

export enum ListItemDragActionEnum {
    DRAG_START = 'dragStart',
    DRAG_END = 'dragEnd'
}

export class ListItemDragActionData {
    public dragEventData: DragEvent; 
    public items: IListItem[];
    public dragAction: ListItemDragActionEnum;
}
