<h1 mat-dialog-title
    class="draggable-dialogue-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
    {{this.title}}
</h1>

<form class="input-parameters-form">
    <div mat-dialog-content class="mat-dialog-content-div">
        <!--
        <mat-form-field appearance="outline">
            <mat-label>Value</mat-label>
            <input matInput [(ngModel)]="value" placeholder="Edit value" name="value" autocomplete="off" required />
        </mat-form-field>
            -->
        <ng-container *ngFor="let parameterControlInfo of this.parametersControlInfo">
            <ng-container [ngSwitch]="parameterControlInfo.controlType">
                <ng-container *ngSwitchCase="'TextInput'">
                    <!-- Text parameter. -->
                    <mat-form-field appearance="outline"
                                    [floatLabel]="'always'"
                                    class="text-parameter-mat-form-field">
                        <mat-label class="field-label">{{parameterControlInfo.parameterName}}</mat-label>
                        <input matInput
                               type="text"
                               [readonly]="false"
                               class="text-parameter-matinput"
                               [formControl]="parameterControlInfo.formControl"
                               placeholder="{{parameterControlInfo.parameterName}}" />
                    </mat-form-field>

                    <ng-container *ngIf="false">
                        <mat-checkbox [formControl]="parameterControlInfo.hasDateTimeSuffixFormControl"
                                      class="add-date-time-suffix-checkbox">
                            Add Date/Time Suffix
                        </mat-checkbox>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'NumericInput'">
                    <mat-form-field appearance="outline"
                                    [floatLabel]="'always'"
                                    class="numeric-parameter-mat-form-field">
                        <mat-label class="field-label">{{parameterControlInfo.parameterName}}</mat-label>
                        <input matInput
                               type="number"
                               [readonly]="false"
                               [formControl]="parameterControlInfo.formControl"
                               placeholder="{{parameterControlInfo.parameterName}}" />
                    </mat-form-field>

                </ng-container>
                <ng-container *ngSwitchCase="'Select'">
                    <mat-form-field appearance="outline"
                                    [floatLabel]="'always'"
                                    class="select-parameter-mat-form-field">
                        <mat-label class="field-label">{{parameterControlInfo.parameterName}}</mat-label>
                        <mat-select [formControl]="parameterControlInfo.formControl"
                                    [disabled]="false">
                            <mat-option *ngFor="let selectOption of parameterControlInfo.selectOptionValues"
                                        [value]="selectOption.value">
                                {{selectOption.displayName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <div mat-dialog-actions>
        <button mat-flat-button type="submit" color="primary" class="theme-primary-btn"
                [disabled]="false"
                (click)="this.returnParameterValues()">
            OK
        </button>
        <button mat-flat-button [mat-dialog-close]="null" style="margin-left: 10px;">Cancel</button>
    </div>
</form>

