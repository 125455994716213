import { Type } from "class-transformer";
import { Subscription } from "rxjs";
import { IconFormatEnum } from "../enums/icon-format.enum";
import { ItemTypeEnum } from "../enums/item-type.enum";
import { IListItem } from "../interfaces/ilist-item.interface";
import { IViewModel } from "../interfaces/view-model.interface";
import { IListItemBase } from "./ilist-item-base.model";
import { SubscriptionFilter } from "./notifications/subscription-filter.model";

export class EventSubscription extends IListItemBase implements IViewModel, IListItem {
    public id: number;
    public eventName: string;
    public subscribeeType: string;
    public subscribee: string;
    public expirationDate: Date;

    public isExactMatch: boolean;
    public isMatchedByAncestor: boolean;
    public entityName: string;
    public entityPath: string;

    public dataCollectionId: number;
    public folderId: number;
    public formId: number;
    public formInstanceId: number;
    public parentFolderId: number;
    public workflowId: number;
    public workflowStateId: number;
    public workflowTransitionId: number;

    //@Type(() => SubscriptionFilter)
    public filters: SubscriptionFilter[];

    public addFilter(filter: any): void {
        if (this.filters == null) {
            this.filters = [];
        }
        this.filters.push(filter);
    }

    public setId(idParam: number): void {
        this.id = idParam;
    }
    public getName(): string {
        let msg = "";
        if (this.dataCollectionId) msg = `Data Collection: ${this.dataCollectionId}`;
        if (this.folderId) msg = ` Folder: ${this.folderId}`;
        if (this.parentFolderId) msg = ` Parent Folder: ${this.parentFolderId}`
        if (this.formId) msg = ` Form Template Id: ${this.formId}`;
        if (this.formInstanceId) msg = ` Form Id; ${this.formInstanceId}`;
        if (this.workflowId) msg = ` Workflow Id: ${this.workflowId}`;
        if (this.workflowStateId) msg = ` Workflow State: ${this.workflowStateId}`;
        if (this.workflowTransitionId) msg = ` Workflow Transition Id: ${this.workflowTransitionId}`;

        return `${this.eventName} for ${msg}`;
    }
    public getTitle(): string {
        return this.eventName;
    }
    public getExtraInfo(): string {
        throw new Error("Method not implemented.");
    }
    public getValue(property: string): string {
        return "";
    }
    public userCanDelete(): boolean {
        throw new Error("Method not implemented.");
    }
    public getPosition(): number {
        throw new Error("Method not implemented.");
    }
    public modifiedDate: Date;
    public modifiedBy: string;
    public getStatus(): string {
        throw new Error("Method not implemented.");
    }
    public getIconType(): IconFormatEnum {
        return IconFormatEnum.MAT_ICON;
    }
    public getIcon(): string {
        return 'subscriptions';
    }
    public canBeDeleted(): boolean {
        throw new Error("Method not implemented.");
    }
    public update(obj: any, type: string, icon?: string, position?: number): void {
        throw new Error("Method not implemented.");
    }
    public getUniqueId(): string {
        throw new Error("Method not implemented.");
    }
    public getOriginalVersionId(): number {
        throw new Error("Method not implemented.");
    }
    public findAndUpdateFrom(items: any, obj: any): void {
        throw new Error("Method not implemented.");
    }
    public isMagicStructure(): boolean {
        throw new Error("Method not implemented.");
    }
    public getChildCount(): number {
        throw new Error('Method not implemented.');
    }
    public getModifiedBy(): string {
        throw new Error("Method not implemented.");
    }
    public getModifiedDate(): Date {
        return this.modifiedDate;
    }

    public name: string;
    public getId(): number {
        return this.id;
    }
    public getType(): string {
        return ItemTypeEnum.SUBSCRIPTION;
    }
    public getParentId(): number {
        throw new Error("Method not implemented.");
    }
}
