import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { UserClaims } from '../models/user-claims.model';
import { ClaimService } from '../services/claim.service';
import { Logging } from '../logging';

@Injectable()
export class ClaimResolver implements Resolve<UserClaims> {
    constructor(private claimService: ClaimService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UserClaims> {
        return this.claimService.getClaims().then(claims => {
            return claims;
        });
    }
}
