import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { BinaryComparisonTypesFieldDefinitionLogicBase } from './binary-comparison-base-logic';
import { SelectOptionValue } from '../../selection-fields/select-option-value.model';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { FormFieldBaseComponent } from '../../../components/form-fields/form-field-base/form-field-base.component';
import { FormInstanceGridRow } from '../../site-content/form-instance-grid-row.model';

export class CheckboxFieldDefLogic extends BinaryComparisonTypesFieldDefinitionLogicBase { //DefaultFieldDefinitionClientLogic {
    // Static data.
    private static selectOptionValues: SelectOptionValue[] = [
        { id: 1, displayName: 'true' },
        { id: 2, displayName: 'false' }
    ];

    // Constructor.
    public constructor() {
        super();
    };

    // Implement IFieldDefinitionLogic methods.
    public getSelectableValuesFor(formField: FormField): SelectOptionValue[] {
        return CheckboxFieldDefLogic.selectOptionValues;
    }

    // pasteDataForInto():  only allow the pasting of values that are valid for a given form field.
    public pasteDataForInto(formField: FormField, formInstanceElement: FormInstanceElement, pasteValue: string): void {
        formInstanceElement.booleanValue = ((pasteValue != null) && pasteValue.toLowerCase() == 'true' ? true : false);
    }

    // Sorting.
    public compareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        let compareResult: number = 0;

        if ((value1.booleanValue != null) && (value2.booleanValue != null)) {
            if (value1.booleanValue != value2.booleanValue) {
                //compareResult = (value1.booleanValue == true ? 1 : -1);
                compareResult = (value1.booleanValue == true ? -1 : 1);
            }
        } else if (value1.booleanValue != null) {
            compareResult = 1;
        } else if (value2.booleanValue != null) {
            compareResult = -1;
        }

        return compareResult;
    }

    public allowsHtmlInDisplayValue(): boolean {
        return true;
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        //VNEXT-981 - KLW: Changes to display a checkbox on the Site Homepage as disabled, use this string to add a class to make the checkbox read only
        let disableClass: string = '';
       
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.booleanValue)) {
            // Set a default value.
            //this.setDefaultBooleanValue();
            FormFieldBaseComponent.staticSetDefaultBooleanValue(formFieldParam, formInstanceElementParam);

            formInstanceElementParam.BooleanValue = formInstanceElementParam.booleanValue;
        }

        //VNEXT-981 - KLW: Changes to display a checkbox on the Site Homepage as disabled, set the class name
        if (formFieldParam.readOnly)
        { 
            disableClass = 'disabled-icon';
        } 

        //VNEXT-981 - KLW: Changes to display a checkbox on the Site Homepage as disabled
        return (formInstanceElementParam.booleanValue ? `<span class="material-icons-outlined ${disableClass} ">check_box</span>` : `<span class="material-icons-outlined ${disableClass}">check_box_outline_blank</span>`);
    }

    // Implement abstract methods.
    protected formatValueForCompare(value: any): any {
        let result: boolean = false;

        if ((value != null) && (value.toString().toLowerCase() == 'true'))
            result = true;

        return result;
    }


    public filterType(): string {
        return 'boolean';
    }

    public isFilterable(): boolean {
        return true;
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }

    public unpackTemplateInstancesRow(formField: FormField, colValue: FormInstanceElement, row: FormInstanceGridRow): any {
        let fieldValue: string = colValue.booleanValue == true ? 'true' : 'false';
        return fieldValue;
    }
}
