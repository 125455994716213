import { add } from 'mathjs';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
import { IElementTypeMetadata } from '../../interfaces/component-scripting/ielement-type-metadata';

export interface INameToPrettyNameMap {
    [name: string]: string;
}
export class OperationCompletedServiceInfo {
    public constructor(public serviceName: string, public operationName: string) { }
}
export interface IOperationCompletedServiceMap {
    [name: string]: OperationCompletedServiceInfo;
}

export interface IAdditionalElementInfo {
    innerTextSelector?: string;
    customTextValue?: string;
    customTextValues?: string[];

    elementSubtypeName?: string;
    elementSubtypeNames?: string[];

    customPreprocessingFunction?: (driver: IBrowserDriver, elements: object, elementTypeMetadata: IElementTypeMetadata, additionalElementInfo: IAdditionalElementInfo) => void;
    customGetTextForFunction?: (driver: IBrowserDriver, element: object, existingTextValue: string, additionalElementInfo: IAdditionalElementInfo) => string;
    getClickableElementFunction?: (element: object) => object;

    //findElementFilter?: string; // Not yet used.
    reFindElementsAfterActionPerformed?: boolean;
    useDoubleClick?: boolean;
    cssFilter?: string;

    name?: string; // An element's display name.
    names?: string[]; // Display names for a list of elements.
    nameToPrettyNameMap?: INameToPrettyNameMap; // Note:  this property is not presently being used.
    standardNameToPrettyStandardNameMap?: INameToPrettyNameMap; // For names used consistently throughout the app.
    elementsSubtypeMap?: INameToPrettyNameMap;
    hasAddedDateTimeSuffix?: boolean;
    operationCompletedServiceMap?: IOperationCompletedServiceMap;
         
    toggleCheckboxMethodName?: string;

    allowZeroElements?: boolean;
    maxTimeoutsLookingForScriptableElements?: number;
}
export class AdditionalElementInfo {
    public innerTextSelector: string = null;
    public customTextValue: string = null;
    public customTextValues: string[];

    public elementSubtypeName: string = null;
    public elementSubtypeNames: string[] = null;

    public customPreprocessingFunction?: (driver: IBrowserDriver, elements: object, elementTypeMetadata: IElementTypeMetadata, additionalElementInfo: IAdditionalElementInfo) => void;
    public customGetTextForFunction?: (driver: IBrowserDriver, element: object, existingTextValue: string, additionalElementInfo: IAdditionalElementInfo) => string;
    public getClickableElementFunction?: (element: object) => object;

    //public findElementFilter?: string = null; // Not yet used.
    public reFindElementsAfterActionPerformed: boolean = false;
    public useDoubleClick: boolean = false;
    public cssFilter: string = null;

    public name: string = null; // Display name for an element
    public names: string[] = null; // Display names for a list of elements.
    public nameToPrettyNameMap: INameToPrettyNameMap = null;
    public standardNameToPrettyStandardNameMap: INameToPrettyNameMap;
    public elementsSubtypeMap: INameToPrettyNameMap;
    public hasAddedDateTimeSuffix: boolean = false;
    public operationCompletedServiceMap: IOperationCompletedServiceMap;

    public toggleCheckboxMethodName: string = null;

    public allowZeroElements: boolean = null;
    public maxTimeoutsLookingForScriptableElements: number = null;

    public constructor(additionalElementInfo: IAdditionalElementInfo) {
        if (additionalElementInfo.innerTextSelector != null)
            this.innerTextSelector = additionalElementInfo.innerTextSelector;
        if (additionalElementInfo.customTextValue != null)
            this.customTextValue = additionalElementInfo.customTextValue;
        if (additionalElementInfo.customTextValues != null)
            this.customTextValues = additionalElementInfo.customTextValues;

        if (additionalElementInfo.elementSubtypeName != null)
            this.elementSubtypeName = additionalElementInfo.elementSubtypeName;
        if (additionalElementInfo.elementSubtypeNames != null)
            this.elementSubtypeNames = additionalElementInfo.elementSubtypeNames;

        if (additionalElementInfo.customPreprocessingFunction != null)
            this.customPreprocessingFunction = additionalElementInfo.customPreprocessingFunction;
        if (additionalElementInfo.customGetTextForFunction != null)
            this.customGetTextForFunction = additionalElementInfo.customGetTextForFunction;

        //if (additionalElementInfo.findElementFilter != null)
        //    this.findElementFilter = additionalElementInfo.findElementFilter;
        if (additionalElementInfo.reFindElementsAfterActionPerformed != null)
            this.reFindElementsAfterActionPerformed = additionalElementInfo.reFindElementsAfterActionPerformed;
        if (additionalElementInfo.useDoubleClick != null)
            this.useDoubleClick = additionalElementInfo.useDoubleClick;
        if (additionalElementInfo.cssFilter == null)
            this.cssFilter = additionalElementInfo.cssFilter;

        if (additionalElementInfo.name != null)
            this.name = additionalElementInfo.name;
        if (additionalElementInfo.names != null)
            this.names = additionalElementInfo.names;
        if (additionalElementInfo.nameToPrettyNameMap != null)
            this.nameToPrettyNameMap = additionalElementInfo.nameToPrettyNameMap;
        if (additionalElementInfo.standardNameToPrettyStandardNameMap != null)
            this.standardNameToPrettyStandardNameMap = additionalElementInfo.standardNameToPrettyStandardNameMap;
        if (additionalElementInfo.elementsSubtypeMap != null)
            this.elementsSubtypeMap = additionalElementInfo.elementsSubtypeMap;
        if (additionalElementInfo.hasAddedDateTimeSuffix != null)
            this.hasAddedDateTimeSuffix = additionalElementInfo.hasAddedDateTimeSuffix;
        if (additionalElementInfo.operationCompletedServiceMap != null)
            this.operationCompletedServiceMap = additionalElementInfo.operationCompletedServiceMap;

        if (additionalElementInfo.toggleCheckboxMethodName != null)
            this.toggleCheckboxMethodName = additionalElementInfo.toggleCheckboxMethodName;

        if (additionalElementInfo.allowZeroElements != null)
            this.allowZeroElements = additionalElementInfo.allowZeroElements;
        if (additionalElementInfo.maxTimeoutsLookingForScriptableElements != null)
            this.maxTimeoutsLookingForScriptableElements = additionalElementInfo.maxTimeoutsLookingForScriptableElements;
    }
}
