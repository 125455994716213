import { IViewModel } from '../interfaces/view-model.interface';
import { RoleGroupMember } from './role-group-member.model';
import { Role } from './role.model';

export class SiteLevelRoleMember implements IViewModel {
    public id: number;

    public roleId: number;
    public roleName: string;

    public memberType: string;  // User or Group

    public member: string; // UserId or GroupName
    public memberId: string; // The underlying user MAX Id or MAX group code.

    public constructor(roleGroupMember: RoleGroupMember = null, role: Role) {
        if (roleGroupMember != null) {
            this.roleName = roleGroupMember.roleName;
            this.memberType = roleGroupMember.memberType;
            this.member = roleGroupMember.member;
            this.memberId = roleGroupMember.memberId;
        }

        if (role != null) {
            this.roleId = role.id;
            this.roleName = role.name;
        }
    }
}
