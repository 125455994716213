<div>
    <div *ngIf="this.ProgressUpdateInfo; else showSiteName">
        <h6 class="progress-title-h">{{this.StatusText}}</h6>

        <section class="progress-section">
            <mat-progress-bar class="status-progress-bar"
                              [mode]="this.Mode"
                              [value]="this.Value"
                              [bufferValue]="this.BufferValue"
                              [color]="this.Color">
            </mat-progress-bar>
        </section>
    </div>

    <!-- VNEXT-1066: KLW - Properties of the Banner -->
    <ng-template #showSiteName>
        <div class="sitename">
           {{this.SiteName}}
        </div>
    </ng-template>
</div>
