import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';

import { CopySiteService } from '../../services/copy-site.service';
import { AsyncJobService } from '../../services/async-job.service';
import { AsyncJob } from '../../models/async-job.model';
import { AsyncJobDialogBase } from '../async-job-base/async-job-base.dialog';
import { ImportAsyncJobDialogBase } from '../async-job-base/import-async-job-base.dialog';
import { ExportSiteMetrics } from '../../models/export-import/export-site-metrics.model';

@Component({
    selector: 'app-import-site',
    templateUrl: './import-site.dialog.html',
    styleUrls: [
        '../async-job-base/async-job-base.dialog.scss',
        './import-site.dialog.scss'
    ]
})
export class ImportSiteDialog extends ImportAsyncJobDialogBase implements OnInit {
    // Properties.
    private importSiteName: string = null;
    private importedSiteId: number = -1;
    private importSiteMetrics: ExportSiteMetrics;

    @ViewChild('file') file;
    private fileSet: Set<File> = new Set();
    private selectedFileName: string = null;
    private ignoreValidationErrors: boolean = false; // NOTE: A true setting is not yet supported on the server, so this is not settable in the UI

    // Constructor.
    public constructor(public dialogRef: MatDialogRef<ImportSiteDialog>,
        //@Inject(MAT_DIALOG_DATA) public siteToCopy: DataCollection,
        private copySiteService: CopySiteService,
        private injectedAsyncJobService: AsyncJobService)
    {
        super(injectedAsyncJobService);

        dialogRef.disableClose = true; // Disable closing the dialogue when the user clicks on the page.

        return;
    }

    // Implement a base class's abstract method.
    public get ComponentClassName(): string {
        return 'ImportSiteDialog';
    }

    public get ExecutionSummaryText(): string {
        let summaryText: string = super.deriveExecutionSummaryText(this.importSiteMetrics);

        return summaryText;
    }

    // Life cycle methods.
    public ngOnInit(): void {
        return;
    }

    // Accessor methods.
    public get ImportSiteName(): string {
        return (this.importSiteName);
    }
    public set ImportSiteName(importSiteNameParam: string) {
        this.importSiteName = importSiteNameParam;

        return;
    }

    public get ImportedSiteId(): number {
        return (this.jobCompleted ? this.importedSiteId : null);
    }

    public get DismissButtonTitle(): string {
        //return (this.jobCompleted && (!this.jobErrorOccurred) ? 'Dismiss, Load Imported Site' : 'Dismiss');
        return ('Dismiss');
    }

    public get ImportSiteNameIsEmpty(): boolean {
        let isEmpty: boolean = ((this.importSiteName == null) || (this.importSiteName.trim().length == 0));

        return (isEmpty);
    }

    public get FileSelected(): boolean {
        let bFileSelected: boolean = (this.fileSet.size > 0);

        return (bFileSelected);
    }
    public get IgnoreValidationErrors(): boolean {
        return this.ignoreValidationErrors;
    }

    public set IgnoreValidationErrors(value: boolean) {
        this.ignoreValidationErrors = value;
    }

    public get SelectedFileName(): string {
        return (this.selectedFileName != null ? this.selectedFileName : 'No File Selected');
    }

    // Handle dialogue action(s).
    public selectImportFile(): void {
        this.file.nativeElement.click(); // Cause the hidden file control to open.

        return;
    }

    public onFileAdded(): void {
        // Clear any previous file.
        this.fileSet = new Set()

        // Save the selected file.
        const files: { [key: string]: File } = this.file.nativeElement.files;

        for (let key in files) {
            if (!isNaN(parseInt(key))) {
                let selectedFile: File = files[key];

                this.fileSet.add(selectedFile);

                this.selectedFileName = selectedFile.name;
            }
        }

        return;
    }

    public startSiteImport(): void {
        // Initiate the import.
        let arrFile: File[] = Array.from(this.fileSet);

        if (arrFile.length > 0) {
            let file: File = arrFile[0];

            this.copySiteService.importSite(file, this.importSiteName, this.ignoreValidationErrors).then(asyncJob => {
                // Save the returned job key.
                this.startMonitoringJob(asyncJob, this.jobCompletedArrowFunction, this.jobCompletedArrowFunction);
            });
        }

        return;
    }

    public dismissClicked(): void {
        let dialogResult: number = !this.jobErrorOccurred ? this.ImportedSiteId : null;
        this.dialogRef.close(dialogResult);
    }

    // Private methods.
    private jobCompletedArrowFunction = (asyncJob: AsyncJob): void => {
        this.importedSiteId = this.parseCreatedSiteId(asyncJob);

        let msg = asyncJob.stdOut || asyncJob.stdError;
        this.importSiteMetrics = this.parseMetrics(msg);
    }

    private parseMetrics(jobMetricsJson: string): ExportSiteMetrics {
        let jobMetricsObject: Object = JSON.parse(jobMetricsJson);
        let metrics: ExportSiteMetrics = new ExportSiteMetrics(jobMetricsObject);
        return metrics;
    }
}
