import { DataCollection } from "./data-collection.model";


export class SiteGridData{
    public results: DataCollection[];

    public resultsAsPlainObjects: object[] = []; // results translated into plain JS objects needed by Kendo Grid by service code

    public total: number = 5000;
}
