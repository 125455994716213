import { ImportMetricsBase } from './import-metrics-base';
import { IJobSummaryText } from './i-job-summary-text';

export class ImportFormInstancesMetrics extends ImportMetricsBase {
    // File counts.
    public totalFileCount: number;
    public zipFileCount: number;
    public xmlFileCount: number;
    public jsonFileCount: number;
    public otherFileCount: number;

    // Processing counts.
    public formInstanceFilesProcessed: number;
    public formInstancesCreated: number;
    public formInstancesUpdated: number;

    public importFileNames: string[];
    public exceptionLoggedAndJobTerminated: boolean;

    // Import summary message.
    //public importSummaryMessage: string;

    // Property names for the super/base class constructor.
    private static readonly propertyNames: string[] =
        [
            // Properties from MetricsBase.
            'progressLog',
            'warningLog',
            'errorLog',

            'jobSummaryMessage',

            'exceptionLoggedAndJobTerminated',

            // My properties.
            'totalFileCount',
            'zipFileCount',
            'xmlFileCount',
            'jsonFileCount',
            'otherFileCount',

            'formInstanceFilesProcessed',
            'formInstancesCreated',
            'formInstancesUpdated',

            'importFileNames'
        ];

    // Constructor.
    public constructor(jsonData: Object) {
        super(ImportFormInstancesMetrics.propertyNames, jsonData);
    }
}
