import { TextFieldDefinitionLogicBase } from './text-logic-base';
import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { FormInstanceGridRow } from '../../site-content/form-instance-grid-row.model';

export class TextMetadataFieldDefLogic extends TextFieldDefinitionLogicBase {
    public constructor(protected fieldName: string) {
        super();
    }

    public unpackTemplateInstancesRow(formField: FormField, colValue: FormInstanceElement, row: FormInstanceGridRow): any {
        let fieldValue: string = row[formField.name];
        return fieldValue;
    }
}
