import { Injectable } from '@angular/core';

import { Folder } from '../models/site-content/folder.model';

/*
 * This is a singleton service used as the single source of the current folder of the Structure screen
 * It was added for VNEXT-111
*/ 

@Injectable({
    providedIn: 'root'
})
export class CurrentFolderService {

    private folder: Folder;

    constructor() { }

    public set Folder(folder: Folder) {
        this.folder = folder;
    }

    public get Folder(): Folder{
        return this.folder;
    }

    public nullifyCurrentFolder() {
        this.folder = null;
    }

    // If this.folder's id property matches that of the id argument, returns true, otherwise returns false
    public isCurrentFolder(id: number): boolean {
        if (this.folder != null && this.folder.id == id) {
            return true;
        } else {
            return false;
        }
    }
}
