
export enum FormFieldConstraintTypeEnum {
    SelectOptions = 'SelectOptions',
    RegularExpression = 'Regex',
    CascadingDropdown = 'CascadingDropdown',
    NumericRange = 'NumericRange',
    DateRange = 'DateRange',

    // New list-values constraint types:
    SimpleListValues = 'SimpleListValues',
    CascadingListValues = 'CascadingListValues'
}

// Define a constraint type enumeration with user-friendly names.
export enum FormFieldConstraintTypeForUsersEnum {
    SelectOptions = 'List Options',
    RegularExpression = 'Regular Expression',
    CascadingDropdown = 'Cascading Dropdown',
    NumericRange = 'Numeric Range',
    DateRange = 'Date Range',

    // New list-values constraint types:
    SimpleListValues = 'Simple List Values',
    CascadingListValues = 'Cascading List Values'
} 
