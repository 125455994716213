import { Injectable } from '@angular/core';

// This service contains the logic to handle use of the Go Back button.
// Could have simply injected Location service and called its back() method, but that would not be specific to vNext, and would allow the Go Back
// button to navigate a user out of the application, so this implements app-specific navigation.
@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private navigationHistoryStack: string[] = [];
    // we need to know if back button was clicked so that we don't go and add a page to the stack
    private backButtonWasClicked: boolean = false;

    public pushOnUrl(url: string) {
        //console.log('push ' + url);
        if (this.backButtonWasClicked) {
            //console.log(' previous nav was Go Back, so do not push this on');
            this.backButtonWasClicked = false; // reset it and do not push url onto the stack
        } else {
            //console.log(' previous nav was NOT Go Back, so push it on...')
            this.navigationHistoryStack.push(url);
            //console.log(this.navigationHistoryStack);
        }
    }

    public popOffPreviousUrl(): string {
        this.backButtonWasClicked = true;
        let url = this.navigationHistoryStack.pop();
        //console.log('popOffPreviousUrl');
        //console.log(this.navigationHistoryStack);

        return url;
    }
}

