import { CachedFormFieldService } from '../../../../../services/form-field-with-cache.service';
import { FormField } from '../../../../../models/form-builder/form-field.model';
import { IFormFieldConstraintLiaison } from './ifield-constraint-liason.interface';
import { FormFieldSelectOptionsConstraintLiaison } from './field-select-options-constraint-liaison';
import { FormFieldRegularExpressionConstraintLiaison } from './field-regular-expression-constraint-liaison';
import { FormFieldCascadingDropdownConstraintLiaison } from './field-cascading-dropdown-constraint-liaison';
import { FormFieldNumericRangeConstraintLiaison } from './field-numeric-range-constraint-liaison';
import { FormFieldDateRangeConstraintLiaison } from './field-date-range-constraint-liaison';
import { FormFieldSimpleListValueConstraintLiaison } from './field-simple-list-values-constraint-liaison';
import { FormFieldCascadingListValueConstraintLiaison } from './field-cascading-list-values-constraint-liaison';

// Note:  this class serves as a convenience, providing/applying logic for
// the five different types of form field constraints in a single class.
//
// Note:  presently this class only provides static methods.
export class FormFieldConstraintLiaisonManager {
    // Properties.
    //private numLiaisons: number = 0;
    //private numLiaisonsProcessed: number = 0;
    //private numConstraintUpdatesApplied = 0;

    // Inaccessible constructor.
    private constructor() { }

    // Static methods.
    // The following method updates a form field to the
    // latest values for any constraints it might have.
    public static updateFormFieldWithLatestConstraintValues(cachedFormFieldService: CachedFormFieldService, siteId: number, formField: FormField): Promise<number> {
        let result = new Promise<number>((resolve, reject) => {
            let constraintLiaisons: IFormFieldConstraintLiaison[] = FormFieldConstraintLiaisonManager.getConstraintLiaisons();
            //let numLiaisons: number = constraintLiaisons.length;
            let numLiaisonsProcessed: number = 0;
            let numConstraintUpdatesApplied = 0; // It looks like this will either stay as zero or be set to one (in the if branch below).

            for (let index: number = 0; index < constraintLiaisons.length; index++) {
                let constraintLiaison: IFormFieldConstraintLiaison = constraintLiaisons[index];
                let constraintId: number = constraintLiaison.getConstraintId(formField);

                if ((constraintId != null) && (constraintId != 0)) {
                    let getSiteConstraintMethodName: string = constraintLiaison.siteNamedConstraintsMethodName;

                    // Each constraint type has its own service method name for retrieve constraints of its type for a given site.
                    cachedFormFieldService[getSiteConstraintMethodName](siteId).then(siteConstraints => {
                        if (constraintLiaison.updateFormFieldWithLatestConstraintValues(siteConstraints, formField))
                            numConstraintUpdatesApplied++;
                        numLiaisonsProcessed++;

                        if (numLiaisonsProcessed == constraintLiaisons.length)
                            resolve(numConstraintUpdatesApplied);
                    });
                } else {
                    numLiaisonsProcessed++;

                    if (numLiaisonsProcessed == constraintLiaisons.length)
                        resolve(numConstraintUpdatesApplied);
                }
            }
        });

        return result;
    }
    public static synchronousUpdateFormFieldWithLatestConstraintValues(cachedFormFieldService: CachedFormFieldService, siteId: number, formField: FormField): number {
        let constraintLiaisons: IFormFieldConstraintLiaison[] = FormFieldConstraintLiaisonManager.getConstraintLiaisons();
        //let numLiaisons: number = constraintLiaisons.length;
        //let numLiaisonsProcessed: number = 0;
        let numConstraintUpdatesApplied = 0;

        for (let index: number = 0; index < constraintLiaisons.length; index++) {
            let constraintLiaison: IFormFieldConstraintLiaison = constraintLiaisons[index];
            let constraintId: number = constraintLiaison.getConstraintId(formField);

            if ((constraintId != null) && (constraintId != 0)) {
                let getSiteConstraintMethodName: string = constraintLiaison.siteNamedConstraintsMethodName;

                cachedFormFieldService[getSiteConstraintMethodName](siteId).then(siteConstraints => {
                    if (constraintLiaison.updateFormFieldWithLatestConstraintValues(siteConstraints, formField))
                        numConstraintUpdatesApplied++;
                    //numLiaisonsProcessed++;

                    //if (numLiaisonsProcessed == numLiaisons)
                    //    resolve(numConstraintUpdatesApplied);
                });
            } else {
                //numLiaisonsProcessed++;

                //if (numLiaisonsProcessed == numLiaisons)
                //    resolve(numConstraintUpdatesApplied);
            }
        }

        return numConstraintUpdatesApplied;
    }

    // Helper methods.
    private static getConstraintLiaisons(): IFormFieldConstraintLiaison[] {
        let constraintLiaisons: IFormFieldConstraintLiaison[] = [];

        constraintLiaisons.push(new FormFieldSelectOptionsConstraintLiaison());
        constraintLiaisons.push(new FormFieldRegularExpressionConstraintLiaison());
        constraintLiaisons.push(new FormFieldCascadingDropdownConstraintLiaison());
        constraintLiaisons.push(new FormFieldNumericRangeConstraintLiaison());
        constraintLiaisons.push(new FormFieldDateRangeConstraintLiaison());

        // New constraints based on value lists:
        constraintLiaisons.push(new FormFieldSimpleListValueConstraintLiaison());
        constraintLiaisons.push(new FormFieldCascadingListValueConstraintLiaison());

        return constraintLiaisons;
    }
}
