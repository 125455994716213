import { HtmlElementInfo } from './html-element-info.model';

export class ElementTypeInfo {
    public elementType: string;    // An element type metadata key.
    public elementSubtypes: string[]; // A subtype, a first sub-categorization of elements of a given type.
    public elementTabName: string; // For display in the select component info component.

    public elements: HtmlElementInfo[] = null;

    public constructor(elementType: string, elementSubtypes: string[], elementTabName: string){
        this.elementType = elementType;
        this.elementSubtypes = elementSubtypes;
        this.elementTabName = elementTabName;
    }
}
