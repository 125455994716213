import { HeaderValuePair } from '../csv-data/header-value-pair.model';
import { FormField } from '../form-builder/form-field.model';
import { CsvOptionsFileData } from '../csv-data/csv-options-file-data.model';

export class CascadingDropDownFormFieldConfig {
    // Properties.
    public dropDownHeaders: string[];
    public dropDownOptionsAsHierarchy: HeaderValuePair[];

    // Constructor.
    constructor(properties: any) {
        this.dropDownHeaders = properties['dropDownHeaders'];
        this.dropDownOptionsAsHierarchy = properties['dropDownOptionsAsHierarchy'];
    }

    // Getter method(s).
    public get NumLevels(): number {
        return (this.dropDownHeaders != null ? this.dropDownHeaders.length : 0);
    }
    public get DropDownHeaders(): string[] {
        return this.dropDownHeaders;
    }

    // Static method.
    /*
    public static parseConfig(formField: FormField): CascadingDropDownFormFieldConfig {
        let config: CascadingDropDownFormFieldConfig = (formField != null ? CascadingDropDownFormFieldConfig.parseJsonConfig(formField.jsonConfig) : null);
        return config;
    }
    */
    /*
    public static parseConfigFrom(jsonConfig: string): CascadingDropDownFormFieldConfig {
        let config: CascadingDropDownFormFieldConfig = (jsonConfig != null ? CascadingDropDownFormFieldConfig.parseJsonConfig(jsonConfig) : null);
        return config;
    }
    */

    public static parseJsonConfig(jsonConfig: string): CascadingDropDownFormFieldConfig {
        let config: CascadingDropDownFormFieldConfig = null;

        //if (formField && formField.jsonConfig && (formField.jsonConfig.trim() !== '')) {
        if ((jsonConfig != null) && (jsonConfig.trim() !== '')) {
            //let hshConfig: any = JSON.parse(formField.jsonConfig);
            let hshConfig: any = JSON.parse(jsonConfig);
            config = new CascadingDropDownFormFieldConfig(hshConfig);
        }

        return config;
    }

    public static createConfigFromCsvOptionsData(csvOptions: CsvOptionsFileData): CascadingDropDownFormFieldConfig {
        //let config: CascadingDropDownFormFieldConfig = { dropDownHeaders: csvOptions.headers, dropDownOptionsAsHierarchy: null };
        let config: CascadingDropDownFormFieldConfig = new CascadingDropDownFormFieldConfig({ dropDownHeaders: csvOptions.headers, dropDownOptionsAsHierarchy: null });
        config.dropDownOptionsAsHierarchy = CascadingDropDownFormFieldConfig.buildOptionValuesHierarchy(config.dropDownHeaders, csvOptions.linesOfHeaderValuePairs);

        return config;
    }

    // Helper methods.
    private static buildOptionValuesHierarchy(dropDownHeaders: string[], flatHeaderValuePairs: HeaderValuePair[][], iHeader: number = 0,
        ancestorHeaderValuePairs: HeaderValuePair[] = [], iValuePairStartingIndex: number = 0): HeaderValuePair[] {
        // Initialize the result array.
        let rootHeaderValuePairs: HeaderValuePair[] = [];

        let previousValueText: string = null;
        let iNextIndex: number = 1;
        let bHaveNewValue: boolean = true;

        let iValuePair: number = iValuePairStartingIndex;

        while ((iValuePair < flatHeaderValuePairs.length) && bHaveNewValue) {
            let flatHeaderValuePairSet: HeaderValuePair[] = flatHeaderValuePairs[iValuePair];

            let flatHeaderValuePair: HeaderValuePair = flatHeaderValuePairSet[iHeader];

            if ((previousValueText == null) || (flatHeaderValuePair.ValueText !== previousValueText)) {
                // Check to see if we have a new value.
                // By definition, a change in the previous
                // values constitutes a new cascading
                // drop-down value if we are processing
                // the first/zeroeth header.
                //
                // If we are not processing the first/
                // zeroeth header, we have to check matches
                // with ancestor values.
                //let bHaveNewValue: boolean = (iHeader === 0);
                bHaveNewValue = (iHeader === 0);

                if (!bHaveNewValue) {
                    //(iHeader === 0) || (prevHeaderValuePair.ValueText === ancestorHeaderValuePair.ValueText)) {
                    let iNumMatches: number = 0;

                    for (let iAncestor: number = 0; iAncestor < ancestorHeaderValuePairs.length; iAncestor++) {
                        let ancestorHeaderValuePair: HeaderValuePair = ancestorHeaderValuePairs[iAncestor];

                        if (flatHeaderValuePairSet[iAncestor].ValueText === ancestorHeaderValuePair.ValueText) {
                            iNumMatches++;
                        }
                    }

                    if (iNumMatches === ancestorHeaderValuePairs.length) {
                        bHaveNewValue = true;
                    }
                }

                if (bHaveNewValue) {
                    let newHeaderValuePair: HeaderValuePair =
                    {
                        //Index: iNextIndex++,
                        Index: iNextIndex++,
                        Header: flatHeaderValuePair.Header,
                        ValueText: flatHeaderValuePair.ValueText,
                        ChildValuePairs: []
                    }

                    if (iHeader < dropDownHeaders.length - 1) {
                        let ancestorsForNextHeader: HeaderValuePair[] = [];
                        for (let iAncestor: number = 0; iAncestor < ancestorHeaderValuePairs.length; iAncestor++) {
                            let ancestorHeaderValuePair: HeaderValuePair = ancestorHeaderValuePairs[iAncestor];

                            ancestorsForNextHeader.push(ancestorHeaderValuePair);
                        }
                        ancestorsForNextHeader.push(newHeaderValuePair);

                        newHeaderValuePair.ChildValuePairs =
                            CascadingDropDownFormFieldConfig.buildOptionValuesHierarchy(dropDownHeaders,
                                flatHeaderValuePairs,
                                iHeader + 1,
                                ancestorsForNextHeader,
                                iValuePair); // Tell the method to start at my current location.
                    } // if

                    rootHeaderValuePairs.push(newHeaderValuePair);
                } // if
            } // if

            previousValueText = flatHeaderValuePair.ValueText;
            iValuePair++;
        } // while

        return (rootHeaderValuePairs);
    }

}
