// Note:  named this file a bit differently so the name would not be excessively long.

import { FormField } from './../form-field.model';
import { FormInstanceElement } from './../form-instance-element.model';
import { IFormFieldConstraint, IFormFieldConstraintWithMetrics } from '../../../interfaces/iform-field-constraint.interface';
import { ICloneAndCopy } from '../../../interfaces/clone-and-copy';
import { FormFieldConstraintBase } from './form-field-constraint-base.model';
import { FormFieldConstraintTypeEnum } from '../../../enums/form-field-constraint-type.enum';
import { CascadingDropDownFormFieldConfig } from '../../cascading-dropdown/cascading-dropdown-config.model';

export class FormFieldCascadingDropdownConstraint extends FormFieldConstraintBase implements IFormFieldConstraint, ICloneAndCopy {
    // Properties.
    protected jsonConfig: string;
    private configForConstraintExpressionSummary: CascadingDropDownFormFieldConfig = null;

    // Constructor.
    public constructor(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        super(dataCollectionId, constraintId, FormFieldConstraintTypeEnum.CascadingDropdown, constraintName);

        this.jsonConfig = constraintValue;
    }

    // Implement IFormFieldConstraint.
    // Property-related methods.
    public get type(): string {
        return FormFieldConstraintTypeEnum.CascadingDropdown;
    }

    public get constraintExpression(): string {
        return this.jsonConfig;
    }
    public set constraintExpression(value: string) {
        this.jsonConfig = value;
    }

    // Not relevant for this field type
    public get constraintFailureMessage(): string {
        return null;
    }
    public set constraintFailureMessage(value: string) {
        // noop
    }

    public get constraintExpressionSummary(): string {
        if (this.configForConstraintExpressionSummary == null)
            this.configForConstraintExpressionSummary = CascadingDropDownFormFieldConfig.parseJsonConfig(this.jsonConfig);
        let summary: string = '';
        if (this.configForConstraintExpressionSummary != null) {
            for (let index: number = 0; index < this.configForConstraintExpressionSummary.dropDownHeaders.length; index++) {
                let header: string = this.configForConstraintExpressionSummary.dropDownHeaders[index];
                if (index > 0)
                    summary += ' -> ';
                summary += header;
            }
        }
        return summary;
    }

    // Logic-related methods.
    public validate(formField: FormField, formInstanceElement: FormInstanceElement): void {
        // TO DO:  IMPLEMENT THIS METHOD.
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let clone: FormFieldCascadingDropdownConstraint = new FormFieldCascadingDropdownConstraint(this.dataCollectionId, this.constraintId, this.constraintName, this.jsonConfig);

        //clone.constraintId = this.constraintId;
        //clone.constraintName = this.constraintName;
        //clone.jsonConfig = this.jsonConfig;

        return clone;
    }

    public copy(objectToCopyParam: ICloneAndCopy): void {
        let objectToCopy: FormFieldCascadingDropdownConstraint = <FormFieldCascadingDropdownConstraint>objectToCopyParam;

        this.dataCollectionId = objectToCopy.dataCollectionId;
        this.constraintId = objectToCopy.constraintId;
        this.constraintType = objectToCopy.constraintType;
        this.constraintName = objectToCopy.constraintName;
        this.jsonConfig = objectToCopy.jsonConfig;
    }
}

export class FormFieldCascadingDropdownConstraintWithMetrics extends FormFieldCascadingDropdownConstraint implements IFormFieldConstraintWithMetrics {
    // Properties.
    public useCount: number = 0;

    // Constructor.
    public constructor(dataCollectionId: number, constraintId: number = 0, constraintName: string = null, constraintValue: string = null) {
        super(dataCollectionId, constraintId, constraintName, constraintValue);
    }
}
