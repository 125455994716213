import { ClickableElementTypeMetadata } from './clickable-element-type-metadata.model';
import { HtmlPropertyValue } from './html-property-value.model';
import { ElementCountExpected } from './element-count-expected.enum';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
import { HtmlElementTypeNames } from './html-element-type-names.model';
import { AdditionalElementInfo, OperationCompletedServiceInfo } from './additional-element-info.model';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
import { HtmlElementInfo } from './html-element-info.model';

export class LinksElementTypeMetadata extends ClickableElementTypeMetadata {
    public constructor() {
        //super('a', 'links', ElementCountExpected.Singular_or_Plural);
        super('a', HtmlElementTypeNames.links_metadataKey, ElementCountExpected.Singular_or_Plural);
    }

    // Override metadata methods.
    public getTitle = (driver: IBrowserDriver, element: object, additionalElementInfo: AdditionalElementInfo = null): string => {
        //return $(element).text();
        let title: string = driver.getTextFor(element);
        return title;
    }

    public getProperties = (driver: IBrowserDriver, element: object): HtmlPropertyValue[] => {
        let properties: HtmlPropertyValue[] = [];

        //let hrefValue: string = $(element).attr('href');
        let hrefValue: string = driver.getAttributeValueFor(element, 'href');
        let propertyValue: HtmlPropertyValue = new HtmlPropertyValue('href', hrefValue);
        properties.push(propertyValue);

        return properties;
    }

    //public click(driver: IBrowserDriver, component: ITestableComponent, element: object): void {
    public click(driver: IBrowserDriver, recorderService: ITestActionRecorderService, component: ITestableComponent, htmlElementInfo: HtmlElementInfo, element: object, getClickableElementFunction: (element: object) => object): void {
        let operationCompletedServiceInfo: OperationCompletedServiceInfo = this.getAnyOperationCompletedServiceInfoFor(htmlElementInfo);
        recorderService.clickLink(htmlElementInfo.elementTitle, htmlElementInfo.elementTypeMetadata.metadataKey, htmlElementInfo.elementSubtype, operationCompletedServiceInfo != null ? operationCompletedServiceInfo.serviceName : null, operationCompletedServiceInfo != null ? operationCompletedServiceInfo.operationName : null);

        driver.clickLink(component, element);
    }
}
