import { ComponentRef } from '@angular/core';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject } from 'rxjs';
import { Observable, of } from 'rxjs';
//import { MatSort, Sort } from '@angular/material/sort';
//import { MatTableDataSource } from '@angular/material/table';

import { FormField } from '../form-builder/form-field.model';
import { FormInstanceElement } from '../form-builder/form-instance-element.model';
import { GridConfig } from './grid-config.model';
import { IFormFieldComponent } from '../../interfaces/iform-field-component';
import { IFormContentElement, IRowCellDataHash, GridRowViewModel } from '../form-builder/grid-row.model';
import { FormFieldProcessingPhaseEnum } from '../../enums/form-field-processing-phase.enum';
import { INumericValuesHashByFieldName, IGridRow } from '../../interfaces/grid-row.interface';
import {
    CascadingDropDownFieldType, RichTextFieldType, ShortTextFieldType, IntegerFieldType,
    DateFieldType, DropDownFieldType, CurrencyFieldType, CheckboxFieldType
} from '../form-builder/form-field-types';
import { CascadingDropDownFormFieldConfig } from '../../models/cascading-dropdown/cascading-dropdown-config.model';
import { CascadingDropDownFormFieldData } from '../../models/cascading-dropdown/cascading-dropdown-data.model';
import { GridFormInstanceElementWrapper } from './grid-form-instance-element-wrapper.model';
import { ComponentAndFormField } from './component-and-form-field-model';
import { FieldDefinition } from '../../models/form-builder/field-definition.model';
import { FieldDefinitionService } from '../../services/field-definition.service';
import { IFieldDefinitionLogic } from '../../interfaces/ifield-definition-logic.interface';
import { NumericFormInstanceElementWrapper, NumericColumnTotalInfo } from './column-total-models';
import { GridRowDef, GRID_ROW_ID_KEY, GRID_COLUMN_ID_KEY } from './grid-row.model';
import { GridCellDataForSerialization } from './grid-cell-data.model';
import { SortGridRowsByFormField, SortGridRowsByRowId } from './sort-classes';
import { IFormFieldNameToFormInstanceElementWrapper, IGetCellDisplayValue } from './grid-interfaces';
import { KENDA_DATA_GRID_ROW_KEY } from '../../services/kendo-grid.service';

interface INumericColumnTotalInfoByColClientId {
    [gridColClientId: number]: NumericColumnTotalInfo;
}

//const KENDA_DATA_GRID_ROW_KEY: string = '__gridRow';

// Define the grid data source.
export class GridAllModesDataSource implements DataSource<GridRowDef> {
    // Instance data.
    private gridConfig: GridConfig = null;
    //private igetDisplayValue: IGetDisplayValue = null;
    private igetDisplayValue: IGetCellDisplayValue = null;

    private arrGridRows: GridRowDef[] = [];
    private arrDeletedGridRows: GridRowDef[] = [];

    private gridRowsSubject: BehaviorSubject<GridRowDef[]> = new BehaviorSubject<GridRowDef[]>([]);
    //private simpleMatTableDataSource: MatTableDataSource<GridRowDef> = null; // Note:  this is not presently being used.

    private iNextId: number = 1;

    // Begin properties related to numeric column totals.
    private hshTotalInfoByColClientId: INumericColumnTotalInfoByColClientId = null;
    // End properties related to numeric column totals.

    // Sort-related property.
    private sortField: FormField = null;
    private fieldLogic: IFieldDefinitionLogic = null;
    private sortAscending: boolean = false;

    // Kendo-related data.
    private arrKendoGridRows: object[] = [];

    // Constructor.
    public constructor(gridConfigParam: GridConfig, getDisplayValueParam: IGetCellDisplayValue) {
        this.gridConfig = gridConfigParam;
        this.igetDisplayValue = getDisplayValueParam;
    }

    // Implement the DataSource interface.
    public connect(collectionViewer: CollectionViewer): Observable<GridRowDef[]> {
        let result: Observable<GridRowDef[]> = this.gridRowsSubject.asObservable();

        this.gridRowsSubject.next(this.arrGridRows);

        return (result);
    }

    public disconnect(collectionViewer: CollectionViewer): void {
        this.gridRowsSubject.complete();

        return;
    }
    // End implementation the DataSource interface.

    // Getter methods.
    public get GridRows(): GridRowDef[] {
        return (this.arrGridRows);
    }

    public set GridRows(gridRows: GridRowDef[]) {
        this.arrGridRows = gridRows;
    }

    public getGridRow(iRowIndex: number): GridRowDef {
        let gridRowDef: GridRowDef =
            ((iRowIndex >= 0) && (iRowIndex < this.arrGridRows.length) ?
                this.arrGridRows[iRowIndex] : null);

        return (gridRowDef);
    }

    public get GridRowCount(): number {
        return (this.arrGridRows ? this.arrGridRows.length : 0);
    }

    public getRowByClientId(iRowClientId: number): GridRowDef {
        let result: GridRowDef = null;

        if (this.arrGridRows) {
            let searchRows: GridRowDef[] = this.arrGridRows.filter(gr => gr.ClientId == iRowClientId);
            if (searchRows && (searchRows.length == 1)) {
                result = searchRows[0];
            }
        }

        return (result);
    }

    // TODO -- optimize by building a map on first call
    public getRowByDatabaseId(databaseId: number): GridRowDef {
        let result: GridRowDef = null;

        if (this.arrGridRows) {
            let searchRows: GridRowDef[] = this.arrGridRows.filter(gr => gr.DatabaseId == databaseId);
            if (searchRows && (searchRows.length == 1)) {
                result = searchRows[0];
            }
        }

        return (result);
    }

    /*
    public get SimpleTableDataSource(): MatTableDataSource<GridRowDef> {
        return this.simpleMatTableDataSource;
    }
    */
    // End getter methods.

    // Manipulate grid data.
    public addRow(iDatabaseId: number = 0, bNotifySubject: boolean = true, bInitializeCellData: boolean = true): GridRowDef {
        let newRow: GridRowDef = null;
        if (bInitializeCellData) {
            newRow = new GridRowDef(this.gridConfig, this.iNextId++, this.arrGridRows.length, iDatabaseId, null, this.igetDisplayValue);
        } else {
            newRow = new GridRowDef(this.gridConfig, this.iNextId++, this.arrGridRows.length, iDatabaseId, null);
        }

        if (this.gridConfig.IsFootnote) {
            let footnoteNumber = this.arrGridRows.length + 1;
            newRow.FormInstanceElementWrappers[0].formInstanceElement.textValue = footnoteNumber.toString();
            newRow.FormInstanceElementWrappers[0].formInstanceElement.intValue = footnoteNumber;
            newRow.FormInstanceElementWrappers[0].standinDisplayValue = footnoteNumber.toString();
            newRow.FormInstanceElementWrappers[0].formInstanceElement.UserUpdatedData = true;
        }

        // Add a row to the original data model.
        this.arrGridRows.push(newRow);
        // Add a row to the Kendo-friendly data mode.
        let blankRow: object = {};
        blankRow[KENDA_DATA_GRID_ROW_KEY] = newRow;
        let colDefs: FormField[] = this.gridConfig.ColumnDefs;
        for (let index: number = 0; index < colDefs.length; index++) {
            let colDef: FormField = colDefs[index];
            blankRow[colDef.name] = ''; // Note:  this is not always correct as columns can have default values.
        }
        this.arrKendoGridRows.push(blankRow);            

        if (bNotifySubject)
            this.gridRowsSubject.next(this.arrGridRows);

        // In case we are managing column totals, call the 'addingRow' method.
        this.addingRow(newRow);

        return newRow;
    }

    public removeRow(IdToDelete: number): boolean {
        let iNumExistingRows: number = this.arrGridRows.length;

        let arrDeletedRows: GridRowDef[] =
            this.arrGridRows.filter(r => r.ClientId == IdToDelete);
        if ((!arrDeletedRows) || (arrDeletedRows.length != 1)) {
            return (false);
        }
        this.arrDeletedGridRows.push(arrDeletedRows[0]);

        // In case we are managing column totals, call the 'deletingRow' method.
        this.deletingRow(arrDeletedRows[0]);

        // Change the indexes of any subsequent rows to account for the deleted row.
        let iDeletedRowIndex: number = arrDeletedRows[0].RowIndex;
        if (iDeletedRowIndex + 1 < this.arrGridRows.length) {
            for (let iSubsequentRowIndex: number = iDeletedRowIndex + 1;
                iSubsequentRowIndex < this.arrGridRows.length;
                iSubsequentRowIndex++) {
                let subsequentRow: GridRowDef = this.arrGridRows[iSubsequentRowIndex];

                subsequentRow.RowIndex = subsequentRow.RowIndex - 1;
            }
        }

        this.arrGridRows = this.arrGridRows.filter(r => r.ClientId != IdToDelete);

        let bRemoved: boolean = (iNumExistingRows != this.arrGridRows.length);
        if (bRemoved) {
            this.gridRowsSubject.next(this.arrGridRows);
        }

        return bRemoved;
    }

    public copyDataToGrid(pasteData: string, replaceOrAppend: any, allComponents: ComponentAndFormField[], hasFixedFirstColumnJsonConfig: boolean, formField: FormField, fieldDefinitionService: FieldDefinitionService): void {
        if (!pasteData) {
            return;
        }
        let offset = 0;
        if (replaceOrAppend.pasteMode == 'append') {
            offset = this.GridRows.length;
        }
        else {
            this.GridRows.forEach(r => this.removeRow(r.ClientId));
        }

        let pasteDataLines: string[] = pasteData.split(/\r\n|\r|\n/);
        let pasteRowCount: number = pasteDataLines.length;
        let startingCol: number = 0;
        if (hasFixedFirstColumnJsonConfig && (formField.transientFixedFirstColumnValues != null)) {
            pasteRowCount = formField.transientFixedFirstColumnValues.values.length;
            offset = 0;
            startingCol = 1;
        }

        for (let row = 0; row < pasteRowCount; row++) {
            if (pasteDataLines[row].trim().length) {
                let pasteCells: string[] = pasteDataLines[row].split('\t');
                if (row + offset + 1 > this.GridRows.length) {
                    this.addRow();
                }

                for (let col = startingCol; col < pasteCells.length; col++) {
                    let formField: FormField = this.gridConfig.getColumnDef(col);
                    let elementWrapper: GridFormInstanceElementWrapper = this.GridRows[row + offset].FormInstanceElementWrappers[col];
                    let pasteValue: string = pasteCells[col];
                    let component: IFormFieldComponent = allComponents[col].Component;
                    component.pseudoStatic_pasteValue(pasteValue, elementWrapper, formField);

                    // Note:  the following block will replace the use of the method component.pseudoStatic_pasteValue(), just above,
                    //        and that will allow us to move this logic into the grid component data class/this.allModesDataSource.
                    //let fieldDefinition: FieldDefinition = this.fieldDefinitionService.getFieldDefinition(formField.fieldDefinitionClassName);
                    let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(formField.fieldDefinitionClassName);
                    let fieldDefLogic: IFieldDefinitionLogic = (fieldDefinition != null ? fieldDefinition.customLogicHandler : null);
                    /*
                    if (fieldDefLogic != null)
                        fieldDefLogic.pasteDataForInto(formField, elementWrapper.formInstanceElement, pasteValue);
                    */

                    elementWrapper.formInstanceElement.UserUpdatedData = true;
                }
            }
        }
    }

    // Load grid rows from the incoming view model data.
    public loadGridDataFromViewModelElements(fieldDefinitionService: FieldDefinitionService, gridRowVMs: GridRowViewModel[]): void {
        this.doLoadGridDataFromViewModelElements(fieldDefinitionService, gridRowVMs);
    }
    private doLoadGridDataFromViewModelElements(fieldDefinitionService: FieldDefinitionService, gridRowVMs: GridRowViewModel[]): void {
        if (gridRowVMs && (gridRowVMs.length > 0)) {
            for (let iRow: number = 0; iRow < gridRowVMs.length; iRow++) {
                let gridRowVM: GridRowViewModel = gridRowVMs[iRow];

                let nextRow: GridRowDef = this.addRow(gridRowVM.id, false, false); // Do not notify the grid on each row added.
                nextRow.RowIndex = iRow;
                // Notify the grid of all rows outside the for loop.
                let rowFIEWrappers: GridFormInstanceElementWrapper[] = nextRow.FormInstanceElementWrappers;
                // Keep track of form fields and form instance elements in case we have a form field, e.g. a formula form field, that needs all values.
                let rowFormFields: FormField[] = [];
                let rowFormInstanceElements: FormInstanceElement[] = [];

                for (let iFIEWrapper: number = 0; iFIEWrapper < rowFIEWrappers.length; iFIEWrapper++) {
                    let formInstanceElementWrapper: GridFormInstanceElementWrapper = rowFIEWrappers[iFIEWrapper];
                    let iColIndex: number = formInstanceElementWrapper.colIndex;
                    let columnDef: FormField = this.gridConfig.getColumnDef(iColIndex);

                    rowFormFields.push(columnDef);
                    rowFormInstanceElements.push(formInstanceElementWrapper.formInstanceElement);
                }

                // Now process each column in turn.
                for (let iFIEWrapper: number = 0; iFIEWrapper < rowFIEWrappers.length; iFIEWrapper++) {
                    let formInstanceElementWrapper: GridFormInstanceElementWrapper = rowFIEWrappers[iFIEWrapper];
                    let iColIndex: number = formInstanceElementWrapper.colIndex;
                    let columnDef: FormField = this.gridConfig.getColumnDef(iColIndex);
                    let strCellName: string = columnDef.name.toLowerCase();

                    let formInstanceElem: FormInstanceElement = formInstanceElementWrapper.formInstanceElement

                    formInstanceElem.transientValuesHash = {};
                    formInstanceElem.transientValuesHash[GRID_ROW_ID_KEY] = nextRow.ClientId

                    let formContentElement: IFormContentElement = gridRowVM.cellDataHash[strCellName];

                    //if (formInstanceElementWrapper) {
                    if (formContentElement != null) {
                        // Set up the wrapper's form instance element
                        // and configure its transient/client-only values.
                        formInstanceElem.UserUpdatedData = true;

                        // Set the form instance element values.
                        formInstanceElem.ValueType = formContentElement.valueType;
                        // TO DO:  ONLY PERFORM ONE OF THE FOLLOWING SET VALUES BASED ON formContentElement.valueType.
                        formInstanceElem.IntValue = formContentElement.intValue;
                        formInstanceElem.DoubleValue = formContentElement.doubleValue;
                        formInstanceElem.DecimalValue = formContentElement.decimalValue;
                        formInstanceElem.CustomValue = formContentElement.customValue;
                        formInstanceElem.TextValue = formContentElement.textValue;
                        formInstanceElem.BooleanValue = formContentElement.booleanValue;

                        // the following block supports multi-select fields
                        {
                            if ((formContentElement.childFormInstanceElements != null) && (formContentElement.childFormInstanceElements.length > 0)) {
                                formInstanceElem.childFormInstanceElements = [];

                                for (let iChild: number = 0; iChild < formContentElement.childFormInstanceElements.length; iChild++) {
                                    let contentElementChild: IFormContentElement = formContentElement.childFormInstanceElements[iChild];

                                    let childElement: FormInstanceElement = new FormInstanceElement();
                                    childElement.id = contentElementChild.id;
                                    childElement.ValueType = contentElementChild.valueType;
                                    childElement.IntValue = contentElementChild.intValue;
                                    childElement.DoubleValue = contentElementChild.doubleValue;
                                    childElement.DecimalValue = contentElementChild.decimalValue;
                                    childElement.CustomValue = contentElementChild.customValue;
                                    childElement.TextValue = contentElementChild.textValue;
                                    childElement.BooleanValue = contentElementChild.booleanValue;

                                    formInstanceElem.childFormInstanceElements.push(childElement);
                                }
                            }
                        }
                    } // if

                    // Do we have a form field that needs all field values, e.g. a formula form field?
                    let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName);
                    if (fieldDefinition.customLogicHandler.requiresFieldValueUpdate()) {
                        fieldDefinition.customLogicHandler.formFieldValuesUpdated(fieldDefinitionService, rowFormFields, rowFormInstanceElements, nextRow, columnDef, formInstanceElem);
                    }

                    //console.log(formInstanceElem);

                    // Now get the cell's display value.
                    formInstanceElementWrapper.standinDisplayValue =
                        this.igetDisplayValue.getCellDisplayValue(
                            iColIndex,
                            columnDef,
                            formInstanceElementWrapper,
                            nextRow,
                            FormFieldProcessingPhaseEnum.LOADING_DATA);
                } // for
            } // for

            this.gridRowsSubject.next(this.arrGridRows); // Notify the grid of all rows added.
        } // if

        return;
    }

    public static createGridRowFromGridViewModelObect(gridConfig: GridConfig, fieldDefinitionService: FieldDefinitionService, igetDisplayValue: IGetCellDisplayValue, gridRowVM: GridRowViewModel, iNextId: number, totalGridRows: number): GridRowDef {
        //let nextRow: GridRowDef = new GridRowDef(this.gridConfig, this.iNextId++, this.arrGridRows.length, gridRowVM.id);
        let nextRow: GridRowDef = new GridRowDef(gridConfig, iNextId++, totalGridRows, gridRowVM.id, gridRowVM.isPendingEditFor_GridRowId);

        let rowFIEWrappers: GridFormInstanceElementWrapper[] = nextRow.FormInstanceElementWrappers;
        // Keep track of form fields and form instance elements in case we have a form field, e.g. a formula form field, that needs all values.
        let rowFormFields: FormField[] = [];
        let rowFormInstanceElements: FormInstanceElement[] = [];

        for (let iFIEWrapper: number = 0; iFIEWrapper < rowFIEWrappers.length; iFIEWrapper++) {
            let formInstanceElementWrapper: GridFormInstanceElementWrapper = rowFIEWrappers[iFIEWrapper];
            let iColIndex: number = formInstanceElementWrapper.colIndex;
            let columnDef: FormField = gridConfig.getColumnDef(iColIndex);

            rowFormFields.push(columnDef);
            rowFormInstanceElements.push(formInstanceElementWrapper.formInstanceElement);
        }

        // Now process each column in turn.
        for (let iFIEWrapper: number = 0; iFIEWrapper < rowFIEWrappers.length; iFIEWrapper++) {
            let formInstanceElementWrapper: GridFormInstanceElementWrapper = rowFIEWrappers[iFIEWrapper];
            let iColIndex: number = formInstanceElementWrapper.colIndex;
            let columnDef: FormField = gridConfig.getColumnDef(iColIndex);
            let strCellName: string = columnDef.name.toLowerCase();

            let formInstanceElem: FormInstanceElement = formInstanceElementWrapper.formInstanceElement

            formInstanceElem.transientValuesHash = {};
            formInstanceElem.transientValuesHash[GRID_ROW_ID_KEY] = nextRow.ClientId

            let formContentElement: IFormContentElement = gridRowVM.cellDataHash[strCellName];

            //if (formInstanceElementWrapper) {
            if (formContentElement != null) {
                // Set up the wrapper's form instance element
                // and configure its transient/client-only values.
                formInstanceElem.UserUpdatedData = true;

                // Set the form instance element values.
                formInstanceElem.ValueType = formContentElement.valueType;
                // TO DO:  ONLY PERFORM ONE OF THE FOLLOWING SET VALUES BASED ON formContentElement.valueType.
                formInstanceElem.IntValue = formContentElement.intValue;
                formInstanceElem.DoubleValue = formContentElement.doubleValue;
                formInstanceElem.DecimalValue = formContentElement.decimalValue;
                formInstanceElem.CustomValue = formContentElement.customValue;
                formInstanceElem.TextValue = formContentElement.textValue;
                formInstanceElem.BooleanValue = formContentElement.booleanValue;

                // 05-10-2021 note:  added the following block to support
                //                   multi - select fields as grid columns.
                {
                    if ((formContentElement.childFormInstanceElements != null) && (formContentElement.childFormInstanceElements.length > 0)) {
                        formInstanceElem.childFormInstanceElements = [];

                        for (let iChild: number = 0; iChild < formContentElement.childFormInstanceElements.length; iChild++) {
                            let contentElementChild: IFormContentElement = formContentElement.childFormInstanceElements[iChild];

                            let childElement: FormInstanceElement = new FormInstanceElement();
                            childElement.id = contentElementChild.id;
                            childElement.ValueType = contentElementChild.valueType;
                            childElement.IntValue = contentElementChild.intValue;
                            childElement.DoubleValue = contentElementChild.doubleValue;
                            childElement.DecimalValue = contentElementChild.decimalValue;
                            childElement.CustomValue = contentElementChild.customValue;
                            childElement.TextValue = contentElementChild.textValue;
                            childElement.BooleanValue = contentElementChild.booleanValue;

                            formInstanceElem.childFormInstanceElements.push(childElement);
                        }
                    }
                }
            } // if

            // Do we have a form field that needs all field values, e.g. a formula form field?
            let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName);
            if (fieldDefinition.customLogicHandler.requiresFieldValueUpdate()) {
                fieldDefinition.customLogicHandler.formFieldValuesUpdated(fieldDefinitionService, rowFormFields, rowFormInstanceElements, nextRow, columnDef, formInstanceElem);
            }

            // Now get the cell's display value.
            formInstanceElementWrapper.standinDisplayValue = igetDisplayValue.getCellDisplayValue(iColIndex, columnDef, formInstanceElementWrapper, nextRow, FormFieldProcessingPhaseEnum.LOADING_DATA);
        } // for

        return nextRow;
    }
    public get GridRowsAsJson(): object[] {
        return this.arrKendoGridRows;
    }

    public saveGridDataToViewModelElements(): GridRowViewModel[] {
        // Package the grid cell data as view model data structures.
        let arrColDefs: FormField[] = this.gridConfig.ColumnDefs;

        let arrAllRowsData: GridRowViewModel[] = [];

        // Process deleted rows.
        {
            for (let iDeletedRow: number = 0; iDeletedRow < this.arrDeletedGridRows.length; iDeletedRow++) {
                let deletedRow: GridRowDef = this.arrDeletedGridRows[iDeletedRow];

                let hshRowCellData: IRowCellDataHash = {};
                this.loadRowCellDataHash(arrColDefs, deletedRow, hshRowCellData);

                let gridRowVM: GridRowViewModel = new GridRowViewModel();
                gridRowVM.rowIndex = iDeletedRow;
                gridRowVM.id = deletedRow.DatabaseId;
                gridRowVM.cellDataHash = hshRowCellData;
                gridRowVM.isDeleted = true;

                arrAllRowsData.push(gridRowVM);
            }
        }

        // Process new and updated rows.
        {
            for (let iRow: number = 0; iRow < this.arrGridRows.length; iRow++) {
                let row: GridRowDef = this.arrGridRows[iRow];

                let hshRowCellData: IRowCellDataHash = {};
                this.loadRowCellDataHash(arrColDefs, row, hshRowCellData);

                let gridRowVM: GridRowViewModel = new GridRowViewModel();
                gridRowVM.rowIndex = iRow;
                gridRowVM.id = row.DatabaseId;
                gridRowVM.cellDataHash = hshRowCellData;
                arrAllRowsData.push(gridRowVM);
            }
        }

        // Save all of the JSON as a string.
        return (arrAllRowsData);
    }

    // Sorting.
    public sortRowsByColumn(formField: FormField, fieldLogic: IFieldDefinitionLogic, sortAscending: boolean): void {
        let gridRowSorter: SortGridRowsByFormField = new SortGridRowsByFormField(formField, fieldLogic, sortAscending);

        this.arrGridRows.sort(gridRowSorter.compareGridRows);
        this.gridRowsSubject.next(this.arrGridRows);
    }

    public sortRowsByRowId(): void {
        // Note:  this method assumes an descending sort.
        let gridRowSorter: SortGridRowsByRowId = new SortGridRowsByRowId();

        this.arrGridRows.sort(gridRowSorter.compareGridRows);
        this.gridRowsSubject.next(this.arrGridRows);
    }

    /*
    private compareGridRows(gridRow1: GridRowDef, gridRow2: GridRowDef): number {
        let cellValue1: GridFormInstanceElementWrapper = gridRow1.getFormInstanceElementWrapper(this.sortField);
        let cellValue2: GridFormInstanceElementWrapper = gridRow2.getFormInstanceElementWrapper(this.sortField);

        let compareResult: number = (this.sortAscending ? this.fieldLogic.compareAscendingFor(this.sortField, cellValue1.formInstanceElement, cellValue2.formInstanceElement) : this.fieldLogic.compareDescendingFor(this.sortField, cellValue1.formInstanceElement, cellValue2.formInstanceElement));
        return compareResult;
    }
    */

    // Column totals logic.
    public trackColumnTotals(numericComponentPairs: ComponentAndFormField[]): void {
        if (numericComponentPairs.length > 0) {
            this.hshTotalInfoByColClientId = {};

            for (let iComp: number = 0; iComp < numericComponentPairs.length; iComp++) {
                let numericComponent: IFormFieldComponent = numericComponentPairs[iComp].Component;
                let compColDef: FormField = numericComponentPairs[iComp].FormField;
                let iColClientId = compColDef.gridColClientId;

                // Create and save the numeric component's total info.
                let compTotalInfo: NumericColumnTotalInfo = new NumericColumnTotalInfo();
                compTotalInfo.numericComponent = numericComponent;
                compTotalInfo.columnDef = compColDef;
                compTotalInfo.columnCellData = [];
                compTotalInfo.columnCellTotal = 0;

                this.hshTotalInfoByColClientId[iColClientId] = compTotalInfo;

                // Load the component's column data.
                {
                    if (this.arrGridRows && (this.arrGridRows.length > 0)) {
                        let dblColumnTotal: number = 0;

                        for (let iRow: number = 0; iRow < this.arrGridRows.length; iRow++) {
                            let gridRow: GridRowDef = this.arrGridRows[iRow];

                            let fieWrapper: GridFormInstanceElementWrapper =
                                gridRow.getFormInstanceElementWrapper(compColDef);

                            if (fieWrapper) {
                                let cellDataWrapper: NumericFormInstanceElementWrapper =
                                    new NumericFormInstanceElementWrapper();
                                cellDataWrapper.gridRowDef = gridRow;
                                cellDataWrapper.formInstanceElementWrapper = fieWrapper;
                                cellDataWrapper.previousNumericValue =
                                    numericComponent.getNumericValue(compColDef,
                                        fieWrapper.formInstanceElement,
                                        gridRow,
                                        FormFieldProcessingPhaseEnum.CALCULATING_COLUMN_TOTAL);
                                compTotalInfo.columnCellData.push(cellDataWrapper);

                                dblColumnTotal += cellDataWrapper.previousNumericValue;
                            }
                        } // for

                        compTotalInfo.columnCellTotal = dblColumnTotal;
                    } // if
                } // block
            } // for 
        } // if

        return;
    }

    public getColumnTotal(columnDef: FormField): number {
        let total: number = 0;

        if (this.hshTotalInfoByColClientId !== null) {
            let totalInfo: NumericColumnTotalInfo =
                this.hshTotalInfoByColClientId[columnDef.gridColClientId];

            if (totalInfo) {
                total = totalInfo.columnCellTotal;
            }
        }

        return (total);
    }

    public cellValueChanged(gridRowDef: GridRowDef, columnDef: FormField, fieldLogicHandler: IFieldDefinitionLogic, newValue: number): void {
        if (this.hshTotalInfoByColClientId !== null) {
            let totalInfo: NumericColumnTotalInfo = this.hshTotalInfoByColClientId[columnDef.gridColClientId];

            if ((totalInfo != null) && (totalInfo.columnCellData != null)) {
                let iRowIndex: number = gridRowDef.RowIndex;

                if ((iRowIndex >= 0) && (iRowIndex < totalInfo.columnCellData.length)) {
                    let cellWrapper: NumericFormInstanceElementWrapper = totalInfo.columnCellData[iRowIndex];

                    let changeAmount: number = (newValue - cellWrapper.previousNumericValue);
                    cellWrapper.previousNumericValue = newValue;
                    totalInfo.columnCellTotal += changeAmount;
                }
            }
        }

        return;
    }

    private addingRow(gridRow: GridRowDef): void {
        if (this.hshTotalInfoByColClientId !== null) {
            // Note:  incorporate the new row, the grid's
            //        last row(addRow() always appends a row),
            //        into the column totals logic.
            let astrClientIds: string[] = Object.keys(this.hshTotalInfoByColClientId);

            for (let iKeyIndex: number = 0; iKeyIndex < astrClientIds.length; iKeyIndex++) {
                let strClientId: string = astrClientIds[iKeyIndex];

                let totalInfo: NumericColumnTotalInfo =
                    this.hshTotalInfoByColClientId[strClientId];

                let gridCellWrapper: GridFormInstanceElementWrapper =
                    gridRow.getFormInstanceElementWrapper(totalInfo.columnDef);

                if (gridCellWrapper) {
                    let totalCellWrapper: NumericFormInstanceElementWrapper =
                        new NumericFormInstanceElementWrapper();

                    {
                        totalCellWrapper.formInstanceElementWrapper = gridCellWrapper;
                        totalCellWrapper.gridRowDef = gridRow;

                        totalCellWrapper.previousNumericValue =
                            totalInfo.numericComponent.getNumericValue(totalInfo.columnDef,
                                gridCellWrapper.formInstanceElement,
                                gridRow,
                                FormFieldProcessingPhaseEnum.LOADING_DATA);

                        totalInfo.columnCellData.push(totalCellWrapper);
                    } // block

                    totalInfo.columnCellTotal += totalCellWrapper.previousNumericValue;
                } // if
            } // for
        } // if

        return;
    }

    private deletingRow(gridRowParam: GridRowDef): void {
        if (this.hshTotalInfoByColClientId !== null) {
            // Note:  remove the row being deleted
            //        from column totals logic.
            let astrClientIds: string[] = Object.keys(this.hshTotalInfoByColClientId);

            for (let iKeyIndex: number = 0; iKeyIndex < astrClientIds.length; iKeyIndex++) {
                let strClientId: string = astrClientIds[iKeyIndex];

                let totalInfo: NumericColumnTotalInfo =
                    this.hshTotalInfoByColClientId[strClientId];

                if (totalInfo && totalInfo.columnCellData) {
                    let arrCellBeingDeleted: NumericFormInstanceElementWrapper[] =
                        totalInfo.columnCellData.filter(ccd => ccd.gridRowDef.RowIndex === gridRowParam.RowIndex);

                    if (arrCellBeingDeleted && (arrCellBeingDeleted.length === 1)) {
                        totalInfo.columnCellTotal -= arrCellBeingDeleted[0].previousNumericValue;

                        totalInfo.columnCellData =
                            totalInfo.columnCellData.filter(ccd => ccd.gridRowDef.RowIndex !== gridRowParam.RowIndex);
                    } // if
                } // if
            } // for
        } // if

        return;
    }
    // End methods related to column totals logic.

    // Define/implement private methods.
    private loadRowCellDataHash(arrColDefs: FormField[],
        row: GridRowDef,
        hshRowCellData: IRowCellDataHash): void {
        let arrFormInstanceElementWrappers: GridFormInstanceElementWrapper[] = row.FormInstanceElementWrappers;

        //let arrRowCellData: GridCellDataForSerialization[] = [];
        //let hshRowCellData: IRowCellDataHash = {};

        //for (let iCol: number = 0; iCol < arrFormInstanceElements.length; iCol++) {
        for (let iCol: number = 0; iCol < arrFormInstanceElementWrappers.length; iCol++) {
            let colDef: FormField = arrColDefs[iCol];
            //let cellData: FormInstanceElement = arrFormInstanceElements[iCol];
            let cellDataWrapper: GridFormInstanceElementWrapper = arrFormInstanceElementWrappers[iCol];
            let cellData: FormInstanceElement = cellDataWrapper.formInstanceElement;

            let hshCellData: GridCellDataForSerialization = {
                id: cellData.id,

                valueType: cellData.valueType,

                intValue: cellData.intValue,
                doubleValue: cellData.doubleValue,
                decimalValue: cellData.decimalValue,
                customValue: cellData.customValue,
                textValue: cellData.textValue,
                booleanValue: cellData.booleanValue,

                childFormInstanceElements: cellData.childFormInstanceElements
            }

            let name = colDef.name; //.replace('_vff', '-vff');
            hshRowCellData[name] = hshCellData;
        }

        return;
    }
}
