import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    Input,
    ComponentFactoryResolver,
    ViewContainerRef,
    Type as AngularCoreType,
    ComponentRef,
    SimpleChanges,
    Output,
    EventEmitter
} from '@angular/core';
import {
    ControlValueAccessor,
    AsyncValidatorFn,
    UntypedFormGroup,
    UntypedFormControl,
    Validators,
    ValidatorFn,
    AbstractControl
    //ValidationErrors
} from '@angular/forms'; // Used for Reactive Forms


import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model'; //TEAMS-424

import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';
import { Logging } from '../../../logging';
import { FormModeEnum } from '../../../enums/form-mode.enum';
import { IFieldNameToFormField, IFormFieldComponent } from '../../../interfaces/iform-field-component';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
//import { decode } from 'punycode';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { FormFieldConfigurationSaveReason } from '../../../enums/form-field-save-config-reason.enum';
import { FieldDefinition } from '../../../models/form-builder/field-definition.model';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { FieldDefinitionService } from '../../../services/field-definition.service';
import { FormInstance } from '../../../models/site-content/form-instance.model';
import { CascadingDropDownFieldType, CheckboxFieldType, CurrencyFieldType, DecimalFieldType, DropDownFieldType, FlexibleSelectionFieldType, GridCascadingDropDownFieldType, HTMLLinkFieldType, IntegerFieldType, ParagraphFieldType, RadioButtonsFieldType, RichTextFieldType, ShortTextFieldType } from '../../../models/form-builder/form-field-types';
import { KendoGridBaseComponent } from '../kendo-grid-base/kendo-grid-base.component';

// Define data structures, enums used with Reactive Forms.
// Define a data structure that can be used for validation with Reactive Forms.
export class ValidationInfo {
    DISPLAY_NAME: string = null;
    IS_CUSTOM_REGULAR_EXPRESSION: boolean = false;
    REGULAR_EXPRESSION: RegExp = null; // pharvey - 1/31/2024 - changed type from string to RegExp - it was unreliable as string and Validators.pattern expects a RegEx
    ERROR_MESSAGE: string = null;
}

export enum ControlType {
    TEMPLATE = 'Template',
    REACTIVE_FORMS = 'Reactive Forms'
};

export class ValidationMessageInfo {
    type: string = null;
    message: string = null;
}
// End data structures, enums used with Reactive Forms.

// Define a field name to component hash/map type.
export interface IFieldNameToComponent {
    [fieldName: string]: FormFieldBaseComponent;
}

// Define a field name to form field hash/map type.
/*
export interface IFieldNameToFormField {
    [fieldName: string]: FormField;
}
*/
// Define an interface for providing display hints.
export interface IFormFieldDisplayHint {
    [hintName: string]: any;
}
// End display hint interface definition.

// ControlValueAccessor IMPORTANT
// allows FormField classes to implement support ngmodel on formfieldwrapper
// listen for model changes
// hand in hand

class OnFocusData {
    public registeringComponent: IFormFieldComponent = null;
    public fnOnFocus = (registeringComponent: IFormFieldComponent, componentWithFocus: IFormFieldComponent, focusEvent: FocusEvent) => { };
}

// Define internally used classes.
class FormFieldBaseCallbackFunctions {
    public fnPropagateChanges = (_ignored: any) => { }; // Initial, NOOP function.
    public fnOnTouched = () => { }; // Initial, NOOP function.
    //public fnOnFocus = (formFieldComponent: FormFieldBaseComponent, event: FocusEvent): void => { }; // Initial, NOOP function.
    //public fnOnFocus = (_ignored1: any, _ignored2: any) => { }; // Initial, NOOP function.
    public onFocus: OnFocusData = new OnFocusData();
}

class FormFieldBaseControlData {
    public formControl: UntypedFormControl = null; // This is used for form field components that use a single Reactive Form FormControl instance (most).
    public formGroup: UntypedFormGroup = null; // This will/would be used for form field components that use multiple Reactive Form FormControl instances (few if any).
    public arrValidationMessages: ValidationMessageInfo[] = [];
    public controlType: string = ControlType.REACTIVE_FORMS;
    public ngOnInitExecuted: boolean = false;
}
// class FormFieldBaseFlags {
//     public bSelectOptionsParsed: boolean = false;
//     public bViewInitialized: boolean = false;
//     public bSetFocusPending: boolean = false;
// }

class FormFieldBaseFlags {
    public bSelectOptionsParsed: boolean = false;
    public bViewInitialized: boolean = false;
    public bSetFocusPending: boolean = false;

    public writeValueForFormInstanceElementCalled: boolean = false;
    public formInstanceElementReceivedCalled: boolean = false;
}

@Component({
    selector: 'app-form-field-base',
    templateUrl: './form-field-base.component.html',
    styleUrls: ['./form-field-base.component.scss']
})
// Why is FormFieldBaseComponent extending KendoGridBaseComponent?
// This means that every FormFieldComponant "is a" KendoGridBaseComponent which doesn't sound right
// Would it be better to use composition to enable different uses of KendoGrid to share functionality rather than inheritance? Like, there could be a KendoGridHelper class or something
export abstract class FormFieldBaseComponent extends KendoGridBaseComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, IFormFieldComponent {
    // Inputs shared by all form fields.

    // New for VNEXT-772 (Dynamic Row Sizing in Grid Form Field)
    @Input() fieldIsInGrid: boolean;
    // New for VNEXT-1135 (flexible selection fields)
    @Input() gridRowId: number;
    @Input() gridColumnDefs: FormField[];

    @Input() formField: FormField = null;
    //@Input() mode: string = null;
    @Input() mode: string = FormModeEnum.DESIGN; // Merely a default.
    @Input() readOnly: boolean = false;
    @Input() displayHints: IFormFieldDisplayHint = {};
    @Input() revealValidationErrors: boolean = false;
    @Input() formInstance: FormInstance; // added 1/31/2023 for VNEXT-562
    @Input() useGridSpecificStyling: boolean = false;

    //VNEXT-811: KLW - Needed for emailing contacts
    @Input() formInstanceName: string = '';

    @Output() invalid = new EventEmitter();
    @Output() touched = new EventEmitter();
    @Output() displayError = new EventEmitter();
    @Output() formFieldValidated = new EventEmitter();
    //TEAMS-838: KLW - When the form control of a form field has been created, emit that its form control is ready to be used
    @Output() formControlCreated = new EventEmitter();

    //VNEXT-610: KLW - Name for the custom validator for white space
    public wsValidatorName = 'whitespace';
    //VNEXT-538: KLW - Implementing Autocomplete
    public rmacValidatorName = 'requireMatchAutoComplete';

    // Define the component's form instance element,
    // the structure that contains its value.     
    private formInstanceElement: FormInstanceElement = new FormInstanceElement();

    // Define a propagate changes property, initially a NOOP function
    // that Angular can replace with a different function to allow it
    // to propagate changes using its forms API, e.g. [(ngMode)] bindings.
    /*
    private fnPropagateChanges = (_ignored: any) => { }; // Initial, NOOP function.
    private fnOnTouched = () => { }; // Initial, NOOP function.
    private fnOnFocus = (event: FocusEvent) => { }; // Initial, NOOP function.
    */
    private callbackFunctions: FormFieldBaseCallbackFunctions = new FormFieldBaseCallbackFunctions();

    // Define properties used with Reactive Forms.
    /*
    private formControl: FormControl = null; // This is used for form field components that use a single Reactive Form FormControl instance (most).
    private formGroup: FormGroup = null; // This will/would be used for form field components that use multiple Reactive Form FormControl instances (few if any).
    private arrValidationMessages: ValidationMessageInfo[] = [];
    private controlType: string = ControlType.REACTIVE_FORMS;
    */
    protected controlData: FormFieldBaseControlData = new FormFieldBaseControlData();
    //protected formValidationFailed: boolean = false; // 01-20-2023:  added this property during troubleshooting.
    // End properties used with Reactive Forms.

    // Define properties used for selection list
    // components like drop-down lists, radio button groups.
    /*
    private bSelectOptionsParsed: boolean = false;
    //private arrSelectOptions: string[] = null; // 05-06-2022 note:  it appears that this property is not being used.
    // End properties used for selection list components.

    // Define properties related to view creation, focus.
    private bViewInitialized: boolean = false;
    private bSetFocusPending: boolean = false;
    */
    private flags: FormFieldBaseFlags = new FormFieldBaseFlags();
    // End properties related to view creation, focus.

    // Define a private component Id, useful in debugging.
    private static iNextComponentId: number = 1;
    private iComponentId: number;

    private textValueOnInitialSetup: string;

    protected fieldHasValidationError: boolean = false;

    // constructor.
    public constructor() {
        super();
        this.iComponentId = FormFieldBaseComponent.iNextComponentId++;

        return;
    }

    // Define abstract methods.
    public getFormField(): FormField {
        return (this.FormField);
    }
    public getFormInstanceElement(): FormInstanceElement {
        return (this.formInstanceElement);
    }

    public abstract getProperties(): any;
    protected getStandardPropertyNames(): string[] {
        let standardPropertyNames: string[] = [FormFieldPropertyEnum.NAME];

        return (standardPropertyNames);
    }

    public abstract getFormFieldClass(): AngularCoreType<any>;

    protected setRedBorderManually(): void {
        // currently noop, but this could emit an event if needed
        return;
    }

    // Note:  the following two methods related to field conditional logic.
    public formFieldUpdated(): void {
        // 03-14-2024 note:  added this method so it can be calld by the field conditional
        //                   logic to indicate that a component's form field has been updated.

        // This default implementation is a NOOP by design.
        // Update:  trying to use the following code to support grid column form fields as conditional logic targets.
        this.writeValueTriggered();
    }
    public isCompoundObjectComponent(): boolean {
        // Note:  this method is also used in apply conditional logic.
        return false;
    }
    public applyFilter(filterValue: string, filterOperator: string, childFieldName: string): void {
        // This default implementation is a NOOP by design.
    }
    public resetConditionalLogicSettings(): void {
        // Note:  this method is a NOOP by default.
    }
    public applyChildFieldAttributes(childFieldName: string, showChildField: boolean, childFieldIsReadOnly: boolean, childFieldIsRequired: boolean): void {
        // Note:  this method is a NOOP by default.
    }

    // When revealValidationErrors is set to true it forces display of errors even if the field is
    // not dirty or touched. It's set to true when saving a form that contains validation errors and causes
    // all validation messages to be revealed next to their respective fields
    // If this is false 
    public getValidationErrors(revealValidationErrors: boolean = false): string[] {
        let formField = this;

        if (formField?.FormControl == null) {
            return [];
        }

        let errorMessages: string[] = [];
        let errorFound = false;
        for (let validationMessage of formField.ValidationMessages) {
            var errors = formField.controlData.formControl.errors;

            if (errors) {
                Object.keys(errors).forEach(error => {
                    if (error == validationMessage.type) {
                        errorMessages.push(validationMessage.message);
                        errorFound = true;
                    }

                });
            }
        }
        this.fieldHasValidationError = errorFound;

        //if (formField.formControl?.dirty || formField.formControl?.touched || revealValidationErrors) {
        if (formField.controlData.formControl?.dirty || formField.controlData.formControl?.touched || revealValidationErrors) {
            return errorMessages;
        } else {
            return [];
        }
    }

    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): string {
        // NOTE:  this method is essentially a NOOP by design.
        return ("");
    }

    // Define additional methods that all
    // form field components implement.
    public hasNumericData(): boolean {
        return (false); // A default that applies to most form field classes.
    }
    public getNumericValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): number {
        return (0); // A default that applies to most form field classes.
    }
    /*
    public dependsOnFormField(formFieldComponent: IFormFieldComponent): boolean {
        return (false); // A default that applies to most form field classes.
    }
    */
    public hasCalculatedValue(): boolean {
        return (false); // A default that applies to most form field classes.
    }

    // 04-05-2022 note:  added the following method with default behavior.
    //
    //                   Please see notes in method setupTextControl() in
    //                   this file/class.
    public get substantivelyChangedLogicApplies(): boolean {
        return false;
    }

    public get FieldIsInGrid(): boolean {
        return this.fieldIsInGrid;
    }

    public get GridRowId(): number {
        return this.gridRowId;
    }

    public get GridColumnDefs(): FormField[] {
        return this.gridColumnDefs;
    }

    public get FieldHasValidationError() {
        return this.fieldHasValidationError;
    }

    public CheckFieldForValidation() {
        if (this.FormControl) {
            this.FormControl.markAsTouched();
            this.FormControl.updateValueAndValidity();
        }
    }

    public get HasInstructions(): boolean {
        return !!this.FormField.instructionsText?.length;
    }

    public setFocus(): void {
        if (!this.flags.bViewInitialized) {
            this.flags.bSetFocusPending = true;
        } else {
            this.doSetFocus();
        }

        return;
    }
    protected doSetFocus(): void { // NOTE:  WILL MAKE THIS METHOD ABSTRACT.
        return;
    }

    public pseudoStatic_pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper, formField: FormField): void {
        // Note:  this simplistic, default implementation does not adequately address the 
        //        needs of all form field classes as it is the responsibility of form field
        //        component classes to implement this either via a base class or directly.
        elementWrapper.formInstanceElement.TextValue = value;
        elementWrapper.standinDisplayValue = value;
        if (!elementWrapper.formInstanceElement.valueType) {
            elementWrapper.formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeText;
        }
    }

    protected generateUniqueId(rootText: string): string {
        let randomNumber: number = Math.floor(Math.random() * 100000);

        let uniqueId: string = `${rootText}-${randomNumber}`;

        return (uniqueId);
    }

    protected viewHasInitialized(): void {
        //if (this.bSetFocusPending) {
        if (this.flags.bSetFocusPending) {
            // Note:  found that setting the focus immediately was not working,
            //        so adding a tiny delay before calling setFocus().
            //this.doSetFocus();
            setTimeout(() => {
                this.doSetFocus();
            }, 0);

            //this.bSetFocusPending = false;
            this.flags.bSetFocusPending = false;
        }

        //this.bViewInitialized = true;
        this.flags.bViewInitialized = true;
    }

    // Life cycle methods.
    public ngOnInit(): void {
        this.controlData.ngOnInitExecuted = true;
    }

    public ngAfterViewInit(): void {
        // Call the viewHasInitialized() method.        
        this.viewHasInitialized();

        return;
    }

    public ngOnDestroy(): void {
        // TO DO:  free resources.

        return;
    }

    public ngOnChanges(changes: SimpleChanges) {
    }

    //TEAMS-424: KLW - Add default properties to all Form Fields
    public get DefaultProperties(): string[] {
        return [FormFieldPropertyEnum.HAS_FOOTNOTE];
    }

    // Define accessor methods.
    /*
    public get FormModeDesign(): string {
        return (FormModeEnum.DESIGN);
    }
    public get FormModePreview(): string {
        return (FormModeEnum.PREVIEW);
    }
    public get FormModeInstance(): string {
        return (FormModeEnum.INSTANCE);
    }
    */
    public get IsInDesignMode(): boolean {
        return (this.mode === FormModeEnum.DESIGN);
    }
    public get IsInPreviewMode(): boolean {
        return (this.mode === FormModeEnum.PREVIEW);
    }
    public get IsInInstanceMode(): boolean {
        return (this.mode === FormModeEnum.INSTANCE);
    }
    public get IsInPreviewOrInstanceMode(): boolean {
        return (
            (this.mode === FormModeEnum.PREVIEW) ||
            (this.mode === FormModeEnum.INSTANCE)
        );
    }

    public get ControlType(): string {
        //return (this.controlType);
        return this.controlData.controlType;
    }

    public get FormControl(): UntypedFormControl {
        //return (this.formControl);
        return this.controlData.formControl;
    }
    // pharv - 11/3/2021 - added in support of date form field validation
    public set FormControl(formControl: UntypedFormControl) {
        //this.formControl = formControl
        this.controlData.formControl = formControl
    }

    public get FormGroup(): UntypedFormGroup {
        return this.controlData.formGroup;
    }

    public get ValidationMessages(): ValidationMessageInfo[] {
        //return (this.arrValidationMessages);
        return this.controlData.arrValidationMessages;
    }

    // Note:  the following getter method is a default that
    // can be overridden, e.g.by LabelFormFieldComponent.
    public get CanBeReadOnly(): boolean {
        return true;
    }
    // End methods used with Reactive Forms.

    // HTML accessor methods.
    public get FormField(): FormField {
        return (this.formField);
    }
    public set FormField(value: FormField) {
        this.formField = value;
    }

    public get FieldDefinitionClassName(): string {
        if (this.FormField != null)
            return (this.FormField.fieldDefinitionClassName);
        else
            return '';
    }

    public get InternalName(): string {
        return this.FormField.name;
    }
    public get DisplayName(): string {
        return (this.FormField.displayName ? this.FormField.displayName : this.FormField.name);//'Display Name');
    }

    public get ReadOnly(): boolean {
        let bResult: boolean = false;

        //return (this.readOnly || this.formField.readOnly);
        if (this.readOnly === true) {
            bResult = true;
        } else if (this.FormField.readOnly === true) {
            bResult = true;
        }

        return (bResult);
    }
    public get UseGridSpecificStyling(): boolean {
        return this.useGridSpecificStyling;
    }

    public get PlaceholderText(): string {
        return (this.FormField.placeholderText ? this.FormField.placeholderText : '');
    }

    public get HasTooltipText(): boolean {
        let bHasTooltip: boolean =
            (this.FormField && this.FormField.toolTip && (this.FormField.toolTip.trim() != ''));

        return (bHasTooltip);
    }

    public get TooltipText(): string {
        return (this.FormField.toolTip ? this.FormField.toolTip : '');
    }

    public get DefaultValue(): string {
        return (this.FormField.defaultValue ? this.FormField.defaultValue : '');
    }

    public get ComponentHasInitialized(): boolean {
        return this.controlData.ngOnInitExecuted;
    }
    public get Mode(): string {
        return (this.mode);
    }

    public get FormInstanceElement(): FormInstanceElement {
        return (this.formInstanceElement);
    }

    public get FormInstance(): FormInstance {
        return this.formInstance;
    }

    public set FormInstanceElement(formInstanceElementParam: FormInstanceElement) {
        // Note:  this method is a NOOP by design.

        return;
    }

    public get DispayHints(): IFormFieldDisplayHint {
        return (this.displayHints);
    }

    public get ShowLabel(): boolean {
        let bShowLabel: boolean = true;

        if (this.displayHints) {
            if (this.displayHints["showLabel"] === false) {
                bShowLabel = false;
            }
        }

        return (bShowLabel);
    }

    /*
    static ParseSelectOptions(formField: FormField): string[] {
        let arrSelectOptions: string[] = [];

        if ((formField.selectOptions !== undefined) &&
            (formField.selectOptions !== null) &&
            (formField.selectOptions.trim() !== '')) {
            arrSelectOptions = formField.selectOptions.split('|');
        }

        return (arrSelectOptions);
    }
    */

    get SelectOptions(): string[] {
        let arrSelectOptions: string[] = [];

        //if (!this.bSelectOptionsParsed) {
        if (!this.flags.bSelectOptionsParsed) {
            /*
            if ((this.formField.selectOptions !== undefined) &&
                (this.formField.selectOptions !== null) &&
                (this.formField.selectOptions.trim() !== '')) {
                arrSelectOptions = this.formField.selectOptions.split('|');
            }
            */
            if (this.FormField) {
                arrSelectOptions = FormField.ParseSelectOptions(this.FormField);
            }
        }

        return (arrSelectOptions);
    }

    // Define helper methods available to derived classes.
    protected raiseException(errorMessage: string): void {
        // Note:  this method merely provides a single entry to report
        //        exceptions, a convenience when troubleshooting.
        throw (errorMessage);
    }

    // Control event handlers.
    public handleOnModelChange(): void {
        // Tell any listeners that my value has changed.
        this.formInstanceElement.UserUpdatedData = true;
        this.notifyValueChanged();
    }

    public handleOnBlur(): void {
        this.notifyControlTouched();
    }

    public handleValidationOnBlur(): void {
        this.emitValidation(this.FormControl.status);
    }

    //VNEXT-982: KLW - Fix validation on blur
    public blurValidation() {
        this.handleValidationOnBlur();
    }

    //VNEXT-538: KLW - Implementing Autocomplete
    public get DropDownOptionsTitle(): string {
        let title: string = this.formField.name;

        if (this.formField.displayName && (this.formField.displayName.trim() !== '')) {
            title = this.formField.displayName.trim();
        }

        return (title);
    }

    public get IsDesign() {
        return this.Mode === 'design';
    }

    public get AutocompleteEnabled() {
        return this.formField.autocomplete;
    }


    public handleOnFocus(eventData: FocusEvent): void {
        this.notifyControlReceivedFocus(eventData);
    }

    // Define methods that can be called by a parent
    // component, e.g.a form builder or a form renderer.
    public saveConfiguration(form: any, reasonForSave: FormFieldConfigurationSaveReason): void {
        // NOTE:  this implementation is a NOOP by design but 
        //        can be overridden to do work by a derived class.
        return;
    }
    public saveConfigurationCompleted(form: any): void {
        // NOTE:  this implementation is a NOOP by design but
        //        can be overridden to do work by a derived class.
        return;
    }

    // Note:  is the following method still needed?
    public saveData(formInstance: any): void {
        // NOTE:  this implementation is a NOOP by design but
        //        can be overridden to do work by a derived class.
        //if (this.formControl && this.formInstanceElement) {
        if (this.controlData.formControl && this.formInstanceElement) {
            // 02-22-2021 SWH note:  I do not believe that the code in the following
            //                       switch statement is needed as the various setupXxxFormControl()
            //                       methods have code to do this already as the user enters data.

            // 11-30-2022 note:  the following bad code should no longer be necessary
            //
            //                   Need to test before removing it.
            switch (this.formInstanceElement.valueType) {
                case FormInstanceElementValueTypeEnum.TypeText:
                    this.formInstanceElement.TextValue = this.controlData.formControl.value;//this.formControl.value;
                    break;

                case FormInstanceElementValueTypeEnum.TypeInteger:
                    this.formInstanceElement.IntValue = this.controlData.formControl.value;//this.formControl.value;
                    break;

                case FormInstanceElementValueTypeEnum.TypeDecimal:
                    this.formInstanceElement.DecimalValue = this.controlData.formControl.value;//this.formControl.value;
                    break;

                case FormInstanceElementValueTypeEnum.TypeDouble:
                    this.formInstanceElement.DoubleValue = this.controlData.formControl.value;//this.formControl.value;
                    break;

                case FormInstanceElementValueTypeEnum.TypeBoolean:
                    this.formInstanceElement.BooleanValue = this.controlData.formControl.value;//this.formControl.value;
                    break;

                case FormInstanceElementValueTypeEnum.TypeMultiBoolean:
                case FormInstanceElementValueTypeEnum.TypeMultiText:
                    break;

                default:
                    Logging.log("FormFieldBaseComponent.saveData():  cannot determine my form instance element value type.");
                    break;
            } // switch
        } // if

        return;
    }

    public saveDataTo(formInstanceElementParam: FormInstanceElement): void {
        // NOTE:  THIS METHOD IS A NOOP BY DESIGN.
    }

    //public saveCompleted(hshSavedComponentData: any): void {
    public saveCompleted(formInstance: any): void {
        // Note:  this method is a NOOP by design.
        //
        //        Derived classes can override this
        //        method to implement post-save logic.

        return;
    }

    // Define a method that a derived class can override
    // to do something when a property update occurs.
    //
    // Note:  the component needs to set the
    //        'propertyUpdateRequired' attribute when it
    //        emits the 'onInit' output.
    public propertyUpdated(formField: FormField, propertyName: string): void {
        // NOTE:  this implementation is a NOOP by design but 
        //        can be overridden to do work by a derived class.
        return;
    }

    // Define a method that allows a form field component
    // to learn the names of other form fields.
    //formFieldNamesRequired: true,
    public receiveFormFieldNames(formFieldNames: string[],
        //hshColNameToComponent: IFieldNameToComponent): void {
        hshColNameToFormField: IFieldNameToFormField): void {
        // NOTE:  this implementation is a NOOP by design but 
        //        can be overridden to do work by a derived class.
        return;
    }

    // Define a method that allows a form field component
    // to be notified of form field value changes.
    //
    //     formFieldValueUpdatesRequired: true
    //
    // Note:  the following method should return true if
    //        the value changed affected the receiver's value.
    public requiresFieldValueUpdate(): boolean { // Is this method still being used?  If not, we can remove it.
        return false; // A default.
    }
    public formFieldValueUpdated(iColIndex: number, formField: FormField, formInstanceElement: FormInstanceElement, gridRow: IGridRow): boolean {
        // NOTE:  this implementation is a NOOP by design but 
        //        can be overridden to do work by a derived class.
        return;
    }

    public formInstanceElementUpdated(formInstanceElement: FormInstanceElement): void {
        // Note:  this method is NOOP by design.
    }

    /*
    public displayDeleteIconInGrid(): boolean {
        return true; // A default.
    }
    */

    // Define a method used by the grid form field to create cell editing controls.
    public static staticCreateFormFieldDynamically(componentFactoryResolver: ComponentFactoryResolver,
        fieldDefinitionService: FieldDefinitionService,
        viewContainerRef: ViewContainerRef,
        formField: FormField,
        formFieldClass: AngularCoreType<any>,
        mode: string,
        formInstanceElement: FormInstanceElement,
        bReadOnly: boolean,
        parentComponentPrefix: string = null,
        useGridSpecificStyling: boolean = false): ComponentRef<FormFieldBaseComponent> {
        // Get the class's factory and create an instance.
        //
        // Note:  apparently ComponentFactoryResolver has been deprecated as of Angular 13:
        //
        //        https://stackoverflow.com/questions/70946038/replace-deprecated-angular-componentfactoryresolver-componentfactory
        //
        //        The above article contains some information on what has replaced ComponentFactoryResolver.
        const componentFactory = componentFactoryResolver.resolveComponentFactory(formFieldClass);
        let componentRef: ComponentRef<FormFieldBaseComponent> = viewContainerRef.createComponent(componentFactory);

        const formFieldInstance: FormFieldBaseComponent = componentRef.instance;

        let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(formField.fieldDefinitionClassName);
        //let parentComponentPrefix: string = Math.random().toString();
        fieldDefinition.customLogicHandler.setFieldSpecificValuesForDynamicallyCreatedField(formFieldInstance, formField, parentComponentPrefix);

        formFieldInstance.FormField = formField;
        formFieldInstance.mode = mode;
        formFieldInstance.readOnly = bReadOnly;
        formFieldInstance.useGridSpecificStyling = useGridSpecificStyling;

        formFieldInstance.writeValue(formInstanceElement);

        return (componentRef);
    }
    public createFormFieldDynamically(componentFactoryResolver: ComponentFactoryResolver,
        fieldDefinitionService: FieldDefinitionService,
        viewContainerRef: ViewContainerRef,
        formField: FormField,
        mode: string,
        formInstanceElement: FormInstanceElement,
        bReadOnly: boolean,
        parentComponentPrefix: string = null,
        useGridSpecificStyling: boolean = false): ComponentRef<FormFieldBaseComponent> {

        // Get my derived class's class.
        let formFieldClass: AngularCoreType<any> = this.getFormFieldClass();

        /*

        // Get the class's factory and create an instance.
        //
        // Note:  apparently ComponentFactoryResolver has been deprecated as of Angular 13:
        //
        //        https://stackoverflow.com/questions/70946038/replace-deprecated-angular-componentfactoryresolver-componentfactory
        //
        //        The above article contains some information on what has replaced ComponentFactoryResolver.
        const componentFactory = componentFactoryResolver.resolveComponentFactory(formFieldClass);
        let componentRef: ComponentRef<FormFieldBaseComponent> = viewContainerRef.createComponent(componentFactory);

        const formFieldInstance: FormFieldBaseComponent = componentRef.instance;

        let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(formField.fieldDefinitionClassName);
        //let parentComponentPrefix: string = Math.random().toString();
        fieldDefinition.customLogicHandler.setFieldSpecificValuesForDynamicallyCreatedField(formFieldInstance, formField, parentComponentPrefix);

        formFieldInstance.formField = formField;
        formFieldInstance.mode = mode;
        formFieldInstance.readOnly = bReadOnly;

        formFieldInstance.writeValue(formInstanceElement);

        return (componentRef);
        */
        let componentRef = FormFieldBaseComponent.staticCreateFormFieldDynamically(componentFactoryResolver, fieldDefinitionService, viewContainerRef, formField, formFieldClass, mode, formInstanceElement, bReadOnly, parentComponentPrefix, useGridSpecificStyling);
        return componentRef;
    }

    // Define a method used to get a derived class's class.
    /*
    public getFormFieldClass(): AngularCoreType<any> {
        throw ("FormFieldBaseComponent:  method getFormFieldClass() is not implemented.");

        return (null);
    }
    */
    //public abstract getFormFieldClass(): AngularCoreType<any>;

    // Define methods used with Reactive Forms.
    protected createFormControl(initialValue: any): UntypedFormControl {
        //this.formControl = null;
        this.controlData.formControl = null;
        let arrValidators: ValidatorFn[] = [];
        let arrAsyncValidators: AsyncValidatorFn[] = [];

        // Build an array of validators, if any.
        //this.buildValidatorFunctionsAndMessages(arrValidators, this.arrValidationMessages, arrAsyncValidators);
        this.buildValidatorFunctionsAndMessages(arrValidators, this.controlData.arrValidationMessages, arrAsyncValidators);

        // 02-25-2020 change:  pass a hash to the FormControl constructor rather than just a value.
        //
        // This change was made in accordance with the following warning message:
        //
        //       It looks like you're using the disabled attribute with a reactive form directive. If you set disabled to true
        //       when you set up this control in your component class, the disabled attribute will actually be set in the DOM for
        //       you. We recommend using this approach to avoid 'changed after checked' errors.
        //
        //   Example:
        //       form = new FormGroup({
        //           first: new FormControl({ value: 'Nancy', disabled: true }, Validators.required),
        //           last: new FormControl('Drew', Validators.required)
        //       });
        let hshControlProperties = {
            value: initialValue,
            disabled: this.ReadOnly
        };

        // Create the form control.
        //this.formControl = new FormControl(hshControlProperties, Validators.compose(arrValidators), Validators.composeAsync(arrAsyncValidators));
        this.controlData.formControl = new UntypedFormControl(hshControlProperties, Validators.compose(arrValidators), Validators.composeAsync(arrAsyncValidators));

        this.handleStatusChangesFor(this.controlData.formControl);
        this.controlData.formControl.updateValueAndValidity();

        //return (this.formControl);
        return this.controlData.formControl;
    }



    // IS THIS METHOD STILL CALLED?
    protected setupTextFormControl(absolutelyNeededDefault: string = null): void {
        if (this.FormControl === null) {
            //VNEXT-561: KLW - This is never called properly since the first call from FormInstanceElementReceived will always have this.FormInstanceElement.textValue as an empty value. The second
            //call will then populate the value for the form control.
            //Create my form control.
            //VNEXT-561: KLW - Account for this form field being in a Grid
            let strInitialValue: string = absolutelyNeededDefault;
            if ((this.FormInstanceElement.textValue !== undefined) &&
                (this.FormInstanceElement.textValue !== null)) {
                strInitialValue = this.FormInstanceElement.textValue;
            }

            //VNEXT-561: KLW - This is to account for the Rich Text field validation, which needs an initial value when the form control is first created. 
            this.createFormControl(strInitialValue);

            this.handleStatusChangesFor(this.FormControl);
            this.FormControl.updateValueAndValidity();

            // Subscribe to value changes.
            this.FormControl.valueChanges
                .subscribe(val => {
                    this.FormInstanceElement.TextValue = val;

                    //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
                    this.handleStatusChangesFor(this.FormControl);

                    // pharv 4/16/2024 - discovered that this is no longer being reached for Rich Text Fields
                    // pharv 1/3/2022 - introduced valueHasSubstantivelyChanged() check because in some cases the rich text editor just changes whitespace/line breaks but makes no
                    // substantive changes to the actual content. Without this check, user would get "unsaved changes" warning upon navigating away from FormInstance
                    // even if they had made no changes
                    if ((!this.substantivelyChangedLogicApplies) ||
                        this.valueHasSubstantivelyChanged(val, this.textValueOnInitialSetup)) {
                        if (!this.FormInstanceElement.transientInSetupFlag) {
                            this.FormInstanceElement.UserUpdatedData = true;
                            this.userEnteredValue();
                        }
                        this.notifyValueChanged();
                    }
                });
        } else { // if (this.FormGroup === null)
            // Update my form control's value.
            if ((this.FormInstanceElement.textValue !== undefined) &&
                (this.FormInstanceElement.textValue !== null)) {
                this.FormInstanceElement.transientInSetupFlag = true;

                this.FormControl.setValue(this.FormInstanceElement.textValue);
                this.textValueOnInitialSetup = this.FormInstanceElement.textValue;

                this.FormInstanceElement.transientInSetupFlag = false;
            }
        }

        // Set my form instance element's value type.
        if (this.FormInstanceElement) {
            this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeText;
        }

        return;
    }

    protected setupIntegerFormControl(): void {
        if (this.FormControl === null) {
            // Create my form group.
            let iInitialValue: number = 0;
            //if ((this.FormInstanceElement.intValue !== undefined) &&
            //    (this.FormInstanceElement.intValue !== null)) {
            //    iInitialValue = this.FormInstanceElement.intValue;
            //}

            //VNEXT-561: KLW - Remove default value
            this.createFormControl(iInitialValue);

            this.handleStatusChangesFor(this.FormControl);
            this.FormControl.updateValueAndValidity();

            // Subscribe to value changes.
            this.FormControl.valueChanges
                .subscribe(val => {
                    this.FormInstanceElement.IntValue = val;

                    //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
                    this.handleStatusChangesFor(this.FormControl);

                    if (!this.FormInstanceElement.transientInSetupFlag) {
                        // A user entered this value.
                        //this.FormInstanceElement.transientValueSetFlag = true;
                        this.FormInstanceElement.UserUpdatedData = true;

                        this.userEnteredValue();
                    }

                    this.notifyValueChanged();
                });
        } else { // if (this.FormGroup === null)
            // Update my form control's value.
            if ((this.FormInstanceElement.intValue !== undefined) &&
                (this.FormInstanceElement.intValue !== null)) {
                this.FormInstanceElement.transientInSetupFlag = true;

                this.FormControl.setValue(this.FormInstanceElement.intValue);

                this.FormInstanceElement.transientInSetupFlag = false;
            }
            else {
                this.FormControl.setValue(null);
            }
        }

        // Set my form instance element's value type.
        if (this.FormInstanceElement) {
            this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeInteger;
        }

        return;
    }

    protected setupDecimalFormControl(): void {
        if (this.FormControl === null) {
            // Create my form group.
            let decInitialValue: number = 0.00;
            if ((this.FormInstanceElement.decimalValue !== undefined) &&
                (this.FormInstanceElement.decimalValue !== null)) {
                decInitialValue = this.FormInstanceElement.decimalValue;
            }

            //VNEXT-561: KLW - Remove default value
            this.createFormControl(decInitialValue);

            this.handleStatusChangesFor(this.FormControl);
            this.FormControl.updateValueAndValidity();

            // Subscribe to value changes.
            this.FormControl.valueChanges
                .subscribe(val => {
                    this.FormInstanceElement.DecimalValue = val;

                    //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
                    this.handleStatusChangesFor(this.FormControl);

                    if (!this.FormInstanceElement.transientInSetupFlag) {
                        // A user entered this value.
                        //this.FormInstanceElement.transientValueSetFlag = true;
                        this.FormInstanceElement.UserUpdatedData = true;

                        this.userEnteredValue();
                    }

                    this.notifyValueChanged();
                });
        } else { // if (this.FormGroup === null)
            // Update my form control's value.
            if ((this.FormInstanceElement.decimalValue !== undefined) &&
                (this.FormInstanceElement.decimalValue !== null)) {
                this.FormInstanceElement.transientInSetupFlag = true;

                this.FormControl.setValue(this.FormInstanceElement.decimalValue);

                this.FormInstanceElement.transientInSetupFlag = false;
            }
        }

        // Set my form instance element's value type.
        if (this.FormInstanceElement) {
            this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeDecimal;
        }

        return;
    }

    protected setupBooleanFormControl(): void {
        if (this.FormControl === null) {
            // Create my form group.
            let bInitialValue: boolean = false;
            if ((this.FormInstanceElement.booleanValue !== undefined) &&
                (this.FormInstanceElement.booleanValue !== null)) {
                bInitialValue = this.FormInstanceElement.booleanValue;
            }

            this.createFormControl(bInitialValue);

            this.handleStatusChangesFor(this.FormControl);
            this.FormControl.updateValueAndValidity();

            // Subscribe to value changes.
            this.FormControl.valueChanges
                .subscribe(val => {
                    this.FormInstanceElement.BooleanValue = val;

                    //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
                    this.handleStatusChangesFor(this.FormControl);

                    if (!this.FormInstanceElement.transientInSetupFlag) {
                        // A user entered this value.
                        //this.FormInstanceElement.transientValueSetFlag = true;
                        this.FormInstanceElement.UserUpdatedData = true;

                        this.userEnteredValue();
                    }

                    this.notifyValueChanged();
                });
        } else { // if (this.FormGroup === null)
            // Update my form control's value.
            if ((this.FormInstanceElement.booleanValue !== undefined) &&
                (this.FormInstanceElement.booleanValue !== null)) {
                this.FormInstanceElement.transientInSetupFlag = true;

                this.FormControl.setValue(this.FormInstanceElement.booleanValue);

                this.FormInstanceElement.transientInSetupFlag = false;
            }
        }

        // Set my form instance element's value type.
        if (this.FormInstanceElement) {
            this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeBoolean;
        }

        return;
    }

    protected createFormGroup(strControlName: string, initialValue: any): UntypedFormGroup {
        //if (this.formGroup === null) {
        if (this.controlData.formGroup === null) {
            let formControl: UntypedFormControl = null;
            let arrValidators: ValidatorFn[] = [];

            // Build an array of validators, if any.
            this.buildValidatorFunctionsAndMessages(arrValidators, this.controlData.arrValidationMessages);//this.arrValidationMessages);

            // Create the form control.
            if (arrValidators.length > 0) {
                formControl = new UntypedFormControl(initialValue, Validators.compose(arrValidators));
            } else {
                formControl = new UntypedFormControl(initialValue);
            }

            //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
            this.controlData.formControl = formControl;

            // Create the form group.
            //this.formGroup = new FormGroup({});
            this.controlData.formGroup = new UntypedFormGroup({});
            //this.formGroup.addControl(strControlName, formControl);
            this.controlData.formGroup.addControl(strControlName, formControl);
        }

        //return (this.formGroup);
        return this.controlData.formGroup;
    }

    //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
    protected setupMultiSelectFormGroup(strControlName: string, arrayValues: any, bSubscribeToValueChanges: boolean = true): void {

        let formControl: UntypedFormControl = null;
        let arrValidators: ValidatorFn[] = [];

        // Build an array of validators, if any.
        this.buildValidatorFunctionsAndMessages(arrValidators, this.controlData.arrValidationMessages);//this.arrValidationMessages);

        // Create the form control.
        if (arrValidators.length > 0) {
            formControl = new UntypedFormControl(arrayValues, Validators.compose(arrValidators));
        } else {
            formControl = new UntypedFormControl(arrayValues);
        }

        //VNEXT-982: KLW - Fix validation on blur
        this.handleStatusChangesFor(formControl);
        // Create the form group. 
        this.controlData.formGroup = new UntypedFormGroup({});
        this.controlData.formGroup.addControl(strControlName, formControl);

        this.controlData.formControl = formControl;


        // Set my form instance element's value type.
        if (this.FormInstanceElement) {
            this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeMultiText;
        }

        return;
    }

    protected handleStatusChangesFor(localFormControl: UntypedFormControl): void {
        localFormControl.statusChanges.subscribe(statusChangeName => {
            if ((statusChangeName == FormFieldPropertyEnum.INVALID) || (statusChangeName == FormFieldPropertyEnum.VALID)) {
                if (this.mode != FormModeEnum.DESIGN) {
                    this.emitValidation(statusChangeName);
                }
            }
        });
    }

    //Kevin - this is needed for validation of a blank value
    //Kevin this is being triggered for RichTextField when it first loads, need to stop that
    private emitValidation(status: string) {
        this.controlData.formControl.markAsTouched();
        this.formFieldValidated.emit(status);
    }

    protected setupTextFormGroup(strControlName: string, bSubscribeToValueChanges: boolean = true): void {
        //if (this.formGroup === null) {
        if (this.controlData.formGroup === null) {
            // Create my form group.
            let strInitialValue: string = '';
            if ((this.FormInstanceElement.textValue !== undefined) &&
                (this.FormInstanceElement.textValue !== null)) {
                strInitialValue = this.FormInstanceElement.textValue;
            }

            this.createFormGroup(strControlName, strInitialValue);

            let localFormControl: UntypedFormControl = <UntypedFormControl>this.controlData.formGroup.get(strControlName);//this.formGroup.get(strControlName);

            this.handleStatusChangesFor(localFormControl);

            if (bSubscribeToValueChanges) {
                // Subscribe to value changes.
                localFormControl.valueChanges
                    .subscribe(val => {
                        this.FormInstanceElement.TextValue = val;

                        //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
                        this.handleStatusChangesFor(this.FormControl);

                        if (!this.FormInstanceElement.transientInSetupFlag) {
                            // A user entered this value.
                            //this.FormInstanceElement.transientValueSetFlag = true;
                            this.FormInstanceElement.UserUpdatedData = true;

                            this.userEnteredValue();
                        }

                        this.notifyValueChanged();
                    });
            }
        } else {
            // Update my control's value.
            let localFormControl: UntypedFormControl = <UntypedFormControl>this.controlData.formGroup.get(strControlName);//this.formGroup.get(strControlName);

            if ((this.FormInstanceElement.textValue !== undefined) &&
                (this.FormInstanceElement.textValue !== null)) {
                this.FormInstanceElement.transientInSetupFlag = true;

                localFormControl.setValue(this.FormInstanceElement.textValue);

                this.FormInstanceElement.transientInSetupFlag = false;
            }
        }

        // Set my form instance element's value type.
        if (this.FormInstanceElement) {
            this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeText;
        }

        return;
    }


    protected setDefaultTextValue(): void {
        if ((this.FormInstanceElement.textValue === undefined) ||
            (this.FormInstanceElement.textValue === null)) {
            let bValueSet: boolean = false;

            if ((this.FormField.defaultValue !== undefined) &&
                (this.FormField.defaultValue !== null)) {
                this.FormInstanceElement.TextValue = this.FormField.defaultValue;

                bValueSet = true;
            }

            if (!bValueSet) {
                if (this.FormField.required) {
                    this.FormInstanceElement.TextValue = '';
                } else {
                    this.FormInstanceElement.TextValue = null;
                }
            }
        }

        return;
    }

    public static staticSetDefaultTextSelectValue(formField: FormField, formInstanceElement: FormInstanceElement, selectOptions: string[]): void {
        if ((formInstanceElement.textValue === undefined) ||
            (formInstanceElement.textValue === null) ||
            (formInstanceElement.textValue.trim() === '')) {
            let bValueSet: boolean = false;
            let arrSelectOptions: string[] = selectOptions;

            if (formField && formField.defaultValue) {
                // Make sure the default value is a valid selection.
                let bValidOption: boolean = false;
                //arrSelectOptions = this.SelectOptions;

                for (let iOption: number = 0; iOption < arrSelectOptions.length; iOption++) {
                    let strOption: string = arrSelectOptions[iOption];

                    if (strOption === formField.defaultValue) {
                        bValidOption = true;

                        break;
                    }
                }

                if (bValidOption) {
                    formInstanceElement.TextValue = formField.defaultValue;

                    bValueSet = true;
                }
            } // if

            //if ((!bValueSet) && this.FormField.required && (arrSelectOptions.length > 0)) {
            if (!bValueSet) {
                if (formField && formField.required && (arrSelectOptions.length > 0)) {
                    formInstanceElement.TextValue = arrSelectOptions[0];
                } else {
                    formInstanceElement.TextValue = null;
                }
            } // if

            ////VNEXT-561: KLW - Remove default value
            //if (!bValueSet) {
            //    this.FormInstanceElement.TextValue = null;
            //} // if

        } // if

        return;
    }
    protected setDefaultTextSelectValue(): void {
        /*
        if ((this.FormInstanceElement.textValue === undefined) ||
            (this.FormInstanceElement.textValue === null) ||
            (this.FormInstanceElement.textValue.trim() === '')) {
            let bValueSet: boolean = false;
            let arrSelectOptions: string[] = this.SelectOptions;

            if (this.formField && this.formField.defaultValue) {
                // Make sure the default value is a valid selection.
                let bValidOption: boolean = false;
                //arrSelectOptions = this.SelectOptions;

                for (let iOption: number = 0; iOption < arrSelectOptions.length; iOption++) {
                    let strOption: string = arrSelectOptions[iOption];

                    if (strOption === this.FormField.defaultValue) {
                        bValidOption = true;

                        break;
                    }
                }

                if (bValidOption) {
                    this.FormInstanceElement.TextValue = this.FormField.defaultValue;

                    bValueSet = true;
                }
            } // if

            //if ((!bValueSet) && this.FormField.required && (arrSelectOptions.length > 0)) {
            if (!bValueSet) {
                if (this.formField && this.formField.required && (arrSelectOptions.length > 0)) {
                    this.FormInstanceElement.TextValue = arrSelectOptions[0];
                } else {
                    this.FormInstanceElement.TextValue = null;
                }
            } // if

            ////VNEXT-561: KLW - Remove default value
            //if (!bValueSet) {
            //    this.FormInstanceElement.TextValue = null;
            //} // if

        } // if

        return;
        */
        // Note:  all of the code in this method has been moved into the static method, just above, called here:
        FormFieldBaseComponent.staticSetDefaultTextSelectValue(this.FormField, this.formInstanceElement, this.SelectOptions);
    }

    public static staticSetDefaultBooleanValue(formField: FormField, formInstanceElement: FormInstanceElement): void {
        if ((formInstanceElement.booleanValue === undefined) ||
            (formInstanceElement.booleanValue === null) ||
            (formInstanceElement.booleanValue === false)) {
            if (formField) {
                if ((formField.defaultValue !== undefined) &&
                    (formField.defaultValue !== null)) {
                    if (formField.defaultValue.toLowerCase() === 'true') {
                        formInstanceElement.BooleanValue = true;
                    } else {
                        formInstanceElement.BooleanValue = false;
                    }
                }
            } else {
                formInstanceElement.BooleanValue = false;
            }
        }
    }
    protected setDefaultBooleanValue(): void {
        /*
        if ((this.FormInstanceElement.booleanValue === undefined) ||
            (this.FormInstanceElement.booleanValue === null) ||
            (this.FormInstanceElement.booleanValue === false)) {
            if (this.formField) {
                if ((this.FormField.defaultValue !== undefined) &&
                    (this.FormField.defaultValue !== null)) {
                    if (this.FormField.defaultValue.toLowerCase() === 'true') {
                        this.FormInstanceElement.BooleanValue = true;
                    } else {
                        this.FormInstanceElement.BooleanValue = false;
                    }
                }
            } else {
                this.FormInstanceElement.BooleanValue = false;
            }
        }

        return;
        */
        // Note:  all of the code in this method has been moved into the static method, just above, called here:
        FormFieldBaseComponent.staticSetDefaultBooleanValue(this.FormField, this.formInstanceElement);
    }

    protected setDefaultDecimalValue(): void {
        FormFieldBaseComponent.setDefaultDecimalValueFor(this.FormField, this.FormInstanceElement);

        return;
    }

    public static setDefaultDecimalValueFor(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement): void {
        // Check for a default value.
        let decDefaultValue: number = 0; //parseFloat(this.FormField.defaultValue);
        let bHasDefaultValue: boolean =
            ((formFieldParam.defaultValue !== undefined) &&
                (formFieldParam.defaultValue !== null) &&
                (formFieldParam.defaultValue.trim() !== ''));
        if (bHasDefaultValue) {
            decDefaultValue = parseFloat(formFieldParam.defaultValue);
        }

        // Do we need to set a value?
        if ((formInstanceElementParam.decimalValue === undefined) ||
            (formInstanceElementParam.decimalValue === null) ||
            (
                bHasDefaultValue &&
                (formInstanceElementParam.decimalValue === 0) &&
                (formInstanceElementParam.decimalValue !== decDefaultValue)
            )
        ) {
            let bValueSet: boolean = false;

            if (bHasDefaultValue) {
                if (!isNaN(decDefaultValue)) {
                    formInstanceElementParam.DecimalValue = decDefaultValue;

                    bValueSet = true;
                }
            }

            if (!bValueSet) {
                if (formFieldParam.required) {
                    formInstanceElementParam.DecimalValue = 0.0;
                } else {
                    formInstanceElementParam.DecimalValue = null;
                }
            }

            ////VNEXT-561: KLW - Remove default value
            //if (!bValueSet) {
            //    formInstanceElementParam.DecimalValue = null;
            //}

        }

        return;
    }

    protected setDefaultIntegerValue(): void {
        FormFieldBaseComponent.setDefaultIntegerValueFor(this.FormField, this.FormInstanceElement);

        return;
    }

    public static setDefaultIntegerValueFor(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement): void {
        // Check for a default value.

        let iDefaultValue: number = 0;
        let bHasDefaultValue: boolean =
            ((formFieldParam.defaultValue !== undefined) &&
                (formFieldParam.defaultValue !== null) &&
                (formFieldParam.defaultValue.trim() !== ''));
        if (bHasDefaultValue) {
            iDefaultValue = parseInt(formFieldParam.defaultValue);
        }

        // Do we need to set a value?
        if ((formInstanceElementParam.intValue === undefined) ||
            (formInstanceElementParam.intValue === null) ||
            (bHasDefaultValue && (formInstanceElementParam.intValue !== iDefaultValue))) {
            let bValueSet: boolean = false;

            if (bHasDefaultValue) {
                if (!isNaN(iDefaultValue)) {
                    formInstanceElementParam.IntValue = iDefaultValue;

                    bValueSet = true;
                }
            }

            if (!bValueSet) {
                formInstanceElementParam.IntValue = null;
            }
        }

        return;
    }

    // Define a method called when a user enters a value.
    protected userEnteredValue(): void {
        // currently noop, but this could emit an event if needed
        return;
    }

    protected formGroupCreatedCompleted() {
    }

    protected formControlCreatedCompleted() {
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    public writeValue(value: any) {
        // NOTE:  THIS METHOD IS NOT YET PROPERLY IMPLEMENTED.
        //        WE WILL NEED TO SET THE MODEL VALUE IN PREVIEW AND INSTANCE MODES.
        if ((value !== undefined) && (value !== null) && (value instanceof FormInstanceElement)) {

            this.formInstanceElement = value;

            if (!this.flags.writeValueForFormInstanceElementCalled) {
                this.flags.writeValueForFormInstanceElementCalled = true;

                setTimeout(() => {
                    if (!this.flags.formInstanceElementReceivedCalled) {
                        this.writeValueTriggered();
                        this.flags.formInstanceElementReceivedCalled = true;
                    }
                }, 0);
            } else {
                this.writeValueTriggered();
                this.flags.formInstanceElementReceivedCalled = true;
            }
        }
    }

    // public SetupFormGroupFromWriteValue(groupName: string, passedFormControls: string[] = [], subscribeToEvents: boolean = true) {

    //     if (this.controlData.formGroup === null) {
    //         this.setupFormGroupBase(this.formInstanceElement, groupName, passedFormControls).then(() => {
    //             console.log("Form Field Base: Form Group of name " + groupName + " created");
    //             this.formGroupCreatedCompleted();
    //         });
    //     }
    //     //This is to account for loading the value for the form field in the grid
    //     else {
    //         this.setFormFieldValueInGrid();
    //     }
    // }

    // public SetupFormControlFromWriteValue() {
    //     if (this.FormControl == null) {
    //         this.setupFormControlBase(this.formInstanceElement).then(() => {
    //             //console.log("Form Field Base: Form Control of type " + this.FieldDefinitionClassName + " created");
    //             this.formControlCreatedCompleted();
    //             this.formControlCreated.emit(this.FormControl);
    //         });
    //     }
    //     //This is to account for loading the value for the form field in the grid
    //     else {
    //         this.setFormFieldValueInGrid();
    //     }

    //     if (this.FormControl?.valid == false) {
    //         this.invalid.emit();
    //     }
    // }


    // private setupFormGroupBase(passedFormInstanceElement, strControlName: string, passedFormControlNames: string[], subscribeToEvents: boolean = true): Promise<FormGroup> {
    //     return new Promise<FormGroup>((resolve) => {
    //         this.controlData.formGroup = new FormGroup({});

    //         if (passedFormControlNames.length > 0) {
    //             /*
    //             passedFormControlNames.forEach(control => {
    //                 let toAdd = new FormControl();
    //                 this.controlData.formGroup.addControl(strControlName, toAdd);
    //             });
    //             */
    //             for (let index: number = 0; index < passedFormControlNames.length; index++) {
    //                 let secondaryControlName: string = passedFormControlNames[index];
    //                 let toAdd = new FormControl();
    //                 this.controlData.formGroup.addControl(secondaryControlName, toAdd);
    //             }
    //         }

    //         this.createFormControlBase(passedFormInstanceElement).then((fc) => {

    //             if (subscribeToEvents)
    //                 this.formControlSubscriptions(fc);

    //             //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
    //             this.controlData.formControl = fc;
    //             this.controlData.formGroup.addControl(strControlName, fc);
    //             this.formGroupCreatedCompleted();
    //             //VNEXT-906: KLW - This is required for form fields using form group to have validation on the Kendo Grid
    //             this.formControlCreated.emit(this.FormControl);

    //             //VNEXT-561: KLW - Needs to go here for timing
    //             resolve(this.controlData.formGroup);
    //         });
    //     });
    // }


    // private setupFormControlBase(passedFormInstanceElement): Promise<void> {
    //     return new Promise<void>((resolve) => {
    //         this.createFormControlBase(passedFormInstanceElement).then((fc) => {
    //             this.formControlSubscriptions(fc);
    //         });

    //         resolve();
    //     });

    // }


    // protected createFormControlBase(passedFormInstanceElement: any): Promise<FormControl> {
    //     return new Promise<FormControl>((resolve) => {
    //         this.controlData.formControl = null;
    //         var initialValue: any = null;
    //         var arrValidators: ValidatorFn[] = [];
    //         var arrAsyncValidators: AsyncValidatorFn[] = [];

    //         // Build an array of validators, if any.
    //         //this.buildValidatorFunctionsAndMessages(arrValidators, this.arrValidationMessages, arrAsyncValidators);
    //         this.buildValidatorFunctionsAndMessages(arrValidators, this.controlData.arrValidationMessages, arrAsyncValidators);

    //         initialValue = this.returnSavedValue(passedFormInstanceElement);

    //         //Set default value if null
    //         if (initialValue == null)
    //             initialValue = this.GetDefaultValue(this.FormField);

    //         // 02-25-2020 change:  pass a hash to the FormControl constructor rather than just a value.
    //         //
    //         // This change was made in accordance with the following warning message:
    //         //
    //         //       It looks like you're using the disabled attribute with a reactive form directive. If you set disabled to true
    //         //       when you set up this control in your component class, the disabled attribute will actually be set in the DOM for
    //         //       you. We recommend using this approach to avoid 'changed after checked' errors.
    //         //
    //         //   Example:
    //         //       form = new FormGroup({
    //         //           first: new FormControl({ value: 'Nancy', disabled: true }, Validators.required),
    //         //           last: new FormControl('Drew', Validators.required)
    //         //       });
    //         let hshControlProperties = {
    //             value: initialValue,
    //             disabled: this.ReadOnly
    //         };

    //         // Create the form control. 
    //         this.FormControl = new FormControl(hshControlProperties, Validators.compose(arrValidators), Validators.composeAsync(arrAsyncValidators));

    //         //Don't enable this, it will cause the validation to happen when the form first loads
    //         //this.FormControl.updateValueAndValidity();

    //         this.setValueType();

    //         resolve(this.FormControl);
    //     });

    // }



    public SetupFormGroupFromWriteValue(groupName: string, passedFormControls: string[] = [], subscribeToEvents: boolean = true) {

        if (this.controlData.formGroup === null) {
            this.setupFormGroupBase(this.formInstanceElement, groupName, passedFormControls);
            //console.log("Form Field Base: Form Group of name " + groupName + " created");
            this.formGroupCreatedCompleted();
        }
        //This is to account for loading the value for the form field in the grid
        else {
            this.setFormFieldValueInGrid();
        }
    }

    public SetupFormControlFromWriteValue() {
        if (this.FormControl == null) {
            this.setupFormControlBase(this.formInstanceElement)
            //console.log("Form Field Base: Form Control of type " + this.FieldDefinitionClassName + " created");
            this.formControlCreatedCompleted();
            this.formControlCreated.emit(this.FormControl);
        }
        //This is to account for loading the value for the form field in the grid
        else {
            this.setFormFieldValueInGrid();
        }

        if (this.FormControl?.valid == false) {
            this.invalid.emit();
        }
    }


    private setupFormGroupBase(passedFormInstanceElement, strControlName: string, passedFormControlNames: string[], subscribeToEvents: boolean = true) {

        this.controlData.formGroup = new UntypedFormGroup({});

        if (passedFormControlNames.length > 0) {
            /*
            passedFormControlNames.forEach(control => {
                let toAdd = new FormControl();
                this.controlData.formGroup.addControl(strControlName, toAdd);
            });
            */
            for (let index: number = 0; index < passedFormControlNames.length; index++) {
                let secondaryControlName: string = passedFormControlNames[index];
                let toAdd = new UntypedFormControl();
                this.controlData.formGroup.addControl(secondaryControlName, toAdd);
            }
        }

        var fc = this.createFormControlBase(passedFormInstanceElement)

        if (subscribeToEvents)
            this.formControlSubscriptions(fc);

        //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
        this.controlData.formControl = fc;
        this.controlData.formGroup.addControl(strControlName, fc);
        this.formGroupCreatedCompleted();
        //VNEXT-906: KLW - This is required for form fields using form group to have validation on the Kendo Grid
        this.formControlCreated.emit(this.FormControl);




    }


    private setupFormControlBase(passedFormInstanceElement) {

        var fc = this.createFormControlBase(passedFormInstanceElement)
        this.formControlSubscriptions(fc);

    }


    protected createFormControlBase(passedFormInstanceElement: any) {

        this.controlData.formControl = null;
        var initialValue: any = null;
        var arrValidators: ValidatorFn[] = [];
        var arrAsyncValidators: AsyncValidatorFn[] = [];

        // Build an array of validators, if any.
        //this.buildValidatorFunctionsAndMessages(arrValidators, this.arrValidationMessages, arrAsyncValidators);
        this.buildValidatorFunctionsAndMessages(arrValidators, this.controlData.arrValidationMessages, arrAsyncValidators);

        initialValue = this.returnSavedValue(passedFormInstanceElement);

        //Set default value if null
        if (initialValue == null)
            initialValue = this.GetDefaultValue(this.FormField, passedFormInstanceElement);

        // 02-25-2020 change:  pass a hash to the FormControl constructor rather than just a value.
        //
        // This change was made in accordance with the following warning message:
        //
        //       It looks like you're using the disabled attribute with a reactive form directive. If you set disabled to true
        //       when you set up this control in your component class, the disabled attribute will actually be set in the DOM for
        //       you. We recommend using this approach to avoid 'changed after checked' errors.
        //
        //   Example:
        //       form = new FormGroup({
        //           first: new FormControl({ value: 'Nancy', disabled: true }, Validators.required),
        //           last: new FormControl('Drew', Validators.required)
        //       });
        let hshControlProperties = {
            value: initialValue,
            disabled: this.ReadOnly
        };

        // Create the form control.
        this.FormControl = new UntypedFormControl(hshControlProperties, Validators.compose(arrValidators), Validators.composeAsync(arrAsyncValidators));

        //Don't enable this, it will cause the validation to happen when the form first loads
        //this.FormControl.updateValueAndValidity();

        this.setValueType();

        return this.FormControl;

    }



    private setFormFieldValueInGrid() {
        switch (this.FieldDefinitionClassName) {
            case IntegerFieldType:
                if ((this.FormInstanceElement.intValue !== undefined) &&
                    (this.FormInstanceElement.intValue !== null)) {
                    this.FormInstanceElement.transientInSetupFlag = true;

                    this.FormControl.setValue(this.FormInstanceElement.intValue);

                    this.FormInstanceElement.transientInSetupFlag = false;
                }
                break;

            case CurrencyFieldType:
            case DecimalFieldType:
                if ((this.FormInstanceElement.decimalValue !== undefined) &&
                    (this.FormInstanceElement.decimalValue !== null)) {
                    this.FormInstanceElement.transientInSetupFlag = true;

                    this.FormControl.setValue(this.FormInstanceElement.decimalValue);

                    this.FormInstanceElement.transientInSetupFlag = false;
                }
                break;

            case RichTextFieldType:
            case ShortTextFieldType:
            case RadioButtonsFieldType:
            case DropDownFieldType:
            case ParagraphFieldType:
            case CascadingDropDownFieldType:
            case GridCascadingDropDownFieldType:
            case HTMLLinkFieldType: //TEAMS-835: KLW - Needed for the new HTML Link field
                if ((this.FormInstanceElement.textValue !== undefined) &&
                    (this.FormInstanceElement.textValue !== null)) {
                    this.FormInstanceElement.transientInSetupFlag = true;

                    this.FormControl.setValue(this.FormInstanceElement.textValue);
                    this.textValueOnInitialSetup = this.FormInstanceElement.textValue;

                    this.FormInstanceElement.transientInSetupFlag = false;
                }
                break;

            case CheckboxFieldType:
                if ((this.FormInstanceElement.booleanValue !== undefined) &&
                    (this.FormInstanceElement.booleanValue !== null)) {
                    this.FormInstanceElement.transientInSetupFlag = true;

                    this.FormControl.setValue(this.FormInstanceElement.booleanValue);

                    this.FormInstanceElement.transientInSetupFlag = false;
                }
                break;
        }

        if (this.FormField != null) {
            if (this.FormField.readOnly)
                this.FormControl.disable();
            else
                this.FormControl.enable();

            this.FormControl.clearValidators();
            if (this.FormField.required)
                this.FormControl.addValidators(Validators.required);
        }
    }

    // private formControlSubscriptions(passedFC: FormControl) {
    //     passedFC.valueChanges
    //         .subscribe(val => {
    //             switch (this.FieldDefinitionClassName) {

    //                 case CheckboxFieldType:
    //                     this.formInstanceElement.BooleanValue = val;
    //                     break;

    //                 case IntegerFieldType:
    //                     this.FormInstanceElement.IntValue = val;
    //                     break;

    //                 case CurrencyFieldType:
    //                 case DecimalFieldType:
    //                     this.FormInstanceElement.DecimalValue = val;
    //                     break;

    //                 case RichTextFieldType:
    //                 case ShortTextFieldType:
    //                 case RadioButtonsFieldType:
    //                 case DropDownFieldType:
    //                 case ParagraphFieldType:
    //                     this.formInstanceElement.TextValue = val;
    //                     break;
    //             }

    //             if (!this.FormInstanceElement.transientInSetupFlag) {
    //                 this.FormInstanceElement.UserUpdatedData = true;
    //             }

    //             this.notifyValueChanged();
    //             this.setRedBorderManually();

    //         });


    //     //Needed for validation
    //     this.handleStatusChangesFor(passedFC);

    //     this.FormInstanceElement.transientInSetupFlag = false;
    // }

    private formControlSubscriptions(passedFC: UntypedFormControl) {
        this.textValueOnInitialSetup = this.FormInstanceElement.textValue;
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            passedFC.valueChanges
                .subscribe(val => {
                    switch (this.FieldDefinitionClassName) {

                        case CheckboxFieldType:
                            this.formInstanceElement.BooleanValue = val;
                            break;

                        case IntegerFieldType:
                            this.FormInstanceElement.IntValue = val;
                            break;

                        case CurrencyFieldType:
                        case DecimalFieldType:
                            this.FormInstanceElement.DecimalValue = val;
                            break;

                        case RichTextFieldType:
                        case ShortTextFieldType:
                        case RadioButtonsFieldType:
                        case ParagraphFieldType:
                        case HTMLLinkFieldType: //TEAMS-835: KLW - Needed for the new HTML Link field
                        case FlexibleSelectionFieldType:
                            //case CascadingDropDownFieldType:
                            //case GridCascadingDropDownFieldType:
                            if (typeof val === 'string') {
                                this.formInstanceElement.TextValue = val;
                            }
                            break;

                        //VNEXT-538: KLW - Implementing Autocomplete
                        case DropDownFieldType:
                            if (val != '') {
                                if (this.SelectOptions) {
                                    if (this.SelectOptions.length > 0) {
                                        if (this.SelectOptions.includes(val)) {
                                            this.formInstanceElement.TextValue = val;
                                        }
                                    }
                                }
                            }
                            break;

                    }

                    if (!this.FormInstanceElement.transientInSetupFlag) {
                        if (!this.substantivelyChangedLogicApplies) {
                            this.FormInstanceElement.UserUpdatedData = true;
                        } else if (this.valueHasSubstantivelyChanged(val, this.textValueOnInitialSetup)) {
                            if (!this.FormInstanceElement.transientInSetupFlag) {
                                this.FormInstanceElement.UserUpdatedData = true;
                            }
                        }
                    }

                    this.notifyValueChanged();
                    this.setRedBorderManually();

                });

            //Needed for validation
            this.handleStatusChangesFor(passedFC);

            this.FormInstanceElement.transientInSetupFlag = false;
        }
    }



    private returnSavedValue(passedFormInstanceElement: FormInstanceElement): any {

        switch (this.FieldDefinitionClassName) {

            case IntegerFieldType:
                return passedFormInstanceElement.intValue;

            case CurrencyFieldType:
            case DecimalFieldType:
                return passedFormInstanceElement.decimalValue;

            case RichTextFieldType:
            case ShortTextFieldType:
            case RadioButtonsFieldType:
            case DropDownFieldType:
            case ParagraphFieldType:
            case HTMLLinkFieldType: //TEAMS-835: KLW - Needed for the new HTML Link field
                return passedFormInstanceElement.textValue;

            case CheckboxFieldType:
                return passedFormInstanceElement.booleanValue;

            // I think this method is only used when dealing with none multi-text fields, but Flexible Selection fields
            // are a new kind of thing. Even when only storing a single value they store it in a childFormInstanceElement
            case FlexibleSelectionFieldType:
                if (passedFormInstanceElement.childFormInstanceElements?.length == 1) {
                    return passedFormInstanceElement.childFormInstanceElements[0]?.textValue
                } else {
                    // keeping this for now, just in case but this can probably removed
                    return passedFormInstanceElement.textValue;
                }
        }
    }

    private setValueType() {
        switch (this.FieldDefinitionClassName) {

            case IntegerFieldType:
                this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeInteger;
                break;

            case CurrencyFieldType:
            case DecimalFieldType:
                this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeDecimal;
                break;

            case RichTextFieldType:
            case ShortTextFieldType:
            case RadioButtonsFieldType:
            case DropDownFieldType:
            case ParagraphFieldType:
            case HTMLLinkFieldType: //TEAMS-835: KLW - Needed for the new HTML Link field
                this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeText;
                break;

            case CheckboxFieldType:
                this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeBoolean;
                break;
        }
    }

    protected GetDefaultValue(formFieldParam: FormField, passedFormInstanceElement: FormInstanceElement): any {
        // Check for a default value.
        let iDefaultValue: any;
        let bHasDefaultValue: boolean =
            ((formFieldParam.defaultValue !== undefined) &&
                (formFieldParam.defaultValue !== null) &&
                (formFieldParam.defaultValue.trim() !== ''));
        if (bHasDefaultValue) {
            switch (this.FieldDefinitionClassName) {
                case IntegerFieldType:
                    iDefaultValue = parseInt(formFieldParam.defaultValue);
                    passedFormInstanceElement.intValue = iDefaultValue;
                    break;

                case CurrencyFieldType:
                case DecimalFieldType:
                    iDefaultValue = parseFloat(formFieldParam.defaultValue);
                    passedFormInstanceElement.decimalValue = iDefaultValue;
                    break;

                case CheckboxFieldType:
                    if (this.FormField.defaultValue.toLowerCase() === 'true') {
                        iDefaultValue = true;
                    } else {
                        iDefaultValue = false;
                    }
                    passedFormInstanceElement.booleanValue = iDefaultValue;
                    break;

                case RichTextFieldType:
                case ShortTextFieldType:
                case RadioButtonsFieldType:
                case DropDownFieldType:
                case ParagraphFieldType:
                case HTMLLinkFieldType: //TEAMS-835: KLW - Needed for the new HTML Link field
                    iDefaultValue = formFieldParam.defaultValue;
                    passedFormInstanceElement.textValue = iDefaultValue;
                    break;
            }
        }
        else {
            iDefaultValue = null;
        }

        return iDefaultValue;
    }

    protected formInstanceElementReceived(): void {
        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {

        //If code is being hit here then it's a form field in a grid
        return;
    }

    public registerOnChange(fn: any): void {
        //this.fnPropagateChanges = fn;
        this.callbackFunctions.fnPropagateChanges = fn;

        return;
    }

    public registerOnTouched(fn: any): void {
        //this.fnOnTouched = fn;
        this.callbackFunctions.fnOnTouched = fn;

        return;
    }

    public registerOnFocus(registeringComponent: IFormFieldComponent, fn: any): void {
        //public registerOnFocus(fn: (formFieldComponent: FormFieldBaseComponent, event: FocusEvent) => void): void {
        //this.fnOnFocus = fn;
        //this.callbackFunctions.fnOnFocus = fn;
        let onFocusData: OnFocusData = new OnFocusData();
        onFocusData.registeringComponent = registeringComponent;
        onFocusData.fnOnFocus = fn;
        this.callbackFunctions.onFocus = onFocusData;

        return;
    }

    public handlingEditPropertiesClickEvent(): void {
        // Note:  this default implementation is a NOOP by design.
    }

    public resetFormField(formField: FormField): void {
        // This default method implementation simply resets my form field.
        //
        // Note:  a component like the grid component can override this method.
        //this.FormField = formField; // This line was throwing the following JavaScript error (which makes no sense):
        //     something about 'FormField' only having a getter even though it has a setter.
        this.formField.assignFrom(formField); //use assignFrom() in case formField is of type Object
    }

    public get canHaveInstructions(): boolean {
        return true;
    }
    public get canHaveFieldConditionalLogic(): boolean {
        return true;
    }

    public formInstanceElementLoaded(formIntsanceElement: FormInstanceElement): void {
        // Note:  this method does nothing by design.
    }

    public configureAnySecondaryComponents(): void {
        // Note:  this method does nothing by design.
    }

    protected static staticToggleFormControlDisabledBasedOnReadOnlyAttribute(formControl: UntypedFormControl, formField: FormField): void {
        if (formControl != null) {
            // Make sure the form control's disabled state agrees with the form field's 'isReadOnly' attribute.
            let formControlDisabled: boolean = formControl.disabled;
            if (formField.readOnly)
                formControl.disable();
            else
                formControl.enable();
        }
    }
    protected toggleFormControlDisabledBasedOnReadOnlyAttribute(): void {
        FormFieldBaseComponent.staticToggleFormControlDisabledBasedOnReadOnlyAttribute(this.FormControl, this.formField);
    }

    /*
    protected static formControlIsRequired(formControl: FormControl): boolean {
        let isRequired: boolean = false;

        const validator = formControl.validator({} as AbstractControl);
        isRequired = (validator != null) && validator.required;

        return isRequired;
    }
    */

    // Define helper methods, called by derived classes,
    // to help implement the ControlValueAccessor interface.
    protected notifyValueChanged(): void {
        //this.fnPropagateChanges(this.formInstanceElement);
        this.callbackFunctions.fnPropagateChanges(this.formInstanceElement);
        //this.fnOnTouched();
        this.callbackFunctions.fnOnTouched();
    }

    protected notifyControlTouched(): void {
        this.touched.emit(this); // pharv - 4/14 - added for VNEXT-195 to support validation
        //this.fnOnTouched();
        this.callbackFunctions.fnOnTouched();

        if (this.mode != FormModeEnum.DESIGN) {
            this.FormControl.markAsTouched();
            this.formFieldValidated.emit(this.FormControl.status);
        }
    }

    protected notifyControlReceivedFocus(eventData: FocusEvent): void {
        this.callbackFunctions.onFocus.fnOnFocus(this.callbackFunctions.onFocus.registeringComponent, this, eventData);
    }

    // Define/implement private helper methods.

    protected buildValidatorFunctionsAndMessages(
        arrValidatorsParam: ValidatorFn[],
        arrValidationMessagesParam: ValidationMessageInfo[],
        arrAsyncValidatorsParam?: AsyncValidatorFn[]): void {
        let formFieldParam: FormField = this.FormField; // This temporarily allows us to continue using this method as originally coded/tested.  

        // Delete any contents in the array.
        while (arrValidatorsParam.length > 0) {
            arrValidatorsParam.pop();
        }

        while (arrValidationMessagesParam.length > 0) {
            arrValidationMessagesParam.pop();
        }

        // Build an array of validators, if any.
        if (formFieldParam) {
            let fnValidator: ValidatorFn = null;
            let strMessage: string = null;
            let fieldName = formFieldParam.displayName || formFieldParam.name;

            //debugger;
            if (formFieldParam.required) {
                arrValidatorsParam.push(Validators.required);
                arrValidationMessagesParam.push({ type: 'required', message: `${fieldName} is required` });

                //VNEXT-610: KLW - Add the custom validation for no whitespace
                arrValidatorsParam.push(this.noWhitespaceValidator.bind(this));
                arrValidationMessagesParam.push({ type: this.wsValidatorName, message: `${fieldName} cannot accept whitespace as the only value` });

                //VNEXT-538: KLW - Implementing Autocomplete
                arrValidatorsParam.push(this.requireMatchAutoComplete.bind(this));
                arrValidationMessagesParam.push({ type: this.rmacValidatorName, message: `${fieldName} requires a value selected from the available options` });
            }

            if ((formFieldParam.maxLength !== null) && (formFieldParam.maxLength !== 0)) {
                let asyncFnc = this.asyncValidatorFn('maxLength', formFieldParam);
                if (asyncFnc != null) {
                    arrAsyncValidatorsParam.push(asyncFnc);
                } else {
                    arrValidatorsParam.push(Validators.maxLength(formFieldParam.maxLength));
                }

                strMessage = `${fieldName} cannot have more than ${formFieldParam.maxLength} characters`;
                arrValidationMessagesParam.push({ type: 'maxlength', message: strMessage });
            }

            // pharv - 11/3/2021 - added to get date field validation working
            if (formFieldParam.minDate) {
                let asyncFnc = this.asyncValidatorFn('minDate', formFieldParam);
                if (asyncFnc != null) {
                    arrAsyncValidatorsParam.push(asyncFnc);
                } else {
                    arrValidatorsParam.push(this.validatorFn('minDate', formFieldParam));
                }

                strMessage = `${fieldName} cannot be before ${this.formatDate(formFieldParam.minDate)}`;
                arrValidationMessagesParam.push({ type: 'minDate', message: strMessage });
            }

            // pharv - 11/3/2021 - added to get date field validation working
            if (formFieldParam.maxDate) {
                let asyncFnc = this.asyncValidatorFn('maxDate', formFieldParam);
                if (asyncFnc != null) {
                    arrAsyncValidatorsParam.push(asyncFnc);
                } else {
                    arrValidatorsParam.push(this.validatorFn('maxDate', formFieldParam));
                }

                strMessage = `${fieldName} cannot be after ${this.formatDate(formFieldParam.maxDate)}`;
                arrValidationMessagesParam.push({ type: 'maxDate', message: strMessage });
            }


            if ((formFieldParam.minValue !== undefined) && (formFieldParam.minValue !== null)) {
                fnValidator = Validators.min(formFieldParam.minValue);
                arrValidatorsParam.push(fnValidator);

                strMessage = `${fieldName} must be greater than or equal to ${formFieldParam.minValue}`;
                arrValidationMessagesParam.push({ type: 'min', message: strMessage });
            }

            if ((formFieldParam.maxValue !== undefined) && (formFieldParam.maxValue !== null)) {
                fnValidator = Validators.max(formFieldParam.maxValue);
                arrValidatorsParam.push(fnValidator);

                strMessage = `${fieldName} must be less than or equal to ${formFieldParam.maxValue}`;
                arrValidationMessagesParam.push({ type: 'max', message: strMessage });
            }

            if (formFieldParam.regex) {
                arrValidatorsParam.push(Validators.pattern(formFieldParam.regex));
                let msg = formFieldParam.regularExpressionConstraintMessage || `must match format '${formFieldParam.regex}'`;
                arrValidationMessagesParam.push({ type: 'pattern', message: `${fieldName}: ${msg}` });
            }
        }

        return;
    }

    //VNEXT-610: KLW - This is a custom validator that will not accept whitespace
    private noWhitespaceValidator(control: UntypedFormControl) {
        var retVal: boolean = false;

        if (control.value) {
            if (control.value != '') {
                if (control.value.toString().trim().length == 0) {
                    retVal = true;
                }
            }
        }

        return retVal ? { [this.wsValidatorName]: true } : null;
    }

    //VNEXT-538: KLW - Implementing Autocomplete
    private requireMatchAutoComplete(control: UntypedFormControl) {
        var retVal: boolean = false;
        let controlVal = '';

        if (control?.value?.constructor === Array && control?.value?.length > 0) {
            controlVal = control.value[0].toString(); //VNEXT-1354
        }
        else {
            controlVal = control.value == null ? '' : control.value.toString();
        }

        if (controlVal != '') {
            if (this.SelectOptions) {
                if (this.SelectOptions.length > 0) {
                    if (!this.SelectOptions.includes(controlVal)) {
                        retVal = true;
                    }
                }
            }
        }

        return retVal ? { [this.rmacValidatorName]: true } : null;
    }

    // NOOP by design
    // override in sub-class if it needs async validation of any property
    // (see RichTextFieldComponent for example)
    protected asyncValidatorFn(validationPropertyName: string, formFieldParam: FormField): AsyncValidatorFn {
        return null;
    }

    protected validatorFn(validationPropertyName: string, formFieldParam: FormField): ValidatorFn {
        return null;
    }

    private formatDate(dateString: string): string {
        let date = new Date(dateString);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    // Tests to see if value has substantively/significantly changed by stripping all but alpha-numeric characters from the strings
    // This is needed because in some cases the wysiwyg text control adds spaces, line breaks and even <span>s when it loads
    // This can end up looking as though the user has made changes when they have not, and leads to an
    // "unsaved changes" warning if they try to navigate away after simply viewing a FormInstance
    protected valueHasSubstantivelyChanged(valCurrent: string, valPrevious: string): boolean {
        if (!valPrevious) return false;

        let re = /<br>|<br\/>|<br \/>|<span>|<\/span>|[^a-zA-Z0-9"'.,\/#!$%\^&\*;:{}=\-_`~()]|&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});/ig;
        let alphaNumericValCurrent = valCurrent?.replace(re, ""); // strip of all but alpha numeric values and punctuation
        let alphaNumericValPrevious = valPrevious?.replace(re, "");
        let changed = alphaNumericValCurrent !== alphaNumericValPrevious;
        return changed;
    }

    // Methods for logging errors or warnings.
    protected logWarning(message: string, componentClassName: string = this.getFormFieldClass().name): void {
        let warning = `${componentClassName}:  ${message}`;
        console.log(warning);
    }
}



