import { TextFieldDefinitionLogicBase } from './text-logic-base';

export class ShortTextFieldDefLogic extends TextFieldDefinitionLogicBase {
    // Constructor.
    public constructor() {
        super();
    };

    public getDefaultMaxLengthWhenInGrid(): number {
        return 250;
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }
}
