
// Export action names.
export enum FormFieldActionNameEnum {
    Show = 'Show',
    Hide = 'Hide',
    Require = 'Require',
    DoNotRequire = 'Do not require',
};

// Define an enumeration for the form field actions available with the new, enhanced field conditional logic.
export enum FieldConditionalLogicActionNameEnum {
    NoAction = '-- Select Action --',

    Show = 'Show',
    CanEdit = 'Can Edit',
    Require = 'Require',

    ApplyFilter = 'ApplyFilter',

    // Combinations:
    Show_CanEdit = 'Show, Can Edit',
    Show_CanEdit_Require = 'Show, Can Edit, Require'
}
export enum FieldConditionalLogicTargetFieldDefaultAttrs {
    None = 'None',

    Show = 'Show',
    Show_CanEdit = 'Show, Can Edit'
}
export enum FieldConditionalLogicActionPrecendencEnum {
    Show_CanEdit_Require = 1,
    Show_CanEdit = 2,

    Show = 3,
    CanEdit = 4,
    Require = 5
}

// Export comparison operator symbols.
export enum FormFieldComparisonOperatorNameEnum {
    IsGreaterThan = '>',
    IsGreaterThanOrEqualTo = '>=',
    Equals = '=',
    DoesNotEqual = '<>',
    IsLessThan = '<',
    IsLessThanOrEqualTo = '<=',
    InListOfValues = 'In',
    NotInListOfValues = 'NotIn',
    StartsWith = 'StartsWith',
    EndsWith = 'EndsWith',
    Contains = 'Contains',
    IsEmpty = 'IsEmpty',
    IsNotEmpty = 'IsNotEmpty',
    //Between = 'Between'
} 

export enum FormFieldActionGuidanceEnum {
    ApplyAction = 'ApplyAction',
    RemoveAction = 'RemoveAction'
};
