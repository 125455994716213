import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { TestConfiguration } from '../models/test-configuration.model';
import { ProgressIndicatorService } from './progress-indicator.service';

@Injectable({
    providedIn: 'root'
})
export class TestConfigurationService extends CollectApiServiceBase<TestConfiguration> {
    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'testConfiguration', TestConfiguration)
    }
}
