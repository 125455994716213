import { IHasIdAndName } from '../interfaces/has-id-and-name.interface';
import { ICloneAndCopy } from '../interfaces/clone-and-copy';
import { WorkflowPermissionBase } from './workflow-permission-base.model';

export class WorkflowTransitionPermission extends WorkflowPermissionBase implements ICloneAndCopy {
    public workflowId: number;

    public transitionId: number;
    public transitionName: string;

    // override of base class method
    public hasPermissions() {
        return this.canEdit == true;
    }

    // Implement interface IHasIdAndName.
    public getId(): number {
        return (this.transitionId);
    }
    public setId(value: number): void {
        this.transitionId = value;
    }

    //public name: string;
    public getName(): string {
        return (this.transitionName);
    }
    public setName(nameParam: string): void {
        this.transitionName = nameParam;

        return;
    }

    public typeName(): string {
        return ('WorkflowTransitionPermission');
    }

    // Implement interface ICloneAndCopy.
    public clone(): ICloneAndCopy {
        let clone: WorkflowTransitionPermission = new WorkflowTransitionPermission();

        clone.copyFieldsFrom(this);
        clone.workflowId = this.workflowId;
        clone.transitionId = this.transitionId;
        clone.transitionName = this.transitionName;

        return (clone);
    }

    public copy(objectToCopy: ICloneAndCopy): void {
        let wsPermission: WorkflowTransitionPermission = <WorkflowTransitionPermission>objectToCopy;

        this.copyFieldsFrom(wsPermission);
        this.workflowId = wsPermission.workflowId;
        this.transitionId = wsPermission.transitionId;
        this.transitionName = wsPermission.transitionName;

        return;
    }
}
