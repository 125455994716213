import { Injectable } from '@angular/core';
import { Role } from '../models/role.model';
import { DataCollection } from '../models/site-content/data-collection.model';

/*
 * This is a singleton service used as the single source of the current site
 * that a number of components need. The site is set from DataCollectionResolver.resolve()
 * which is set on the main 'site' route.
 * The Components of routes which are children of the 'site' route used to access the current site
 * via the router's parent's data property, but now get it via this service
*/ 

@Injectable({
    providedIn: 'root'
})
export class CurrentSiteService {

    private site: DataCollection;

    constructor() { }

    public set Site(site: DataCollection) {
        this.site = site;
    }

    public get Site(): DataCollection {
        return this.site;
    }

    // If this.site's id property matches that of the id argument, returns true, otherwise returns false
    public isCurrentSite(id: number): boolean {
        if (this.site != null && this.site.id == id) {
            return true;
        } else {
            return false;
        }
    }

    // updates the given role in this.site.roles
    public updateRole(role: Role): void {
        this.deleteRole(role);
        this.site.roles.push(role);
    }

    public addRole(role: Role) {
        this.site.roles.push(role);
    }

    public deleteRole(role: Role) {
        let otherRoles = this.site.roles.filter(x => { return x.id != role.id });
        this.site.roles = otherRoles;
    }
}
