import { IFormFieldConstraint } from '../../../interfaces/iform-field-constraint.interface';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';

export abstract class FormFieldConstraintBase implements IFormFieldConstraint { 
    // Properties.
    public dataCollectionId: number;
    public constraintId: number = 0;
    public constraintType: string;
    public constraintName: string;

    // Constructor.
    public constructor(dataCollectionIdParam: number, constraintIdParam: number, constraintTypeParam: string, constraintNameParam: string) {
        this.dataCollectionId = dataCollectionIdParam;
        this.constraintId = constraintIdParam;
        this.constraintType = constraintTypeParam;
        this.constraintName = constraintNameParam;
    }

    // Abstract methods.
    public abstract get type(): string;

    public abstract get constraintExpression(): string;
    public abstract set constraintExpression(value: string);

    public abstract get constraintFailureMessage(): string;
    public abstract set constraintFailureMessage(value: string);

    // Logic-related methods.
    public abstract validate(formField: FormField, formInstanceElement: FormInstanceElement): void;

    // Implement some of the methods in interface IFormFieldConstraint.
    public get id(): number {
        return this.constraintId;
    }
    public set id(value: number) {
        this.constraintId = value;
    }

    public get name(): string {
        return this.constraintName;
    }
    public set name(value: string) {
        this.constraintName = value;
    }

    public get constraintExpressionSummary(): string {
        return this.constraintExpression;
    }
}

export class FormFieldConstraintViewModel {
    // Properties.
    public dataCollectionId: number;

    public constraintId: number = 0;
    public constraintType: string;
    public constraintName: string;

    // Constraint-specific properties.
    public selectOptionsConstraintValue: string;
    public regularExpressionConstraintValue: string;
    public jsonConfig: string;
    public minValue: number = null;
    public maxValue: number = null;
    public minDate: string = null;
    public maxDate: string = null;
}
