import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { EventSubscriptionService } from '../../../shared/services/event-subscription.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { EventSubscription } from '../../models/event-subscription.model';
import { NotificationTemplate } from '../../models/notifications/notification-template.model';
import { DataCollection } from '../../models/site-content/data-collection.model';
import { QueryOptions } from '../../query-options';
import { ButtonConfig } from '../list-view/list-view-button-config.model';
import { TrashConfig, TrashDeleteEvent } from '../list-view/list-view-trash-config.model';

@Component({
    selector: 'app-notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit {
    private notificationsForListView: NotificationTemplate[]; // has just summary of the notification text
    private notificationsLookup: any = {};
    private site: DataCollection;
    private buttonConfig: any;
    private showDetails: boolean;
    private selectedMessageParts: string[]; // the message may be split into several parts for clarity of display

    private static readonly SUBSCRIPTIONS_TAB: string = "Your Subscriptions";
    private eventSubscriptions: EventSubscription[];

    constructor(private route: ActivatedRoute,
        private eventSubscriptionService: EventSubscriptionService,
        private readonly titleService: Title,
        private notificationService: NotificationService) { }

    ngOnInit(): void {
        let notifications: NotificationTemplate[] = [];
        for (let n of this.route.snapshot.data['notifications']) {
            let notification = Object.assign(new NotificationTemplate(), n);
            this.notificationsLookup[n.id] = notification;

            n.notificationText = notification.getFirstPartOfNotificationText();
            notifications.push(n);
        }
        this.notificationsForListView = notifications;
        this.site = this.route.parent.snapshot.data['currentSite'];

        this.buttonConfig = {
            item_actions: [new ButtonConfig('View Details', 'info', 'info')]
        };
        this.buttonConfig['trash'] = new TrashConfig(true);
        this.titleService.setTitle(this.route.snapshot.data['title'] + ' for ' + this.site.name);
    }

    public onItemAction(e: any) {
        let nt = this.notificationsLookup[e.item.id];
        this.toggleDetails(nt);
    }

    public onListItemDeleted(e: TrashDeleteEvent) {
        console.log('onListItemDeleted()...');
        console.log(e);
        for (let item of e.items) {
            this.notificationService.delete(item.id).then(x => {
                this.notificationsForListView = Object.assign([], this.notificationsForListView.filter(x => { return x.id != item.id }));
            });
        }
    }

    public get Notifications(): NotificationTemplate[] {
        return this.notificationsForListView;
    }

    public get EventSubscriptions(): EventSubscription[] {
        return this.eventSubscriptions;
    }

    public get ButtonConfig() {
        return this.buttonConfig;
    }

    public get ShowDetails() {
        return this.showDetails;
    }

    public get SelectedMessageParts(): string[] {
        return this.selectedMessageParts;
    }

    public toggleDetails(notificationInstance = null): void {
        if (!this.showDetails) {
            this.selectedMessageParts = notificationInstance?.getAllTextParts();
        } else {
            this.selectedMessageParts = null;
        }
        this.showDetails = !this.showDetails;
    }event

    public onSelectedTabChange(event: any) {
        if (event.tab.textLabel == NotificationCenterComponent.SUBSCRIPTIONS_TAB && !this.subscriptionsLoaded()) {
            console.log("Loading subscriptions...");
            let queryOptions = new QueryOptions();
            queryOptions.addParameter("dataCollectionId", this.site.id);
            this.eventSubscriptionService.getAll(queryOptions).then(res => {
                this.eventSubscriptions = res;
            });
        } 
    }

    private subscriptionsLoaded(): boolean {
        return this.eventSubscriptions && this.eventSubscriptions.length > 0;
    };
}
