import { DefaultFieldDefinitionClientLogic } from './default-field-def-client-logic';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { CascadingDropDownFormFieldConfig } from '../../../models/cascading-dropdown/cascading-dropdown-config.model';
import { CascadingDropDownFormFieldData } from '../../../models/cascading-dropdown/cascading-dropdown-data.model';

export class CascadingDropdownVirtualFieldDefLogic extends DefaultFieldDefinitionClientLogic {
    public constructor() {
        super();
    }

    public allowsHtmlInDisplayValue(): boolean {
        return true;
    }
    
    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        }

        return (formInstanceElementParam.textValue);
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }

    public filterType(): string {
        return 'text';
    }

    public isFilterable(): boolean {
        return true;
    }
}
