import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-select-options',
    templateUrl: './select-options.dialog.html',
    styleUrls: ['./select-options.dialog.scss']
})
export class SelectOptionsDialog implements OnInit {
    // Properties.
    private title: string = "Select Options";
    private label: string = 'Enter or Paste List of Options';
    public options: string = null;

    // Constructor.
    public constructor(@Inject(MAT_DIALOG_DATA) data) {
        this.options = data.options;
        if (data.title != null)
            this.title = data.title;
        if (data.label != null)
            this.label = data.label;
    }

    // Life cycle methods.
    public ngOnInit(): void {
    }

    // Methods called by my HTML code.
    public get Title(): string {
        return this.title;
    }
    public get Label(): string {
        return this.label;
    }
}
