import { IViewModel } from '../interfaces/view-model.interface';
import { FormInstanceElement } from './form-builder/form-instance-element.model';
import { Form } from './form-builder/form.model';
//import { FolderInstance } from './folder-instance.model'; // 03-04-2020 note:  per below, this needs to be phased out.
import { Folder } from './site-content/folder.model';
import { WorkflowTransition } from './workflow-transition.model';
// 02-02-2021 note:  at some point, making class Folder
//                   implement interface IListItem.
import { IListItem } from '../interfaces/ilist-item.interface';
import { Workflow } from './site-content/workflow.model'; //TEAMS-424: KLW - Import workflow model
import { ItemTypeEnum } from '../enums/item-type.enum';
import { IHasIdAndNameAndWorkflowId } from '../interfaces/has-id-and-name.interface';
import { IListItemBase } from './ilist-item-base.model';
import { IconFormatEnum } from '../enums/icon-format.enum';

export class LogMessage extends IListItemBase implements IViewModel, IListItem, IHasIdAndNameAndWorkflowId{
    public message: string;

    // Constructor.
    public constructor(_id: number = 0) {
        super();

        this.id = _id;
    }
    public findAndUpdateFrom(items: any, obj: any): void {
        throw new Error('Method not implemented.');
    }
    public userCanDelete(): boolean {
        throw new Error('Method not implemented.');
    }
    public getStatus(): string {
        throw new Error('Method not implemented.');
    }
    public getOriginalVersionId(): number {
        throw new Error('Method not implemented.');
    }

    // Define properties.
    // Key field.
    public id: number = 0;
    public explicitType: string = 'log-message';

    public modifiedBy: string;
    public modifiedDate: Date;

    public createdBy: string;
    public createdDate: Date;

    // Implement assignFrom.
    public assignFrom(cloneObj: any): any {
        for (var attribut in cloneObj) {
            this[attribut] = cloneObj[attribut];
        }

        return (this);
    }

    // IListItem methods.
    public getId(): number {
        return (this.id);
    }
    public setId(idParam: number): void {
        this.id = idParam;

        return;
    }

    public getTitle(): string {
        let title: string = "Log Message";

        return (title);
    }

    public isMagicStructure(): boolean {
        return false;
    }

    public getExtraInfo(): string {
        return this.message;
    }

    public getValue(property: string): string {
        return "";
    }

    //TEAMS-424: KLW - set the object type
    public static readonly TypeName: string = ItemTypeEnum.FORM_INSTANCE;
    public getType(): string {
        return 'log-message';
    }

    public getPosition(): number {
        return 0;
    }

    public canBeDeleted(): boolean { // Note:  this will have to be fixed to use
        //        a value returned by the server.
        return false;
    }

    public getIconType(): IconFormatEnum {
        // Note:  will enable the following conditional line after reviewing the new magic form icon with others.
        //return (this.magicStructureDefinitionId != null ? IconFormatEnum.SVG_ICON : IconFormatEnum.MAT_ICON);
        return IconFormatEnum.MAT_ICON;
    }
    public getIcon(): string {
        let icon: string = 'article';
        
        return icon;
    }

    public getUniqueId(): string { // For interface IListItem
        var uniqueId: string = `${this.id}-${this.getType()}`;

        return (uniqueId);
    }

    public getChildCount(): number {
        throw new Error('Method not implemented.');
    }

    // If modifiedByUserName is set, return that
    public getModifiedBy(): string {
        return this.modifiedBy;
    }
    public getModifiedDate(): Date {
        return this.modifiedDate;
    }

    public update(obj: any, type: string, icon?: string, position?: number): void {
        
    }

    // Implement interface IHasIdAndName methods.
    public getName(): string {
        return (this.name);
    }
    public setName(nameParam: string): void {
        this.name = nameParam;
    }

    public typeName(): string {
        return 'log-message';
    }

    hasDescriptionField(): boolean {
        return true;
    }
    getDescription(): string {
        return this.message;
    }
    setDescription(val: string) {
        this.message = val;
    }

    // Define HasIdAndName interface methods that have no meaning for this class.
    public getChangeWorkflowStateDialogTitle(): string { return null; }
    public setChangeWorkflowStateDialogTitle(value: string): void { }

    public getTransitionConfirmationDialogMessage(): string { return null; }
    public setTransitionConfirmationDialogMessage(value: string): void { }

    public getTakeUserToSiteHomePageAfterTransitionApplied(): boolean { return false; }
    public setTakeUserToSiteHomePageAfterTransitionApplied(value: boolean): void { }

    public getPropertiesDrawerTitle(): string {
        return null;
    }
    // End HasIdAndName interface methods that have no meaning for this class.

    public getParentId(): number {
        return -1;
    }

    public getWorkflowId(): number {
        return -1;
    }
}
