<h1 mat-dialog-title
    class="draggable-dialogue-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
    Import Site
</h1>

<form>
    <div mat-dialog-content
         class="mat-dialog-content-div">
        <span>Name for Imported Site: </span>

        <mat-form-field appearance="outline"
                        class="import-site-name-mat-form-field">
            <mat-label>Import Site As</mat-label>
            <input matInput
                   [(ngModel)]="this.ImportSiteName"
                   placeholder="Name for Imported Site"
                   [disabled]="this.JobInitiated"
                   name="importSiteName"
                   autocomplete="off"
                   required />
        </mat-form-field>

        <br />

        <span>Select Import File: </span>

        <input type="file"
               accept=".zip"
               #file
               style="display: none"
               (change)="this.onFileAdded()" />

        <button [disabled]="this.JobInitiated"
                mat-raised-button
                color="primary"
                class="add-file-btn theme-primary-btn"
                (click)="this.selectImportFile()">
            Select File ...
        </button>

        <br />

        <span>Selected Import File: </span>
        <span>{{this.SelectedFileName}}</span>
    </div>

    <div class="execution-time-div">
        <span>Execution Time:</span>
        <span [ngClass]="this.JobInProgress ? 'active-execution-time-span' : 'idle-execution-time-span'">{{this.JobTimeElapsed}}</span>
    </div>

    <div class="execution-status-div">
        <span>Execution Status:</span>
        <span [ngClass]="this.JobCompleted ? 'completed-execution-status-span' : 'incomplete-execution-status-span'">{{this.ImportStatus}}</span>
    </div>

    <!--
    <div class="export-ignore-validation-errors-checkbox">
        <mat-checkbox name="IgnoreValidationErrors" [(ngModel)]="IgnoreValidationErrors">Ignore Validation Errors?</mat-checkbox>
    </div>
    -->

    <div class="execution-summary-div">
        <p [ngClass]="this.JobErrorOccurred ? 'execution-summary-p execution-summary-with-error-p' : 'execution-summary-p execution-summary-without-error-p'"
           data-placeholder="Details will display here ...">{{this.ExecutionSummaryText}}</p>
    </div>

    <div mat-dialog-actions>
        <button mat-flat-button
                type="submit"
                color="primary"
                class="theme-primary-btn"
                [disabled]="this.ImportSiteNameIsEmpty || (!this.FileSelected) || this.JobInitiated"
                (click)="this.startSiteImport()">
            Start Import ...
        </button>
        <!--[mat-dialog-close]="this.ImportedSiteId"-->
        <button mat-flat-button
                type="submit"
                color="accent"
                [disabled]="!this.JobInitiated"
                (click)="this.dismissClicked()">
            {{this.DismissButtonTitle}}
        </button>
        <button mat-flat-button
                [disabled]="this.JobInitiated"
                [mat-dialog-close]="null"
                style="margin-left: 10px;">
            Cancel
        </button>
    </div>
</form>
