import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { Task } from '../models/task.model';

import { plainToClass } from "class-transformer";
import { ImpersonatableUser } from '../models/impersonatable-user.model';

@Injectable({
    providedIn: 'root'
})
export class UserImpersonationService {

    private url: string;

    constructor(private http: HttpClient) { }

    public impersonate(userId: string): Promise<any> {
        var url = `${environment.apiUrl}/api/impersonation/start/${userId}`;
        return this.http.get(url).toPromise();
    }

    public stopImpersonating(): Promise<any> {
        var url = `${environment.apiUrl}/api/impersonation/stop`;
        return this.http.get(url).toPromise();
    }

    public checkIfImpersonating(): void {
        var url = `${environment.apiUrl}/api/impersonation/check`;
        this.http.get(url).toPromise().then(x => {
            console.log(`Message from api: ${x}`);
        });
    }

    public getImpersonatableUsers(): Promise<any> {
        var url = `${environment.apiUrl}/api/impersonation/getImpersonatableUsers`;
        return this.http.get(url).toPromise();
    }
}
