// Define form field type constants used by multiple classes.
export const ShortTextFieldType = 'Collect.vNext.FieldDefinitions.ShortTextFieldDefinition';
export const ParagraphFieldType = 'Collect.vNext.FieldDefinitions.ParagraphFieldDefinition';
export const DateFieldType = 'Collect.vNext.FieldDefinitions.DateFieldDefinition';
export const CheckboxFieldType = 'Collect.vNext.FieldDefinitions.CheckBoxFieldDefinition';
export const RadioButtonsFieldType = 'Collect.vNext.FieldDefinitions.RadioButtonFieldDefinition';
export const IntegerFieldType = 'Collect.vNext.FieldDefinitions.IntegerFieldDefinition';
export const DecimalFieldType = 'Collect.vNext.FieldDefinitions.DecimalFieldDefinition';
export const CurrencyFieldType = 'Collect.vNext.FieldDefinitions.CurrencyFieldDefinition';
export const LabelFieldType = 'Collect.vNext.FieldDefinitions.LabelFieldDefinition';
export const DropDownFieldType = 'Collect.vNext.FieldDefinitions.DropDownFieldDefinition';
export const GridFieldType = 'Collect.vNext.FieldDefinitions.GridFieldDefinition';
export const CascadingDropDownFieldType = 'Collect.vNext.FieldDefinitions.CascadingDropDownFieldDefinition';
export const CascadingDropDownVirtualFieldType = 'Collect.vNext.FieldDefinitions.CascadingDropDownVirtualFieldDefinition';
export const AttachmentFieldType = 'Collect.vNext.FieldDefinitions.AttachmentFieldDefinition';
export const InstructionsFieldType = 'Collect.vNext.FieldDefinitions.InstructionsFieldDefinition';
export const RawHTMLFieldType = 'Collect.vNext.FieldDefinitions.RawHTMLFieldDefinition';
export const RichTextFieldType = 'Collect.vNext.FieldDefinitions.RichTextFieldDefinition';
export const InlineContentFieldType = 'Collect.vNext.FieldDefinitions.InlineContentFieldDefinition';
export const DevOnlyFieldType = 'Collect.vNext.FieldDefinitions.DevOnlyFieldDefinition';
export const FootnoteFieldType = 'Collect.vNext.FieldDefinitions.FootnoteFieldDefinition';
export const MultiCheckboxFieldType = 'Collect.vNext.FieldDefinitions.MultiCheckBoxFieldDefinition';
export const MultiDropDownFieldType = 'Collect.vNext.FieldDefinitions.MultiDropDownFieldDefinition';
export const FlexibleSelectionFieldType = 'Collect.vNext.FieldDefinitions.FlexibleSelectionFieldDefinition';

//export const StartShowHideFieldType = 'Collect.vNext.FieldDefinitions.StartShowHideFieldDefinition';
export const ShowToFieldType = 'Collect.vNext.FieldDefinitions.VisibilityShowToFieldDefinition';
export const HideFromFieldType = 'Collect.vNext.FieldDefinitions.VisibilityHideFromFieldDefinition';
export const MarkDataReadOnlyFieldType = 'Collect.vNext.FieldDefinitions.VisibilityMarkDataReadOnlyFieldDefinition';

export const AddRowAboveFieldType = 'Collect.vNext.FieldDefinitions.VisibilityAddRowAboveFieldDefinition';
export const AddRowBelowFieldType = 'Collect.vNext.FieldDefinitions.VisibilityAddRowBelowFieldDefinition';

export const GridCascadingDropDownFieldType = 'Collect.vNext.FieldDefinitions.GridCascadingDropDownFieldDefinition';

export const ContactsFieldType = 'Collect.vNext.FieldDefinitions.ContactsFieldDefinition';
export const CommentsFieldType = 'Collect.vNext.FieldDefinitions.CommentsFieldDefinition';

export const HTMLLinkFieldType = 'Collect.vNext.FieldDefinitions.HtmlLinkFieldDefinition';

// Define form field type constants for field types only available in the grid form field component.
export const FormulaFieldType = 'Collect.vNext.FieldDefinitions.FormulaFieldDefinition';
export const UrlBuilderFieldType = 'Collect.vNext.FieldDefinitions.UrlBuilderFieldDefinition';

// Metadata field definition class name constants.
export const UserRolesFieldType = 'Collect.vNext.FieldDefinitions.UserRolesFieldDefinition';
export const WorkflowStateFieldType = 'Collect.vNext.FieldDefinitions.WorkflowStateFieldDefinition';
export const FolderNameFieldType = 'Collect.vNext.FieldDefinitions.FolderNameFieldDefinition';
export const FolderPathFieldType = 'Collect.vNext.FieldDefinitions.FolderPathFieldDefinition';
export const FormInstanceNameFieldType = 'Collect.vNext.FieldDefinitions.FormInstanceNameFieldDefinition';
export const FormTemplateInstancesFieldType = 'Collect.vNext.FieldDefinitions.FormTemplateInstancesFieldDefinition';
export const IdFieldType = 'Collect.vNext.FieldDefinitions.IdFieldDefinition';
export const FavoriteFieldType = 'Collect.vNext.FieldDefinitions.FavoriteFieldDefinition';
export const MultipleFieldsMetadataFieldType = 'Collect.vNext.FieldDefinitions.MultipleFieldsMetadataFieldDefinition';

export enum FormFieldTypeEnum {
    ShortTextFieldType = 'Collect.vNext.FieldDefinitions.ShortTextFieldDefinition',
    DateFieldType = 'Collect.vNext.FieldDefinitions.DateFieldDefinition',
    CheckboxFieldType = 'Collect.vNext.FieldDefinitions.CheckBoxFieldDefinition',
    RadioButtonsFieldType = 'Collect.vNext.FieldDefinitions.RadioButtonFieldDefinition',
    IntegerFieldType = 'Collect.vNext.FieldDefinitions.IntegerFieldDefinition',
    DecimalFieldType = 'Collect.vNext.FieldDefinitions.DecimalFieldDefinition',
    CurrencyFieldType = 'Collect.vNext.FieldDefinitions.CurrencyFieldDefinition',
    LabelFieldType = 'Collect.vNext.FieldDefinitions.LabelFieldDefinition',
    DropDownFieldType = 'Collect.vNext.FieldDefinitions.DropDownFieldDefinition',
    GridFieldType = 'Collect.vNext.FieldDefinitions.GridFieldDefinition',
    CascadingDropDownFieldType = 'Collect.vNext.FieldDefinitions.CascadingDropDownFieldDefinition',
    AttachmentFieldType = 'Collect.vNext.FieldDefinitions.AttachmentFieldDefinition',
    //InstructionsFieldType = 'Collect.vNext.FieldDefinitions.InstructionsFieldDefinition',
    RawHTMLFieldType = 'Collect.vNext.FieldDefinitions.RawHTMLFieldDefinition',
    RichTextFieldType = 'Collect.vNext.FieldDefinitions.RichTextFieldDefinition',
    InlineContentFieldType = 'Collect.vNext.FieldDefinitions.InlineContentFieldDefinition',
    MultiCheckboxFieldType = 'Collect.vNext.FieldDefinitions.MultiCheckBoxFieldDefinition',
    MultiDropDownFieldType = 'Collect.vNext.FieldDefinitions.MultiDropDownFieldDefinition',

    ShowToFieldType = 'Collect.vNext.FieldDefinitions.VisibilityShowToFieldDefinition',
    HideFromFieldType = 'Collect.vNext.FieldDefinitions.VisibilityHideFromFieldDefinition',

    AddRowAboveFieldType = 'Collect.vNext.FieldDefinitions.VisibilityAddRowAboveFieldDefinition',
    AddRowBelowFieldType = 'Collect.vNext.FieldDefinitions.VisibilityAddRowBelowFieldDefinition',

    GridCascadingDropDownFieldType = 'Collect.vNext.FieldDefinitions.GridCascadingDropDownFieldDefinition',

    ContactsFieldType = 'Collect.vNext.FieldDefinitions.ContactsFieldDefinition',
    CommentsFieldType = 'Collect.vNext.FieldDefinitions.CommentsFieldDefinition',
    HTMLLinkFieldType = 'Collect.vNext.FieldDefinitions.HtmlLinkFieldDefinition', //TEAMS-835: KLW - Needed for the HTML Link form field
    FormTemplateInstancesFieldDefinition = 'Collect.vNext.FieldDefinitions.FormTemplateInstancesFieldDefinition'
}
export enum FormFieldTypePrettyNamesEnum {
    ShortTextFieldType = 'Short Text',
    DateFieldType = 'Date',
    CheckboxFieldType = 'Check Box',
    RadioButtonsFieldType = 'Radio Buttons',
    IntegerFieldType = 'Integer',
    DecimalFieldType = 'Decimal',
    CurrencyFieldType = 'Currency',
    LabelFieldType = 'Label',
    DropDownFieldType = 'Drop-down',
    GridFieldType = 'Grid',
    CascadingDropDownFieldType = 'Cascading Drop-down',
    AttachmentFieldType = 'Attachment',
    //InstructionsFieldType = 'InstructionsFieldDefinition',
    RawHTMLFieldType = 'Raw HTML',
    RichTextFieldType = 'Rich Text',
    InlineContentFieldType = 'Inline Content',
    MultiCheckboxFieldType = 'Multiselected Check Box',
    MultiDropDownFieldType = 'Multiselected Drop-down',

    ShowToFieldType = 'Show To',
    HideFromFieldType = 'Hide From',

    AddRowAboveFieldType = 'Add Row Above',
    AddRowBelowFieldType = 'Add Row Below',

    GridCascadingDropDownFieldType = 'Grid Cascading Drop-down',

    ContactsFieldType = 'Contacts',
    CommentsFieldType = 'Comments',

    HTMLLinkFieldType = 'HTML Link' //TEAMS-835: KLW - Needed for the HTML Link form field
}

// Export an enum of control type hints.
export enum FieldControlTypeHint { // Listed in alphabetical order.
    DATE_INPUT = 'date',
    MULTI_SELECT_INPUT = 'multi-select',
    NONE = 'none',
    NUMERIC_INPUT = 'numeric input',
    SELECT_INPUT = 'select',
    TEXT_INPUT = 'input'
}
