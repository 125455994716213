import { Injectable, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';

import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { FieldDefinition } from '../models/form-builder/field-definition.model';
import { IFieldDefinitionLogic } from '../interfaces/ifield-definition-logic.interface';
import {
    ShowToFieldType,
    HideFromFieldType,
    AddRowAboveFieldType,
    AddRowBelowFieldType,
    GridCascadingDropDownFieldType,
    CheckboxFieldType,
    DropDownFieldType,
    MultiDropDownFieldType,
    RadioButtonsFieldType,
    DecimalFieldType,
    CurrencyFieldType,
    IntegerFieldType,
    DateFieldType,
    ShortTextFieldType,
    RichTextFieldType,
    ParagraphFieldType,
    CascadingDropDownFieldType,
    MultiCheckboxFieldType,
    FormulaFieldType,
    HTMLLinkFieldType,
    MarkDataReadOnlyFieldType,
    GridFieldType,
    FlexibleSelectionFieldType,
    CascadingDropDownVirtualFieldType,
    UserRolesFieldType,
    WorkflowStateFieldType,
    FolderNameFieldType,
    FolderPathFieldType,
    FormInstanceNameFieldType,
    FormTemplateInstancesFieldType,
    IdFieldType,
    FavoriteFieldType,
    UrlBuilderFieldType
} from '../models/form-builder/form-field-types';
import { DefaultFieldDefinitionClientLogic } from '../models/form-builder/field-defs/default-field-def-client-logic';
import { AddRowAboveFieldDefinitionCustomLogic } from '../models/form-builder/field-defs/add-row-above-field-def-logic';
import { AddRowBelowFieldDefinitionCustomLogic } from '../models/form-builder/field-defs/add-row-below-field-def-logic';
import { ShowToFieldDefinitionCustomLogic } from '../models/form-builder/field-defs/show-to-field-def-logic';
import { HideFromFieldDefinitionCustomLogic } from '../models/form-builder/field-defs/hide-from-field-def-logic';
import { CascadingDropdownFieldDefLogic } from '../models/form-builder/field-defs/cascading-dropdown-field-def-logic';
import { CascadingDropdownVirtualFieldDefLogic } from '../models/form-builder/field-defs/cascading-dropdown-virtual-field-def-logic';
import { GridCascadingDropdownFieldDefLogic } from '../models/form-builder/field-defs/grid-cascading-dropdown-field-def-logic';
import { QueryOptions } from '../query-options';
import { CheckboxFieldDefLogic } from '../models/form-builder/field-defs/checkbox-field-def-logic';
import { DropdownFieldDefLogic } from '../models/form-builder/field-defs/dropdown-field-def-logic';
import { MultiDropdownFieldDefLogic } from '../models/form-builder/field-defs/multi-dropdown-field-def-logic';
import { MultiCheckboxFieldDefLogic } from '../models/form-builder/field-defs/multi-checkbox-field-def-logic';
import { RadioButtonsFieldDefLogic } from '../models/form-builder/field-defs/radio-buttons-field-def-logic';
import { DecimalFieldDefLogic } from '../models/form-builder/field-defs/decimal-field-def-logic';
import { CurrencyFieldDefLogic } from '../models/form-builder/field-defs/currency-field-def-logic';
import { IntegerFieldDefLogic } from '../models/form-builder/field-defs/integer-field-def-logic';;
import { DateFieldDefLogic } from '../models/form-builder/field-defs/date-field-def-logic';
import { ShortTextFieldDefLogic } from '../models/form-builder/field-defs/short-text-field-def-logic';
import { RichTextFieldDefLogic } from '../models/form-builder/field-defs/rich-text-field-def-logic';
import { ParagraphFieldDefLogic } from '../models/form-builder/field-defs/paragraph-field-def-logic';
//import { AttachmentService } from './attachment.service';
import { FormulaFieldDefLogic } from '../models/form-builder/field-defs/formula-field-def-logic';
import { UrlBuilderFieldDefLogic } from '../models/form-builder/field-defs/url-builder-field-def-logic';
import { HtmlLinkFieldDefLogic } from '../models/form-builder/field-defs/html-link-field-def-logic';
import { MarkDataAsReadOnlyFieldDefinitionCustomLogic } from '../models/form-builder/field-defs/mark-data-as-read-only-field-def-logic';
import { GridFieldDefLogic } from '../models/form-builder/field-defs/grid-field-def-logic';
import { UserRolesFieldDefinitionLogic } from '../models/form-builder/field-defs/user-roles-field-def-logic';
import { WorkflowStateFieldDefinitionLogic } from '../models/form-builder/field-defs/workflow-state-field-def-logic';
import { FolderNameFieldDefinitionLogic } from '../models/form-builder/field-defs/folder-name-field-def-logic';
import { FlexibleSelectionFieldDefLogic } from '../models/form-builder/field-defs/flexible-selection-field-def-logic';
import { FormInstanceNameFieldDefinitionLogic } from '../models/form-builder/field-defs/form-instance-name-field-def-logic';
import { FormTemplateInstancesFieldDefLogic } from '../models/form-builder/field-defs/template-instances-field-def-logic';
import { IdMetadataFieldDefLogic } from '../models/form-builder/field-defs/id-metadata-field-def-logic';
import { FolderPathFieldDefinitionLogic } from '../models/form-builder/field-defs/folder-path-field-def-logic';
import { FavoriteFieldDefLogic } from '../models/form-builder/field-defs/favorite-field-def-logic';
import { UrlBuilderFormFieldComponent } from '../components/form-fields/url-builder-form-field/url-builder-form-field.component';

// Define type-safe map types.
interface IFieldDefinitionNameToFieldDefinition {
    [fieldName: string]: FieldDefinition;
}
interface IFieldDefinitionNameToDisplayName {
    [fieldName: string]: string;
}
export interface IFieldDefintionToCustomLogicHandler {
    [fieldDefinitionClassName: string]: IFieldDefinitionLogic;
}

// Define/implement field definition custom logic classes.
// Define the custom logic handler that does nothing (works for most field definition classes).
//
// Note:  these classes have been moved into their own files and are imported above.

// Implement this file's service class.
@Injectable({
    providedIn: 'root'
})
export class FieldDefinitionService extends CollectApiServiceBase<FieldDefinition> {
    // Properties.
    private cachedFieldDefs: FieldDefinition[] = null;
    private mapOfCachedFieldDefs: IFieldDefinitionNameToFieldDefinition = null;

    private mapOfFieldDefinitionNamesToDisplayNames: IFieldDefinitionNameToDisplayName = {};
    private mapOfCustomLogicHandlers: IFieldDefintionToCustomLogicHandler = {};

    // Constructor.
    public constructor(http: HttpClient) {
        //private renderer: Renderer2,
        //private attachmentService: AttachmentService,
        //private dateAdapter: DateAdapter<Date>,
        //private dialog: MatDialog) {
        super(http, null, environment.apiUrl, 'fieldDefinition', FieldDefinition);

        this.loadCustomLogicHandlers();
    }

    public get fieldDefinitionsLoaded(): boolean {
        let loaded = ((this.cachedFieldDefs != null) && (this.cachedFieldDefs.length > 0));
        return loaded;
    }

    //formatResponse(data: FieldDefinition): FieldDefinition {
    //    let obj = Object.assign(new FieldDefinition(), data);

    //    return obj;
    //}
    public formatResponse(data: FieldDefinition): FieldDefinition {
        let obj = plainToClass(FieldDefinition, data);

        return (obj);
    }

    public getAll(queryOptions?: QueryOptions): Promise<FieldDefinition[]> {
        let result: Promise<FieldDefinition[]> = null;

        if (this.cachedFieldDefs == null) {
            result = new Promise<FieldDefinition[]>((resolve, reject) => {
                super.getAll(queryOptions).then(responseFieldDefs => {
                    this.cacheFieldDefinitions(responseFieldDefs);

                    resolve(responseFieldDefs);
                });
            });
        } else {
            result = new Promise<FieldDefinition[]>((resolve, reject) => {
                resolve(this.cachedFieldDefs);
            });
        }

        return result;
    }

    public getMetadataFields(): Promise<FieldDefinition[]> {
        let result: Promise<FieldDefinition[]> = null;

        result = new Promise<FieldDefinition[]>((resolve, reject) => {
            this.getAll().then(allFieldDefs => {
                let metadataFieldDefs: FieldDefinition[] = allFieldDefs.filter(fd => fd.isMetadataField);

                resolve(metadataFieldDefs);
            });
        });

        return result;
    }
    /*
    public getAllGridFieldTypesAndNameFields(): Promise<FieldDefinition[]> {
        let result: Promise<FieldDefinition[]> = null;

        result = new Promise<FieldDefinition[]>((resolve, reject) => {
            this.getAll().then(allFieldDefs => {
                let responseFieldDefs: FieldDefinition[] = allFieldDefs.filter(fd => fd.canBeDroppedOnGrid);

                resolve(responseFieldDefs);
            });
        });

        return result;
    }
    */

    // Implement getAllResponseReady(), called by the base class's getAll() method.
    protected getAllResponseReady(response: FieldDefinition[]): void {
        // Have we initialized the map of field definition names to display names?
        let mapKeys: string[] = Object.keys(this.mapOfFieldDefinitionNamesToDisplayNames);
        if (mapKeys.length == 0) {
            // Initialize the map now.
            for (let iFieldDef: number = 0; iFieldDef < response.length; iFieldDef++) {
                let fieldDefinition: FieldDefinition = response[iFieldDef];

                this.mapOfFieldDefinitionNamesToDisplayNames[fieldDefinition.className] = fieldDefinition.displayName;
            }
        }
    }

    public getFieldTypeDisplayName(fieldDefinitionName: string, throwExceptionOnError: boolean = true): string {
        let displayName: string = this.mapOfFieldDefinitionNamesToDisplayNames[fieldDefinitionName];

        if ((displayName == undefined) && throwExceptionOnError) {
            let errorMsg: string = `FieldDefinitionService.getFieldTypeDisplayName():  could not find a display name for field definition class '${fieldDefinitionName}.`;
            throw (errorMsg);
        }

        return (displayName);
    }

    // Note:  it might make sense to rework the following method to return a Promise<FieldDefinition> 
    //        result, though we would have to analyze how this method is being used before doing so.
    public getFieldDefinition(fieldDefinitionClassName: string, throwExceptionOnFailure: boolean = true): FieldDefinition {
        let fieldDefinition: FieldDefinition = null;

        if (this.mapOfCachedFieldDefs != null) {
            fieldDefinition = this.mapOfCachedFieldDefs[fieldDefinitionClassName];

            if (fieldDefinition.customLogicHandler == null)
                fieldDefinition.customLogicHandler = this.getFieldClientLogicHandler(fieldDefinitionClassName);
        }

        if ((fieldDefinition == null) && throwExceptionOnFailure)
            throw `FieldDefinitionService.getFieldDefinition():  could not get a field definition for field class '${fieldDefinitionClassName}'.`;

        return fieldDefinition;
    }

    /*
    public getFieldClientLogicHandler(fieldDefinitionClassName: string): IFieldDefinitionLogic {
        let fieldDefinition: FieldDefinition = this.getFieldDefinition(fieldDefinitionClassName);

        return fieldDefinition.customLogicHandler;
    }
    */

    public getFieldClientLogicHandler(fieldDefinitionClassName: string): IFieldDefinitionLogic {
        let customLogicHandler: IFieldDefinitionLogic = this.mapOfCustomLogicHandlers[fieldDefinitionClassName];

        if (customLogicHandler == null)
            customLogicHandler = new DefaultFieldDefinitionClientLogic();

        return customLogicHandler;
    }

    // Helper methods.
    private cacheFieldDefinitions(responseFieldDefs: FieldDefinition[]): void {
        this.cachedFieldDefs = responseFieldDefs;

        this.mapOfCachedFieldDefs = {};

        if (responseFieldDefs != null){
            for (let index: number = 0; index < responseFieldDefs.length; index++){
                let responseFieldDef: FieldDefinition = responseFieldDefs[index];

                this.mapOfCachedFieldDefs[responseFieldDef.className] = responseFieldDef;
            }
        }
    }

    private loadCustomLogicHandlers(): void {
        this.mapOfCustomLogicHandlers[AddRowAboveFieldType] = new AddRowAboveFieldDefinitionCustomLogic();
        this.mapOfCustomLogicHandlers[AddRowBelowFieldType] = new AddRowBelowFieldDefinitionCustomLogic();
        this.mapOfCustomLogicHandlers[ShowToFieldType] = new ShowToFieldDefinitionCustomLogic();
        this.mapOfCustomLogicHandlers[HideFromFieldType] = new HideFromFieldDefinitionCustomLogic();
        this.mapOfCustomLogicHandlers[CascadingDropDownFieldType] = new CascadingDropdownFieldDefLogic();
        this.mapOfCustomLogicHandlers[CascadingDropDownVirtualFieldType] = new CascadingDropdownVirtualFieldDefLogic();
        this.mapOfCustomLogicHandlers[GridCascadingDropDownFieldType] = new GridCascadingDropdownFieldDefLogic();
        this.mapOfCustomLogicHandlers[CheckboxFieldType] = new CheckboxFieldDefLogic();
        this.mapOfCustomLogicHandlers[DropDownFieldType] = new DropdownFieldDefLogic();
        this.mapOfCustomLogicHandlers[MultiDropDownFieldType] = new MultiDropdownFieldDefLogic();
        this.mapOfCustomLogicHandlers[MultiCheckboxFieldType] = new MultiCheckboxFieldDefLogic();
        this.mapOfCustomLogicHandlers[RadioButtonsFieldType] = new RadioButtonsFieldDefLogic();
        this.mapOfCustomLogicHandlers[DecimalFieldType] = new DecimalFieldDefLogic();
        this.mapOfCustomLogicHandlers[CurrencyFieldType] = new CurrencyFieldDefLogic();
        this.mapOfCustomLogicHandlers[IntegerFieldType] = new IntegerFieldDefLogic();
        this.mapOfCustomLogicHandlers[DateFieldType] = new DateFieldDefLogic();
        this.mapOfCustomLogicHandlers[ShortTextFieldType] = new ShortTextFieldDefLogic();
        this.mapOfCustomLogicHandlers[RichTextFieldType] = new RichTextFieldDefLogic();
        this.mapOfCustomLogicHandlers[ParagraphFieldType] = new ParagraphFieldDefLogic();
        this.mapOfCustomLogicHandlers[FormulaFieldType] = new FormulaFieldDefLogic(this);
        this.mapOfCustomLogicHandlers[UrlBuilderFieldType] = new UrlBuilderFieldDefLogic(this); 
        //TEAMS-835: KLW - Needed for the HTML Link form field
        this.mapOfCustomLogicHandlers[HTMLLinkFieldType] = new HtmlLinkFieldDefLogic();
        this.mapOfCustomLogicHandlers[MarkDataReadOnlyFieldType] = new MarkDataAsReadOnlyFieldDefinitionCustomLogic();
        this.mapOfCustomLogicHandlers[GridFieldType] = new GridFieldDefLogic();
        this.mapOfCustomLogicHandlers[FlexibleSelectionFieldType] = new FlexibleSelectionFieldDefLogic();

        // Metadata field definition logic classes:
        this.mapOfCustomLogicHandlers[UserRolesFieldType] = new UserRolesFieldDefinitionLogic();
        this.mapOfCustomLogicHandlers[WorkflowStateFieldType] = new WorkflowStateFieldDefinitionLogic();
        this.mapOfCustomLogicHandlers[FolderNameFieldType] = new FolderNameFieldDefinitionLogic();
        this.mapOfCustomLogicHandlers[FolderPathFieldType] = new FolderPathFieldDefinitionLogic();
        this.mapOfCustomLogicHandlers[FormInstanceNameFieldType] = new FormInstanceNameFieldDefinitionLogic();
        this.mapOfCustomLogicHandlers[IdFieldType] = new IdMetadataFieldDefLogic();
        this.mapOfCustomLogicHandlers[FavoriteFieldType] = new FavoriteFieldDefLogic();

        this.mapOfCustomLogicHandlers[FormTemplateInstancesFieldType] = new FormTemplateInstancesFieldDefLogic();
    }
}
