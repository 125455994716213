//import { IClicksLink } from '../../interfaces/component-scripting/iclicks-link';
import { ScriptableFunctionBase } from './scriptable-function-base';
import { ITestActionRecorderService } from '../../interfaces/itest-action-recorder-service.interface';
import { FunctionExecutionStatus, IScriptableFunction } from '../../interfaces/iscriptable-function';
import { IOperationCompletedCallback, IRegisterOperationCompletedCallback } from '../../interfaces/ioperation-completed.intefaces';

export class ClickLinkScriptableFunction extends ScriptableFunctionBase implements IScriptableFunction, IOperationCompletedCallback {
    // Constructor.
    public constructor() {
        super();
    }

    // Implement IScriptableFunction methods.
    public get callableName(): string {
        return 'clickLink';
    }

    public execute(testActionService: ITestActionRecorderService, params: string[]): FunctionExecutionStatus {
        let executionStatus: FunctionExecutionStatus = FunctionExecutionStatus.Failed;

        if ((params != null) && (params.length >= 2) && (params.length <= 5)) {
            let elementTitle: string = params[0];
            let elementMetadataKey: string = params[1];
            let elementSubtype: string = params.length == 3 ? params[2] : null;
            let operationCompletedServiceName: string = params.length == 5 ? params[3] : null;
            let operationName: string = params.length == 5 ? params[4] : null;

            let okToProceed: boolean = this.registerAsOperationCompletedCallback(testActionService, operationCompletedServiceName, operationName);

            if (okToProceed) {
                testActionService.clickLink(elementTitle, elementMetadataKey, elementSubtype, operationCompletedServiceName, operationName);
                executionStatus = this.awaitingCompletionOfOperationName != null ? FunctionExecutionStatus.Verifying : FunctionExecutionStatus.Completed;
            }
        }

        return executionStatus;
    }

    public checkExecutionStatus(testActionService: ITestActionRecorderService, params: string[]): FunctionExecutionStatus {
        let executionStatus: FunctionExecutionStatus = this.awaitedOperationCompleted && (this.numCheckExecutionCallsSinceComponentInitialized > 1) ? FunctionExecutionStatus.Completed : FunctionExecutionStatus.Verifying;
        this.numCheckExecutionCallsSinceComponentInitialized++;
        return executionStatus;
    }

    // Implement IRegisterOperationCompletedCallback.
    public operationCompleted(operationName: string): void {
        if (operationName == this.awaitingCompletionOfOperationName)
            this.awaitedOperationCompleted = true;
    }
}
