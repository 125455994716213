
export class ExcelSiteFormDataExportRequest {
    public dataCollectionId: number;
    public selectedFormNames: string[];

    public constructor(dataCollectionId: number, selectedFormNames: string[]) {
        this.dataCollectionId = dataCollectionId;
        this.selectedFormNames = selectedFormNames;
    }
}
