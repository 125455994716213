import { ComponentRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms'; // Used for Reactive Forms

import { FormInstanceElement } from '../form-builder/form-instance-element.model';
import { IFormFieldComponent } from '../../interfaces/iform-field-component';
import {
    CascadingDropDownFieldType, RichTextFieldType, CheckboxFieldType
} from '../form-builder/form-field-types';
import { FormField } from '../form-builder/form-field.model';

export class GridFormInstanceElementWrapper {
    public formInstanceElement: FormInstanceElement = null;
    public standinDisplayValue: string = null; // Note:  this is used when a grid row is not the currently active row.
    public componentRef: ComponentRef<IFormFieldComponent> = null;
    public colIndex: number = -1;
    public fieldName: string;
    public fieldDisplayName: string;
    public fieldClass: string;
    public formControl: UntypedFormControl = null;

    public constructor(formInstanceElementParam: FormInstanceElement, iColIndexParam: number, field: FormField) {
        this.formInstanceElement = formInstanceElementParam;
        this.colIndex = iColIndexParam;
        this.fieldClass = field.fieldDefinitionClassName;
        this.fieldName = field.name;
        this.fieldDisplayName = field.displayName;

        return;
    }

    public allowHtml() {
        let ind = [RichTextFieldType, CascadingDropDownFieldType, CheckboxFieldType].indexOf(this.fieldClass);
        return ind > -1;
    }
}
