import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { KendoGridHelper } from '../../kendo-grid-helper';
import { MultipleSortSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor, State, CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { CrossSiteUserFavoriteFormsColumnDef, CrossSiteUserFavoriteFormsStateInfo } from './cross-site-user-favorite-forms.extras';
import { Router } from '@angular/router';
import { CrossSiteUserFavoriteFormsData } from '../../models/site-content/cross-site-user-favorite-form.model';
import { CrossSiteUserFavoriteFormsRemoteBindingDirective } from '../../directives/cross-site-user-favorite-forms.directive';
import { FormInstanceService } from '../../services/form-instance.service';
import { ConfirmationDialogComponent, ConfirmationDialogModel } from '../../dialogs/confirmation/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-cross-site-user-favorite-forms',
    templateUrl: './cross-site-user-favorite-forms.component.html',
    styleUrls: ['./cross-site-user-favorite-forms.component.scss'],
    encapsulation: ViewEncapsulation.None //enable CSS overrides
})
export class CrossSiteUserFavoriteFormsComponent implements OnInit {
    @ViewChild(CrossSiteUserFavoriteFormsRemoteBindingDirective) public remoteBindingDirectiveRef: CrossSiteUserFavoriteFormsRemoteBindingDirective;
    private helper = new KendoGridHelper();
    private kendoGridData: CrossSiteUserFavoriteFormsStateInfo = new CrossSiteUserFavoriteFormsStateInfo();

    constructor(private router: Router, private dialog: MatDialog, private formInstanceService: FormInstanceService) { }

    public ngOnInit(): void {
    }
    

    public columnDefs: CrossSiteUserFavoriteFormsColumnDef[] = [
        { fieldName: 'formName', title: 'Name', width: '200' },
        { fieldName: 'siteName', title: 'Site', width: '75' },
        { fieldName: 'modifiedDate', title: 'Modified Date', width: '75' },
        { fieldName: 'modifiedBy', title: 'Modified By', width: '75' },
        { fieldName: 'workflowState', title: 'Status', width: '75' }
    ];

    public openForm(dataItem: CrossSiteUserFavoriteFormsData): void {
        window.open(`/site/${dataItem.siteId}/structure/folder/${dataItem.folderId}/form/${dataItem.formId}`, '_blank').focus();
        //this.router.navigateByUrl(`/site/${dataItem.siteId}/structure/folder/${dataItem.folderId}/form/${dataItem.formId}`);
    }

    public toggleFave(dataItem: CrossSiteUserFavoriteFormsData): void {
        // If the user is unfaving, give them a warning that it will be removed from the Grid
        if (dataItem.isFavorite) {
            let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: "400px",
                data: new ConfirmationDialogModel(
                    `Unfave`,
                    `Are you sure you want to unfave the Form "${dataItem.formName}"? <br/><br/> If you proceed it will continue to display in this grid for now, but will no longer be displayed upon reload.`
                )
            });
            dialogRef.afterClosed().subscribe(yes => {
                if (yes) {
                    this.doToggleFavorite(dataItem);
                }
            });
        } else {
            this.doToggleFavorite(dataItem);
        }
    }

    private doToggleFavorite(dataItem: CrossSiteUserFavoriteFormsData) {
        dataItem.isFavorite = !dataItem.isFavorite;
        this.formInstanceService.toggleIsFavorite(dataItem.formId).then(fi => { });
    }

    public favIcon(dataItem: any): string {
        if (dataItem.isFavorite) {
            return "star";
        } else {
            return "star_outline";
        }
    }

    public get SortSettings(): any {
        let settings: MultipleSortSettings = {
            //mode: "multiple",
            initialDirection: "asc",
            allowUnsort: true,
            showIndexes: true,
        };
        return settings;
    }

    // Getters / Setters
    public get ColumnDefs() {
        return this.columnDefs;
    }

    public get Helper(): KendoGridHelper {
        return this.helper;
    }
    // Getters for virtual scrolling
    public get PageSize(): number {
        return this.kendoGridData.gridState.take;
    }

    public get Skip(): number {
        return this.kendoGridData.gridState.skip;
    }
    public get Sort(): SortDescriptor[] {
        return this.kendoGridData.gridState.sort;
    }

}
