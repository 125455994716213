import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpRequest,
    HttpEventType,
    HttpResponse,
    HttpHeaders,
    HttpEvent
} from '@angular/common/http';
import {
    Subject,
    Observable
} from 'rxjs';

import { AsyncJob } from '../models/async-job.model';
import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { Attachment, AttachmentProperties } from '../models/attachment/attachment.model';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';
import { plainToClass } from "class-transformer";
import { GenericImportDataFromExcelSpreadsheet, ImportDataServiceNames } from '../models/export-import/generic-import-data-from-excel-spreadsheet';

export enum ImportDataFromExcelDialogAction {
    ImportSiteStructure = 'ImportSiteStructureService',
    ImportSiteFormInstances = 'ImportSiteFormInstancesService',
    ImportFormInstanceGrids = 'ImportSiteGridsService',
    ImportValuesToExistingFormInstances = 'ImportValuesToExistingFormInstancesService'
}

@Injectable()
export class ExcelImportService extends CollectApiServiceBase<Attachment> {

    // Constructor.
    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'ExcelImport', Attachment)

        return;
    }

    // The following method is meant to serve as a generic Excel data import endpoint.
    public genericImportDataFromExcelSpreadsheet(importInstructions: GenericImportDataFromExcelSpreadsheet, singleExcelFileAsArray: File[]): Promise<AsyncJob> {

        let url: string = `${this.url}/${this.endpoint}/GenericImportDataFromExcelSpreadsheet/${importInstructions.serviceName}/objectId/${importInstructions.getObjectId()}`;

        // Package the data file and create an HTTP POST request.
        const formData: FormData = this.packageDataFile(singleExcelFileAsArray);

        return this.http.post<AsyncJob>(url, formData)
            .toPromise()
            .then(res => {
                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    // Custom service methods.
    public uploadExcelFile(dataCollectionId: number, folderId: number, importFile: File, notificationEmail: string, shiftClick: boolean): Promise<AsyncJob> {
        let url: string = `${this.url}/${this.endpoint}/ImportExcel/${dataCollectionId}/${folderId}`;

        const formData: FormData = new FormData();
        formData.append('file', importFile, importFile.name);
        formData.append('email', notificationEmail);
        formData.append('shiftClick', String(shiftClick));

        return this.http.post<AsyncJob>(url, formData)
            .toPromise()
            .then(res => {
                return this.formatAsyncJobResponse(res);
            })
            .catch(this.handleError);
    }

    public formatResponse(data: Attachment): Attachment {
        let obj = plainToClass(Attachment, data);
        return (obj);
    }

    public save(data: Attachment): Promise<Attachment> {
        //console.log(data.id);
        if (data.id > 0) {
            return (this.update(data));
        } else
            return (this.create(data));
    }

    // Helper methods.
    private formatAsyncJobResponse(data: AsyncJob): AsyncJob {
        let obj = plainToClass(AsyncJob, data);

        return (obj);
    }

    private packageDataFile(arrayOfOneDataFile: File[]): FormData {
        let formData: FormData = null;

        let dataFile: File = arrayOfOneDataFile.length > 0 ? arrayOfOneDataFile[0] : null;
        if (dataFile != null) {
            formData = new FormData();
            formData.append('file', dataFile, dataFile.name);
        }        

        return formData;
    }
}
