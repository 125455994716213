<ng-container *ngIf="this.Title != null">
    <h1 mat-dialog-title
        class="draggable-dialogue-header title-h"
        cdkDrag
        cdkDragRootElement=".cdk-overlay-pane"
        cdkDragHandle>
        {{this.Title}}
    </h1>

    <div mat-dialog-content
         [ngClass]="this.TabCount == 0 ? 'mat-dialog-content-div' :'mat-dialog-content-div'">
        <!-- Display one text area without tabs if there is only one type of content. -->
        <ng-container *ngIf="this.HasInitialized">
            <ng-container *ngIf="this.HasInitialized && (this.TabCount == 1)">
                <mat-form-field appearance="outline"
                                [floatLabel]="'always'"
                                class="content-mat-form-field">
                    <mat-label>{{this.ContentLabel}}</mat-label>

                    <textarea matInput
                              class="content-text-area"
                              [formControl]="this.ContentFormControl(0)"
                              rows="{{this.NumContentControlRows}}"
                              style="color: black;"></textarea>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="this.HasInitialized && (this.TabCount > 1)">
                <!-- Display multiple text areas with tabs when there is one than one type of content. -->
                <!-- creating buttons to control tabs rather than have mat-tab-group do it -->
                <!--<button mat-button class="tabButton" *ngFor="let tabName of this.TabNames; let iBtn=index" (click)="activateTab(tabGroup, iBtn)">{{tabName}}</button>&nbsp;-->
                <mat-tab-group [(selectedIndex)]="this.SelectedContentIndex"
                               (selectedTabChange)="this.tabChanged()"
                               #tabGroup>
                    <mat-tab *ngFor="let tabName of this.TabNames; let iTab=index"
                             label="{{tabName}}">
                        <ng-template matTabContent>
                            <mat-form-field appearance="outline"
                                            [floatLabel]="'always'"
                                            class="content-mat-form-field">
                                <mat-label>{{this.ContentLabel}}</mat-label>

                                <textarea matInput
                                          class="content-text-area"
                                          [formControl]="this.ContentFormControl(iTab)"
                                          rows="{{this.NumContentControlRows}}"
                                          style="color: black;"></textarea>
                            </mat-form-field>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </ng-container>
        </ng-container>

        <div class="message-span-div">
            <span class="message-span"
                  #messageSpan>{{this.Message}}</span>
        </div>
    </div>

    <div mat-dialog-actions
         class="mat-dialog-actions-div">
        <button mat-flat-button
                type="submit"
                color="primary"
                class="theme-primary-btn"
                [disabled]="this.HasNoContent"
                (click)="this.copyContentToClipboard()">
            Copy Content
        </button>

        <div class="consume-remaining-space"></div>

        <button mat-flat-button
                [disabled]="false"
                style="margin-left: 10px;"
                [mat-dialog-close]="null">
            Close
        </button>
    </div>

</ng-container>
