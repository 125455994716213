<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<div>

    <!-- DESIGN mode -->
    <div *ngIf="this.Mode === 'design'" class="flex-row-top-right">
        <div class="fill-remaining-space">
            <ng-container *ngIf="this.HasInstructions">
                <div class="design-mode-field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </ng-container>
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label *ngIf="this.ShowLabel && !this.HasInstructions"
                           class="design-mode-field-label">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></mat-label>

                <input matInput
                       type="number"
                       placeholder="{{this.PlaceholderText}}"
                       min="0.00"
                       max="10000.00"
                       step="0.01"
                       value="{{this.DefaultValue}}"
                       disabled />
                <span matPrefix>$</span>

            </mat-form-field>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>

    </div>

    <!-- PREVIEW or INSTANCE mode (no format preview) -->
    <div *ngIf="((this.Mode === 'preview') || (this.Mode === 'instance')) && (! this.ShowFormattedValue)"
         class="flex-row-top-right">
        <div class="fill-remaining-space">
            <ng-container *ngIf="(this.ControlType === 'Reactive Forms') && (this.FormControl !== null)">
                <ng-container *ngIf="this.HasInstructions">
                    <div class="field-label" *ngIf="this.DisplayName">
                        {{this.DisplayName}}
                        <span *ngIf="this.FormField.required" style="color:red">*</span>
                    </div>
                    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                </ng-container>

                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid"
                               class="field-label">
                        {{this.DisplayName}}
                        <span *ngIf="this.FormField.required" style="color:red">*</span>
                    </mat-label>

                    <ng-container *ngIf="(! this.ReadOnly) && (! this.ShowFormattedValue)">
                        <input matInput
                               type="text"
                               pattern="[0-9.,]+"
                               id="{{this.MatInputId}}"
                               placeholder="{{this.PlaceholderText}}"
                               step="0.01"
                               [formControl]="this.FormControl"
                               (blur)="this.handleOnBlur()"
                               (focus)="this.handleOnFocus($event)" />
                    </ng-container>

                    <ng-container *ngIf="this.ReadOnly">
                        <input matInput
                               readonly
                               type="number"
                               id="{{this.MatInputId}}"
                               placeholder="{{this.PlaceholderText}}"
                               step="0.01"
                               [formControl]="this.FormControl"
                               (blur)="this.handleOnBlur()"
                               (focus)="this.handleOnFocus($event)" />
                    </ng-container>

                </mat-form-field>

                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>

            </ng-container>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>

    <!-- DESIGN PREVIEW or INSTANCE mode (with format preview) -->

    <div *ngIf="((this.Mode === 'preview') || (this.Mode === 'instance')) && this.ShowFormattedValue"
         class="flex-row-top-right">
        <div class="fill-remaining-space">
            <ng-container *ngIf="(this.ControlType === 'Reactive Forms') && (this.FormControl !== null)">

                <ng-container *ngIf="this.HasInstructions">
                    <div class="field-label" *ngIf="this.DisplayName">
                        {{this.DisplayName}}
                        <span *ngIf="this.FormField.required" style="color:red">*</span>
                    </div>
                    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                </ng-container>

                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid"
                               class="field-label">
                        {{this.DisplayName}}
                        <span *ngIf="this.FormField.required" style="color:red">*</span>
                    </mat-label>

                    <input matInput
                           type="text"
                           pattern="[0-9.]+"
                           id="{{this.MatInputId}}"
                           placeholder="{{this.PlaceholderText}}"
                           step="0.01"
                           [formControl]="this.FormControl"
                           (blur)="this.handleOnBlur()"
                           (ngModelChange)="this.onChange()"
                           (focus)="this.handleOnFocus($event)" />
                    <span matPrefix>$</span>
                </mat-form-field>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </ng-container>
        </div>

        <div *ngIf="this.ShowFormattedValue && this.hasFocus" class="show-formatted-value-formatted-value-div">
            <ng-container *ngIf="this.FormControl !== null && this.NumberFormatPreview != ''">
                preview:&nbsp;$<span>{{this.NumberFormatPreview}}</span>
            </ng-container>
            <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
        </div>
    </div>
</div>
