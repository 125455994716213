import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
// Note:  despite the name of this service, it is used to interpret the meaning of query parameters, not get the raw query parameters themselves.
export class QueryParamsService {
    // Constants.
    public static readonly SHOW_APP_CHROME_PARAM: string = 'action';

    // Properties.
    private parsedQueryParams: Params = null;

    // Constructor.
    public constructor(private route: ActivatedRoute) {
        this.route.queryParams.subscribe(params => {
            this.parsedQueryParams = params;
        });
    }

    // Getter method(s).
    public get HideAppChrome(): boolean {
        let hideAppChrome: boolean = false;
        let showChromeParamValue = this.parsedQueryParams[QueryParamsService.SHOW_APP_CHROME_PARAM];
        if ((this.parsedQueryParams != null) && (showChromeParamValue == 'edit' || showChromeParamValue == 'print'))
            hideAppChrome = true;

        return hideAppChrome;
    }
}
