import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CurrentUser } from '../security/current-user.model';
import * as parseUri from 'parse-uri';
import { Logging } from '../shared/logging';
import { BrowserStorageService } from '../shared/services/browserstorage.service';
import { BrowserStorageEnums } from '../shared/enums/browserstorage.enum';

//import 'rxjs/add/operator/toPromise';

@Injectable()
export class CurrentUserService {
    public loginPromise: Promise<CurrentUser>;

    private serviceUrl = '';
    private setCookieUrl = '';
    private checkCookieUrl = '';
    private noOpUrl = '';
    // pharvey - 9/1/2023 - added for site home page to determine if user should see
    // Site overview, strucuture or home-page screens
    private initialRouteToActiviateUrl = '';
    private cookieName = 'CookieSettingsCheck';
    private initialLoad: boolean = true;

    private _user: CurrentUser;
    private _browserOk: boolean = false;

    constructor(private http: HttpClient,
        private localStorageService: BrowserStorageService) {
        this.serviceUrl = environment.loginSiteUrl + (environment.loginSiteUrl.endsWith("/") ? "" : "/") + "frameworkapi/userinfo/maxattributes";
        this.setCookieUrl = environment.loginSiteUrl + (environment.loginSiteUrl.endsWith("/") ? "" : "/") + "frameworkapi/health/setcookie";
        this.checkCookieUrl = environment.loginSiteUrl + (environment.loginSiteUrl.endsWith("/") ? "" : "/") + "frameworkapi/health/checkcookie";
        this.noOpUrl = environment.loginSiteUrl + (environment.loginSiteUrl.endsWith("/") ? "" : "/") + "frameworkapi/health/noop";
        this.initialRouteToActiviateUrl = environment.loginSiteUrl + (environment.loginSiteUrl.endsWith("/") ? "" : "/") + "api/datacollection/initialRouteToActiviate";
    }

    public getDomain(url): string {
        var u = parseUri(url);
        return u.host;
    }

    public get user(): CurrentUser {
        return this._user;
    }

    public get userIsLoggedIn(): boolean {
        return this._user != null;
    }

    public get browserOk(): boolean {
        return this._browserOk;
    }

    public get domain(): string {
        return this.getDomain(environment.loginSiteUrl);
    }

    public get siteUrl(): string {
        return environment.loginSiteUrl + (environment.loginSiteUrl.endsWith("/") ? "" : "/");
    }

    private getIdleLogout(): boolean {
        var retVal: boolean = false;

        if (this.localStorageService.get(BrowserStorageEnums.IDLELOGOUT, BrowserStorageService.SESSION_SCOPE))
            retVal = this.localStorageService.get(BrowserStorageEnums.IDLELOGOUT, BrowserStorageService.SESSION_SCOPE).toLowerCase() == 'true';

        return retVal
    }

    public get isIdleLogout(): boolean {
        return this.getIdleLogout();
    }

    public setIdleLogout(val) {
        this.localStorageService.set(BrowserStorageEnums.IDLELOGOUT, val, BrowserStorageService.SESSION_SCOPE);
    }

    // Called with a value of true in the following circumstances...
    // 1) The user clicks the "LOG OUT" menu option
    // 2) The user gets logged out do to a session timeout
    // 3) The users gets prompted with the "Session Expiration Warning" alert, and chooses "No" to continuing
    // The method sets a value in session storage and thus it will expire when the user closes their browser
    public setForcedLogout(val: boolean) {
        this.localStorageService.set(BrowserStorageEnums.FORCEDLOGOUT, val.toString(), BrowserStorageService.SESSION_SCOPE);
    }
    
    // For the duration of the browser session isForcedLogout will return the last set value of true or false
    // On new browser session, it will ALWAYS return false, and this plays into the automatic login that occurs
    // when a user opens a browser and navigates to the application
    public get isForcedLogout(): boolean {
        var retVal: boolean = false;

        if (this.localStorageService.get(BrowserStorageEnums.FORCEDLOGOUT, BrowserStorageService.SESSION_SCOPE))
            retVal = this.localStorageService.get(BrowserStorageEnums.FORCEDLOGOUT, BrowserStorageService.SESSION_SCOPE) == true.toString();

        return retVal
    }

    public browserCheck(): Promise<boolean> {
        return this.http.get(this.noOpUrl, { withCredentials: false })
            .toPromise()
            .then(() => {
                return this.http.get(this.setCookieUrl, { withCredentials: true })
                    .toPromise()
                    .then(response => {
                        return this.http.get(this.checkCookieUrl, { withCredentials: true })
                            .toPromise().then(response => {
                                this._browserOk = true;
                                return true;
                            })
                    })
                    .catch((error: Response) => {
                        this._browserOk = false;
                        this._user = null;
                        return Promise.reject<boolean>("Domain " + this.getDomain(this.setCookieUrl) + " probably doesn't allow CORS from domain " + this.domain);
                    });
            })
            .catch(error => {
                this._browserOk = false;
                this._user = null;
                return Promise.reject<boolean>("Can't reach site " + environment.loginSiteUrl + " (" + error + ")");
            });
    }

    public preFlight(): Promise<any> {
        return this.http.options(this.serviceUrl)
            .toPromise()
            .then(response => { return response; })
            .catch(this.handleError);
    }

    public login(): Promise<CurrentUser> {
        this.loginPromise = this.http.get(this.serviceUrl)
            .toPromise()
            .then((response: CurrentUser) => {
                var user: CurrentUser;
                user = Object.assign(new CurrentUser(), response);
                user.displayName = user.firstName + ' ' + (user.middleName == '' ? ' ' : user.middleName + '. ') + user.lastName + user.orgTag;
                user.authenticationGroups = user.maxAuthenticationGroups.split(",");
                this.setIdleLogout(false);
                this._user = user;
                return user;
            })
            .catch(error => {
                //console.log("==== login error: " + error);
                return this.handleError(error);
            });

        return this.loginPromise;
    }

    // For a given site, returns true if the current user should see the Site's
    // Homepage by default
    public initialRouteToActiviateForUser(id: number){
        return this.http.get(`${this.initialRouteToActiviateUrl}/${id}`).toPromise().then(response => {
            return response;
        });
    }

    public logout(idleLogout?: boolean) {
        this._user = null;
        // Added for VNEXT-591 - store the user's last url so we can send
        // them back there when they log back in.
        localStorage.setItem('urlBeforeLogout', window.location.href);
        this.setIdleLogout(idleLogout);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
