import { IViewModel } from '../../interfaces/view-model.interface';

export class NotificationTemplate implements IViewModel {
    // Define primitive properties.
    // Key.
    public id: number = 0;

    public name: string;
    public category: string;
    public templateType: string;

    public format: string;

    public notificationType: string;
    public notificationText: string;

    // notificationText can be a | delimited string to allow presentation of summary information in a grid/list
    // this method returns either the first part of the text (or all of it if there's no pipe)
    public getFirstPartOfNotificationText(): string {
        return this.notificationText.split("|")[0];
    }

    // returns all parts of the notification text so the caller can iterate them/concatenate them as needed
    public getAllTextParts(): string[] {
        return this.notificationText.split('|');
    }

}
