import { Component, Renderer2 } from '@angular/core';
import { InputFormFieldBaseComponent } from './input-form-field-base.component';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';

@Component({
    selector: 'app-text-input-form-field-base',
    templateUrl: './input-form-field-base.component.html',
    styleUrls: ['./input-form-field-base.component.scss']
})
export abstract class TextInputFormFieldBaseComponent extends InputFormFieldBaseComponent {
    // Constructor.
    protected constructor(protected renderer: Renderer2) {    
        super(renderer);
    }

    // Static method.
    public static pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper): void {
        elementWrapper.formInstanceElement.TextValue = value;
        elementWrapper.standinDisplayValue = value;
        elementWrapper.formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeText;
    }

    // Implement methods common to form field component classes that use FormInstanceElement property 'textValue'.
    public pseudoStatic_pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper, formField: FormField): void {
        TextInputFormFieldBaseComponent.pasteValue(value, elementWrapper);
    }

    // Handle updates made outside the control.
    public formInstanceElementUpdated(formInstanceElement: FormInstanceElement): void {
        if ((this.controlData != null) && (this.controlData.formControl != null))
            this.controlData.formControl.setValue(formInstanceElement.textValue);
    }
}
