import { HttpClient } from '@angular/common/http';
//import { Observable } from 'rxjs';
import { QueryOptions } from '../query-options';
import { Logging } from '../logging';
import { IViewModel } from '../interfaces/view-model.interface';
import { ProgressIndicatorService, ProgressConfig } from './progress-indicator.service';
import { IOperationCompletedCallback, IRegisterOperationCompletedCallback } from '../interfaces/ioperation-completed.intefaces';

export class CollectApiServiceBase<T extends IViewModel> {
    // Properties.
    private operationCompletedCallbacks: IOperationCompletedCallback[] = [];
    private logDebugMessages: boolean = false;

    public constructor(
        public http: HttpClient,
        public progressIndicatorService: ProgressIndicatorService,
        public url: string,
        public endpoint: string,
        private TCreator: { new(): T; }) {

        this.url = (url.endsWith('/') ? url.slice(0, -1) : url) + '/api';
    }

    public getAll(queryOptions?: QueryOptions): Promise<T[]> {
        let url = `${this.url}/${this.endpoint}`;

        if (queryOptions)
            url += `?${queryOptions.toQueryString()}`;

        let lStartTime: number = performance.now();

        return this.http
            .get<T[]>(url)
            .toPromise()
            .then(response => {
                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let data: T[] = [];

                response.forEach(x => {
                    let obj = this.formatResponse(x);
                    data.push(obj);
                });

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);

                this.getAllResponseReady(data);
                return data;
            })
            .catch(this.handleError);
    }

    protected getAllResponseReady(response: T[]): void {
        // Note:  this default method implementation is a NOOP by design.
    }

    protected operationCompleted(operationName: string): void {
        // Note:  this method can be overriden by derived classes.
    }

    public create(item: T, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<T> {
        let url = `${this.url}/${this.endpoint}`;
        let msg = progressConfig.msgDuring || this.progressMsg('Creating');
        this.updateProgress(50, 75, msg);

        return this.http.post<T>(url, item)
            .toPromise()
            .then(response => {
                let obj = this.formatResponse(response);
                msg = progressConfig.msgOnComplete || this.progressMsg('Created');
                this.updateProgress(100, 100, msg);
                this.operationCompleted('create');
                return obj;
            })
            .catch(this.handleError);
    }

    public get(id: number): Promise<T> {
        let url = `${this.url}/${this.endpoint}/${id}`;

        let lStartTime: number = performance.now();

        return this.http.get<T>(url)
            .toPromise()
            .then(response => {
                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let obj = this.formatResponse(response);

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);

                return obj;
            })
            .catch(this.handleError);
    }

    public update(item: T, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<T> {
        let url = `${this.url}/${this.endpoint}/${item.id}`;

        let msg = progressConfig.msgDuring || this.progressMsg('Updating');
        this.updateProgress(50, 75, msg);

        let lStartTime: number = performance.now();

        return this.http.put<T>(url, item)
            .toPromise()
            .then(response => {
                let lResponseReceivedTime: number = performance.now();;
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let obj = this.formatResponse(response);

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);

                msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                this.operationCompleted('update');
                this.updateProgress(100, 100, msg);

                return obj;
            })
            .catch(this.handleError);
    }

    public updateIsFavorite(item: T, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<T> {
        let url = `${this.url}/${this.endpoint}/UpdateIsFavorite/${item.id}`;

        let msg = progressConfig.msgDuring || this.progressMsg('Updating is favorite');
        this.updateProgress(50, 75, msg);

        let lStartTime: number = performance.now();

        return this.http.put<T>(url, item)
            .toPromise()
            .then(response => {
                let lResponseReceivedTime: number = performance.now();
                this.reportResponseTime(lStartTime, lResponseReceivedTime, url);

                let obj = this.formatResponse(response);

                let lResponseFormattedTime: number = performance.now();
                this.reportFormatTime(lResponseReceivedTime, lResponseFormattedTime, url);

                msg = progressConfig.msgOnComplete || this.progressMsg('Updated');
                this.updateProgress(100, 100, msg);

                return obj;
            })
            .catch(this.handleError);
    }

    public delete(id: number, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<number> {
        let msg = progressConfig.msgDuring || this.progressMsg('Deleting');
        this.updateProgress(50, 75, msg);
        return this.http.delete(`${this.url}/${this.endpoint}/${id}`)
            .toPromise()
            .then(res => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Deleted');
                this.updateProgress(100, 100, msg);
                return id;
            }).catch(this.handleError);
    }

    // Override to fully instantiate child objects.  Be sure to call super.formatResponse first to instantiate the parent object.
    public formatResponse(data: T): T {
        return Object.assign(new this.TCreator(), data);
    }

    public handleError(error: Response | any): Promise<any> {
        Logging.log(error);
        if (this != null) {
            this.hideProgressIndicator();
        }        
        return Promise.reject(error.message || error);
    }

    // Implement inteface IRegisterOperationCompleted.
    public registerOperationCompletedCallback(callback: IOperationCompletedCallback): void {
        // See if this callback is already registered.
        let alreadyRegisteredCallback: IOperationCompletedCallback = this.operationCompletedCallbacks.find(cb => cb == callback);

        if (alreadyRegisteredCallback == null)
            this.operationCompletedCallbacks.push(callback);
    }

    // This can be overridden in sub-classes as needed
    protected progressMsg(verb: string, entity: string = this.endpoint): string {
        let ending = verb.endsWith('ing') ? '...' : '.';
        let a = verb == 'Creating' ? ' a ' : ' ';
        return `${verb}${a}${entity}${ending}`;
    }
    // Define protected helper methods.
    protected reportResponseTime(lStartTime: number, lEndTime: number, url: string): void {
        let lElapsedTimeInMilliseconds: number = (lEndTime - lStartTime);

        if (this.logDebugMessages) {
            let message: string = `reportResponseTime():  call to url '${url}' ` + `took ${lElapsedTimeInMilliseconds} milliseconds.`;
            console.log(message);
        }

        return;
    }

    protected reportFormatTime(lStartTime: number, lEndTime: number, url: string): void {
        let lElapsedTimeInMilliseconds: number = (lEndTime - lStartTime);

        if (this.logDebugMessages) {
            let message: string = `reportFormatTime():  parsing the response from url '${url}' ` + `took ${lElapsedTimeInMilliseconds} milliseconds.`;
            console.log(message);
        }

        return;
    }

    // delegates to service after null guard
    protected updateProgress(statusValue: number, bufferValue: number, statusText: string): void {
        if (this.progressIndicatorService != null) {
            this.progressIndicatorService.updateProgress(statusValue, bufferValue, statusText);
        }
    }

    // delegates to service after null guard
    protected hideProgressIndicator(): void {
        if (this.progressIndicatorService != null) {
            this.progressIndicatorService.hideProgress();
        }
    }

    protected notifyAnyCallbacksOperationCompleted(operationName: string): void {
        for (let index: number = 0; index < this.operationCompletedCallbacks.length; index++) {
            let callback: IOperationCompletedCallback = this.operationCompletedCallbacks[index];
            callback.operationCompleted(operationName);
        }
    }
}
