import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IViewModel } from '../interfaces/view-model.interface';
import { Logging } from '../logging';
import { PickerItem } from '../models/picker-item.model';
import { BehaviorSubject, EMPTY } from 'rxjs';

@Injectable()
export class MaxPickerService {
    public isSearching = new BehaviorSubject(false);
    
    constructor(private http: HttpClient) { }

    search(query: string, max: number = 100, scope: string = 'usersAndGroups'): Promise<PickerItem[]> {
        if ((!query) || (query.length == 0)) { return EMPTY.toPromise(); }

        this.isSearching.next(true);

        var url = `${environment.apiUrl}/frameworkapi/maxpicker/search?searchstring=${query}&maxcount=${max}&scope=${scope}`;

        return this.http
            .get<PickerItem[]>(url)
            .toPromise()
            .then(response => {
                var data: PickerItem[] = [];

                response.forEach(x => {
                    data.push(new PickerItem(x));
                });
                this.isSearching.next(false);
                return data;
            })
            .catch(this.handleError);
    }

    handleError(error: Response | any): Promise<any> {
        Logging.log(error);
        return Promise.reject(error.message || error);
    }
}
